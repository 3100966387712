import { RentalState } from '@cooltra/api';
import { Icon, Badge, BadgeVariant } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { MdCheck, MdClose } from 'react-icons/md';

import messages from './messages';

const mapStateToBadge: { [key in RentalState]: BadgeVariant } = {
  COMPLETED: 'neutral',
  ACTIVE: 'success',
  CLOSED: 'danger',
};

const mapStateToIcon: { [key in RentalState]: JSX.Element } = {
  CLOSED: <MdClose />,
  COMPLETED: <MdCheck />,
  ACTIVE: (
    <span className="w-3 h-3 inline-flex justify-center items-center">
      <span className="inline-block w-1 h-1 rounded-full bg-success-600" />
    </span>
  ),
};

export type RentalStateBadgeProps = {
  state: RentalState;
};

export const RentalStateBadge = ({ state }: RentalStateBadgeProps) => (
  <Badge variant={mapStateToBadge[state]}>
    <div className="flex items-center gap-1">
      <Icon>{mapStateToIcon[state]}</Icon>
      <span className="block">
        <FormattedMessage {...messages[state]} />
      </span>
    </div>
  </Badge>
);
