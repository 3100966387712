import { UserUninvoicedCharge } from '@cooltra/api';
import { Card } from '@cooltra/ui';
import { shortenId } from '@cooltra/utils';
import { RouterLink } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';

import { Amount, MultilineDateTime, InvoiceLegalEntity } from '~/common';

import { UninvoicedChargeRowLayout } from '../UninvoicedChargeRowLayout';
import { CancelUninvoicedCharge } from '../CancelUninvoicedCharge/CancelUninvoicedCharge';

import messages from './messages';

export type UninvoicedChargeCardProps = {
  uninvoicedCharge: UserUninvoicedCharge;
};

export const UninvoicedChargeCard = ({
  uninvoicedCharge: {
    id,
    createdAt,
    rentalId,
    legalEntity,
    total,
    price,
    chargeState,
  },
}: UninvoicedChargeCardProps) => (
  <Card className="py-5 text-sm text-neutral-800">
    <UninvoicedChargeRowLayout
      createdAt={<MultilineDateTime date={createdAt} />}
      rentalId={
        <RouterLink to={`/rentals/${rentalId}`}>
          #{shortenId(rentalId)}
        </RouterLink>
      }
      legalEntity={<InvoiceLegalEntity legalEntity={legalEntity} />}
      total={<Amount {...total} />}
      price={<Amount {...price} />}
      state={
        chargeState === 'CANCELLED' ? (
          <FormattedMessage {...messages.canceled} />
        ) : (
          <CancelUninvoicedCharge id={id} />
        )
      }
    />
  </Card>
);
