import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';

export type ListColumnLabelProps = HTMLAttributes<HTMLSpanElement>;

export const ListColumnLabel = ({
  className,
  ...rest
}: ListColumnLabelProps) => (
  <span
    className={classNames('block text-xs text-neutral-300', className)}
    {...rest}
  />
);
