import { AsyncSelect, AsyncSelectOption } from '@cooltra/ui';
import { useEffect, useRef } from 'react';
import { useToggle } from '@cooltra/hooks';
import { classNames } from '@cooltra/utils';
import { useIntl } from 'react-intl';
import { MdSearch } from 'react-icons/md';

import { useMapCenter } from '~/libs/map-center';

import { autofill } from './autofill';
import { geocode } from './geocode';
import messages from './messages';

import './address-search.css';

export const AddressSearch = () => {
  const { formatMessage } = useIntl();
  const [isOpen, { toggle }] = useToggle();

  const timeout = useRef<NodeJS.Timeout>();
  const { updateViewState } = useMapCenter();

  const loadOptions = (
    val: string,
    callback: (options: AsyncSelectOption[]) => void
  ) => {
    if (!val) {
      return;
    }
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      autofill({ searchText: val }).then((suggestions) =>
        callback(
          suggestions.map(({ full_address }) => ({
            label: full_address,
            value: full_address,
          }))
        )
      );
    }, 1000);
  };

  const onChange = (searchText: string) => {
    geocode({ searchText }).then((center) => {
      if (center) {
        updateViewState({
          longitude: center[0],
          latitude: center[1],
          zoom: 16,
        });
      }
    });
  };

  useEffect(
    () => () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    },
    []
  );

  return (
    <div
      className={classNames(
        'absolute bottom-6 left-6 h-11 w-80 address-search',
        isOpen ? 'shadow-md w-80' : 'w-11'
      )}
    >
      <label
        htmlFor="map-address-search"
        onClick={toggle}
        className={classNames(
          'absolute inset-y-0 bottom-0 flex items-center justify-center w-11 h-11 bg-neutral-0 text-neutral-400 z-10 text-xl rounded-md cursor-pointer',
          !isOpen && 'shadow-md'
        )}
      >
        <MdSearch className="text-2xl" />
      </label>
      {isOpen && (
        <AsyncSelect
          id="map-address-search"
          isClearable
          cacheOptions
          placeholder={formatMessage(messages.placeholder)}
          onChange={onChange}
          menuPlacement="top"
          loadOptions={loadOptions}
        />
      )}
    </div>
  );
};
