import { Legend } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormRadioGroupField, FormRadioGroupFieldProps } from '@cooltra/form';

import messages from './messages';

export type CategoryProps = Omit<
  FormRadioGroupFieldProps,
  'name' | 'innerClassName' | 'legend' | 'items'
>;

export const Category = (props: CategoryProps) => {
  const { formatMessage } = useIntl();

  return (
    <FormRadioGroupField
      name="driverLicenseCategory"
      innerClassName="flex flex-row gap-6"
      legend={
        <Legend>
          <FormattedMessage {...messages.driverLicenseCategory} />
        </Legend>
      }
      items={[
        { value: 'sm', label: formatMessage(messages.smScooter) },
        { value: 'lg', label: formatMessage(messages.lgScooter) },
        { value: 'xl', label: formatMessage(messages.xlScooter) },
      ]}
      {...props}
    />
  );
};
