import { VehicleType } from '@cooltra/api';
import { getParams } from '@cooltra/utils';
import { useLocation } from 'react-router-dom';

export const useVehicleTypeQueryParam = () => {
  const { search } = useLocation();
  const queryParams = getParams<'vehicleType'>(search);
  const vehicleType = queryParams.string('vehicleType') || 'SCOOTER';

  return vehicleType as VehicleType;
};
