import { defineMessages } from 'react-intl';

export default defineMessages({
  vehicleInTransit: {
    defaultMessage: 'Vehicle in transit',
  },
  active: {
    defaultMessage: 'Active',
  },
});
