import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type InvoiceItemRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  chargedAt: ReactNode;
  item: ReactNode;
  total: ReactNode;
  price: ReactNode;
  state?: ReactNode;
};

export const InvoiceItemRowLayout = ({
  chargedAt,
  item,
  total,
  price,
  state,
  className,
  ...rest
}: InvoiceItemRowLayoutProps) => (
  <div className={classNames('flex items-center gap-4', className)} {...rest}>
    <div className="w-40 shrink-0">{chargedAt}</div>
    <div className="flex-1 shrink-0">{item}</div>
    <div className="w-20 shrink-0">{total}</div>
    <div className="w-20 shrink-0">{price}</div>
    {state && <div className="w-24 shrink-0 text-right">{state}</div>}
  </div>
);
