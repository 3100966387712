import { defineMessages } from 'react-intl';

export default defineMessages({
  theDateMustBeInTheFuture: {
    defaultMessage: 'The date must be in the future',
  },
  creditSuccessfullyAdded: {
    defaultMessage: 'Credit successfully added',
  },
  theValueMustBeBiggerThanZero: {
    defaultMessage: 'The value must be bigger than zero',
  },
  somethingWentWrong: {
    defaultMessage:
      'Something went wrong and we could not add the credit to the user',
  },
});
