import { FormProvider, Form, FormProviderProps } from '@cooltra/form';
import { useIntl } from 'react-intl';

import { PromotionFormValues } from './promotion-form';
import { validatePromotionForm } from './promotion-form-validations';

export type PromotionFormProps = Omit<
  FormProviderProps<PromotionFormValues>,
  'validate'
>;

export const PromotionForm = ({
  onSubmit,
  initialValues,
  children,
}: PromotionFormProps) => {
  const intl = useIntl();

  return (
    <FormProvider
      initialValues={initialValues}
      validate={validatePromotionForm(intl)}
      onSubmit={onSubmit}
    >
      <Form>{children}</Form>
    </FormProvider>
  );
};
