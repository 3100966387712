import { UserStateType } from '@cooltra/api';

import { DateTime, UserStateBadge } from '~/common';

export type UnitStateHeaderProps = {
  type: UserStateType;
  createdAt: string;
};

export const UnitStateHeader = ({ createdAt, type }: UnitStateHeaderProps) => (
  <div className="flex items-center gap-4">
    <UserStateBadge state={type} />
    <DateTime date={createdAt} />
  </div>
);
