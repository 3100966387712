import { RouterButton } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Card, List } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';
import { useEffect } from 'react';
import { usePacksQuery } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';

import {
  ErrorPage,
  Four0Three,
  NoSearchResults,
  ResetFiltersButton,
  SortFields,
} from '~/common';
import { useNavigateWithQueryParams } from '~/hooks';

import { OffersNavigationTabs } from '../../../OffersNavigationTabs/OffersNavigationTabs';
import { initialValues, PacksFiltersFormValues } from '../../PacksFiltersForm';
import * as FilterFields from '../FilterFields';
import { PacksTotal } from '../PacksTotal/PacksTotal';
import { PacksListHeader } from '../PacksListHeader/PacksListHeader';
import { PackCard, PackCardLoading } from '../PackCard';

import messages from './messages';

export const PacksList = () => {
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const { hasPermission } = useAuthClaimsQuery();

  const { values } = useFormContext<PacksFiltersFormValues>();

  const { data, isLoading, isError, error } = usePacksQuery(values);

  useEffect(() => {
    navigateWithQueryParams(values);
  }, [navigateWithQueryParams, values]);

  if (!hasPermission('read:offers')) {
    return <Four0Three />;
  }

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div className="container min-w-5xl max-w-6xl py-12">
      <div className="flex items-center justify-between mb-6">
        <OffersNavigationTabs />
        {hasPermission('write:offers') && (
          <RouterButton emphasis="high" to="/offers/packs/new">
            <FormattedMessage {...messages.createPack} />
          </RouterButton>
        )}
      </div>
      <Card className="p-8 mb-20">
        <div className="grid grid-cols-3 gap-x-8 mb-12">
          <FilterFields.Keyword />
          <FilterFields.State />
        </div>
        <div className="flex justify-end">
          <ResetFiltersButton initialValues={initialValues} />
        </div>
      </Card>
      <div className="flex justify-between items-center mb-12">
        <PacksTotal loading={isLoading} total={data?.packs.length || 0} />
        <SortFields.CreatedAt />
      </div>
      <List
        isLoading={isLoading}
        loadingRows={4}
        idProp="packId"
        data={data?.packs || []}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<PacksListHeader />}
        renderLoadingRow={<PackCardLoading />}
        renderRow={(pack) => <PackCard pack={pack} />}
      />
    </div>
  );
};
