import { Model } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { RouterLink } from '@cooltra/navigation';

import { Pair } from '../../Pair';
import { ModelImage } from '../Model';

import messages from './messages';

export type VehiclePairProps = {
  vehicleId?: string;
  model?: Model;
  externalId?: string;
  identificationNumber?: string;
};

export const VehiclePair = ({
  vehicleId,
  model,
  externalId,
  identificationNumber,
}: VehiclePairProps) => {
  if (vehicleId && model && externalId) {
    return (
      <div>
        <Pair.Label className="mb-2">
          <FormattedMessage {...messages.vehicle} />
        </Pair.Label>
        <Pair.Value className="h-5">
          <RouterLink to={`/vehicles/${vehicleId}`}>
            <span className="flex items-center gap-1 h-5">
              <ModelImage
                className="h-6"
                model={model}
                identificationNumber={identificationNumber || ''}
              />
              <span className="truncate">{externalId}</span>
            </span>
          </RouterLink>
        </Pair.Value>
      </div>
    );
  }

  return (
    <div>
      <Pair.Label className="mb-2">
        <FormattedMessage {...messages.vehicle} />
      </Pair.Label>
      <Pair.ValueLoading />
    </div>
  );
};
