import { Rental } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { Amount } from '~/common';

import messages from './messages';

export type PricingFinalPriceProps = Pick<Rental, 'pricing'>;

export const PricingFinalPrice = ({ pricing }: PricingFinalPriceProps) => {
  if (!pricing) {
    return <></>;
  }

  return (
    <div
      className="flex justify-between items-center pt-4"
      data-testid="RENTAL_PRICING_SUMMARY_FINAL_PRICE"
    >
      <span className="text-sm">
        <FormattedMessage {...messages.totalCharged} />
      </span>
      <Amount
        className="text-sm"
        value={pricing.finalPrice.value}
        currency={pricing.finalPrice.currency}
      />
    </div>
  );
};
