import { forwardRef, ReactNode } from 'react';
import ReactMapGl, { MapProps, MapRef } from 'react-map-gl';

import { getEnvVariable } from '~/utils/environment';
import { isTestingEnv } from '~/utils/e2e';
import 'mapbox-gl/dist/mapbox-gl.css';

export type BaseMapProps = Omit<MapProps, 'fog' | 'terrain'> & {
  children: ReactNode;
};

export const BaseMap = forwardRef<MapRef, BaseMapProps>(
  ({ initialViewState, ...rest }: BaseMapProps, ref) => {
    const isCy = isTestingEnv();
    return (
      <ReactMapGl
        ref={ref}
        mapboxAccessToken={isCy ? '' : getEnvVariable('MAPBOX_ACCESS_TOKEN')}
        mapStyle={isCy ? '' : getEnvVariable('MAPBOX_STYLE')}
        initialViewState={{ bearing: 0, pitch: 0, ...initialViewState }}
        minZoom={0}
        bearing={0}
        pitch={0}
        scrollZoom
        reuseMaps
        fadeDuration={0}
        dragRotate={false}
        pitchWithRotate={false}
        attributionControl={false}
        touchPitch={false}
        testMode={isCy}
        {...rest}
      />
    );
  }
);
