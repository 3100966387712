import { differenceInHours } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { MdArrowDownward } from 'react-icons/md';
import { Contract } from '@cooltra/station-based-api';

import messages from './messages';

type DetailsProps = Pick<Contract, 'details'>;

export const Details = ({ details }: DetailsProps) => {
  const { formatDate } = useIntl();

  if (!details) return null;

  const formatDateTime = (date: string, time: string) =>
    `${formatDate(date, {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })} - ${time}`;

  return (
    <>
      <span className="block text-sm text-neutral-800 mb-4">
        <FormattedMessage
          {...messages.days}
          values={{
            totalDays: Math.ceil(
              differenceInHours(
                new Date(`${details.endDate}T${details.endTime}`),
                new Date(`${details.startDate}T${details.startTime}`)
              ) / 24
            ),
          }}
        />
      </span>
      <div>
        <div className="flex justify-between mb-2 gap-8">
          <div>
            <span className="block text-sm text-neutral-600 mb-2">
              {details.servicePointName}
            </span>
            <span className="text-base text-neutral-800 font-medium">
              {formatDateTime(details.startDate, details.startTime)}
            </span>
          </div>
          <MdArrowDownward className="text-lg mt-4 -rotate-90 text-neutral-800" />
          <div>
            <span className="block text-sm text-neutral-600 mb-2">
              {details.servicePointName}
            </span>
            <span className="text-base font-medium text-neutral-800">
              {formatDateTime(details.endDate, details.endTime)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
