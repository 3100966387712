import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useLockReviewMutation } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { TopLoadingBar } from '@cooltra/ui';

import { LastReviews, ReviewForm } from '~/common';

import { ReviewLocked } from '../ReviewLocked/ReviewLocked';

export const Review = () => {
  const { id = '' } = useParams<'id'>();
  const { data, isError, mutate } = useLockReviewMutation(id);
  const navigate = useNavigate();

  const { hasPermission } = useAuthClaimsQuery();
  const hasWriteUsersPermission = hasPermission('write:users');

  useEffect(() => {
    if (hasWriteUsersPermission) {
      mutate();
    }
  }, [hasWriteUsersPermission, mutate]);

  if (isError) {
    return <ReviewLocked />;
  }

  if (!data && hasWriteUsersPermission) {
    return <TopLoadingBar />;
  }

  const onFinish = () => navigate(`/users/${id}/account/overview`);

  return (
    <>
      <ReviewForm userId={id} onFinish={onFinish} />
      <LastReviews />
    </>
  );
};
