import { useTaskCommentsQuery } from '@cooltra/api';
import { useOperatorsQuery } from '@cooltra/auth-api';
import { FormattedMessage } from 'react-intl';

import { Comment } from '~/common';
import { sortByDate } from '~/utils/date';

import messages from './messages';

export type CommentsProps = {
  taskId: string;
};

export const Comments = ({ taskId }: CommentsProps) => {
  const { data: operators } = useOperatorsQuery();
  const { data: tasks = [] } = useTaskCommentsQuery(taskId);

  if (!tasks.length) {
    return (
      <p className="text-base text-neutral-500 mt-1.5">
        <FormattedMessage {...messages.noComments} />
      </p>
    );
  }

  return (
    <div>
      {tasks
        .sort(sortByDate(false))
        .map(({ createdAt, createdBy, comment }, index) => (
          <Comment
            createdBy={
              operators?.find(({ externalId }) => createdBy === externalId)
                ?.name
            }
            comment={comment}
            createdAt={createdAt}
            key={createdAt}
            isLast={index === tasks.length - 1}
          />
        ))}
    </div>
  );
};
