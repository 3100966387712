import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Congratulations! All rentals parking has been reviewed',
  },
  content: {
    defaultMessage:
      'To check new incoming rentals parking you must reload the page or change some filters',
  },
});
