import { PromotionListItem } from '@cooltra/api';
import { Badge } from '@cooltra/ui';
import { isFuture, isPast } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type PromotionStateProps = Pick<
  PromotionListItem,
  'validFrom' | 'validUntil'
>;

export enum PromotionStateType {
  EXPIRED = 'EXPIRED',
  ACTIVE = 'ACTIVE',
  SCHEDULED = 'SCHEDULED',
}

export const getPromotionStateType = ({
  validFrom,
  validUntil,
}: PromotionStateProps) => {
  if (validUntil && isPast(new Date(validUntil))) {
    return PromotionStateType.EXPIRED;
  }

  if (validFrom && isFuture(new Date(validFrom))) {
    return PromotionStateType.SCHEDULED;
  }

  return PromotionStateType.ACTIVE;
};

export const PromotionState = ({
  validFrom,
  validUntil,
}: PromotionStateProps) => {
  const promotionStateType = getPromotionStateType({ validFrom, validUntil });

  if (promotionStateType === PromotionStateType.EXPIRED) {
    return (
      <Badge emphasis="low">
        <FormattedMessage {...messages.expired} />
      </Badge>
    );
  }

  if (promotionStateType === PromotionStateType.SCHEDULED) {
    return (
      <Badge>
        <FormattedMessage {...messages.scheduled} />
      </Badge>
    );
  }

  return (
    <Badge variant="success">
      <FormattedMessage {...messages.active} />
    </Badge>
  );
};
