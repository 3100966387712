import {
  useDeleteCreditMutation,
  UserCredit,
  useUserCreditsQuery,
} from '@cooltra/api';
import { List } from '@cooltra/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { DeleteModal, ErrorPage } from '~/common';
import { useNotification } from '~/hooks';

import { CreditCard, CreditCardLoading } from '../CreditCard';
import { CreditsListHeader } from '../CreditsListHeader/CreditsListHeader';
import { NoCredits } from '../NoCredits/NoCredits';

import messages from './messages';

export type CreditsProps = {
  userId: string;
};

export const Credits = ({ userId }: CreditsProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { addSuccessNotification, addErrorNotification } = useNotification();

  const [selectedCreditId, selectCreditId] = useState<string>();
  const { data, isLoading, isError } = useUserCreditsQuery(userId);

  const { mutateAsync, isPending: isDeleting } = useDeleteCreditMutation(
    selectedCreditId || ''
  );

  const onConfirm = () =>
    mutateAsync()
      .then(() => {
        addSuccessNotification(formatMessage(messages.success));
        queryClient.invalidateQueries({ queryKey: ['user-credits', userId] });
        selectCreditId(undefined);
      })
      .catch(() => addErrorNotification());

  const onClose = () => selectCreditId(undefined);

  const onDeleteCredit = (creditId: string) => () => selectCreditId(creditId);

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div data-testid="USER_CREDITS" className="pt-10">
      <DeleteModal
        onClose={onClose}
        onConfirm={onConfirm}
        isOpen={!!selectedCreditId}
        isDeleting={isDeleting}
      />
      <List
        loadingRows={4}
        isLoading={isLoading}
        idProp="creditId"
        data={data?.credits || []}
        renderEmptyMessage={<NoCredits />}
        renderHeader={<CreditsListHeader />}
        renderLoadingRow={<CreditCardLoading />}
        renderRow={(credit: UserCredit) => (
          <CreditCard
            onDelete={onDeleteCredit(credit.creditId)}
            credit={credit}
          />
        )}
      />
    </div>
  );
};
