import { RentalState, Telematics as TelematicsType } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import {
  Lock,
  Unlock,
  Honk,
  Sync,
  UnlockTopCase,
  PowerOff,
  GpsReboot,
  OpenSaddle,
  IgnitionOn,
  IgnitionOff,
  EnableAlarm,
  DisableAlarm,
  ActionsDropdown,
} from '~/common';
import { useVehicleQuery } from '~/hooks';

export type TelematicsInformationProps = {
  vehicleId: string;
  rentalState: RentalState;
};

const actions = {
  askoll: {
    main: [Lock, Unlock, Honk, Sync, UnlockTopCase],
    moreActions: [],
  },
  niu: {
    main: [Lock, Unlock, Honk, Sync, UnlockTopCase],
    moreActions: [
      GpsReboot,
      OpenSaddle,
      IgnitionOn,
      IgnitionOff,
      EnableAlarm,
      DisableAlarm,
    ],
  },
  arcade: {
    main: [Lock, Unlock, Sync, PowerOff],
    moreActions: [],
  },
  segway: {
    main: [Lock, Unlock, Honk, Sync],
    moreActions: [],
  },
};

export const Telematics = ({
  vehicleId,
  rentalState,
}: TelematicsInformationProps) => {
  const { hasPermission } = useAuthClaimsQuery();
  const { data: vehicle } = useVehicleQuery(vehicleId);

  const getActions = (telematics: TelematicsType | undefined) => {
    switch (telematics) {
      case 'INVERS':
      case 'ASTRA':
        return actions.askoll;
      case 'NIU':
        return actions.niu;
      case 'AXA':
        return actions.arcade;
      case 'SEGWAY':
        return actions.segway;
      default:
        return actions.askoll;
    }
  };

  const vehicleActions = getActions(vehicle?.telematics);

  return (
    <div className="flex gap-2" data-testid="RENTAL_TELEMATICS_ACTIONS">
      {hasPermission('action:vehicles') && rentalState === 'ACTIVE' && (
        <>
          {vehicleActions.main.map((ActionButton, i) => (
            <ActionButton
              key={`action-${i}`}
              vehicleId={vehicleId}
              emphasis="high"
              showIcon={false}
              isDropdown={false}
            />
          ))}
          {!!vehicleActions.moreActions.length && (
            <ActionsDropdown emphasis="high">
              {vehicleActions.moreActions.map((ActionButton, i) => (
                <ActionButton
                  key={`action-${i}`}
                  vehicleId={vehicleId}
                  emphasis="high"
                  showIcon={true}
                  isDropdown={true}
                />
              ))}
            </ActionsDropdown>
          )}
        </>
      )}
    </div>
  );
};
