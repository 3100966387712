import { Route, Routes } from 'react-router-dom';

import { Four0Four } from '~/common';

import { User } from '../User/User';

import { UsersLanding } from './UsersLanding/UsersLanding';
import { UsersFiltersForm } from './UsersList/UsersFiltersForm';
import { UsersList } from './UsersList/UsersList';

export const Users = () => (
  <Routes>
    <Route path="/" element={<UsersLanding />} />
    <Route
      path="/search"
      element={
        <UsersFiltersForm>
          <UsersList />
        </UsersFiltersForm>
      }
    />
    <Route path="/:id/*" element={<User />} />
    <Route path="*" element={<Four0Four />} />
  </Routes>
);
