import { FormattedMessage } from 'react-intl';
import { ContractAvailableVehicleTypes } from '@cooltra/station-based-api';
import { Button, Card } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';

import { Amount } from '~/common';
import { useContractFlags } from '~/hooks';

import { AssignLicensePlateModal } from '../../AssignLicensePlateModal/AssignLicensePlateModal';
import { VehicleTypeCardAddButton } from '../VehicleTypeCardAddButton/VehicleTypeCardAddButton';
import { VehicleTypeCardRemoveButton } from '../VehicleTypeCardRemoveButton/VehicleTypeCardRemoveButton';

import messages from './messages';

export type VehicleTypeCardProps = ContractAvailableVehicleTypes & {
  isAdded?: boolean;
  contractId: string | undefined;
  vehicleTypeName: string;
  vehicleTypeImage: string;
  vehicleLicensePlate?: string;
};

export const VehicleTypeCard = ({
  id,
  name,
  price,
  contractId,
  isAdded,
  vehicleTypeName,
  vehicleTypeImage,
  vehicleLicensePlate,
}: VehicleTypeCardProps) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { isSignedOrPendingSignature } = useContractFlags();

  return (
    <Card data-testid={`VEHICLE_${name}`} className="bg-neutral-0 p-8">
      {contractId && isAdded && (
        <AssignLicensePlateModal
          isOpen={isOpen}
          contractId={contractId}
          vehicleTypeId={id}
          vehicleTypeName={vehicleTypeName}
          onClose={toggleOff}
        />
      )}
      <div>
        <h2 className="mb-4 text-lg font-semibold text-neutral-800">
          {vehicleLicensePlate ? (
            `${vehicleLicensePlate} - ${vehicleTypeName}`
          ) : (
            <FormattedMessage
              {...messages.orSimilar}
              values={{
                vehicleTypeName: vehicleTypeName,
              }}
            />
          )}
        </h2>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img className="w-24 mr-6" alt={name} src={vehicleTypeImage} />
          <div className="flex flex-col">
            <span className="mb-2 text-3xl font-semibold text-neutral-800">
              <Amount {...price.total} />
            </span>
            <span className="text-base font-medium text-neutral-600">
              <FormattedMessage
                {...messages.dailyPrice}
                values={{
                  amount: <Amount {...price.daily} />,
                }}
              />
            </span>
          </div>
        </div>
        <div className="flex items-end gap-2">
          {isAdded && <VehicleTypeCardRemoveButton vehicleTypeId={id} />}
          {isAdded && (
            <>
              {vehicleLicensePlate ? (
                <Button
                  onClick={toggleOn}
                  disabled={isSignedOrPendingSignature}
                >
                  <FormattedMessage {...messages.changeLicensePlate} />
                </Button>
              ) : (
                <Button onClick={toggleOn}>
                  <FormattedMessage {...messages.assignLicensePlate} />
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      {!isAdded && (
        <div className="mt-6 flex items-center justify-end">
          <VehicleTypeCardAddButton vehicleTypeId={id} />
        </div>
      )}
    </Card>
  );
};
