import { Button } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ContractPayment,
  ContractStatus,
  useRefundTicketContractMutation,
} from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { useToggle } from '@cooltra/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Amount, ConfirmModal } from '~/common';
import { useContractFlags, useNotification } from '~/hooks';

import { useContractPaymentsPolling } from '../../ContractPaymentsItems/useContractPaymentsPolling';
import messages from '../../ContractPaymentsItems/messages';

type RefundButtonProps = {
  contractId: string;
  contractStatus: ContractStatus;
  payment: ContractPayment;
};

export const RefundButton = ({
  contractId,
  contractStatus,
  payment: {
    id,
    status,
    totalPrice: { currency, value },
    paymentMethod,
    operationNumber,
    lastFourDigits,
    deposit,
  },
}: RefundButtonProps) => {
  const { hasPermission } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { mutateAsync, reset, isPending } = useRefundTicketContractMutation(
    contractId,
    id
  );
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const queryClient = useQueryClient();
  const { isSignedOrPendingSignature } = useContractFlags();

  const { isPolling, setIsStripePollingActive } = useContractPaymentsPolling({
    onSettle: () => {
      queryClient.invalidateQueries({ queryKey: ['contract', contractId] });

      reset();
      toggleOff();

      setIsStripePollingActive(false);
    },
    contractId,
    ticketId: id,
  });

  const handleOnRefund = () =>
    mutateAsync()
      .then(() => {
        if (paymentMethod === 'CARD') {
          setIsStripePollingActive(true);
          return;
        }
        toggleOff();
        addSuccessNotification(formatMessage(messages.refundSuccess));
      })
      .catch((error: AxiosError) => {
        switch (error.response?.status) {
          case 400:
            addErrorNotification(formatMessage(messages.refundValidationError));
            break;
          default:
            addErrorNotification();
        }
      });

  const isRefundButtonVisible =
    (contractStatus === 'DRAFT' || contractStatus === 'BOOKED') && !deposit;

  return (
    <>
      <ConfirmModal
        className="max-w-md min-w-md"
        dataTestId="REFUND_CONTRACT_TICKET_CONFIRM_MODAL"
        isOpen={isOpen}
        title={formatMessage(messages.areYourSureToRefundTicket)}
        content={
          paymentMethod === 'TPV' ? (
            <div className="mb-5">
              <FormattedMessage
                {...messages.tpvOperationId}
                tagName="p"
                values={{
                  operationId: operationNumber,
                }}
              />
              <FormattedMessage
                {...messages.cardLastFourDigits}
                tagName="p"
                values={{
                  lastFourDigits: lastFourDigits,
                }}
              />
            </div>
          ) : (
            <></>
          )
        }
        confirmText={formatMessage(messages.confirmRefund, {
          amount: <Amount value={value} currency={currency} />,
        })}
        onClose={toggleOff}
        onConfirm={handleOnRefund}
        loading={isPending || isPolling}
      />
      {hasPermission('cancel:station_based_contracts') &&
        isRefundButtonVisible &&
        status === 'PAID' && (
          <Button
            size="sm"
            disabled={isSignedOrPendingSignature || isPending || isPolling}
            onClick={toggleOn}
          >
            <FormattedMessage {...messages.refund} />
          </Button>
        )}
      {status === 'REFUNDED' && (
        <span className="text-sm text-success-500">
          <FormattedMessage {...messages.refunded} />
        </span>
      )}
    </>
  );
};
