import { HTMLAttributes } from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';
import ideal from './assets/ideal.svg';
import bancontact from './assets/bancontact.svg';

export type PaymentMethodType = 'ideal' | 'bancontact';

const paymentMethodLogos: { [key in PaymentMethodType]: string } = {
  ideal: ideal,
  bancontact: bancontact,
};

export type PaymentMethodLogoProps = Omit<
  HTMLAttributes<HTMLImageElement>,
  'src' | 'alt'
> & {
  type: PaymentMethodType;
};

export const PaymentMethodLogo = ({
  type,
  ...rest
}: PaymentMethodLogoProps) => {
  const { formatMessage } = useIntl();
  const logo = paymentMethodLogos[type];

  if (!logo) {
    return (
      <div
        aria-label={formatMessage(messages.unknown)}
        className="w-14 h-9 bg-neutral-600 rounded relative"
      >
        <div className="bg-neutral-0 absolute top-2 w-full h-2" />
      </div>
    );
  }

  return (
    <img
      alt={formatMessage(messages[type])}
      src={paymentMethodLogos[type]}
      {...rest}
    />
  );
};
