import { useVehicleTasksQuery, VehicleTaskListItem } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { List } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { EmptyListMessageCard, ListErrorMessageCard } from '~/common';

import {
  OpenVehicleTaskCard,
  OpenVehicleTaskCardLoading,
} from '../OpenVehicleTaskCard';
import { OpenVehicleTasksHeader } from '../OpenVehicleTasksHeader/OpenVehicleTasksHeader';

import messages from './messages';

export type OpenVehicleTasksProps = {
  vehicleId: string;
};

export const OpenVehicleTasks = ({ vehicleId }: OpenVehicleTasksProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  const {
    data: tasks = [],
    isLoading,
    isError,
  } = useVehicleTasksQuery(vehicleId, {
    open: 'true',
  });

  if (!hasPermission('read:tasks')) {
    return <></>;
  }

  if (isError) {
    return (
      <section>
        <OpenVehicleTasksHeader vehicleId={vehicleId} count={tasks.length} />
        <ListErrorMessageCard>
          <FormattedMessage {...messages.error} />
        </ListErrorMessageCard>
      </section>
    );
  }

  return (
    <section data-testid="VEHICLE_OPEN_TASKS">
      <OpenVehicleTasksHeader vehicleId={vehicleId} count={tasks.length} />
      <List
        loadingRows={2}
        isLoading={isLoading}
        idProp="id"
        data={tasks}
        renderEmptyMessage={
          <EmptyListMessageCard>
            <FormattedMessage {...messages.noOpenTasks} />
          </EmptyListMessageCard>
        }
        renderLoadingRow={<OpenVehicleTaskCardLoading />}
        renderRow={(task: VehicleTaskListItem) => (
          <OpenVehicleTaskCard task={task} />
        )}
      />
    </section>
  );
};
