import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { FormCheckboxField, useFormContext } from '@cooltra/form';

import { PromotionStateType } from '../../PromotionState/PromotionState';
import { PromotionFormValues } from '../../PromotionForm';

import messages from './messages';

export const UserCanRedeemMoreThanOneCode = () => {
  const { values } = useFormContext<PromotionFormValues>();
  const { editMode, stateType, promotionCodeMode } = values;
  const editingExpiredPromotion =
    editMode && stateType === PromotionStateType.EXPIRED;

  if (promotionCodeMode === 'single-code') {
    return <></>;
  }

  return (
    <FormCheckboxField
      id="userCanRedeemMoreThanOneCode"
      disabled={editingExpiredPromotion}
      label={
        <InputLabel emphasis="low" htmlFor="userCanRedeemMoreThanOneCode">
          <FormattedMessage {...messages.userCanRedeemMoreThanOneCode} />
        </InputLabel>
      }
      name="userCanRedeemMoreThanOneCode"
    />
  );
};
