import { useInvoiceAllMutation } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { useToggle } from '@cooltra/hooks';
import { Button } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';

import { ConfirmModal } from '~/common';
import { useNotification } from '~/hooks';

import messages from './messages';

export type InvoiceAllProps = {
  userId: string;
};

export const InvoiceAll = ({ userId }: InvoiceAllProps) => {
  const { hasPermission } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();
  const { addErrorNotification } = useNotification();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const { isPending, mutateAsync } = useInvoiceAllMutation(userId);

  const handleOnConfirm = () => {
    mutateAsync()
      .then(() => toggleOff())
      .catch(() => addErrorNotification());
  };

  if (!hasPermission('write:invoices')) {
    return null;
  }

  return (
    <>
      <Button emphasis="high" onClick={toggleOn}>
        <FormattedMessage {...messages.invoiceAll} />
      </Button>
      <ConfirmModal
        isOpen={isOpen}
        title={formatMessage(messages.modalTitle)}
        content={formatMessage(messages.modalContent)}
        onClose={toggleOff}
        onConfirm={handleOnConfirm}
        loading={isPending}
      />
    </>
  );
};
