import { defineMessages } from 'react-intl';

export default defineMessages({
  scooters: {
    defaultMessage: 'Scooters',
  },
  eBikes: {
    defaultMessage: 'E-Bikes',
  },
});
