import { Placeholder } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type MoreReasonsTakesProps = {
  moreReasonsTakes?: number;
};

export const MoreReasonsTakes = ({
  moreReasonsTakes,
}: MoreReasonsTakesProps) => {
  return (
    <div className="flex flex-col">
      {moreReasonsTakes !== undefined ? (
        <span className="text-md text-neutral-1000 font-semibold">
          {moreReasonsTakes}
        </span>
      ) : (
        <Placeholder className="w-6 h-5 mx-auto mb-1" />
      )}
      <span className="text-xs text-neutral-300">
        <FormattedMessage {...messages.moreReasonsTakes} />
      </span>
    </div>
  );
};
