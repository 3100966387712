import { defineMessages } from 'react-intl';

import { CouponType } from '../../StationBasedForm';

export default defineMessages<CouponType>({
  CREDIT: {
    defaultMessage: 'Credit',
  },
  DISCOUNT: {
    defaultMessage: 'Discount',
  },
});
