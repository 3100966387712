import { HTMLAttributes } from 'react';
import { classNames } from '@cooltra/utils';

export type LabelProps = HTMLAttributes<HTMLSpanElement>;

export const Label = ({ className, ...rest }: LabelProps) => (
  <span
    className={classNames(
      'block text-neutral-400 text-xs leading-5',
      className
    )}
    {...rest}
  />
);
