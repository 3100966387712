import { defineMessages } from 'react-intl';
import { ContractServiceType } from '@cooltra/station-based-api';

export default defineMessages({
  all: {
    defaultMessage: 'All',
  },
  serviceType: {
    defaultMessage: 'Delivery/Return',
  },
  servicePoint: {
    defaultMessage: 'Service point',
  },
  status: {
    defaultMessage: 'Status',
  },
  clearFilters: {
    defaultMessage: 'Clear filters',
  },
  showContracts: {
    defaultMessage: 'Show contracts',
  },
});

export const serviceTypesMessages = defineMessages<ContractServiceType | 'ALL'>(
  {
    ALL: {
      defaultMessage: 'All',
    },
    DROP_OFF: {
      defaultMessage: 'Return',
    },
    PICK_UP: {
      defaultMessage: 'Delivery',
    },
  }
);
