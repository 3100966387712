import { TaskColumnLabel } from '~/common';

import { VehicleTaskRowLayout } from './VehicleTaskRowLayout';

export const VehicleTasksListHeader = () => (
  <VehicleTaskRowLayout
    task={<TaskColumnLabel name="task" />}
    assignee={<TaskColumnLabel name="assignee" />}
    updated={<TaskColumnLabel name="updated" />}
    created={<TaskColumnLabel name="created" />}
  />
);
