import { RouterButton } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { MdOutlineChevronRight } from 'react-icons/md';

import { UsersAwaitingReviewSummary } from './UsersAwaitingReviewSummary';
import messages from './messages';

export const UsersOverview = () => {
  return (
    <div className="w-[34rem] py-12 mx-auto">
      <UsersAwaitingReviewSummary />
      <div className="w-full mt-11 text-center">
        <RouterButton
          emphasis="high"
          to="/users-reviews"
          data-testid="USERS_REVIEW_LINK"
          trailingIcon={<MdOutlineChevronRight className="text-xl" />}
        >
          <FormattedMessage {...messages.reviewUsers} />
        </RouterButton>
      </div>
    </div>
  );
};
