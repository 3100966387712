import { Placeholder } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';

export type SidebarIconItemLoadingProps = {
  className?: string;
};

export const SidebarIconItemLoading = ({
  className,
}: SidebarIconItemLoadingProps) => (
  <div className={classNames('flex', 'items-center', 'gap-2', className)}>
    <Placeholder className="w-4 h-4 rounded-full" />
    <Placeholder className="h-3 w-16" />
  </div>
);
