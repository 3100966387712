import { FormattedMessage } from 'react-intl';

import { ByOperator } from '../Operator';
import { ByUser } from '../User';
import { DateTime } from '../DateTime';
import { Pair } from '../Pair';

import { LogAction } from './types';
import messages from './messages';

export type LogProps = {
  action: LogAction;
  user?: 'customer' | 'operator';
  date?: string | null;
  userId?: string | null;
};

export const Log = ({ action, user, userId, date }: LogProps) => (
  <div>
    <Pair.Label className="mb-1">
      <FormattedMessage {...messages[action]} />
    </Pair.Label>
    <DateTime date={date} />
    {user && userId ? (
      user === 'customer' ? (
        <ByUser id={userId} />
      ) : (
        <ByOperator id={userId} />
      )
    ) : null}
  </div>
);
