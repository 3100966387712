import { defineMessages } from 'react-intl';

export default defineMessages({
  next: {
    defaultMessage: 'Next',
  },
  expired: {
    defaultMessage: 'Expired',
  },
  active: {
    defaultMessage: 'Active',
  },
});
