import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Current activity',
  },
  noCurrentActivity: {
    defaultMessage: 'This user has no active reservation or rental',
  },
  error: {
    defaultMessage: "There has been a problem loading user's current activity",
  },
});
