import { defineMessages } from 'react-intl';

export default defineMessages({
  allPricingGroups: {
    defaultMessage: 'All pricing groups',
  },
  pricingGroup: {
    defaultMessage: 'Pricing group',
  },
});
