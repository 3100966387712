import { InvoiceLegalEntity as ApiInvoiceLegalEntity } from '@cooltra/api';
import { HTMLAttributes } from 'react';

const legalEntityNames: Record<ApiInvoiceLegalEntity, string> = {
  COOLTRA_SPAIN: 'Cooltra Motosharing S.L.U.',
  COOLTRA_FRANCE: 'Cooltra Scooter Sharing France SARL',
  COOLTRA_ITALY: 'eCooltra Scootersharing Italia srl',
  COOLTRA_GERMANY: '',
  COOLTRA_PORTUGAL: 'eCooltra Scootersharing PT',
  COOLTRA_SWITZERLAND: '',
  FELYX_NETHERLANDS: 'Felyx Netherlands B.V.',
  FELYX_BELGIUM: 'Felyx Belgium BV',
};

export type InvoiceLegalEntityProps = Omit<
  HTMLAttributes<HTMLSpanElement>,
  'children'
> & {
  legalEntity: ApiInvoiceLegalEntity;
};

export const InvoiceLegalEntity = ({
  legalEntity,
  ...rest
}: InvoiceLegalEntityProps) => (
  <span {...rest}>{legalEntityNames[legalEntity]}</span>
);
