import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';
import { useLocation } from 'react-router-dom';
import { getParams } from '@cooltra/utils';

import {
  vehicleTasksFiltersInitialValues,
  VehicleTasksFiltersFormValues,
} from './vehicle-tasks-filters-form';

const { minDate, maxDate, open } = vehicleTasksFiltersInitialValues;

export const VehicleTasksFiltersForm = ({ children }: PropsWithChildren) => {
  const { search } = useLocation();
  const params = getParams<keyof VehicleTasksFiltersFormValues>(search);

  const initialValues = {
    minDate: params.string('minDate') || minDate,
    maxDate: params.string('maxDate') || maxDate,
    open: params.string('open') || open,
  } as VehicleTasksFiltersFormValues;

  return <FormProvider initialValues={initialValues}>{children}</FormProvider>;
};
