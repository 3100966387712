import { Divider } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { RouterSidebarNavLink } from '@cooltra/navigation';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import { SidebarLinkContent, SidebarHeading } from '~/common';

import messages from './messages';

export const Sidebar = () => {
  const { hasPermission } = useAuthClaimsQuery();

  return (
    <div className="w-72 pt-14 pb-20" data-testid="SETTINGS_SIDEBAR">
      <div className="px-8 mt-14 mb-7">
        <SidebarHeading className="mb-4">
          <FormattedMessage {...messages.settings} />
        </SidebarHeading>
      </div>
      <ul>
        {hasPermission('read:locations') && (
          <li>
            <Divider />
            <RouterSidebarNavLink to="locations">
              <SidebarLinkContent>
                <FormattedMessage {...messages.locations} />
              </SidebarLinkContent>
            </RouterSidebarNavLink>
          </li>
        )}
        <li>
          <Divider />
          <RouterSidebarNavLink to="tags">
            <SidebarLinkContent>
              <FormattedMessage {...messages.tags} />
            </SidebarLinkContent>
          </RouterSidebarNavLink>
          <Divider />
        </li>
      </ul>
    </div>
  );
};
