import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Divider } from '@cooltra/ui';
import {
  Contract,
  useSendPickUpInstructionsEmailMutation,
  useSendDropOffInstructionsEmailMutation,
} from '@cooltra/station-based-api';
import { MdSend } from 'react-icons/md';

import { useNotification } from '~/hooks';

import { VehicleGeolocation } from '../VehicleGeolocation/VehicleGeolocation';
import { ParkingPhoto } from '../ParkingPhoto/ParkingPhoto';

import messages from './messages';

export type ContractClickAndRideProps = {
  contract: Contract;
};

export const ContractClickAndRide = ({
  contract,
}: ContractClickAndRideProps) => {
  const { formatMessage } = useIntl();
  const {
    pickUp = null,
    dropOff = null,
    vehicleId = '',
    lockCode = '',
  } = contract.vehicle?.assignedVehicle || {};

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const sendPickUpInstructionsQuery = useSendPickUpInstructionsEmailMutation(
    contract.contractId
  );

  const sendDropOffInstructionsQuery = useSendDropOffInstructionsEmailMutation(
    contract.contractId
  );

  const handleSendingPickUpEmail = () =>
    sendPickUpInstructionsQuery
      .mutateAsync()
      .then(() =>
        addSuccessNotification(
          formatMessage(messages.pickUpEmailSuccessfullySent)
        )
      )
      .catch(() => addErrorNotification());

  const handleSendingDropOffInstructions = () =>
    sendDropOffInstructionsQuery
      .mutateAsync()
      .then(() =>
        addSuccessNotification(
          formatMessage(messages.dropOffEmailSuccessfullySent)
        )
      )
      .catch(() => addErrorNotification());

  const canSendPickUpInstructions =
    pickUp &&
    lockCode &&
    (contract.status === 'ACTIVE' || contract.status === 'BOOKED');

  const canSendDropOffInstructions =
    pickUp && lockCode && contract.status === 'ACTIVE';

  return (
    <div className="mx-auto max-w-4xl pt-4 pb-10">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-xl text-neutral-700 notranslate">Click & Ride</h1>
        <div className="flex gap-4 items-center">
          <Button
            onClick={handleSendingPickUpEmail}
            disabled={!canSendPickUpInstructions}
            loading={sendPickUpInstructionsQuery.isPending}
            size="sm"
            leadingIcon={<MdSend />}
          >
            <FormattedMessage {...messages.sendPickUpInstructions} />
          </Button>
          <Button
            onClick={handleSendingDropOffInstructions}
            disabled={!canSendDropOffInstructions}
            loading={sendDropOffInstructionsQuery.isPending}
            size="sm"
            leadingIcon={<MdSend />}
          >
            <FormattedMessage {...messages.sendDropOffInstructions} />
          </Button>
        </div>
      </div>
      <Card className="p-8  gap-7">
        <span className="text-neutral-700">
          <FormattedMessage {...messages.vehicleLockCode} />
          <span className="font-semibold ml-2">
            {lockCode ? lockCode : '-'}
          </span>
        </span>
        <Divider className="mb-6 mt-4" />
        <div
          className="flex flex-row gap-5 justify-between"
          data-testid="PICK_UP"
        >
          <div className="max-w-xs w-full h-full">
            <h2 className="font-semibold text-base text-neutral-800 mb-5">
              <FormattedMessage {...messages.pickUpPicture} />
            </h2>
            <div className="h-[444px] bg-neutral-100 rounded-lg overflow-hidden flex items-center justify-center text-center text-sm text-neutral-400">
              {pickUp?.parkingPhoto ? (
                <ParkingPhoto imageId={pickUp.parkingPhoto} />
              ) : (
                <FormattedMessage {...messages.noParkingPhoto} />
              )}
            </div>
          </div>
          <div className="max-w-xl w-full">
            <h2 className="font-semibold text-base text-neutral-800 mb-5">
              <FormattedMessage {...messages.pickUpGeolocation} />
            </h2>
            <div className="bg-neutral-100 h-[444px] overflow-hidden flex items-center justify-center text-center text-neutral-400 text-sm rounded-md">
              <VehicleGeolocation
                geolocation={pickUp?.geolocation || null}
                vehicleId={vehicleId}
              />
            </div>
          </div>
        </div>
        <Divider className="my-8" />
        <div
          className="flex flex-row gap-5 justify-between"
          data-testid="DROP_OFF"
        >
          <div className="max-w-xs w-full h-full">
            <h2 className="font-semibold text-base text-neutral-800 mb-5">
              <FormattedMessage {...messages.dropOffPicture} />
            </h2>
            <div className="h-[444px] bg-neutral-100 rounded-lg overflow-hidden flex items-center justify-center text-center text-sm text-neutral-400">
              {dropOff?.parkingPhoto ? (
                <ParkingPhoto imageId={dropOff.parkingPhoto} />
              ) : (
                <FormattedMessage {...messages.noParkingPhoto} />
              )}
            </div>
          </div>
          <div className="max-w-xl w-full">
            <h2 className="font-semibold text-base text-neutral-800 mb-5">
              <FormattedMessage {...messages.dropOffGeolocation} />
            </h2>
            <div className="bg-neutral-100 h-[444px] overflow-hidden flex items-center justify-center text-center text-neutral-400 text-sm rounded-md">
              <VehicleGeolocation
                geolocation={dropOff?.geolocation || null}
                vehicleId={vehicleId}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
