import { Placeholder } from '@cooltra/ui';
import { useVehicleStatusQuery } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { TimeAgo } from '~/common';

import messages from './messages';

export type LastSyncListItemProps = {
  vehicleId: string;
};

export const LastSyncListItem = ({ vehicleId }: LastSyncListItemProps) => {
  const {
    data: vehicleStatus,
    isLoading,
    isError,
  } = useVehicleStatusQuery(vehicleId, {
    enabled: !!vehicleId,
    retry: false,
  });

  if (isError || !vehicleStatus) {
    return <></>;
  }

  return (
    <li className="py-4 border-b border-solid border-neutral-100">
      <div className="flex justify-between items-center gap-4">
        <span className="text-sm text-neutral-400">
          <FormattedMessage {...messages.lastSync} />
        </span>
        {isLoading ? (
          <Placeholder className="w-32 h-3" />
        ) : (
          <p className="text-sm text-neutral-300">
            <TimeAgo ISODate={vehicleStatus?.updatedAt} />
          </p>
        )}
      </div>
    </li>
  );
};
