import { RentalListItem, useRentalsQuery, useRentalsTotal } from '@cooltra/api';
import { InfiniteList } from '@cooltra/ui';
import { useParams } from 'react-router-dom';
import { useFormContext } from '@cooltra/form';
import { useEffect } from 'react';

import {
  ErrorPage,
  FilterFields,
  NoSearchResults,
  RentalsTotal,
} from '~/common';
import { useNavigateWithQueryParams } from '~/hooks';

import { VehicleRentalsListHeader } from './VehicleRentalsListHeader';
import {
  VehicleRentalCard,
  VehicleRentalCardLoading,
} from './VehicleRentalCard';
import { VehicleRentalsFiltersFormValues } from './vehicle-rentals-filters-form';

export const VehicleRentalsList = () => {
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const { vehicleId = '' } = useParams<'vehicleId'>();

  const { values } = useFormContext<VehicleRentalsFiltersFormValues>();

  const { isError, isLoading, data, fetchNextPage, isFetchingNextPage } =
    useRentalsQuery({ vehicleId, ...values }, { enabled: !!vehicleId });

  useEffect(() => {
    navigateWithQueryParams(values);
  }, [navigateWithQueryParams, values]);

  const rentalsTotal = useRentalsTotal({ vehicleId, ...values }) || 0;

  const rentals = data?.pages
    ? ([] as RentalListItem[]).concat.apply([], data.pages)
    : [];

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div
      className="container min-w-3xl max-w-5xl py-12"
      data-testid="VEHICLE_RENTALS"
    >
      <div className="flex mb-10">
        <FilterFields.Created />
      </div>
      <RentalsTotal loading={isLoading} total={rentalsTotal} className="mb-6" />
      <InfiniteList
        isLoading={isLoading || !rentals}
        idProp="rentalId"
        fetchMore={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        data={rentals}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<VehicleRentalsListHeader />}
        renderLoadingRow={<VehicleRentalCardLoading />}
        renderRow={(rental: RentalListItem) => (
          <VehicleRentalCard {...rental} />
        )}
      />
    </div>
  );
};
