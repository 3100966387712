import { defineMessages } from 'react-intl';

export default defineMessages({
  restriction: {
    defaultMessage: 'Restriction',
  },
  bonusZone: {
    defaultMessage: 'Bonus zone',
  },
});
