import { defineMessages } from 'react-intl';

export default defineMessages({
  personalDetails: {
    defaultMessage: 'Personal details',
  },
  documents: {
    defaultMessage: 'Documents',
  },
  billingAddress: {
    defaultMessage: 'Billing address',
  },
});
