import { defineMessages } from 'react-intl';

export default defineMessages({
  migratedFromEtendo: {
    defaultMessage: 'Migrated from Etendo',
  },
  continueInEtendo: {
    defaultMessage: 'Continue in Etendo',
  },
});
