import { IntlShape } from 'react-intl';
import { Task } from '@cooltra/api';

import { validateRequired, createSetError } from '~/validation';

export type TaskFormValues = Pick<
  Task,
  | 'title'
  | 'operatorGroupId'
  | 'operatorId'
  | 'tagIds'
  | 'description'
  | 'blocking'
>;

type TaskFormFieldName = keyof TaskFormValues;

type TaskFormErrors = Partial<Record<TaskFormFieldName, string | undefined>>;

export const validateTaskForm =
  (intl: IntlShape) => (values: TaskFormValues) => {
    const errors: TaskFormErrors = {};
    const setError = createSetError(errors);

    const titleErrorMessage = validateRequired(intl, values.title);
    setError('title', titleErrorMessage);

    const operatorGroupIdErrorMessage = validateRequired(
      intl,
      values.operatorGroupId
    );
    setError('operatorGroupId', operatorGroupIdErrorMessage);

    return errors;
  };

export const taskInitialFormValues: TaskFormValues = {
  title: '',
  operatorGroupId: '',
  operatorId: '',
  tagIds: [],
  description: '',
  blocking: false,
};
