import { ReactNode } from 'react';

export type VehicleTechnicalInspectionRowLayoutProps = {
  scheduledAt: ReactNode;
  serviced: ReactNode;
  state: ReactNode;
};

export const VehicleTechnicalInspectionRowLayout = ({
  scheduledAt,
  serviced,
  state,
}: VehicleTechnicalInspectionRowLayoutProps) => (
  <div className="flex items-center gap-4">
    <div className="flex-1 shrink-0">{scheduledAt}</div>
    <div className="flex-1 shrink-0">{serviced}</div>
    <div className="w-64 shrink-0 flex justify-center">{state}</div>
  </div>
);
