import { useIntl, FormattedMessage } from 'react-intl';
import { MdLockOutline } from 'react-icons/md';

import { ErrorPage } from '~/common';

import messages from './messages';

export const ReviewLocked = () => {
  const { formatMessage } = useIntl();
  return (
    <ErrorPage
      icon={<MdLockOutline />}
      title={formatMessage(messages.title)}
      error={
        <FormattedMessage
          {...messages.content}
          values={{
            duration: (
              <span className="font-bold">
                <FormattedMessage {...messages.duration} />
              </span>
            ),
          }}
        />
      }
    />
  );
};
