import { useRouteState } from '@cooltra/navigation';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { BackLink, RouterBackLink } from '../../BackLink';

import messages from './messages';

export type IncidentBackLinkProps = {
  problemReportId: string | null;
};

export const IncidentBackLink = ({
  problemReportId,
}: IncidentBackLinkProps) => {
  const routeState = useRouteState<'from'>();
  const navigate = useNavigate();

  const navigateBack = () => navigate(-1);

  if (!routeState?.from && !problemReportId) {
    return (
      <RouterBackLink to="/incidents">
        <FormattedMessage {...messages.backToIncidentList} />
      </RouterBackLink>
    );
  }

  if (routeState?.from) {
    return <BackLink onClick={navigateBack} />;
  }

  return (
    <RouterBackLink to={`/problem-reports/${problemReportId}`}>
      <FormattedMessage {...messages.backToProblemReport} />
    </RouterBackLink>
  );
};
