import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';
import {
  CompleteVehiclesTechnicalInspectionsInBulkPayload,
  useCompleteVehiclesTechnicalInspectionsInBulkMutation,
} from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import {
  serviceInitialFormValues,
  TechnicalInspectionFormProvider,
  TechnicalInspectionFormValues,
} from '~/common';
import { useNotification } from '~/hooks';

import { CreateTechnicalInspectionModal } from './CreateTechnicalInspectionModal';
import { editServiceFormValidation } from './edit-service-form-validation';
import messages from './messages';

export type CreateTechnicalInspectionProps = {
  vehicleIds: string[];
  refetchVehicles: () => void;
};

export const CreateTechnicalInspection = ({
  vehicleIds,
  refetchVehicles,
}: CreateTechnicalInspectionProps) => {
  const intl = useIntl();
  const [isOpen, { toggleOff, toggleOn }] = useToggle();
  const { hasPermission } = useAuthClaimsQuery();

  const { mutateAsync, isPending } =
    useCompleteVehiclesTechnicalInspectionsInBulkMutation();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  if (!hasPermission('bulk:actions') || !hasPermission('write:services')) {
    return <></>;
  }

  const handleOnSubmit = (values: TechnicalInspectionFormValues) => {
    const payload: CompleteVehiclesTechnicalInspectionsInBulkPayload = {
      vehicleIds,
      scheduledAt: values.scheduledAt,
      servicedAt: values.servicedAt,
      servicedBy: values.servicedBy,
    };

    return mutateAsync(payload)
      .then(() => {
        toggleOff();
        addSuccessNotification(intl.formatMessage(messages.success));
        refetchVehicles();
      })
      .catch(() => {
        addErrorNotification(
          intl.formatMessage(messages.technicalInspectionWereNotSavedProperly)
        );
      });
  };

  const isActionDisabled = vehicleIds.length === 0;

  return (
    <>
      <TechnicalInspectionFormProvider
        onSubmit={handleOnSubmit}
        validate={editServiceFormValidation(intl)}
        initialValues={serviceInitialFormValues}
      >
        <CreateTechnicalInspectionModal
          onClose={toggleOff}
          isOpen={isOpen}
          loading={isPending}
        />
      </TechnicalInspectionFormProvider>
      <Button
        emphasis="high"
        size="sm"
        onClick={toggleOn}
        disabled={isActionDisabled}
      >
        <FormattedMessage {...messages.createTechnicalInspection} />
      </Button>
    </>
  );
};
