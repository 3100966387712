import { Card, Placeholder } from '@cooltra/ui';

import { UserRowLayout } from '../UserRowLayout';

export const UserCardLoading = () => (
  <Card className="h-28 py-0.5 flex">
    <UserRowLayout
      system={<Placeholder className="w-28 h-2" />}
      name={
        <>
          <Placeholder className="h-2 w-44 mb-3" />
          <Placeholder className="h-5 w-20" />
        </>
      }
      provider={
        <div>
          <Placeholder className="h-2 w-32" />
        </div>
      }
      status={
        <div className="text-center">
          <Placeholder className="h-6 w-20 mx-auto rounded-full" />
        </div>
      }
    />
  </Card>
);
