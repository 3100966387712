import { BadgePlaceholder, Card, Placeholder } from '@cooltra/ui';

import { PackRowLayout } from '../PackRowLayout';

export const PackCardLoading = () => (
  <Card>
    <PackRowLayout
      className="min-h-24 py-4"
      code={
        <div className="flex flex-col gap-3 py-0.5">
          <Placeholder className="w-24 h-2" />
          <Placeholder className="w-40 h-2" />
        </div>
      }
      price={<Placeholder className="h-2 w-12" />}
      credit={<Placeholder className="h-2 w-12" />}
      validFrom={
        <div className="flex flex-col gap-3 py-0.5">
          <Placeholder className="w-24 h-2" />
          <Placeholder className="w-16 h-2" />
        </div>
      }
      validUntil={
        <div className="flex flex-col gap-3 py-0.5">
          <Placeholder className="w-24 h-2" />
          <Placeholder className="w-16 h-2" />
        </div>
      }
      state={<BadgePlaceholder className="w-20" />}
    />
  </Card>
);
