import { ReactNode } from 'react';
import { useAuthClaimsQuery, Permission } from '@cooltra/auth-api';

import { Four0Three } from '../ErrorPage';

export type PermissionCheckProps = {
  children: ReactNode;
  permission: Permission;
};

export const PermissionCheck = ({
  children,
  permission,
}: PermissionCheckProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  if (!hasPermission(permission)) {
    return <Four0Three />;
  }

  return <>{children}</>;
};
