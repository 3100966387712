import { useState } from 'react';
import { useContractQuery } from '@cooltra/station-based-api';

import { usePolling } from '~/hooks';

export type UsePayTicketPollingProps = {
  contractId: string;
  onSettle: () => void;
};

export const useWaitAnnexToBePaidPolling = ({
  onSettle,
  contractId,
}: UsePayTicketPollingProps) => {
  const [
    isWaitAnnexToBePaidPollingActive,
    setIsWaitAnnexToBePaidPollingActive,
  ] = useState(false);
  const { data: contract, refetch: refetchContract } =
    useContractQuery(contractId);

  const hasPendingPayAnnex = contract?.annexes?.some(
    (annex) => annex.status === 'PENDING_PAY'
  );

  const { isPolling } = usePolling({
    fn: refetchContract,
    onSettle: onSettle,
    maxAttempts: 5,
    interval: hasPendingPayAnnex && isWaitAnnexToBePaidPollingActive ? 500 : 0,
  });

  return {
    isPolling,
    setIsWaitAnnexToBePaidPollingActive,
  };
};
