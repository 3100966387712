import { useTaskQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { useParams } from 'react-router-dom';
import { SpinnerScreen } from '@cooltra/ui';

import { ErrorPage, Four0Three } from '~/common';
import { useVehicleQuery } from '~/hooks';

import { EditTask } from '../EditTask';
import { ViewTask } from '../ViewTask/ViewTask';

export const Task = () => {
  const { taskId = '' } = useParams<'taskId'>();
  const { hasPermission } = useAuthClaimsQuery();
  const { data: task, ...taskQuery } = useTaskQuery(taskId);
  const { data: vehicle, ...vehicleQuery } = useVehicleQuery(
    task?.vehicleId || '',
    {
      enabled: !!task?.vehicleId,
    }
  );

  if (
    (taskQuery.isError && getErrorStatus(taskQuery.error) === 403) ||
    (vehicleQuery.isError && getErrorStatus(vehicleQuery.error) === 403)
  ) {
    return <Four0Three />;
  }

  if (taskQuery.isError || vehicleQuery.isError) {
    return <ErrorPage />;
  }

  if (hasPermission('write:tasks')) {
    return task && vehicle ? (
      <EditTask task={task} vehicle={vehicle} />
    ) : (
      <SpinnerScreen />
    );
  }

  return task && vehicle ? (
    <ViewTask task={task} vehicle={vehicle} />
  ) : (
    <SpinnerScreen />
  );
};
