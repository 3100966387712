import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
  },
  close: {
    defaultMessage: 'Close',
  },
  confirm: {
    defaultMessage: 'Cancel charge',
  },
  success: {
    defaultMessage: 'Pending charge has been removed successfully',
  },
  cancelAwaitingPaymentTitle: {
    defaultMessage: 'Remove pending charge',
  },
  cancelAwaitingPaymentContent: {
    defaultMessage: 'Are you sure you want to cancel this pending charge?',
  },
});
