import { parse } from 'date-fns';
import {
  CreatePromotionCouponPayload,
  CreatePromotionWebOfferPayload,
} from '@cooltra/station-based-api';

import { dateAndTimeFormat } from '~/utils/date';
import { StationBasedFormValues } from '~/common';
import { clean } from '~/utils/objects';

const getISODate = (
  date: string | undefined,
  time: string | undefined
): string => {
  if (!date || !time) {
    return '';
  }

  return parse(`${date} ${time}`, dateAndTimeFormat, new Date()).toISOString();
};

const formatPromotionTranslations = (values: StationBasedFormValues) => {
  const titles = {
    en: values['titleTranslations.en'],
    es: values['titleTranslations.es'],
    it: values['titleTranslations.it'],
    pt: values['titleTranslations.pt'],
    fr: values['titleTranslations.fr'],
    ca: values['titleTranslations.ca'],
    de: values['titleTranslations.de'],
  };
  const titleTranslations = clean(titles);

  return {
    titleTranslations,
  };
};

export const getCreatePromotionWebOfferPayload = (
  values: StationBasedFormValues
): CreatePromotionWebOfferPayload => {
  const translations = formatPromotionTranslations(values);

  return {
    target: values.promotionTarget,
    about: values.about,
    discount: Number(values.discount),
    validFrom: getISODate(values.validFromDate, values.validFromTime),
    validUntil: getISODate(values.validUntilDate, values.validUntilTime),
    pricingGroups: values.pricingGroups,
    vehicleTypes:
      values.enableVehicleTypes === 'VEHICLE_TYPES_YES'
        ? values.vehicleTypes
        : null,
    accessories:
      values.enableAccessories === 'ACCESSORIES_YES'
        ? values.accessories
        : null,
    insurances:
      values.enableInsurances === 'INSURANCES_YES' ? values.insurances : null,
    ...translations,
  };
};

export const getCreatePromotionCouponPayload = (
  values: StationBasedFormValues
): CreatePromotionCouponPayload => {
  const translations = formatPromotionTranslations(values);

  const isDiscountCoupon = values.couponType === 'DISCOUNT';
  const isCreditCoupon = values.couponType === 'CREDIT';

  return {
    target: values.promotionTarget,
    about: values.about,
    discount: isDiscountCoupon ? Number(values.discount) : null,
    validFrom: getISODate(values.validFromDate, values.validFromTime),
    validUntil: getISODate(values.validUntilDate, values.validUntilTime),
    pricingGroups: values.pricingGroups,
    vehicleTypes:
      values.enableGenericAppliesTo === 'enableVehicleTypes'
        ? values.vehicleTypes
        : null,
    accessories:
      values.enableGenericAppliesTo === 'enableAccessories'
        ? values.accessories
        : null,
    insurances:
      values.enableGenericAppliesTo === 'enableInsurances'
        ? values.insurances
        : null,
    code: values.code,
    credit: isCreditCoupon
      ? {
          value: Number(values['credit.value']),
          currency: 'EUR',
        }
      : null,
    ...translations,
  };
};
