import { Route, Routes } from 'react-router-dom';

import { Four0Four } from '~/common';

import { SubscriptionCharges } from './SubscriptionCharges/SubscriptionCharges';

export const Pricing = () => (
  <Routes>
    <Route path="/" element={<SubscriptionCharges />} />
    <Route path="*" element={<Four0Four />} />
  </Routes>
);
