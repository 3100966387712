import { Placeholder } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { useLocationName, useVehicleQuery } from '~/hooks';

import messages from './messages';

export type LocationListItemProps = {
  vehicleId: string;
};

export const LocationListItem = ({ vehicleId }: LocationListItemProps) => {
  const { data: vehicle, isLoading: isVehicleLoading } = useVehicleQuery(
    vehicleId,
    { enabled: !!vehicleId }
  );

  const locationName = useLocationName(vehicle?.locationId);

  const renderLocation = () => {
    if (isVehicleLoading) {
      return <Placeholder className="w-32 h-3" />;
    }

    if (locationName) {
      return (
        <span className="text-sm text-neutral-500 pl-2 truncate">
          {locationName}
        </span>
      );
    }

    return (
      <span className="text-neutral-300 text-sm">
        <FormattedMessage {...messages.withoutLocation} />
      </span>
    );
  };

  return (
    <li className="py-4 border-b border-solid border-neutral-100">
      <div className="flex justify-between items-center gap-4">
        <span className="text-sm text-neutral-400">
          <FormattedMessage {...messages.location} />
        </span>
        {renderLocation()}
      </div>
    </li>
  );
};
