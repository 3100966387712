import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type UninvoicedChargeRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  createdAt: ReactNode;
  rentalId: ReactNode;
  legalEntity: ReactNode;
  total: ReactNode;
  price: ReactNode;
  state?: ReactNode;
};

export const UninvoicedChargeRowLayout = ({
  createdAt,
  rentalId,
  legalEntity,
  total,
  price,
  state,
  className,
  ...rest
}: UninvoicedChargeRowLayoutProps) => (
  <div
    className={classNames('px-6 flex items-center gap-4', className)}
    {...rest}
  >
    <div className="w-36 shrink-0">{createdAt}</div>
    <div className="w-32 shrink-0">{rentalId}</div>
    <div className="flex-1 shrink-0">{legalEntity}</div>
    <div className="w-20 shrink-0 text-right">{total}</div>
    <div className="w-28 shrink-0 text-right pr-8">{price}</div>
    <>
      {state ? (
        <div className="w-20 shrink-0">{state}</div>
      ) : (
        <div className="w-20 shrink-0" />
      )}
    </>
  </div>
);
