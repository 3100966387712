import { List } from '@cooltra/ui';
import { UserDiscountListItem, useUserDiscountsQuery } from '@cooltra/api';

import { ErrorPage } from '~/common';

import { NoDiscounts } from '../NoDiscounts/NoDiscounts';
import { DiscountsListHeader } from '../DiscountsListHeader/DiscountsListHeader';
import { DiscountCard, DiscountCardLoading } from '../DiscountCard';

export type DiscountsProps = {
  userId: string;
};

export const Discounts = ({ userId }: DiscountsProps) => {
  const { data: discounts, isLoading, isError } = useUserDiscountsQuery(userId);

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div data-testid="USER_DISCOUNTS" className="pt-10">
      <List
        loadingRows={4}
        isLoading={isLoading}
        idProp="id"
        data={discounts || []}
        renderEmptyMessage={<NoDiscounts />}
        renderHeader={<DiscountsListHeader />}
        renderLoadingRow={<DiscountCardLoading />}
        renderRow={(discount: UserDiscountListItem) => (
          <DiscountCard discount={discount} />
        )}
      />
    </div>
  );
};
