import { Button } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';
import { useCancelContractPendingDepositMutation } from '@cooltra/station-based-api';
import { FormattedMessage, useIntl } from 'react-intl';

import { ConfirmModal } from '~/common';
import { useNotification } from '~/hooks';

import messages from './messages';

type CancelButtonProps = {
  contractId: string;
  depositId: string;
};

export const RemoveDepositButton = ({
  contractId,
  depositId,
}: CancelButtonProps) => {
  const intl = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle(false);
  const { mutateAsync, isPending } = useCancelContractPendingDepositMutation(
    contractId,
    depositId
  );
  const { addSuccessNotification, addErrorNotification } = useNotification();

  const cancelPendingDeposit = () =>
    mutateAsync()
      .then(() => {
        addSuccessNotification(intl.formatMessage(messages.success));
        toggleOff();
      })
      .catch(() => addErrorNotification());

  return (
    <>
      <Button onClick={toggleOn} emphasis="low" size="sm" variant="danger">
        <FormattedMessage {...messages.removeDeposit} />
      </Button>
      <ConfirmModal
        className="max-w-md min-w-xs pb-3"
        isOpen={isOpen}
        title={intl.formatMessage(messages.cancelPendingDepositTitle)}
        content={
          <p className="px-12 mt-7 mb-9 text-neutral-700 text-md">
            <FormattedMessage {...messages.cancelPendingDepositContent} />
          </p>
        }
        confirmText={<FormattedMessage {...messages.removeDeposit} />}
        onConfirm={cancelPendingDeposit}
        onClose={toggleOff}
        loading={isPending}
      />
    </>
  );
};
