import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Additional information',
  },
  label: {
    defaultMessage: "Client's preferred address",
  },
  button: {
    defaultMessage: 'Add address',
  },
});
