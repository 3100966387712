import { defineMessages } from 'react-intl';

export default defineMessages({
  activeSuspensions: {
    defaultMessage: 'Active suspensions',
  },
  clearedSuspensions: {
    defaultMessage: 'Cleared suspensions',
  },
});
