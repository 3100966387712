import { DepositMethod } from '@cooltra/station-based-api';
import { IntlShape } from 'react-intl';
import { FormErrors } from '@cooltra/form';

import { createSetError, errorMessages, validateRequired } from '~/validation';

export type DepositFormValues = {
  amount: string;
  method?: DepositMethod;
  operationNumber: string;
  lastFourDigits: string;
  isCharge: boolean;
  billingServicePointId: string;
};

export const validateMethod = (intl: IntlShape, value: any) =>
  value === undefined ? intl.formatMessage(errorMessages.required) : undefined;
export const validateString = (intl: IntlShape, value: any) =>
  value === '' ? intl.formatMessage(errorMessages.required) : undefined;

export const validateDepositForm =
  (intl: IntlShape) =>
  (values: DepositFormValues): FormErrors<DepositFormValues> => {
    const errors: FormErrors<DepositFormValues> = {};
    const setError = createSetError(errors);

    setError('method', validateMethod(intl, values.method));

    if (values.method === 'TPV') {
      setError(
        'operationNumber',
        validateRequired(intl, values.operationNumber)
      );
      setError('lastFourDigits', validateRequired(intl, values.lastFourDigits));
      setError(
        'billingServicePointId',
        validateString(intl, values.billingServicePointId)
      );
    }

    return errors;
  };
