import { IntlShape } from 'react-intl';
import { getKeys } from '@cooltra/utils';
import { languages } from '@cooltra/countries';

import {
  createSetError,
  validateDate,
  validateRequired,
  validateTime,
} from '~/validation';

import { PackFormErrors, PackFormType, PackFormValues } from './pack-form';
import messages from './messages';

type SetError = (
  errorKey: keyof PackFormValues,
  message: string | undefined
) => void;

export const validateBaseFields = (
  formType: PackFormType,
  existingCodes: string[],
  values: PackFormValues,
  intl: IntlShape,
  setError: SetError
) => {
  const codeErrorMessage = validateRequired(intl, values.code);
  setError('code', codeErrorMessage);

  if (formType === 'create' && existingCodes.includes(values.code)) {
    setError('code', intl.formatMessage(messages.duplicatePackCode));
  }

  const priceErrorMessage = validateRequired(intl, values.price);
  setError('price', priceErrorMessage);

  const creditErrorMessage = validateRequired(intl, values.credit);
  setError('credit', creditErrorMessage);
};

export const validateUsage = (
  values: PackFormValues,
  intl: IntlShape,
  setError: SetError
) => {
  const validFromDateErrorMessage = validateDate(intl, values.validFromDate);
  setError('validFromDate', validFromDateErrorMessage);

  const validFromTimeErrorMessage = validateTime(intl, values.validFromTime);
  setError('validFromTime', validFromTimeErrorMessage);

  if (!values.packDoesntExpire) {
    const validUntilDateErrorMessage = validateDate(
      intl,
      values.validUntilDate
    );
    setError('validUntilDate', validUntilDateErrorMessage);
    const validUntilTimeErrorMessage = validateTime(
      intl,
      values.validUntilTime
    );
    setError('validUntilTime', validUntilTimeErrorMessage);
  }

  if (values.benefitDoesntExpire) {
    return;
  }

  if (values.benefitExpirationMode === 'specific-date') {
    const benefitUntilDateErrorMessage = validateDate(
      intl,
      values.benefitUntilDate
    );
    setError('benefitUntilDate', benefitUntilDateErrorMessage);
    const benefitUntilTimeErrorMessage = validateTime(
      intl,
      values.benefitUntilTime
    );
    setError('benefitUntilTime', benefitUntilTimeErrorMessage);
  }

  if (values.benefitExpirationMode === 'after-days') {
    const benefitExpiresAfterDaysErrorMessage = validateRequired(
      intl,
      values.benefitExpiresAfterDays
    );
    setError('benefitExpiresAfterDays', benefitExpiresAfterDaysErrorMessage);
  }
};

const validateTitlesAndDescriptions = (
  values: PackFormValues,
  intl: IntlShape,
  setError: SetError
) => {
  const englishTitleErrorMessage = validateRequired(
    intl,
    values['titleTranslations.en']
  );
  setError('titleTranslations.en', englishTitleErrorMessage);

  const englishDescription = validateRequired(
    intl,
    values['descriptionTranslations.en']
  );
  setError('descriptionTranslations.en', englishDescription);

  getKeys(languages).forEach((isoCode) => {
    if (
      values[`descriptionTranslations.${isoCode}`] ||
      values[`titleTranslations.${isoCode}`]
    ) {
      setError(
        `titleTranslations.${isoCode}`,
        validateRequired(intl, values[`titleTranslations.${isoCode}`])
      );
      setError(
        `descriptionTranslations.${isoCode}`,
        validateRequired(intl, values[`descriptionTranslations.${isoCode}`])
      );
    }
  });
};

export const validatePackForm =
  (intl: IntlShape, formType: PackFormType, existingCodes: string[]) =>
  (values: PackFormValues) => {
    const errors: PackFormErrors = {};
    const setError = createSetError(errors);

    validateBaseFields(formType, existingCodes, values, intl, setError);
    validateUsage(values, intl, setError);
    validateTitlesAndDescriptions(values, intl, setError);

    return errors;
  };
