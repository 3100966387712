import { defineMessages } from 'react-intl';

export default defineMessages({
  orSimilar: {
    defaultMessage: '{vehicleTypeName} or similar',
  },
  dailyPrice: {
    defaultMessage: '{amount} / day',
  },
  assignLicensePlate: {
    defaultMessage: 'Assign license plate',
  },
  changeLicensePlate: {
    defaultMessage: 'Change license plate',
  },
});
