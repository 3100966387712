import { useIntl } from 'react-intl';
import { Message } from '@cooltra/ui';

import { CustomIcon } from '~/common';

import messages from './messages';

export const FinesReviewedMessage = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="container flex justify-center py-16 tc max-w-5xl">
      <Message
        icon={<CustomIcon.Motorcycle />}
        title={formatMessage(messages.title)}
        text={formatMessage(messages.content)}
      />
    </div>
  );
};
