export type ServiceFormValues = {
  scheduledMileage: string;
  scheduledAt: string;
  servicedBy: string;
  servicedAt: string;
  serviceMileage: string;
};

export const serviceInitialFormValues: ServiceFormValues = {
  scheduledMileage: '',
  scheduledAt: '',
  servicedBy: '',
  servicedAt: '',
  serviceMileage: '',
};
