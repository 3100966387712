import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type SuspensionRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  type: ReactNode;
  reason: ReactNode;
  created: ReactNode;
  clear?: ReactNode;
};

export const SuspensionRowLayout = ({
  type,
  reason,
  created,
  clear,
  className,
  ...rest
}: SuspensionRowLayoutProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  return (
    <div
      className={classNames('flex', 'h-full', 'w-full', className)}
      {...rest}
    >
      <div className="flex items-center w-full px-8 gap-4">
        <div className="w-32 shrink-0">{type}</div>
        <div className="flex-1">{reason}</div>
        <div className="w-40 shrink-0">{created}</div>
        {hasPermission('write:users') && clear && (
          <div className="w-32 shrink-0 flex justify-end">{clear}</div>
        )}
      </div>
    </div>
  );
};
