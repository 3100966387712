import { ReactNode } from 'react';

export type SidebarLayoutProps = {
  sidebar: ReactNode;
  children: ReactNode;
};

export const SidebarLayout = ({ sidebar, children }: SidebarLayoutProps) => (
  <>
    <aside className="z-10 fixed pt-14 mt-1 h-full w-72 bg-neutral-0 overflow-auto shadow-md">
      {sidebar}
    </aside>
    <main className="pl-72">{children}</main>
  </>
);
