import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { VehicleServiceRowLayout } from '../VehicleServiceRowLayout/VehicleServiceRowLayout';

import messages from './messages';

export const VehicleServicesListHeader = () => (
  <div className="px-6">
    <VehicleServiceRowLayout
      scheduledMileage={
        <ListColumnLabel>
          <FormattedMessage {...messages.scheduledMileage} />
        </ListColumnLabel>
      }
      scheduledAt={
        <ListColumnLabel>
          <FormattedMessage {...messages.scheduledAt} />
        </ListColumnLabel>
      }
      serviced={
        <ListColumnLabel>
          <FormattedMessage {...messages.serviced} />
        </ListColumnLabel>
      }
      state={
        <ListColumnLabel>
          <FormattedMessage {...messages.state} />
        </ListColumnLabel>
      }
    />
  </div>
);
