import { defineMessages } from 'react-intl';

export default defineMessages({
  personalDetails: {
    defaultMessage: 'Personal details',
  },
  contactAndAddress: {
    defaultMessage: 'Contact & Address',
  },
  submitBtn: {
    defaultMessage: 'Save changes',
  },
  submissionSuccess: {
    defaultMessage: "You have successfully updated user's personal details!",
  },
  duplicated: {
    defaultMessage: 'It has already been taken',
  },
  conflictErrorMessage: {
    defaultMessage:
      'The user details have been updated in the meantime. Please refresh the page.',
  },
});
