import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteBtn: {
    defaultMessage: 'Delete this user',
  },
  success: {
    defaultMessage: 'You have successfully deleted the user!',
  },
});
