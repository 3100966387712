import { FormattedMessage, useIntl } from 'react-intl';
import { useInsurancesQuery } from '@cooltra/station-based-api';
import { InputLabel, MultiSelectOption } from '@cooltra/ui';
import { FormMultiSelectField, useFormContext } from '@cooltra/form';
import { classNames, getInsuranceName } from '@cooltra/utils';

import {
  InsurancesOptions,
  StationBasedFormValues,
  StationBasedStateType,
} from '~/common';

import { AppliesToFormRadio } from '../AppliesToFormRadio/AppliesToFormRadio';

import messages from './messages';

type YesOrNoOptions = {
  label: string;
  value: InsurancesOptions;
}[];

export const Insurances = () => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext<StationBasedFormValues>();
  const {
    editMode,
    stateType,
    promotionTarget,
    enableInsurances,
    enableGenericAppliesTo,
  } = values;
  const editingNoScheduledPromotion =
    editMode && stateType !== StationBasedStateType.SCHEDULED;
  const { data: insurances = [], isLoading } = useInsurancesQuery();
  const options: MultiSelectOption[] = insurances.map((insurance) => ({
    label: getInsuranceName(insurance.name),
    value: insurance.id,
  }));
  const yesOrNoOptions: YesOrNoOptions = [
    {
      label: formatMessage(messages.yes),
      value: 'INSURANCES_YES',
    },
    {
      label: formatMessage(messages.no),
      value: 'INSURANCES_NO',
    },
  ];

  const disabledForWebOffer =
    promotionTarget === 'WEB_OFFER' && enableInsurances === 'INSURANCES_NO';
  const disabledForCoupon =
    promotionTarget === 'COUPON' &&
    enableGenericAppliesTo != 'enableInsurances';

  return (
    <fieldset data-testid="INSURANCES_SELECTOR">
      <InputLabel htmlFor="insurances" className="mb-2">
        <FormattedMessage {...messages.insurance} />
      </InputLabel>
      <div className="flex items-center gap-4">
        <AppliesToFormRadio
          yesOrNoOptions={yesOrNoOptions}
          name="enableInsurances"
          disabled={editingNoScheduledPromotion}
        />
        <FormMultiSelectField
          id="insurances"
          name="insurances"
          options={options}
          isLoading={isLoading}
          disabled={
            disabledForWebOffer ||
            disabledForCoupon ||
            editingNoScheduledPromotion
          }
          placeholder={formatMessage(messages.allInsurances)}
          className={classNames(
            'w-full',
            disabledForWebOffer || disabledForCoupon ? 'invisible' : ''
          )}
        />
      </div>
    </fieldset>
  );
};
