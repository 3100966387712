import { FormattedMessage } from 'react-intl';
import { VehicleListItem } from '@cooltra/api';
import { useOperatorsQuery } from '@cooltra/auth-api';
import { Placeholder } from '@cooltra/ui';

import messages from './messages';

type TakenByProps = Pick<VehicleListItem, 'takenBy'>;

export const TakenBy = ({ takenBy }: TakenByProps) => {
  const { data, isLoading } = useOperatorsQuery();

  const operatorName = data?.find(
    ({ externalId }) => takenBy === externalId
  )?.name;

  return (
    <div
      data-testid="VEHICLE_LIST_ITEM_TAKEN_BY"
      className="h-24 w-full flex flex-col justify-center"
    >
      <span className="block text-xs text-neutral-400 mb-0.5">
        <FormattedMessage {...messages.takenBy} />
      </span>
      <span className="block text-xs text-neutral-900">
        {isLoading ? <Placeholder className="h-2 mt-0.5 w-24" /> : operatorName}
      </span>
    </div>
  );
};
