import { defineMessages } from 'react-intl';

export default defineMessages({
  goToNextMonth: {
    defaultMessage: 'Go to the next month',
  },
  goToPreviousMonth: {
    defaultMessage: 'Go to the previous month',
  },
});
