import { Route, Routes } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { RouterButton, RouterTab } from '@cooltra/navigation';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Spinner } from '@cooltra/ui';

import { ErrorPage } from '~/common';

import messages from './messages';

type VehicleMaintenanceHeaderProps = {
  vehicleId: string;
};

export const VehicleMaintenanceHeader = ({
  vehicleId,
}: VehicleMaintenanceHeaderProps) => {
  const {
    hasPermission,
    isLoading: isPermissionLoading,
    isError,
  } = useAuthClaimsQuery();

  if (isPermissionLoading) {
    return <Spinner size="sm" data-testid="PERMISSION_LOADING" />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div className="flex items-center justify-between mb-10">
      <div className="w-full flex items-center gap-4">
        <RouterTab className="py-2" to="services">
          <FormattedMessage {...messages.services} />
        </RouterTab>
        <RouterTab className="py-2" to="technical-inspections">
          <FormattedMessage {...messages.technicalInspections} />
        </RouterTab>
      </div>
      {hasPermission('write:services') && (
        <Routes>
          <Route
            path="/technical-inspections"
            element={
              <div className="w-full flex justify-end">
                <RouterButton
                  emphasis="high"
                  to={`/technical-inspections/new?vehicleId=${vehicleId}`}
                >
                  <FormattedMessage {...messages.createTechnicalInspection} />
                </RouterButton>
              </div>
            }
          />
          <Route
            path="/services"
            element={
              <div className="w-full flex justify-end">
                <RouterButton
                  emphasis="high"
                  to={`/services/new?vehicleId=${vehicleId}`}
                >
                  <FormattedMessage {...messages.createService} />
                </RouterButton>
              </div>
            }
          />
        </Routes>
      )}
    </div>
  );
};
