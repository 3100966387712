import { PacksSort, PackState } from '@cooltra/api';

export type PacksFiltersFormValues = {
  keyword: string;
  state: PackState;
  sort: PacksSort;
};

export const initialValues: PacksFiltersFormValues = {
  keyword: '',
  state: '' as PackState,
  sort: 'createdAt.desc',
};
