import { defineMessages } from 'react-intl';

export default defineMessages({
  bonusZone: {
    defaultMessage: 'Bonus zone {bonusZoneTitle}',
  },
  discount: {
    defaultMessage: '{bonusZoneDiscount} discount',
  },
});
