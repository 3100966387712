import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'No uninvoiced charges found',
  },
  content: {
    defaultMessage: 'This does not have any uninvoiced charges',
  },
});
