import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type VehicleTaskRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  task: ReactNode;
  assignee: ReactNode;
  updated: ReactNode;
  created: ReactNode;
};

export const VehicleTaskRowLayout = ({
  task,
  assignee,
  updated,
  created,
  className,
  ...rest
}: VehicleTaskRowLayoutProps) => (
  <div className={classNames('flex', 'h-full', 'w-full', className)} {...rest}>
    <div className="flex items-center gap-4 w-full pl-8 pr-4">
      <div className="flex-1 overflow-hidden">{task}</div>
      <div className="w-32 shrink-0">{assignee}</div>
      <div className="w-48 shrink-0">{updated}</div>
      <div className="w-48 shrink-0">{created}</div>
    </div>
  </div>
);
