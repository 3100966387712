import { defineMessages } from 'react-intl';
import { RequestChangesReason } from '@cooltra/api';

export default defineMessages({
  requestChangesHeading: {
    defaultMessage: 'The following changes will be requested',
  },
  requestChangesButton: {
    defaultMessage: 'Request changes',
  },
});

export const requestChangesReasonMessages =
  defineMessages<RequestChangesReason>({
    'incomplete address': {
      defaultMessage: 'Complete address',
    },
    'invalid selfie': {
      defaultMessage: 'Different selfie',
    },
    'invalid id': {
      defaultMessage: 'Different ID',
    },
    'invalid id photo': {
      defaultMessage: 'Different ID photo',
    },
    'invalid driver license': {
      defaultMessage: 'Different driver license',
    },
    'invalid driver license photo': {
      defaultMessage: 'Different driver license photo',
    },
    'address id country mismatch': {
      defaultMessage: 'The address matching the country of the ID',
    },
    'expired driver license': {
      defaultMessage: 'Driver license with a valid expiration date',
    },
  });
