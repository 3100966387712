import { Rental, useVehicleQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { ScrollToTop } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { isBike } from '@cooltra/vehicle-utils';

import { Four0Three } from '~/common';

import { RentalManualClose } from '../RentalManualClose';
import { Telematics } from '../Telematics/Telematics';
import { TripSummary } from '../TripSummary/TripSummary';
import { RentalMap } from '../RentalMap/RentalMap';
import { Timeline } from '../Timeline/Timeline';
import { RentalManualCloseForm } from '../RentalManualClose/RentalManualCloseForm';

import messages from './messages';

export type RentalOverviewProps = {
  rental: Rental;
};

export const RentalOverview = ({ rental }: RentalOverviewProps) => {
  const { hasPermission } = useAuthClaimsQuery();
  const { data: vehicle } = useVehicleQuery(rental.vehicle.vehicleId, {
    enabled: false,
  });

  if (!hasPermission('read:rentals')) {
    return <Four0Three />;
  }

  const isABike = Boolean(vehicle && isBike(vehicle));

  return (
    <div
      className="container py-12 px-10 min-w-5xl max-w-7xl"
      data-testid="RENTAL_OVERVIEW"
    >
      <ScrollToTop />
      <div className="w-full mb-10">
        <div className="flex items-center justify-between">
          <h2 className="text-base text-neutral-600">
            <FormattedMessage {...messages.rentalOverview} />
          </h2>
          <Telematics
            vehicleId={rental.vehicle.vehicleId}
            rentalState={rental.state}
          />
        </div>
        <RentalManualCloseForm rentalCreatedAt={rental.pickUp.createdAt}>
          <div className="grid grid-cols-5 gap-10 items-stretch mt-8">
            <div className="col-span-3">
              <TripSummary rental={rental} />
            </div>
            <div className="col-span-2">
              <RentalManualClose rental={rental} />
            </div>
          </div>
          <div className="w-full mt-10">
            <div className="flex items-start gap-8">
              <div className="w-80 h-full sticky top-24 rounded-xl overflow-hidden">
                <h2 className="w-full text-base text-neutral-600 h-7 mb-4">
                  <FormattedMessage {...messages.rentalTimeline} />
                </h2>
                <RentalMap rental={rental} />
              </div>
              <div className="flex-1">
                <div className="h-7 mb-4" />
                <Timeline
                  isRentalActive={rental.state === 'ACTIVE'}
                  rentalId={rental.rentalId}
                  reservation={rental.reservation}
                  isABike={isABike}
                  vehicle={rental.vehicle}
                />
              </div>
            </div>
          </div>
        </RentalManualCloseForm>
      </div>
    </div>
  );
};
