/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useFormContext, useField } from '@cooltra/form';

import { ReviewFormValues } from '../review-form-types';

export type IgnoreDriverLicenseProps = {
  onIgnore: (ignore: boolean) => void;
};

export const IgnoreDriverLicense = ({ onIgnore }: IgnoreDriverLicenseProps) => {
  const { initialValues, setValues, values } =
    useFormContext<ReviewFormValues>();

  const { setValue } = useField('isValidDriverLicensePhoto');

  const clearAddressFields = useCallback(() => {
    setValues({
      driverLicenseCategory: '',
      driverLicenseExpirationDate: '',
      driverLicenseCountry: '',
      driverLicenseNumber: '',
    });
  }, [setValues]);

  const resetAddressFields = useCallback(() => {
    setValues({
      driverLicenseCategory: initialValues.driverLicenseCategory,
      driverLicenseExpirationDate: initialValues.driverLicenseExpirationDate,
      driverLicenseCountry: initialValues.driverLicenseCountry,
      driverLicenseNumber: initialValues.driverLicenseNumber,
    });
  }, [
    initialValues.driverLicenseCategory,
    initialValues.driverLicenseCountry,
    initialValues.driverLicenseExpirationDate,
    initialValues.driverLicenseNumber,
    setValues,
  ]);

  useEffect(() => {
    if (values.isValidDriverLicensePhoto == 'ignore') {
      onIgnore(true);
      clearAddressFields();
    } else {
      onIgnore(false);
      resetAddressFields();
    }
  }, [values.isValidDriverLicensePhoto]);

  useEffect(() => {
    setValue(initialValues.isValidDriverLicensePhoto);
  }, [values.arePhotosValid]);

  return null;
};
