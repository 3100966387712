import { Button, Modal } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCreateVehiclesTasksMutation } from '@cooltra/api';
import { useToggle } from '@cooltra/hooks';
import { Form, FormButton } from '@cooltra/form';
import { useQueryClient } from '@tanstack/react-query';

import {
  IssueFields,
  TaskFields,
  TaskFormProvider,
  TaskFormValues,
  taskInitialFormValues,
} from '~/common';
import { useNotification } from '~/hooks';

import messages from './messages';

export type AddVehiclesTaskProps = {
  vehicleIds: string[];
};

export const AddVehiclesTask = ({ vehicleIds }: AddVehiclesTaskProps) => {
  const queryClient = useQueryClient();
  const intl = useIntl();

  const [isOpen, { toggleOff, toggleOn }] = useToggle();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const { mutateAsync } = useCreateVehiclesTasksMutation();

  const handleSubmit = (values: TaskFormValues) =>
    mutateAsync({ vehicleIds, ...values })
      .then(() => {
        toggleOff();
        addSuccessNotification(intl.formatMessage(messages.success));
        queryClient.invalidateQueries({ queryKey: ['vehicles'] });
      })
      .catch(() => addErrorNotification());

  return (
    <>
      <Button
        emphasis="high"
        size="sm"
        onClick={toggleOn}
        disabled={vehicleIds.length == 0}
      >
        <FormattedMessage {...messages.addTask} />
      </Button>
      <Modal
        className="max-w-2xl min-w-xl"
        isOpen={isOpen}
        onRequestClose={toggleOff}
      >
        <div className="pb-10 px-10" data-testid="ADD_VEHICLES_TASK">
          <h6 className="text-neutral-400 text-lg mb-4">
            <FormattedMessage {...messages.addTask} />
          </h6>
          <TaskFormProvider
            initialValues={taskInitialFormValues}
            onSubmit={handleSubmit}
          >
            <Form className="flex flex-col gap-y-5 gap-x-8">
              <TaskFields.Title />
              <div className="grid grid-cols-2 gap-x-4">
                <IssueFields.Assignee />
                <IssueFields.Operator />
              </div>
              <TaskFields.Tags />
              <IssueFields.Description />
              <TaskFields.Blocking />
              <div className="grid grid-cols-2 mt-4 gap-4">
                <Button type="button" onClick={toggleOff}>
                  <FormattedMessage {...messages.cancel} />
                </Button>
                <FormButton emphasis="high">
                  <FormattedMessage {...messages.save} />
                </FormButton>
              </div>
            </Form>
          </TaskFormProvider>
        </div>
      </Modal>
    </>
  );
};
