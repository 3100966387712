import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type IncidentRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  incident: ReactNode;
  assignee: ReactNode;
  created: ReactNode;
};

export const IncidentRowLayout = ({
  incident,
  assignee,
  created,
  className,
  ...rest
}: IncidentRowLayoutProps) => (
  <div className={classNames('flex', 'h-full', 'w-full', className)} {...rest}>
    <div className="flex items-center gap-4 w-full px-8">
      <div className="flex-1 pr-10">{incident}</div>
      <div className="w-48 shrink-0">{assignee}</div>
      <div className="w-40 shrink-0">{created}</div>
    </div>
  </div>
);
