import { IntlShape } from 'react-intl';

import { isPast } from '~/utils/date';
import {
  createSetError,
  validateAmount,
  validateDate,
  validateRequired,
} from '~/validation';

import messages from './messages';

export type AddCreditFormValues = {
  title: string;
  amount: string;
  expiresAt: string;
};

export const addCreditFormValues: AddCreditFormValues = {
  title: '',
  amount: '',
  expiresAt: '',
};

type AddCreditFormFieldName = keyof AddCreditFormValues;

type AddCreditFormErrors = Partial<
  Record<AddCreditFormFieldName, string | undefined>
>;

export const validateAddCreditForm =
  (intl: IntlShape) => (values: AddCreditFormValues) => {
    const errors: AddCreditFormErrors = {};
    const setError = createSetError(errors);

    const titleErrorMessage = validateRequired(intl, values.title);
    setError('title', titleErrorMessage);

    setError(
      'amount',
      Number(values.amount) === 0
        ? intl.formatMessage(messages.theValueMustBeBiggerThanZero)
        : undefined
    );
    setError('amount', validateAmount(intl, values.amount));
    setError('amount', validateRequired(intl, values.amount));

    if (values.expiresAt !== '') {
      setError('expiresAt', validateDate(intl, values.expiresAt));
      setError(
        'expiresAt',
        isPast(values.expiresAt)
          ? intl.formatMessage(messages.theDateMustBeInTheFuture)
          : undefined
      );
    }

    return errors;
  };
