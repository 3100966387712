import { Button, ButtonProps } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { Checkmark } from 'react-checkmark';
import { classNames } from '@cooltra/utils';

import messages from './messages';

export type SaveButtonProps = ButtonProps & {
  isSaved: boolean;
};

export const SaveButton = ({
  isSaved,
  className,
  ...rest
}: SaveButtonProps) => (
  <Button
    type="button"
    className={classNames(className, 'rounded-full', isSaved && 'pr-3')}
    emphasis="high"
    size="lg"
    {...rest}
  >
    {isSaved ? (
      <span className="inline-flex gap-2 items-center">
        <FormattedMessage {...messages.saved} />
        <Checkmark size="medium" color="hsl(210, 18%, 65%)" />
      </span>
    ) : (
      <FormattedMessage {...messages.save} />
    )}
  </Button>
);
