import { FormattedMessage, useIntl } from 'react-intl';
import { FormMultiSelectField } from '@cooltra/form';
import { InputLabel, MultiSelectOptionVariant } from '@cooltra/ui';
import { ContractStatus } from '@cooltra/station-based-api';

import {
  allContractStatuses,
  getContractStatusMessage,
  getContractStatusVariant,
} from '~/utils/contract';

import messages from './messages';

export type StatusFieldOption = {
  label: string;
  value: ContractStatus;
  variant: MultiSelectOptionVariant;
};

export const StatusField = () => {
  const { formatMessage } = useIntl();

  const statusOptions: StatusFieldOption[] = allContractStatuses
    .filter((s) => s !== 'NEW')
    .map((status) => ({
      label: formatMessage(getContractStatusMessage(status)),
      variant: getContractStatusVariant(status),
      value: status,
    }));

  return (
    <FormMultiSelectField
      id="status"
      name="status"
      label={
        <InputLabel htmlFor="status" className="shrink-0">
          <FormattedMessage {...messages.status} />
        </InputLabel>
      }
      options={statusOptions}
      placeholder={formatMessage(messages.all)}
    />
  );
};
