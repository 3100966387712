import { UserListItem, useUsersQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { Card } from '@cooltra/ui';
import { Form } from '@cooltra/form';

import { ErrorPage, Four0Three, NoSearchResults } from '~/common';

import { UsersFormInputSearch } from './UsersFormInputSearch/UsersFormInputSearch';
import { useUsersFiltersFromUrl } from './UsersFiltersForm';
import { CooltraUsersList } from './CooltraUsersList';
import { ProviderUsersList } from './ProviderUsersList';

export const UsersList = () => {
  const { hasPermission } = useAuthClaimsQuery();

  const userFiltersFromUrl = useUsersFiltersFromUrl();

  const { data, isLoading, isError, error } = useUsersQuery(
    userFiltersFromUrl,
    {
      refetchOnWindowFocus: false,
    }
  );

  const users = data?.pages
    ? ([] as UserListItem[]).concat.apply([], data.pages)
    : [];

  if (!hasPermission('read:users')) {
    return <Four0Three />;
  }

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  const cooltraUsers = users.filter((user) => user.provider == 'COOLTRA');
  const providerUsers = users.filter((user) => user.provider != 'COOLTRA');

  const getContent = () => {
    if (isError) {
      return <ErrorPage />;
    }

    if (users.length === 0 && !isLoading) {
      return <NoSearchResults />;
    }

    return (
      <>
        <CooltraUsersList
          users={cooltraUsers}
          isLoading={isLoading}
          keyword={userFiltersFromUrl.keyword}
        />
        <ProviderUsersList
          users={providerUsers}
          isLoading={isLoading}
          keyword={userFiltersFromUrl.keyword}
        />
      </>
    );
  };

  return (
    <div>
      <Card className="p-5 mb-10 rounded-none">
        <Form className="container min-w-3xl max-w-6xl">
          <UsersFormInputSearch />
        </Form>
      </Card>
      {getContent()}
    </div>
  );
};
