import { languages } from '@cooltra/countries';
import { getKeys } from '@cooltra/utils';
import { IntlShape } from 'react-intl';
import { parse } from 'date-fns';

import {
  createSetError,
  validateAmount,
  validateDate,
  validateRequired,
  validateTime,
} from '~/validation';
import { dateAndTimeFormat } from '~/utils/date';

import { PromotionFormValues } from './promotion-form';
import messages from './messages';

type PromotionFormFieldName = keyof PromotionFormValues;

type PromotionFormErrors = Partial<
  Record<PromotionFormFieldName, string | undefined>
>;

const validateSingleCodeFields = (
  values: PromotionFormValues,
  intl: IntlShape,
  setError: (
    errorKey: keyof PromotionFormValues,
    message: string | undefined
  ) => void
) => {
  const maxMaxUsesValue = 50_000;
  const hasExceedMaxUsesValues =
    values.maxUses && Number(values.maxUses) > maxMaxUsesValue;
  const areMaxUsesAndRegistrationEmpty =
    !values.registration && values.maxUses === '';

  if (hasExceedMaxUsesValues) {
    setError('maxUses', intl.formatMessage(messages.exceedMaxUsesValue));
  }

  if (areMaxUsesAndRegistrationEmpty) {
    setError(
      'maxUses',
      intl.formatMessage(messages.checkOnlyToNewCustomersOrMaxPromoUses)
    );
    setError(
      'registration',
      intl.formatMessage(messages.checkOnlyToNewCustomersOrMaxPromoUses)
    );
  }

  const codeErrorMessage = validateRequired(intl, values.code);
  setError('code', codeErrorMessage);
};

const validateBenefitExpirationRangeMode = (
  values: PromotionFormValues,
  intl: IntlShape,
  setError: (
    errorKey: keyof PromotionFormValues,
    message: string | undefined
  ) => void
) => {
  switch (values.benefitExpirationRangeMode) {
    case 'specific-date':
      setError('benefitUntilDate', validateDate(intl, values.benefitUntilDate));
      setError('benefitUntilTime', validateTime(intl, values.benefitUntilTime));
      break;
    case 'after-days':
      setError(
        'benefitExpiresAfterDays',
        validateAmount(intl, String(values.benefitExpiresAfterDays))
      );
      break;
    case 'after-hours':
      setError(
        'benefitExpiresAfterHours',
        validateAmount(intl, String(values.benefitExpiresAfterHours))
      );
      break;
  }
};

const validateTitlesAndDescriptions = (
  values: PromotionFormValues,
  intl: IntlShape,
  setError: (
    errorKey: keyof PromotionFormValues,
    message: string | undefined
  ) => void
) => {
  const englishTitleErrorMessage = validateRequired(
    intl,
    values['titleTranslations.en']
  );
  setError('titleTranslations.en', englishTitleErrorMessage);

  getKeys(languages).forEach((isoCode) => {
    if (values[`descriptionTranslations.${isoCode}`]) {
      setError(
        `titleTranslations.${isoCode}`,
        validateRequired(intl, values[`titleTranslations.${isoCode}`])
      );
    }
  });
};

export const validatePromotionForm =
  (intl: IntlShape) => (values: PromotionFormValues) => {
    const errors: PromotionFormErrors = {};
    const setError = createSetError(errors);
    const isAMultiCodePromotion = values.promotionCodeMode === 'multi-code';
    const isASingleCodePromotion = values.promotionCodeMode === 'single-code';
    const isACreditPromotion = values.promotionTypeMode === 'credit';
    const isADiscountPromotion = values.promotionTypeMode === 'discount';
    const isARentalCapType = values.capType === 'rentals';

    if (isAMultiCodePromotion) {
      const numberOfCodesErrorMessage = validateAmount(
        intl,
        String(values.numberOfCodes)
      );
      setError('numberOfCodes', numberOfCodesErrorMessage);

      if (values.numberOfCodes == 1) {
        setError(
          'numberOfCodes',
          intl.formatMessage(messages.numberOfCodesHaveToBeGreaterThanOne)
        );
      }
    }

    if (isASingleCodePromotion) {
      validateSingleCodeFields(values, intl, setError);
    }

    if (isACreditPromotion) {
      const creditValueErrorMessage = validateRequired(
        intl,
        values['credit.value']
      );
      setError('credit.value', creditValueErrorMessage);
    }

    if (isADiscountPromotion) {
      const discountErrorMessage = validateRequired(intl, values['discount']);
      setError('discount', discountErrorMessage);

      const maxDiscountValue = 100;
      const hasExceedDiscountValues =
        values.discount && Number(values.discount) > maxDiscountValue;

      if (hasExceedDiscountValues) {
        setError('discount', intl.formatMessage(messages.exceedDiscountValue));
      }

      if (isARentalCapType) {
        const rentalsCapErrorMessage = validateRequired(
          intl,
          values['rentalsCap']
        );
        setError('rentalsCap', rentalsCapErrorMessage);
        const discountCapPerRentalErrorMessage = validateRequired(
          intl,
          values['discountCapPerRental.value']
        );
        setError(
          'discountCapPerRental.value',
          discountCapPerRentalErrorMessage
        );
      } else {
        const discountCapErrorMessage = validateRequired(
          intl,
          values['discountCap.value']
        );
        setError('discountCap.value', discountCapErrorMessage);
      }
    }

    setError('validFromDate', validateDate(intl, values.validFromDate));
    setError('validFromTime', validateTime(intl, values.validFromTime));

    if (!values.promotionDoesntExpire) {
      setError('validUntilDate', validateDate(intl, values.validUntilDate));
      setError('validUntilTime', validateTime(intl, values.validUntilTime));
    }

    if (
      isADiscountPromotion &&
      values.benefitExpirationRangeMode === 'specific-date'
    ) {
      setError('benefitFromDate', validateDate(intl, values.benefitFromDate));
      setError('benefitFromTime', validateTime(intl, values.benefitFromTime));

      if (!values.promotionDoesntExpire) {
        setError(
          'benefitUntilDate',
          validateDate(intl, values.benefitUntilDate)
        );
        setError(
          'benefitUntilTime',
          validateTime(intl, values.benefitUntilTime)
        );
      }
    }

    if (!values.benefitDoesntExpire) {
      validateBenefitExpirationRangeMode(values, intl, setError);
    }

    if (
      values.validFromDate != null &&
      values.validUntilDate != null &&
      values.validFromTime != null &&
      values.validUntilTime != null
    ) {
      const validFromDatetime = `${values.validFromDate} ${values.validFromTime}`;
      const validUntilDatetime = `${values.validUntilDate} ${values.validUntilTime}`;

      const validFromDateParsed = parse(
        validFromDatetime,
        dateAndTimeFormat,
        new Date()
      );
      const validUntilDateParsed = parse(
        validUntilDatetime,
        dateAndTimeFormat,
        new Date()
      );

      if (validUntilDateParsed <= validFromDateParsed) {
        setError(
          'validUntilDate',
          intl.formatMessage(messages.validFromGreaterThanValidUntil)
        );
      }
    }

    if (
      values.benefitFromDate != null &&
      values.benefitUntilDate != null &&
      values.benefitFromTime != null &&
      values.benefitUntilTime != null
    ) {
      const benefitFromDatetime = `${values.benefitFromDate} ${values.benefitFromTime}`;
      const benefitUntilDatetime = `${values.benefitUntilDate} ${values.benefitUntilTime}`;

      const benefitFromDateParsed = parse(
        benefitFromDatetime,
        dateAndTimeFormat,
        new Date()
      );
      const benefitUntilDateParsed = parse(
        benefitUntilDatetime,
        dateAndTimeFormat,
        new Date()
      );

      if (benefitUntilDateParsed <= benefitFromDateParsed) {
        setError(
          'benefitUntilDate',
          intl.formatMessage(messages.benefitFromGreaterThanBenefitUntil)
        );
      }
    }

    if (
      values.validFromTime != null &&
      values.validFromDate != null &&
      values.benefitFromTime != null &&
      values.benefitFromDate != null
    ) {
      const validFromDatetime = `${values.validFromDate} ${values.validFromTime}`;
      const benefitFromDatetime = `${values.benefitFromDate} ${values.benefitFromTime}`;

      const validFromDate = parse(
        validFromDatetime,
        dateAndTimeFormat,
        new Date()
      );
      const benefitFromDateParsed = parse(
        benefitFromDatetime,
        dateAndTimeFormat,
        new Date()
      );

      if (benefitFromDateParsed < validFromDate) {
        setError(
          'benefitFromDate',
          intl.formatMessage(
            messages.consumableFromGreaterOrEqualThanBenefitFrom
          )
        );
      }
    }

    if (
      values.validUntilTime != null &&
      values.validUntilDate != null &&
      values.benefitUntilTime != null &&
      values.benefitUntilDate != null
    ) {
      const validUntilDatetime = `${values.validUntilDate} ${values.validUntilTime}`;
      const benefitUntilDatetime = `${values.benefitUntilDate} ${values.benefitUntilTime}`;

      const validUntilDate = parse(
        validUntilDatetime,
        dateAndTimeFormat,
        new Date()
      );
      const benefitUntilDateParsed = parse(
        benefitUntilDatetime,
        dateAndTimeFormat,
        new Date()
      );

      if (benefitUntilDateParsed < validUntilDate) {
        setError(
          'benefitUntilDate',
          intl.formatMessage(messages.consumableUntilGreaterThanBenefitUntil)
        );
      }
    }

    validateTitlesAndDescriptions(values, intl, setError);

    return errors;
  };
