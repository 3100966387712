import { FormattedMessage } from 'react-intl';

import {
  ListColumnLabel,
  ListColumnLabelProps,
} from '../../List/ListColumnLabel/ListColumnLabel';

import messages from './messages';

export type ReservationColumnLabelProps = Omit<
  ListColumnLabelProps,
  'children'
> & {
  name: keyof typeof messages;
};

export const ReservationColumnLabel = ({
  name,
  ...rest
}: ReservationColumnLabelProps) => (
  <ListColumnLabel {...rest}>
    <FormattedMessage {...messages[name]} />
  </ListColumnLabel>
);
