import { useUserSuspensionsQuery } from '@cooltra/api';
import { MdOutlineChevronRight } from 'react-icons/md';
import { RouterButton, RouterLink } from '@cooltra/navigation';
import { Icon } from '@cooltra/ui';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { useParams } from 'react-router-dom';

import { sortByDate } from '~/utils/date';

import { SuspensionBadge } from '../SuspensionBadge/SuspensionBadge';

import messages from './messages';

export const SuspensionAlert = () => {
  const { id = '' } = useParams<'id'>();

  const {
    data: suspensions = [],
    isLoading,
    isError,
  } = useUserSuspensionsQuery(id);

  if (isError || isLoading) {
    return null;
  }

  const activeSuspensions = suspensions
    .filter(({ clearedAt }) => !clearedAt)
    .sort(sortByDate(false));

  if (!activeSuspensions.length) {
    return null;
  }

  const lastActiveSuspension = activeSuspensions[0];

  return (
    <div className="bg-neutral-0 shadow" data-testid="SUSPENSION_ALERT">
      <div className="bg-danger-100/20">
        <div className="container px-10 min-w-5xl max-w-7xl">
          <div className="flex items-center justify-between gap-12 h-24">
            <div className="flex items-center gap-4">
              <div>
                <p className="text-lg font-semibold text-danger-700">
                  <FormattedMessage {...messages.accountSuspended} />
                </p>
              </div>
              <SuspensionBadge
                type={lastActiveSuspension.type}
                className="bg-neutral-0"
              />
            </div>
            {lastActiveSuspension.type === 'AUTO' && (
              <div>
                <p className="text-sm flex gap-3">
                  <span>{lastActiveSuspension.comment}</span>
                  {lastActiveSuspension.comment === 'failed_invoice' && (
                    <RouterLink to="../billing/invoices">
                      <FormattedMessage {...messages.linkToInvoiceList} />
                    </RouterLink>
                  )}
                </p>
              </div>
            )}
            <div className="flex items-baseline gap-4">
              <span className="text-xs">
                <FormattedMessage
                  {...messages.suspendedSince}
                  values={{
                    date: (
                      <FormattedDate
                        value={new Date(lastActiveSuspension.createdAt)}
                        day="2-digit"
                        month="short"
                        year="numeric"
                      />
                    ),
                    time: (
                      <FormattedTime
                        value={new Date(lastActiveSuspension.createdAt)}
                        hour="2-digit"
                        hourCycle="h23"
                        minute="2-digit"
                      />
                    ),
                  }}
                />
              </span>
              <RouterButton
                to="../suspensions"
                variant="danger"
                size="sm"
                trailingIcon={
                  <Icon className="text-xl">
                    <MdOutlineChevronRight />
                  </Icon>
                }
              >
                <FormattedMessage {...messages.seeMore} />
              </RouterButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
