import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { RouterBackLink } from '~/common';

import { PriceListTypeContext } from '../PriceListTypeContext';

import messages from './messages';

export const CoveragePriceListDetailsBackLink = () => {
  const { type } = useContext(PriceListTypeContext);

  return (
    <RouterBackLink to={`/price-lists/${type.toLowerCase()}/accessories`}>
      <FormattedMessage {...messages.title} />
    </RouterBackLink>
  );
};
