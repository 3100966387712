import { useFormContext } from '@cooltra/form';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { QRCode } from './QRCode';

export type FormQRCodeProps = {
  dirty?: boolean;
};

export const FormQRCode = (props: FormQRCodeProps) => {
  const queryClient = useQueryClient();
  const { contractId = '' } = useParams();

  const { handleSubmit, isValid, ...formContext } = useFormContext();
  const dirty = props.dirty === undefined ? formContext.dirty : props.dirty;

  const onOpen = (isDesktop: boolean) => {
    if (isDesktop && isValid && dirty) {
      handleSubmit();
    }
  };

  const onClose = (isDesktop: boolean) => {
    if (isDesktop) {
      queryClient.invalidateQueries({ queryKey: ['contract', contractId] });
    }
  };

  return <QRCode onOpen={onOpen} onClose={onClose} />;
};
