import { stringifyParams } from '@cooltra/utils';
import axios from 'axios';

import { getEnvVariable } from '~/utils/environment';

type AutofillParams = {
  searchText: string;
};

type AutofillResponse = {
  suggestions: {
    full_address: string;
  }[];
};

export const autofill = ({ searchText }: AutofillParams) => {
  const url = `https://api.mapbox.com/autofill/v1/suggest/${encodeURIComponent(
    searchText
  )}?${stringifyParams({
    types: 'address',
    streets: true,
    proximity: 'ip',
    access_token: getEnvVariable('MAPBOX_ACCESS_TOKEN'),
  })}`;
  return axios.get<AutofillResponse>(url).then(({ data }) => data.suggestions);
};
