import { Card, Placeholder } from '@cooltra/ui';

import { SubscriptionRowLayout } from '../SubscriptionRowLayout';

export const SubscriptionCardLoading = () => (
  <Card>
    <SubscriptionRowLayout
      className="h-16"
      name={<Placeholder className="h-2 w-40" />}
      validFrom={<Placeholder className="h-2 w-28" />}
      createdBy={<Placeholder className="w-8 h-8 rounded-full" />}
      state={<Placeholder className="h-2 w-12" />}
    />
  </Card>
);
