import { Vehicle } from '@cooltra/api';
import { Icon } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { MdCheckCircleOutline, MdOutlineCancel } from 'react-icons/md';

import messages from './messages';

export type CityHallStaticProps = {
  value: Vehicle['cityHallPublished'];
};

export const CityHallStatic = ({ value }: CityHallStaticProps) => (
  <span className="flex items-center gap-1">
    <Icon className="text-neutral-600 text-xl">
      {value ? <MdCheckCircleOutline /> : <MdOutlineCancel />}
    </Icon>
    <span className="text-neutral-1000 text-sm">
      {value ? (
        <FormattedMessage {...messages.cityHall} />
      ) : (
        <FormattedMessage {...messages.noCityHall} />
      )}
    </span>
  </span>
);
