import { Task } from '@cooltra/api';
import { RouterLink } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { TimelineItem, TimelineItemProps } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';

import messages from './messages';

type TaskDoneProps = Pick<Task, 'taskId' | 'title'> & {
  type: 'open' | 'closed' | 'commented';
} & Omit<TimelineItemProps, 'header'>;

export const TaskDone = ({
  taskId,
  title,
  type,
  ...timelineItemProps
}: TaskDoneProps) => {
  const getLabelType = () => {
    switch (type) {
      case 'open':
        return <FormattedMessage {...messages.created} />;
      case 'closed':
        return <FormattedMessage {...messages.closed} />;
      case 'commented':
        return <FormattedMessage {...messages.commented} />;
    }
  };

  return (
    <TimelineItem
      {...timelineItemProps}
      header={
        <div
          className={classNames(
            'flex-1 flex items-center gap-1 text-xs relative -top-1',
            timelineItemProps.isLast ? '' : 'pb-3'
          )}
        >
          <span className="font-semibold">{getLabelType()}</span>
          <span>
            <RouterLink
              to={`/tasks/${taskId}`}
              target="_blank"
              className="w-36 text-xs truncate overflow-ellipsis block"
            >
              {title}
            </RouterLink>
          </span>
        </div>
      }
    />
  );
};
