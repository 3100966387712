import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Service',
  },
  createService: {
    defaultMessage: 'Create service',
  },
  success: {
    defaultMessage: 'You have successfully created a new service!',
  },
  badFormattedFields: {
    defaultMessage:
      'This service cannot be created, please check that every field is correct',
  },
});
