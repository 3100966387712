import { Money } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';

import { Amount } from '~/common';

import { pricingDiscountTypesMessages } from './messages';

export type PricingPassProps = {
  amount: Money;
};

export const PricingPass = ({ amount }: PricingPassProps) => (
  <div
    className="flex justify-between items-center py-4"
    data-testid="RENTAL_PRICING_SUMMARY_PASS"
  >
    <span className="text-sm">
      <FormattedMessage {...pricingDiscountTypesMessages['PASS']} />
    </span>
    <Amount
      className="text-sm"
      value={-amount.value}
      currency={amount.currency}
    />
  </div>
);
