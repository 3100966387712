import { Badge } from '@cooltra/ui';
import { VehicleTechnicalInspectionItem } from '@cooltra/api';
import { RouterCardLink } from '@cooltra/navigation';
import { classNames } from '@cooltra/utils';
import { isFuture, isPast, parse } from 'date-fns';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { ByOperator } from '~/common';
import { dateFormat } from '~/utils/date';

import { VehicleTechnicalInspectionRowLayout } from '../VehicleTechnicalInspectionRowLayout/VehicleTechnicalInspectionRowLayout';

import messages from './messages';

export type VehicleTechnicalInspectionCardProps =
  VehicleTechnicalInspectionItem;

export const VehicleTechnicalInspectionCard = ({
  technicalInspectionId,
  scheduledAt,
  servicedBy,
  servicedAt,
}: VehicleTechnicalInspectionCardProps) => {
  const scheduledAtDate = parse(scheduledAt || '', dateFormat, new Date());
  const isServicedDone = servicedAt && servicedBy;

  const getBadgeState = () => {
    if (isServicedDone) {
      return (
        <Badge variant="success">
          <FormattedMessage {...messages.done} />
        </Badge>
      );
    }

    if (!scheduledAt || isFuture(scheduledAtDate)) {
      return (
        <Badge>
          <FormattedMessage {...messages.next} />
        </Badge>
      );
    }

    return (
      <div>
        {isPast(scheduledAtDate) && (
          <Badge variant="danger">
            <FormattedMessage {...messages.pastDue} />
          </Badge>
        )}
      </div>
    );
  };

  return (
    <RouterCardLink
      to={`/technical-inspections/${technicalInspectionId}`}
      className="text-sm text-neutral-800 p-6"
    >
      <VehicleTechnicalInspectionRowLayout
        scheduledAt={
          scheduledAt ? (
            <span
              className={classNames(
                'font-semibold',
                !isServicedDone && isPast(scheduledAtDate)
                  ? 'text-danger-500'
                  : ''
              )}
            >
              <FormattedDate
                value={scheduledAtDate}
                day="2-digit"
                month="short"
                year="numeric"
              />
            </span>
          ) : (
            <span />
          )
        }
        serviced={
          servicedAt && servicedBy ? (
            <div>
              <p className="text-sm text-neutral-600">
                <FormattedDate
                  value={parse(servicedAt, dateFormat, new Date())}
                  day="2-digit"
                  month="short"
                  year="numeric"
                />
              </p>
              <ByOperator className="text-neutral-400" id={servicedBy} />
            </div>
          ) : (
            <></>
          )
        }
        state={getBadgeState()}
      />
    </RouterCardLink>
  );
};
