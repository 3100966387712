import {
  useVehicleServicesQuery,
  useVehicleStatusQuery,
  VehicleServiceItem,
} from '@cooltra/api';
import { List } from '@cooltra/ui';

import { ErrorPage, NoSearchResults } from '~/common';

import { VehicleServiceCard } from '../VehicleServiceCard';
import { VehicleServicesListHeader } from '../VehicleServicesListHeader/VehicleServicesListHeader';

type VehicleServicesListProps = {
  vehicleId: string;
};

export const VehicleServicesList = ({
  vehicleId,
}: VehicleServicesListProps) => {
  const { data: vehicleStatus } = useVehicleStatusQuery(vehicleId);
  const { data, isLoading, isError } = useVehicleServicesQuery(
    vehicleId,
    'SERVICE'
  );

  if (isError) {
    return <ErrorPage />;
  }

  const vehicleServices = data
    ? data.sort((currentService, nextService) => {
        return currentService.scheduledMileage > nextService.scheduledMileage
          ? -1
          : 1;
      })
    : [];

  return (
    <div data-testid="VEHICLE_SERVICES_LIST">
      <List
        loadingRows={4}
        isLoading={isLoading}
        idProp="serviceId"
        data={vehicleServices}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<VehicleServicesListHeader />}
        renderRow={(vehicleService: VehicleServiceItem) => (
          <VehicleServiceCard
            key={vehicleService.serviceId}
            {...vehicleService}
            currentMileage={vehicleStatus?.mileage?.value}
          />
        )}
      />
    </div>
  );
};
