import { useTaskQuery } from '@cooltra/api';

import { TaskCard, TaskCardLoading } from './TaskCard';

export type ProblemReportTaskProps = {
  taskId: string;
};

export const ProblemReportTask = ({ taskId }: ProblemReportTaskProps) => {
  const { data: task } = useTaskQuery(taskId);
  return task ? <TaskCard task={task} /> : <TaskCardLoading />;
};
