import { defineMessages } from 'react-intl';

export default defineMessages({
  invoiceCharge: {
    defaultMessage: 'New manual charge',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  save: {
    defaultMessage: 'Save',
  },
  minAmountError: {
    defaultMessage: 'The minimum amount is €0.50',
  },
  maxAmountError: {
    defaultMessage: 'The maximum amount is €2500',
  },
});
