import { defineMessages } from 'react-intl';
import { ParkingReview } from '@cooltra/api';

export const parkingReviews = defineMessages<ParkingReview>({
  PARKED_CORRECTLY: {
    defaultMessage: 'Well',
  },
  MAYBE_PARKED_INCORRECTLY: {
    defaultMessage: "Can't identify",
  },
  PARKED_INCORRECTLY: {
    defaultMessage: 'Parked poorly',
  },
  INCORRECT_PHOTO: {
    defaultMessage: 'Bad photo',
  },
});
