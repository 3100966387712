import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@cooltra/ui';
import { ButtonHTMLAttributes } from 'react';
import { equal } from '@cooltra/utils';
import { MdTune } from 'react-icons/md';

import { useMediaQuery } from '~/hooks';

import { useFilters } from '../../useFilters';
import { filterInitialFormValues } from '../FiltersModal/FilterFormProvider';

import messages from './messages';

export type FiltersButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const FiltersButton = ({
  onClick,
  className,
  ...rest
}: FiltersButtonProps) => {
  const isLargeScreen = useMediaQuery('lg');

  const { formatMessage } = useIntl();
  const { servicePointId, serviceType, status } = useFilters();

  const isActive = !equal(filterInitialFormValues, {
    serviceType: serviceType || '',
    servicePointId,
    status,
  });

  return (
    <Button
      variant="neutral"
      onClick={onClick}
      square={!isLargeScreen}
      leadingIcon={isLargeScreen && <MdTune className="rotate-90 text-xl" />}
      {...rest}
    >
      {isLargeScreen ? (
        <span className="text-md font-semibold">
          <FormattedMessage {...messages.filters} />
        </span>
      ) : (
        <MdTune className="rotate-90 text-xl" />
      )}
      {isActive && (
        <span
          aria-label={formatMessage(messages.active)}
          className="absolute w-3 h-3 inline-block rounded-full bg-primary-500 bottom-0 right-0 translate-x-1/2 translate-y-1/2"
        />
      )}
    </Button>
  );
};
