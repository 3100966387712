import { FormProvider, Form, FormProviderProps } from '@cooltra/form';
import { useIntl } from 'react-intl';
import { usePacksQuery } from '@cooltra/api';

import { PackFormType, PackFormValues } from './pack-form';
import { validatePackForm } from './pack-form-validation';

export type PackFormProps = Omit<
  FormProviderProps<PackFormValues>,
  'validate'
> & {
  formType: PackFormType;
};

export const PackForm = ({
  formType,
  onSubmit,
  initialValues,
  children,
  disabled,
}: PackFormProps) => {
  const intl = useIntl();

  const { data, isLoading } = usePacksQuery();
  const codes = data?.packs.map(({ code }) => code) || [];

  return (
    <FormProvider
      validate={validatePackForm(intl, formType, codes)}
      initialValues={initialValues}
      onSubmit={onSubmit}
      disabled={isLoading || disabled}
    >
      <Form>{children}</Form>
    </FormProvider>
  );
};
