import { UserRentalPassStatus } from '@cooltra/api';
import { Badge } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type RentalPassStatusProps = {
  status: UserRentalPassStatus;
};

export const RentalPassStatus = ({ status }: RentalPassStatusProps) => {
  if (status === 'ACTIVE') {
    return (
      <Badge variant="success">
        <FormattedMessage {...messages.active} />
      </Badge>
    );
  }

  return (
    <Badge emphasis="low">
      <FormattedMessage {...messages.expired} />
    </Badge>
  );
};
