import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';
import { useLocation } from 'react-router-dom';
import { getParams } from '@cooltra/utils';

import { useNavigateWithQueryParams } from '~/hooks';

import {
  initialRentalsFiltersFormValues,
  RentalsFiltersFormValues,
} from './rentals-filters-form';

const { system, state, minDate, maxDate, sort } =
  initialRentalsFiltersFormValues;

export const useRentalFiltersFromUrl = () => {
  const { search } = useLocation();

  const params = getParams<keyof RentalsFiltersFormValues>(search);

  return {
    system: params.array('system').length ? params.array('system') : system,
    state: params.array('state').length ? params.array('state') : state,
    minDate: params.string('minDate') || minDate,
    maxDate: params.string('maxDate') || maxDate,
    sort: params.string('sort') || sort,
  } as RentalsFiltersFormValues;
};

export const RentalsFiltersForm = ({ children }: PropsWithChildren) => {
  const navigateWithQueryParams = useNavigateWithQueryParams();

  const values = useRentalFiltersFromUrl();

  const onSubmit = async (values: RentalsFiltersFormValues) =>
    navigateWithQueryParams(values);

  return (
    <FormProvider initialValues={values} onSubmit={onSubmit}>
      {children}
    </FormProvider>
  );
};
