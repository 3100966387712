import { defineMessages } from 'react-intl';

export default defineMessages({
  en: {
    defaultMessage: 'English',
  },
  es: {
    defaultMessage: 'Spanish',
  },
  fr: {
    defaultMessage: 'French',
  },
  pt: {
    defaultMessage: 'Portuguese',
  },
  it: {
    defaultMessage: 'Italian',
  },
  ca: {
    defaultMessage: 'Catalan',
  },
  nl: {
    defaultMessage: 'Dutch',
  },
});
