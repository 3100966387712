import { InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormMultiSelectField } from '@cooltra/form';

import { useTelematicsOptions } from '../../../Vehicle';

import messages from './messages';

export const Telematics = () => {
  const { formatMessage } = useIntl();
  const telematicsOptions = useTelematicsOptions();
  return (
    <FormMultiSelectField
      name="telematics"
      id="telematics"
      label={
        <InputLabel htmlFor="telematics">
          <FormattedMessage {...messages.telematics} />
        </InputLabel>
      }
      placeholder={formatMessage(messages.placeholder)}
      options={telematicsOptions}
    />
  );
};
