import { RouterLink } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const AlarmsListHeader = () => (
  <div className="flex mb-12 gap-7">
    <RouterLink className="font-semibold text-primary-500" to="/vehicles">
      <FormattedMessage {...messages.allVehicles} />
    </RouterLink>
    <RouterLink className="font-semibold text-primary-500" to="/vehicles/map">
      <FormattedMessage {...messages.mapView} />
    </RouterLink>
    <span className="font-semibold text-neutral-600">
      <FormattedMessage {...messages.alarms} />
    </span>
  </div>
);
