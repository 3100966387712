import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, FormButton, FormHelpers } from '@cooltra/form';
import {
  CreateVehicleServicePayload,
  useCreateVehicleServiceMutation,
  useVehicleStatusQuery,
} from '@cooltra/api';

import {
  DividedCard,
  MileagePair,
  ServiceBackLink,
  ServiceFormProvider,
  ServiceFormValues,
  serviceInitialFormValues,
  VehiclePair,
  validateServiceForm,
} from '~/common';
import { useNotification, useVehicleQuery } from '~/hooks';

import messages from './messages';
import { CreateServiceForm } from './CreateServiceForm';

export const CreateService = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const navigate = useNavigate();
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const [searchParams] = useSearchParams();
  const vehicleId = searchParams.get('vehicleId') || '';

  const { data: vehicle } = useVehicleQuery(vehicleId);
  const { data: vehicleStatus, isLoading: isVehicleStatusLoading } =
    useVehicleStatusQuery(vehicleId);
  const { mutateAsync, isPending } = useCreateVehicleServiceMutation(vehicleId);

  const handleOnSubmit = (
    values: ServiceFormValues,
    formHelpers: FormHelpers<ServiceFormValues>
  ) => {
    const payload: CreateVehicleServicePayload = {
      category: 'SERVICE',
      scheduledMileage: Number(values.scheduledMileage),
      scheduledAt: values.scheduledAt,
    };

    return mutateAsync(payload)
      .then(({ serviceId }) => {
        addSuccessNotification(formatMessage(messages.success));
        formHelpers.resetForm(serviceInitialFormValues);
        navigate(`/services/${serviceId}`);
      })
      .catch(({ response }) => {
        switch (response.status) {
          case 400:
            addErrorNotification(formatMessage(messages.badFormattedFields));
            break;
          default:
            addErrorNotification();
            break;
        }
      });
  };

  return (
    <ServiceFormProvider
      onSubmit={handleOnSubmit}
      initialValues={serviceInitialFormValues}
      validate={validateServiceForm(intl)}
    >
      <Form>
        <DividedCard
          className="min-w-2xl max-w-4xl pt-12 pb-20"
          renderHeader={
            <div className="flex items-end justify-between">
              <div>
                <ServiceBackLink vehicleId={vehicleId} />
                <h1 className="text-3xl">
                  <FormattedMessage {...messages.title} />
                </h1>
              </div>
              <FormButton loading={isPending}>
                <FormattedMessage {...messages.createService} />
              </FormButton>
            </div>
          }
          renderLeft={
            <div className="flex flex-col gap-9">
              <VehiclePair
                vehicleId={vehicle?.vehicleId}
                model={vehicle?.model}
                externalId={vehicle?.externalId}
                identificationNumber={vehicle?.identificationNumber}
              />
              <MileagePair
                mileage={vehicleStatus?.mileage?.value}
                isLoading={isVehicleStatusLoading}
              />
            </div>
          }
          renderRight={<CreateServiceForm />}
        />
      </Form>
    </ServiceFormProvider>
  );
};
