import { List } from '@cooltra/ui';
import { UserRentalPassListItem, useUserRentalPassesQuery } from '@cooltra/api';

import { ErrorPage } from '~/common';

import { RentalPassCard, RentalPassCardLoading } from '../RentalPassCard';
import { NoRentalPasses } from '../NoRentalPasses/NoRentalPasses';
import { RentalPassesListHeader } from '../RentalPassesListHeader/RentalPassesListHeader';

export type RentalPassesProps = {
  userId: string;
};

export const RentalPasses = ({ userId }: RentalPassesProps) => {
  const {
    data: rentalPasses,
    isLoading,
    isError,
  } = useUserRentalPassesQuery(userId);

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div data-testid="USER_RENTAL_PASSES" className="pt-10">
      <List
        loadingRows={4}
        isLoading={isLoading}
        idProp="validFrom"
        data={rentalPasses || []}
        renderEmptyMessage={<NoRentalPasses />}
        renderHeader={<RentalPassesListHeader />}
        renderLoadingRow={<RentalPassCardLoading />}
        renderRow={(rentalPass: UserRentalPassListItem) => (
          <RentalPassCard rentalPass={rentalPass} />
        )}
      />
    </div>
  );
};
