import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type PriceListsByPricingGroupRowLayoutProps =
  HTMLAttributes<HTMLDivElement> & {
    appliesIn: ReactNode;
    eBike: ReactNode;
    standard: ReactNode;
    plus: ReactNode;
    premium: ReactNode;
    superPremium: ReactNode;
    exportPrices: ReactNode;
  };

export const PriceListsByPricingGroupRowLayout = ({
  appliesIn,
  eBike,
  standard,
  plus,
  premium,
  superPremium,
  className,
  exportPrices,
  ...rest
}: PriceListsByPricingGroupRowLayoutProps) => (
  <div className={classNames('flex', 'h-full', 'w-full', className)} {...rest}>
    <div className="flex items-center w-full px-8 gap-4">
      <div className="w-64 shrink-0">{appliesIn}</div>
      <div className="flex-1 shrink-0">{eBike}</div>
      <div className="flex-1 shrink-0">{standard}</div>
      <div className="flex-1 shrink-0">{plus}</div>
      <div className="flex-1 shrink-0">{premium}</div>
      <div className="flex-1 shrink-0">{superPremium}</div>
      <div className="w-6 shrink-0 flex justify-end">{exportPrices}</div>
    </div>
  </div>
);
