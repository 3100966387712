import { ReactNode, useEffect, useRef } from 'react';
import { useField, useFormContext } from '@cooltra/form';
import { MapLayerMouseEvent } from 'mapbox-gl';
import { GeolocateControl, MapRef, NavigationControl } from 'react-map-gl';

import { isTestingEnv } from '~/utils/e2e';
import { BaseMap } from '~/common';

import { VehicleFormValues, VehicleGeolocationFormValue } from '../../../index';

import { VehicleMarker } from './VehicleMapMarker';

export type VehicleMapProps = {
  id: string;
  children?: ReactNode;
};

export const VehicleMap = ({ id, children }: VehicleMapProps) => {
  const mapRef = useRef<MapRef | null>(null);
  const {
    values: { parkingPhoto },
  } = useFormContext<VehicleFormValues>();
  const { value: geolocation, setValue } =
    useField<VehicleGeolocationFormValue>('geolocation');

  useEffect(() => {
    if (!geolocation) return;

    mapRef.current?.setCenter([geolocation.longitude, geolocation.latitude], {
      animate: false,
    });
  }, [parkingPhoto]);

  if (isTestingEnv() || !geolocation) {
    return (
      <div
        className="h-full w-full bg-neutral-100 relative"
        data-testid="VEHICLE_GEOLOCATION"
      >
        {children}
      </div>
    );
  }

  const setGeolocationOnClickOnMap = ({ lngLat }: MapLayerMouseEvent) => {
    setValue({
      latitude: lngLat.lat,
      longitude: lngLat.lng,
    });
  };

  const setGeolocationOnGeolocate = ({ coords }: GeolocationPosition) => {
    setValue({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };

  return (
    <BaseMap
      id={id}
      ref={mapRef}
      initialViewState={{
        latitude: geolocation.latitude,
        longitude: geolocation.longitude,
        zoom: 16,
      }}
      style={{ width: '100%', height: '444px' }}
      onClick={setGeolocationOnClickOnMap}
    >
      <NavigationControl showZoom showCompass={false} />
      <GeolocateControl onGeolocate={setGeolocationOnGeolocate} />
      <VehicleMarker
        latitude={geolocation.latitude}
        longitude={geolocation.longitude}
      />
      {children}
    </BaseMap>
  );
};
