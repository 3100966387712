import { defineMessages } from 'react-intl';

export default defineMessages({
  percentage: {
    defaultMessage: 'Discount (%)',
  },
  discountCap: {
    defaultMessage: 'Discount cap (€)',
  },
  discountCapPerRental: {
    defaultMessage: 'Discount cap per rental (€)',
  },
  rentalsCap: {
    defaultMessage: 'Rentals cap',
  },
  rentals: {
    defaultMessage: 'Rentals',
  },
  total: {
    defaultMessage: 'Total',
  },
  capType: {
    defaultMessage: 'Cap type',
  },
});
