import { Placeholder } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type OpenedTasksProps = {
  openedTasks?: number;
};

export const OpenedTasks = ({ openedTasks }: OpenedTasksProps) => (
  <div className="flex flex-col">
    {openedTasks !== undefined ? (
      <span className="text-md text-neutral-1000 font-semibold">
        {openedTasks}
      </span>
    ) : (
      <Placeholder className="w-6 h-5 mx-auto mb-1" />
    )}
    <span className="text-xs text-neutral-300">
      <FormattedMessage {...messages.openedTasks} />
    </span>
  </div>
);
