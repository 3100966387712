import {
  InsuranceDepositPrices,
  UpdatePricingDepositPricesPayload,
} from '@cooltra/station-based-api';

import { DepositsFormValues, VehicleCategory } from './Form';

export const getFormValues = (
  insuranceDepositPrices: InsuranceDepositPrices
): DepositsFormValues => {
  const getValue = (vehicleType: VehicleCategory): string => {
    const depositPrices = insuranceDepositPrices.deposits.filter((value) => {
      return value.vehicleTypeCategory === vehicleType;
    });

    return depositPrices.length == 0 ? '' : `${depositPrices[0].amount.value}`;
  };

  return {
    'prices.EBIKE.price.value': `${getValue('EBIKE')}`,
    'prices.PREMIUM.price.value': `${getValue('PREMIUM')}`,
    'prices.STANDARD.price.value': `${getValue('STANDARD')}`,
    'prices.PLUS.price.value': `${getValue('PLUS')}`,
    'prices.SUPER_PREMIUM.price.value': `${getValue('SUPER_PREMIUM')}`,
  };
};

export const getUpdatePricingDepositPricesPayload = (
  values: DepositsFormValues
): UpdatePricingDepositPricesPayload => {
  const vehicleCategories: VehicleCategory[] = [
    'EBIKE',
    'STANDARD',
    'PLUS',
    'PREMIUM',
    'SUPER_PREMIUM',
  ];

  const payload: UpdatePricingDepositPricesPayload = {};

  vehicleCategories
    .filter((vehicleType) => values[`prices.${vehicleType}.price.value`])
    .map(
      (vehicleType) =>
        (payload[vehicleType] = {
          deposit: {
            value: Number(values[`prices.${vehicleType}.price.value`]),
            currency: 'EUR',
          },
        })
    );

  return payload;
};
