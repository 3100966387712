import { VehicleListItem } from '@cooltra/api';

export const isKeywordMatch = (
  vehicle: VehicleListItem,
  { keyword }: { keyword: string }
) => {
  if (!keyword) {
    return true;
  }

  const lowerCaseKeyword = keyword.toLowerCase();

  if (lowerCaseKeyword.includes(' ')) {
    const keywords = lowerCaseKeyword
      .split(' ')
      .map((partial) => partial.trim());
    const { vehicleId, licensePlate } = vehicle;
    return keywords.some(
      (keyword) =>
        (!!vehicleId && vehicleId.toLowerCase().includes(keyword)) ||
        (!!licensePlate && licensePlate.toLowerCase().includes(keyword))
    );
  }

  const searchableFields: (keyof VehicleListItem)[] = [
    'licensePlate',
    'externalId',
    'vehicleId',
    'telematicsId',
    'identificationNumber',
  ];

  return searchableFields
    .filter((field) => vehicle[field])
    .some((field) =>
      (vehicle[field] as string).toLowerCase().includes(lowerCaseKeyword)
    );
};
