import { defineMessages } from 'react-intl';

export default defineMessages({
  code: {
    defaultMessage: 'Code',
  },
  discountPercentage: {
    defaultMessage: 'Value',
  },
  offer: {
    defaultMessage: 'Offer',
  },
  redeemedAt: {
    defaultMessage: 'Redeemed at',
  },
  status: {
    defaultMessage: 'Status',
  },
  validFrom: {
    defaultMessage: 'Valid from',
  },
  validUntil: {
    defaultMessage: 'Valid until',
  },
});
