import { FormattedMessage, useIntl } from 'react-intl';
import { Placeholder } from '@cooltra/ui';
import { useVehicleStatusQuery } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';

import { SidebarItem } from '~/common';

import messages from './messages';

export type MileageProps = {
  vehicleId: string;
};

export const Mileage = ({ vehicleId }: MileageProps) => {
  const { data: status, error } = useVehicleStatusQuery(vehicleId);
  const { formatMessage } = useIntl();

  if (getErrorStatus(error) === 404) {
    return (
      <SidebarItem
        className="mb-2"
        label={formatMessage(messages.mileage)}
        value={<FormattedMessage {...messages.noData} />}
      />
    );
  }

  if (!status) {
    return (
      <SidebarItem
        className="mb-2"
        label={formatMessage(messages.mileage)}
        value={<Placeholder className="inline-flex w-10 h-2" />}
      />
    );
  }

  if (status.mileage === null) {
    return (
      <SidebarItem
        className="mb-2"
        label={formatMessage(messages.mileage)}
        value={<FormattedMessage {...messages.noData} />}
      />
    );
  }

  const { unit, value } = status.mileage;

  return (
    <SidebarItem
      className="mb-2"
      label={formatMessage(messages.mileage)}
      value={`${value} ${unit}`}
    />
  );
};
