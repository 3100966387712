import { Card } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { UserRentalPassListItem } from '@cooltra/api';

import { DateTime, Amount } from '~/common';

import { RentalPassRowLayout } from '../RentalPassRowLayout';
import { RentalPassStatus } from '../RentalPassStatus/RentalPassStatus';

export type RentalPassCardProps = {
  rentalPass: UserRentalPassListItem;
};

export const RentalPassCard = ({
  rentalPass: { title, price, validFrom, validUntil, status },
}: RentalPassCardProps) => (
  <Card
    className={classNames(
      'text-sm text-neutral-800',
      status === 'EXPIRED' && 'opacity-50'
    )}
  >
    <RentalPassRowLayout
      className="h-16"
      title={<span className="capitalize">{title}</span>}
      price={<Amount {...price} />}
      validFrom={<DateTime date={validFrom} />}
      validUntil={<DateTime date={validUntil} />}
      status={<RentalPassStatus status={status} />}
    />
  </Card>
);
