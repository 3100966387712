import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from '@cooltra/ui';

import messages from './messages';

export type ConfirmModalProps = {
  destructive?: boolean;
  isOpen: boolean;
  title: string;
  content: ReactNode;
  confirmText?: ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
  dataTestId?: string;
  className?: string;
};

export const ConfirmModal = ({
  destructive = false,
  isOpen,
  title,
  content,
  onClose,
  onConfirm,
  loading = false,
  confirmText,
  dataTestId = 'CONFIRM_MODAL',
  className = 'max-w-sm min-w-xs',
}: ConfirmModalProps) => (
  <Modal className={className} isOpen={isOpen} onRequestClose={onClose}>
    <div data-testid={dataTestId} className="text-center pb-8 px-8">
      <h6 className="text-neutral-700 text-lg font-semibold mb-2">{title}</h6>
      {typeof content === 'string' ? (
        <p className="text-neutral-600 text-sm mb-8">{content}</p>
      ) : (
        content
      )}
      <div className="flex justify-center gap-4">
        {destructive ? (
          <>
            <Button loading={loading} variant="danger" onClick={onConfirm}>
              {confirmText || <FormattedMessage {...messages.confirm} />}
            </Button>
            <Button emphasis="high" onClick={onClose}>
              <FormattedMessage {...messages.cancel} />
            </Button>
          </>
        ) : (
          <>
            <Button onClick={onClose}>
              <FormattedMessage {...messages.cancel} />
            </Button>
            <Button loading={loading} emphasis="high" onClick={onConfirm}>
              {confirmText || <FormattedMessage {...messages.confirm} />}
            </Button>
          </>
        )}
      </div>
    </div>
  </Modal>
);
