import { Invoice, useCreateChargeAttemptMutation } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { useNotification, usePolling, usePrevious } from '~/hooks';

import messages from './messages';

export type InvoiceNewChargeAttemptProps = {
  invoiceId: string;
  invoice: Invoice;
  refetchInvoice: () => Promise<unknown>;
};

export const InvoiceNewChargeAttempt = ({
  invoiceId,
  invoice: { state, chargeAttempts },
  refetchInvoice,
}: InvoiceNewChargeAttemptProps) => {
  const { addErrorNotification } = useNotification();
  const { hasPermission } = useAuthClaimsQuery();

  const { mutate, isSuccess, isPending, reset } =
    useCreateChargeAttemptMutation(invoiceId, {
      onError: () => addErrorNotification(),
    });

  const chargeAttemptState = chargeAttempts[0]?.state;

  const previousChargeAttemptState = usePrevious(chargeAttemptState);

  const { isPolling } = usePolling({
    fn: refetchInvoice,
    interval:
      isSuccess &&
      (chargeAttemptState === 'PENDING' ||
        chargeAttemptState === previousChargeAttemptState)
        ? 2000
        : 0,
    onSettle: reset,
  });

  const isInvoiceStateValidForNewCharges =
    state === 'OPEN' || state === 'FAILED';

  const isLastChargeAttemptValidForNewCharges =
    chargeAttemptState === 'FAILED' || chargeAttemptState === 'PENDING';

  if (
    !hasPermission('write:invoices') ||
    !isInvoiceStateValidForNewCharges ||
    !isLastChargeAttemptValidForNewCharges
  ) {
    return <span data-testid="EMPTY_INVOICE_NEW_CHARGE" />;
  }

  const newChargeAttempt = () => mutate();

  return (
    <Button
      size="sm"
      emphasis="high"
      onClick={newChargeAttempt}
      disabled={chargeAttemptState === 'PENDING'}
      loading={isPending || isPolling}
    >
      <FormattedMessage {...messages.newChargeAttempt} />
    </Button>
  );
};
