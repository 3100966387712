import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { FormNumberInputField } from '~/common';

import messages from './messages';

export const LockCode = () => (
  <FormNumberInputField
    name="lockCode"
    id="lock-code"
    className="w-28 shrink-0"
    label={
      <InputLabel htmlFor="lock-code">
        <FormattedMessage {...messages.lockCode} />
      </InputLabel>
    }
  />
);
