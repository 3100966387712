import { NumberFormatBase, NumberFormatBaseProps } from 'react-number-format';
import { Input, InputProps } from '@cooltra/ui';
import { ReactNode } from 'react';

export type NumberInputFieldProps = NumberFormatBaseProps<InputProps> & {
  error?: ReactNode;
  label?: ReactNode;
  maxLength?: number;
};

export const NumberInputField = ({
  error,
  label,
  className,
  maxLength,
  ...rest
}: NumberInputFieldProps) => {
  const format = (val: string) => {
    if (val.length === 0) {
      return '';
    }
    if (maxLength && val.length > maxLength) {
      return val.substring(0, maxLength);
    }
    return val;
  };

  return (
    <div className={className}>
      {label && <div className="flex flex-row items-end mb-1">{label}</div>}
      <NumberFormatBase
        format={format}
        customInput={Input}
        invalid={!!error}
        {...rest}
      />
      {error && <div className="flex flex-row items-end mt-1">{error}</div>}
    </div>
  );
};
