import { defineMessages } from 'react-intl';

export default defineMessages({
  dateInThePastError: {
    defaultMessage: 'Date cannot be in the past',
  },
  subscriptionSuccessfullyAdded: {
    defaultMessage: 'Subscriptions has been successfully added',
  },
});
