import { defineMessages } from 'react-intl';

export default defineMessages({
  personalDetails: {
    defaultMessage: 'Personal details',
  },
  contactAndAddress: {
    defaultMessage: 'Contact & Address',
  },
});
