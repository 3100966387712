import { UserSuspension } from '@cooltra/api';
import { Card, Divider } from '@cooltra/ui';

import { DateTime } from '../DateTime';
import { ByOperator } from '../Operator';

import { ClearedSuspensionRowLayout } from './ClearedSuspensionRowLayout';
import { SuspensionBadge } from './SuspensionBadge/SuspensionBadge';

export type ClearedSuspensionCardProps = {
  suspension: UserSuspension;
};

export const ClearedSuspensionCard = ({
  suspension: {
    type,
    comment,
    createdAt,
    createdBy,
    clearedAt,
    clearedBy,
    clearComment,
  },
}: ClearedSuspensionCardProps) => (
  <Card className="opacity-50">
    <ClearedSuspensionRowLayout
      className="py-4"
      type={<SuspensionBadge type={type} className="mt-2" />}
      reason={
        <>
          <div className="mb-3">
            <DateTime date={createdAt} />
            <ByOperator id={createdBy} />
          </div>
          <p className="text-neutral-1000 text-sm">{comment}</p>
        </>
      }
      divider={<Divider direction="vertical" className="h-full" />}
      clearance={
        <>
          <div className="mb-3">
            <DateTime date={clearedAt} />
            <ByOperator id={clearedBy} />
          </div>
          <p className="text-neutral-1000 text-sm">{clearComment}</p>
        </>
      }
    />
  </Card>
);
