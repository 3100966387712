import { defineMessages } from 'react-intl';

export default defineMessages({
  missingUserData: {
    defaultMessage: 'Missing user data',
  },
  missingVehicleData: {
    defaultMessage: 'Missing vehicle data',
  },
  assignLicensePlate: {
    defaultMessage: 'Assign license plate',
  },
  days: {
    defaultMessage: '{totalDays, plural, one {# day} other {# days}}',
  },
  missingCheckIn: {
    defaultMessage: 'Missing check-in',
  },
});
