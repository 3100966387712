import { useField } from '@cooltra/form';
import { ToggleField } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const InTransit = () => {
  const { value, setValue } = useField<boolean>('inTransit');

  return (
    <div>
      <span className="block text-base font-semibold text-neutral-800 mb-3">
        <FormattedMessage {...messages.vehicleInTransit} />
      </span>
      <div className="flex items-center gap-3">
        <ToggleField
          data-testid="IN_TRANSIT_FIELD"
          id="in-transit-field"
          checked={value}
          onChange={({ target }) => setValue(target.checked)}
        />
        <FormattedMessage tagName="span" {...messages.active} />
      </div>
    </div>
  );
};
