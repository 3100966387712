import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckboxField, InputLabel } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';

import { CooltraForEmployeesFormValues } from '../../Form';

import { RedeemableFrom } from './RedeemableFrom';
import { RedeemableUntil } from './RedeemableUntil';
import messages from './messages';

export const RedeemableDates = () => {
  const {
    values: { editMode, redeemableDoesNotExpire },
    setValues,
    isSubmitting,
  } = useFormContext<CooltraForEmployeesFormValues>();

  const handleChange = ({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setValues({
        redeemableUntilDate: '',
        redeemableUntilTime: '',
        redeemableDoesNotExpire: checked,
      });
    } else {
      setValues({ redeemableDoesNotExpire: checked });
    }
  };

  return (
    <div
      className="flex gap-x-8 items-start"
      data-testid="REDEEMABLE_VALIDITY_DATES"
    >
      <RedeemableFrom disabled={editMode} />
      <RedeemableUntil disabled={redeemableDoesNotExpire || editMode} />
      <CheckboxField
        className="mt-8"
        id="promotion-expires"
        checked={redeemableDoesNotExpire}
        label={
          <InputLabel emphasis="low" htmlFor="promotion-expires">
            <FormattedMessage {...messages.doesntExpire} />
          </InputLabel>
        }
        disabled={isSubmitting || editMode}
        onChange={handleChange}
      />
    </div>
  );
};
