import { IntlShape } from 'react-intl';
import { FormErrors } from '@cooltra/form';
import { differenceInMinutes } from 'date-fns';

import { validateRequired } from '~/validation';

import messages from './messages';

export type RentalManualCloseFormValues = {
  billableDuration: string;
};

export const rentalManualCloseFormInitialValues: RentalManualCloseFormValues = {
  billableDuration: '',
};

export const validateRentalManualCloseForm =
  (intl: IntlShape, rentalCreatedAt: string) =>
  (
    values: RentalManualCloseFormValues
  ): FormErrors<RentalManualCloseFormValues> => {
    const errors: FormErrors<RentalManualCloseFormValues> = {};

    const totalDurationInMinutes =
      differenceInMinutes(new Date(), new Date(rentalCreatedAt)) || 1;

    const isTotalMinutesGreaterThanDuration =
      !!Number(values.billableDuration) &&
      Number(values.billableDuration) > totalDurationInMinutes;

    if (isTotalMinutesGreaterThanDuration) {
      errors.billableDuration = intl.formatMessage(messages.invalid);
    }

    const requiredErrorMessage = validateRequired(
      intl,
      values.billableDuration
    );
    if (requiredErrorMessage) {
      errors.billableDuration = requiredErrorMessage;
    }

    return errors;
  };
