import { defineMessages } from 'react-intl';

import { POSView } from './types';

export const calendarFilterMessages = defineMessages<POSView>({
  daily: {
    defaultMessage: 'Daily',
  },
  weekly: {
    defaultMessage: 'Weekly',
  },
  monthly: {
    defaultMessage: 'Monthly',
  },
});

export default defineMessages({
  today: {
    defaultMessage: 'Today',
  },
  next: {
    defaultMessage: 'Next',
  },
  previous: {
    defaultMessage: 'Previous',
  },
});
