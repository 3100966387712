import { ProductCategory } from '@cooltra/station-based-api';

type PricesProductCategoryType = `prices.${ProductCategory}.type`;
type PricesProductCategoryPriceValue = `prices.${ProductCategory}.price.value`;
type PricesProductCategoryPriceCurrency =
  `prices.${ProductCategory}.price.currency`;

export type PriceListFormValues = {
  dateFrom: string;
  dateUntil: string;
  pricingGroup: string;
} & Record<PricesProductCategoryType, string> &
  Record<PricesProductCategoryPriceValue, string> &
  Record<PricesProductCategoryPriceCurrency, string>;

export const priceListInitialFormValues: PriceListFormValues = {
  dateFrom: '',
  dateUntil: '',
  pricingGroup: '',
  'prices.EBIKE.type': '',
  'prices.EBIKE.price.value': '',
  'prices.EBIKE.price.currency': '',
  'prices.PREMIUM.type': '',
  'prices.PREMIUM.price.value': '',
  'prices.PREMIUM.price.currency': '',
  'prices.STANDARD.type': '',
  'prices.STANDARD.price.value': '',
  'prices.STANDARD.price.currency': '',
  'prices.PLUS.type': '',
  'prices.PLUS.price.value': '',
  'prices.PLUS.price.currency': '',
  'prices.SUPER.type': '',
  'prices.SUPER.price.value': '',
  'prices.SUPER.price.currency': '',
};
