import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';
import { useLocation } from 'react-router-dom';
import { getParams } from '@cooltra/utils';

import {
  IncidentsFiltersFormValues,
  incidentsFiltersInitialValues,
} from './incidents-filters-form';

const { system, operatorGroupId, operatorId, open, sort } =
  incidentsFiltersInitialValues;

export const IncidentsFiltersForm = ({ children }: PropsWithChildren) => {
  const { search } = useLocation();

  const params = getParams(search);

  const initialValues = {
    system: params.array('system').length ? params.array('system') : system,
    operatorGroupId: params.string('operatorGroupId') || operatorGroupId,
    operatorId: params.string('operatorId') || operatorId,
    open: params.string('open') || open,
    sort: params.string('sort') || sort,
  } as IncidentsFiltersFormValues;

  return <FormProvider initialValues={initialValues}>{children}</FormProvider>;
};
