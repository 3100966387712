import { useUserQuery } from '@cooltra/api';
import { Card } from '@cooltra/ui';
import { countries, CountryCode } from '@cooltra/countries';

import { UserPhoto } from '~/common';

export type UserInfoProps = {
  userId: string;
};

export const UserInfo = ({ userId }: UserInfoProps) => {
  const { data: user, isError, isLoading } = useUserQuery(userId);

  if (isError || isLoading || !user) {
    return null;
  }

  const getPhotos = () => {
    const { driverLicenseBack, driverLicenseFront, idCardBack, idCardFront } =
      user.photos;

    if (driverLicenseFront) {
      return (
        <>
          <UserPhoto
            initialSize="lg"
            isFixedSize
            imageKey={driverLicenseFront}
            data-testid="DRIVER_LICENSE_FRONT"
          />
          {driverLicenseBack && (
            <UserPhoto
              initialSize="lg"
              isFixedSize
              imageKey={driverLicenseBack}
              data-testid="DRIVER_LICENSE_BACK"
            />
          )}
        </>
      );
    }

    return (
      <>
        <UserPhoto
          initialSize="lg"
          isFixedSize
          imageKey={idCardFront}
          data-testid="DRIVER_ID_CARD_FRONT"
        />
        {idCardBack && (
          <UserPhoto
            initialSize="lg"
            isFixedSize
            imageKey={idCardBack}
            data-testid="DRIVER_ID_CARD_BACK"
          />
        )}
      </>
    );
  };

  return (
    <Card data-testid="TRAFFIC_OFFENSE_USER_INFO">
      <div className="grid grid-cols-3">
        <div className="col-span-2">
          <div className="py-4 pl-4 flex flex-col gap-4">{getPhotos()}</div>
        </div>
        <div className="px-10 pt-8 flex flex-col gap-4 text-neutral-800">
          <h6 className="text-lg">{user.name}</h6>
          <span>{user.phone}</span>
          <span>{user.email}</span>
          <div className="flex flex-col">
            <span>{user.address.street}</span>
            <span>{user.address.city}</span>
            <span>{countries[user.address.country as CountryCode].name}</span>
            <span>{user.address.zip}</span>
          </div>
        </div>
      </div>
    </Card>
  );
};
