import { User } from '@cooltra/api';

import { ReviewFormValues } from '../review-form-types';

export const reviewFormInitialValues: ReviewFormValues = {
  arePhotosValid: '',
  isFraud: '',
  isValidSelfie: '',
  isValidIdPhoto: '',
  isValidDriverLicensePhoto: '',

  isValidId: '',
  idCardNumber: '',
  dateOfBirth: '',
  taxIdNumber: '',
  taxIdCountry: '',

  isAddressComplete: '',
  zip: '',
  street: '',
  city: '',
  country: '',

  isValidDriverLicense: '',
  driverLicenseExpirationDate: '',
  driverLicenseCategory: '',
  driverLicenseCountry: '',
  driverLicenseNumber: '',

  comments: '',
};

export const parseReviewFormValues = ({
  idCardNumber,
  dateOfBirth,
  taxIdNumber,
  taxIdCountry,
  address: { zip, street, city, country },
  driverLicenseExpirationDate,
  driverLicenseCategory,
  driverLicenseCountry,
  driverLicenseNumber,
}: User): ReviewFormValues => ({
  ...reviewFormInitialValues,
  idCardNumber,
  dateOfBirth,
  taxIdNumber,
  taxIdCountry,
  zip,
  street,
  city,
  country,
  driverLicenseExpirationDate,
  driverLicenseCategory,
  driverLicenseCountry,
  driverLicenseNumber,
});
