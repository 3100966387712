import type { SVGProps } from 'react';

export const Android = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.847 1.372a.417.417 0 0 1 .59 0l.92.921a4.684 4.684 0 0 1 2.349-.626h.588c.856 0 1.659.228 2.35.627l.922-.922a.417.417 0 1 1 .59.59l-.82.82A4.696 4.696 0 0 1 15 6.371a.294.294 0 0 1-.294.295H5.294A.294.294 0 0 1 5 6.373c0-1.44.647-2.73 1.666-3.593l-.819-.819a.417.417 0 0 1 0-.589zM8.75 4.375a.625.625 0 1 1-1.25 0 .625.625 0 0 1 1.25 0zM11.853 5a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M3.333 7.456a.833.833 0 0 0-.833.833v5a.833.833 0 0 0 1.667 0v-5a.833.833 0 0 0-.834-.833zm2.5 0A.833.833 0 0 0 5 8.29v7.5c0 .46.373.834.833.834h8.334c.46 0 .833-.373.833-.834v-7.5a.833.833 0 0 0-.833-.833H5.833zm10.176.834a.833.833 0 0 1 1.666 0v5a.833.833 0 0 1-1.666 0v-5zM6.495 17.5a.245.245 0 0 0-.245.245v.172a1.458 1.458 0 0 0 2.917 0v-.172a.245.245 0 0 0-.245-.245H6.495zm4.338.245c0-.136.11-.245.245-.245h2.427c.135 0 .245.11.245.245v.172a1.458 1.458 0 1 1-2.917 0v-.172z"
    />
  </svg>
);
