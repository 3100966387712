import { FormattedMessage } from 'react-intl';
import { MdWorkOutline } from 'react-icons/md';

import messages from './messages';
import { StatusContainer } from './StatusContainer';

type TopCaseProps = {
  closed?: boolean | undefined;
};

export const TopCase = ({ closed }: TopCaseProps) => {
  const getContent = () => {
    if (closed === undefined) {
      return <></>;
    }

    if (closed) {
      return (
        <>
          <MdWorkOutline className="w-auto h-5 text-success-600" />
          <FormattedMessage {...messages.closed} />
        </>
      );
    }

    return (
      <>
        <MdWorkOutline className="w-auto h-5 text-neutral-200" />
        <span className="text-neutral-300">
          <FormattedMessage {...messages.open} />
        </span>
      </>
    );
  };

  return <StatusContainer>{getContent()}</StatusContainer>;
};
