import { defineMessages } from 'react-intl';

export default defineMessages({
  allVehicleTypes: {
    defaultMessage: 'All vehicle types',
  },
  vehicleTypes: {
    defaultMessage: 'Vehicle types',
  },
  yes: {
    defaultMessage: 'Yes',
  },
  no: {
    defaultMessage: 'No',
  },
});
