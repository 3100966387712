import { defineMessages } from 'react-intl';

export default defineMessages({
  pickUpPicture: {
    defaultMessage: 'Parking photo',
  },
  dropOffPicture: {
    defaultMessage: 'Client parking photo',
  },
  pickUpGeolocation: {
    defaultMessage: 'Geolocation',
  },
  dropOffGeolocation: {
    defaultMessage: 'Client geolocation',
  },
  vehicleLockCode: {
    defaultMessage: 'Vehicle lock code:',
  },
  noParkingPhoto: {
    defaultMessage: "There's no parking photo yet.",
  },
  sendPickUpInstructions: {
    defaultMessage: 'Send pickup instructions',
  },
  sendDropOffInstructions: {
    defaultMessage: 'Send dropoff instructions',
  },
  pickUpEmailSuccessfullySent: {
    defaultMessage: 'Email with pickup instructions has been sent successfully',
  },
  dropOffEmailSuccessfullySent: {
    defaultMessage:
      'Email with dropoff instructions has been sent successfully',
  },
});
