import { Divider, Placeholder, Tooltip } from '@cooltra/ui';
import { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { getErrorStatus } from '@cooltra/axios';
import { isBike } from '@cooltra/vehicle-utils';

import { BatteryState, NotAvailable } from '~/common';

import { NoDataAvailable } from '../../NoDataAvailable/NoDataAvailable';
import { TelematicsItemLayout } from '../TelematicsItemLayout/TelematicsItemLayout';
import { TelematicsFieldProps } from '../types';

import messages from './messages';
import { Voltage } from './Voltage';
import { Range } from './Range';

export const TelematicsBattery = forwardRef<
  HTMLDivElement,
  TelematicsFieldProps
>(
  (
    { vehicleStatus, model = 'ASKOLL_ES2', telematics = 'ASTRA', error },
    ref
  ) => {
    const { formatMessage } = useIntl();

    if (getErrorStatus(error) === 404) {
      return (
        <TelematicsItemLayout
          title={formatMessage(messages.battery)}
          value={<NoDataAvailable />}
        />
      );
    }

    if (!vehicleStatus) {
      return (
        <TelematicsItemLayout
          title={formatMessage(messages.battery)}
          value={<Placeholder className="w-20 h-2" />}
        />
      );
    }

    if (vehicleStatus.batteryCharge === null) {
      return (
        <TelematicsItemLayout
          title={formatMessage(messages.battery)}
          value={<NoDataAvailable />}
        />
      );
    }

    const areBatteriesUnbalanced =
      vehicleStatus.battery1Charge !== null &&
      vehicleStatus.battery2Charge !== null &&
      Math.abs(
        vehicleStatus.battery1Charge.value - vehicleStatus.battery2Charge.value
      ) > 15;

    return (
      <TelematicsItemLayout
        title={formatMessage(messages.battery)}
        value={
          <Tooltip
            button={
              <div className="flex flex-col items-end" ref={ref}>
                <BatteryState
                  value={vehicleStatus.batteryCharge.value || 0}
                  model={model}
                />
                {areBatteriesUnbalanced && (
                  <p className="text-xs mt-1.5">
                    <span className="text-danger-500 font-semibold">
                      Unbalanced
                    </span>
                    <span className="text-neutral-300">{' >'} 15%</span>
                  </p>
                )}
              </div>
            }
            content={
              <div
                data-testid="BATTERY_TOOLTIP"
                className="w-60 px-5 py-4 flex flex-col gap-2"
              >
                {!isBike({ telematics }) && (
                  <>
                    <div className="flex justify-between">
                      <div>
                        <p className="text-neutral-300 text-xs mb-2">
                          Battery A
                        </p>
                        {vehicleStatus.battery1Charge ? (
                          <BatteryState
                            value={vehicleStatus.battery1Charge?.value || 0}
                            model={model}
                          />
                        ) : (
                          <NotAvailable />
                        )}
                      </div>
                      <div>
                        <p className="text-neutral-300 text-xs mb-2">
                          Battery B
                        </p>
                        {vehicleStatus.battery2Charge ? (
                          <BatteryState
                            value={vehicleStatus.battery2Charge?.value || 0}
                            model={model}
                          />
                        ) : (
                          <NotAvailable />
                        )}
                      </div>
                    </div>
                    <Divider className="my-2" />
                  </>
                )}
                <Voltage voltage={vehicleStatus.voltage} />
                <Range range={vehicleStatus.range} />
              </div>
            }
          />
        }
        updatedAt={
          vehicleStatus.updatedAt === vehicleStatus.batteryCharge.updatedAt
            ? undefined
            : vehicleStatus.batteryCharge.updatedAt
        }
      />
    );
  }
);
