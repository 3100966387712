import { FormattedMessage } from 'react-intl';
import { RouterNavLink } from '@cooltra/navigation';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { stringifyParams } from '@cooltra/utils';

import { yesterday } from '~/utils/date';

import messages from '../messages';

export const WorkUnits = () => {
  const { hasPermission } = useAuthClaimsQuery();

  if (!hasPermission('read:work_unit')) {
    return <></>;
  }

  const defaultShiftsParams = {
    system: [],
    minDate: yesterday,
    maxDate: '',
    operatorId: '',
    sort: 'createdAt.desc',
  };

  return (
    <li>
      <RouterNavLink to={`/shifts?${stringifyParams(defaultShiftsParams)}`}>
        <FormattedMessage {...messages.shifts} />
      </RouterNavLink>
    </li>
  );
};
