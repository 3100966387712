import {
  ProblemReportListItem,
  useProblemReportsQuery,
  useProblemReportsTotal,
} from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { equal } from '@cooltra/utils';
import { useFormContext } from '@cooltra/form';
import { useParams } from 'react-router-dom';
import { InfiniteList } from '@cooltra/ui';
import { useEffect } from 'react';

import {
  ErrorPage,
  FilterFields,
  Four0Three,
  NoSearchResults,
  ProblemReportsTotal,
} from '~/common';
import { useNavigateWithQueryParams } from '~/hooks';

import { UserProblemReportsListHeader } from './UserProblemReportsListHeader';
import {
  UserProblemReportCard,
  UserProblemReportCardLoading,
} from './UserProblemReportCard';
import {
  UserProblemReportsFiltersFormValues,
  userProblemReportsFiltersInitialValues,
} from './user-problem-reports-filters-form';
import { NoUserProblemReports } from './NoUserProblemReports/NoUserProblemReports';

export const UserProblemReportsList = () => {
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const { id = '' } = useParams<'id'>();

  const { hasPermission } = useAuthClaimsQuery();

  const { values } = useFormContext<UserProblemReportsFiltersFormValues>();

  const { data, isLoading, fetchNextPage, isFetchingNextPage, isError, error } =
    useProblemReportsQuery({ ...values, userId: id });

  useEffect(() => {
    navigateWithQueryParams(values);
  }, [navigateWithQueryParams, values]);

  const problemReports = data?.pages
    ? ([] as ProblemReportListItem[]).concat.apply([], data.pages)
    : [];

  const problemReportsTotal =
    useProblemReportsTotal({ ...values, userId: id }) || 0;

  const noProblemReportsFound = !isLoading && !problemReports?.length;

  if (!hasPermission('read:problem_reports')) {
    return <Four0Three />;
  }

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (
    noProblemReportsFound &&
    equal(values, userProblemReportsFiltersInitialValues)
  ) {
    return <NoUserProblemReports />;
  }

  return (
    <div className="container min-w-3xl max-w-5xl py-12">
      <div className="flex gap-8 mb-10">
        <div className="w-60">
          <FilterFields.Status />
        </div>
        <FilterFields.Created />
      </div>
      <ProblemReportsTotal
        loading={isLoading}
        total={problemReportsTotal}
        className="mb-6"
      />
      <InfiniteList
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasMore={problemReports.length < problemReportsTotal}
        fetchMore={fetchNextPage}
        idProp="problemReportId"
        data={problemReports}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<UserProblemReportsListHeader />}
        renderLoadingRow={<UserProblemReportCardLoading />}
        renderRow={(problemReport: ProblemReportListItem) => (
          <UserProblemReportCard problemReport={problemReport} />
        )}
      />
    </div>
  );
};
