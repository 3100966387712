import { defineMessages } from 'react-intl';

export default defineMessages({
  offers: {
    defaultMessage: 'Offers',
  },
  thereAreNoOffers: {
    defaultMessage: 'There are no active offers yet.',
  },
});
