import { FormattedMessage, FormattedTime } from 'react-intl';
import { classNames } from '@cooltra/utils';
import { VehicleTake } from '@cooltra/api';
import { RouterLink } from '@cooltra/navigation';

import { WorkActionDot } from '../WorkActionDot/WorkActionDot';
import { WorkActionRowLayout } from '../WorkActionRowLayout/WorkActionRowLayout';

import messages from './messages';

type VehicleTakenRowProps = {
  vehicleTake: VehicleTake;
};

export const VehicleTakenRow = ({ vehicleTake }: VehicleTakenRowProps) => {
  return (
    <div className="relative">
      <WorkActionRowLayout
        time={
          <span
            className={classNames(
              'text-sm tabular-nums font-semibold text-neutral-800'
            )}
          >
            <FormattedTime
              value={vehicleTake.createdAt}
              hour="2-digit"
              hourCycle="h23"
              minute="2-digit"
            />
          </span>
        }
        timeline={<WorkActionDot />}
        content={
          <div className="flex flex-col gap-2">
            <span className={classNames('block font-semibold text-sm mt-0.5')}>
              <FormattedMessage {...messages.takenVehicle} />
            </span>
            <RouterLink
              to={`/vehicles/${vehicleTake.vehicleId}`}
              target="_blank"
              className="block text-xs top-1 relative text-neutral-500"
            >
              {vehicleTake.vehicleExternalId} ({vehicleTake.reason})
            </RouterLink>
          </div>
        }
      />
    </div>
  );
};
