import { useVehicleParkedNext, useVehicleParkedTotal } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { Divider, SpinnerScreen } from '@cooltra/ui';
import { useEffect, useState } from 'react';
import { useFormContext } from '@cooltra/form';
import { keepPreviousData } from '@tanstack/react-query';

import { ErrorPage, Four0Three } from '~/common';
import { useNavigateWithQueryParams } from '~/hooks';

import { Rental } from '../Rental/Rental';
import { FilterToolbar } from '../FilterToolbar/FilterToolbar';
import { FinesReviewedMessage } from '../FinesReviewedMessage/FinesReviewedMessage';
import { ParkedVehiclesReviewFormValues } from '../ParkedVehiclesReviewForm';
import { VehicleBonusFloatingMenu } from '../VehicleBonusFloatingMenu/VehicleBonusFloatingMenu';

export const ParkedVehiclesReview = () => {
  const navigateWithFilters = useNavigateWithQueryParams();

  const { values } = useFormContext<ParkedVehiclesReviewFormValues>();

  const { data: vehicleParkedNext, ...vehicleParkedNextQuery } =
    useVehicleParkedNext(values, {
      refetchOnWindowFocus: false,
      retry: false,
      placeholderData: keepPreviousData,
    });

  const {
    data: vehiclesParkedTotal,
    isRefetching,
    isSuccess,
  } = useVehicleParkedTotal(values, {
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: keepPreviousData,
  });

  const [counter, setCounter] = useState(1);

  useEffect(() => {
    navigateWithFilters(values);
    setCounter(1);
  }, [navigateWithFilters, values]);

  useEffect(() => {
    if (!isRefetching && isSuccess) {
      setCounter(1);
    }
  }, [isRefetching, isSuccess]);

  const increment = () => setCounter(counter + 1);

  if (getErrorStatus(vehicleParkedNextQuery.error) === 403) {
    return <Four0Three />;
  }

  if (getErrorStatus(vehicleParkedNextQuery.error) === 404) {
    return (
      <main className="m-auto mt-6">
        <div className="w-[70rem] flex flex-col gap-4 pb-32">
          <FilterToolbar
            vehiclesParkedTotal={vehiclesParkedTotal?.total || 0}
          />
          <Divider />
          <FinesReviewedMessage />
        </div>
      </main>
    );
  }

  if (vehicleParkedNextQuery.error) {
    return <ErrorPage />;
  }

  if (!vehicleParkedNext || !vehiclesParkedTotal) {
    return <SpinnerScreen />;
  }

  return (
    <main className="mx-auto">
      <div className="w-[70rem] flex flex-col items-start gap-4 pt-6 pb-32">
        <FilterToolbar vehiclesParkedTotal={vehiclesParkedTotal?.total || 0} />
        <Divider />
        <Rental
          counter={counter}
          rentalId={vehicleParkedNext.rentalId}
          parkingPhoto={vehicleParkedNext.parkingPhoto}
          vehiclesParkedTotal={vehiclesParkedTotal?.total || 0}
          vehicle={vehicleParkedNext.vehicle}
        />
      </div>
      <VehicleBonusFloatingMenu increment={increment} counter={counter} />
    </main>
  );
};
