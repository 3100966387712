import { FormattedMessage } from 'react-intl';
import { InputLabel } from '@cooltra/ui';

import { FormFieldWithName } from '~/utils/form';

import { FormPhoneField } from '../../../FormattedInput';

import messages from './messages';

export const MobilePhone = ({ name = 'phone' }: FormFieldWithName) => (
  <FormPhoneField
    name={name}
    id="mobile-phone"
    label={
      <InputLabel htmlFor="mobile-phone">
        <FormattedMessage {...messages.mobilePhone} />
      </InputLabel>
    }
  />
);
