import { RentalListItem } from '@cooltra/api';
import { RouterCardLink } from '@cooltra/navigation';
import { classNames } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';

import { cities } from '~/common/System/messages';

import {
  DropOffListItem,
  PickUp,
  RentalDuration,
  RentalIncidentsBadge,
  RentalStateBadge,
  RentalSystem,
} from '../../Rental';
import { VehicleLink } from '../../Vehicle';
import { UserRentalRowLayout } from '../UserRentalRowLayout';

export const UserRentalCard = ({
  rentalId,
  state,
  system,
  vehicle,
  incidentIds,
  pickUp,
  dropOff,
}: RentalListItem) => (
  <RouterCardLink
    to={`/rentals/${rentalId}`}
    data-testid={`RENTAL_CARD_${rentalId}`}
    className={classNames('py-4', state === 'ACTIVE' && 'bg-success-50/50')}
    isMultiAction
  >
    <UserRentalRowLayout
      pickUp={<PickUp pickUp={pickUp} />}
      dropOff={<DropOffListItem dropOff={dropOff} />}
      duration={
        <RentalDuration
          startDate={pickUp.createdAt}
          finishDate={dropOff?.createdAt || new Date()}
          className="text-neutral-400 text-sm"
        />
      }
      vehicle={<VehicleLink vehicle={vehicle} />}
      system={
        <RentalSystem>
          <FormattedMessage {...cities[system]} />
        </RentalSystem>
      }
      incidents={
        <RentalIncidentsBadge rentalId={rentalId} incidentIds={incidentIds} />
      }
      state={<RentalStateBadge state={state} />}
    />
  </RouterCardLink>
);
