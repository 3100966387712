import { BadgePlaceholder, Card, Placeholder } from '@cooltra/ui';

import { VehicleTaskRowLayout } from '../VehicleTaskRowLayout';

export const VehicleTaskCardLoading = () => (
  <Card className="h-28 flex items-center">
    <VehicleTaskRowLayout
      task={
        <>
          <Placeholder className="w-28 h-3 mt-2 mb-4" />
          <div className="flex gap-2">
            <BadgePlaceholder className="w-20" />
            <BadgePlaceholder className="w-20" />
          </div>
        </>
      }
      assignee={<Placeholder className="w-24 h-2" />}
      updated={
        <>
          <Placeholder className="w-32 h-2 mb-3" />
          <Placeholder className="w-24 h-2" />
        </>
      }
      created={
        <>
          <Placeholder className="w-32 h-2 mb-3" />
          <Placeholder className="w-24 h-2" />
        </>
      }
    />
  </Card>
);
