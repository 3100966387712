import { Button, Divider, Link, Tooltip } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';
import {
  useDeleteUserMutation,
  useUserInvoicesQuery,
  useUserUninvoicedChargesQuery,
} from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getZeusErrorMessage } from '@cooltra/axios';
import { useToggle } from '@cooltra/hooks';
import { MdErrorOutline, MdOutlineOpenInNew } from 'react-icons/md';

import { useNotification } from '~/hooks';
import { DeleteModal } from '~/common';

import messages from './messages';

type DeleteUserProps = {
  email: string | null;
};

export const DeleteUser = ({ email }: DeleteUserProps) => {
  const { formatMessage } = useIntl();

  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const navigate = useNavigate();
  const { id = '' } = useParams<'id'>();

  const { hasPermission } = useAuthClaimsQuery();

  const { mutateAsync, isPending } = useDeleteUserMutation(id);
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const { data: invoicesResponse } = useUserInvoicesQuery(id);
  const { data: uninvoicedCharges } = useUserUninvoicedChargesQuery(id);
  const hasUninvoicedCharges = uninvoicedCharges?.length !== 0;
  const hasPaidAllInvoices = invoicesResponse?.invoices.every(
    ({ state }) => state === 'PAID'
  );
  const canDeleteUser = !hasUninvoicedCharges && hasPaidAllInvoices;

  const handleConfirm = () =>
    mutateAsync()
      .then(() => {
        toggleOff();
        addSuccessNotification(formatMessage(messages.success));
        navigate(`/users/search?keyword=${email}`);
      })
      .catch((error) => {
        toggleOff();
        const zeusErrorMessage = getZeusErrorMessage(error);
        addErrorNotification(zeusErrorMessage);
      });

  return (
    <div className="flex items-center">
      {hasPermission('delete:users') && (
        <>
          <DeleteModal
            onClose={toggleOff}
            onConfirm={handleConfirm}
            isOpen={isOpen}
            isDeleting={isPending}
          />
          <Button
            variant="danger"
            emphasis="low"
            onClick={toggleOn}
            disabled={!canDeleteUser}
          >
            <FormattedMessage {...messages.deleteBtn} />
          </Button>
          {!canDeleteUser && (
            <Tooltip
              interactive
              button={
                <button
                  type="button"
                  className="text-3xl text-warning-600"
                  aria-label={formatMessage(messages.warning)}
                >
                  <MdErrorOutline />
                </button>
              }
              content={
                <div
                  className="p-6 w-96 pb-10"
                  data-testid="DELETE_USER_WARNING_TOOLTIP"
                >
                  <h5 className="mb-5 text-neutral-900">
                    <FormattedMessage {...messages.userCannotBeDeleted} />
                  </h5>
                  <Divider />
                  {hasUninvoicedCharges && (
                    <>
                      <div className="flex justify-between py-4">
                        <FormattedMessage
                          {...messages.uninvoicedCharges}
                          tagName="span"
                        />
                        <span className="flex items-center gap-4">
                          <span className="text-sm text-neutral-800">
                            {uninvoicedCharges?.length}
                          </span>
                          <Link
                            target="_blank"
                            className="text-lg"
                            href={`/users/${id}/account/billing/uninvoiced-charges`}
                            aria-label={formatMessage(
                              messages.uninvoicedChargesLink
                            )}
                          >
                            <MdOutlineOpenInNew />
                          </Link>
                        </span>
                      </div>
                      <Divider />
                    </>
                  )}
                  {!hasPaidAllInvoices && (
                    <>
                      <div className="flex justify-between py-4">
                        <FormattedMessage
                          {...messages.unpaidInvoices}
                          tagName="span"
                        />
                        <span className="flex items-center gap-4">
                          <span className="text-sm text-neutral-800">
                            {
                              invoicesResponse?.invoices.filter(
                                ({ state }) => state !== 'PAID'
                              ).length
                            }
                          </span>
                          <Link
                            target="_blank"
                            className="text-lg"
                            href={`/users/${id}/account/billing/invoices`}
                            aria-label={formatMessage(messages.invoicesLink)}
                          >
                            <MdOutlineOpenInNew />
                          </Link>
                        </span>
                      </div>
                      <Divider />
                    </>
                  )}
                </div>
              }
            />
          )}
        </>
      )}
    </div>
  );
};
