import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type DepositsRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  coverage: ReactNode;
  eBike: ReactNode;
  standard: ReactNode;
  plus: ReactNode;
  premium: ReactNode;
  superpremium: ReactNode;
};

export const DepositsRowLayout = ({
  coverage,
  eBike,
  standard,
  plus,
  premium,
  superpremium,
  className,
  ...rest
}: DepositsRowLayoutProps) => (
  <div className={classNames('flex', 'h-full', 'w-full', className)} {...rest}>
    <div className="flex items-center w-full px-8 gap-4">
      <div className="w-64 shrink-0">{coverage}</div>
      <div className="flex-1 shrink-0">{eBike}</div>
      <div className="flex-1 shrink-0">{standard}</div>
      <div className="flex-1 shrink-0">{plus}</div>
      <div className="flex-1 shrink-0">{premium}</div>
      <div className="flex-1 shrink-0">{superpremium}</div>
    </div>
  </div>
);
