import { defineMessages } from 'react-intl';

export default defineMessages({
  addCustomerRetries: {
    defaultMessage: 'Add customer retries',
  },
  success: {
    defaultMessage: 'You have successfully added more retries to the customer',
  },
  error404: {
    defaultMessage: 'The invoice does not exists',
  },
  error422: {
    defaultMessage: 'The invoice does not allow retries',
  },
});
