import { RentalColumnLabel } from '../Rental';

import { UserRentalRowLayout } from './UserRentalRowLayout';

export const UserRentalsListHeader = () => (
  <UserRentalRowLayout
    pickUp={<RentalColumnLabel name="pickUp" />}
    dropOff={<RentalColumnLabel name="dropOff" />}
    duration={<RentalColumnLabel name="duration" />}
    vehicle={<RentalColumnLabel name="vehicle" />}
    system={<RentalColumnLabel name="system" />}
    incidents={<RentalColumnLabel name="incidents" />}
    state={<RentalColumnLabel name="state" />}
  />
);
