import { Button, Card } from '@cooltra/ui';
import {
  Contract,
  ContractAvailableInsurances,
  ContractPayment,
  usePatchContractInsuranceMutation,
} from '@cooltra/station-based-api';
import { FormattedMessage } from 'react-intl';
import { getInsuranceName } from '@cooltra/utils';

import { useContractFlags, useNotification } from '~/hooks';
import { Amount } from '~/common';

import { Added } from '../Added/Added';

import { WarningTooltip } from './WarningTooltip';
import messages from './messages';

export type InsuranceTypeCardProps = ContractAvailableInsurances & {
  contract: Contract;
  payments: ContractPayment[];
  isAdded?: boolean;
  insuranceId: string;
};

export const InsuranceTypeCard = ({
  contract,
  payments,
  insuranceId,
  name,
  price,
  isAdded,
}: InsuranceTypeCardProps) => {
  const { mutateAsync, isPending } = usePatchContractInsuranceMutation(
    contract.contractId
  );

  const { addErrorNotification } = useNotification();
  const { hasDepositBeenWithheld, isSignedOrPendingSignature } =
    useContractFlags();

  const updateInsurance = () =>
    mutateAsync({ insuranceId }).catch(() => addErrorNotification());

  const canChangeInsurance = () => {
    if (isSignedOrPendingSignature) {
      return false;
    }

    if (contract.status === 'ACTIVE') {
      return false;
    }

    if (hasDepositBeenWithheld) {
      return false;
    }

    const isInsurancePaidFor = payments
      .filter(({ status }) => status === 'PAID')
      .filter(({ insurance }) => insurance)
      .some(
        ({ insurance }) =>
          insurance.name === contract.insurance && insurance.price.value > 0
      );

    return !isInsurancePaidFor;
  };

  return (
    <Card data-testid={`INSURANCE_${name}`} className="bg-neutral-0 p-8">
      <h2 className="mb-4 text-lg font-semibold text-neutral-800">
        {getInsuranceName(name)}
      </h2>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span className="text-3xl font-semibold text-neutral-800">
            <Amount {...price.total} />
          </span>
          {name != 'BASIC' && (
            <span className="text-base font-medium text-neutral-600">
              <FormattedMessage
                {...messages.dailyPrice}
                values={{
                  amount: <Amount {...price.daily} />,
                }}
              />
            </span>
          )}
        </div>
      </div>
      <div className="mt-4 flex items-center justify-end h-11">
        {isAdded ? (
          <Added />
        ) : (
          <div className="flex items-center gap-2">
            <Button
              disabled={!canChangeInsurance()}
              loading={isPending}
              onClick={updateInsurance}
            >
              <FormattedMessage {...messages.add} />
            </Button>
            <WarningTooltip
              isContractStatusBooked={contract.status === 'BOOKED'}
              isDepositWithheld={hasDepositBeenWithheld}
            />
          </div>
        )}
      </div>
    </Card>
  );
};
