import { Label } from './Label';
import { LabelLoading } from './LabelLoading';
import { Value } from './Value';
import { ValueLoading } from './ValueLoading';

export const Pair = {
  Label,
  LabelLoading,
  Value,
  ValueLoading,
};
