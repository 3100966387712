import { PropsWithChildren } from 'react';
import { MdCoffee } from 'react-icons/md';
import { Card, Icon } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';

export const ListMessageCard = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <Card
    className={classNames(
      'h-36 flex items-center justify-center px-8',
      className
    )}
  >
    <div className="text-center">
      <Icon className="text-3xl mr-0.5 text-neutral-700">
        <MdCoffee />
      </Icon>
      <p className="text-neutral-800 text-sm max-w-xs px-6">{children}</p>
    </div>
  </Card>
);
