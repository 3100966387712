import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { FormInputField } from '@cooltra/form';

import messages from './messages';

export const Street = () => (
  <FormInputField
    name="street"
    id="street"
    label={
      <InputLabel htmlFor="street">
        <FormattedMessage {...messages.residentialAddress} />
      </InputLabel>
    }
  />
);
