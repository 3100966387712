import { useIntl } from 'react-intl';
import { useFormContext } from '@cooltra/form';

import { FormNumberInputField } from '~/common';

import { PromotionFormValues } from '../../PromotionForm';

import messages from './messages';
import { RangeDateField } from './RangeDateField';

export const ExpirationInputField = ({ disabled }: { disabled: boolean }) => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext<PromotionFormValues>();

  if (values.benefitExpirationRangeMode === 'after-days') {
    return (
      <FormNumberInputField
        disabled={disabled}
        className="w-32"
        name="benefitExpiresAfterDays"
        aria-label={formatMessage(messages.afterDays)}
      />
    );
  }

  if (values.benefitExpirationRangeMode === 'after-hours') {
    return (
      <FormNumberInputField
        disabled={disabled}
        className="w-32"
        name="benefitExpiresAfterHours"
        aria-label={formatMessage(messages.afterHours)}
      />
    );
  }

  return <RangeDateField />;
};
