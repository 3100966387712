import { FormattedMessage, useIntl } from 'react-intl';
import { FormMultiSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { useTagsQuery } from '@cooltra/api';

import messages from './messages';

export const Tag = () => {
  const { data, isLoading } = useTagsQuery();
  const { formatMessage } = useIntl();
  return (
    <FormMultiSelectField
      isLoading={isLoading}
      isClearable
      placeholder={formatMessage(messages.placeholder)}
      id="task-tag"
      name="tagId"
      noOptionsMessage={() => <FormattedMessage {...messages.noTags} />}
      options={data
        ?.map(({ tagId, name }) => ({
          label: name,
          value: tagId,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))}
      label={
        <InputLabel htmlFor="task-tag">
          <FormattedMessage {...messages.tags} />
        </InputLabel>
      }
    />
  );
};
