import { classNames } from '@cooltra/utils';
import { Marker } from 'react-map-gl';

import { CircleProps, Circle } from '~/common';

const MarkerCircle = ({ className, ...rest }: CircleProps) => (
  <Circle
    className={classNames(
      'w-5 h-5 flex bg-neutral-700 text-neutral-50 box-content',
      className
    )}
    {...rest}
  />
);

export type MarkerProps = {
  latitude: number;
  longitude: number;
};

export const VehicleMarker = ({ latitude, longitude }: MarkerProps) => (
  <Marker latitude={latitude} longitude={longitude}>
    <MarkerCircle className="w-6 h-6 border-solid border-4 border-neutral-50 outline outline-1 outline-neutral-700" />
  </Marker>
);
