import { Placeholder } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type VehiclesSkippedProps = {
  vehiclesSkipped?: number;
};

export const VehiclesSkipped = ({ vehiclesSkipped }: VehiclesSkippedProps) => (
  <div className="flex flex-col">
    {vehiclesSkipped !== undefined ? (
      <span className="text-md text-neutral-1000 font-semibold">
        {vehiclesSkipped}
      </span>
    ) : (
      <Placeholder className="w-6 h-5 mx-auto mb-1" />
    )}
    <span className="text-xs text-neutral-300">
      <FormattedMessage {...messages.vehiclesSkipped} />
    </span>
  </div>
);
