import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    defaultMessage: 'ID {id}',
  },
  age: {
    defaultMessage: 'Age {age}',
  },
  profile: {
    defaultMessage: 'Profile',
  },
  rentals: {
    defaultMessage: 'Rentals',
  },
  reservations: {
    defaultMessage: 'Reservations',
  },
  suspensions: {
    defaultMessage: 'Suspensions',
  },
  providerId: {
    defaultMessage: 'External ID:',
  },
  billing: {
    defaultMessage: 'Billing & Subscription',
  },
});
