import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type RentalTimelineRowLayoutProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'content'
> & {
  time?: ReactNode;
  timeline?: ReactNode;
  content?: ReactNode;
  isFirst?: boolean;
  isLast?: boolean;
};

export const RentalTimelineRowLayout = ({
  className,
  time,
  timeline,
  content,
  isFirst = false,
  isLast = false,
  ...rest
}: RentalTimelineRowLayoutProps) => (
  <div className={classNames('flex items-stretch', className)} {...rest}>
    <div className="w-12 shrink-0 flex items-center justify-end">{time}</div>
    <div className="w-8 shrink-0 flex items-center justify-center relative">
      {isFirst && <div className="absolute top-0 h-1/2 w-8 bg-neutral-0" />}
      {isLast && <div className="absolute bottom-0 h-1/2 w-8 bg-neutral-0" />}
      {timeline}
    </div>
    <div className="flex-1 text-left">{content}</div>
  </div>
);
