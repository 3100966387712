import { defineMessages } from 'react-intl';

export default defineMessages({
  close: {
    defaultMessage: 'Close incident',
  },
  reopen: {
    defaultMessage: 'Reopen incident',
  },
});
