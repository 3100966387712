import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { PackRowLayout } from '../PackRowLayout';

import messages from './messages';

export const PacksListHeader = () => (
  <PackRowLayout
    data-testid="PACKS_LIST_HEADER"
    code={
      <ListColumnLabel>
        <FormattedMessage {...messages.code} />
      </ListColumnLabel>
    }
    price={
      <ListColumnLabel>
        <FormattedMessage {...messages.price} />
      </ListColumnLabel>
    }
    credit={
      <ListColumnLabel>
        <FormattedMessage {...messages.credit} />
      </ListColumnLabel>
    }
    validFrom={
      <ListColumnLabel>
        <FormattedMessage {...messages.validFrom} />
      </ListColumnLabel>
    }
    validUntil={
      <ListColumnLabel>
        <FormattedMessage {...messages.validUntil} />
      </ListColumnLabel>
    }
    state={
      <ListColumnLabel>
        <FormattedMessage {...messages.state} />
      </ListColumnLabel>
    }
  />
);
