import { IntlShape } from 'react-intl';
import { User } from '@cooltra/api';

import { validateRequired, validateCity, createSetError } from '~/validation';

export type BillingAddressFormValues = {
  name: string;
  street: string;
  zip: string;
  city: string;
  country: string;
};

type BillingAddressFormFieldName = keyof BillingAddressFormValues;

type BillingAddressFormErrors = Partial<
  Record<BillingAddressFormFieldName, string | undefined>
>;

export const validateBillingAddressForm =
  (intl: IntlShape) =>
  (values: BillingAddressFormValues): BillingAddressFormErrors => {
    const errors: BillingAddressFormErrors = {};
    const setError = createSetError(errors);

    const hasAnyValue = !!Object.values(values).find((value) => !!value);

    if (hasAnyValue) {
      setError('city', validateCity(intl, values.city));

      const requiredField: BillingAddressFormFieldName[] = [
        'name',
        'street',
        'zip',
        'city',
        'country',
      ];
      requiredField.forEach((fieldName) => {
        setError(fieldName, validateRequired(intl, values[fieldName]));
      });
    }
    return errors;
  };

const billingAddressFormInitialValues: BillingAddressFormValues = {
  name: '',
  street: '',
  zip: '',
  city: '',
  country: '',
};

export const getDocumentsFormInitialValues = ({
  billingAddress,
}: User): BillingAddressFormValues => ({
  ...billingAddressFormInitialValues,
  ...billingAddress,
});

export const getUserDocumentsPayload = (
  formValues: BillingAddressFormValues,
  version: number
) => ({
  ...formValues,
  version,
});
