import { useProblemReportQuery, useUserQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { useParams } from 'react-router-dom';
import { SpinnerScreen } from '@cooltra/ui';

import { ErrorPage, Four0Three } from '~/common';
import { useVehicleQuery } from '~/hooks';

import { ViewProblemReport } from '../ViewProblemReport/ViewProblemReport';

export const ProblemReport = () => {
  const { problemReportId = '' } = useParams<'problemReportId'>();
  const { hasPermission } = useAuthClaimsQuery();
  const { data: problemReport, ...problemReportQuery } =
    useProblemReportQuery(problemReportId);

  const { data: vehicle, ...vehicleQuery } = useVehicleQuery(
    problemReport?.vehicleId || '',
    {
      enabled: !!problemReport?.vehicleId,
    }
  );

  const { data: user } = useUserQuery(problemReport?.createdBy || '', {
    enabled: !!problemReport?.createdBy,
  });

  if (
    (problemReportQuery.isError &&
      getErrorStatus(problemReportQuery.error) === 403) ||
    (vehicleQuery.isError && getErrorStatus(vehicleQuery.error) === 403) ||
    !hasPermission('read:problem_reports')
  ) {
    return <Four0Three />;
  }

  if (problemReportQuery.isError || vehicleQuery.isError) {
    return <ErrorPage />;
  }

  return problemReport && vehicle ? (
    <ViewProblemReport
      problemReport={problemReport}
      vehicle={vehicle}
      user={user}
    />
  ) : (
    <SpinnerScreen />
  );
};
