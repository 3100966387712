import { PromotionListItem } from '@cooltra/api';
import { RouterCardLink } from '@cooltra/navigation';
import { classNames } from '@cooltra/utils';
import { isPast } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { Amount, MultilineDateTime, PromotionState } from '~/common';

import { PromotionRowLayout } from '../PromotionRowLayout';

import messages from './messages';

export type PromotionCardProps = {
  promotion: PromotionListItem;
};

export const PromotionCard = ({
  promotion: {
    promotionId,
    title,
    about,
    credit,
    validFrom,
    validUntil,
    uses,
    codesCount,
    discount,
  },
}: PromotionCardProps) => (
  <RouterCardLink
    to={`/offers/promotions/${promotionId}/edit`}
    className={classNames(
      validUntil && isPast(new Date(validUntil)) ? 'opacity-50' : 'opacity-100'
    )}
  >
    <PromotionRowLayout
      className="min-h-24 py-4"
      promotion={
        <>
          <span className="block text-base">
            <span className="text-base text-neutral-1000 font-semibold">
              {title}
            </span>
            <span className="pl-2 text-sm text-neutral-600">
              <FormattedMessage
                {...messages.codes}
                values={{
                  codesCount,
                }}
              />
            </span>
          </span>
          <span className="text-sm text-neutral-600">{about}</span>
        </>
      }
      benefit={
        <span className="text-right">
          {discount ? `${discount}%` : <Amount {...credit} />}
        </span>
      }
      validFrom={
        validFrom && (
          <MultilineDateTime emphasize="date" date={new Date(validFrom)} />
        )
      }
      validUntil={
        validUntil && (
          <MultilineDateTime emphasize="date" date={new Date(validUntil)} />
        )
      }
      uses={
        <span className="text-base text-neutral-900 tabular-nums">{uses}</span>
      }
      state={<PromotionState validFrom={validFrom} validUntil={validUntil} />}
    />
  </RouterCardLink>
);
