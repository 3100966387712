import { Badge } from '@cooltra/ui';
import { VehicleServiceItem } from '@cooltra/api';
import { RouterCardLink } from '@cooltra/navigation';
import { isFuture, isPast, parse } from 'date-fns';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { ByOperator } from '~/common';
import { dateFormat } from '~/utils/date';

import { VehicleServiceRowLayout } from '../VehicleServiceRowLayout/VehicleServiceRowLayout';

import messages from './messages';

export type VehicleServiceCardProps = VehicleServiceItem & {
  currentMileage: number | undefined;
};

export const VehicleServiceCard = ({
  serviceId,
  scheduledMileage,
  currentMileage,
  scheduledAt,
  serviceMileage,
  servicedBy,
  servicedAt,
}: VehicleServiceCardProps) => {
  const getFormattedMileage = (mileage: number) => {
    return new Intl.NumberFormat('es-ES', {
      style: 'unit',
      unit: 'kilometer',
    }).format(mileage);
  };

  const scheduledAtDate = parse(scheduledAt || '', dateFormat, new Date());
  const formattedScheduledMileage = getFormattedMileage(scheduledMileage);
  const isServicedDone = servicedAt && servicedBy && serviceMileage;

  const getRemainingMileage = () => {
    if (!currentMileage) {
      return <></>;
    }

    const remainingMileage = scheduledMileage - currentMileage;
    const formattedRemainingMileage = getFormattedMileage(remainingMileage);

    return (
      <p className="text-xs text-neutral-600 flex gap-1">
        <span className="text-neutral-400">
          <FormattedMessage {...messages.remaining} />
        </span>
        <span className={remainingMileage < 0 ? 'text-danger-500' : ''}>
          {formattedRemainingMileage}
        </span>
      </p>
    );
  };

  const getBadgeState = () => {
    if (isServicedDone) {
      return (
        <Badge variant="success">
          <FormattedMessage {...messages.done} />
        </Badge>
      );
    }

    if (
      scheduledMileage > Number(currentMileage) &&
      (!scheduledAt || isFuture(scheduledAtDate))
    ) {
      return (
        <Badge>
          <FormattedMessage {...messages.next} />
        </Badge>
      );
    }

    return (
      <div className="flex gap-2">
        {scheduledMileage < Number(currentMileage) && (
          <Badge variant="danger">
            <FormattedMessage {...messages.outOfMileage} />
          </Badge>
        )}
        {isPast(scheduledAtDate) && (
          <Badge variant="danger">
            <FormattedMessage {...messages.pastDue} />
          </Badge>
        )}
      </div>
    );
  };

  return (
    <RouterCardLink
      to={`/services/${serviceId}`}
      className="text-sm text-neutral-800 p-6"
    >
      <VehicleServiceRowLayout
        scheduledMileage={
          <div>
            <span className="font-semibold mb-1">
              {formattedScheduledMileage}
            </span>
            {!isServicedDone && getRemainingMileage()}
          </div>
        }
        scheduledAt={
          scheduledAt ? (
            <span
              className={
                !isServicedDone && isPast(scheduledAtDate)
                  ? 'text-danger-500'
                  : ''
              }
            >
              <FormattedDate
                value={scheduledAtDate}
                day="2-digit"
                month="short"
                year="numeric"
              />
            </span>
          ) : (
            <span />
          )
        }
        serviced={
          servicedAt && servicedBy && serviceMileage ? (
            <div>
              <p className="text-sm text-neutral-400">
                <FormattedMessage
                  {...messages.serviced}
                  values={{
                    mileage: (
                      <span className="text-neutral-1000">
                        {getFormattedMileage(serviceMileage)}
                      </span>
                    ),
                    date: (
                      <span className="text-neutral-1000">
                        <FormattedDate
                          value={parse(servicedAt, dateFormat, new Date())}
                          day="2-digit"
                          month="short"
                          year="numeric"
                        />
                      </span>
                    ),
                  }}
                />
              </p>
              <ByOperator className="text-neutral-400" id={servicedBy} />
            </div>
          ) : (
            <></>
          )
        }
        state={getBadgeState()}
      />
    </RouterCardLink>
  );
};
