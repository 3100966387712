import { UserDiscount } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { Amount } from '~/common';

import messages from './messages';

export const DiscountCapPerRental = ({
  discountCapPerRental,
}: Pick<UserDiscount, 'discountCapPerRental'>) => {
  if (!discountCapPerRental) {
    return <></>;
  }

  return (
    <FormattedMessage
      {...messages.maxPerRental}
      values={{
        discountCapPerRental: (
          <Amount
            value={discountCapPerRental.value}
            currency={discountCapPerRental.currency}
          />
        ),
      }}
    />
  );
};
