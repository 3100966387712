import {
  GoogleMap,
  StreetViewPanorama,
  StreetViewService,
  useJsApiLoader,
} from '@react-google-maps/api';

import { getEnvVariable } from '~/utils/environment';

export type GoogleStreetViewMapProps = {
  latitude: number;
  longitude: number;
  onStreetViewDoesNotExist: () => void;
};

export const GoogleStreetViewMap = ({
  latitude,
  longitude,
  onStreetViewDoesNotExist,
}: GoogleStreetViewMapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: getEnvVariable('GOOGLE_MAPS_API_KEY'),
  });
  const position = { lng: longitude, lat: latitude };
  const STREET_VIEW_MAX_DISTANCE_IN_METERS = 10;

  const onLoad = (streetViewService: google.maps.StreetViewService | null) => {
    streetViewService?.getPanorama(
      {
        location: position,
        radius: STREET_VIEW_MAX_DISTANCE_IN_METERS,
      },
      (data: google.maps.StreetViewPanoramaData | null) => {
        if (data === null) {
          onStreetViewDoesNotExist();
        }
      }
    );
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '100%',
      }}
      center={position}
      options={{
        controlSize: null,
      }}
    >
      <StreetViewPanorama
        options={{
          position: position,
          visible: true,
          fullscreenControl: false,
          panControl: false,
        }}
      />
      <StreetViewService onLoad={onLoad} />
    </GoogleMap>
  ) : (
    <></>
  );
};
