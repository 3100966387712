import { SpinnerScreen } from '@cooltra/ui';
import { getErrorStatus } from '@cooltra/axios';
import { useWorkShiftQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { useParams } from 'react-router-dom';

import { ErrorPage, Four0Three, Shift as ShiftUI } from '~/common';

export const Shift = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { workShiftId = '' } = useParams<'workShiftId'>();
  const { data, error, isError, isLoading } = useWorkShiftQuery(workShiftId, {
    enabled: Boolean(workShiftId),
  });

  if (
    (isError && getErrorStatus(error) === 403) ||
    !hasPermission('read:work_shift')
  ) {
    return <Four0Three />;
  }

  if (isLoading) {
    return <SpinnerScreen />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (!data?.summary) {
    return <ErrorPage />;
  }

  return <ShiftUI workShift={data} />;
};
