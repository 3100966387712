import { Task } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { StaticField } from '../../../StaticField';

import messages from './messages';

export type TitleStaticProps = {
  value: Task['title'];
};

export const TitleStatic = ({ value }: TitleStaticProps) => (
  <StaticField
    renderLabel={<FormattedMessage {...messages.title} />}
    renderValue={value}
  />
);
