import { CsvPriceListItem } from '../../utils/export-csv';

type ListItem = {
  name: string;
  priceList: { value: number }[] | null;
};

export const getDailyPrices = <Type extends string>(
  items: ListItem[] | undefined
) => {
  if (!items || !items.length) {
    return;
  }

  const firstItemWithPriceList = items.find((item) => !!item.priceList?.length);
  if (!firstItemWithPriceList || !firstItemWithPriceList.priceList?.length) {
    return;
  }

  const itemsByDay = firstItemWithPriceList.priceList.map((_, index) => {
    return items.reduce(
      (acc, item) => ({
        ...acc,
        day: (index + 1).toString(),
        [item.name]: item.priceList?.[index].value,
      }),
      {} as CsvPriceListItem<Type>
    );
  });

  return itemsByDay;
};
