import { format } from 'date-fns';
import { Promotion } from '@cooltra/api';

import { getPromotionStateType, PromotionFormValues } from '~/common';
import { hoursInADay, dateFormat, timeFormat } from '~/utils/date';

type BenefitExpirationAttributes = Pick<
  PromotionFormValues,
  | 'benefitDoesntExpire'
  | 'benefitFromDate'
  | 'benefitFromTime'
  | 'benefitUntilDate'
  | 'benefitUntilTime'
  | 'benefitExpirationRangeMode'
  | 'benefitExpiresAfterHours'
  | 'benefitExpiresAfterDays'
>;

const getBenefitExpirationAttributes = (
  promotion: Promotion
): BenefitExpirationAttributes => {
  const benefitExpirationAttributes: BenefitExpirationAttributes = {
    benefitFromDate: '',
    benefitFromTime: '',
    benefitUntilDate: '',
    benefitUntilTime: '',
    benefitExpirationRangeMode: 'specific-date',
    benefitExpiresAfterDays: '',
    benefitExpiresAfterHours: '',
    benefitDoesntExpire: true,
  };

  const benefitFrom = promotion.benefitFrom
    ? new Date(promotion.benefitFrom)
    : null;
  const benefitUntil = promotion.benefitUntil
    ? new Date(promotion.benefitUntil)
    : null;

  if (benefitFrom || benefitUntil) {
    return {
      ...benefitExpirationAttributes,
      benefitFromDate: benefitFrom ? format(benefitFrom, dateFormat) : '',
      benefitFromTime: benefitFrom ? format(benefitFrom, timeFormat) : '',
      benefitUntilDate: benefitUntil ? format(benefitUntil, dateFormat) : '',
      benefitUntilTime: benefitUntil ? format(benefitUntil, timeFormat) : '',
      benefitDoesntExpire: benefitUntil === null,
    };
  }

  if (promotion.benefitExpiresAfterInHours != null) {
    const benefitDoesntExpire = promotion.benefitExpiresAfterInHours === 0;

    if (promotion.benefitExpiresAfterInHours % hoursInADay === 0) {
      return {
        ...benefitExpirationAttributes,
        benefitExpirationRangeMode: benefitDoesntExpire
          ? 'after-hours'
          : 'after-days',
        benefitExpiresAfterDays:
          promotion.benefitExpiresAfterInHours / hoursInADay,
        benefitDoesntExpire: benefitDoesntExpire,
      };
    }

    return {
      ...benefitExpirationAttributes,
      benefitExpirationRangeMode: 'after-hours',
      benefitExpiresAfterHours: promotion.benefitExpiresAfterInHours,
      benefitDoesntExpire: benefitDoesntExpire,
    };
  }

  return benefitExpirationAttributes;
};

export const getFormValuesFromPromotion = (
  promotion: Promotion,
  editMode: boolean
): PromotionFormValues => {
  const stateType = getPromotionStateType({
    validFrom: promotion.validFrom,
    validUntil: promotion.validUntil,
  });
  const validFrom = new Date(promotion.validFrom);
  let validUntil: Date | null = null;

  if (promotion.validUntil) {
    validUntil = new Date(promotion.validUntil);
  }

  return {
    promotionTypeMode: promotion.credit?.value ? 'credit' : 'discount',
    code: promotion.code as string,
    promotionCodeMode: promotion.multiCode ? 'multi-code' : 'single-code',
    codePrefix: promotion.codePrefix as string,
    numberOfCodes: promotion.numberOfCodes as number,
    'credit.value': promotion.credit?.value ?? '',
    discount: promotion.discount ?? '',
    'discountCap.value': promotion.discountCap?.value ?? '',
    capType: promotion.rentalsCap ? 'rentals' : 'total',
    rentalsCap: promotion.rentalsCap ?? '',
    'discountCapPerRental.value': promotion.discountCapPerRental?.value ?? '',
    about: promotion.about,
    validFromDate: format(validFrom, dateFormat),
    validFromTime: format(validFrom, timeFormat),
    validUntilDate: validUntil ? format(validUntil, dateFormat) : '',
    validUntilTime: validUntil ? format(validUntil, timeFormat) : '',
    promotionDoesntExpire: !validUntil,
    maxUses: promotion.maxUses,
    registration: promotion.registration,
    userCanRedeemMoreThanOneCode: promotion.userCanRedeemMoreThanOneCode,
    'titleTranslations.en': promotion.titleTranslations.en
      ? promotion.titleTranslations.en
      : '',
    'titleTranslations.es': promotion.titleTranslations.es
      ? promotion.titleTranslations.es
      : '',
    'titleTranslations.it': promotion.titleTranslations.it
      ? promotion.titleTranslations.it
      : '',
    'titleTranslations.pt': promotion.titleTranslations.pt
      ? promotion.titleTranslations.pt
      : '',
    'titleTranslations.fr': promotion.titleTranslations.fr
      ? promotion.titleTranslations.fr
      : '',
    'titleTranslations.ca': promotion.titleTranslations.ca
      ? promotion.titleTranslations.ca
      : '',
    'titleTranslations.nl': promotion.titleTranslations.nl
      ? promotion.titleTranslations.nl
      : '',
    'descriptionTranslations.en': promotion.descriptionTranslations.en
      ? promotion.descriptionTranslations.en
      : '',
    'descriptionTranslations.es': promotion.descriptionTranslations.es
      ? promotion.descriptionTranslations.es
      : '',
    'descriptionTranslations.it': promotion.descriptionTranslations.it
      ? promotion.descriptionTranslations.it
      : '',
    'descriptionTranslations.pt': promotion.descriptionTranslations.pt
      ? promotion.descriptionTranslations.pt
      : '',
    'descriptionTranslations.fr': promotion.descriptionTranslations.fr
      ? promotion.descriptionTranslations.fr
      : '',
    'descriptionTranslations.ca': promotion.descriptionTranslations.ca
      ? promotion.descriptionTranslations.ca
      : '',
    'descriptionTranslations.nl': promotion.descriptionTranslations.nl
      ? promotion.descriptionTranslations.nl
      : '',
    editMode,
    stateType,
    targetHomeSystems: promotion.targetHomeSystems,
    targetUserIds: promotion.targetUserIds.join('\n'),
    targetVehicleModels: promotion.targetVehicleModels || [],
    ...getBenefitExpirationAttributes(promotion),
  };
};
