import { defineMessages } from 'react-intl';

export default defineMessages({
  backToInvoices: {
    defaultMessage: 'Back to invoices',
  },
  backToRental: {
    defaultMessage: 'Back to rental',
  },
});
