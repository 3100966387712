import { FormProvider, FormProviderProps } from '@cooltra/form';

import { FilterFormValues } from './filter-form';

export type FilterFormProviderProps = Omit<
  FormProviderProps<FilterFormValues>,
  'validate'
>;

export const FilterFormProvider = ({
  initialValues,
  children,
}: FilterFormProviderProps) => (
  <FormProvider initialValues={initialValues}>{children}</FormProvider>
);
