import { useClearSuspensionMutation } from '@cooltra/api';
import {
  Form,
  FormButton,
  FormHelpers,
  FormProvider,
  FormTextareaField,
} from '@cooltra/form';
import { useToggle } from '@cooltra/hooks';
import { Button, InputLabel, Modal } from '@cooltra/ui';
import { useQueryClient } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';

import { useNotification } from '~/hooks';

import {
  clearSuspensionFormInitialValues,
  ClearSuspensionFormValues,
  validateClearSuspensionForm,
} from './clear-suspension-form';
import messages from './messages';

export type ClearSuspensionModalProps = {
  suspensionId: string;
  userId: string;
};

export const ClearSuspensionModal = ({
  userId,
  suspensionId,
}: ClearSuspensionModalProps) => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const [isOpen, { toggleOff, toggleOn }] = useToggle();
  const mutation = useClearSuspensionMutation(userId, suspensionId);

  const handleSubmit = (
    values: ClearSuspensionFormValues,
    { resetForm }: FormHelpers<ClearSuspensionFormValues>
  ) =>
    mutation
      .mutateAsync(values)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['user', userId] });
        queryClient.invalidateQueries({ queryKey: ['maas-user', userId] });
        addSuccessNotification(intl.formatMessage(messages.success));
        resetForm(values);
        toggleOff();
      })
      .catch(() => addErrorNotification());

  return (
    <>
      <Button size="sm" onClick={toggleOn}>
        <FormattedMessage {...messages.clear} />
      </Button>
      <Modal
        className="max-w-lg min-w-md"
        isOpen={isOpen}
        onRequestClose={toggleOff}
        header={
          <h6 className="text-neutral-400 text-lg font-semibold mt-8 ml-4">
            <FormattedMessage {...messages.clearSuspension} />
          </h6>
        }
      >
        <div className="px-8 pt-6 pb-8" data-testid="CLEAR_SUSPENSION_MODAL">
          <FormProvider
            initialValues={clearSuspensionFormInitialValues}
            validate={validateClearSuspensionForm(intl)}
            onSubmit={handleSubmit}
          >
            <Form>
              <FormTextareaField
                name="comment"
                className="mb-4"
                rows={5}
                id="comment"
                label={
                  <InputLabel htmlFor="comment">
                    <FormattedMessage {...messages.comment} />
                  </InputLabel>
                }
              />
              <div className="grid grid-cols-2 gap-2 justify-evenly">
                <Button type="button" onClick={toggleOff}>
                  <FormattedMessage {...messages.cancel} />
                </Button>
                <FormButton>
                  <FormattedMessage {...messages.clearSuspension} />
                </FormButton>
              </div>
            </Form>
          </FormProvider>
        </div>
      </Modal>
    </>
  );
};
