import { Icon } from '@cooltra/ui';
import { MdOutlineDescription } from 'react-icons/md';
import { useIntl } from 'react-intl';
import {
  PriceTypeByProductCategoryWithPriceList,
  ProductCategory,
} from '@cooltra/station-based-api';
import { getKeys } from '@cooltra/utils';
import { format } from 'date-fns';

import { saveCsvFile } from '~/utils/file';
import { dateFormat } from '~/utils/date';

import { CsvPriceListItem, priceListsToCsv } from '../../../utils/export-csv';

import messages from './messages';

export type ExportPriceListProps = {
  prices: Record<
    ProductCategory,
    PriceTypeByProductCategoryWithPriceList | null
  >;
};

export const ExportPriceList = ({ prices }: ExportPriceListProps) => {
  const { formatMessage } = useIntl();

  const downloadCSV = () => {
    const pricesByDay = (prices.STANDARD?.priceList || []).map((_, index) => {
      return getKeys(prices).reduce(
        (acc, key) => ({
          ...acc,
          day: (index + 1).toString(),
          [key]: prices[key]?.priceList?.[index].value.toString(),
        }),
        {} as CsvPriceListItem<ProductCategory>
      );
    });

    const pricesCsv = priceListsToCsv<ProductCategory>(
      pricesByDay,
      getKeys(prices)
    );

    saveCsvFile(
      `vehicles-prices-${format(new Date(), dateFormat)}.csv`,
      pricesCsv
    );
  };

  return (
    <button aria-label={formatMessage(messages.export)} onClick={downloadCSV}>
      <Icon className="text-xl text-neutral-700">
        <MdOutlineDescription />
      </Icon>
    </button>
  );
};
