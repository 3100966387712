import type { SVGProps } from 'react';

export const Ios = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.753 4.68c.024-.754.231-1.864.954-2.594.67-.677 1.487-1.007 1.995-1.158a.29.29 0 0 1 .376.295c-.034.721-.224 1.797-.714 2.374-.461.543-1.203 1.264-2.29 1.385a.29.29 0 0 1-.321-.302z"
    />
    <path
      fill="currentColor"
      d="M11.614 18.474c.522.2 1.05.403 1.528.403 1.022 0 3.998-2.358 4.105-4.807a.333.333 0 0 0-.18-.3c-.756-.423-1.993-1.478-2.036-3.013-.05-1.788.848-3.208 1.528-3.796.153-.132.205-.365.069-.514-.619-.675-1.863-1.604-2.997-1.581-.826.016-1.57.324-2.208.588-.49.202-.917.38-1.272.38-.336 0-.74-.177-1.202-.378-.624-.272-1.355-.59-2.176-.59-1.52 0-4.523 1.26-4.523 6.126 0 2.58 2.663 7.953 4.91 7.885.485 0 1.003-.201 1.518-.4.507-.197 1.01-.392 1.473-.392.447 0 .952.194 1.463.39z"
    />
  </svg>
);
