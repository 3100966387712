import { FormattedMessage } from 'react-intl';
import { Card } from '@cooltra/ui';
import { Contract, ContractPayment } from '@cooltra/station-based-api';

import { PaidTicketSummary } from './PaidTicketSummary/PaidTicketSummary';
import messages from './messages';
import { AwaitingPaymentTicket } from './AwaitingPaymentTicket/AwaitingPaymentTicket';

export type ContractPaidOrRefundedPaymentsProps = {
  contract: Contract;
  contratPaymentTypes: ContractPayment[];
};

export const ContractPaidOrRefundedPayments = ({
  contract,
  contratPaymentTypes,
}: ContractPaidOrRefundedPaymentsProps) => {
  const paidPayments = contratPaymentTypes.filter(
    (p) => p.status === 'PAID' || p.status === 'REFUNDED'
  );
  const awaitingPayments =
    contratPaymentTypes.filter((p) => p.status === 'AWAITING_PAYMENT') || [];

  if (!paidPayments) {
    return (
      <Card className="pt-8 py-10 px-14 mb-4">
        <h2 className="text-lg text-neutral-800 mb-8">
          <div className="flex justify-between my-2 gap-8">
            <FormattedMessage {...messages.pastCharges} />
            <span>-</span>
          </div>
        </h2>
      </Card>
    );
  }
  return (
    <Card data-testid="REFUNDED_PAYMENTS_CARD" className="pt-8 py-10 px-14">
      <h2 className="text-lg text-neutral-800 mb-8">
        <FormattedMessage {...messages.pastCharges} />
      </h2>
      {awaitingPayments.map((awaitingPaymentTicket, idx) => (
        <AwaitingPaymentTicket
          contractId={contract.contractId}
          ticket={awaitingPaymentTicket}
          key={`awaiting-ticket-${idx}`}
        />
      ))}
      {paidPayments.map((paidPayment) => (
        <PaidTicketSummary
          key={paidPayment.id}
          contractId={contract.contractId}
          contractStatus={contract.status}
          payment={paidPayment}
        />
      ))}
    </Card>
  );
};
