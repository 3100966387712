import { FormattedMessage, useIntl } from 'react-intl';
import { useSyncVehicleMutation } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { Button } from '@cooltra/ui';
import { AxiosError } from 'axios';

import { useNotification } from '~/hooks';
import { useVehiclePolling } from '~/libs/polling-data';

import { errorMessages } from '../error-messages';
import { ActionButton } from '../types';

import messages from './messages';

export type SyncProps = Omit<ActionButton, 'showIcon'>;

export const Sync = ({ vehicleId, emphasis, isDropdown }: SyncProps) => {
  const { formatMessage } = useIntl();
  const { activatePollingVehicle } = useVehiclePolling();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleOnSuccess = () => {
    addSuccessNotification(formatMessage(messages.syncSuccess));
    activatePollingVehicle(vehicleId);
  };

  const handleOnError = (error: AxiosError) => {
    let errorNotification;

    switch (getErrorStatus(error)) {
      case 409:
        errorNotification = formatMessage(errorMessages.conflictInAction);
        break;
      case 422:
        errorNotification = formatMessage(
          errorMessages.notProperlyConfiguredVehicle
        );
        break;
      case 502:
        errorNotification = formatMessage(
          errorMessages.issueWithTelematicsProvider
        );
        break;
      case 504:
        errorNotification = formatMessage(errorMessages.vehicleIsOffline);
        break;
    }

    addErrorNotification(errorNotification);
  };

  const { isPending, mutate } = useSyncVehicleMutation(vehicleId, {
    onError: handleOnError,
    onSuccess: handleOnSuccess,
  });

  return (
    <>
      {isDropdown ? (
        <Button
          size="sm"
          emphasis="low"
          className="w-full border-0 rounded-none py-5 text-neutral-1000"
          onClick={() => mutate()}
          loading={isPending}
        >
          <div className="flex gap-4 items-center">
            <span className="w-32 text-left font-normal text-sm">
              <FormattedMessage {...messages.sync} />
            </span>
          </div>
        </Button>
      ) : (
        <Button
          size="sm"
          emphasis={emphasis}
          loading={isPending}
          onClick={() => mutate()}
        >
          <div className="flex flex-col items-center justify-center">
            <span className="text-sm bg">
              <FormattedMessage {...messages.sync} />
            </span>
          </div>
        </Button>
      )}
    </>
  );

  return (
    <Button
      size="sm"
      emphasis={emphasis}
      loading={isPending}
      onClick={() => mutate()}
    >
      <FormattedMessage {...messages.sync} />
    </Button>
  );
};
