import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@cooltra/ui';
import { useReleaseVehiclesFromLocationMutation } from '@cooltra/api';
import { useToggle } from '@cooltra/hooks';

import { ConfirmModal } from '~/common';
import { useNotification } from '~/hooks';

import messages from './messages';

export type ReleaseFromLocationsProps = {
  vehicleIds: string[];
  refetchVehicles: () => void;
};

export const ReleaseFromLocations = ({
  vehicleIds,
  refetchVehicles,
}: ReleaseFromLocationsProps) => {
  const { formatMessage } = useIntl();
  const [isOpen, { toggleOff, toggleOn }] = useToggle();

  const { addErrorNotification, addSuccessNotification } = useNotification();
  const { isPending, mutateAsync } = useReleaseVehiclesFromLocationMutation();

  const handleOnSave = () =>
    mutateAsync({ vehicleIds })
      .then(() => {
        addSuccessNotification(formatMessage(messages.success));
        refetchVehicles();
        toggleOff();
      })
      .catch(() => addErrorNotification());

  return (
    <>
      <ConfirmModal
        title={formatMessage(messages.title)}
        content={formatMessage(messages.content)}
        onConfirm={handleOnSave}
        onClose={toggleOff}
        isOpen={isOpen}
        loading={isPending}
      />
      <Button
        emphasis="high"
        size="sm"
        onClick={toggleOn}
        disabled={!vehicleIds.length}
      >
        <FormattedMessage {...messages.release} />
      </Button>
    </>
  );
};
