import { UserCredit } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Button, Icon, Tooltip } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { MdCheck, MdInfo } from 'react-icons/md';

import { ByOperator, DateTime } from '~/common';
import {
  isCreditDeleted,
  isCreditExpired,
  isCreditUsed,
} from '~/utils/credits';

import messages from './messages';

export type CreditStateProps = Pick<
  UserCredit,
  'deletedAt' | 'expiresAt' | 'remainingAmount' | 'deletedBy'
> & {
  onDelete: () => void;
};

export const CreditState = ({
  deletedAt,
  deletedBy,
  expiresAt,
  remainingAmount,
  onDelete,
}: CreditStateProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  if (isCreditDeleted(deletedAt) && deletedAt && deletedBy) {
    return (
      <Tooltip
        button={
          <span className="mr-4  inline-flex items-center">
            <Icon className="mr-0.5 text-neutral-500">
              <MdInfo />
            </Icon>
            <span className="text-neutral-800">
              <FormattedMessage {...messages.deleted} />
            </span>
          </span>
        }
        content={
          <div className="p-4">
            <DateTime date={deletedAt} />
            <ByOperator id={deletedBy} />
          </div>
        }
      />
    );
  }

  if (isCreditUsed(remainingAmount)) {
    return (
      <span className="mr-4 text-success-600 inline-flex items-center">
        <Icon className="mr-0.5">
          <MdCheck />
        </Icon>
        <span>
          <FormattedMessage {...messages.used} />
        </span>
      </span>
    );
  }

  if (isCreditExpired(expiresAt)) {
    return (
      <span className="mr-4 text-neutral-800">
        <FormattedMessage {...messages.expired} />
      </span>
    );
  }

  if (!hasPermission('delete:credits')) {
    return <span data-testid="EMPTY_CREDIT_STATE" />;
  }

  return (
    <Button variant="danger" emphasis="low" size="sm" onClick={onDelete}>
      <FormattedMessage {...messages.delete} />
    </Button>
  );
};
