import { Card, Placeholder } from '@cooltra/ui';

import { DepositsRowLayout } from './DepositsRowLayout';

export const DepositsCardLoading = () => {
  return (
    <Card>
      <DepositsRowLayout
        className="min-h-20"
        coverage={<Placeholder className="h-2 w-20" />}
        eBike={<Placeholder className="h-2 w-20" />}
        standard={<Placeholder className="h-2 w-20" />}
        plus={<Placeholder className="h-2 w-20" />}
        premium={<Placeholder className="h-2 w-20" />}
        superpremium={<Placeholder className="h-2 w-20" />}
      />
    </Card>
  );
};
