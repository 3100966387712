import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { FormInputField } from '@cooltra/form';

import { FormFieldWithName } from '~/utils/form';

import messages from './messages';

export const Email = ({ name = 'email' }: FormFieldWithName) => (
  <FormInputField
    name={name}
    id="email"
    label={
      <InputLabel htmlFor="email">
        <FormattedMessage {...messages.email} />
      </InputLabel>
    }
  />
);
