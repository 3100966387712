import { defineMessages } from 'react-intl';

export default defineMessages({
  about: {
    defaultMessage: 'About',
  },
  placeholder: {
    defaultMessage: 'This promotion is associated to the xmas promotion',
  },
});
