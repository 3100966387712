import { defineMessages } from 'react-intl';

export default defineMessages({
  disableAlarm: {
    defaultMessage: 'Disable Alarm',
  },
  successOnDisableAlarmVehicle: {
    defaultMessage: 'Disable alarm action has been triggered successfully',
  },
});
