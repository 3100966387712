import { defineMessages } from 'react-intl';

export default defineMessages({
  required: {
    defaultMessage: 'This field is required',
  },
  invalidDate: {
    defaultMessage: 'Invalid date',
  },
  invalidTime: {
    defaultMessage: 'Invalid time',
  },
  invalidId: {
    defaultMessage: 'Invalid ID number',
  },
  invalidTaxId: {
    defaultMessage: 'Invalid tax ID number',
  },
  invalidEmail: {
    defaultMessage: 'Invalid email',
  },
  invalidName: {
    defaultMessage: 'Invalid name',
  },
  invalidCity: {
    defaultMessage: 'Invalid city',
  },
  invalidPhone: {
    defaultMessage: 'Invalid phone number',
  },
  invalidExpirationDate: {
    defaultMessage: 'It should not be a date in the past',
  },
  invalidAmount: {
    defaultMessage: 'Invalid amount',
  },
  invalidCharacters: {
    defaultMessage: 'Invalid characters',
  },
  invalidMaxLength: {
    defaultMessage: 'Should be at most {length} characters long',
  },
  invalidExactLength: {
    defaultMessage: 'It should be {length} characters long',
  },
  notAdult: {
    defaultMessage: 'Should be at least 18 years old',
  },
});
