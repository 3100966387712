import { useIntl } from 'react-intl';
import { MdOutlineLayers } from 'react-icons/md';
import { VehicleType } from '@cooltra/api';
import { Button, Icon } from '@cooltra/ui';
import { ButtonHTMLAttributes } from 'react';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import messages from './messages';

type SwitchGeofencesButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  vehicleType: VehicleType;
  onSwitchVehicleType: (vehicleType: VehicleType) => void;
};

export const SwitchGeofencesButton = ({
  vehicleType,
  onSwitchVehicleType,
  ...rest
}: SwitchGeofencesButtonProps) => {
  const { data: claims } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();
  const operatorsInBarcelona = claims?.systems.includes('Barcelona');

  if (!operatorsInBarcelona) {
    return null;
  }

  const handleSwitchVehicleType = () =>
    onSwitchVehicleType(vehicleType === 'SCOOTER' ? 'BIKE' : 'SCOOTER');

  return (
    <div
      data-testid="SWITCH_GEOFENCE_BUTTON"
      className="bg-neutral-0 shadow rounded-md"
    >
      <Button
        className="bg-neutral-0 rounded-md"
        {...rest}
        onClick={handleSwitchVehicleType}
        aria-label={formatMessage(messages.switch)}
        square
      >
        <Icon className="text-xl text-neutral-500">
          {vehicleType === 'SCOOTER' ? (
            <MdOutlineLayers className="text-primary-400" />
          ) : (
            <MdOutlineLayers className="text-accent-500" />
          )}
        </Icon>
      </Button>
    </div>
  );
};
