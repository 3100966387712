import { DamageType, VehiclePart } from '@cooltra/station-based-api';

import { damageTypeMessages, vehiclePartMessages } from './messages';

export const allDamageTypes: DamageType[] = [
  'SCRATCHED',
  'DENTED',
  'MISSING',
  'BROKEN',
];

export const getDamageTypeMessage = (damageType: DamageType) =>
  damageTypeMessages[damageType];

export const allVehicleParts: VehiclePart[] = [
  'FRONT',
  'BACK',
  'LEFT',
  'RIGHT',
  'TOP',
];

export const getVehiclePartMessage = (vehiclePart: VehiclePart) =>
  vehiclePartMessages[vehiclePart];
