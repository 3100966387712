import { Card } from '@cooltra/ui';
import { RouterLink } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { MdOpenInNew } from 'react-icons/md';
import { Vehicle } from '@cooltra/station-based-api';

import { Damages } from '~/common';

import { VehicleFormFields } from '../VehicleForm';

import messages from './messages';

type VehicleCheckAndDamagesProps = {
  vehicle: Vehicle;
};

export const VehicleCheckAndDamages = ({
  vehicle,
}: VehicleCheckAndDamagesProps) => {
  if (vehicle.contract) {
    return (
      <div className="mt-16">
        <h2 className="font-semibold text-xl text-neutral-800 mb-5">
          <FormattedMessage {...messages.checksAndDamages} />
        </h2>
        <Card className="h-32 w-full flex items-center justify-center">
          <RouterLink
            className="inline-flex gap-1 items-center text-s font-semibold"
            to={`/contracts/${vehicle.contract?.contractId}/checks-damages`}
          >
            <FormattedMessage {...messages.editInTheContract} />
            <MdOpenInNew />
          </RouterLink>
        </Card>
      </div>
    );
  }

  return (
    <div className="mt-16">
      <VehicleFormFields.Checks />
      <div className="mt-8">
        <Damages vehicleId={vehicle.vehicleId} />
      </div>
    </div>
  );
};
