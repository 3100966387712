import { About } from './About/About';
import { Code } from './Code/Code';
import { CouponTypeSelector } from './CouponTypeSelector/PromotionTypeSelectorMode';
import { CreditValue } from './CreditValue/CreditValue';
import { Discount } from './Discount/Discount';
import { MaxUses } from './MaxUses/MaxUses';
import { PromotionTargetSelector } from './PromotionTargetSelector/PromotionTypeSelectorMode';
import { StationBasedPromotionAppliesToFields } from './AppliesTo';
import { ValidFrom } from './ValidityDates/ValidFrom';
import { ValidUntil } from './ValidityDates/ValidUntil';

export const StationBasedPromotionFields = {
  About,
  Code,
  CreditValue,
  CouponTypeSelector,
  Discount,
  MaxUses,
  ValidFrom,
  ValidUntil,
  PromotionTargetSelector,
  ...StationBasedPromotionAppliesToFields,
};
