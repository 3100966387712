import { FormSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { countryOptions } from '@cooltra/countries';

import messages from './messages';

export const Country = () => (
  <FormSelectField
    name="country"
    id="country"
    isClearable
    options={countryOptions}
    label={
      <InputLabel htmlFor="country">
        <FormattedMessage {...messages.country} />
      </InputLabel>
    }
  />
);
