import { defineMessages } from 'react-intl';

export default defineMessages({
  rentals: {
    defaultMessage: 'Rentals',
  },
  reservations: {
    defaultMessage: 'Reservations',
  },
});
