import { defineMessages } from 'react-intl';
import { VehiclePart, DamageType } from '@cooltra/station-based-api';

export const vehiclePartMessages = defineMessages<VehiclePart>({
  FRONT: {
    defaultMessage: 'Front',
  },
  BACK: {
    defaultMessage: 'Back',
  },
  LEFT: {
    defaultMessage: 'Left',
  },
  RIGHT: {
    defaultMessage: 'Right',
  },
  TOP: {
    defaultMessage: 'Top',
  },
});

export const damageTypeMessages = defineMessages<DamageType>({
  SCRATCHED: {
    defaultMessage: 'Scratched',
  },
  DENTED: {
    defaultMessage: 'Dented',
  },
  MISSING: {
    defaultMessage: 'Missing',
  },
  BROKEN: {
    defaultMessage: 'Broken',
  },
});
