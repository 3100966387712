import { useIntl } from 'react-intl';

import {
  FormPatternInputField,
  FormPatternInputFieldProps,
} from '../FormPatternInputField';

import messages from './messages';

export type FormTimeInputFieldProps = Omit<
  FormPatternInputFieldProps,
  'placeholder' | 'format'
>;

export const FormTimeInputField = (props: FormTimeInputFieldProps) => {
  const { formatMessage } = useIntl();
  return (
    <FormPatternInputField
      placeholder={formatMessage(messages.placeholder)}
      format="##:##"
      mask="_"
      {...props}
    />
  );
};
