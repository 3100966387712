import { FormattedMessage } from 'react-intl';

import { ListColumnLabel, ListColumnLabelProps } from '../../List';

import messages from './messages';

type AlarmsColumnLabelProps = Omit<ListColumnLabelProps, 'children'> & {
  name: keyof typeof messages;
};

export const AlarmsColumnLabel = ({
  name,
  ...rest
}: AlarmsColumnLabelProps) => (
  <ListColumnLabel {...rest}>
    <FormattedMessage {...messages[name]} />
  </ListColumnLabel>
);
