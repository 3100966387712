import { FormButton, useFormContext } from '@cooltra/form';
import { FormattedMessage } from 'react-intl';
import { Money } from '@cooltra/utils';

import { Amount } from '~/common';

import { PayTicketFormValues } from '../../PayTicketFormValues';
import { StripeQRPaymentButton } from '../../StripeQRPaymentButton/StripeQRPaymentButton';

import messages from './messages';

export type SubmitButtonProps = {
  disabled: boolean;
  isLoading: boolean;
  amount: Money;
  closeModal: VoidFunction;
  contractId: string;
};

export const SubmitButton = ({
  disabled,
  isLoading,
  amount,
  closeModal,
  contractId,
}: SubmitButtonProps) => {
  const {
    isSubmitting,
    values: { paymentMethod },
  } = useFormContext<PayTicketFormValues>();

  if (paymentMethod === 'STRIPE_QR') {
    return (
      <StripeQRPaymentButton
        contractId={contractId}
        onCloseModal={closeModal}
        disabled={disabled}
      />
    );
  }
  return (
    <FormButton
      emphasis="high"
      className="mt-8"
      disabled={disabled}
      loading={isSubmitting || isLoading}
    >
      <FormattedMessage
        {...messages.sendPayment}
        values={{
          amount: <Amount {...amount} />,
        }}
      />
    </FormButton>
  );
};
