import { useContext } from 'react';

import { FocusedZoneContext } from './FocusedZoneContext';

export const useFocusedZone = () => {
  const restrictionContext = useContext(FocusedZoneContext);

  if (!restrictionContext) {
    throw new Error(
      'useFocusedZone can be only used within <FocusedZoneProvider />'
    );
  }

  return restrictionContext;
};
