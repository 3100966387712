import { FormattedMessage } from 'react-intl';
import { Button, Modal } from '@cooltra/ui';

import messages from './messages';

export type RejectOverrideModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onReject: () => void;
  isRejecting?: boolean;
};

export const RejectOverrideModal = ({
  isOpen,
  onClose,
  onReject,
  isRejecting = false,
}: RejectOverrideModalProps) => (
  <Modal className="max-w-md" isOpen={isOpen} onRequestClose={onClose}>
    <div data-testid="REJECT_OVERRIDE_MODAL" className="pb-8 px-8">
      <h6 className="text-neutral-700 text-lg font-semibold mb-2">
        <FormattedMessage {...messages.title} />
      </h6>
      <p className="text-neutral-600 text-sm mb-8">
        <FormattedMessage {...messages.content} />
      </p>
      <div className="flex justify-end gap-4">
        <Button loading={isRejecting} variant="danger" onClick={onReject}>
          <FormattedMessage {...messages.confirm} />
        </Button>
        <Button emphasis="high" onClick={onClose}>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </div>
    </div>
  </Modal>
);
