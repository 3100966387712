import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type InvoiceRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  issuedAt: ReactNode;
  type: ReactNode;
  invoiceNumber: ReactNode;
  legalEntity: ReactNode;
  total: ReactNode;
  state: ReactNode;
  invoicePdf: ReactNode;
};

export const InvoiceRowLayout = ({
  issuedAt,
  type,
  invoiceNumber,
  legalEntity,
  total,
  state,
  invoicePdf,
  className,
  ...rest
}: InvoiceRowLayoutProps) => (
  <div
    className={classNames('px-6 flex items-center gap-4', className)}
    {...rest}
  >
    <div className="w-40 shrink-0">{issuedAt}</div>
    <div className="w-36 shrink-0">{type}</div>
    <div className="w-32 shrink-0">{invoiceNumber}</div>
    <div className="flex-1 shrink-0">{legalEntity}</div>
    <div className="w-24 shrink-0 text-right pr-4">{total}</div>
    <div className="w-24 shrink-0 text-center">{state}</div>
    <div className="w-16 text-center">{invoicePdf}</div>
  </div>
);
