import { defineMessages } from 'react-intl';

export default defineMessages({
  creditBalance: {
    defaultMessage: 'Credit balance',
  },
  referralCode: {
    defaultMessage: 'Referral code',
  },
});
