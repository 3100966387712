import { FormattedMessage, FormattedTime } from 'react-intl';

import { RentalTimelineRowLayout } from '../RentalTimelineRowLayout';
import { RentalTimelineTick } from '../RentalTimelineTick';

import messages from './messages';

export type VehicleTelematicStatusProps = {
  occurredOn: string;
  variant: 'offline' | 'online';
};

export const VehicleTelematicStatus = ({
  occurredOn,
  variant,
}: VehicleTelematicStatusProps) => {
  const textColor =
    variant === 'offline' ? 'text-danger-700' : 'text-success-700';
  const message =
    variant === 'offline' ? messages.vehicleOffline : messages.vehicleOnline;

  return (
    <RentalTimelineRowLayout
      className="mb-8"
      timeline={
        <RentalTimelineTick
          variant={variant === 'offline' ? 'danger' : 'neutral'}
        />
      }
      content={
        <div className="flex items-center text-xs">
          <span className={`${textColor} mr-2`}>
            <FormattedTime
              value={occurredOn}
              hour="2-digit"
              hourCycle="h23"
              minute="2-digit"
            />
          </span>
          <span className={`font-semibold ${textColor}`}>
            <FormattedMessage {...message} />
          </span>
        </div>
      }
    />
  );
};
