import { RouterButton } from '@cooltra/navigation';
import { FormattedMessage, useIntl } from 'react-intl';
import { stringifyParams } from '@cooltra/utils';
import {
  addDays,
  addMonths,
  addWeeks,
  endOfWeek,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import { formatDateQueryParam } from '../utils';
import { useFilters } from '../useFilters';

import { POSView } from './types';
import { FiltersModal } from './FiltersModal/FiltersModal';
import messages, { calendarFilterMessages } from './messages';

const allViews: POSView[] = ['daily', 'weekly', 'monthly'];

const dateModifiers: Record<
  POSView,
  Record<'add' | 'sub', (date: Date, amount: number) => Date>
> = {
  monthly: {
    add: addMonths,
    sub: subMonths,
  },
  weekly: {
    add: addWeeks,
    sub: subWeeks,
  },
  daily: {
    add: addDays,
    sub: subDays,
  },
};

export type POSNavbarProps = {
  view: POSView;
};

export const POSNavbar = ({ view }: POSNavbarProps) => {
  const { formatMessage, formatDate } = useIntl();
  const { date, ...filtersRest } = useFilters();

  const headingDate: Record<POSView, string> = {
    monthly: formatDate(date, { month: 'long', year: 'numeric' }),
    weekly: `${formatDate(startOfWeek(date), {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })} - ${formatDate(endOfWeek(date), {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })}`,
    daily: formatDate(date, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      weekday: 'long',
    }),
  };

  return (
    <div className="bg-neutral-0 shadow-sm relative">
      <div className="mx-auto px-4 lg:px-8 max-w-screen-xl">
        <div className="flex justify-between items-center gap-10 h-20">
          <div className="flex items-center gap-4">
            <RouterButton
              to={`?${stringifyParams({
                date: formatDateQueryParam(),
                ...filtersRest,
              })}`}
            >
              <FormattedMessage {...messages.today} />
            </RouterButton>
            <div className="flex gap-1">
              <RouterButton
                square
                emphasis="low"
                aria-label={formatMessage(messages.previous)}
                to={`?${stringifyParams({
                  date: formatDateQueryParam(dateModifiers[view].sub(date, 1)),
                  ...filtersRest,
                })}`}
              >
                <MdChevronLeft className="text-2xl" />
              </RouterButton>
              <RouterButton
                square
                emphasis="low"
                aria-label={formatMessage(messages.next)}
                to={`?${stringifyParams({
                  date: formatDateQueryParam(dateModifiers[view].add(date, 1)),
                  ...filtersRest,
                })}`}
              >
                <MdChevronRight className="text-2xl" />
              </RouterButton>
            </div>
            <h1 className="font-medium text-neutral-800 text-sm lg:text-xl text-center">
              {headingDate[view]}
            </h1>
          </div>
          <div className="flex gap-4 items-center">
            <FiltersModal />
            <div className="flex items-center justify-around bg-neutral-0 border border-neutral-200 rounded-lg h-11 px-1.5 gap-2">
              {allViews.map((posView) => (
                <RouterButton
                  size="sm"
                  emphasis={view === posView ? 'high' : 'low'}
                  key={posView}
                  to={`/pos/${posView}?${stringifyParams({
                    date: formatDateQueryParam(date),
                    ...filtersRest,
                  })}`}
                >
                  <FormattedMessage {...calendarFilterMessages[posView]} />
                </RouterButton>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
