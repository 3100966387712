import { defineMessages } from 'react-intl';
import { UserStateType } from '@cooltra/api';

export default defineMessages<UserStateType>({
  AWAITING_REVIEW: {
    defaultMessage: 'Awaiting review',
  },
  ACTIVATED: {
    defaultMessage: 'Active',
  },
  SUSPENDED: {
    defaultMessage: 'Suspended',
  },
  REJECTED: {
    defaultMessage: 'Rejected',
  },
  CORRECTIONS_REQUESTED: {
    defaultMessage: 'Corrections requested',
  },
  DELETED: {
    defaultMessage: 'Deleted',
  },
});
