import { useSuspendMutation } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import {
  Form,
  FormButton,
  FormHelpers,
  FormProvider,
  FormTextareaField,
} from '@cooltra/form';
import { useToggle } from '@cooltra/hooks';
import { Button, InputLabel, Modal } from '@cooltra/ui';
import { useQueryClient } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';

import { useNotification } from '~/hooks';

import messages from './messages';
import {
  suspendFormInitialValues,
  SuspendFormValues,
  validateSuspendForm,
} from './suspend-form';

export type SuspensionModalProps = {
  userId: string;
};

export const SuspensionModal = ({ userId }: SuspensionModalProps) => {
  const queryClient = useQueryClient();
  const intl = useIntl();

  const { hasPermission } = useAuthClaimsQuery();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const [isOpen, { toggleOff, toggleOn }] = useToggle();
  const mutation = useSuspendMutation(userId);

  const handleSubmit = (
    values: SuspendFormValues,
    { resetForm }: FormHelpers<SuspendFormValues>
  ) =>
    mutation
      .mutateAsync(values)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['user', userId] });
        queryClient.invalidateQueries({ queryKey: ['maas-user', userId] });
        addSuccessNotification(intl.formatMessage(messages.success));
        resetForm(values);
        toggleOff();
      })
      .catch(() => addErrorNotification());

  if (!hasPermission('write:users')) {
    return null;
  }

  return (
    <>
      <Button emphasis="high" onClick={toggleOn}>
        <FormattedMessage {...messages.addSuspension} />
      </Button>
      <Modal
        className="max-w-lg min-w-md"
        isOpen={isOpen}
        onRequestClose={toggleOff}
        header={
          <h6 className="text-neutral-400 text-lg font-semibold mt-8 ml-4">
            <FormattedMessage {...messages.addSuspension} />
          </h6>
        }
      >
        <div className="px-8 pt-6 pb-8" data-testid="SUSPENSION_MODAL">
          <FormProvider
            initialValues={suspendFormInitialValues}
            validate={validateSuspendForm(intl)}
            onSubmit={handleSubmit}
          >
            <Form>
              <FormTextareaField
                name="comment"
                className="mb-4"
                rows={5}
                id="comment"
                label={
                  <InputLabel htmlFor="comment">
                    <FormattedMessage {...messages.comment} />
                  </InputLabel>
                }
              />
              <div className="grid grid-cols-2 gap-2 justify-evenly">
                <Button type="button" onClick={toggleOff}>
                  <FormattedMessage {...messages.cancel} />
                </Button>
                <FormButton>
                  <FormattedMessage {...messages.addSuspension} />
                </FormButton>
              </div>
            </Form>
          </FormProvider>
        </div>
      </Modal>
    </>
  );
};
