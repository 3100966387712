import { FormattedMessage } from 'react-intl';
import { FormInputField } from '@cooltra/form';

import { OptionalFieldLabel } from '~/common';

import messages from './messages';

export const LicensePlate = () => (
  <FormInputField
    name="licensePlate"
    id="license-plate"
    label={
      <OptionalFieldLabel htmlFor="license-plate">
        <FormattedMessage {...messages.licensePlate} />
      </OptionalFieldLabel>
    }
  />
);
