import { ReactNode, useState } from 'react';

import { FocusedZoneContext } from './FocusedZoneContext';

export const FocusedZoneProvider = ({ children }: { children: ReactNode }) => {
  const [restrictionIdFocused, setRestrictionIdFocused] = useState<
    string | undefined
  >(undefined);
  const [bonusZoneIdFocused, setBonusZoneIdFocused] = useState<
    string | undefined
  >(undefined);

  return (
    <FocusedZoneContext.Provider
      value={{
        setRestrictionIdFocused,
        restrictionIdFocused,
        setBonusZoneIdFocused,
        bonusZoneIdFocused,
      }}
    >
      {children}
    </FocusedZoneContext.Provider>
  );
};
