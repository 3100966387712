import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useSignedContractPdfByDocumentIdQuery } from '@cooltra/station-based-api';
import { useEffect } from 'react';

import { useNotification } from '~/hooks';

import messages from './messages';

type SeeAnnexPDFButtonProps = {
  annexId: string;
  contractId: string;
};

export const SeeAnnexPDFButton = ({
  contractId,
  annexId,
}: SeeAnnexPDFButtonProps) => {
  const { addErrorNotification } = useNotification();
  const { fetchStatus, refetch, error } = useSignedContractPdfByDocumentIdQuery(
    contractId,
    annexId,
    { enabled: false }
  );

  useEffect(() => {
    if (error) {
      addErrorNotification();
    }
  }, [addErrorNotification, error]);

  return (
    <Button
      size="md"
      emphasis="medium"
      loading={fetchStatus === 'fetching'}
      onClick={() => refetch()}
    >
      <FormattedMessage {...messages.seeAnnex} />
    </Button>
  );
};
