import { IntlShape } from 'react-intl';

import {
  validateRequired,
  validateDate,
  validateTaxId,
  validateCity,
  createSetError,
} from '~/validation';

import {
  ReviewFormValues,
  ReviewFormErrors,
  ReviewFormFieldName,
} from '../review-form-types';

const requiredFields: ReviewFormFieldName[] = [
  'arePhotosValid',
  'street',
  'zip',
  'city',
  'country',
  'isAddressComplete',
];

type ValidateOptions = {
  hasDriverLicense: boolean;
  isDriverLicenseIgnored: boolean;
  hasIdentityCard: boolean;
};

export const validateActivation =
  (
    intl: IntlShape,
    {
      hasDriverLicense,
      isDriverLicenseIgnored,
      hasIdentityCard,
    }: ValidateOptions
  ) =>
  (values: ReviewFormValues): ReviewFormErrors => {
    const errors: ReviewFormErrors = {};

    const setError = createSetError(errors);

    if (values.isValidId === true) {
      setError('dateOfBirth', validateDate(intl, values.dateOfBirth));

      if (values.taxIdCountry) {
        setError(
          'idCardNumber',
          validateTaxId(intl, values.idCardNumber, values.taxIdCountry)
        );
        setError(
          'taxIdNumber',
          validateTaxId(intl, values.taxIdNumber, values.taxIdCountry)
        );
      }
      const requiredConditionalFields: ReviewFormFieldName[] = [
        'idCardNumber',
        'dateOfBirth',
        'taxIdNumber',
        'taxIdCountry',
      ];
      requiredConditionalFields.forEach((fieldName) => {
        setError(fieldName, validateRequired(intl, values[fieldName]));
      });
    }

    setError('city', validateCity(intl, values.city));

    if (hasDriverLicense && !isDriverLicenseIgnored) {
      setError(
        'isValidDriverLicense',
        validateRequired(intl, values.isValidDriverLicense)
      );

      if (values.isValidDriverLicense === true) {
        setError(
          'driverLicenseExpirationDate',
          validateDate(intl, values.driverLicenseExpirationDate)
        );
        const requiredConditionalFields: ReviewFormFieldName[] = [
          'driverLicenseNumber',
          'driverLicenseCategory',
          'driverLicenseCountry',
        ];
        requiredConditionalFields.forEach((fieldName) => {
          setError(fieldName, validateRequired(intl, values[fieldName]));
        });
      }
    }

    if (hasIdentityCard) {
      setError('isValidId', validateRequired(intl, values.isValidId));
    }

    requiredFields.forEach((fieldName) => {
      setError(fieldName, validateRequired(intl, values[fieldName]));
    });

    return errors;
  };

export const validateNonActivation =
  (intl: IntlShape) =>
  (values: ReviewFormValues): ReviewFormErrors => {
    const errors: ReviewFormErrors = {};

    const setError = createSetError(errors);

    if (values.dateOfBirth) {
      setError('dateOfBirth', validateDate(intl, values.dateOfBirth));
    }

    if (values.taxIdCountry) {
      if (values.idCardNumber) {
        setError(
          'idCardNumber',
          validateTaxId(intl, values.idCardNumber, values.taxIdCountry)
        );
      }
      if (values.taxIdNumber) {
        setError(
          'taxIdNumber',
          validateTaxId(intl, values.taxIdNumber, values.taxIdCountry)
        );
      }
    }

    if (values.city) {
      setError('city', validateCity(intl, values.city));
    }

    if (values.driverLicenseExpirationDate) {
      setError(
        'driverLicenseExpirationDate',
        validateDate(intl, values.driverLicenseExpirationDate)
      );
    }

    return errors;
  };
