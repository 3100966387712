import { HTMLAttributes } from 'react';
import { Provider } from '@cooltra/api';

import { providerNames } from '~/utils/providers';

import freeNow from './assets/freenow.svg';
import imbric from './assets/imbric.png';
import renfe from './assets/renfe.svg';
import cabify from './assets/cabify.svg';
import sixt from './assets/sixt.svg';
import urbi from './assets/urbi.png';
import telepass from './assets/telepass.png';
import umob from './assets/umob.png';

const providerLogos: { [key in Provider]: string } = {
  COOLTRA: '',
  FREENOW: freeNow,
  IMBRIC: imbric,
  SIXT: sixt,
  RENFE: renfe,
  CABIFY: cabify,
  URBI: urbi,
  TELEPASS: telepass,
  UMOB: umob,
};

export type MaasLogoProps = Omit<
  HTMLAttributes<HTMLImageElement>,
  'src' | 'alt'
> & {
  provider: Provider;
};

export const MaasLogo = ({ provider, ...rest }: MaasLogoProps) => {
  const logo = providerLogos[provider];

  if (!logo) {
    return null;
  }

  return (
    <img
      alt={providerNames[provider]}
      src={providerLogos[provider]}
      {...rest}
    />
  );
};
