import { IntlShape } from 'react-intl';

import { createSetError, validateDate, validateRequired } from '~/validation';
import { ServiceFormValues } from '~/common';

import messages from './messages';

type ServiceFormFieldName = keyof ServiceFormValues;
type ServiceFormErrors = Partial<
  Record<ServiceFormFieldName, string | undefined>
>;
export const editServiceFormValidation =
  (intl: IntlShape) => (values: ServiceFormValues) => {
    const errors: ServiceFormErrors = {};
    const setError = createSetError(errors);

    if (values.servicedBy || values.servicedAt || values.serviceMileage) {
      setError('servicedBy', validateRequired(intl, values.servicedBy));
      setError('servicedAt', validateRequired(intl, values.servicedAt));
      setError('serviceMileage', validateRequired(intl, values.serviceMileage));
    }

    if (values.scheduledAt) {
      setError(
        'scheduledAt',
        validateDate(intl, values.scheduledAt)
          ? intl.formatMessage(messages.invalidFormat)
          : undefined
      );
    }

    if (values.servicedAt) {
      setError(
        'servicedAt',
        validateDate(intl, values.servicedAt)
          ? intl.formatMessage(messages.invalidFormat)
          : undefined
      );
    }

    return errors;
  };
