import { useMaasUserQuery } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { SpinnerScreen } from '@cooltra/ui';

import {
  ErrorPage,
  Four0Three,
  SidebarLayout,
  UserRentals,
  UserReservations,
} from '~/common';

import { Profile } from '../Profile';
import { Suspensions } from '../Suspensions/Suspensions';
import { Billing } from '../Billing/Billing';

import { Sidebar, SidebarLoading } from './Sidebar';

export const Account = () => {
  const { id = '' } = useParams<'id'>();

  const { data: maasUser, isError, error } = useMaasUserQuery(id);

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <SidebarLayout
      sidebar={maasUser ? <Sidebar maasUser={maasUser} /> : <SidebarLoading />}
    >
      <Routes>
        <Route path="profile/*" element={<Profile />} />
        <Route
          path="suspensions"
          element={
            <Suspensions userId={id} userDeletedAt={maasUser?.deletedAt} />
          }
        />
        <Route path="rentals" element={<UserRentals />} />
        <Route path="reservations" element={<UserReservations />} />
        <Route
          path="billing/*"
          element={
            maasUser ? (
              <Billing
                userId={maasUser.id}
                system={maasUser.system}
                provider={maasUser.provider}
              />
            ) : (
              <SpinnerScreen />
            )
          }
        />
        <Route path="*" element={<Navigate replace to="profile" />} />
      </Routes>
    </SidebarLayout>
  );
};
