import { ChangeEvent } from 'react';
import { CheckboxField, InputLabel, Legend, SelectOption } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSelectField, useFormContext } from '@cooltra/form';

import {
  BenefitExpirationRangeMode,
  PromotionFormValues,
} from '../../PromotionForm';
import { PromotionStateType } from '../../PromotionState/PromotionState';

import messages, { benefitExpirationRangeModeMessages } from './messages';
import { ExpirationInputField } from './ExpirationInputField';

type CreditExpirationRangeSelectOption = SelectOption & {
  value: BenefitExpirationRangeMode;
};

export const BenefitExpiresAt = () => {
  const { formatMessage } = useIntl();
  const { values, setValues, isSubmitting } =
    useFormContext<PromotionFormValues>();
  const { editMode, stateType } = values;
  const editingExpiredPromotion =
    editMode && stateType === PromotionStateType.EXPIRED;
  const inputsDisabled = values.benefitDoesntExpire || editingExpiredPromotion;

  const handleChange = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setValues({
        benefitUntilDate: '',
        benefitUntilTime: '',
        benefitExpiresAfterHours: '',
        benefitExpiresAfterDays: '',
        benefitDoesntExpire: checked,
      });
    } else {
      setValues({ benefitDoesntExpire: checked });
    }
  };

  const availableSelectOptionsModes: BenefitExpirationRangeMode[] = [
    'specific-date',
    'after-hours',
    'after-days',
  ];

  const selectOptions: CreditExpirationRangeSelectOption[] =
    availableSelectOptionsModes.map((option) => ({
      label: formatMessage(benefitExpirationRangeModeMessages[option]),
      value: option,
    }));

  const isRangeDateActivated =
    values.benefitExpirationRangeMode === 'specific-date';

  const getTitleLegend = () => {
    return (
      <Legend className="mb-1">
        {values.promotionTypeMode === 'discount' ? (
          <FormattedMessage {...messages.discountValidity} />
        ) : (
          <FormattedMessage {...messages.expirationAfterRedeem} />
        )}
      </Legend>
    );
  };

  const getDoesNotExpireCheckboxField = () => (
    <CheckboxField
      id="benefit-expires-at"
      className="mt-8"
      checked={values.benefitDoesntExpire}
      label={
        <InputLabel emphasis="low" htmlFor="benefit-expires-at">
          <FormattedMessage {...messages.doesntExpire} />
        </InputLabel>
      }
      disabled={isSubmitting || editingExpiredPromotion}
      onChange={handleChange}
    />
  );

  if (isRangeDateActivated) {
    return (
      <div
        className="flex-col gap-4 items-start"
        data-testid="BENEFIT_EXPIRES_AT"
      >
        <fieldset className="flex gap-2">
          {getTitleLegend()}
          <FormSelectField
            className="w-44 mb-2"
            disabled={editingExpiredPromotion}
            name="benefitExpirationRangeMode"
            options={selectOptions}
            aria-label={formatMessage(messages.expirationMode)}
          />
        </fieldset>
        <div className="flex mt-2 gap-x-8 items-start">
          <ExpirationInputField disabled={inputsDisabled} />
          {getDoesNotExpireCheckboxField()}
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-x-8 items-start" data-testid="BENEFIT_EXPIRES_AT">
      <fieldset className="flex gap-2">
        {getTitleLegend()}
        <FormSelectField
          className="w-44 mb-2"
          disabled={editingExpiredPromotion}
          name="benefitExpirationRangeMode"
          options={selectOptions}
          aria-label={formatMessage(messages.expirationMode)}
        />
        <ExpirationInputField disabled={inputsDisabled} />
      </fieldset>
      {getDoesNotExpireCheckboxField()}
    </div>
  );
};
