import { DailyContractsListItem } from '@cooltra/station-based-api';
import { useRef } from 'react';
import { consecutiveNumbers } from '@cooltra/utils';

import { ContractCard } from './ContractCard';

export type ContractsByTimeProps = {
  time: string;
  contracts: DailyContractsListItem[];
};

export const daySlots = consecutiveNumbers(24)
  .map((hour) =>
    ['00', '30'].map(
      (minutes) => `${hour.toString().padStart(2, '0')}:${minutes}`
    )
  )
  .flat();

export const ContractsByTime = ({ time, contracts }: ContractsByTimeProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref} className="flex gap-4" data-testid={time}>
      <span className="font-semibold text-neutral-400">{time}</span>
      <div className="flex flex-col gap-2.5 w-full">
        {contracts.map((contract) => (
          <ContractCard key={contract.contractId} contract={contract} />
        ))}
      </div>
    </div>
  );
};
