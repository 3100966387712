import { defineMessages } from 'react-intl';

export default defineMessages({
  workStarted: {
    defaultMessage: 'Work started',
  },
  workInProgress: {
    defaultMessage: 'Work in progress',
  },
  workFinished: {
    defaultMessage: 'Work finished',
  },
  workSkipped: {
    defaultMessage: 'Work skipped',
  },
});
