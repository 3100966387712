import { Contract, ContractStatus } from '@cooltra/station-based-api';
import { IntlShape } from 'react-intl';

import { createSetError, errorMessages } from '~/validation';
import { getContractTypeName } from '~/utils/contract';

export type ContractDetailsFormValues = {
  type: string;
  pickup?: string;
  pickUpDate?: Date;
  pickUpTime?: string;
  dropOffDate?: Date;
  dropOffTime?: string;
  contractStatus: ContractStatus;
};

export type ContractDetailsFormFieldName = keyof ContractDetailsFormValues;

type ContractDetailsFormErrors = Partial<
  Record<ContractDetailsFormFieldName, string | undefined>
>;

export const validateRequired = (intl: IntlShape, value: any) =>
  value === '' || value === undefined
    ? intl.formatMessage(errorMessages.required)
    : undefined;

export const validateContractDetailsForm =
  (intl: IntlShape) =>
  (values: ContractDetailsFormValues): ContractDetailsFormErrors => {
    const errors: ContractDetailsFormErrors = {};
    const setError = createSetError(errors);

    const requiredFields: ContractDetailsFormFieldName[] = [
      'pickup',
      'type',
      'pickUpDate',
      'pickUpTime',
      'dropOffDate',
      'dropOffTime',
    ];
    requiredFields.forEach((fieldName) => {
      setError(fieldName, validateRequired(intl, values[fieldName]));
    });

    return errors;
  };

export const getInitialContractDetailsFormValues = (
  contract: Contract,
  preferredServicePointId: string | undefined
): ContractDetailsFormValues => {
  const { details, type } = contract;

  if (!details) {
    return {
      type: '',
      pickup: preferredServicePointId || '',
      pickUpDate: undefined,
      pickUpTime: '',
      dropOffDate: undefined,
      dropOffTime: '',
      contractStatus: 'NEW',
    };
  }

  return {
    type: getContractTypeName(type),
    pickup: details.servicePointId,
    pickUpDate: new Date(details.startDate),
    pickUpTime: details.startTime,
    dropOffDate: new Date(details.endDate),
    dropOffTime: details.endTime,
    contractStatus: contract.status,
  };
};
