import { Card, Icon } from '@cooltra/ui';
import { PropsWithChildren } from 'react';
import { MdCheckCircleOutline } from 'react-icons/md';

export const EmptyListMessageCard = ({ children }: PropsWithChildren) => (
  <Card className="h-36 flex items-center justify-center opacity-60 px-8">
    <p className="text-neutral-600 text-sm">
      <Icon className="text-lg mr-0.5 relative top-1">
        <MdCheckCircleOutline />
      </Icon>
      {children}
    </p>
  </Card>
);
