import { PropsWithChildren } from 'react';
import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { getEnvVariable } from '~/utils/environment';

export const Auth0ProviderWithHistory = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      useRefreshTokensFallback
      useRefreshTokens
      domain={getEnvVariable('AUTH0_DOMAIN')}
      clientId={getEnvVariable('AUTH0_CLIENT_ID')}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: getEnvVariable('AUTH0_AUDIENCE'),
      }}
    >
      {children}
    </Auth0Provider>
  );
};
