import { RouterLink } from '@cooltra/navigation';
import { shortenId } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';

import { Pair } from '../Pair';

import messages from './messages';

export type ContextProps = {
  rentalId?: string | null;
  reservationId?: string | null;
  isLoading?: boolean;
};

export const Context = ({
  rentalId,
  reservationId,
  isLoading = false,
}: ContextProps) => {
  if (isLoading) {
    return (
      <div>
        <Pair.LabelLoading className="mb-1" />
        <Pair.ValueLoading />
      </div>
    );
  }

  if (rentalId) {
    return (
      <div>
        <Pair.Label className="mb-1">
          <FormattedMessage {...messages.rental} />
        </Pair.Label>
        <Pair.Value>
          <RouterLink to={`/rentals/${rentalId}`}>
            #{shortenId(rentalId)}
          </RouterLink>
        </Pair.Value>
      </div>
    );
  }

  if (reservationId) {
    return (
      <div>
        <Pair.Label className="mb-1">
          <FormattedMessage {...messages.reservation} />
        </Pair.Label>
        <Pair.Value>#{shortenId(reservationId)}</Pair.Value>
      </div>
    );
  }

  return <></>;
};
