import { defineMessages } from 'react-intl';
import { RentalTimelineCheckoutErrorType } from '@cooltra/api';

export default defineMessages({
  failed: {
    defaultMessage: 'Failed',
  },
  atTime: {
    defaultMessage: 'at {time}',
  },
  errors: {
    defaultMessage: '{amountOfErrors, plural, one {# error} other {# errors}}',
  },
  checkOutAttemptDueTo: {
    defaultMessage: 'Checkout attempt due to {error}',
  },
  closeRental: {
    defaultMessage: 'Close',
  },
});

export const errorTypesMessage =
  defineMessages<RentalTimelineCheckoutErrorType>({
    telematics_error: {
      defaultMessage: 'telematics error(s)',
    },
    policy_error: {
      defaultMessage: 'policies error(s)',
    },
    internal_error: {
      defaultMessage: 'internals error(s)',
    },
  });
