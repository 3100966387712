import { Form, FormButton, FormProvider, FormSelectField } from '@cooltra/form';
import { useToggle } from '@cooltra/hooks';
import { Button, InputLabel, Modal } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDamageMutation } from '@cooltra/station-based-api';

import { useNotification } from '~/hooks';
import {
  allDamageTypes,
  allVehicleParts,
  getDamageTypeMessage,
  getVehiclePartMessage,
} from '~/utils/damages';

import { CameraImageField } from '../CameraImageField/CameraImageField';

import {
  AddDamageFormValues,
  initialValues,
  validateAddDamageForm,
} from './add-damage-form';
import messages from './messages';

export type AddDamageProps = {
  vehicleId: string;
  disabled?: boolean;
};

export const AddDamage = ({ vehicleId, disabled = false }: AddDamageProps) => {
  const intl = useIntl();
  const [isOpen, { toggleOff, toggleOn }] = useToggle();
  const { mutateAsync } = useDamageMutation(vehicleId);
  const { addErrorNotification } = useNotification();

  const onSubmit = async ({
    imageId,
    type,
    vehiclePart,
  }: AddDamageFormValues) => {
    if (type && vehiclePart && imageId) {
      mutateAsync({ imageId, type, vehiclePart })
        .then(() => toggleOff())
        .catch(() => addErrorNotification());
    }
  };

  return (
    <>
      <Button onClick={toggleOn} disabled={disabled}>
        <FormattedMessage {...messages.addDamage} />
      </Button>
      <Modal isOpen={isOpen} onRequestClose={toggleOff}>
        <div className="min-w-lg max-w-lg px-8 pb-8">
          <h2 className="text-xl text-neutral-700 font-semibold mb-8">
            <FormattedMessage {...messages.addDamage} />
          </h2>
          <FormProvider
            initialValues={initialValues}
            validate={validateAddDamageForm(intl)}
            onSubmit={onSubmit}
          >
            <Form>
              <div className="grid grid-cols-2 gap-4 pb-6">
                <FormSelectField
                  isClearable
                  name="vehiclePart"
                  options={allVehicleParts.map((value) => ({
                    value,
                    label: intl.formatMessage(getVehiclePartMessage(value)),
                  }))}
                  id="vehicle-part"
                  label={
                    <InputLabel htmlFor="vehicle-part">
                      <FormattedMessage {...messages.vehiclePart} />
                    </InputLabel>
                  }
                />
                <FormSelectField
                  isClearable
                  name="type"
                  options={allDamageTypes.map((value) => ({
                    value,
                    label: intl.formatMessage(getDamageTypeMessage(value)),
                  }))}
                  id="type"
                  label={
                    <InputLabel htmlFor="type">
                      <FormattedMessage {...messages.type} />
                    </InputLabel>
                  }
                />
              </div>
              <CameraImageField />
              <div className="flex justify-center pt-10">
                <FormButton disableInvalid className="mx-auto">
                  <FormattedMessage {...messages.saveDamage} />
                </FormButton>
              </div>
            </Form>
          </FormProvider>
        </div>
      </Modal>
    </>
  );
};
