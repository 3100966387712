import { ButtonHTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { LanguageCode } from '@cooltra/countries';
import { classNames } from '@cooltra/utils';
import { Icon } from '@cooltra/ui';
import { MdOutlineExpandMore } from 'react-icons/md';

import { headings } from './messages';

export type TranslationSectionHeaderProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'children'
> & {
  language: LanguageCode;
  isActive: boolean;
};

export const TranslationSectionHeader = ({
  language,
  isActive,
  className,
  ...rest
}: TranslationSectionHeaderProps) => (
  <button
    type="button"
    className={classNames(
      'w-full flex items-center justify-between px-10 py-6 focus-visible:ring focus-visible:ring-primary-200 transition-shadow',
      className
    )}
    {...rest}
  >
    <span className="block text-xl font-semibold text-neutral-400">
      <FormattedMessage {...headings[language]} />
    </span>
    <Icon
      className={classNames(
        'text-xl text-neutral-500 relative transition-transform',
        isActive ? 'rotate-180' : 'rotate-0'
      )}
    >
      <MdOutlineExpandMore />
    </Icon>
  </button>
);
