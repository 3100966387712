import { FormattedMessage } from 'react-intl';
import { useField } from '@cooltra/form';
import { Button, Image } from '@cooltra/ui';
import { useVehicleParkingPhoto } from '@cooltra/station-based-api';

import messages from './messages';

export const ParkingPhotoView = () => {
  const { value, setValue } = useField('parkingPhoto');
  const parkingPhoto = useVehicleParkingPhoto(value);
  const parkingPhotoBase64 = parkingPhoto
    ? `data:image/jpeg;base64,${parkingPhoto}`
    : '';

  const removeParkingPhoto = () => setValue(null);

  return (
    <div className="h-[444px] bg-neutral-100 rounded-lg overflow-hidden relative">
      <Image
        key={value}
        alt={value}
        src={parkingPhotoBase64}
        className="h-full w-full object-cover absolute"
      />
      <Button
        size="sm"
        variant="danger"
        onClick={removeParkingPhoto}
        className="absolute bottom-3 left-3"
      >
        <FormattedMessage {...messages.deletePicture} />
      </Button>
    </div>
  );
};
