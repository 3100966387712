import { HTMLAttributes, ReactNode } from 'react';
import { classNames } from '@cooltra/utils';

export type ReservationRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  checkIn: ReactNode;
  createdAt: ReactNode;
  expiresAt: ReactNode;
  problemReports: ReactNode;
  spacer?: ReactNode;
  state: ReactNode;
  system: ReactNode;
  user: ReactNode;
  vehicle: ReactNode;
};

export const ReservationRowLayout = ({
  checkIn,
  createdAt,
  expiresAt,
  problemReports,
  spacer,
  state,
  system,
  user,
  vehicle,
  className,
  ...rest
}: ReservationRowLayoutProps) => (
  <div
    className={classNames(
      'relative w-full flex items-center gap-4 pl-6 pr-4',
      className
    )}
    {...rest}
  >
    <div className="w-24">{createdAt}</div>
    <div className="w-4">{spacer}</div>
    <div className="w-24 mr-4">{expiresAt}</div>
    <div className="flex-1 flex items-center">{vehicle}</div>
    <div className="w-32">{user}</div>
    <div className="w-20">{system}</div>
    <div className="w-24 text-center">{checkIn}</div>
    <div className="w-28 text-center">{problemReports}</div>
    <div className="w-32 text-center">{state}</div>
  </div>
);
