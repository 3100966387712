import { parse } from 'date-fns';
import { IntlShape } from 'react-intl';

import { dateAndTimeFormat } from '~/utils/date';
import {
  createSetError,
  validateAmount,
  validateDate,
  validateExactLength,
  validateRequired,
  validateTime,
} from '~/validation';

import { CooltraForEmployeesFormValues } from './cooltra-for-employees-form';
import messages from './messages';

type CooltraForEmployeesFormFieldName = keyof CooltraForEmployeesFormValues;

type CooltraForEmployeesFormErrors = Partial<
  Record<CooltraForEmployeesFormFieldName, string | undefined>
>;

export const validateCooltraForEmployeesForm =
  (intl: IntlShape) => (values: CooltraForEmployeesFormValues) => {
    const errors: CooltraForEmployeesFormErrors = {};
    const setError = createSetError(errors);

    setError('title', validateRequired(intl, values.title));
    setError('subscription', validateRequired(intl, values.subscription));
    setError('companyName', validateRequired(intl, values.companyName));

    const codePrefixError =
      validateRequired(intl, values.codePrefix) ||
      validateExactLength(intl, values.codePrefix, 4);

    setError('codePrefix', codePrefixError);
    setError(
      'numberOfCodes',
      validateAmount(intl, String(values.numberOfCodes))
    );

    setError(
      'redeemableFromDate',
      validateDate(intl, values.redeemableFromDate)
    );
    setError(
      'redeemableFromTime',
      validateTime(intl, values.redeemableFromTime)
    );

    if (!values.redeemableDoesNotExpire) {
      setError(
        'redeemableUntilDate',
        validateDate(intl, values.redeemableUntilDate)
      );
      setError(
        'redeemableUntilTime',
        validateTime(intl, values.redeemableUntilTime)
      );
    }

    const allRedeemableDatesAreFulfilled =
      values.redeemableFromDate != null &&
      values.redeemableFromTime != null &&
      values.redeemableUntilDate != null &&
      values.redeemableUntilTime != null;

    if (allRedeemableDatesAreFulfilled) {
      const redeemableFromDatetime = `${values.redeemableFromDate} ${values.redeemableFromTime}`;
      const redeemableUntilDatetime = `${values.redeemableUntilDate} ${values.redeemableUntilTime}`;

      const redeemableFromDateParsed = parse(
        redeemableFromDatetime,
        dateAndTimeFormat,
        new Date()
      );
      const redeemableUntilDateParsed = parse(
        redeemableUntilDatetime,
        dateAndTimeFormat,
        new Date()
      );

      if (redeemableUntilDateParsed <= redeemableFromDateParsed) {
        setError(
          'redeemableUntilDate',
          intl.formatMessage(messages.validFromGreaterThanValidUntil)
        );
      }
    }

    return errors;
  };
