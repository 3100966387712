import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: "You're not authorized",
  },
  content: {
    defaultMessage:
      "You don't have sufficient permissions to see the contents of this page",
  },
});
