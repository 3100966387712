import { Task } from '@cooltra/api';
import { MdCheckCircleOutline, MdOutlineCancel } from 'react-icons/md';
import { Icon } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type BlockingStaticProps = {
  value: Task['blocking'];
};

export const BlockingStatic = ({ value }: BlockingStaticProps) => (
  <span className="flex items-center gap-1">
    {value ? (
      <Icon className="text-danger-500 text-xl">
        <MdOutlineCancel />
      </Icon>
    ) : (
      <Icon className="text-success-500 text-xl">
        <MdCheckCircleOutline />
      </Icon>
    )}
    <span className="text-neutral-1000 text-sm">
      {value ? (
        <FormattedMessage {...messages.blocking} />
      ) : (
        <FormattedMessage {...messages.notBlocking} />
      )}
    </span>
  </span>
);
