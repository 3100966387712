import { RentalDiscountItem } from '@cooltra/api';
import { defineMessages } from 'react-intl';

export const pricingDiscountTypesMessages = defineMessages<
  RentalDiscountItem['type']
>({
  VEHICLE_BONUS: {
    defaultMessage: 'Vehicle bonus',
  },
  AREA_BONUS: {
    defaultMessage: 'Bonus zone',
  },
  PASS: {
    defaultMessage: 'Pass',
  },
  DISCOUNT: {
    defaultMessage: 'Discount promotion',
  },
});

export default defineMessages({
  totalPrice: {
    defaultMessage: 'Total price',
  },
  riding: {
    defaultMessage: 'Ride ({charge} / min) – {durationInMinutes}m',
  },
  pausing: {
    defaultMessage: 'Pause ({charge} / min) – {durationInMinutes}m',
  },
  starting: {
    defaultMessage: 'Starting fee',
  },
  credit: {
    defaultMessage: 'Credit',
  },
  totalCharged: {
    defaultMessage: 'Total charged',
  },
});
