import { Card, Placeholder, TimelineItem } from '@cooltra/ui';

export const TimelineLoading = () => (
  <Card className="w-full pt-10 pl-16 pr-8">
    <div className="relative">
      <TimelineItem
        header={
          <>
            <span className="absolute -left-9 top-0.5">
              <Placeholder className="w-10 h-3 mt-1" />
            </span>
            <span>
              <Placeholder className="w-24 h-3 mt-1.5" />
            </span>
          </>
        }
        timelineClassName="min-h-20 translate-y-2"
      />
    </div>
    <div className="relative">
      <TimelineItem
        header={
          <>
            <span className="absolute -left-9 top-0.5">
              <Placeholder className="w-10 h-3 mt-1" />
            </span>
            <span>
              <Placeholder className="w-24 h-3 mt-1.5" />
            </span>
          </>
        }
        timelineClassName="min-h-20 translate-y-2"
      />
    </div>
    <div className="relative">
      <TimelineItem
        header={
          <>
            <span className="absolute -left-9 top-0.5">
              <Placeholder className="w-10 h-3 mt-1" />
            </span>
            <span>
              <Placeholder className="w-24 h-3 mt-1.5" />
            </span>
          </>
        }
        timelineClassName="min-h-20 translate-y-2"
        isLast
      />
    </div>
  </Card>
);
