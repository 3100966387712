import { LanguageCode } from '@cooltra/countries';
import { FormInputField, FormTextareaField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { HTMLAttributes, forwardRef } from 'react';

import { OptionalFieldLabel } from '../../../OptionalFieldLabel/OptionalFieldLabel';

import { descriptionLabels, titleLabels } from './messages';

export type TranslationSectionProps = HTMLAttributes<HTMLDivElement> & {
  language: LanguageCode;
  disabled: boolean;
  requireDescription?: boolean;
  includeDescription?: boolean;
};

export const TranslationsSection = forwardRef<
  HTMLDivElement,
  TranslationSectionProps
>(
  (
    {
      language,
      disabled,
      requireDescription = false,
      includeDescription = true,
      ...rest
    },
    ref
  ) => (
    <div {...rest}>
      <div className="grid gap-y-10 px-10 pb-10 pt-2" ref={ref}>
        <FormInputField
          name={`titleTranslations.${language}`}
          id={`title-translations-${language}`}
          disabled={disabled}
          label={
            <InputLabel htmlFor={`title-translations-${language}`}>
              <FormattedMessage {...titleLabels[language]} />
            </InputLabel>
          }
        />
        {includeDescription && (
          <FormTextareaField
            name={`descriptionTranslations.${language}`}
            id={`description-translations-${language}`}
            disabled={disabled}
            rows={5}
            label={
              requireDescription ? (
                <InputLabel htmlFor={`description-translations-${language}`}>
                  <FormattedMessage {...descriptionLabels[language]} />
                </InputLabel>
              ) : (
                <OptionalFieldLabel
                  htmlFor={`description-translations-${language}`}
                >
                  <FormattedMessage {...descriptionLabels[language]} />
                </OptionalFieldLabel>
              )
            }
          />
        )}
      </div>
    </div>
  )
);
