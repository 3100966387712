import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Review is temporarily locked',
  },
  content: {
    defaultMessage:
      "This user is being currently reviewed by another operator. It's gonna be unlocked within {duration}.",
  },
  duration: {
    defaultMessage: '3 minutes',
  },
});
