import { useIntl } from 'react-intl';
import { Placeholder } from '@cooltra/ui';

import { PairValue } from './PairValue';
import messages from './messages';

type AvailableVehiclesItemProps = {
  totalAvailableVehicles: number;
  isLoading: boolean;
};

export const AvailableVehiclesItem = ({
  totalAvailableVehicles,
  isLoading,
}: AvailableVehiclesItemProps) => {
  const { formatMessage } = useIntl();

  if (isLoading) {
    return (
      <PairValue
        value={
          <div className="h-10 flex items-center">
            <Placeholder className="w-12 h-3" />
          </div>
        }
        title={formatMessage(messages.vehiclesAvailable)}
      />
    );
  }

  return (
    <PairValue
      value={totalAvailableVehicles}
      title={formatMessage(messages.vehiclesAvailable)}
    />
  );
};
