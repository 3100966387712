import { FormattedMessage } from 'react-intl';
import { Badge, BadgeProps } from '@cooltra/ui';

import messages from './messages';

export type ClosedBadgeProps = Omit<BadgeProps, 'emphasis'>;

export const ClosedBadge = (props: ClosedBadgeProps) => (
  <Badge emphasis="low" {...props}>
    <FormattedMessage {...messages.closed} />
  </Badge>
);
