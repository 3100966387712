import { Button } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';
import { useCancelAwaitingPaymentTicketMutation } from '@cooltra/station-based-api';
import { FormattedMessage, useIntl } from 'react-intl';

import { ConfirmModal } from '~/common';
import { useNotification } from '~/hooks';

import messages from './messages';

type CancelAwaitingPaymentButtonProps = {
  contractId: string;
  ticketId: string;
};

export const CancelAwaitingPaymentButton = ({
  contractId,
  ticketId,
}: CancelAwaitingPaymentButtonProps) => {
  const intl = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle(false);
  const { mutateAsync, isPending } = useCancelAwaitingPaymentTicketMutation(
    contractId,
    ticketId
  );
  const { addSuccessNotification, addErrorNotification } = useNotification();

  const cancelAwaitingPayment = () =>
    mutateAsync()
      .then(() => {
        addSuccessNotification(intl.formatMessage(messages.success));
        toggleOff();
      })
      .catch(() => addErrorNotification());

  return (
    <>
      <Button onClick={toggleOn} emphasis="low" size="sm" variant="danger">
        <FormattedMessage {...messages.cancel} />
      </Button>
      <ConfirmModal
        className="max-w-md min-w-xs pb-3"
        isOpen={isOpen}
        title={intl.formatMessage(messages.cancelAwaitingPaymentTitle)}
        content={
          <p className="px-12 mt-7 mb-9 text-neutral-700 text-md">
            <FormattedMessage {...messages.cancelAwaitingPaymentContent} />
          </p>
        }
        confirmText={<FormattedMessage {...messages.confirm} />}
        onConfirm={cancelAwaitingPayment}
        onClose={toggleOff}
        loading={isPending}
      />
    </>
  );
};
