import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PriceListTypeContext } from './PriceListTypeContext';
import { VehicleTypePriceListDetails } from './VehicleTypePriceListDetails/VehicleTypePriceListDetails';
import { VehicleTypePriceLists } from './VehicleTypePriceLists/VehicleTypePriceLists';
import { AccessoriesPriceList } from './AccessoriesPriceList/AccessoriesPriceList';
import { AccessoryPriceDetails } from './AccessoryPriceDetails/AccessoryPriceDetails';
import { CoveragePriceDetails } from './CoveragePriceDetails/CoveragePriceDetails';

export const PriceListsRoutes = () => {
  const { type } = useContext(PriceListTypeContext);

  return (
    <Routes>
      <Route path="/vehicle-types/*" element={<VehicleTypePriceLists />} />
      <Route
        path="/vehicle-types/:priceListId/*"
        element={<VehicleTypePriceListDetails />}
      />
      <Route path="/accessories/*" element={<AccessoriesPriceList />} />
      <Route
        path="/accessories/:accessoryId/*"
        element={<AccessoryPriceDetails />}
      />
      <Route
        path="/coverages/:coverageId/*"
        element={<CoveragePriceDetails />}
      />
      <Route
        path="*"
        element={
          <Navigate
            replace
            to={`/price-lists/${type.toLowerCase()}/vehicle-types`}
          />
        }
      />
    </Routes>
  );
};
