import { Alert, Button } from '@cooltra/ui';
import { RentalTimelineCheckoutError } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { differenceInMinutes, differenceInSeconds } from 'date-fns';
import { useField } from '@cooltra/form';

import { RentalTimelineItemReduced } from '~/libs/reduce-rental-timeline/reduce-rental-timeline';

import { RentalTimelineRowLayout } from '../RentalTimelineRowLayout';
import { RentalTimelineTick } from '../RentalTimelineTick';

import messages, { errorTypesMessage } from './messages';

export type RentalTimelineCheckoutFailureItemProps = {
  rentalActiveOccurredOn: string;
  error: RentalTimelineCheckoutError;
  isLast: boolean;
  isRentalActive: boolean;
} & Pick<
  RentalTimelineItemReduced,
  'occurredOn' | 'endAttemptsOn' | 'attemptsTimes'
>;

export const CheckoutFailureItem = ({
  isLast,
  occurredOn,
  rentalActiveOccurredOn,
  endAttemptsOn,
  attemptsTimes,
  isRentalActive,
  error: { type, detail },
}: RentalTimelineCheckoutFailureItemProps) => {
  const { hasPermission } = useAuthClaimsQuery();
  const { setValue } = useField('billableDuration');

  const prefillCloseRentalForm = () => {
    setValue(minutesSinceActive);
    window.scrollTo(0, 0);
  };

  const minutesSinceActive = differenceInMinutes(
    new Date(occurredOn),
    new Date(rentalActiveOccurredOn),
    { roundingMethod: 'ceil' }
  );

  const secondsSinceActive = differenceInSeconds(
    new Date(occurredOn),
    new Date(rentalActiveOccurredOn)
  );

  const getTotalDurationOfAttempts = () => {
    if (!endAttemptsOn) {
      return <></>;
    }

    const durationInMinutes = differenceInMinutes(
      new Date(endAttemptsOn),
      new Date(occurredOn)
    );

    if (durationInMinutes > 0) {
      return (
        <span className="text-neutral-400 text-xs">({durationInMinutes}m)</span>
      );
    }

    const durationInSeconds = differenceInSeconds(
      new Date(endAttemptsOn),
      new Date(occurredOn)
    );

    return (
      <span className="text-neutral-400 text-xs">({durationInSeconds}s)</span>
    );
  };

  return (
    <div
      data-testid={`CHECKOUT_FAILURE_ITEM_${type.toUpperCase()}`}
      className="mb-8"
    >
      <RentalTimelineRowLayout
        isLast={isLast}
        timeline={<RentalTimelineTick variant="danger" />}
        content={
          <div className="flex items-center text-xs">
            <span className="text-danger-700 mr-2">
              <FormattedTime
                value={occurredOn}
                hour="2-digit"
                hourCycle="h23"
                minute="2-digit"
              />
            </span>
            <div className="flex flex-1 items-center justify-between">
              <div className="flex gap-1">
                <span className="font-semibold text-danger-700">
                  <FormattedMessage {...messages.failed} />
                </span>
                <span className="text-neutral-400">
                  <FormattedMessage
                    {...messages.atTime}
                    values={{
                      time:
                        minutesSinceActive > 0
                          ? `${minutesSinceActive}m`
                          : `${secondsSinceActive}s`,
                    }}
                  />
                </span>
              </div>
              {attemptsTimes && (
                <div className="flex gap-1">
                  <span className="font-semibold">
                    <FormattedMessage
                      {...messages.errors}
                      values={{
                        amountOfErrors: attemptsTimes,
                      }}
                    />
                  </span>
                  {getTotalDurationOfAttempts()}
                </div>
              )}
            </div>
          </div>
        }
      />
      <RentalTimelineRowLayout
        timeline={isLast && <div className="bg-neutral-0 inset-0 absolute" />}
        content={
          <Alert variant="danger" className="ml-9 mt-1">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm">
                  <FormattedMessage
                    {...messages.checkOutAttemptDueTo}
                    values={{
                      error: (
                        <span className="font-semibold">
                          <FormattedMessage {...errorTypesMessage[type]} />:
                        </span>
                      ),
                    }}
                  />
                </p>
                <p className="text-sm text-neutral-1000">{detail}</p>
              </div>
              {isRentalActive && hasPermission('write:rentals') && (
                <Button
                  variant="danger"
                  emphasis="high"
                  size="sm"
                  onClick={prefillCloseRentalForm}
                >
                  <FormattedMessage {...messages.closeRental} />
                </Button>
              )}
            </div>
          </Alert>
        }
      />
    </div>
  );
};
