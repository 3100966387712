import { FormattedMessage } from 'react-intl';

import { ListTotal, ListTotalProps } from '~/common';

import messages from './messages';

export type UsersTotalProps = Omit<ListTotalProps, 'children'> & {
  total: number;
  keyboard: string;
};

export const UsersTotal = ({
  total,
  keyboard,
  loading,
  ...rest
}: UsersTotalProps) => (
  <ListTotal loading={loading} {...rest}>
    <FormattedMessage
      {...messages.users}
      values={{
        total,
        keyboard,
      }}
    />
  </ListTotal>
);
