import { FormattedMessage } from 'react-intl';
import { RouterNavLink } from '@cooltra/navigation';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import messages from '../messages';

export const Locations = () => {
  const { hasPermission } = useAuthClaimsQuery();

  if (!hasPermission('read:locations')) {
    return <></>;
  }

  return (
    <li>
      <RouterNavLink to="/locations">
        <FormattedMessage {...messages.locations} />
      </RouterNavLink>
    </li>
  );
};
