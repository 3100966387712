import { FormButton, FormHelpers } from '@cooltra/form';
import {
  usePricingDepositPricesMutation,
  usePricingDepositPricesQuery,
} from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { FormattedMessage, useIntl } from 'react-intl';
import { capitalize } from '@cooltra/utils';
import { useParams } from 'react-router-dom';
import { Spinner } from '@cooltra/ui';

import { ErrorPage, Four0Four, Four0Three } from '~/common';
import { useNotification } from '~/hooks';

import {
  DepositsFormValues,
  depositsInitialFormValues,
  DepositsPriceListDetailsForm,
  VehicleCategory,
} from './Form';
import {
  getFormValues,
  getUpdatePricingDepositPricesPayload,
} from './get-form-values';
import { DepositsPriceListDetailsBackLink } from './DepositsPriceListDetailsBackLink';
import messages from './messages';
import { DepositsPriceListDetailsFormFields } from './DepositsPriceListDetailsFormFields';

export const DepositsPriceListDetails = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();

  const { insuranceId = '' } = useParams<'insuranceId'>();
  const {
    data: insuranceDepositPrices,
    isLoading,
    isError,
    error,
  } = usePricingDepositPricesQuery(insuranceId, {
    enabled: hasPermission('read:prices'),
  });
  const { mutateAsync } = usePricingDepositPricesMutation(insuranceId);
  const { addSuccessNotification, addErrorNotification } = useNotification();

  if (!hasPermission('read:prices')) {
    return <Four0Three />;
  }

  if (isLoading) {
    return (
      <div className="w-full text-center mt-8">
        <Spinner size="lg" />
      </div>
    );
  }

  if (isError && getErrorStatus(error) === 404) {
    return <Four0Four />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (!insuranceDepositPrices) {
    return <Four0Four />;
  }

  const handleOnSubmit = (
    values: DepositsFormValues,
    { resetForm }: FormHelpers<DepositsFormValues>
  ) => {
    const payload = getUpdatePricingDepositPricesPayload(values);

    return mutateAsync(payload)
      .then(() => {
        resetForm(values);
        addSuccessNotification(formatMessage(messages.updateSuccessfully));
      })
      .catch(({ response }) => {
        switch (response.status) {
          case 400:
            addErrorNotification(formatMessage(messages.badFormattedFields));
            break;
          default:
            addErrorNotification();
            break;
        }
      });
  };

  const enabledVehicleCategories: VehicleCategory[] =
    insuranceDepositPrices.deposits.map(
      (deposit) => deposit.vehicleTypeCategory as VehicleCategory
    );

  return (
    <div className="container min-w-3xl max-w-4xl py-12 pb-32">
      <DepositsPriceListDetailsForm
        enabledVehicleCategories={enabledVehicleCategories}
        initialValues={{
          ...depositsInitialFormValues,
          ...getFormValues(insuranceDepositPrices),
        }}
        onSubmit={handleOnSubmit}
      >
        <>
          <DepositsPriceListDetailsBackLink />
          <div className="flex items-center justify-between">
            <h2 className="text-3xl">
              {capitalize(insuranceDepositPrices.insuranceName.toLowerCase())}
            </h2>
            {hasPermission('write:prices') && (
              <FormButton>
                <FormattedMessage {...messages.savePriceList} />
              </FormButton>
            )}
          </div>
          <DepositsPriceListDetailsFormFields
            enabledVehicleCategories={enabledVehicleCategories}
          />
        </>
      </DepositsPriceListDetailsForm>
    </div>
  );
};
