import { useCancelUninvoicedChargeMutation } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { useToggle } from '@cooltra/hooks';
import { Button } from '@cooltra/ui';
import { useQueryClient } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { ConfirmModal } from '~/common';
import { useNotification } from '~/hooks';

import messages from './messages';

export type CancelUninvoicedChargeProps = {
  id: string;
};

export const CancelUninvoicedCharge = ({ id }: CancelUninvoicedChargeProps) => {
  const queryClient = useQueryClient();
  const { id: userId = '' } = useParams();
  const { hasPermission } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();
  const { addErrorNotification } = useNotification();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const { isPending, mutateAsync } = useCancelUninvoicedChargeMutation(
    userId,
    id
  );

  const handleOnConfirm = () => {
    mutateAsync()
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['user-uninvoiced-charges', userId],
        });
        toggleOff();
      })
      .catch(() => addErrorNotification());
  };

  if (!hasPermission('write:invoices')) {
    return null;
  }

  return (
    <>
      <Button size="sm" onClick={toggleOn}>
        <FormattedMessage {...messages.cancel} />
      </Button>
      <ConfirmModal
        isOpen={isOpen}
        title={formatMessage(messages.cancelUninvoicedCharge)}
        content={formatMessage(messages.areYouSureToCancel)}
        onClose={toggleOff}
        onConfirm={handleOnConfirm}
        loading={isPending}
      />
    </>
  );
};
