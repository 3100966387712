import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    defaultMessage: 'Pass search',
  },
  placeholder: {
    defaultMessage: 'Code or title',
  },
});
