import { Card, Divider, Icon, Placeholder } from '@cooltra/ui';
import { useUserCreditBalanceQuery, useUserQuery } from '@cooltra/api';
import { RouterLink } from '@cooltra/navigation';
import { MdOutlineChevronRight } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Amount, NotAvailable, Pair } from '~/common';

import messages from './messages';

export const CreditBalance = () => {
  const { id = '' } = useParams<'id'>();

  const {
    data: creditBalance,
    isLoading,
    isError,
  } = useUserCreditBalanceQuery(id);

  const { data: user } = useUserQuery(id);

  const getCreditBalance = () => {
    if (isLoading) {
      return (
        <div>
          <Placeholder className="w-24 h-3 mt-6 mb-4" />
        </div>
      );
    }

    if (!creditBalance || !creditBalance.amount || isError) {
      return (
        <NotAvailable className="text-neutral-800 font-semibold text-3xl mb-4" />
      );
    }

    return (
      <Amount
        className="flex gap-2 text-3xl font-semibold"
        {...creditBalance.amount}
      />
    );
  };

  return (
    <Card className="p-6 flex flex-col h-full" data-testid="CREDIT_BALANCE">
      <div className="flex-1">
        <span className="block whitespace-nowrap text-neutral-400 text-xs mb-3">
          <RouterLink
            to="../billing/credits"
            className="flex justify-between text-xs mb-3"
          >
            <FormattedMessage {...messages.creditBalance} />
            <Icon className="pl-1 text-base">
              <MdOutlineChevronRight />
            </Icon>
          </RouterLink>
        </span>
        {getCreditBalance()}
      </div>
      <Divider />
      <div className="flex-1 flex flex-col justify-end">
        <Pair.Label className="block text-neutral-400 text-xs mt-4">
          <FormattedMessage {...messages.referralCode} />
        </Pair.Label>
        <Pair.Value>{user?.referralCode ? user.referralCode : '-'}</Pair.Value>
      </div>
    </Card>
  );
};
