import { Route, Routes } from 'react-router-dom';

import { Four0Four, PermissionCheck } from '~/common';

import { IncidentsList, IncidentsFiltersForm } from './IncidentsList';
import { CreateIncident } from './CreateIncident/CreateIncident';
import { Incident } from './Incident/Incident';

export const Incidents = () => (
  <Routes>
    <Route
      path="/"
      element={
        <IncidentsFiltersForm>
          <IncidentsList />
        </IncidentsFiltersForm>
      }
    />
    <Route
      path="new"
      element={
        <PermissionCheck permission="write:incidents">
          <CreateIncident />
        </PermissionCheck>
      }
    />
    <Route path="/:incidentId/*" element={<Incident />} />
    <Route path="*" element={<Four0Four />} />
  </Routes>
);
