import { defineMessages } from 'react-intl';

export default defineMessages({
  submitBtn: {
    defaultMessage: 'Save changes',
  },
  submissionSuccess: {
    defaultMessage: "You have successfully updated user's billing address!",
  },
  conflictErrorMessage: {
    defaultMessage:
      'The user details have been updated in the meantime. Please refresh the page.',
  },
});
