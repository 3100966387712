import { TimelineWorkUnitTaskClosed } from '@cooltra/api';
import { RouterLink } from '@cooltra/navigation';
import { TimelineItem } from '@cooltra/ui';
import { FormattedMessage, FormattedTime } from 'react-intl';

import messages from './messages';

export type WorkUnitTaskClosedProps = Pick<
  TimelineWorkUnitTaskClosed,
  'createdAt' | 'title' | 'taskId'
> & {
  isLast: boolean;
};

export const WorkUnitTaskClosed = ({
  createdAt,
  title,
  taskId,
  isLast,
}: WorkUnitTaskClosedProps) => (
  <TimelineItem
    timelineClassName="translate-y-1.5"
    isLast={isLast}
    header={
      <div className="flex gap-2 text-sm mb-4">
        <span className="text-neutral-400">
          <FormattedTime
            value={createdAt}
            hour="2-digit"
            hourCycle="h23"
            minute="2-digit"
          />
        </span>
        <span className="text-neutral-800">
          <FormattedMessage
            {...messages.closeTask}
            values={{
              title: <RouterLink to={`/tasks/${taskId}`}>{title}</RouterLink>,
            }}
          />
        </span>
      </div>
    }
  />
);
