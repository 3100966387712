import { defineMessages } from 'react-intl';

export default defineMessages({
  assign: {
    defaultMessage: 'Assign',
  },
  theLicensePlateHasBeenAssigned: {
    defaultMessage: 'The license plate has been assigned',
  },
});
