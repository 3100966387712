import { FormattedMessage } from 'react-intl';
import { useFormContext } from '@cooltra/form';

import { ContractDetailsFormValues } from '../../contract-details-form';

import messages from './messages';

export const ClearDates = () => {
  const { values, setValues } = useFormContext<ContractDetailsFormValues>();

  const handleClearingDates = () =>
    setValues({
      dropOffDate: undefined,
      pickUpDate: undefined,
      pickUpTime: '',
      dropOffTime: '',
    });

  if (values.contractStatus === 'ACTIVE') {
    return null;
  }

  return (
    <button
      type="button"
      className="link-primary focus:underline hover:underline text-sm"
      onClick={handleClearingDates}
    >
      <FormattedMessage {...messages.clearDates} />
    </button>
  );
};
