import { DateOfBirth, DateOfBirthStatic } from './DateOfBirth';
import { Email, EmailStatic } from './Email';
import { FirstName, FirstNameStatic } from './FirstName';
import { System, SystemStatic } from './System';
import { Language, LanguageStatic } from './Language';
import { LastName, LastNameStatic } from './LastName';
import { MobilePhone, MobilePhoneStatic } from './MobilePhone';

export const UserFields = {
  DateOfBirth,
  DateOfBirthStatic,
  Email,
  EmailStatic,
  FirstName,
  FirstNameStatic,
  System,
  SystemStatic,
  Language,
  LanguageStatic,
  LastName,
  LastNameStatic,
  MobilePhone,
  MobilePhoneStatic,
};
