import { CurrentActivityReservation } from '@cooltra/api';
import { Card } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import {
  MultilineDateTime,
  FailedCheckIn,
  VehicleLink,
  ReservationStateBadge,
  ReservationProblemReportsBadge,
  ListColumnLabel,
} from '~/common';

import { CurrentReservationLayout } from './CurrentReservationLayout';
import messages from './messages';

export type CurrentReservationProps = {
  reservation: CurrentActivityReservation;
  userId: string;
};

export const CurrentReservation = ({
  reservation: {
    vehicle,
    createdAt,
    expiresAt,
    lastCheckinFailure,
    system,
    state,
    problemReportIds,
  },
  userId,
}: CurrentReservationProps) => (
  <Card data-testid="CURRENT_ACTIVITY_CARD" className="py-6 relative">
    <CurrentReservationLayout
      timeline={
        <div className="flex gap-4">
          <div>
            <ListColumnLabel className="mb-2">
              <FormattedMessage {...messages.createdAt} />
            </ListColumnLabel>
            <MultilineDateTime date={createdAt} />
          </div>
          <div className="w-6 mt-10 border-t border-neutral-200" />
          <div>
            <ListColumnLabel className="mb-2">
              <FormattedMessage {...messages.expiresAt} />
            </ListColumnLabel>
            <MultilineDateTime date={expiresAt} />
          </div>
        </div>
      }
      vehicle={
        <div>
          <ListColumnLabel className="mb-1.5">
            <FormattedMessage {...messages.vehicle} />
          </ListColumnLabel>
          <VehicleLink vehicle={vehicle} />
        </div>
      }
      system={
        <div>
          <ListColumnLabel className="mb-2">
            <FormattedMessage {...messages.system} />
          </ListColumnLabel>
          <span className="block text-neutral-800 text-sm font-medium">
            {system}
          </span>
        </div>
      }
      checkIn={
        <div>
          <ListColumnLabel className="mb-2">
            <FormattedMessage {...messages.checkIn} />
          </ListColumnLabel>
          {lastCheckinFailure ? (
            <FailedCheckIn error={lastCheckinFailure} />
          ) : (
            <span />
          )}
        </div>
      }
      problemReports={
        <div>
          <ListColumnLabel className="mb-1.5">
            <FormattedMessage {...messages.problemReports} />
          </ListColumnLabel>
          <ReservationProblemReportsBadge
            problemReportIds={problemReportIds}
            userId={userId}
          />
        </div>
      }
      state={
        <div>
          <ListColumnLabel className="mb-2">
            <FormattedMessage {...messages.state} />
          </ListColumnLabel>
          <ReservationStateBadge state={state} />
        </div>
      }
    />
  </Card>
);
