import { RentalPassListItem } from '@cooltra/api';
import { RouterCardLink } from '@cooltra/navigation';

import { Amount, MultilineDateTime } from '~/common';

import { PassState } from '../../PassState/PassState';
import { PassRowLayout } from '../PassRowLayout';

export type PassCardProps = {
  pass: RentalPassListItem;
};

export const PassCard = ({
  pass: { passId, code, price, validFrom, validUntil, state, title },
}: PassCardProps) => (
  <RouterCardLink
    to={`/offers/passes/${passId}/edit`}
    className={state === 'EXPIRED' ? 'opacity-50' : 'opacity-100'}
  >
    <PassRowLayout
      className="min-h-24 py-4"
      code={
        <div>
          <span className="block text-neutral-1000 font-semibold">{code}</span>
          <span className="text-sm text-neutral-600">{title}</span>
        </div>
      }
      price={<Amount {...price} />}
      validFrom={
        validFrom && (
          <MultilineDateTime emphasize="date" date={new Date(validFrom)} />
        )
      }
      validUntil={
        validUntil && (
          <MultilineDateTime emphasize="date" date={new Date(validUntil)} />
        )
      }
      state={<PassState state={state} />}
    />
  </RouterCardLink>
);
