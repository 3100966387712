import { Claims, System } from '@cooltra/auth-api';
import { InvoiceLegalEntity } from '@cooltra/api';

const systemLegalEntities: { [key in System]: InvoiceLegalEntity } = {
  Barcelona: 'COOLTRA_SPAIN',
  Berlin: 'COOLTRA_GERMANY',
  Munich: 'COOLTRA_GERMANY',
  Lisbon: 'COOLTRA_PORTUGAL',
  Madrid: 'COOLTRA_SPAIN',
  Malaga: 'COOLTRA_SPAIN',
  Mallorca: 'COOLTRA_SPAIN',
  Seville: 'COOLTRA_SPAIN',
  Valencia: 'COOLTRA_SPAIN',
  Milan: 'COOLTRA_ITALY',
  Rome: 'COOLTRA_ITALY',
  Turin: 'COOLTRA_ITALY',
  Paris: 'COOLTRA_FRANCE',
  Zurich: 'COOLTRA_SWITZERLAND',
  Amsterdam: 'FELYX_NETHERLANDS',
  Breda: 'FELYX_NETHERLANDS',
  Brussels: 'FELYX_BELGIUM',
  Antwerp: 'FELYX_BELGIUM',
  Arnhem: 'FELYX_NETHERLANDS',
  Den_bosch: 'FELYX_NETHERLANDS',
  The_hague: 'FELYX_NETHERLANDS',
  Enschede: 'FELYX_NETHERLANDS',
  Groningen: 'FELYX_NETHERLANDS',
  Haarlem: 'FELYX_NETHERLANDS',
  Nijmegen: 'FELYX_NETHERLANDS',
  Rotterdam: 'FELYX_NETHERLANDS',
  Tilburg: 'FELYX_NETHERLANDS',
  Zwolle: 'FELYX_NETHERLANDS',
  Hilversum: 'FELYX_NETHERLANDS',
};

export const getSystemLegalEntity = (system: System) =>
  systemLegalEntities[system];

export const getInnerJoinBetweenSystemsAndClaimsSystems = (
  systems: System[] | undefined,
  claims: Claims | undefined
): System[] => {
  if (!systems || !claims) {
    return [];
  }

  if (systems.length === 0) {
    return claims.systems;
  }

  return claims.systems.filter((claimSystem) => systems.includes(claimSystem));
};
