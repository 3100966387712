import { PackListItem } from '@cooltra/api';
import { RouterCardLink } from '@cooltra/navigation';

import { Amount, MultilineDateTime } from '~/common';

import { PackRowLayout } from '../PackRowLayout';
import { PackState } from '../../PackState/PackState';

export type PackCardProps = {
  pack: PackListItem;
};

export const PackCard = ({
  pack: { packId, code, price, credit, validFrom, validUntil, state, title },
}: PackCardProps) => (
  <RouterCardLink
    to={`/offers/packs/${packId}/edit`}
    className={state === 'EXPIRED' ? 'opacity-50' : 'opacity-100'}
  >
    <PackRowLayout
      className="min-h-24 py-4"
      code={
        <div>
          <span className="block text-neutral-1000 font-semibold">{code}</span>
          <span className="text-sm text-neutral-600">{title}</span>
        </div>
      }
      price={<Amount {...price} />}
      credit={<Amount {...credit} />}
      validFrom={
        validFrom && (
          <MultilineDateTime emphasize="date" date={new Date(validFrom)} />
        )
      }
      validUntil={
        validUntil && (
          <MultilineDateTime emphasize="date" date={new Date(validUntil)} />
        )
      }
      state={<PackState state={state} />}
    />
  </RouterCardLink>
);
