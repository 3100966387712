import { monthAgo } from '~/utils/date';

export type VehicleRentalsFiltersFormValues = {
  minDate: string;
  maxDate: string;
};

export const vehicleRentalsFiltersInitialValues: VehicleRentalsFiltersFormValues =
  {
    minDate: monthAgo,
    maxDate: '',
  };
