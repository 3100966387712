import { useVehicleTasksQuery } from '@cooltra/api';
import { Placeholder } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { OpenTasksCount } from '../OpenTasksCount/OpenTasksCount';

import messages from './messages';

export type OpenTaskListItemProps = {
  vehicleId: string;
};

export const OpenTasksListItem = ({ vehicleId }: OpenTaskListItemProps) => {
  const {
    data: tasks = [],
    isLoading,
    isError,
  } = useVehicleTasksQuery(
    vehicleId,
    { open: 'true' },
    { enabled: !!vehicleId }
  );

  const blockingTasks = tasks.filter(({ blocking }) => blocking);

  return (
    <li className="py-4 border-b border-solid border-neutral-100">
      <div className="flex justify-between items-center gap-4">
        <span className="text-sm text-neutral-400">
          <FormattedMessage {...messages.openTasks} />
        </span>
        <span className="text-sm text-neutral-500 pl-2 truncate">
          {isError ? (
            <></>
          ) : isLoading ? (
            <Placeholder className="w-32 h-3" />
          ) : (
            <OpenTasksCount
              totalTasks={tasks.length}
              totalBlockingTasks={blockingTasks.length}
            />
          )}
        </span>
      </div>
    </li>
  );
};
