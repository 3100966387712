import { FormattedMessage, useIntl } from 'react-intl';
import { Card } from '@cooltra/ui';
import { Form, FormHelpers, FormProvider } from '@cooltra/form';
import {
  Contract,
  usePatchContractChecksOutMutation,
} from '@cooltra/station-based-api';
import { MdInfoOutline, MdOpenInNew } from 'react-icons/md';
import { RouterLink } from '@cooltra/navigation';

import { useNotification } from '~/hooks';
import {
  FormQRCode,
  FormSaveButton,
  UnsavedChangesPrompt,
  ChecksFields,
} from '~/common';

import {
  ChecksFormValues,
  initialValues,
  validateChecksOutForm,
} from './checks-form';
import messages from './messages';

const MAX_FUEL_LEVEL = 8;

export type ChecksOutProps = {
  contract: Contract;
  disabled: boolean;
};

export const ChecksOut = ({ contract, disabled }: ChecksOutProps) => {
  const intl = useIntl();

  const { checks, accessories, contractId, vehicle } = contract;
  const { licensePlate, vehicleId } = vehicle?.assignedVehicle || {};

  const { mutateAsync, isSuccess } =
    usePatchContractChecksOutMutation(contractId);
  const { addErrorNotification } = useNotification();
  const currentAccessories = checks.accessories ?? [];
  const deliveredAccessories = currentAccessories?.filter(
    (accessory) => accessory.delivered
  );

  const getInitialFormValues = () => {
    if (checks) {
      const currentSelectedReturnedAccessories: Record<string, boolean> = {};

      deliveredAccessories.forEach((accessory, index) => {
        currentSelectedReturnedAccessories[String(index)] = accessory.returned;
      });

      return {
        ...initialValues,
        mileage: Number(checks.out?.mileage) || '',
        fuelLevel: checks.out?.fuelLevel || 0,
        accessories: currentSelectedReturnedAccessories,
      };
    }

    return initialValues;
  };

  const handleOnSubmit = (
    values: ChecksFormValues,
    { resetForm }: FormHelpers<ChecksFormValues>
  ) => {
    const selectedIndexes = Object.keys(values.accessories).filter(
      (index) => values.accessories[index]
    );
    const selectedDeliveredAccessories = selectedIndexes.map(
      (index) => checks?.accessories[Number(index)].accessoryId
    );

    return mutateAsync({
      fuelLevel: values.fuelLevel,
      mileage: Number(values.mileage),
      returnedAccessories: selectedDeliveredAccessories,
    })
      .then(() => resetForm(values))
      .catch(() => addErrorNotification());
  };

  return (
    <FormProvider
      onSubmit={handleOnSubmit}
      initialValues={getInitialFormValues()}
      validate={validateChecksOutForm(intl, contract)}
      disabled={disabled}
    >
      <UnsavedChangesPrompt />
      <Form>
        <div className="mx-auto max-w-4xl pt-4 pb-10">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-xl text-neutral-700 ">
              <FormattedMessage {...messages.checksOut} />
            </h1>
            <RouterLink
              className="inline-flex gap-1 items-center text-s font-semibold"
              to={`/pos/vehicles/${vehicleId}`}
            >
              <FormattedMessage
                {...messages.openDetails}
                values={{
                  licensePlate,
                }}
              />
              <MdOpenInNew />
            </RouterLink>
          </div>
          <Card className="p-8">
            <div className="grid grid-cols-2 gap-x-8 gap-y-5 mb-6">
              <ChecksFields.Mileage />
              {vehicle?.category !== 'EBIKE' && (
                <>
                  <div data-testid="FUEL_LEVEL">
                    <ChecksFields.LevelSelector
                      levels={MAX_FUEL_LEVEL}
                      name="fuelLevel"
                    />
                    {checks.out &&
                      checks.in &&
                      checks.out.fuelLevel < checks.in.fuelLevel && (
                        <p className="flex items-center gap-1 mt-1 font-regular text-sm text-warning-600">
                          <MdInfoOutline />
                          <FormattedMessage {...messages.fuelPenalty} />
                        </p>
                      )}
                  </div>
                  <div
                    className="flex flex-col gap-2"
                    data-testid="LAST_REGISTRY_MILEAGE"
                  >
                    <p className="font-medium text-xs text-neutral-500">
                      <FormattedMessage {...messages.lastRegistry} />
                    </p>
                    <span className="font-medium text-sm text-neutral-800">
                      <FormattedMessage
                        {...messages.lastRegistryMileage}
                        values={{
                          mileage: checks.in?.mileage || 0,
                        }}
                      />
                    </span>
                  </div>
                  <div
                    className="flex flex-col gap-2"
                    data-testid="LAST_REGISTRY_FUEL_LEVEL"
                  >
                    <p className="font-medium text-xs text-neutral-500">
                      <FormattedMessage {...messages.lastRegistry} />
                    </p>
                    <ul className="flex gap-1">
                      <ChecksFields.Levels
                        id="fuel-level-read-only"
                        value={checks.in?.fuelLevel || 0}
                        levels={MAX_FUEL_LEVEL}
                        buttonClassNames="h-6 w-3 p-0 rounded-sm"
                        buttonGreaterThanValueClassNames="bg-neutral-300"
                        buttonLessThanValueClassNames="bg-neutral-100"
                        readonly
                      />
                    </ul>
                  </div>
                </>
              )}
            </div>
            {accessories && (
              <ChecksFields.Accessories accessories={deliveredAccessories} />
            )}
          </Card>
        </div>
        <FormSaveButton
          className="btn-shadow fixed bottom-8 right-28 -mr-2 z-10"
          isSaved={isSuccess}
        />
      </Form>
      <FormQRCode />
    </FormProvider>
  );
};
