import { InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSelectField } from '@cooltra/form';

import messages from './messages';

export const BonusEnabled = () => {
  const { formatMessage } = useIntl();

  return (
    <FormSelectField
      id="bonus-enabled"
      label={
        <InputLabel htmlFor="bonus-enabled">
          <FormattedMessage {...messages.label} />
        </InputLabel>
      }
      isClearable
      name="bonusEnabled"
      placeholder={formatMessage(messages.placeholder)}
      options={[
        {
          label: formatMessage(messages.enabled),
          value: 'true',
        },
        {
          label: formatMessage(messages.disabled),
          value: 'false',
        },
      ]}
    />
  );
};
