import { FormattedMessage } from 'react-intl';
import { FormTextareaField } from '@cooltra/form';

import { OptionalFieldLabel } from '../../OptionalFieldLabel/OptionalFieldLabel';

import messages from './messages';

export type DescriptionProps = {
  disabled?: boolean;
};

export const Description = ({ disabled = false }: DescriptionProps) => (
  <FormTextareaField
    name="description"
    id="task-description"
    rows={6}
    disabled={disabled}
    label={
      <OptionalFieldLabel htmlFor="task-description">
        <FormattedMessage {...messages.description} />
      </OptionalFieldLabel>
    }
  />
);
