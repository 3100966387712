import { HTMLAttributes } from 'react';
import { classNames } from '@cooltra/utils';

export type SidebarHeadingProps = HTMLAttributes<HTMLHeadingElement>;

export const SidebarHeading = ({ className, ...rest }: SidebarHeadingProps) => (
  <h4
    className={classNames(
      'text-3xl',
      'font-semibold',
      'text-neutral-800',
      'mb-4',
      className
    )}
    {...rest}
  />
);
