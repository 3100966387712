import { FormattedMessage } from 'react-intl';

import * as Filters from '../Filters';

import messages from './messages';

export type FilterToolbarProps = {
  vehiclesParkedTotal: number;
};

export const FilterToolbar = ({ vehiclesParkedTotal }: FilterToolbarProps) => (
  <nav className="w-full flex items-center justify-between">
    <div className="flex items-center gap-4 relative">
      <Filters.SystemField className="w-60" />
      <Filters.IdleFor />
      <Filters.SidewalkParked />
    </div>
    <span className="font-semibol text-neutral-600">
      <FormattedMessage
        {...messages.vehicles}
        values={{ vehiclesParkedTotal }}
      />
    </span>
  </nav>
);
