import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { FormProvider } from '@cooltra/form';
import { getParams } from '@cooltra/utils';

import { usePreferredSystem } from '~/libs/preferred-system';

import { ParkedVehiclesReviewFormValues } from './parked-vehicles-review-form-values';

export const ParkedVehiclesReviewForm = ({ children }: PropsWithChildren) => {
  const { search } = useLocation();
  const { system } = usePreferredSystem();
  const params = getParams<keyof ParkedVehiclesReviewFormValues>(search);

  const initialValues = {
    idleFor: params.string('idleFor') || '',
    sidewalkParked: params.string('sidewalkParked') || '',
    system: params.string('system') || system,
  } as ParkedVehiclesReviewFormValues;

  return <FormProvider initialValues={initialValues}>{children}</FormProvider>;
};
