import { createContext } from 'react';

export type PreferredServicePointsContextShape = {
  updatePreferredServicePoints: (servicePoints: string[]) => void;
  preferredServicePoints: string[];
};

export const PreferredServicePointsContext = createContext<
  PreferredServicePointsContextShape | undefined
>(undefined);
