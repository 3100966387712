import { IntlShape } from 'react-intl';
import { User } from '@cooltra/api';

import {
  validateRequired,
  validateDate,
  validateEmail,
  validateName,
  validateCity,
  validatePhone,
  createSetError,
} from '~/validation';

export type PersonalDetailsFormValues = {
  firstName: string;
  lastName: string;
  system: string;
  language: string;
  dateOfBirth: string;
  email: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  phone: string;
};

type PersonalDetailsFormFieldName = keyof PersonalDetailsFormValues;

type PersonalDetailsFormErrors = Partial<
  Record<PersonalDetailsFormFieldName, string | undefined>
>;

const personalDetailsFormInitialValues: PersonalDetailsFormValues = {
  firstName: '',
  lastName: '',
  system: '',
  language: '',
  dateOfBirth: '',
  email: '',
  street: '',
  zip: '',
  city: '',
  country: '',
  phone: '',
};

export const getPersonalDetailsInitialValues = ({
  firstName,
  lastName,
  system,
  language,
  dateOfBirth,
  email,
  phone,
  address: { street, zip, city, country },
}: User): PersonalDetailsFormValues => ({
  ...personalDetailsFormInitialValues,
  firstName,
  lastName,
  system,
  language,
  dateOfBirth,
  email,
  phone,
  street,
  zip,
  city,
  country,
});

export const getPersonalDetailsPayload = (
  {
    firstName,
    lastName,
    system,
    language,
    dateOfBirth,
    email,
    phone,
    street,
    zip,
    city,
    country,
  }: PersonalDetailsFormValues,
  version: number
) => ({
  firstName,
  lastName,
  system,
  language,
  dateOfBirth,
  email,
  phone,
  address: { street, zip, city, country },
  version,
});

const requiredFields: PersonalDetailsFormFieldName[] = [
  'firstName',
  'lastName',
  'system',
  'language',
  'dateOfBirth',
  'email',
  'street',
  'zip',
  'city',
  'country',
  'phone',
];

const nameFields: PersonalDetailsFormFieldName[] = ['firstName', 'lastName'];

export const validatePersonalDetailsForm =
  (intl: IntlShape) =>
  (values: PersonalDetailsFormValues): PersonalDetailsFormErrors => {
    const errors: PersonalDetailsFormErrors = {};
    const setError = createSetError(errors);

    setError('dateOfBirth', validateDate(intl, values.dateOfBirth));
    setError('city', validateCity(intl, values.city));
    setError('email', validateEmail(intl, values.email));
    setError('phone', validatePhone(intl, values.phone));
    nameFields.forEach((fieldName) => {
      setError(fieldName, validateName(intl, values[fieldName]));
    });
    requiredFields.forEach((fieldName) => {
      setError(fieldName, validateRequired(intl, values[fieldName]));
    });
    return errors;
  };
