import { UserSuspension } from '@cooltra/api';
import { classNames } from '@cooltra/utils';
import { Card } from '@cooltra/ui';

import { DateTime } from '../../DateTime';
import { ByOperator } from '../../Operator';
import { SuspensionRowLayout } from '../SuspensionRowLayout/SuspensionRowLayout';
import { SuspensionBadge } from '../SuspensionBadge/SuspensionBadge';
import { ClearSuspensionModal } from '../ClearSuspensionModal/ClearSuspensionModal';

export type SuspensionCardProps = {
  userId: string;
  isUserDeleted: boolean;
  suspension: UserSuspension;
};

export const SuspensionCard = ({
  userId,
  isUserDeleted,
  suspension: { suspensionId, type, comment, clearedAt, createdAt, createdBy },
}: SuspensionCardProps) => (
  <Card className={classNames(clearedAt ? 'opacity-50' : 'opacity-100')}>
    <SuspensionRowLayout
      className="py-6"
      type={<SuspensionBadge type={type} />}
      reason={<p className="text-neutral-1000 mt-1 text-sm">{comment}</p>}
      created={
        <div>
          <DateTime date={createdAt} />
          <ByOperator id={createdBy} />
        </div>
      }
      clear={
        !isUserDeleted && (
          <ClearSuspensionModal userId={userId} suspensionId={suspensionId} />
        )
      }
    />
  </Card>
);
