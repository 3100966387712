import { defineMessages } from 'react-intl';

export default defineMessages({
  ideal: {
    defaultMessage: 'iDeal',
  },
  bancontact: {
    defaultMessage: 'Bancontact',
  },

  unknown: {
    defaultMessage: 'Unknown payment method',
  },
});
