import { defineMessages } from 'react-intl';

export default defineMessages({
  totalPrice: {
    defaultMessage: 'Total price',
  },
  startingPrice: {
    defaultMessage: 'Starting fee',
  },
  bonusZone: {
    defaultMessage: 'Bonus zone',
  },
  discountPromotion: {
    defaultMessage: 'Discount promotion',
  },
  credit: {
    defaultMessage: 'Credit',
  },
  total: {
    defaultMessage: 'Total',
  },
  rentalPassDiscount: {
    defaultMessage: 'Pass',
  },
  vehicleBonusDiscount: {
    defaultMessage: 'Vehicle bonus',
  },
  errorOnRentalEstimationPrice: {
    defaultMessage:
      'The estimation of the rental price could not be made. Try again later.',
  },
});
