import { FormattedMessage, useIntl } from 'react-intl';
import { InputLabel } from '@cooltra/ui';
import { FormSelectField, useFormContext } from '@cooltra/form';

import {
  FormAmountInputField,
  FormNumberInputField,
} from '../../../../FormattedInput';
import { PromotionFormValues } from '../../PromotionForm';
import { PromotionStateType } from '../../PromotionState/PromotionState';

import messages from './messages';

export const Discount = () => {
  const { formatMessage } = useIntl();

  const { values } = useFormContext<PromotionFormValues>();

  const { promotionTypeMode, editMode, stateType, capType } = values;
  const editingNoSchedulePromotion =
    editMode && stateType !== PromotionStateType.SCHEDULED;

  if (promotionTypeMode !== 'discount') {
    return <></>;
  }

  return (
    <>
      <FormNumberInputField
        name="discount"
        id="discount-percentage"
        className="w-44"
        disabled={editingNoSchedulePromotion}
        placeholder="10%"
        label={
          <InputLabel htmlFor="discount-percentage">
            <FormattedMessage {...messages.percentage} />
          </InputLabel>
        }
        maxLength={3}
      />
      <div className="flex gap-6">
        <FormSelectField
          id="cap-type"
          label={
            <InputLabel htmlFor="cap-type">
              <FormattedMessage {...messages.capType} />
            </InputLabel>
          }
          disabled={editingNoSchedulePromotion}
          className="w-40"
          name="capType"
          options={[
            {
              label: formatMessage(messages.total),
              value: 'total',
            },
            {
              label: formatMessage(messages.rentals),
              value: 'rentals',
            },
          ]}
        />
        {capType === 'total' && (
          <FormAmountInputField
            name="discountCap.value"
            id="discount-cap"
            className="w-44"
            disabled={editingNoSchedulePromotion}
            placeholder="50 €"
            label={
              <InputLabel htmlFor="discount-cap">
                <FormattedMessage {...messages.discountCap} />
              </InputLabel>
            }
          />
        )}
        {capType === 'rentals' && (
          <>
            <FormNumberInputField
              name="rentalsCap"
              id="rentals-cap"
              className="w-44"
              disabled={editingNoSchedulePromotion}
              placeholder="10"
              label={
                <InputLabel htmlFor="rentals-cap">
                  <FormattedMessage {...messages.rentalsCap} />
                </InputLabel>
              }
              maxLength={2}
            />
            <FormAmountInputField
              name="discountCapPerRental.value"
              id="dicount-cap-per-rental"
              disabled={editingNoSchedulePromotion}
              placeholder="5 €"
              label={
                <InputLabel htmlFor="dicount-cap-per-rental">
                  <FormattedMessage {...messages.discountCapPerRental} />
                </InputLabel>
              }
            />
          </>
        )}
      </div>
    </>
  );
};
