import { ServiceFields } from '~/common';

export const CreateTechnicalInspectionForm = () => (
  <div
    data-testid="CREATE_TECHNICAL_INSPECTION"
    className="flex flex-col gap-y-6 gap-x-8"
  >
    <div className="w-1/2">
      <ServiceFields.ScheduledAt optional={false} />
    </div>
  </div>
);
