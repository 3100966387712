import { FormattedMessage, useIntl } from 'react-intl';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Card, CopyButton, Icon } from '@cooltra/ui';
import { Link } from 'react-router-dom';
import { MdChevronRight, MdClose } from 'react-icons/md';

import {
  Sync,
  Lock,
  Unlock,
  ModelImage,
  OperationalConditionBadge,
  OperationalStateBadge,
} from '~/common';
import { useSelectVehicle } from '~/libs/select-vehicle';
import { useVehicleQuery } from '~/hooks';

import { VehicleBonusListItem } from './VehicleBonusListItem/VehicleBonusListItem';
import { BatteryListItem } from './BatteryListItem/BatteryListItem';
import { TelematicsListItem } from './TelematicsListItem/TelematicsListItem';
import { TakenByListItem } from './TakenByListItem/TakenByListItem';
import { LocationListItem } from './LocationListItem/LocationListItem';
import { OpenTasksListItem } from './OpenTasksListItem';
import { LastSyncListItem } from './LastSyncListItem/LastSyncListItem';
import messages from './messages';

export const PreviewVehicleCard = () => {
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthClaimsQuery();
  const { vehicleId, deselectVehicle } = useSelectVehicle();
  const { data: vehicle, isLoading } = useVehicleQuery(String(vehicleId), {
    enabled: Boolean(vehicleId),
  });

  if (isLoading || !vehicle || !vehicleId) {
    return <></>;
  }

  const userCanSeeTelematicsActionsButtons = hasPermission('action:vehicles');

  const modelSuffix = () => {
    if (vehicle.model === 'NIU_N1S') return ' | 45 km/h';
    else if (vehicle.model === 'NIU_N1S_25') return ' | 25 km/h';
    else return '';
  };

  return (
    <div className="absolute left-6 top-40 z-10">
      <Card className="w-96 p-8 pt-12 relative">
        <div className="w-full flex justify-end absolute right-4 top-4">
          <button className="tap-highlight" onClick={deselectVehicle}>
            <Icon
              aria-label={formatMessage(messages.close)}
              className="text-neutral-400 text-xl"
            >
              <MdClose />
            </Icon>
          </button>
        </div>
        <div className="flex flex-col items-center">
          <div className="flex justify-center items-center gap-2">
            <Link to={`/vehicles/${vehicle.vehicleId}`} target="_blank">
              <ModelImage
                className="w-24"
                model={vehicle.model}
                identificationNumber={vehicle.identificationNumber}
              />
            </Link>
            <div className="flex flex-col">
              <span className="text-lg text-neutral-800 font-semibold">
                {vehicle.externalId}
                <CopyButton
                  value={vehicle.externalId}
                  className="ml-2 relative top-0.5"
                />
              </span>
              <span className="text-md text-neutral-800">
                {vehicle.licensePlate}
                {modelSuffix()}
              </span>
            </div>
          </div>
          <div className="flex flex-wrap justify-center items-center gap-2 mt-4 mb-6">
            <OperationalStateBadge
              operationalState={vehicle.operationalState}
              className="text-xs"
            />
            {vehicle.operationalConditions
              .filter(
                (operationalCondition) => operationalCondition != 'OPERATIONAL'
              )
              .map((operationalCondition) => (
                <OperationalConditionBadge
                  key={operationalCondition}
                  className="text-xs"
                  operationalCondition={operationalCondition}
                />
              ))}
          </div>
        </div>
        <ul>
          <BatteryListItem vehicleId={vehicleId} model={vehicle.model} />
          {vehicle.takenBy ? (
            <TakenByListItem vehicleId={vehicleId} />
          ) : (
            <LocationListItem vehicleId={vehicleId} />
          )}
          <TelematicsListItem vehicleId={vehicleId} />
          <LastSyncListItem vehicleId={vehicleId} />
          <OpenTasksListItem vehicleId={vehicleId} />
          <VehicleBonusListItem vehicleId={vehicleId} />
        </ul>
        {userCanSeeTelematicsActionsButtons && (
          <div className="w-full grid grid-cols-3 my-6 gap-2">
            <Sync vehicleId={vehicleId} isDropdown={false} />
            <Lock vehicleId={vehicleId} showIcon={false} isDropdown={false} />
            <Unlock vehicleId={vehicleId} showIcon={false} isDropdown={false} />
          </div>
        )}
        <Link
          className="w-full text-sm text-primary-700 text-center"
          to={`/vehicles/${vehicle.vehicleId}`}
          target="_blank"
        >
          <FormattedMessage {...messages.goToVehicle} />
          <MdChevronRight className="text-lg" />
        </Link>
      </Card>
    </div>
  );
};
