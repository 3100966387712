import { stringifyParams } from '@cooltra/utils';
import axios from 'axios';

import { getEnvVariable } from '~/utils/environment';

type GeocodeParams = {
  searchText: string;
};

type GeocodeResponse = {
  features: {
    center: [number, number];
  }[];
};

export const geocode = ({ searchText }: GeocodeParams) => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
    searchText
  )}.json?${stringifyParams({
    access_token: getEnvVariable('MAPBOX_ACCESS_TOKEN'),
    limit: 1,
  })}`;
  return axios
    .get<GeocodeResponse | undefined>(url)
    .then(({ data }) => data?.features[0]?.center);
};
