import { defineMessages } from 'react-intl';

export default defineMessages({
  today: {
    defaultMessage: 'Today',
  },
  rental: {
    defaultMessage: 'Rental',
  },
  pickUp: {
    defaultMessage: 'Pickup',
  },
  dropOff: {
    defaultMessage: 'Dropoff',
  },
  duration: {
    defaultMessage: 'Duration',
  },
  vehicle: {
    defaultMessage: 'Vehicle',
  },
  system: {
    defaultMessage: 'System',
  },
  incidents: {
    defaultMessage: 'Incidents',
  },
});
