import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Application crashed',
  },
  content: {
    defaultMessage:
      'Try refreshing the page and if the problem persists, please contact the Zeus team',
  },
});
