import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { FormDateInputField } from '~/common';

import messages from './messages';

export const ServicedAt = () => {
  return (
    <div data-testid="SERVICED_AT">
      <FormDateInputField
        name="servicedAt"
        id="servicedAt"
        className="w-full"
        label={
          <InputLabel htmlFor="servicedAt">
            <FormattedMessage {...messages.servicedAt} />
          </InputLabel>
        }
      />
    </div>
  );
};
