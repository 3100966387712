import { defineMessages } from 'react-intl';

export default defineMessages({
  season: {
    defaultMessage: 'Season',
  },
  appliesIn: {
    defaultMessage: 'Applies in',
  },
  eBike: {
    defaultMessage: 'eBike',
  },
  standard: {
    defaultMessage: 'Standard',
  },
  plus: {
    defaultMessage: 'Plus',
  },
  premium: {
    defaultMessage: 'Premium',
  },
  superPremium: {
    defaultMessage: 'Super premium',
  },
});
