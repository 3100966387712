import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Welcome to Zeus',
  },
  intro: {
    defaultMessage:
      'Zeus is a platform to manage users, vehicles, rentals and much more.',
  },
  pricing: {
    defaultMessage: 'Pricing',
  },
  subscriptionCharges: {
    defaultMessage: 'See subscription charges',
  },
  parkedVehicles: {
    defaultMessage: 'Parked vehicles',
  },
  startParkingTinder: {
    defaultMessage: 'Start reviewing',
  },
});
