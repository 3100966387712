import { defineMessages } from 'react-intl';

export default defineMessages({
  serviceLink: {
    defaultMessage: 'Service ID',
  },
  scheduledMileage: {
    defaultMessage: 'Scheduled mileage',
  },
  scheduledAt: {
    defaultMessage: 'Scheduled at',
  },
  serviced: {
    defaultMessage: 'Serviced',
  },
  state: {
    defaultMessage: 'State',
  },
});
