import { useContext } from 'react';
import { getErrorStatus } from '@cooltra/axios';
import {
  UpdatePricingAccessoryPricesPayload,
  usePricingAccessoryPricesQuery,
  usePricingAccessoryPricesMutation,
} from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Spinner } from '@cooltra/ui';
import { useParams } from 'react-router-dom';
import { FormButton, FormHelpers } from '@cooltra/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { capitalize } from '@cooltra/utils';

import { ErrorPage, Four0Four, Four0Three } from '~/common';
import { useNotification } from '~/hooks';
import { PriceListTypeContext } from '~/components/PriceLists/PriceListTypeContext';

import messages from './messages';
import {
  AccessoryPriceFormValues,
  accessoryPriceFormInitialValues,
} from './Form/form-details';
import { AccessoryPriceListDetailsBackLink } from './AccessoryPriceListDetailsBackLink';
import { AccessoryPriceListDetailsForm } from './Form/AccessoryPriceListDetailsForm';
import { getFormValuesFromAccessoryDetails } from './Form/get-form-values-from-accessory-details';
import { AccessoryPriceListDetailsFormFields } from './AccessoryPriceListDetailsFormFields';

export const AccessoryPriceDetails = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();
  const { type } = useContext(PriceListTypeContext);

  const { accessoryId = '' } = useParams<'accessoryId'>();
  const {
    data: accessoryPrice,
    isLoading,
    isError,
    error,
  } = usePricingAccessoryPricesQuery(
    accessoryId,
    {
      type,
    },
    {
      enabled: hasPermission('read:prices'),
    }
  );

  const { mutateAsync } = usePricingAccessoryPricesMutation(accessoryId);
  const { addSuccessNotification, addErrorNotification } = useNotification();

  if (!hasPermission('read:prices')) {
    return <Four0Three />;
  }

  if (isLoading) {
    return (
      <div className="w-full text-center mt-8">
        <Spinner size="lg" />
      </div>
    );
  }

  if (isError && getErrorStatus(error) === 404) {
    return <Four0Four />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (!accessoryPrice) {
    return <Four0Four />;
  }

  const getAccessoryPricePayload = (
    values: AccessoryPriceFormValues
  ): UpdatePricingAccessoryPricesPayload => ({
    price: {
      value: Number(values.price),
      currency: 'EUR',
    },
    algorithmPrice: values.algorithmName,
    type,
  });

  const handleOnSubmit = (
    values: AccessoryPriceFormValues,
    { resetForm }: FormHelpers<AccessoryPriceFormValues>
  ) => {
    const payload = getAccessoryPricePayload(values);

    return mutateAsync(payload)
      .then(() => {
        resetForm(values);
        addSuccessNotification(formatMessage(messages.updateSuccessfully));
      })
      .catch(({ response }) => {
        switch (response.status) {
          case 400:
            addErrorNotification(formatMessage(messages.badFormattedFields));
            break;
          default:
            addErrorNotification();
            break;
        }
      });
  };

  return (
    <div className="container min-w-3xl max-w-4xl py-12 pb-32">
      <AccessoryPriceListDetailsForm
        initialValues={{
          ...accessoryPriceFormInitialValues,
          ...getFormValuesFromAccessoryDetails(accessoryPrice),
        }}
        onSubmit={handleOnSubmit}
      >
        <>
          <AccessoryPriceListDetailsBackLink />
          <div className="flex items-center justify-between">
            <h2 className="text-3xl">
              {capitalize(
                accessoryPrice.name.split('_').join(' ').toLowerCase()
              )}
            </h2>
            {hasPermission('write:prices') && (
              <FormButton>
                <FormattedMessage {...messages.savePriceList} />
              </FormButton>
            )}
          </div>
          <AccessoryPriceListDetailsFormFields />
        </>
      </AccessoryPriceListDetailsForm>
    </div>
  );
};
