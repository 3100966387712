import { Incident } from '@cooltra/api';
import { classNames } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';
import { RouterCardLink } from '@cooltra/navigation';

import { OperatorOrGroup, Log, Pair, IncidentCardInfo } from '~/common';

import { IncidentRowLayout } from './IncidentRowLayout';
import messages from './messages';

export type IncidentCardProps = {
  incident: Incident;
};

export const IncidentCard = ({
  incident: {
    incidentId,
    title,
    description,
    open,
    operatorId,
    operatorGroupId,
    createdAt,
    createdBy,
  },
}: IncidentCardProps) => (
  <RouterCardLink
    to={`/incidents/${incidentId}`}
    className={classNames(open ? 'opacity-100' : 'opacity-50')}
  >
    <IncidentRowLayout
      className="min-h-28 py-4"
      incident={
        <IncidentCardInfo open={open} title={title} description={description} />
      }
      assignee={
        <div className="text-sm text-neutral-400">
          <Pair.Label>
            <FormattedMessage {...messages.assignee} />
          </Pair.Label>
          <OperatorOrGroup
            operatorId={operatorId}
            operatorGroupId={operatorGroupId}
          />
        </div>
      }
      created={
        <Log
          action="create"
          date={createdAt}
          user="operator"
          userId={createdBy}
        />
      }
    />
  </RouterCardLink>
);
