import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
  },
  cancelItem: {
    defaultMessage: 'Cancel item',
  },
  areYouSureToCancel: {
    defaultMessage: 'Are you sure you want to cancel this item?',
  },
});
