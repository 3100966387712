import { classNames } from '@cooltra/utils';
import { FormattedDate, FormattedMessage } from 'react-intl';

import messages from './messages';

export type TechnicalInspectionScheduledAtValueProps = {
  date: Date;
  remainingDays: number;
  className?: string;
};

const DAYS_PER_MONTH = 30;

export const TechnicalInspectionScheduledAtValue = ({
  date,
  remainingDays,
  className,
}: TechnicalInspectionScheduledAtValueProps) => {
  const isInThePast = remainingDays < 0;

  const getFormattedNegativeRemainingDays = () => {
    const absoluteRemainingDays = Math.abs(remainingDays);

    if (absoluteRemainingDays >= DAYS_PER_MONTH) {
      return (
        <FormattedMessage
          {...messages.monthsOverdue}
          values={{
            total: Math.floor(absoluteRemainingDays / DAYS_PER_MONTH),
          }}
        />
      );
    }

    return (
      <FormattedMessage
        {...messages.daysOverdue}
        values={{
          total: absoluteRemainingDays,
        }}
      />
    );
  };

  const getFormattedRemainingDays = () => {
    if (remainingDays >= DAYS_PER_MONTH) {
      return (
        <FormattedMessage
          {...messages.remainingMonths}
          values={{
            total: Math.floor(remainingDays / DAYS_PER_MONTH),
          }}
        />
      );
    }

    if (remainingDays === 0) {
      return <FormattedMessage {...messages.today} />;
    }

    return (
      <FormattedMessage
        {...messages.remainingDays}
        values={{
          total: remainingDays,
        }}
      />
    );
  };

  return (
    <span className={classNames('', className)}>
      <span>
        <FormattedDate
          value={date}
          day="2-digit"
          month="short"
          year="numeric"
        />
      </span>
      {isInThePast ? (
        <span className="text-danger-500 ml-1">
          ({getFormattedNegativeRemainingDays()})
        </span>
      ) : (
        <span className="ml-1">({getFormattedRemainingDays()})</span>
      )}
    </span>
  );
};
