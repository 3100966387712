import { format } from 'date-fns';
import { CooltraForEmployeePromotion } from '@cooltra/api';

import { CooltraForEmployeesFormValues } from '~/common';
import { dateFormat, timeFormat } from '~/utils/date';

export const getInitialFormValuesFromPayload = ({
  codePrefix,
  companyFiscalName,
  companyName,
  codes,
  numberOfCodes,
  redeemableFrom,
  redeemableUntil,
  consumableUntil,
  subscriptionId,
  title,
}: CooltraForEmployeePromotion): CooltraForEmployeesFormValues => {
  const redeemableFromDate = new Date(redeemableFrom);

  return {
    title: title,
    subscription: subscriptionId,
    companyName: companyName,
    companyFiscalName: companyFiscalName,
    codes,
    codePrefix: codePrefix,
    numberOfCodes: numberOfCodes,
    redeemableFromDate: format(redeemableFromDate, dateFormat),
    redeemableFromTime: format(redeemableFromDate, timeFormat),
    redeemableUntilDate: redeemableUntil
      ? format(new Date(redeemableUntil), dateFormat)
      : '',
    redeemableUntilTime: redeemableUntil
      ? format(new Date(redeemableUntil), timeFormat)
      : '',
    redeemableDoesNotExpire: !redeemableUntil,
    consumableUntilDate: consumableUntil
      ? format(new Date(consumableUntil), dateFormat)
      : '',
    consumableUntilTime: consumableUntil
      ? format(new Date(consumableUntil), timeFormat)
      : '',
    consumableDoesNotExpire: !consumableUntil,
    editMode: true,
  };
};
