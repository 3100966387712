import { useVehiclesQuery, VehicleListItem } from '@cooltra/api';
import { useMemo } from 'react';

import {
  isVehicleAMatch,
  sortVehicles,
} from './regular-filters/regular-filters';
import { useVehiclesFilters } from './useVehiclesFilters';

export type ExtendedVehicleListItem = VehicleListItem & {
  isMatch: boolean;
};

export const useFilteredVehiclesQuery = () => {
  const { values } = useVehiclesFilters();

  const { data: allVehicles = [], ...rest } = useVehiclesQuery({
    system: values.system,
  });

  const filteredData = useMemo(() => {
    const vehicles: ExtendedVehicleListItem[] = (allVehicles || []).map(
      (vehicle) => ({
        ...vehicle,
        isMatch: isVehicleAMatch(vehicle, values),
      })
    );
    const filteredVehicles: ExtendedVehicleListItem[] = vehicles.filter(
      ({ isMatch }) => isMatch
    );
    return {
      vehicles,
      filteredVehicles: sortVehicles(filteredVehicles, values),
      filteredVehiclesTotal: filteredVehicles.length,
    };
  }, [allVehicles, values]);

  return {
    ...rest,
    data: filteredData,
  };
};
