import { FormattedMessage } from 'react-intl';
import { useFormContext } from '@cooltra/form';

import { StationBasedFormValues, StationBasedPromotionFields } from '~/common';

import messages from '../messages';

export const WebOffersDetails = () => {
  const { values } = useFormContext<StationBasedFormValues>();

  if (values.promotionTarget !== 'WEB_OFFER') {
    return <></>;
  }

  return (
    <div className="p-10">
      <div className="flex flex-col gap-8">
        <h2 className="text-xl font-semibold text-neutral-400">
          <FormattedMessage {...messages.details} />
        </h2>
        <div className="grid grid-cols-3 gap-x-5">
          <div className="col-span-3">
            <StationBasedPromotionFields.About />
          </div>
        </div>
        <StationBasedPromotionFields.Discount />
      </div>
    </div>
  );
};
