import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Service',
  },
  editService: {
    defaultMessage: 'Edit service',
  },
  success: {
    defaultMessage: 'You have successfully edited the service!',
  },
  badFormattedFields: {
    defaultMessage:
      'This service cannot be edited, please check that every field is correct',
  },
  mainInformation: {
    defaultMessage: 'Main information',
  },
  completedServiceData: {
    defaultMessage: 'Completed service data',
  },
  invalidFormat: {
    defaultMessage: 'Invalid format',
  },
});
