import { AccessoryName } from '@cooltra/utils';
import { useIntl } from 'react-intl';

import { getAccessoryNameMessage } from '~/utils/accessories';

export const useAccessoryName = () => {
  const { formatMessage } = useIntl();
  const getAccessoryName = (name: AccessoryName) => {
    const message = getAccessoryNameMessage(name);
    return message ? formatMessage(message) : name;
  };

  return getAccessoryName;
};
