import { Route, Routes } from 'react-router-dom';

import { CreateCooltraForEmployees } from './Mutations/Create/CreateCooltraForEmployees';
import { CooltraForEmployeesFiltersForm } from './List/CooltraForEmployeesFiltersForm';
import { CooltraForEmployeesList } from './List/CooltraForEmployeesList';
import { EditCooltraForEmployees } from './Mutations/Edit/EditCooltraForEmployees';

export const CooltraForEmployees = () => (
  <Routes>
    <Route
      path="/"
      element={
        <CooltraForEmployeesFiltersForm>
          <CooltraForEmployeesList />
        </CooltraForEmployeesFiltersForm>
      }
    />
    <Route path="/new" element={<CreateCooltraForEmployees />} />
    <Route
      path="/:cooltraForEmployeesId/edit"
      element={<EditCooltraForEmployees />}
    />
  </Routes>
);
