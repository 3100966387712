import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { Telematics, modelTelematics, Model as ModelType } from '@cooltra/api';
import { useEffect } from 'react';
import { useField, useFormContext, FormSelectField } from '@cooltra/form';

import { useModelOptions } from '~/common';

import messages from './messages';

const modelFieldName = 'model';

export const Model = () => {
  const options = useModelOptions();
  const { dirty } = useFormContext();
  const { setValue: setTelematicsValue } = useField<Telematics>('telematics');
  const { value: model } = useField<ModelType>(modelFieldName);

  useEffect(() => {
    if (model && dirty) {
      const compatibleTelematics = modelTelematics[model];
      setTelematicsValue(compatibleTelematics[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  return (
    <FormSelectField
      id="model"
      label={
        <InputLabel htmlFor="model">
          <FormattedMessage {...messages.model} />
        </InputLabel>
      }
      options={options}
      name={modelFieldName}
    />
  );
};
