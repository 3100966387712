import { useIntl } from 'react-intl';
import { MdOutlineSettings } from 'react-icons/md';
import { RouterNavLink } from '@cooltra/navigation';

import messages from '../messages';

export const Settings = () => {
  const { formatMessage } = useIntl();

  return (
    <li>
      <RouterNavLink
        to="/settings"
        aria-label={formatMessage(messages.settings)}
      >
        <span className="text-lg leading-0">
          <MdOutlineSettings />
        </span>
      </RouterNavLink>
    </li>
  );
};
