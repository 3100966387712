import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholder: {
    defaultMessage: 'dd-mm-yyyy',
  },
  from: {
    defaultMessage: 'From:',
  },
  to: {
    defaultMessage: 'To:',
  },
});
