import { Button } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';

export type LevelsProps = {
  id: string;
  value: number;
  levels: number;
  buttonClassNames?: string;
  buttonGreaterThanValueClassNames?: string;
  buttonLessThanValueClassNames?: string;
  onClick?: (value: number) => void;
  readonly?: boolean;
};

export const Levels = ({
  id,
  value,
  levels,
  buttonClassNames,
  buttonGreaterThanValueClassNames,
  buttonLessThanValueClassNames,
  onClick,
  readonly,
}: LevelsProps) => {
  const handleOnClick = (level: number) => {
    if (onClick) {
      onClick(level);
    }
  };

  return (
    <>
      {[...Array(levels).keys()].map((index) => {
        const level = index + 1;
        return (
          <li key={level} id={id}>
            {readonly ? (
              <div
                data-testid={`LEVEL_BUTTON_${
                  value === level ? `${level}_ACTIVE` : level
                }`}
                className={classNames(
                  buttonClassNames,
                  value >= level
                    ? buttonGreaterThanValueClassNames
                    : buttonLessThanValueClassNames
                )}
              />
            ) : (
              <Button
                type="button"
                data-testid={`LEVEL_BUTTON_${
                  value === level ? `${level}_ACTIVE` : level
                }`}
                className={classNames(
                  buttonClassNames,
                  value >= level
                    ? buttonGreaterThanValueClassNames
                    : buttonLessThanValueClassNames
                )}
                onClick={() => handleOnClick(level)}
              />
            )}
          </li>
        );
      })}
    </>
  );
};
