import { defineMessages } from 'react-intl';

export default defineMessages({
  vehicle: {
    defaultMessage: 'Vehicle',
  },
  servicePoint: {
    defaultMessage: 'Service point',
  },
});
