import { ProblemReportListItem } from '@cooltra/api';
import { Icon } from '@cooltra/ui';
import { MdOutlineImage } from 'react-icons/md';

import { ProblemReportBadge } from '../ProblemReportBadge/ProblemReportBadge';

export type ProblemReportCardInfoProps = Pick<
  ProblemReportListItem,
  'open' | 'numberImages' | 'description' | 'tags'
>;

export const ProblemReportCardInfo = ({
  open,
  numberImages,
  description,
  tags,
}: ProblemReportCardInfoProps) => (
  <div data-testid={open ? 'OPEN_PROBLEM_REPORT' : 'CLOSED_PROBLEM_REPORT'}>
    <div className="flex gap-4 items-center">
      <div className="flex flex-wrap gap-2">
        {tags.map((tag) => (
          <ProblemReportBadge key={tag} className="text-xs" tagId={tag} />
        ))}
        {numberImages > 0 && (
          <span
            data-testid="PHOTOS_COUNT"
            className="flex gap-0.5 items-center text-neutral-400"
          >
            <Icon className="text-lg">
              <MdOutlineImage />
            </Icon>
            <span className="text-xs">{numberImages}</span>
          </span>
        )}
      </div>
    </div>
    {description && (
      <p className="mt-3 text-xs text-neutral-800">{description}</p>
    )}
  </div>
);
