import { defineMessages } from 'react-intl';

export default defineMessages({
  damages: {
    defaultMessage: 'Damages',
  },
  error: {
    defaultMessage: 'There has been a problem loading vehicle damages',
  },
  empty: {
    defaultMessage: 'The assigned vehicle has no damages',
  },
});
