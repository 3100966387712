import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { FormDateInputField } from '../../../FormattedInput';

import messages from './messages';

export const DateOfBirth = () => (
  <FormDateInputField
    id="date-of-birth"
    label={
      <InputLabel htmlFor="date-of-birth">
        <FormattedMessage {...messages.dateOfBirth} />
      </InputLabel>
    }
    name="dateOfBirth"
  />
);
