import { BadgePlaceholder, Card, Divider, Placeholder } from '@cooltra/ui';

import { StationBasedPromotionRowLayout } from '../StationBasedPromotionRowLayout';

export const StationBasedPromotionCardLoading = () => (
  <Card>
    <StationBasedPromotionRowLayout
      className="min-h-24 py-4"
      promotion={
        <div className="flex flex-col gap-3 py-0.5">
          <Placeholder className="w-24 h-2" />
          <Placeholder className="w-40 h-2" />
        </div>
      }
      type={<Placeholder className="h-2 w-16" />}
      validity={
        <div className="flex gap-4 items-center">
          <div className="flex flex-col gap-3 py-0.5">
            <Placeholder className="w-16 h-2" />
            <Placeholder className="w-24 h-2" />
          </div>
          <div className="w-6">
            <Divider />
          </div>
          <div className="flex flex-col gap-3 py-0.5">
            <Placeholder className="w-16 h-2" />
            <Placeholder className="w-24 h-2" />
          </div>
        </div>
      }
      state={<BadgePlaceholder className="w-20" />}
    />
  </Card>
);
