import { useOperatorsQuery } from '@cooltra/auth-api';
import { Placeholder } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { useVehicleQuery } from '~/hooks';

import messages from './messages';

export type TakenByListItemProps = {
  vehicleId: string;
};

export const TakenByListItem = ({ vehicleId }: TakenByListItemProps) => {
  const { data: vehicle, isLoading: isVehicleLoading } = useVehicleQuery(
    vehicleId,
    {
      enabled: Boolean(vehicleId),
    }
  );
  const { data: operators, isLoading: isOperatorsLoading } =
    useOperatorsQuery();

  const renderTakenBy = () => {
    if (isVehicleLoading || isOperatorsLoading) {
      return <Placeholder className="w-32 h-3" />;
    }

    const operatorName = operators?.find(
      ({ externalId }) => vehicle?.takenBy === externalId
    )?.name;

    return (
      <span className="text-sm text-neutral-500 pl-2 truncate">
        {operatorName}
      </span>
    );
  };

  return (
    <li className="py-4 border-b border-solid border-neutral-100">
      <div className="flex justify-between items-center gap-4">
        <span className="text-sm text-neutral-400">
          <FormattedMessage {...messages.location} />
        </span>
        {renderTakenBy()}
      </div>
    </li>
  );
};
