import { FormRadioGroupField, useFormContext } from '@cooltra/form';

import {
  AccessoriesOptions,
  VehicleTypeOptions,
  InsurancesOptions,
  StationBasedFormValues,
} from '../../../StationBasedForm/station-based-form';

type YesOrNoOptions = {
  label: string;
  value: AccessoriesOptions | VehicleTypeOptions | InsurancesOptions;
};

type AppliesToFormRadioPros = {
  yesOrNoOptions: YesOrNoOptions[];
  name: string;
  disabled: boolean;
};

export const AppliesToFormRadio = ({
  yesOrNoOptions,
  name,
  disabled,
}: AppliesToFormRadioPros) => {
  const { values } = useFormContext<StationBasedFormValues>();
  const { promotionTarget } = values;

  if (promotionTarget === 'COUPON') {
    return (
      <FormRadioGroupField
        items={[
          {
            label: '',
            value: name,
          },
        ]}
        className="w-8 flex flex-col text-center"
        innerClassName="flex"
        legend={<></>}
        name="enableGenericAppliesTo"
        disabled={disabled}
      />
    );
  }

  return (
    <FormRadioGroupField
      items={yesOrNoOptions}
      className="w-44 flex flex-col text-xs"
      innerClassName="flex flex-row gap-6"
      legend={<></>}
      name={name}
      disabled={disabled}
    />
  );
};
