import { classNames, consecutiveNumbers } from '@cooltra/utils';
import {
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  format,
  isToday,
} from 'date-fns';
import { HTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import { FormattedDate } from 'react-intl';

import { dateFormat } from '~/utils/date';

import { CalendarLabel, CalendarCell, HeaderCell } from '../Calendar';

import './weekly-calendar.css';

const getWeekData = (date: Date) => {
  const weekStart = startOfWeek(date);
  const weekEnd = endOfWeek(date);

  const dateInterval = { start: weekStart, end: weekEnd };
  const dates = eachDayOfInterval(dateInterval);

  return dates;
};

export const daySlots = consecutiveNumbers(24)
  .map((hour) =>
    ['00', '30'].map(
      (minutes) => `${hour.toString().padStart(2, '0')}:${minutes}`
    )
  )
  .flat();

export type WeeklyCalendarProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'children'
> & {
  displayDate?: Date;
  renderSlot: (date: Date, time: string) => ReactNode;
};

export const WeeklyCalendar = ({
  displayDate = new Date(),
  renderSlot,
  className,
  ...rest
}: WeeklyCalendarProps) => {
  const halfPastSevenSlot = useRef<HTMLDivElement>(null);

  const weekData = getWeekData(displayDate);

  useEffect(() => {
    halfPastSevenSlot.current?.scrollIntoView();
  }, []);

  return (
    <div
      className={classNames(
        'h-full overflow-scroll no-scrollbar pb-8',
        className
      )}
      {...rest}
    >
      <div className="bg-neutral-0">
        <div className="grid weekly-calendar-row">
          <div>
            <HeaderCell className="border sticky top-0 bg-neutral-0 z-10" />
            {daySlots.map((time) => (
              <CalendarCell
                ref={time === '07:30' ? halfPastSevenSlot : null}
                className="border-b border-l border-r text-center h-40"
                key={`row-label-${time}`}
              >
                <CalendarLabel>{time}</CalendarLabel>
              </CalendarCell>
            ))}
          </div>
          {weekData.map((date) => {
            const dateKey = format(date, dateFormat);
            return (
              <div key={`weekly-calendar-column-${dateKey}`}>
                <HeaderCell className="border-r border-t border-b sticky top-0 bg-neutral-0 z-10">
                  {isToday(date) ? (
                    <span className="text-neutral-0 bg-neutral-800 rounded-full p-1.5 p font-semibold text-xs">
                      <FormattedDate day="2-digit" month="short" value={date} />
                    </span>
                  ) : (
                    <CalendarLabel>
                      <FormattedDate day="2-digit" month="short" value={date} />
                    </CalendarLabel>
                  )}
                </HeaderCell>
                {daySlots.map((time) => (
                  <CalendarCell
                    className="border-b border-r h-40"
                    key={`weekly-calendar-column-cell-${dateKey}-${time}`}
                  >
                    {renderSlot(date, time)}
                  </CalendarCell>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
