import { defineMessages } from 'react-intl';

export default defineMessages({
  idCardNumber: {
    defaultMessage: 'ID number',
  },
  idCardExpirationDate: {
    defaultMessage: 'ID expiration date',
  },
  day: {
    defaultMessage: 'Day',
  },
  month: {
    defaultMessage: 'Month',
  },
  year: {
    defaultMessage: 'Year',
  },
  dayPlaceholder: {
    defaultMessage: 'DD',
  },
  monthPlaceholder: {
    defaultMessage: 'MM',
  },
  yearPlaceholder: {
    defaultMessage: 'YYYY',
  },
  taxIdNumber: {
    defaultMessage: 'Tax ID number',
  },
  taxIdCountry: {
    defaultMessage: 'Tax ID country',
  },
});
