import { FormattedMessage } from 'react-intl';
import { Divider } from '@cooltra/ui';
import { MdOutlineExpandMore, MdOutlineSchedule } from 'react-icons/md';
import { LatestApprovalReview } from '@cooltra/api';

import { ReviewTimelineItem } from './ReviewTimelineItem';
import messages from './messages';

type LastReviewsListProps = {
  closeReviewsList: () => void;
  approvalReviews: LatestApprovalReview[];
};
export const LastReviewsList = ({
  closeReviewsList,
  approvalReviews,
}: LastReviewsListProps) => {
  return (
    <div className="w-[30rem] fixed left-0 bottom-0 bg-neutral-0 rounded-tr-lg">
      <button
        className="w-full flex items-center justify-between p-5 cursor-pointer"
        onClick={closeReviewsList}
      >
        <div className="flex items-center gap-2">
          <MdOutlineSchedule className="w-5 h-5 text-neutral-400" />
          <span className="text-neutral-500 font-semibold text-md">
            <FormattedMessage {...messages.yourLastReviews} />
          </span>
        </div>
        <MdOutlineExpandMore className="text-2xl text-neutral-300" />
      </button>
      <Divider />
      <div className="w-full pt-5 px-5">
        {approvalReviews?.map((approvalReview) => (
          <ReviewTimelineItem key={approvalReview.userId} {...approvalReview} />
        ))}
      </div>
    </div>
  );
};
