import { useUserCurrentActivityQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Card } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { ListErrorMessageCard } from '~/common';

import { CurrentRental } from '../CurrentRental';
import { CurrentReservation } from '../CurrentReservation';

import { CurrentActivityLoading } from './CurrentActivityLoading';
import messages from './messages';

export const CurrentActivity = () => {
  const { hasPermission } = useAuthClaimsQuery();

  const { id: userId = '' } = useParams<'id'>();

  const canViewCurrentActivity = hasPermission('read:rentals');

  const { data, isError, isLoading } = useUserCurrentActivityQuery(userId, {
    enabled: !!userId && canViewCurrentActivity,
  });

  const renderContent = () => {
    if (isError) {
      return (
        <ListErrorMessageCard>
          <FormattedMessage {...messages.error} />
        </ListErrorMessageCard>
      );
    }

    if (isLoading) {
      return <CurrentActivityLoading />;
    }

    if (!data?.reservation && !data?.rental) {
      return (
        <Card className="h-20 flex items-center justify-center opacity-60 px-8">
          <p className="text-neutral-600 text-sm">
            <FormattedMessage {...messages.noCurrentActivity} />
          </p>
        </Card>
      );
    }

    if (data.reservation) {
      return (
        <CurrentReservation reservation={data.reservation} userId={userId} />
      );
    }

    if (data.rental) {
      return <CurrentRental rental={data.rental} />;
    }

    return <></>;
  };

  if (!canViewCurrentActivity) {
    return null;
  }

  return (
    <section data-testid="CURRENT_ACTIVITY">
      <h2 className="text-base text-neutral-500 font-semibold mb-6">
        <FormattedMessage {...messages.heading} />
      </h2>
      {renderContent()}
    </section>
  );
};
