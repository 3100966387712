import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Modal } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';
import {
  useContractQuery,
  useSignAnnexMutation,
} from '@cooltra/station-based-api';

import { useNotification, usePolling } from '~/hooks';

import messages from './messages';

import './sign-annex-button.css';

export type SignAnnexButtonProps = {
  contractId: string;
  annexId: string;
};

export const SignAnnexButton = ({
  contractId,
  annexId,
}: SignAnnexButtonProps) => {
  const { formatMessage } = useIntl();
  const { user: operator } = useAuth0();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const [signaturitUrl, setSignaturitUrl] = useState<string | null>(null);
  const [isPollingTriggered, setIsPollingTriggered] = useState(false);
  const { mutateAsync, isPending } = useSignAnnexMutation(contractId, annexId);
  const { data: contract, refetch } = useContractQuery(contractId);
  const annex = contract?.annexes?.find(({ id }) => id === annexId);

  const { isPolling } = usePolling({
    fn: refetch,
    onSettle: () => {
      if (annex && annex.status === 'SIGNED') {
        addSuccessNotification(formatMessage(messages.annexSignedSuccessfully));
        return;
      }

      addErrorNotification();
    },
    interval: isPollingTriggered && annex?.status !== 'SIGNED' ? 2500 : 0,
    maxAttempts: 10,
  });

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      if (event.data.event === 'completed') {
        toggleOff();
        setIsPollingTriggered(true);
      }
    },
    [toggleOff]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  const signAnnex = async () =>
    await mutateAsync({ operatorFullName: operator?.name ?? '' })
      .then(({ url }) => {
        setSignaturitUrl(url);
        toggleOn();
      })
      .catch(() => addErrorNotification());

  return (
    <>
      <Modal
        className="h-full w-full"
        isOpen={isOpen}
        onRequestClose={toggleOff}
      >
        {signaturitUrl && (
          <iframe
            data-testid="SIGN_ANNEX_MODAL"
            className="signature-modal-content w-full"
            src={signaturitUrl}
          ></iframe>
        )}
      </Modal>
      <Button
        size="md"
        emphasis="high"
        loading={isPolling || isPending}
        disabled={annex?.status === 'PENDING_PAY'}
        onClick={signAnnex}
      >
        <FormattedMessage {...messages.signAnnex} />
      </Button>
    </>
  );
};
