import { CreatePackPayload } from '@cooltra/api';
import { System } from '@cooltra/auth-api';
import { LanguageCode } from '@cooltra/countries';
import { format, parse } from 'date-fns';

import { dateAndTimeFormat, dateFormat, timeFormat } from '~/utils/date';
import { clean } from '~/utils/objects';

type TitleTranslationKey = `titleTranslations.${LanguageCode}`;
type TitleTranslations = Record<TitleTranslationKey, string>;

type DescriptionTranslationKey = `descriptionTranslations.${LanguageCode}`;
type DescriptionTranslations = Record<DescriptionTranslationKey, string>;

export type PackExpirationMode = 'specific-date' | 'after-days';

export type PackFormValues = {
  code: string;
  price: string;
  credit: string;
  validFromDate: string;
  validFromTime: string;
  validUntilDate: string;
  validUntilTime: string;
  packDoesntExpire: boolean;
  benefitUntilDate: string;
  benefitUntilTime: string;
  benefitDoesntExpire: boolean;
  benefitExpiresAfterDays: string;
  benefitExpirationMode: PackExpirationMode | '';
  maxPurchasesPerUser: string;
  targetHomeSystems: System[];
  targetUserIds: string;
} & TitleTranslations &
  DescriptionTranslations;

export type PackFormErrors = Partial<Record<string, string | undefined>>;

const currentDate = format(new Date(), dateFormat);
const currentTime = format(new Date(), timeFormat);

export const initialPackFormValues: PackFormValues = {
  code: '',
  price: '',
  credit: '',
  validFromDate: currentDate,
  validFromTime: currentTime,
  validUntilDate: '',
  validUntilTime: '',
  packDoesntExpire: false,
  benefitDoesntExpire: false,
  benefitUntilDate: '',
  benefitUntilTime: '',
  benefitExpiresAfterDays: '',
  benefitExpirationMode: 'specific-date',
  maxPurchasesPerUser: '',
  targetHomeSystems: [],
  targetUserIds: '',
  'titleTranslations.en': '',
  'titleTranslations.es': '',
  'titleTranslations.it': '',
  'titleTranslations.pt': '',
  'titleTranslations.fr': '',
  'titleTranslations.ca': '',
  'titleTranslations.nl': '',
  'descriptionTranslations.en': '',
  'descriptionTranslations.es': '',
  'descriptionTranslations.it': '',
  'descriptionTranslations.pt': '',
  'descriptionTranslations.fr': '',
  'descriptionTranslations.ca': '',
  'descriptionTranslations.nl': '',
};

const getISODateTime = (
  date: string | undefined,
  time: string | undefined
): string | null => {
  if (!date || !time) {
    return null;
  }

  return parse(`${date} ${time}`, dateAndTimeFormat, new Date()).toISOString();
};

export const formatPackTranslations = (values: PackFormValues) => {
  const titles = {
    en: values['titleTranslations.en'],
    es: values['titleTranslations.es'],
    it: values['titleTranslations.it'],
    pt: values['titleTranslations.pt'],
    fr: values['titleTranslations.fr'],
    ca: values['titleTranslations.ca'],
    nl: values['titleTranslations.nl'],
  };
  const titleTranslations = {
    en: values['titleTranslations.en'],
    ...clean(titles),
  };

  const descriptions = {
    es: values['descriptionTranslations.es'],
    it: values['descriptionTranslations.it'],
    pt: values['descriptionTranslations.pt'],
    fr: values['descriptionTranslations.fr'],
    ca: values['descriptionTranslations.ca'],
    nl: values['descriptionTranslations.nl'],
  };
  const descriptionTranslations = {
    en: values['descriptionTranslations.en'],
    ...clean(descriptions),
  };

  return {
    titleTranslations,
    descriptionTranslations,
  };
};

const formatUserIds = (userIds: string): string[] => {
  const uuidLength = 36;
  return userIds
    .split('\n')
    .map((userId) => userId.trim())
    .filter((userId) => userId.length === uuidLength);
};

export const formatPackFormValues = (
  values: PackFormValues
): CreatePackPayload => {
  const {
    code,
    price,
    credit,
    validFromDate,
    validFromTime,
    validUntilDate,
    validUntilTime,
    benefitUntilDate,
    benefitUntilTime,
    benefitExpiresAfterDays,
    maxPurchasesPerUser,
    targetHomeSystems,
    targetUserIds,
  } = values;

  return {
    code,
    price: {
      value: Number(price),
      currency: 'EUR',
    },
    credit: {
      value: Number(credit),
      currency: 'EUR',
    },
    validFrom: getISODateTime(validFromDate, validFromTime),
    validUntil: getISODateTime(validUntilDate, validUntilTime),
    benefitUntil: getISODateTime(benefitUntilDate, benefitUntilTime),
    benefitExpiresAfterDays: benefitExpiresAfterDays
      ? Number(benefitExpiresAfterDays)
      : null,
    maxPurchasesPerUser: maxPurchasesPerUser
      ? Number(maxPurchasesPerUser)
      : null,
    targetHomeSystems,
    targetUserIds: formatUserIds(targetUserIds),
    ...formatPackTranslations(values),
  };
};

export type PackFormType = 'create' | 'edit';
