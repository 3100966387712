import { FormattedMessage } from 'react-intl';
import { countryOptions } from '@cooltra/countries';
import { InputLabel } from '@cooltra/ui';
import { FormSelectFieldProps, FormSelectField } from '@cooltra/form';

import messages from './messages';

export type IdCardCountryProps = Omit<
  FormSelectFieldProps,
  'name' | 'id' | 'label' | 'isClearable' | 'options'
>;

export const IdCardCountry = (props: IdCardCountryProps) => (
  <FormSelectField
    id="tax-id-country-field"
    name="taxIdCountry"
    isClearable
    options={countryOptions}
    label={
      <InputLabel htmlFor="tax-id-country-field">
        <FormattedMessage {...messages.taxIdCountry} />
      </InputLabel>
    }
    {...props}
  />
);
