import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useIncidentMutation, useProblemReportQuery } from '@cooltra/api';
import { System } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { TopLoadingBar } from '@cooltra/ui';
import { Form, FormProvider, FormButton } from '@cooltra/form';

import {
  DividedCard,
  ErrorPage,
  ProblemReportPair,
  SystemPair,
  IssueFields,
  Context,
  Four0Three,
  IncidentBackLink,
} from '~/common';
import { useNotification, useVehicleQuery } from '~/hooks';

import * as IncidentFields from '../IncidentFields';
import {
  validateIncidentForm,
  IncidentFormValues,
  incidentInitialFormValues,
} from '../incident-form';

import messages from './messages';

export const CreateIncident = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const vehicleId = searchParams.get('vehicleId') || '';
  const problemReportId = searchParams.get('problemReportId') || '';

  const { data: vehicle, ...vehicleQuery } = useVehicleQuery(vehicleId, {
    enabled: !!vehicleId,
  });
  const { data: problemReport, ...problemReportQuery } = useProblemReportQuery(
    problemReportId,
    {
      enabled: !!problemReportId,
    }
  );

  const rentalId = searchParams.get('rentalId');
  const systemByParams = searchParams.get('system') as System | null;
  const system = vehicle?.system || systemByParams || null;

  const mutation = useIncidentMutation();

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleSubmit = async (values: IncidentFormValues) => {
    if (problemReport) {
      return mutation
        .mutateAsync({
          problemReportId: problemReport.problemReportId,
          rentalId: problemReport.rentalId,
          system,
          ...values,
        })
        .then(() => {
          addSuccessNotification(intl.formatMessage(messages.success));
          navigate(`/problem-reports/${problemReportId}`);
        })
        .catch(() => addErrorNotification());
    } else if (rentalId) {
      return mutation
        .mutateAsync({
          problemReportId: null,
          rentalId,
          system,
          ...values,
        })
        .then(() => {
          addSuccessNotification(intl.formatMessage(messages.success));
          navigate(`/rentals/${rentalId}/incidents`);
        })
        .catch(() => addErrorNotification());
    }
  };

  if (
    getErrorStatus(problemReportQuery.error) === 403 ||
    getErrorStatus(vehicleQuery.error) === 403
  ) {
    return <Four0Three />;
  }

  if (problemReportQuery.isError || vehicleQuery.isError) {
    return <ErrorPage />;
  }

  return (
    <>
      {problemReportQuery.fetchStatus === 'fetching' && <TopLoadingBar />}
      <FormProvider
        initialValues={incidentInitialFormValues}
        validate={validateIncidentForm(intl)}
        onSubmit={handleSubmit}
      >
        <Form>
          <DividedCard
            className="min-w-4xl max-w-5xl py-12"
            renderHeader={
              <div className="flex items-end justify-between">
                <div>
                  <IncidentBackLink problemReportId={problemReportId} />
                  <h1 className="text-3xl">
                    <FormattedMessage {...messages.title} />
                  </h1>
                </div>
                <FormButton>
                  <FormattedMessage {...messages.createIncident} />
                </FormButton>
              </div>
            }
            renderLeft={
              <div className="flex flex-col gap-9">
                <Context
                  isLoading={!!problemReportId && problemReportQuery.isLoading}
                  rentalId={problemReport?.rentalId || rentalId}
                />
                <SystemPair system={system} />
                {problemReportId && (
                  <ProblemReportPair problemReportId={problemReportId} />
                )}
              </div>
            }
            renderRight={
              <div className="flex flex-col gap-y-6 gap-x-8">
                <IncidentFields.Title system={system || undefined} />
                <div className="grid grid-cols-2 gap-x-8">
                  <IssueFields.Assignee />
                  <IssueFields.Operator systems={system ? [system] : []} />
                </div>
                <IssueFields.Description />
              </div>
            }
          />
        </Form>
      </FormProvider>
    </>
  );
};
