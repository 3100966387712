import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';
import { useLocation } from 'react-router-dom';
import { getParams } from '@cooltra/utils';

import { useNavigateWithQueryParams } from '~/hooks';

import { UsersFiltersFormValues } from './users-filters-form';

export const useUsersFiltersFromUrl = () => {
  const { search } = useLocation();
  const params = getParams<'keyword'>(search);

  return {
    keyword: params.string('keyword') || '',
  } as UsersFiltersFormValues;
};

export const UsersFiltersForm = ({ children }: PropsWithChildren) => {
  const navigateWithQueryParams = useNavigateWithQueryParams();

  const values = useUsersFiltersFromUrl();

  const onSubmit = async (values: UsersFiltersFormValues) =>
    navigateWithQueryParams(values);

  return (
    <FormProvider initialValues={values} onSubmit={onSubmit}>
      {children}
    </FormProvider>
  );
};
