import { Task } from '@cooltra/api';
import { classNames } from '@cooltra/utils';
import { RouterCardLink } from '@cooltra/navigation';

import { Log, TaskCardTitle, TaskTags } from '~/common';

import { TaskRowLayout } from './TaskRowLayout';

export type TaskCardProps = {
  task: Omit<Task, 'vehicle'>;
};

export const TaskCard = ({
  task: {
    taskId,
    blocking,
    tagIds,
    title,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    open,
  },
}: TaskCardProps) => (
  <RouterCardLink
    to={`/tasks/${taskId}`}
    className={classNames(open ? 'opacity-100' : 'opacity-50', 'h-28')}
  >
    <TaskRowLayout
      task={
        <div data-testid={open ? 'OPEN_TASK' : 'CLOSED_TASK'}>
          <TaskCardTitle>{title}</TaskCardTitle>
          <TaskTags tagIds={tagIds} blocking={blocking} />
        </div>
      }
      updated={
        updatedAt &&
        updatedBy && (
          <Log
            action="update"
            date={updatedAt}
            user="operator"
            userId={updatedBy}
          />
        )
      }
      created={
        <Log
          action="create"
          date={createdAt}
          user="operator"
          userId={createdBy}
        />
      }
    />
  </RouterCardLink>
);
