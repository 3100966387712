import { defineMessages } from 'react-intl';

export default defineMessages({
  save: {
    defaultMessage: 'Save',
  },
  saved: {
    defaultMessage: 'Saved',
  },
});
