import { Button, Close, Image, Modal, ModalProps } from '@cooltra/ui';
import React, { useRef, useState } from 'react';
import { Camera, CameraType } from 'react-camera-pro';
import { useIntl } from 'react-intl';
import { classNames } from '@cooltra/utils';
import { useToggle } from '@cooltra/hooks';

import { blobFromBase64String, readFile } from '~/utils/file';
import { useNotification } from '~/hooks/useNotification';
import { getEnvVariable } from '~/utils/environment';

import messages from './messages';
import rectangularOverlayHorizontal from './rectangular-overlay-horizontal.svg';
import rectangularOverlayVertical from './rectangular-overlay-vertical.svg';

const isPlaywright = getEnvVariable('IS_PLAYWRIGHT') === 'true';

export type CameraModalProps = Pick<ModalProps, 'isOpen' | 'onRequestClose'> & {
  onChange: (image: string) => void;
  onSave: (formData: FormData) => Promise<{ id: string }>;
  isDocument?: boolean;
  fileType: string;
};

export const CameraModal = ({
  onChange,
  onSave,
  onRequestClose,
  isOpen,
  isDocument = false,
  fileType = 'zeus-camera-image',
}: CameraModalProps) => {
  const [
    isLoading,
    { toggleOn: toggleLoadingOn, toggleOff: toggleLoadingOff },
  ] = useToggle();

  const { formatMessage } = useIntl();
  const webcamRef = useRef<CameraType | null>(null);
  const [image, setImage] = useState<string | null>(null);
  const [numberOfCameras, setNumberOfCameras] = useState(0);

  const { addErrorNotification } = useNotification();

  const handleTakePicture = () => {
    if (isPlaywright) {
      const fakeImage =
        'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=';
      setImage(fakeImage);
      return;
    }
    if (webcamRef.current) {
      const photo = webcamRef.current.takePhoto();
      setImage(photo as string);
    }
  };

  const handleRemovePicture = () => setImage(null);

  const handleClose = (e: React.MouseEvent<Element, MouseEvent>) => {
    setImage(null);
    onRequestClose && onRequestClose(e);
  };

  const handleUpload = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (!image) return;

    toggleLoadingOn();
    const blob = blobFromBase64String(image);
    const file = new File([blob], fileType, { type: blob.type });

    readFile(blob)
      .then(() => {
        const formData = new FormData();
        formData.append('image', file);
        return onSave(formData);
      })
      .then(({ id }) => {
        toggleLoadingOff();
        onChange(id);
        handleClose(e);
      })
      .catch(() => {
        toggleLoadingOff();
        addErrorNotification();
      });
  };

  return (
    <Modal
      type="screen"
      className="h-full"
      isOpen={isOpen}
      hideHeader
      onRequestClose={handleClose}
    >
      <div className="relative w-full h-full" data-testid="CAMERA_MODAL">
        <Camera
          numberOfCamerasCallback={setNumberOfCameras}
          ref={webcamRef}
          errorMessages={{}}
          facingMode="environment"
        />
        <Close
          onClick={handleClose}
          className="absolute top-4 right-4 z-40 text-neutral-0 text-5xl p-3"
          aria-label={formatMessage(messages.closeButton)}
        />
        {image && (
          <Image
            src={image}
            alt={image}
            className="absolute top-0 left-0 w-full h-full z-10"
          />
        )}
        {!image &&
          (isDocument ? (
            <>
              <img
                className="absolute z-20 landscape:block portrait:hidden object-cover h-full w-full"
                src={rectangularOverlayHorizontal}
              />
              <img
                className="absolute z-20 portrait:block landscape:hidden object-cover h-full w-full"
                src={rectangularOverlayVertical}
              />
            </>
          ) : (
            <div
              className="absolute top-0 left-0 w-full h-full z-20 opacity-60"
              style={{
                background:
                  'radial-gradient(circle at 50%, transparent, transparent 40%, #1A2128, #1A2128 40%)',
              }}
            />
          ))}

        <div
          className={classNames(
            'top-0 landscape:right-0 absolute z-30 portrait:w-full h-full flex landscape:flex-col items-end landscape:items-center pb-20 landscape:py-44 px-28',
            image ? ' justify-between' : 'justify-center'
          )}
        >
          {!image ? (
            <button
              className={classNames(
                numberOfCameras === 0 && 'opacity-50',
                'transition-opacity'
              )}
              disabled={!isPlaywright && numberOfCameras === 0}
              onClick={handleTakePicture}
              aria-label={formatMessage(messages.takePicture)}
            >
              <span className="w-24 h-24 rounded-full flex border-solid justify-center bg-neutral-0 items-center border-4 border-neutral-300">
                <span className="block w-20 h-20 rounded-full border-solid border-2 border-neutral-400" />
              </span>
            </button>
          ) : (
            <>
              <Button
                className="btn-size-lg w-40"
                onClick={handleRemovePicture}
              >
                {formatMessage(messages.tryAgain)}
              </Button>
              <Button
                loading={isLoading}
                onClick={handleUpload}
                className="btn-size-lg w-40"
                emphasis="high"
              >
                {formatMessage(messages.useCurrent)}
              </Button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
