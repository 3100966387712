import { defineMessages } from 'react-intl';

export default defineMessages({
  clearFilters: {
    defaultMessage: 'Reset filters',
  },
  showVehicles: {
    defaultMessage: 'Show {vehiclesTotal} vehicles',
  },
  loading: {
    defaultMessage: 'Loading...',
  },
});
