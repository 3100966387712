import { FormattedDate, FormattedMessage } from 'react-intl';
import { Card, Icon, Placeholder } from '@cooltra/ui';
import { useParams } from 'react-router-dom';
import { useUserCurrentSubscriptionQuery } from '@cooltra/api';
import { RouterLink } from '@cooltra/navigation';
import { MdOutlineChevronRight } from 'react-icons/md';

import messages from './messages';

export const CurrentSubscription = () => {
  const { id = '' } = useParams<'id'>();

  const { data, isError } = useUserCurrentSubscriptionQuery(id);

  const getContent = () => {
    if (isError) {
      return null;
    }

    if (!data) {
      return <Placeholder className="w-20 h-3 mt-3" />;
    }

    return (
      <>
        <span className="text-3xl font-semibold capitalize block mb-2">
          {data.subscription}
        </span>
        <p className="text-xs text-neutral-1000">
          <FormattedMessage
            {...messages.validFrom}
            values={{
              date: (
                <span className="font-semibold">
                  <FormattedDate
                    value={new Date(data.validFrom)}
                    day="2-digit"
                    month="short"
                    year="numeric"
                  />
                </span>
              ),
            }}
          />
        </p>
      </>
    );
  };

  return (
    <Card
      className="p-6 flex flex-col h-full"
      data-testid="CURRENT_USER_SUBSCRIPTION"
    >
      <RouterLink
        to="../billing/subscriptions"
        className="flex justify-between text-xs mb-3"
      >
        <FormattedMessage {...messages.subscription} />
        <Icon className="pl-1 text-base">
          <MdOutlineChevronRight />
        </Icon>
      </RouterLink>
      <div>{getContent()}</div>
    </Card>
  );
};
