import { useIntl } from 'react-intl';
import { RouterNavLink } from '@cooltra/navigation';
import { useAuth0 } from '@auth0/auth0-react';

import messages from '../messages';

export const Account = () => {
  const { user } = useAuth0();
  const { formatMessage } = useIntl();

  return (
    <li>
      <RouterNavLink
        end
        to="/account"
        aria-label={formatMessage(messages.home)}
      >
        <span className="w-7 h-7 overflow-hidden rounded-full">
          <img src={user?.picture} alt={user?.name} className="max-w-full" />
        </span>
      </RouterNavLink>
    </li>
  );
};
