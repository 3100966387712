import { FormInputField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const Discount = () => (
  <FormInputField
    name="discount"
    id="discount"
    label={
      <InputLabel htmlFor="discount">
        <FormattedMessage {...messages.discount} />
      </InputLabel>
    }
  />
);
