import { defineMessages } from 'react-intl';
import { ProductCategory, AlgorithmPrice } from '@cooltra/station-based-api';

export default defineMessages({
  startDate: {
    defaultMessage: 'Start date',
  },
  endDate: {
    defaultMessage: 'End date',
  },
  placeholder: {
    defaultMessage: 'dd-mm',
  },
  algorithmForDiscount: {
    defaultMessage: 'Algorithm for discount',
  },
  price: {
    defaultMessage: 'Price',
  },
});

export const algorithmMessages = defineMessages<AlgorithmPrice>({
  AGGRESSIVE: {
    defaultMessage: 'Aggressive',
  },
  BASE: {
    defaultMessage: 'Base',
  },
  MODERATE: {
    defaultMessage: 'Moderate',
  },
  LINEAR: {
    defaultMessage: 'Linear',
  },
  FIXED: {
    defaultMessage: 'Fixed',
  },
  EXTRA: {
    defaultMessage: 'Extra',
  },
});

export const productCategoryTypeMessages = defineMessages<ProductCategory>({
  EBIKE: {
    defaultMessage: 'Ebike',
  },
  STANDARD: {
    defaultMessage: 'Standard',
  },
  PLUS: {
    defaultMessage: 'Plus',
  },
  PREMIUM: {
    defaultMessage: 'Premium',
  },
  SUPER: {
    defaultMessage: 'Super premium',
  },
});
