import { defineMessages } from 'react-intl';

export default defineMessages({
  refundItem: {
    defaultMessage: 'Refund item',
  },
  areYouSureToRefund: {
    defaultMessage: 'Are you sure you want to refund this item?',
  },
  refund: {
    defaultMessage: 'Refund',
  },
  refunded: {
    defaultMessage: 'Refunded',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
});
