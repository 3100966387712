import { useMarkInvoiceAsPaidMutation } from '@cooltra/api';
import {
  Form,
  FormButton,
  FormHelpers,
  FormInputField,
  FormProvider,
} from '@cooltra/form';
import { Button, InputLabel, Modal } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';

import { useNotification } from '~/hooks';
import { validateRequired } from '~/validation';

import messages from './messages';

type FormValues = {
  comment: string;
};

const intialValues: FormValues = {
  comment: '',
};

export type MarkInvoiceAsPaidModalProps = {
  invoiceId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const MarkInvoiceAsPaidModal = ({
  invoiceId,
  isOpen,
  onClose,
}: MarkInvoiceAsPaidModalProps) => {
  const intl = useIntl();
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const { mutateAsync } = useMarkInvoiceAsPaidMutation(invoiceId);

  const onSubmit = (
    values: FormValues,
    { resetForm }: FormHelpers<FormValues>
  ) =>
    mutateAsync(values)
      .then(() => {
        resetForm(intialValues);
        addSuccessNotification(intl.formatMessage(messages.success));
        onClose();
      })
      .catch(() => addErrorNotification());

  return (
    <Modal
      className="max-w-lg min-w-md"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div data-testid="MARK_INVOICE_AS_PAID_MODAL" className="pb-12 px-12">
        <h6 className="text-neutral-400 text-lg mb-6">
          <FormattedMessage {...messages.markInvoiceAsPaid} />
        </h6>
        <FormProvider
          initialValues={intialValues}
          onSubmit={onSubmit}
          validate={({ comment }) => {
            const commentError = validateRequired(intl, comment);
            return commentError ? { comment: commentError } : {};
          }}
        >
          <Form>
            <FormInputField
              name="comment"
              id="comment"
              label={
                <InputLabel htmlFor="comment" className="mb-1">
                  <FormattedMessage {...messages.comment} />
                </InputLabel>
              }
            />
            <div className="flex mt-6 gap-4">
              <Button type="button" className="w-full" onClick={onClose}>
                <FormattedMessage {...messages.cancel} />
              </Button>
              <FormButton className="w-full" emphasis="high">
                <FormattedMessage {...messages.save} />
              </FormButton>
            </div>
          </Form>
        </FormProvider>
      </div>
    </Modal>
  );
};
