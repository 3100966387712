import { StationBasedLanguageCode } from '@cooltra/countries';
import { PricingGroup, PromotionTarget } from '@cooltra/station-based-api';

import { today } from '~/utils/date';
import { StationBasedStateType } from '~/common';

type TitleTranslationKey = `titleTranslations.${StationBasedLanguageCode}`;
type TitleTranslations = Record<TitleTranslationKey, string>;

export type CouponType = 'CREDIT' | 'DISCOUNT';
export type VehicleTypeOptions = 'VEHICLE_TYPES_YES' | 'VEHICLE_TYPES_NO';
export type AccessoriesOptions = 'ACCESSORIES_YES' | 'ACCESSORIES_NO';
export type InsurancesOptions = 'INSURANCES_YES' | 'INSURANCES_NO';
export type EnableGenericAppliesTo =
  | 'enableVehicleTypes'
  | 'enableAccessories'
  | 'enableInsurances';

export type StationBasedFormValues = {
  promotionTarget: PromotionTarget;
  couponType: CouponType | '';
  discount: number | '';
  about: string;
  validFromDate: string;
  validFromTime: string;
  validUntilDate: string;
  validUntilTime: string;
  editMode: boolean;
  pricingGroups: PricingGroup[];
  stateType: StationBasedStateType | null;
  enableVehicleTypes: VehicleTypeOptions;
  vehicleTypes: string[];
  enableAccessories: AccessoriesOptions;
  accessories: string[];
  enableInsurances: InsurancesOptions;
  enableGenericAppliesTo: EnableGenericAppliesTo;
  insurances: string[];
  code: string | '';
  'credit.value': number | '';
} & TitleTranslations;

export const stationBasedInitialFormValues: StationBasedFormValues = {
  promotionTarget: 'COUPON',
  couponType: 'CREDIT',
  discount: '',
  about: '',
  validFromDate: today,
  validFromTime: '00:00',
  validUntilDate: '',
  validUntilTime: '23:59',
  'titleTranslations.en': '',
  'titleTranslations.es': '',
  'titleTranslations.it': '',
  'titleTranslations.pt': '',
  'titleTranslations.fr': '',
  'titleTranslations.ca': '',
  'titleTranslations.de': '',
  editMode: false,
  stateType: null,
  pricingGroups: [],
  enableVehicleTypes: 'VEHICLE_TYPES_NO',
  vehicleTypes: [],
  enableAccessories: 'ACCESSORIES_NO',
  accessories: [],
  enableInsurances: 'INSURANCES_NO',
  insurances: [],
  enableGenericAppliesTo: 'enableVehicleTypes',
  'credit.value': '',
  code: '',
};
