import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { DiscountRowLayout } from '../DiscountRowLayout';

import messages from './messages';

export const DiscountsListHeader = () => (
  <DiscountRowLayout
    code={
      <ListColumnLabel>
        <FormattedMessage {...messages.code} />
      </ListColumnLabel>
    }
    discountPercentage={
      <ListColumnLabel>
        <FormattedMessage {...messages.discountPercentage} />
      </ListColumnLabel>
    }
    offer={
      <ListColumnLabel>
        <FormattedMessage {...messages.offer} />
      </ListColumnLabel>
    }
    redeemedAt={
      <ListColumnLabel>
        <FormattedMessage {...messages.redeemedAt} />
      </ListColumnLabel>
    }
    state={
      <ListColumnLabel>
        <FormattedMessage {...messages.status} />
      </ListColumnLabel>
    }
    validFrom={
      <ListColumnLabel>
        <FormattedMessage {...messages.validFrom} />
      </ListColumnLabel>
    }
    validUntil={
      <ListColumnLabel>
        <FormattedMessage {...messages.validUntil} />
      </ListColumnLabel>
    }
  />
);
