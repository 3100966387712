import { defineMessages } from 'react-intl';

export default defineMessages({
  invoiceAll: {
    defaultMessage: 'Invoice all',
  },
  modalTitle: {
    defaultMessage: 'Invoice all uninvoiced charges',
  },
  modalContent: {
    defaultMessage: 'Are you sure you want to invoice all uninvoiced charges?',
  },
});
