import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type SubscriptionRowLayoutProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'title'
> & {
  name: ReactNode;
  validFrom: ReactNode;
  createdBy: ReactNode;
  state: ReactNode;
};

export const SubscriptionRowLayout = ({
  validFrom,
  name,
  createdBy,
  state,
  className,
  ...rest
}: SubscriptionRowLayoutProps) => (
  <div
    className={classNames('pl-6 pr-4 flex items-center gap-4', className)}
    {...rest}
  >
    <div className="flex-1 shrink-0">{name}</div>
    <div className="w-56 shrink-0">{validFrom}</div>
    <div className="w-52 shrink-0 flex justify-center">{createdBy}</div>
    <div className="w-24 shrink-0 flex justify-center">{state}</div>
  </div>
);
