import { ReactNode } from 'react';

export type CurrentReservationLayoutProps = {
  timeline: ReactNode;
  vehicle: ReactNode;
  system: ReactNode;
  checkIn: ReactNode;
  problemReports: ReactNode;
  state: ReactNode;
};

export const CurrentReservationLayout = ({
  timeline,
  vehicle,
  system,
  checkIn,
  problemReports,
  state,
}: CurrentReservationLayoutProps) => (
  <div className="w-full flex gap-8 px-6 relative">
    <div>{timeline}</div>
    <div className="flex-1">{vehicle}</div>
    <div>{system}</div>
    <div className="w-24 text-center">{checkIn}</div>
    <div className="w-28 text-center">{problemReports}</div>
    <div className="w-32 text-center">{state}</div>
  </div>
);
