import { Route, Routes } from 'react-router-dom';

import { SidebarLayout } from '~/common';

import { Sidebar } from './Sidebar';
import { PriceListsRoutes } from './PriceListsRoutes';
import { PriceListTypeContext } from './PriceListTypeContext';
import { DepositsPriceList } from './DepositsPriceList/DepositsPriceList';
import { DepositsPriceListDetails } from './DepositsPriceListDetails/DepositsPriceListDetails';

export const PriceLists = () => (
  <SidebarLayout sidebar={<Sidebar />}>
    <Routes>
      <Route path="/deposits/*" element={<DepositsPriceList />} />
      <Route
        path="/deposits/:insuranceId/*"
        element={<DepositsPriceListDetails />}
      />
      <Route
        path="/web/*"
        element={
          <PriceListTypeContext.Provider value={{ type: 'WEB' }}>
            <PriceListsRoutes />
          </PriceListTypeContext.Provider>
        }
      />
      <Route
        path="/pos/*"
        element={
          <PriceListTypeContext.Provider value={{ type: 'POS' }}>
            <PriceListsRoutes />
          </PriceListTypeContext.Provider>
        }
      />
    </Routes>
  </SidebarLayout>
);
