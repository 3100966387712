import { Card } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { UserSubscriptionListItem } from '@cooltra/api';

import { DateTime, RoundOperatorInitials } from '~/common';

import { SubscriptionRowLayout } from '../SubscriptionRowLayout';
import { SubscriptionState } from '../SubscriptionState/SubscriptionState';

export type SubscriptionCardProps = {
  subscription: UserSubscriptionListItem;
};

export const SubscriptionCard = ({
  subscription: { name, validFrom, createdBy, state },
}: SubscriptionCardProps) => (
  <Card
    className={classNames(
      'text-sm text-neutral-800',
      state === 'EXPIRED' && 'opacity-50'
    )}
  >
    <SubscriptionRowLayout
      className="h-16"
      name={<span className="capitalize">{name}</span>}
      validFrom={<DateTime date={validFrom} />}
      createdBy={createdBy && <RoundOperatorInitials operatorId={createdBy} />}
      state={<SubscriptionState state={state} />}
    />
  </Card>
);
