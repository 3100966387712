import { defineMessages } from 'react-intl';

export default defineMessages({
  remainingDays: {
    defaultMessage: 'In {total, plural, one {# day} other {# days}}',
  },
  remainingMonths: {
    defaultMessage: 'In {total, plural, one {# month} other {# months}}',
  },
  daysOverdue: {
    defaultMessage: '{total, plural, one {# day} other {# days}} overdue',
  },
  monthsOverdue: {
    defaultMessage: '{total, plural, one {# month} other {# months}} overdue',
  },
  today: {
    defaultMessage: 'Today',
  },
});
