import { defineMessages } from 'react-intl';

export default defineMessages({
  purchasedOn: {
    defaultMessage: 'Purchased on',
  },
  credit: {
    defaultMessage: 'Credit',
  },
});
