import { InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormMultiSelectField } from '@cooltra/form';

import { useParkingReviewOptions } from '../../../Vehicle';

import messages from './messages';

export const ParkingReview = () => {
  const { formatMessage } = useIntl();
  const parkingReviewOptions = useParkingReviewOptions();
  return (
    <FormMultiSelectField
      name="parkingReview"
      id="parkingReview"
      label={
        <InputLabel htmlFor="parkingReview">
          <FormattedMessage {...messages.parkingReview} />
        </InputLabel>
      }
      placeholder={formatMessage(messages.placeholder)}
      options={parkingReviewOptions}
    />
  );
};
