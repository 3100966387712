import { defineMessages } from 'react-intl';

export default defineMessages({
  bonusZone: {
    defaultMessage: 'Bonus zone',
  },
  back: {
    defaultMessage: 'Back',
  },
  createBonusZone: {
    defaultMessage: 'Create bonus zone',
  },
  success: {
    defaultMessage: 'You have successfully created a new bonus zone!',
  },
});
