import { FormattedMessage } from 'react-intl';
import { MdOutlineLock } from 'react-icons/md';

import { CustomIcon } from '~/common';

import messages from './messages';
import { StatusContainer } from './StatusContainer';

type LockProps = {
  lock?: boolean | undefined;
};

export const Lock = ({ lock }: LockProps) => {
  const getContent = () => {
    if (lock === undefined) {
      return <></>;
    }

    if (lock) {
      return (
        <>
          <MdOutlineLock className="w-auto h-4 text-success-600 shrink-0" />
          <FormattedMessage {...messages.locked} />
        </>
      );
    }

    return (
      <>
        <CustomIcon.LockOpen className="w-auto h-4 text-neutral-200 shrink-0" />
        <span className="text-neutral-300">
          <FormattedMessage {...messages.unlocked} />
        </span>
      </>
    );
  };

  return <StatusContainer>{getContent()}</StatusContainer>;
};
