import { RouterLink } from '@cooltra/navigation';
import { shortenId } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';

import { Pair } from '../../Pair';

import messages from './messages';

export type ProblemReportPairProps = {
  problemReportId?: string;
};

export const ProblemReportPair = ({
  problemReportId,
}: ProblemReportPairProps) => (
  <div>
    <Pair.Label>
      <FormattedMessage {...messages.problemReport} />
    </Pair.Label>
    {problemReportId ? (
      <Pair.Value>
        <RouterLink
          to={`/problem-reports/${problemReportId}`}
          className="text-sm"
        >
          #{shortenId(problemReportId)}
        </RouterLink>
      </Pair.Value>
    ) : (
      <Pair.ValueLoading />
    )}
  </div>
);
