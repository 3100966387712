import { FormProvider, Form, FormProviderProps } from '@cooltra/form';
import { useIntl } from 'react-intl';
import { useRentalPassesQuery } from '@cooltra/api';

import { PassFormValues } from './pass-form';
import { validatePassForm } from './pass-form-validation';

export type PassFormProps = Omit<FormProviderProps<PassFormValues>, 'validate'>;

export const PassForm = ({
  onSubmit,
  initialValues,
  children,
  disabled,
  ...rest
}: PassFormProps) => {
  const intl = useIntl();

  const { data, isLoading } = useRentalPassesQuery();
  const codes = data?.rentalPasses.map(({ code }) => code) || [];

  return (
    <FormProvider
      validate={validatePassForm(intl, codes)}
      initialValues={initialValues}
      onSubmit={onSubmit}
      disabled={isLoading || disabled}
      {...rest}
    >
      <Form>{children}</Form>
    </FormProvider>
  );
};
