import { defineMessages } from 'react-intl';

export default defineMessages({
  subscription: {
    defaultMessage: 'Subscription',
  },
  validFrom: {
    defaultMessage: 'Valid from {date}',
  },
});
