import { IntlShape } from 'react-intl';

import { createSetError, validateDate, validateRequired } from '~/validation';

import { PriceListFormValues } from './vehicle-type-price-list-details-form';

type PriceListFormFieldName = keyof PriceListFormValues;

type PriceListFormErrors = Partial<
  Record<PriceListFormFieldName, string | undefined>
>;

const LEAP_YEAR = 2024;

export const validateForm =
  (intl: IntlShape) => (values: PriceListFormValues) => {
    const errors: PriceListFormErrors = {};
    const setError = createSetError(errors);

    setError('dateFrom', validateRequired(intl, values.dateFrom));

    if (!validateRequired(intl, values.dateFrom)) {
      setError(
        'dateFrom',
        validateDate(intl, `${values.dateFrom}-${LEAP_YEAR}`)
      );
    }

    return errors;
  };
