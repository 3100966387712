import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';
import { useLocation } from 'react-router-dom';
import { getParams } from '@cooltra/utils';

import { AlarmsFiltersFormValues } from './alarms-filters-form';

export const AlarmsFiltersForm = ({ children }: PropsWithChildren) => {
  const { search } = useLocation();

  const params = getParams<'sort'>(search);

  const initialValues = {
    sort: params.string('sort') || 'updatedAt.desc',
  } as AlarmsFiltersFormValues;

  return <FormProvider initialValues={initialValues}>{children}</FormProvider>;
};
