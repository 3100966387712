import { Routes, Route } from 'react-router-dom';

import { Four0Four, PermissionCheck } from '~/common';

import { CreateLocation } from '../CreateLocation/CreateLocation';
import { EditLocationForm } from '../EditLocation';
import { LocationsList } from '../LocationsList/LocationsList';

export const Locations = () => (
  <Routes>
    <Route path="/" element={<LocationsList />} />
    <Route
      path="new"
      element={
        <PermissionCheck permission="write:locations">
          <CreateLocation />
        </PermissionCheck>
      }
    />
    <Route
      path="/:locationId/*"
      element={
        <PermissionCheck permission="write:locations">
          <EditLocationForm />
        </PermissionCheck>
      }
    />
    <Route path="*" element={<Four0Four />} />
  </Routes>
);
