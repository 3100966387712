import { FormattedMessage } from 'react-intl';
import { FormTextareaField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';

import messages from './messages';

export const Polygon = () => (
  <FormTextareaField
    name="polygon"
    id="polygon"
    rows={6}
    label={
      <InputLabel htmlFor="polygon">
        <FormattedMessage {...messages.geoJson} />
      </InputLabel>
    }
  />
);
