import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { SubscriptionRowLayout } from '../SubscriptionRowLayout';

import messages from './messages';

export const SubscriptionsListHeader = () => (
  <SubscriptionRowLayout
    name={
      <ListColumnLabel>
        <FormattedMessage {...messages.name} />
      </ListColumnLabel>
    }
    validFrom={
      <ListColumnLabel>
        <FormattedMessage {...messages.validFrom} />
      </ListColumnLabel>
    }
    createdBy={
      <ListColumnLabel>
        <FormattedMessage {...messages.createdBy} />
      </ListColumnLabel>
    }
    state={
      <ListColumnLabel>
        <FormattedMessage {...messages.state} />
      </ListColumnLabel>
    }
  />
);
