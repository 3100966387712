import { IntlShape } from 'react-intl';
import { FormErrors } from '@cooltra/form';

import { validateRequired } from '~/validation';

export type SuspendFormValues = {
  comment: string;
};

export const suspendFormInitialValues: SuspendFormValues = {
  comment: '',
};

export const validateSuspendForm =
  (intl: IntlShape) =>
  (values: SuspendFormValues): FormErrors<SuspendFormValues> => {
    const errors: FormErrors<SuspendFormValues> = {};
    const requiredErrorMessage = validateRequired(intl, values.comment);
    if (requiredErrorMessage) {
      errors.comment = requiredErrorMessage;
    }
    return errors;
  };
