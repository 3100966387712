import { VehicleListItem, useVehiclesQuery } from '@cooltra/station-based-api';
import { getParams } from '@cooltra/utils';
import { useFormContext } from '@cooltra/form';
import { InfiniteList } from '@cooltra/ui';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ErrorPage, NoSearchResults } from '~/common';

import { VehiclesLayout } from '../VehiclesLayout/VehiclesLayout';

import { VehicleCard } from './VehicleCard/VehicleCard';
import { VehicleLoadingCard } from './VehicleCard/VehicleLoadingCard';
import { VehiclesListHeader } from './VehiclesListHeader/VehiclesListHeader';
import { VehiclesInputSearch } from './VehiclesInputSearch/VehiclesInputSearch';
import { VehiclesFiltersFormValues } from './VehiclesFilters/vehicles-filter-form';
import { VehicleFilters } from './VehiclesFilters/VehicleFilters';

const pageSize = 20;

export const VehiclesList = () => {
  const { search } = useLocation();
  const licensePlateKeyword = getParams(search).string('keyword') || '';
  const { values: vehiclesFiltersFormValues } =
    useFormContext<VehiclesFiltersFormValues>();
  const [page, setPage] = useState(1);

  const fetchMore = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
  }, []);

  const {
    data: vehicles = [],
    isLoading,
    isError,
  } = useVehiclesQuery(
    {
      servicePointId: vehiclesFiltersFormValues.servicePointId,
    },
    { retry: false }
  );

  if (isError) {
    return <ErrorPage />;
  }

  const data = vehicles
    .filter((vehicle) =>
      vehicle.licensePlate.includes(licensePlateKeyword.toUpperCase())
    )
    .slice(0, pageSize * page);

  return (
    <VehiclesLayout
      renderNavbar={<VehiclesInputSearch />}
      renderContent={
        <div className="mx-auto max-w-4xl">
          <VehicleFilters />
          <InfiniteList
            isFetchingNextPage={false}
            fetchMore={fetchMore}
            loadingRows={6}
            isLoading={isLoading}
            idProp="vehicleId"
            scrollableTarget="scrollable-container"
            data={data}
            renderEmptyMessage={<NoSearchResults />}
            renderHeader={<VehiclesListHeader />}
            renderLoadingRow={<VehicleLoadingCard />}
            renderRow={(vehicle: VehicleListItem) => (
              <VehicleCard vehicle={vehicle} />
            )}
          />
        </div>
      }
    />
  );
};
