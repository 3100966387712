import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';
import { useLocation } from 'react-router-dom';
import { getParams } from '@cooltra/utils';

import { PacksFiltersFormValues, initialValues } from './packs-filters-form';

const { state, keyword, sort } = initialValues;

export const PacksFiltersForm = ({ children }: PropsWithChildren) => {
  const { search } = useLocation();
  const params = getParams<keyof PacksFiltersFormValues>(search);

  const initialValues = {
    state: params.string('state') || state,
    keyword: params.string('keyword') || keyword,
    sort: params.string('sort') || sort,
  } as PacksFiltersFormValues;

  return <FormProvider initialValues={initialValues}>{children}</FormProvider>;
};
