import { FormattedMessage } from 'react-intl';
import { FormInputField, useFormContext } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';

import { StationBasedFormValues } from '../../StationBasedForm';
import { StationBasedStateType } from '../../StationBasedState/StationBasedState';

import messages from './messages';

export const Code = () => {
  const { values } = useFormContext<StationBasedFormValues>();
  const { editMode, stateType } = values;
  const editingNonScheduledPromotion =
    editMode && stateType !== StationBasedStateType.SCHEDULED;

  return (
    <FormInputField
      name="code"
      id="code"
      disabled={editingNonScheduledPromotion}
      placeholder="SUMMER23"
      label={
        <InputLabel htmlFor="code">
          <FormattedMessage {...messages.code} />
        </InputLabel>
      }
    />
  );
};
