import { InputLabel, InputLabelProps } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const OptionalFieldLabel = ({ children, ...rest }: InputLabelProps) => (
  <InputLabel {...rest}>
    <span>{children} </span>
    <span className="font-normal">
      (<FormattedMessage {...messages.optionalFieldLabel} />)
    </span>
  </InputLabel>
);
