import { FormattedMessage } from 'react-intl';
import { InputLabel } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';

import { FormNumberInputField } from '../../../../FormattedInput';
import { StationBasedFormValues } from '../../StationBasedForm';
import { StationBasedStateType } from '../../StationBasedState/StationBasedState';

import messages from './messages';

export const Discount = () => {
  const { values } = useFormContext<StationBasedFormValues>();

  const { editMode, stateType } = values;
  const editingNoSchedulePromotion =
    editMode && stateType !== StationBasedStateType.SCHEDULED;

  return (
    <>
      <FormNumberInputField
        name="discount"
        id="discount-percentage"
        className="w-44"
        disabled={editingNoSchedulePromotion}
        placeholder="10%"
        label={
          <InputLabel htmlFor="discount-percentage">
            <FormattedMessage {...messages.percentage} />
          </InputLabel>
        }
        maxLength={3}
      />
    </>
  );
};
