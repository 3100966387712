import { FormattedMessage } from 'react-intl';
import {
  System,
  operatorGroups,
  useOperatorsQuery,
  useAuthClaimsQuery,
} from '@cooltra/auth-api';
import { FormSelectField, useField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';

import { getEnvVariable } from '~/utils/environment';
import {
  canAccessHomeSystem,
  isBlocked,
  isOperatorGroup,
} from '~/utils/operators';

import messages from './messages';

const shiftOperatorGroups =
  getEnvVariable('ENVIRONMENT') === 'production'
    ? [
        operatorGroups.prod.JOCKEY.operatorGroupId,
        operatorGroups.prod.JOCKEY_EXT.operatorGroupId,
        operatorGroups.prod.RECOLLECTOR.operatorGroupId,
        operatorGroups.prod.SWAPPER.operatorGroupId,
        operatorGroups.prod.SWAPPER_EXT.operatorGroupId,
        operatorGroups.prod.SHIFT_MANAGER.operatorGroupId,
      ]
    : [
        operatorGroups.stage.JOCKEY.operatorGroupId,
        operatorGroups.stage.JOCKEY_EXT.operatorGroupId,
        operatorGroups.stage.RECOLLECTOR.operatorGroupId,
        operatorGroups.stage.SWAPPER.operatorGroupId,
        operatorGroups.stage.SWAPPER_EXT.operatorGroupId,
        operatorGroups.stage.SHIFT_MANAGER.operatorGroupId,
      ];

export const Operator = () => {
  const { data: claims } = useAuthClaimsQuery();
  const accessibleSystems = claims?.systems || [];

  const { data = [], isLoading } = useOperatorsQuery();
  const { value: systems } = useField<System[]>('system');

  return (
    <FormSelectField
      isLoading={isLoading}
      noOptionsMessage={() => <FormattedMessage {...messages.noOperators} />}
      id="shift-operator"
      name="operatorId"
      isClearable
      options={data
        .filter(isBlocked(false))
        .filter(isOperatorGroup(shiftOperatorGroups))
        .filter(
          canAccessHomeSystem(systems.length ? systems : accessibleSystems)
        )
        .sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
        .map(({ externalId, name }) => ({
          label: name,
          value: externalId,
        }))}
      label={
        <InputLabel htmlFor="shift-operator">
          <FormattedMessage {...messages.operator} />
        </InputLabel>
      }
    />
  );
};
