import { Button, Modal, ModalProps } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type SecondDriverWarningModalProps = ModalProps & {
  onConfirm: VoidFunction;
  isPending: boolean;
};

export const SecondDriverWarningModal = ({
  isOpen,
  onRequestClose,
  isPending,
  onConfirm,
}: SecondDriverWarningModalProps) => {
  const handleOnConfirm = (e: React.MouseEvent<Element, MouseEvent>) => {
    onConfirm();
    onRequestClose && onRequestClose(e);
  };

  return (
    <Modal
      className="max-w-sm min-w-xs"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <div className="text-center pb-8 px-8">
        <h6 className="text-neutral-700 text-lg font-semibold mb-2">
          <FormattedMessage {...messages.confirmation} />
        </h6>
        <p className="text-neutral-600 text-sm mb-8">
          <FormattedMessage {...messages.disclaimer} />
        </p>
        <div className="flex justify-center gap-4">
          <Button onClick={onRequestClose}>
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button loading={isPending} emphasis="high" onClick={handleOnConfirm}>
            <FormattedMessage {...messages.confirm} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
