import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  LockNextAwaitingReviewUserPayload,
  useLockNextAwaitingReviewUserMutation,
} from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { TopLoadingBar } from '@cooltra/ui';
import { getParams } from '@cooltra/utils';
import { useQueryClient } from '@tanstack/react-query';

import { Four0Three, LastReviews, ReviewForm } from '~/common';
import { useNotification } from '~/hooks';

import messages from './messages';

export const ContinuousReview = () => {
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const params = getParams<'system'>(search);
  const lockUserFromSystem = params.string('system');
  const lockNextAwaitingReviewUserPayload:
    | LockNextAwaitingReviewUserPayload
    | object = useMemo(
    () => (lockUserFromSystem ? { system: lockUserFromSystem } : {}),
    [lockUserFromSystem]
  );
  const { hasPermission } = useAuthClaimsQuery();
  const hasWriteUsersPermission = hasPermission('write:users');

  const { data, mutate, error } = useLockNextAwaitingReviewUserMutation();
  const { formatMessage } = useIntl();
  const { addSuccessNotification } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasWriteUsersPermission) {
      mutate(lockNextAwaitingReviewUserPayload);
    }
  }, [hasWriteUsersPermission, lockNextAwaitingReviewUserPayload, mutate]);

  if (!hasWriteUsersPermission || getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (getErrorStatus(error) === 404) {
    addSuccessNotification(formatMessage(messages.allUsersReviewed));
    navigate('/');
    return <></>;
  }

  if (!data) {
    return <TopLoadingBar />;
  }

  const onFinish = async () => {
    if (hasWriteUsersPermission) {
      await queryClient.invalidateQueries({
        queryKey: ['latest-approval-reviews'],
      });
      mutate(lockNextAwaitingReviewUserPayload);
    }
  };

  return (
    <>
      <ReviewForm userId={data.userId} onFinish={onFinish} />
      <LastReviews />
    </>
  );
};
