import { Card, Divider, InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormInputField, FormTextareaField } from '@cooltra/form';

import {
  FormAmountInputField,
  FormNumberInputField,
  Translations,
} from '~/common';

import { ValidityDates } from '../ValidityDates/ValidityDates';

import messages from './messages';

export const PassFormFields = () => {
  const { formatMessage } = useIntl();
  return (
    <Card className="w-full mb-5">
      <div className="p-10">
        <h2 className="text-xl text-neutral-400 mb-6">
          <FormattedMessage {...messages.passOverview} />
        </h2>
        <div className="grid grid-cols-3 gap-x-5">
          <FormInputField
            name="code"
            id="code"
            label={
              <InputLabel htmlFor="code">
                <FormattedMessage {...messages.code} />
              </InputLabel>
            }
          />
          <FormAmountInputField
            name="price"
            id="price"
            placeholder="12.50"
            label={
              <InputLabel htmlFor="price">
                <FormattedMessage {...messages.price} />
              </InputLabel>
            }
          />
        </div>
      </div>
      <Divider />
      <div className="p-10">
        <h2 className="text-xl text-neutral-400 mb-6">
          <FormattedMessage {...messages.validity} />
        </h2>
        <div className="flex flex-col gap-y-8">
          <ValidityDates />
          <FormNumberInputField
            className="w-56"
            name="benefitExpiresAfterHours"
            id="benefit-expires-after-hours"
            label={
              <InputLabel htmlFor="benefit-expires-after-hours">
                <FormattedMessage {...messages.benefitExpiresAfterHours} />
              </InputLabel>
            }
          />
        </div>
      </div>
      <Divider />
      <div className="p-10">
        <h2 className="text-xl text-neutral-400 mb-6">
          <FormattedMessage {...messages.targetGroup} />
        </h2>
        <div className="flex flex-col gap-y-8">
          <FormTextareaField
            name="targetUserIds"
            id="target-user-ids"
            placeholder={formatMessage(messages.targetUserIdsPlaceholder)}
            rows={5}
            className="w-96"
            label={
              <InputLabel htmlFor="target-user-ids">
                <FormattedMessage {...messages.targetUserIds} />
              </InputLabel>
            }
          />
        </div>
      </div>
      <Translations includeDescription={false} />
    </Card>
  );
};
