import { defineMessages } from 'react-intl';

export default defineMessages({
  code: {
    defaultMessage: 'Code',
  },
  price: {
    defaultMessage: 'Price',
  },
  credit: {
    defaultMessage: 'Credit',
  },
  validFrom: {
    defaultMessage: 'Purchasable from',
  },
  validUntil: {
    defaultMessage: 'Purchasable until',
  },
  state: {
    defaultMessage: 'State',
  },
});
