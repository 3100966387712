import { defineMessages } from 'react-intl';
import { InvoiceCategory } from '@cooltra/api';

export default defineMessages({
  label: {
    defaultMessage: 'Category',
  },
});

export const categories = defineMessages<InvoiceCategory>({
  ADMIN_COSTS: {
    defaultMessage: 'Management / Admin costs',
  },
  CRANE: {
    defaultMessage: 'Crane',
  },
  DAMAGE: {
    defaultMessage: 'Damage',
  },
  PENALTIES: {
    defaultMessage: 'Penalties / Breach of T&Cs',
  },
  RENTAL: {
    defaultMessage: 'Rental',
  },
  CLAIM: {
    defaultMessage: 'Non-communication of accident / claim',
  },
  FINES: {
    defaultMessage: 'Fines',
  },
  ADMIN_COSTS_CRANE: {
    defaultMessage: 'Management / Admin costs + Crane',
  },
});
