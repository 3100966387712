import { useIntl } from 'react-intl';

import { SortField, SortFieldProps } from '~/common';

import messages from './messages';

const options = [
  {
    label: messages.pickUpAscending,
    value: 'pickUp.asc',
  },
  {
    label: messages.pickUpDescending,
    value: 'pickUp.desc',
  },
];

type SortProps = Pick<SortFieldProps, 'onChange'>;

export const Sort = ({ onChange }: SortProps) => {
  const { formatMessage } = useIntl();

  const translatedOptions = options.map(({ label, value }) => ({
    label: formatMessage(label),
    value,
  }));

  return <SortField options={translatedOptions} onChange={onChange} />;
};
