import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type PromotionRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  promotion: ReactNode;
  type: ReactNode;
  validity: ReactNode;
  state: ReactNode;
};

export const StationBasedPromotionRowLayout = ({
  promotion,
  type,
  validity,
  state,
  className,
  ...rest
}: PromotionRowLayoutProps) => (
  <div className={classNames('flex h-full w-full px-8', className)} {...rest}>
    <div className="flex items-center w-full gap-4">
      <div className="flex-1 shrink-0">{promotion}</div>
      <div className="w-64 shrink-0">{type}</div>
      <div className="w-80 shrink-0">{validity}</div>
      <div className="w-24 flex justify-center shrink-0">{state}</div>
    </div>
  </div>
);
