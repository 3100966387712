import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type PassRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  code: ReactNode;
  price: ReactNode;
  validFrom: ReactNode | null;
  validUntil: ReactNode | null;
  state: ReactNode;
};

export const PassRowLayout = ({
  code,
  price,
  validFrom,
  validUntil,
  state,
  className,
  ...rest
}: PassRowLayoutProps) => (
  <div className={classNames('flex h-full w-full', className)} {...rest}>
    <div className="flex items-center w-full px-8 gap-4">
      <div className="w-32 flex-1">{code}</div>
      <div className="flex flex-1">{price}</div>
      <div className="w-32 flex-1">{validFrom}</div>
      <div className="w-32 flex-1">{validUntil}</div>
      <div className="w-24 flex justify-center shrink-0">{state}</div>
    </div>
  </div>
);
