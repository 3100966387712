import { FormattedMessage, useIntl } from 'react-intl';
import { usePromotionMutation } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { FormButton, FormHelpers } from '@cooltra/form';
import { useNavigate } from 'react-router-dom';

import { useNotification } from '~/hooks';
import {
  Four0Three,
  PromotionForm,
  PromotionFormValues,
  promotionInitialFormValues,
  RouterBackLink,
} from '~/common';

import { PromotionFormFields } from '../PromotionFormFields';

import { getCreatePromotionPayload } from './get-create-promotion-payload';
import messages from './messages';

export const CreatePromotion = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { hasPermission, data } = useAuthClaimsQuery();
  const { mutateAsync } = usePromotionMutation();
  const { addSuccessNotification, addErrorNotification } = useNotification();

  if (!hasPermission('write:offers')) {
    return <Four0Three />;
  }

  const onSubmitPromotionForm = (
    values: PromotionFormValues,
    { resetForm }: FormHelpers<PromotionFormValues>
  ) => {
    const payload = getCreatePromotionPayload(values);
    return mutateAsync({ ...payload, operatorGroupIds: data?.roles || [] })
      .then(({ promotionId }) => {
        resetForm(promotionInitialFormValues);
        addSuccessNotification(
          formatMessage(messages.youHaveSuccessfullyCreatedThePromotion)
        );
        navigate(`/offers/promotions/${promotionId}/edit`);
      })
      .catch(({ response }) => {
        switch (response.status) {
          case 422:
            addErrorNotification(formatMessage(messages.promoCodesDuplicated));
            break;
          case 400:
            addErrorNotification(formatMessage(messages.badFormattedFields));
            break;
          default:
            addErrorNotification();
            break;
        }
      });
  };

  return (
    <div
      className="container min-w-screen-sm max-w-3xl py-12"
      data-testid="CREATE_PROMOTION"
    >
      <div className="mb-9">
        <RouterBackLink to="/offers/promotions">
          <FormattedMessage {...messages.backToPromotions} />
        </RouterBackLink>
        <h1 className="text-3xl">
          <FormattedMessage {...messages.title} />
        </h1>
      </div>
      <PromotionForm
        initialValues={promotionInitialFormValues}
        onSubmit={onSubmitPromotionForm}
      >
        <PromotionFormFields />
        <div className="flex justify-end">
          <FormButton>
            <FormattedMessage {...messages.submitButton} />
          </FormButton>
        </div>
      </PromotionForm>
    </div>
  );
};
