import { Model, useVehicleStatusQuery } from '@cooltra/api';
import { Placeholder } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { BatteryState } from '~/common';

import messages from './messages';

export type BatteryListItemProps = {
  vehicleId: string;
  model: Model;
};

export const BatteryListItem = ({ vehicleId, model }: BatteryListItemProps) => {
  const { data: vehicleStatus, isLoading } = useVehicleStatusQuery(vehicleId, {
    enabled: Boolean(vehicleId),
    retry: false,
  });

  return (
    <li className="py-4 border-b border-solid border-neutral-100">
      <div className="flex justify-between items-center gap-4">
        <span className="text-sm text-neutral-400">
          <FormattedMessage {...messages.battery} />
        </span>
        {isLoading ? (
          <Placeholder className="w-32 h-3" />
        ) : (
          <BatteryState
            value={vehicleStatus?.batteryCharge?.value || 0}
            model={model}
          />
        )}
      </div>
    </li>
  );
};
