import { CardBrand } from '@cooltra/api';
import { defineMessages } from 'react-intl';

export default defineMessages<CardBrand>({
  amex: {
    defaultMessage: 'American Express',
  },
  diners: {
    defaultMessage: 'Diners Club',
  },
  discover: {
    defaultMessage: 'Discover card',
  },
  jcb: {
    defaultMessage: 'JCB card',
  },
  mastercard: {
    defaultMessage: 'Mastercard',
  },
  unionpay: {
    defaultMessage: 'Union Pay',
  },
  visa: {
    defaultMessage: 'Visa',
  },
  unknown: {
    defaultMessage: 'Unkown card',
  },
});
