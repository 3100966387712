import { Card, Divider, Placeholder } from '@cooltra/ui';

import { PriceListsByPricingGroupRowLayout } from '../PriceListsByPricingGroupRowLayout';

export const PriceListsByPricingGroupCardLoading = () => {
  return (
    <Card>
      <PriceListsByPricingGroupRowLayout
        className="min-h-20"
        appliesIn={
          <div className="flex items-center gap-2">
            <Placeholder className="h-2 w-12" />
            <div className="w-4">
              <Divider />
            </div>
            <Placeholder className="h-2 w-12" />
          </div>
        }
        eBike={<Placeholder className="h-2 w-20" />}
        standard={<Placeholder className="h-2 w-20" />}
        plus={<Placeholder className="h-2 w-20" />}
        premium={<Placeholder className="h-2 w-20" />}
        superPremium={<Placeholder className="h-2 w-20" />}
        exportPrices={<Placeholder className="h-2 w-4" />}
      />
    </Card>
  );
};
