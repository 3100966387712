import { differenceInDays } from 'date-fns';
import { consecutiveNumbers } from '@cooltra/utils';

export const exceedsMaxRentalDuration = (
  startDate: Date | undefined,
  endDate: Date | undefined
) => (startDate && endDate ? differenceInDays(endDate, startDate) <= 30 : true);

export const daySlots = consecutiveNumbers(24)
  .map((hour) =>
    ['00', '30'].map(
      (minutes) => `${hour.toString().padStart(2, '0')}:${minutes}`
    )
  )
  .flat();
