import { ReservationState } from '@cooltra/api';
import { defineMessages } from 'react-intl';

export default defineMessages({
  state: {
    defaultMessage: 'State',
  },
  placeholder: {
    defaultMessage: 'All',
  },
});

export const stateLabels = defineMessages<ReservationState>({
  ACTIVE: {
    defaultMessage: 'Active',
  },
  USED: {
    defaultMessage: 'Used',
  },
  FAILED: {
    defaultMessage: 'Failed',
  },
  CANCELED: {
    defaultMessage: 'Canceled',
  },
  EXPIRED: {
    defaultMessage: 'Expired',
  },
  CHECKING_IN: {
    defaultMessage: 'Checking-in',
  },
});
