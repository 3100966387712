import { useAddUserSubscriptionMutation } from '@cooltra/api';
import { useIntl } from 'react-intl';
import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';

import { useNotification } from '~/hooks';

import messages from './messages';
import {
  subscriptionInitialFormValues,
  validateSubscriptionForm,
} from './subscription-form';

export type SubscriptionFormProps = PropsWithChildren<{
  userId: string;
  onSuccess: () => void;
}>;

export const SubscriptionForm = ({
  userId,
  children,
  onSuccess,
}: SubscriptionFormProps) => {
  const intl = useIntl();
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const { mutateAsync } = useAddUserSubscriptionMutation(userId);

  return (
    <FormProvider
      initialValues={subscriptionInitialFormValues}
      validate={validateSubscriptionForm(intl)}
      onSubmit={(values, { resetForm }) =>
        mutateAsync(values)
          .then(() => {
            resetForm(subscriptionInitialFormValues);
            addSuccessNotification(
              intl.formatMessage(messages.subscriptionSuccessfullyAdded)
            );
            onSuccess();
          })
          .catch(() => addErrorNotification())
      }
    >
      {children}
    </FormProvider>
  );
};
