import { InputLabel, Select, SelectOption } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useField, useFormContext } from '@cooltra/form';

import messages from './messages';

export type SortFieldProps = {
  options: SelectOption[];
  onChange?: (option: string) => void;
};

export const SortField = ({ options, onChange }: SortFieldProps) => {
  const { isSubmitting, isFormDisabled } = useFormContext();
  const { value, setValue, setTouched } = useField('sort');

  return (
    <div className="flex items-center gap-4">
      <InputLabel htmlFor="sort" className="shrink-0">
        <FormattedMessage {...messages.sort} />
      </InputLabel>
      <Select
        id="sort"
        className="w-64"
        onChange={(val) => {
          setValue(val);
          onChange && onChange(val);
        }}
        onBlur={() => {
          setTouched(true);
        }}
        value={value}
        name="sort"
        disabled={isFormDisabled || isSubmitting}
        options={options}
      />
    </div>
  );
};
