import { FormattedMessage } from 'react-intl';
import { RouterNavLink } from '@cooltra/navigation';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import { usePreferredSystem } from '~/libs/preferred-system';

import messages from '../messages';

export const Zones = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { system } = usePreferredSystem();

  const isZonesTabDisplayed =
    hasPermission('read:bonus_zones') ||
    hasPermission('read:geofence_restrictions');

  if (!isZonesTabDisplayed) {
    return <></>;
  }

  return (
    <li>
      <RouterNavLink to={`/zones/${system}/restrictions`}>
        <FormattedMessage {...messages.zones} />
      </RouterNavLink>
    </li>
  );
};
