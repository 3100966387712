import { Contract } from '@cooltra/station-based-api';
import { getVehicleName, vehicleImages } from '@cooltra/utils';

type VehicleProps = Pick<Contract, 'vehicle' | 'status'>;

export const Vehicle = ({ vehicle, status }: VehicleProps) => {
  if (!vehicle) return null;

  return (
    <div className="flex items-center pt-8 pb-2">
      <img
        className="w-28"
        src={vehicleImages[vehicle.name]}
        alt={getVehicleName(vehicle.name)}
      />
      <div className="ml-6 flex flex-col gap-2">
        <span className="text-base text-neutral-800 block">
          {getVehicleName(vehicle.name)}
        </span>
        {(status === 'ACTIVE' || status === 'CLOSED') &&
          vehicle.assignedVehicle && (
            <span className="text-base text-neutral-800 font-medium">
              {vehicle.assignedVehicle.licensePlate}
            </span>
          )}
      </div>
    </div>
  );
};
