import { TicketPaymentMethod } from '@cooltra/station-based-api';
import { defineMessages } from 'react-intl';
import { PenaltyName } from '@cooltra/utils';

export default defineMessages({
  vehicle: {
    defaultMessage: 'Vehicle',
  },
  coverage: {
    defaultMessage: 'Coverage',
  },
  accessories: {
    defaultMessage: 'Accessories',
  },
  total: {
    defaultMessage: 'Total',
  },
  orSimilar: {
    defaultMessage: '{vehicleTypeName} or similar ({contractDays} days)',
  },
  days: {
    defaultMessage: '{name} ({contractDays} days)',
  },
  valueAdjustment: {
    defaultMessage: 'Price adjustments',
  },
  remove: {
    defaultMessage: 'Remove',
  },
  removeSuccessNotification: {
    defaultMessage: 'Price adjustment removed successfully',
  },
  totalOriginalPrice: {
    defaultMessage: 'Original price',
  },
  couponDiscount: {
    defaultMessage: 'Coupon discount',
  },
  refund: {
    defaultMessage: 'Refund',
  },
  areYourSureToRefundTicket: {
    defaultMessage: 'Are you sure you want to proceed with the refund?',
  },
  ticketOperationNumber: {
    defaultMessage: 'Operation number {ticketOperationNumber}',
  },
  confirmRefund: {
    defaultMessage: 'Refund {amount}',
  },
  refundSuccess: {
    defaultMessage: 'Refund done successfully',
  },
  refunded: {
    defaultMessage: 'Refunded',
  },
  tpvOperationId: {
    defaultMessage: 'TPV Operation ID: {operationId}',
  },
  cardLastFourDigits: {
    defaultMessage: 'Card: **** {lastFourDigits}',
  },
  penalties: {
    defaultMessage: 'Penalties',
  },
  invoicePdf: {
    defaultMessage: 'Invoice PDF',
  },
  creditNotePdf: {
    defaultMessage: 'Credit note PDF',
  },
  pdfDoesNotExist: {
    defaultMessage: 'The PDF is not available for this invoice',
  },
  refundValidationError: {
    defaultMessage:
      'You must remove all pending tickets before refunding paid tickets',
  },
  somethingWentWrongOnDownloadingPdf: {
    defaultMessage: 'Something went wrong trying to download the PDF',
  },
  deposit: {
    defaultMessage: 'Deposit',
  },
});

export const contractPaymentMessages = defineMessages<TicketPaymentMethod>({
  TPV: {
    defaultMessage: 'TPV',
  },
  CARD: {
    defaultMessage: 'Stripe card',
  },
  CASH: {
    defaultMessage: 'Cash',
  },
  STRIPE_QR: {
    defaultMessage: 'Charge ticket with Stripe QR',
  },
});

export const contractPaymentTitleMessages = defineMessages<TicketPaymentMethod>(
  {
    TPV: {
      defaultMessage: 'In-store collection',
    },
    CARD: {
      defaultMessage: 'Web booking',
    },
    CASH: {
      defaultMessage: 'In-store collection',
    },
    STRIPE_QR: {
      defaultMessage: 'In-store collection',
    },
  }
);

export const penaltiesMessages = defineMessages<PenaltyName>({
  LOST_SEAT: {
    defaultMessage: 'Lost seat',
  },
  LOST_KEYS_PADLOCK: {
    defaultMessage: 'Lost keys padlock',
  },
  LOST_OR_DAMAGED_BASKET: {
    defaultMessage: 'Lost or damaged basket',
  },
  LOST_OR_DAMAGED_1ST_HELMET: {
    defaultMessage: 'Lost or damaged 1st helmet',
  },
  LOST_LOCK: {
    defaultMessage: 'Lost lock',
  },
  FINES: {
    defaultMessage: 'Fines',
  },
  LOST_DOCUMENTATION: {
    defaultMessage: 'Lost documentation',
  },
  LOST_KEYS: {
    defaultMessage: 'Lost keys',
  },
  TOW_TRUCK: {
    defaultMessage: 'Town truck',
  },
  LATE_RETURN: {
    defaultMessage: 'Late return',
  },
  DIRTY_VEHICLE: {
    defaultMessage: 'Dirty vehicle',
  },
  LOST_CHARGER: {
    defaultMessage: 'Lost charger',
  },
  LOST_EXTRAS: {
    defaultMessage: 'Lost extras',
  },
  ADMINISTRATIVE_COSTS: {
    defaultMessage: 'Administrative costs',
  },
  FUEL_PENALTY: {
    defaultMessage: 'Fuel penalty',
  },
  RETURN_IN_A_DIFFERENT_POINT: {
    defaultMessage: 'Return in a different point',
  },
  MUNICIPAL_TOW_TRUCK_REMOVAL: {
    defaultMessage: 'Municipal tow truck removal',
  },
});
