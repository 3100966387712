import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'No credits found',
  },
  content: {
    defaultMessage: 'This user does not have any credits',
  },
});
