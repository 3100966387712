import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Form, FormButton } from '@cooltra/form';
import { getErrorStatus } from '@cooltra/axios';
import {
  UpdateVehicleServicePayload,
  useUpdateVehicleServiceMutation,
  useVehicleQuery,
  useVehicleServiceQuery,
} from '@cooltra/api';
import { SpinnerScreen } from '@cooltra/ui';
import { useAuth0 } from '@auth0/auth0-react';

import {
  DividedCard,
  ErrorPage,
  Four0Three,
  Log,
  ServiceBackLink,
  ServiceFormProvider,
  ServiceFormValues,
  VehiclePair,
} from '~/common';
import { useNotification } from '~/hooks';

import { DeleteService } from '../DeleteService/DeleteService';

import messages from './messages';
import { EditServiceForm } from './EditServiceForm';
import { editServiceFormValidation } from './edit-service-form-validation';
import { VehicleMileage } from './VehicleMileage';

export const EditService = () => {
  const { user } = useAuth0();

  const { serviceId = '' } = useParams<'serviceId'>();
  const intl = useIntl();
  const { formatMessage } = intl;
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const {
    data: service,
    isError: isServiceError,
    error: serviceError,
    isLoadingError: isServiceLoading,
  } = useVehicleServiceQuery(serviceId);

  const enabled = !!service?.vehicleId;
  const {
    data: vehicle,
    isError: isVehicleError,
    error: vehicleError,
    isLoading: isVehicleLoading,
  } = useVehicleQuery(service?.vehicleId || '', {
    enabled,
  });

  const { mutateAsync, isPending } = useUpdateVehicleServiceMutation(
    String(service?.vehicleId),
    serviceId
  );

  const handleOnSubmit = (values: ServiceFormValues) => {
    const payload: UpdateVehicleServicePayload = {
      category: 'SERVICE',
      scheduledMileage: Number(values.scheduledMileage),
      scheduledAt: values.scheduledAt || null,
      serviceMileage: Number(values.serviceMileage) || null,
      servicedAt: values.servicedAt || null,
      servicedBy: values.servicedBy || null,
    };

    return mutateAsync(payload)
      .then(() => {
        addSuccessNotification(formatMessage(messages.success));
      })
      .catch(({ response }) => {
        switch (response.status) {
          case 400:
            addErrorNotification(formatMessage(messages.badFormattedFields));
            break;
          default:
            addErrorNotification();
            break;
        }
      });
  };

  if (
    (isServiceError && getErrorStatus(serviceError) === 403) ||
    (isVehicleError && getErrorStatus(vehicleError) === 403)
  ) {
    return <Four0Three />;
  }

  if (isServiceLoading || isVehicleLoading) {
    return <SpinnerScreen />;
  }

  if (!service || !vehicle) {
    return <ErrorPage />;
  }

  return (
    <ServiceFormProvider
      onSubmit={handleOnSubmit}
      validate={editServiceFormValidation(intl)}
      initialValues={{
        scheduledMileage: String(service.scheduledMileage),
        scheduledAt: service.scheduledAt || '',
        servicedAt: service.servicedAt || '',
        servicedBy: service.servicedBy || user?.sub || '',
        serviceMileage: service.serviceMileage
          ? String(service.serviceMileage)
          : '',
      }}
    >
      <Form>
        <DividedCard
          className="min-w-2xl max-w-4xl pt-12 pb-20"
          renderHeader={
            <div className="flex items-end justify-between">
              <div>
                <ServiceBackLink vehicleId={vehicle?.vehicleId} />
                <h1 className="text-3xl">
                  <FormattedMessage {...messages.title} />
                </h1>
              </div>
              <FormButton disabled={isPending} loading={isPending}>
                <FormattedMessage {...messages.editService} />
              </FormButton>
            </div>
          }
          renderLeft={
            <div className="h-full flex flex-col justify-between">
              <div className="flex flex-col gap-9">
                <VehiclePair
                  vehicleId={vehicle.vehicleId}
                  model={vehicle.model}
                  externalId={vehicle?.externalId}
                  identificationNumber={vehicle?.identificationNumber}
                />
                {vehicle.vehicleId && (
                  <VehicleMileage vehicleId={vehicle.vehicleId} />
                )}
              </div>
              <div className="flex flex-col gap-6">
                <Log
                  user="operator"
                  action="create"
                  userId={service.createdBy}
                  date={service.createdAt}
                />
                {service.updatedBy && service.updatedAt && (
                  <Log
                    user="operator"
                    action="update"
                    userId={service.updatedBy}
                    date={service.updatedAt}
                  />
                )}
              </div>
            </div>
          }
          renderRight={<EditServiceForm vehicleId={vehicle.vehicleId} />}
          renderFooter={<DeleteService vehicleId={vehicle.vehicleId} />}
        />
      </Form>
    </ServiceFormProvider>
  );
};
