import { FormattedMessage } from 'react-intl';
import { ContractPayment } from '@cooltra/station-based-api';

import { Amount } from '~/common';

import messages, { penaltiesMessages } from './messages';

type PenaltiesProps = {
  className?: string;
  payment?: ContractPayment;
};

export const Penalties = ({ className, payment }: PenaltiesProps) => {
  if (!payment || !payment.penalties || payment.penalties.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      <h3>
        <span className="text-sm font-semibold text-neutral-800">
          <FormattedMessage {...messages.penalties} />
        </span>
      </h3>
      <div>
        {payment.penalties.map((penalty) => (
          <div
            key={penalty.id}
            className="flex justify-between my-2 gap-8 text-neutral-800"
          >
            <div className="flex gap-1 items-center">
              <span>
                <FormattedMessage {...penaltiesMessages[penalty.name]} />
              </span>
            </div>
            <div>
              <Amount {...penalty.price} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
