import { CurrentActivityRental } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { RouterCardLink, RouterLink } from '@cooltra/navigation';
import { shortenId } from '@cooltra/utils';

import {
  PickUp,
  RentalDuration,
  RentalIncidentsBadge,
  VehicleLink,
  ListColumnLabel,
} from '~/common';

import { CurrentRentalLayout } from '../CurrentRentalLayout';

import messages from './messages';

export type CurrentRentalProps = {
  rental: CurrentActivityRental;
};

export const CurrentRental = ({
  rental: { rentalId, pickUp, system, vehicle, incidentIds },
}: CurrentRentalProps) => (
  <RouterCardLink
    isMultiAction
    to={`/rentals/${rentalId}`}
    data-testid="CURRENT_ACTIVITY_CARD"
    className="py-6 bg-success-50/50"
  >
    <CurrentRentalLayout
      pickUp={
        <div>
          <ListColumnLabel className="mb-1">
            <FormattedMessage {...messages.pickUp} />
          </ListColumnLabel>
          <PickUp pickUp={pickUp} />
        </div>
      }
      duration={
        <div>
          <ListColumnLabel className="mb-2">
            <FormattedMessage {...messages.duration} />
          </ListColumnLabel>
          <RentalDuration
            startDate={pickUp.createdAt}
            finishDate={new Date()}
            className="text-neutral-400 text-sm"
          />
        </div>
      }
      vehicle={
        <div>
          <ListColumnLabel className="mb-1.5">
            <FormattedMessage {...messages.vehicle} />
          </ListColumnLabel>
          <VehicleLink vehicle={vehicle} />
        </div>
      }
      rental={
        <div>
          <ListColumnLabel className="mb-1.5">
            <FormattedMessage {...messages.rental} />
          </ListColumnLabel>
          <RouterLink
            to={`/rentals/${rentalId}`}
            className="block text-sm font-medium"
          >
            #{shortenId(rentalId)}
          </RouterLink>
        </div>
      }
      system={
        <div>
          <ListColumnLabel className="mb-2">
            <FormattedMessage {...messages.system} />
          </ListColumnLabel>
          <span className="block text-neutral-800 text-sm font-medium">
            {system}
          </span>
        </div>
      }
      incidents={
        <div>
          <ListColumnLabel className="mb-2">
            <FormattedMessage {...messages.incidents} />
          </ListColumnLabel>
          <RentalIncidentsBadge rentalId={rentalId} incidentIds={incidentIds} />
        </div>
      }
    />
  </RouterCardLink>
);
