import { FormattedMessage } from 'react-intl';
import { ContractDeposit, useContractQuery } from '@cooltra/station-based-api';
import { Button } from '@cooltra/ui';
import { MdQrCode } from 'react-icons/md';
import { useContext } from 'react';

import { Amount } from '~/common';
import { RemoveDepositButton } from '~/components/Contract/ContractPayments/ContractDeposit/PendingDeposit/RemoveDepositButton/RemoveDepositButton';

import { StripeDepositQRContext } from '../StripeDepositQRProvider/StripeDepositQRProvider';

import messages from './messages';

type PendingDepositProps = {
  contractId: string;
  deposit: ContractDeposit;
};

export const PendingDeposit = ({
  contractId,
  deposit,
}: PendingDepositProps) => {
  const { refetch, isRefetching } = useContractQuery(contractId, {
    enabled: false,
  });
  const { openWithDepositId } = useContext(StripeDepositQRContext);
  const { amount } = deposit;
  const verifyDeposit = () => refetch();
  const openQR = () => openWithDepositId(deposit.id);

  return (
    <div className="flex justify-between items-end">
      <div className="flex flex-col gap-3">
        <span className="font-medium text-lg">
          <FormattedMessage {...messages.pendingDeposit} />
        </span>
        <div className="flex items-center gap-3 text-neutral-500">
          <FormattedMessage {...messages.waitingStripeWithhold} />
          <span className="text-neutral-100">|</span>
          <Button
            onClick={openQR}
            emphasis="low"
            size="sm"
            leadingIcon={<MdQrCode />}
          >
            <FormattedMessage {...messages.showStripeQR} />
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-3 items-end">
        <Amount
          className="font-semibold text-xl text-neutral-800"
          {...amount}
        />
        <div className="flex items-center gap-3">
          <RemoveDepositButton contractId={contractId} depositId={deposit.id} />
          <Button loading={isRefetching} size="sm" onClick={verifyDeposit}>
            <FormattedMessage {...messages.verify} />
          </Button>
        </div>
      </div>
    </div>
  );
};
