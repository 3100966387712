import { useIntl } from 'react-intl';
import { Placeholder } from '@cooltra/ui';

import { PairValue } from './PairValue';
import messages from './messages';

type ActiveReservationsItemsProps = {
  totalActiveReservations: number;
  isLoading: boolean;
};

export const ActiveReservationsItems = ({
  totalActiveReservations,
  isLoading,
}: ActiveReservationsItemsProps) => {
  const { formatMessage } = useIntl();

  if (isLoading) {
    return (
      <PairValue
        value={
          <div className="h-10 flex items-center">
            <Placeholder className="w-12 h-3" />
          </div>
        }
        title={formatMessage(messages.activeReservations)}
      />
    );
  }

  return (
    <PairValue
      value={totalActiveReservations}
      title={formatMessage(messages.activeReservations)}
    />
  );
};
