import { Route, Routes } from 'react-router-dom';

import { CreateStationBasedPromotion } from './Mutations/Create/CreateStationBasedPromotion';
import { EditStationBasedPromotion } from './Mutations/Edit/EditStationBasedPromotion';
import { StationBasedList } from './List/List/StationBasedList';
import { PromotionsFiltersForm } from './List/List/PromotionsFiltersForm';

export const StationBased = () => (
  <Routes>
    <Route
      path="/"
      element={
        <PromotionsFiltersForm>
          <StationBasedList />
        </PromotionsFiltersForm>
      }
    />
    <Route path="/new" element={<CreateStationBasedPromotion />} />
    <Route path="/:promotionId/edit" element={<EditStationBasedPromotion />} />
  </Routes>
);
