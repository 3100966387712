import { defineMessages } from 'react-intl';

export default defineMessages({
  online: {
    defaultMessage: 'Connected',
  },
  loading: {
    defaultMessage: 'Loading',
  },
  offline: {
    defaultMessage: 'Offline',
  },
  since: {
    defaultMessage: 'Since {timeInThePast}',
  },
});
