import {
  MouseEventHandler,
  ImgHTMLAttributes,
  ChangeEvent,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { UploadButton, Image, Button } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import {
  useDocumentImageMutation,
  useDocumentImage,
} from '@cooltra/station-based-api';
import { useField, useFormContext } from '@cooltra/form';

import { useNotification, useRotation } from '~/hooks';
import { CameraModal, PhotoModal, PhotoSide, PhotoSideLabel } from '~/common';
import { readFile } from '~/utils/file';

import { photoSize } from './photo-classes';
import messages from './messages';

const hasChangedAxis = (rotation: number) =>
  rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;

const getPhotoClasses = (rotation: number) => {
  const constrainedDimension = hasChangedAxis(rotation) ? 'width' : 'height';
  return classNames(
    'object-contain',
    photoSize[constrainedDimension],
    constrainedDimension === 'height' ? 'max-w-full' : 'max-h-full'
  );
};

export type PhotoProps = ImgHTMLAttributes<HTMLImageElement> & {
  name: string;
  side?: PhotoSide;
  isDocument?: boolean;
  testId?: string;
};

export const Photo = ({
  name,
  className,
  side,
  isDocument = true,
  testId,
  ...rest
}: PhotoProps) => {
  const { isFormDisabled } = useFormContext();
  const { value, setValue } = useField<string>(name);

  const [uploadedImageData, setUploadedImageData] = useState('');
  const fetchedImageData = useDocumentImage(value);
  const mutation = useDocumentImageMutation();

  const { addErrorNotification } = useNotification();

  const { formatMessage } = useIntl();

  const [isOpen, { toggleOff, toggleOn }] = useToggle();
  const [
    isCameraOpen,
    { toggleOff: toggleCameraOff, toggleOn: toggleCameraOn },
  ] = useToggle();
  const openModal: MouseEventHandler<HTMLButtonElement> = (e) => {
    // prevent the modal from focusing on the last active element
    // otherwise the photo toolbar is being jerked up and down upon closing
    e.currentTarget.blur();
    toggleOn();
  };

  const { rotation, rotationClassName, rotateLeft, rotateRight } =
    useRotation();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    openModal(e);
  };

  const handleFileUpload = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const file = target?.files?.[0];
    if (file) {
      readFile(file)
        .then((blob) => {
          const formData = new FormData();
          formData.append('image', file);
          return Promise.all([blob, mutation.mutateAsync(formData)]);
        })
        .then(([blob, { id }]) => {
          setUploadedImageData(blob);
          setValue(id);
        })
        .catch(() =>
          addErrorNotification(formatMessage(messages.uploadingError))
        );
    }
  };

  const imageData = uploadedImageData
    ? uploadedImageData
    : fetchedImageData
      ? `data:image/jpeg;base64,${fetchedImageData}`
      : '';

  return (
    <div className={className} data-testid={testId}>
      <div className={photoSize.height}>
        <div
          tabIndex={0}
          className="group relative bg-neutral-100 h-full outline-none rounded-lg"
        >
          <div className="h-full flex items-center justify-center">
            {value ? (
              <button
                type="button"
                className="h-full w-full"
                onClick={handleClick}
                aria-label={formatMessage(messages.fullScreen)}
              >
                <Image
                  src={imageData}
                  className={classNames(
                    getPhotoClasses(rotation),
                    rotationClassName
                  )}
                  {...rest}
                />
              </button>
            ) : isFormDisabled ? null : (
              <div className="flex flex-col gap-2">
                <Button type="button" onClick={toggleCameraOn}>
                  <FormattedMessage {...messages.takePhoto} />
                </Button>
                <UploadButton
                  accept=".png,.jpg,.jpeg"
                  id={`upload-${name}-image`}
                  loading={mutation.isPending}
                  onChange={handleFileUpload}
                  emphasis="low"
                >
                  <FormattedMessage {...messages.uploadPhoto} />
                </UploadButton>
              </div>
            )}
          </div>
          {side !== undefined && (
            <div className="absolute top-2 right-2">
              <PhotoSideLabel side={side} />
            </div>
          )}
        </div>
        <PhotoModal
          isOpen={isOpen}
          onRequestClose={toggleOff}
          rotateLeft={rotateLeft}
          rotateRight={rotateRight}
          className={rotationClassName}
          imageData={imageData}
          {...rest}
        />
        <CameraModal
          isOpen={isCameraOpen}
          onChange={setValue}
          onSave={mutation.mutateAsync}
          onRequestClose={toggleCameraOff}
          isDocument={isDocument}
          fileType="zeus-contract-driver-photo"
        />
      </div>
      {value && (
        <Button
          type="button"
          variant="danger"
          size="sm"
          emphasis="low"
          className="mt-2"
          onClick={() => setValue('')}
          disabled={isFormDisabled}
        >
          <FormattedMessage {...messages.removePhoto} />
        </Button>
      )}
    </div>
  );
};
