import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Incident',
  },
  createIncident: {
    defaultMessage: 'Create incident',
  },
  success: {
    defaultMessage: 'You have successfully created a new incident!',
  },
});
