import { List } from '@cooltra/ui';
import { getErrorStatus } from '@cooltra/axios';
import { SubscriptionCharge, useSubscriptionChargesQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { FormattedMessage } from 'react-intl';

import { ErrorPage, Four0Three, NoSearchResults } from '~/common';

import { SubscriptionChargeCard } from './SubscriptionChargeCard/SubscriptionChargeCard';
import { SubscriptionChargeCardLoading } from './SubscriptionChargeCard/SubscriptionChargeCardLoading';
import messages from './messages';

export const SubscriptionCharges = () => {
  const {
    data = [],
    isLoading,
    isError,
    error,
  } = useSubscriptionChargesQuery('basic');

  const { hasPermission } = useAuthClaimsQuery();
  const hasReadSubscriptionChargesPermission = hasPermission(
    'read:subscription_charges'
  );
  const hasWriteSubscriptionChargesPermission = hasPermission(
    'write:subscription_charges'
  );

  if (
    (isError && getErrorStatus(error) === 403) ||
    !hasReadSubscriptionChargesPermission
  ) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div className="container py-10 min-w-3xl max-w-4xl">
      <h3 className="mb-7 text-base font-semibold text-neutral-600">
        <FormattedMessage {...messages.basicPricing} />
      </h3>
      <List
        loadingRows={4}
        isLoading={isLoading}
        idProp="system"
        data={data}
        renderLoadingRow={<SubscriptionChargeCardLoading />}
        renderEmptyMessage={<NoSearchResults />}
        renderRow={(subscriptionCharge: SubscriptionCharge) => (
          <SubscriptionChargeCard
            subscriptionCharge={{ ...subscriptionCharge }}
            isEditable={hasWriteSubscriptionChargesPermission}
          />
        )}
      />
    </div>
  );
};
