import { HTMLAttributes, ReactNode } from 'react';
import { classNames } from '@cooltra/utils';

export type VehicleReservationRowLayoutProps =
  HTMLAttributes<HTMLDivElement> & {
    checkIn: ReactNode;
    createdAt: ReactNode;
    expiresAt: ReactNode;
    problemReports: ReactNode;
    spacer?: ReactNode;
    state: ReactNode;
    system: ReactNode;
    user: ReactNode;
  };

export const VehicleReservationRowLayout = ({
  checkIn,
  createdAt,
  expiresAt,
  problemReports,
  spacer,
  state,
  system,
  user,
  className,
  ...rest
}: VehicleReservationRowLayoutProps) => (
  <div
    className={classNames(
      'relative w-full flex items-center gap-4 pl-6 pr-4',
      className
    )}
    {...rest}
  >
    <div className="w-24">{createdAt}</div>
    <div className="w-4">{spacer}</div>
    <div className="w-24 mr-4">{expiresAt}</div>
    <div className="w-44">{user}</div>
    <div className="flex-1 text-center">{system}</div>
    <div className="w-24 text-center">{checkIn}</div>
    <div className="w-28 text-center">{problemReports}</div>
    <div className="w-32 text-center">{state}</div>
  </div>
);
