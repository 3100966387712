import { FormattedMessage } from 'react-intl';
import { Icon, Tooltip } from '@cooltra/ui';
import { MdInfoOutline } from 'react-icons/md';

import messages from './messages';

export type WarningTooltipProps = {
  isAccessoryPaidFor: boolean;
  isContractStatusActive: boolean;
  isAccessoryAwaitingPayment: boolean;
};

export const WarningTooltip = ({
  isAccessoryPaidFor,
  isAccessoryAwaitingPayment,
  isContractStatusActive,
}: WarningTooltipProps) => {
  const isOnATicket = isAccessoryAwaitingPayment || isAccessoryPaidFor;

  if (!isOnATicket || isContractStatusActive) {
    return null;
  }

  return (
    <Tooltip
      button={
        <button data-testid="WARNING_ACCESSORY_TOOLTIP">
          <Icon className="text-xl text-warning-600">
            <MdInfoOutline />
          </Icon>
        </button>
      }
      content={
        <div className="p-4 max-w-60 text-center">
          <p>
            {isAccessoryPaidFor ? (
              <FormattedMessage {...messages.accessoryIncludedOnAPaidTicket} />
            ) : (
              <FormattedMessage
                {...messages.accessoryIncludedOnAAwaitingTicket}
              />
            )}
          </p>
        </div>
      }
    />
  );
};
