import { VehicleTypeCategory } from '@cooltra/station-based-api';

export const vehicleTypeCategoryNames: Record<VehicleTypeCategory, string> = {
  EBIKE: 'E-Bike',
  PLUS: 'Plus',
  PREMIUM: 'Premium',
  STANDARD: 'Standard',
  SUPER: 'Super',
};

export const getVehicleTypeCategoryName = (vehicleType: VehicleTypeCategory) =>
  vehicleTypeCategoryNames[vehicleType];
