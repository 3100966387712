import { defineMessages } from 'react-intl';

export default defineMessages({
  assignedToContract: {
    defaultMessage: 'Assigned to contract {link}',
  },
  assigned: {
    defaultMessage: 'Assigned',
  },
  available: {
    defaultMessage: 'Available',
  },
  vehicleData: {
    defaultMessage: 'Vehicle data',
  },
  lockCode: {
    defaultMessage: 'Lock code',
  },
  checksAndDamages: {
    defaultMessage: 'Checks and damages',
  },
  editInTheContract: {
    defaultMessage: 'Edit in the contract',
  },
  servicePoint: {
    defaultMessage: 'Service point',
  },
});
