import { Vehicle } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { StaticField } from '~/common';

import messages from './messages';

export type LicensePlateStaticProps = {
  value: Vehicle['licensePlate'];
};

export const LicensePlateStatic = ({ value }: LicensePlateStaticProps) => (
  <StaticField
    renderLabel={<FormattedMessage {...messages.licensePlate} />}
    renderValue={value}
  />
);
