import { defineMessages } from 'react-intl';

export default defineMessages({
  model: {
    defaultMessage: 'Model',
  },
  placeholder: {
    defaultMessage: 'All models',
  },
});
