import { useCancelInvoiceItemMutation } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { useToggle } from '@cooltra/hooks';
import { Button } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useNotification } from '~/hooks';

import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';

import messages from './messages';

export type CancelInvoiceItemProps = {
  id: string;
  type: 'RENTAL' | 'MANUAL';
};

export const CancelInvoiceItem = ({ id, type }: CancelInvoiceItemProps) => {
  const { invoiceId = '' } = useParams();
  const { hasPermission } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();
  const { addErrorNotification } = useNotification();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const { isPending, mutateAsync } = useCancelInvoiceItemMutation(
    invoiceId,
    id
  );

  const handleOnConfirm = () => {
    mutateAsync({ type })
      .then(() => toggleOff())
      .catch(() => addErrorNotification());
  };

  if (!hasPermission('write:invoices')) {
    return null;
  }

  return (
    <>
      <Button size="sm" onClick={toggleOn}>
        <FormattedMessage {...messages.cancel} />
      </Button>
      <ConfirmModal
        isOpen={isOpen}
        title={formatMessage(messages.cancelItem)}
        content={formatMessage(messages.areYouSureToCancel)}
        onClose={toggleOff}
        onConfirm={handleOnConfirm}
        loading={isPending}
      />
    </>
  );
};
