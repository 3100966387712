import { defineMessages } from 'react-intl';

export default defineMessages({
  pickUp: {
    defaultMessage: 'Pick up',
  },
  dropOff: {
    defaultMessage: 'Drop off',
  },
  inProgress: {
    defaultMessage: 'In progress',
  },
  now: {
    defaultMessage: 'Now',
  },
  tripSummary: {
    defaultMessage: 'Trip summary',
  },
  comingSoon: {
    defaultMessage: 'Coming soon...',
  },
  subscription: {
    defaultMessage: 'Subscription',
  },
  duration: {
    defaultMessage: 'Duration',
  },
  billableDuration: {
    defaultMessage: 'Billable duration',
  },
  totalDistance: {
    defaultMessage: 'Total distance',
  },
});
