import { RouterButton, RouterButtonProps } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { stringifyParams } from '@cooltra/utils';

import { useVehicleTypeQueryParam } from '../../utils';

import messages from './messages';

export type NewRestrictionLinkProps = Omit<
  RouterButtonProps,
  'to' | 'size' | 'emphasis'
>;

export const NewRestrictionLink = (props: NewRestrictionLinkProps) => {
  const vehicleType = useVehicleTypeQueryParam();
  const { hasPermission } = useAuthClaimsQuery();

  return hasPermission('write:geofence_restrictions') ? (
    <RouterButton
      emphasis="high"
      size="sm"
      to={`new?${stringifyParams({ vehicleType })}`}
      {...props}
    >
      <FormattedMessage {...messages.newRestriction} />
    </RouterButton>
  ) : null;
};
