import { defineMessages } from 'react-intl';

export default defineMessages({
  locked: {
    defaultMessage: 'Locked',
  },
  unlocked: {
    defaultMessage: 'Unlocked',
  },
  down: {
    defaultMessage: 'Down',
  },
  up: {
    defaultMessage: 'Up',
  },
  closed: {
    defaultMessage: 'Closed',
  },
  open: {
    defaultMessage: 'Open',
  },
  on: {
    defaultMessage: 'On',
  },
  off: {
    defaultMessage: 'Off',
  },
});
