import { FormattedMessage } from 'react-intl';
import { FormMultiSelectField } from '@cooltra/form';
import { useTagsQuery } from '@cooltra/api';

import { OptionalFieldLabel } from '../../../OptionalFieldLabel/OptionalFieldLabel';

import messages from './messages';

export const Tags = () => {
  const { data, isLoading } = useTagsQuery();
  return (
    <FormMultiSelectField
      isLoading={isLoading}
      id="task-tags"
      name="tagIds"
      noOptionsMessage={() => <FormattedMessage {...messages.noTags} />}
      options={data
        ?.map(({ tagId, name }) => ({
          label: name,
          value: tagId,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))}
      label={
        <OptionalFieldLabel htmlFor="task-tags">
          <FormattedMessage {...messages.tags} />
        </OptionalFieldLabel>
      }
    />
  );
};
