import { defineMessages } from 'react-intl';

export default defineMessages({
  rentalAlreadyClosed: {
    defaultMessage: 'Rental already closed',
  },
  closeRental: {
    defaultMessage: 'Close rental',
  },
  rentalNotFoundError: {
    defaultMessage: 'Rental is neither not found or not active',
  },
  missingMinutesError: {
    defaultMessage: 'Minutes are required to close de rental',
  },
  notAuthorisedError: {
    defaultMessage: 'Your are not authorised to perform this action',
  },
  closeActionAlreadyInProgress: {
    defaultMessage: 'There is a close action already in progress',
  },
  somethingWentWrong: {
    defaultMessage: 'Something went wrong!',
  },
  theVehicleCouldNotBeLocked: {
    defaultMessage: 'The vehicle could not be locked',
  },
});
