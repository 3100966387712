import { defineMessages } from 'react-intl';

export default defineMessages({
  task: {
    defaultMessage: 'Task',
  },
  assignee: {
    defaultMessage: 'Assignee',
  },
  created: {
    defaultMessage: 'Created',
  },
  updated: {
    defaultMessage: 'Updated',
  },
  context: {
    defaultMessage: 'Context',
  },
});
