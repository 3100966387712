import { Money } from '@cooltra/utils';
import { useIntl } from 'react-intl';

import { Amount } from '~/common';

import messages from './messages';

export const PaidAmount = ({ amount }: { amount: Money }) => {
  const intl = useIntl();
  return (
    <div className="flex flex-col">
      <span className="text-neutral-500 text-xs">
        {intl.formatMessage(messages.paidAmount)}
      </span>
      <span className="text-neutral-800 text-xl font-semibold">
        <span className="flex items-center gap-1">
          <Amount value={amount.value} currency={amount.currency} />
        </span>
      </span>
    </div>
  );
};
