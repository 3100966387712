import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';
import { usePayTicketContractWithQRMutation } from '@cooltra/station-based-api';

import { useNotification } from '~/hooks';

import { DepositFormValues } from '../../ContractPayments/ContractDeposit/deposit-form';
import { StripePaymentQRContext } from '../../ContractPayments/StripePaymentQRProvider/StripePaymentQRProvider';
import { PayTicketFormValues } from '../PayTicketFormValues';

import messages from './messages';

type StripeQRPaymentButtonProps = {
  contractId: string;
  onCloseModal: () => void;
  disabled: boolean;
};

export const StripeQRPaymentButton = ({
  contractId,
  onCloseModal,
  disabled,
}: StripeQRPaymentButtonProps) => {
  const {
    values: { billingServicePointId },
  } = useFormContext<PayTicketFormValues>();
  const { openTicketQrModal, ticketToMarkAsAwaitingForPayment } = useContext(
    StripePaymentQRContext
  );
  const { addErrorNotification } = useNotification();
  const { mutateAsync, isPending } = usePayTicketContractWithQRMutation(
    contractId,
    ticketToMarkAsAwaitingForPayment
  );
  const { initialValues, resetForm } = useFormContext<DepositFormValues>();

  const handleStripeQRSubmit = async () =>
    mutateAsync({
      billingServicePointId,
    })
      .then(() => {
        openTicketQrModal(ticketToMarkAsAwaitingForPayment);
        resetForm(initialValues);
        onCloseModal();
      })
      .catch(() => {
        addErrorNotification();
      });

  return (
    <Button
      onClick={handleStripeQRSubmit}
      loading={isPending}
      disabled={disabled}
      emphasis="high"
      className="mt-8"
    >
      <FormattedMessage {...messages.seeStripeQR} />
    </Button>
  );
};
