import { defineMessages } from 'react-intl';

export default defineMessages({
  inALocation: {
    defaultMessage: 'In a location',
  },
  since: {
    defaultMessage: 'since',
  },
  withoutLocation: {
    defaultMessage: 'No current activity',
  },
});
