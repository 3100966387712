import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { PassRowLayout } from '../PassRowLayout';

import messages from './messages';

export const PassesListHeader = () => (
  <PassRowLayout
    data-testid="PASSES_LIST_HEADER"
    code={
      <ListColumnLabel>
        <FormattedMessage {...messages.code} />
      </ListColumnLabel>
    }
    price={
      <ListColumnLabel>
        <FormattedMessage {...messages.price} />
      </ListColumnLabel>
    }
    validFrom={
      <ListColumnLabel>
        <FormattedMessage {...messages.validFrom} />
      </ListColumnLabel>
    }
    validUntil={
      <ListColumnLabel>
        <FormattedMessage {...messages.validUntil} />
      </ListColumnLabel>
    }
    state={
      <ListColumnLabel>
        <FormattedMessage {...messages.state} />
      </ListColumnLabel>
    }
  />
);
