import { useRef } from 'react';
import { useToggle } from '@cooltra/hooks';
import { useLatestApprovalReviewsQuery } from '@cooltra/api';
import { MdSchedule } from 'react-icons/md';

import { useOutsideElement } from '~/hooks';

import { LastReviewsList } from './LastReviewsList';

export const LastReviews = () => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const ref = useRef(null);
  const { data, isLoading } = useLatestApprovalReviewsQuery();

  useOutsideElement(ref, toggleOff);

  const isThereAnyApprovalReview = data && data.approvalReviews?.length > 0;

  if (isLoading || !isThereAnyApprovalReview) {
    return <></>;
  }

  return (
    <div ref={ref}>
      {isOpen ? (
        <LastReviewsList
          closeReviewsList={toggleOff}
          approvalReviews={data.approvalReviews}
        />
      ) : (
        <div
          data-testid="BUTTON_LAST_APPROVAL_REVIEWS"
          className="p-4 fixed left-0 bottom-0 bg-primary-500 rounded-tr-lg cursor-pointer"
          onClick={toggleOn}
        >
          <MdSchedule className="w-7 h-7 text-neutral-0" />
        </div>
      )}
    </div>
  );
};
