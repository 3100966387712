import { defineMessages } from 'react-intl';

export default defineMessages({
  locations: {
    defaultMessage: 'Locations',
  },
  placeholder: {
    defaultMessage: 'All locations',
  },
  noLocations: {
    defaultMessage: 'There is no locations',
  },
});
