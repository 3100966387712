import { BadgePlaceholder, Placeholder } from '@cooltra/ui';
import { consecutiveNumbers } from '@cooltra/utils';

import {
  SidebarHeadingLoading,
  SidebarLinksLoading,
  SidebarIconItemLoading,
  SidebarItemLoading,
} from '~/common';

export const SidebarLoading = () => (
  <div className="pt-14 pb-20">
    <div className="px-8">
      <BadgePlaceholder className="w-20 mb-4" />
      <SidebarHeadingLoading className="mb-5" />
      <SidebarItemLoading className="mb-9" />
      <div className="mb-7 flex flex-col gap-4">
        {consecutiveNumbers(3).map((num) => (
          <SidebarIconItemLoading key={`sidebar-icon-item-loading-${num}`} />
        ))}
      </div>
      <Placeholder className="h-3 w-24 mb-2" />
      <Placeholder className="h-3 w-20 mb-7" />
    </div>
    <SidebarLinksLoading linksNumber={8} />
  </div>
);
