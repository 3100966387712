import {
  useVehicleBonusToggleMutation,
  useVehicleQuery,
  Vehicle,
} from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { Card, Divider, ToggleField } from '@cooltra/ui';
import { useQueryClient } from '@tanstack/react-query';
import { ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useNotification } from '~/hooks';

import { IdleFor } from '../IdleFor/IdleFor';
import { ParkingReview } from '../ParkingReview/ParkingReview';

import messages from './messages';
import star from './assets/star.png';

export type VehicleBonusProps = {
  vehicleId: string;
};

export const VehicleBonus = ({ vehicleId }: VehicleBonusProps) => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { addErrorNotification } = useNotification();
  const { hasPermission } = useAuthClaimsQuery();

  const { data, isLoading } = useVehicleQuery(vehicleId);

  const { mutateAsync } = useVehicleBonusToggleMutation(vehicleId);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const vehicle = queryClient.getQueryData<Vehicle>(['vehicle', vehicleId]);
    if (!vehicle) {
      return;
    }

    const bonusEnabled = e.target.checked;
    queryClient.setQueryData<Vehicle>(['vehicle', vehicleId], {
      ...vehicle,
      bonusEnabled,
    });

    mutateAsync({ bonusEnabled }).catch((error) => {
      let errorNotification;

      switch (getErrorStatus(error)) {
        case 403:
          errorNotification = intl.formatMessage(messages.permanentBonus);
          break;
        case 409:
          errorNotification = intl.formatMessage(messages.limitReached);
          break;
        default:
          errorNotification = undefined;
          break;
      }

      addErrorNotification(errorNotification);
      queryClient.setQueryData<Vehicle>(['vehicle', vehicleId], {
        ...vehicle,
        bonusEnabled: !bonusEnabled,
      });
    });
  };

  return (
    <div>
      <h2 className="text-base text-neutral-500 font-semibold mb-6">
        <FormattedMessage {...messages.vehicleBonus} />
      </h2>
      <Card>
        <div className="px-8 pb-8 pt-6">
          <div className="flex justify-between items-center pb-3">
            <div className="flex">
              <div className="w-5">
                <img src={star} />
              </div>
              <span className="pl-2 pt-px inline-block text-sm text-neutral-400">
                <FormattedMessage {...messages.vehicleBonus} />
              </span>
            </div>

            {hasPermission('write:vehicle_bonus') ? (
              <ToggleField
                disabled={isLoading}
                data-testid="VEHICLE_BONUS_TOGGLE"
                id="vehicle-bonus-toggle"
                checked={data?.bonusEnabled}
                onChange={handleChange}
              />
            ) : (
              <span className="font-semibold text-neutral-800 text-sm">
                {data?.bonusEnabled ? (
                  <FormattedMessage {...messages.yes} />
                ) : (
                  <FormattedMessage {...messages.no} />
                )}
              </span>
            )}
          </div>
          <Divider />
          <div className="py-5 flex gap-8 justify-between items-center">
            <span className="text-sm text-neutral-400">
              <FormattedMessage {...messages.parkingReview} />
            </span>
            <span className="font-semibold text-neutral-800 text-sm">
              <ParkingReview type={data?.parkingReview} />
            </span>
          </div>
          <Divider />
          <div className="py-5 flex gap-8 justify-between items-center">
            <span className="text-sm text-neutral-400">
              <FormattedMessage {...messages.idleFor} />
            </span>
            <span className="font-semibold text-neutral-800 text-sm">
              <IdleFor date={data?.idleFrom} />
            </span>
          </div>
          <Divider />
        </div>
      </Card>
    </div>
  );
};
