import { FormProvider, FormProviderProps } from '@cooltra/form';
import { useIntl } from 'react-intl';

import { TaskFormValues, validateTaskForm } from './task-form';

export type TaskFormProviderProps = Omit<
  FormProviderProps<TaskFormValues>,
  'validate'
>;

export const TaskFormProvider = ({
  onSubmit,
  initialValues,
  children,
  disabled,
}: TaskFormProviderProps) => {
  const intl = useIntl();
  return (
    <FormProvider
      initialValues={initialValues}
      validate={validateTaskForm(intl)}
      onSubmit={onSubmit}
      disabled={disabled}
    >
      {children}
    </FormProvider>
  );
};
