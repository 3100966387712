import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type PhotoSide = 'front' | 'back';

export type PhotoSideLabelProps = {
  side: PhotoSide;
};

export const PhotoSideLabel = ({ side }: PhotoSideLabelProps) => (
  <div className="relative overflow-hidden rounded-md">
    <div className="absolute inset-0 bg-neutral-0 opacity-80" />
    <span className="relative text-xs text-neutral-500 inline-block px-2 py-1">
      <FormattedMessage {...messages[side]} />
    </span>
  </div>
);
