import { FormattedMessage } from 'react-intl';
import { AccessoryName } from '@cooltra/utils';
import { ContractPayment } from '@cooltra/station-based-api';

import { useAccessoryName } from '~/hooks';
import { Amount } from '~/common';

import messages from './messages';

type AccessoriesProps = {
  className?: string;
  payment?: ContractPayment;
};

export const Accessories = ({ className, payment }: AccessoriesProps) => {
  const getAccessoryName = useAccessoryName();

  if (!payment || !payment.accessories || payment.accessories.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      <h3>
        <span className="text-sm font-semibold text-neutral-800">
          <FormattedMessage {...messages.accessories} />
        </span>
      </h3>
      <div>
        {payment.accessories.map((accessory) => (
          <div
            key={accessory.id}
            className="flex justify-between my-2 gap-8 text-neutral-800"
          >
            <div className="flex gap-1 items-center">
              <span>
                {accessory.days === null ? (
                  getAccessoryName(accessory.name as AccessoryName)
                ) : (
                  <FormattedMessage
                    {...messages.days}
                    values={{
                      name: getAccessoryName(accessory.name as AccessoryName),
                      contractDays: accessory.days,
                    }}
                  />
                )}
              </span>
              <span>
                {accessory.quantity > 1 ? 'x' + accessory.quantity : ''}
              </span>
            </div>
            <div>
              <Amount {...accessory.price} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
