import { Incident } from '@cooltra/api';
import { classNames } from '@cooltra/utils';
import { RouterCardLink } from '@cooltra/navigation';

import {
  DateTime,
  ByOperator,
  OperatorOrGroup,
  IncidentCardInfo,
} from '~/common';

import { RentalIncidentRowLayout } from '../RentalIncidentRowLayout';

export type RentalIncidentCardProps = {
  incident: Incident;
};

export const RentalIncidentCard = ({
  incident: {
    incidentId,
    title,
    description,
    open,
    operatorId,
    operatorGroupId,
    updatedAt,
    updatedBy,
  },
}: RentalIncidentCardProps) => (
  <RouterCardLink
    isMultiAction
    to={`/incidents/${incidentId}`}
    className={classNames(open ? 'opacity-100' : 'opacity-50')}
  >
    <RentalIncidentRowLayout
      className="min-h-28 py-4"
      incident={
        <IncidentCardInfo open={open} title={title} description={description} />
      }
      assignee={
        <div className="text-sm text-neutral-400">
          <OperatorOrGroup
            operatorId={operatorId}
            operatorGroupId={operatorGroupId}
          />
        </div>
      }
      lastUpdated={
        <div>
          <DateTime date={updatedAt} />
          <ByOperator id={updatedBy} />
        </div>
      }
    />
  </RouterCardLink>
);
