import { useField, useFormContext } from '@cooltra/form';
import { InputError, SelectField, SelectFieldProps } from '@cooltra/ui';

import { ContractDetailsFormValues } from '../contract-details-form';

export type ContractServicePointFieldProps = Pick<
  SelectFieldProps,
  'isLoading' | 'options' | 'id' | 'className' | 'name' | 'label' | 'disabled'
> & {
  name: string;
  shouldReset: boolean;
};

export const ContractServicePointField = ({
  name,
  isLoading,
  options,
  shouldReset,
  ...rest
}: ContractServicePointFieldProps) => {
  const { isSubmitting, setValues, values } =
    useFormContext<ContractDetailsFormValues>();
  const { value, error, touched, setTouched } = useField(name);
  const handleChange = (val: string) => {
    if (shouldReset) {
      return setValues({
        ...values,
        pickup: val,
      });
    }
    setValues({
      ...values,
      pickup: val,
      dropOffDate: undefined,
      dropOffTime: '',
      pickUpDate: undefined,
      pickUpTime: '',
    });
  };

  return (
    <SelectField
      key={`${name}-${value}`}
      error={touched && error ? <InputError>{error}</InputError> : undefined}
      onChange={handleChange}
      value={value}
      onBlur={() => {
        setTouched(true);
      }}
      name={name}
      disabled={isSubmitting || isLoading}
      options={options}
      {...rest}
    />
  );
};
