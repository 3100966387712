import { createContext, ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactQrCode from 'react-qr-code';
import { Button, CopyButton, Modal } from '@cooltra/ui';
import {
  useContractPaymentsQuery,
  useContractQuery,
} from '@cooltra/station-based-api';

import { getEnvVariable } from '~/utils/environment';

import messages from './messages';

type StripePaymentQRContextValues = {
  openTicketQrModal: (testId: string) => void;
  ticketToMarkAsAwaitingForPayment: string;
};

export const StripePaymentQRContext =
  createContext<StripePaymentQRContextValues>({
    openTicketQrModal: () => {},
    ticketToMarkAsAwaitingForPayment: '',
  });

type StripePaymentQRProviderProps = {
  contractId: string;
  children: ReactNode;
};

export const StripePaymentQRProvider = ({
  contractId,
  children,
}: StripePaymentQRProviderProps) => {
  const [{ isOpen, ticketId }, setState] = useState({
    isOpen: false,
    ticketId: '',
  });

  const { data: contract } = useContractQuery(contractId);
  const {
    data: tickets = [],
    refetch,
    isRefetching,
  } = useContractPaymentsQuery(contractId);

  const ticketPending = tickets.find((ticket) => ticket.status === 'PENDING');
  const ticketToMarkAsAwaitingForPayment = ticketPending?.id || '';

  const onVerifyPaymentPaid = () => refetch();
  const openTicketQrModal = (ticketId: string) =>
    setState({
      ticketId,
      isOpen: true,
    });
  const handleClose = () => setState({ isOpen: false, ticketId: '' });

  const language = contract?.user?.language.toLowerCase() || 'en';
  const publicQRLink = `${getEnvVariable('COOLTRA_WEB_DOMAIN')}/${language}/contracts/${contractId}/pay-ticket/${ticketId}`;

  return (
    <StripePaymentQRContext.Provider
      value={{
        openTicketQrModal,
        ticketToMarkAsAwaitingForPayment,
      }}
    >
      {!!ticketId && (
        <Modal isOpen={isOpen} onRequestClose={handleClose}>
          <h2 className="text-xl font-semibold text-neutral-700 text-center">
            <FormattedMessage {...messages.stripeQRPaymentMethod} />
          </h2>
          <div className="pt-16 px-36" data-testid="STRIPE_QR">
            <ReactQrCode
              size={382}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={publicQRLink}
              viewBox="0 0 256 256"
            />
          </div>
          <div className="text-center py-9">
            <Button className="text-primary-500 font-semibold" emphasis="low">
              <CopyButton
                className="text-primary-500 font-semibold"
                value={publicQRLink}
                label={
                  <span className="text-primary-500 font-semibold pl-1">
                    <FormattedMessage {...messages.copyLink} />
                  </span>
                }
              />
            </Button>
          </div>
          <div className="w-full pb-12 text-center">
            <Button onClick={onVerifyPaymentPaid} loading={isRefetching}>
              <FormattedMessage {...messages.verifyIfPaid} />
            </Button>
          </div>
        </Modal>
      )}
      {children}
    </StripePaymentQRContext.Provider>
  );
};
