import {
  AlgorithmPrice,
  AccessoryPricesItem,
} from '@cooltra/station-based-api';

import { AccessoryPriceFormValues } from './form-details';

export const getFormValuesFromAccessoryDetails = (
  accessoryPrice: AccessoryPricesItem
): AccessoryPriceFormValues => {
  const getAlgorithmPrice = (): AlgorithmPrice => {
    if (!accessoryPrice.algorithmPrice) {
      return 'BASE';
    }

    return accessoryPrice.algorithmPrice;
  };

  const getPriceValue = (): string => {
    if (!accessoryPrice.price) {
      return '';
    }

    return accessoryPrice.price.value.toString();
  };

  return {
    price: getPriceValue(),
    algorithmName: getAlgorithmPrice(),
  };
};
