import { FormattedMessage } from 'react-intl';
import { InputLabel } from '@cooltra/ui';

import { FormNumberInputField } from '~/common';

import messages from './messages';

export const Mileage = () => (
  <FormNumberInputField
    name="mileage"
    id="mileage"
    className="w-60"
    label={
      <InputLabel htmlFor="mileage">
        <FormattedMessage {...messages.kilometers} />
      </InputLabel>
    }
  />
);
