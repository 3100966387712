import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteBtn: {
    defaultMessage: 'Delete location',
  },
  success: {
    defaultMessage: 'You have successfully deleted the location!',
  },
});
