import {
  useVehicleTechnicalInspectionsQuery,
  VehicleTechnicalInspectionItem,
} from '@cooltra/api';
import { List } from '@cooltra/ui';

import { ErrorPage, NoSearchResults } from '~/common';

import { VehicleTechnicalInspectionCard } from '../VehicleTechnicalInspectionCard';
import { VehicleTechnicalInspectionsListHeader } from '../VehicleTechnicalInspectionsListHeader/VehicleTechnicalInspectionsListHeader';

type VehicleTechnicalInspectionsListProps = {
  vehicleId: string;
};

export const VehicleTechnicalInspectionsList = ({
  vehicleId,
}: VehicleTechnicalInspectionsListProps) => {
  const { data, isLoading, isError } =
    useVehicleTechnicalInspectionsQuery(vehicleId);

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div data-testid="VEHICLE_TECHNICAL_INSPECTIONS_LIST">
      <List
        loadingRows={4}
        isLoading={isLoading}
        idProp="technicalInspectionId"
        data={data || []}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<VehicleTechnicalInspectionsListHeader />}
        renderRow={(
          vehicleTechnicalInspectionItem: VehicleTechnicalInspectionItem
        ) => (
          <VehicleTechnicalInspectionCard
            key={vehicleTechnicalInspectionItem.technicalInspectionId}
            {...vehicleTechnicalInspectionItem}
          />
        )}
      />
    </div>
  );
};
