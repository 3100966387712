import { defineMessages } from 'react-intl';
import { PromotionTarget } from '@cooltra/station-based-api';

export default defineMessages<PromotionTarget>({
  COUPON: {
    defaultMessage: 'Coupon',
  },
  WEB_OFFER: {
    defaultMessage: 'Web Offer',
  },
});
