import { ReactNode } from 'react';
import './vehicles-layout.css';

export type VehiclesLayoutProps = {
  renderNavbar: ReactNode;
  renderContent: ReactNode;
};

export const VehiclesLayout = ({
  renderNavbar,
  renderContent,
}: VehiclesLayoutProps) => (
  <div className="flex flex-col h-screen-content">
    <div className="bg-neutral-0 shadow-sm relative">
      <div className="flex justify-between items-center gap-10 h-20 px-4 lg:px-8">
        {renderNavbar}
      </div>
    </div>
    <div
      className="w-full flex flex-1 vehicles-content overflow-auto pt-4 lg:pt-8 px-4 lg:px-8"
      id="scrollable-container"
    >
      <div className="w-full h-full">{renderContent}</div>
    </div>
  </div>
);
