import { defineMessages } from 'react-intl';

export default defineMessages({
  payment: {
    defaultMessage: 'Payment',
  },
  billingReference: {
    defaultMessage: 'Billing reference',
  },
  paymentMethodNotFound: {
    defaultMessage: 'The payment method is not set up',
  },
});
