import { defineMessages } from 'react-intl';

export default defineMessages({
  language: {
    defaultMessage: 'Language',
  },
  driverLicenseNumber: {
    defaultMessage: 'License number',
  },
  residentialAddress: {
    defaultMessage: 'Residential address',
  },
  zip: {
    defaultMessage: 'Zip code',
  },
  city: {
    defaultMessage: 'City',
  },
  country: {
    defaultMessage: 'Residential country',
  },
  taxIdNumber: {
    defaultMessage: 'Tax ID number',
  },
  idCountry: {
    defaultMessage: 'ID Country',
  },
  dateOfBirth: {
    defaultMessage: 'Date of birth',
  },
});
