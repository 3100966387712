import { Marker } from 'react-map-gl';
import { useVehicleQuery, useVehicleStatusQuery, Vehicle } from '@cooltra/api';
import { Tooltip } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { NotAvailable, VehicleIcon } from '~/common';
import { thirtySeconds } from '~/utils/date';

import messages from './messages';

export type VehicleCurrentPositionMarkerProps = Pick<Vehicle, 'vehicleId'>;

export const VehicleCurrentPositionMarker = ({
  vehicleId,
}: VehicleCurrentPositionMarkerProps) => {
  const { data: vehicleStatus, isLoading } = useVehicleStatusQuery(vehicleId, {
    refetchInterval: thirtySeconds,
  });

  const { data: vehicle, isLoading: isVehicleLoading } =
    useVehicleQuery(vehicleId);

  if (
    !vehicle ||
    isLoading ||
    isVehicleLoading ||
    !vehicleStatus?.geoLocation?.latitude ||
    !vehicleStatus?.geoLocation?.longitude
  ) {
    return <></>;
  }

  return (
    <Marker
      latitude={vehicleStatus.geoLocation?.latitude}
      longitude={vehicleStatus.geoLocation?.longitude}
    >
      <Tooltip
        interactive
        button={
          <span className="flex justify-center w-16">
            <VehicleIcon
              size={32}
              operationalState={vehicle.operationalState}
              operationalConditions={vehicle.operationalConditions}
              bonusEnabled={false}
              model={vehicle.model}
              telematics={vehicle.telematics}
              batteryCharge={vehicleStatus.batteryCharge?.value}
            />
          </span>
        }
        content={
          <div className="px-5 py-4 w-40">
            <li className="flex gap-8 justify-between items-center text-xs">
              <span className="text-neutral-400">
                <FormattedMessage {...messages.speed} />
              </span>
              {vehicleStatus.speed != null ? (
                <span className="font-semibold text-neutral-800">
                  {`${vehicleStatus.speed.value} ${vehicleStatus.speed.unit}`}
                </span>
              ) : (
                <NotAvailable />
              )}
            </li>
          </div>
        }
      />
    </Marker>
  );
};
