import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'No invoices found',
  },
  content: {
    defaultMessage: 'This user is yet to receive her first invoice',
  },
});
