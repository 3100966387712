import { FormattedMessage } from 'react-intl';
import { Button, Placeholder } from '@cooltra/ui';

import messages from './messages';

export const AvailableVehicleCardLoading = () => {
  return (
    <div className="flex items-center py-7">
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <span className="font-semibold text-lg">
            {<Placeholder className="h-3 my-1.5 w-24" />}
          </span>
          <span className="font-normal text-lg">
            {<Placeholder className="h-3 my-1.5 w-24" />}
          </span>
        </div>
        <div>{<Placeholder className="h-3 my-1.5 w-24" />}</div>
      </div>
      <Button className="ml-auto" disabled>
        <FormattedMessage {...messages.assign} />
      </Button>
    </div>
  );
};
