import { defineMessages } from 'react-intl';

export default defineMessages({
  waitingStripeWithhold: {
    defaultMessage: 'Waiting for Stripe withhold',
  },
  pendingDeposit: {
    defaultMessage: 'Pending deposit',
  },
  showStripeQR: {
    defaultMessage: 'Show QR',
  },
  verify: {
    defaultMessage: 'Verify deposit',
  },
});
