import { defineMessages } from 'react-intl';

export default defineMessages({
  incident: {
    defaultMessage: 'Incident',
  },
  assignee: {
    defaultMessage: 'Assignee',
  },
  updated: {
    defaultMessage: 'Updated',
  },
  context: {
    defaultMessage: 'Context',
  },
});
