import { FormattedMessage, useIntl } from 'react-intl';
import { useTakeVehicleMutation, Vehicle } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { AxiosError } from 'axios';
import { Button } from '@cooltra/ui';

import { useNotification } from '~/hooks';
import { useVehiclePolling } from '~/libs/polling-data';

import messages from './messages';
import { getErrorMessageForTakingVehicle } from './errors';

export type TakeProps = Pick<Vehicle, 'vehicleId'>;

export const Take = ({ vehicleId }: TakeProps) => {
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthClaimsQuery();
  const { activatePollingVehicle } = useVehiclePolling();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const { isPending, mutate } = useTakeVehicleMutation(vehicleId, {
    onSuccess: () => {
      addSuccessNotification(formatMessage(messages.successfullyTaken));
      activatePollingVehicle(vehicleId);
    },
    onError: (error: AxiosError) => {
      const errorMessageFromStatusCode = getErrorMessageForTakingVehicle(
        error.response
      );

      addErrorNotification(
        errorMessageFromStatusCode.avoidTranslation
          ? errorMessageFromStatusCode.defaultMessage
          : formatMessage(errorMessageFromStatusCode)
      );
    },
  });

  const takeVehicle = () => mutate();

  return (
    <div data-testid="TAKE_VEHICLE">
      {hasPermission('transfer:vehicles') && (
        <Button
          size="sm"
          emphasis="high"
          loading={isPending}
          disabled={isPending}
          onClick={takeVehicle}
        >
          <FormattedMessage {...messages.takeVehicle} />
        </Button>
      )}
    </div>
  );
};
