import { Vehicle } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { StaticField } from '~/common';

import messages from './messages';

export type InsuranceExpirationStaticProps = {
  value: Vehicle['insuranceExpiresAt'];
};

export const InsuranceExpirationStatic = ({
  value,
}: InsuranceExpirationStaticProps) => (
  <StaticField
    renderLabel={<FormattedMessage {...messages.insuranceExpiration} />}
    renderValue={value}
  />
);
