import { FormattedMessage, useIntl } from 'react-intl';
import { AxiosError } from 'axios';
import { Button } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';
import { useRejectMutation, User } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { useFormContext } from '@cooltra/form';
import { useNotificationContext } from '@cooltra/notifications';

import { ReviewFormValues } from '../review-form-types';
import { getRejectPayload } from '../payload';

import { RejectOverrideModal } from './RejectOverrideModal';
import messages from './messages';

export type RejectOverrideProps = Pick<User, 'version'> & {
  userId: string;
  onFinish: () => void;
};

export const RejectOverride = ({
  version,
  userId,
  onFinish,
}: RejectOverrideProps) => {
  const [isOpen, { toggleOff, toggleOn }] = useToggle();

  const { formatMessage } = useIntl();

  const { addNotification } = useNotificationContext();

  const { setSubmitting, values } = useFormContext<ReviewFormValues>();

  const onError = (error: AxiosError) => {
    const status = getErrorStatus(error);
    if (status === 409) {
      addNotification({
        variant: 'danger',
        text: formatMessage(messages.conflictErrorMessage),
      });
    } else {
      addNotification({
        variant: 'danger',
        text: formatMessage(messages.errorMessage),
      });
    }
  };

  const { isPending, mutate } = useRejectMutation(userId, {
    onMutate: () => setSubmitting(true),
    onSuccess: () => {
      toggleOff();
      addNotification({
        variant: 'danger',
        text: formatMessage(messages.userRejected),
      });
      onFinish();
    },
    onError,
    onSettled: () => setSubmitting(false),
  });

  const handleTogglingOff = () => {
    if (isPending) {
      return;
    }
    toggleOff();
  };

  return (
    <>
      <div className="flex justify-end" data-testid="REJECT_OVERRIDE">
        <Button variant="danger" emphasis="low" onClick={toggleOn}>
          <FormattedMessage {...messages.rejectButton} />
        </Button>
      </div>
      {isOpen && (
        <RejectOverrideModal
          isOpen={isOpen}
          onClose={handleTogglingOff}
          onReject={() => mutate(getRejectPayload(values, ['other'], version))}
          isRejecting={isPending}
        />
      )}
    </>
  );
};
