import { defineMessages } from 'react-intl';

export default defineMessages({
  map: {
    defaultMessage: 'Map',
  },
  streetView: {
    defaultMessage: 'Street View',
  },
  streetViewDoesNotExist: {
    defaultMessage:
      'Street view does not exist for the current vehicle position',
  },
});
