import { Divider, InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import { FormPatternInputField } from '~/common';

import messages from './messages';

export const DateRange = () => {
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthClaimsQuery();

  return (
    <>
      <FormPatternInputField
        name="dateFrom"
        id="dateFrom"
        placeholder={formatMessage(messages.placeholder)}
        label={
          <InputLabel htmlFor="dateFrom">
            <FormattedMessage {...messages.startDate} />
          </InputLabel>
        }
        disabled={!hasPermission('write:prices')}
        className="w-32"
        format="##-##"
        mask="_"
      />
      <div className="w-12 mt-6">
        <Divider />
      </div>
      <FormPatternInputField
        name="dateUntil"
        id="dateUntil"
        placeholder={formatMessage(messages.placeholder)}
        label={
          <InputLabel htmlFor="dateUntil">
            <FormattedMessage {...messages.endDate} />
          </InputLabel>
        }
        disabled
        className="w-32"
        format="##-##"
        mask="_"
      />
    </>
  );
};
