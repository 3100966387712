import {
  BonusZone,
  useBonusZoneToggleMutation,
  useDeleteBonusZoneMutation,
} from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { Badge, Card, ToggleField } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChangeEventHandler, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useToggle } from '@cooltra/hooks';
import { classNames } from '@cooltra/utils';

import { Amount, DeleteModal } from '~/common';
import { useNotification } from '~/hooks';

import { useFocusedZone } from '../../FocusedZone';

import messages from './messages';

export type BonusZoneCardProps = BonusZone & {
  deleteEnabled?: boolean;
};

export const BonusZoneCard = ({
  title,
  enabled,
  discount,
  bonusZoneId,
  system,
  deleteEnabled = false,
}: BonusZoneCardProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthClaimsQuery();

  const { setBonusZoneIdFocused } = useFocusedZone();

  const { addSuccessNotification, addErrorNotification } = useNotification();

  const [
    isDeleteModalOpen,
    { toggleOn: toggleDeleteModalOn, toggleOff: toggleDeleteModalOff },
  ] = useToggle();

  const [bonusZoneEnabled, setBonusZoneEnabled] = useState(enabled);
  const toggleQuery = useBonusZoneToggleMutation(system, bonusZoneId);

  const deleteQuery = useDeleteBonusZoneMutation(system, bonusZoneId);

  const deleteBonusZone = () =>
    deleteQuery
      .mutateAsync()
      .then(() => toggleDeleteModalOff())
      .catch(() => addErrorNotification());

  const disableBonusZone = () =>
    toggleQuery
      .mutateAsync({ enabled: false })
      .then(() =>
        addSuccessNotification(formatMessage(messages.disablingSuccess))
      )
      .catch(() => {
        setBonusZoneEnabled(true);
        addErrorNotification();
      })
      .finally(() =>
        queryClient.invalidateQueries({ queryKey: ['bonus-zones', system] })
      );

  const enableBonusZone = () =>
    toggleQuery
      .mutateAsync({ enabled: true })
      .then(() =>
        addSuccessNotification(formatMessage(messages.enablingSuccess))
      )
      .catch((error) => {
        setBonusZoneEnabled(false);
        switch (getErrorStatus(error)) {
          case 409:
            addErrorNotification(formatMessage(messages.collisionError));
            break;
          default:
            addErrorNotification();
            break;
        }
      })
      .finally(() =>
        queryClient.invalidateQueries({ queryKey: ['bonus-zones', system] })
      );

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked } = event.target;

    if (checked) {
      enableBonusZone();
    } else {
      disableBonusZone();
    }

    setBonusZoneEnabled(checked);
  };

  const handleOnMouseOver = () => {
    setBonusZoneIdFocused(bonusZoneId);
  };
  const handleOnMouseLeave = () => {
    setBonusZoneIdFocused(undefined);
  };

  return (
    <Card
      className={classNames('px-4', deleteEnabled ? 'py-4' : 'py-6')}
      onMouseOver={handleOnMouseOver}
      onMouseLeave={handleOnMouseLeave}
      data-testid={`BONUS_ZONE_${bonusZoneId}`}
    >
      <div className="flex items-center justify-between">
        <div className="flex-1 text-ellipsis overflow-hidden">
          <span className="block text-neutral-800 font-semibold text-sm">
            {title}
          </span>
          {deleteEnabled && (
            <>
              <button
                onClick={toggleDeleteModalOn}
                className="text-danger-500 hover:text-danger-700 focus:text-danger-700 font-semibold text-sm transition-colors py-1"
              >
                <FormattedMessage {...messages.delete} />
              </button>
              <DeleteModal
                onClose={toggleDeleteModalOff}
                onConfirm={deleteBonusZone}
                isOpen={isDeleteModalOpen}
                isDeleting={deleteQuery.isPending}
              />
            </>
          )}
        </div>
        <Amount className="px-4" value={discount} currency="EUR" />
        {bonusZoneEnabled ? (
          <Badge variant="success">
            <FormattedMessage {...messages.active} />
          </Badge>
        ) : (
          <Badge>
            <FormattedMessage {...messages.inactive} />
          </Badge>
        )}
        {hasPermission('switch:bonus_zones') && (
          <div className="pl-4">
            <ToggleField
              data-testid="BONUS_ZONE_TOGGLE"
              id={`toggle-bonus-zone-${bonusZoneId}`}
              checked={bonusZoneEnabled}
              onChange={handleOnChange}
            />
          </div>
        )}
      </div>
    </Card>
  );
};
