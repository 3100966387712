import { useTagsQuery } from '@cooltra/api';
import { useOperatorsQuery, useAuthClaimsQuery } from '@cooltra/auth-api';

import { useLocations } from '~/hooks';

export const PrefetchQueries = () => {
  const { hasPermission } = useAuthClaimsQuery();

  useLocations({
    enabled: hasPermission('read:locations'),
  });
  useTagsQuery();
  useOperatorsQuery();

  return null;
};
