import { HTMLAttributes, ReactNode } from 'react';
import { Card } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';

export type CardLayoutProps = HTMLAttributes<HTMLDivElement> & {
  icon: ReactNode;
  heading: ReactNode;
};

export const CardLayout = ({
  icon,
  heading,
  children,
  className,
  ...rest
}: CardLayoutProps) => (
  <Card className="p-4">
    <div className={classNames('flex pb-4 pr-4', className)} {...rest}>
      <div className="w-10 shrink-0">
        <div className="p-0.5">
          <span className="text-neutral-700 dib text-2xl">{icon}</span>
        </div>
      </div>
      <div className="grow">
        <h3 className="text-neutral-700 text-xl mb-4 font-bold">{heading}</h3>
        {children}
      </div>
    </div>
  </Card>
);
