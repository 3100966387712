import { FormattedMessage, useIntl } from 'react-intl';
import { allVehicleModelsList, Model } from '@cooltra/api';
import { useField, useFormContext } from '@cooltra/form';
import { InputError, InputLabel, MultiSelectField } from '@cooltra/ui';

import { PromotionStateType } from '../../PromotionState/PromotionState';
import { PromotionFormValues } from '../../PromotionForm';
import { useModelOptions } from '../../../../Vehicle';

import messages from './messages';

export const TargetVehicleModels = () => {
  const { formatMessage } = useIntl();
  const vehicleModelsOptions = useModelOptions();
  const { values, isSubmitting, initialValues } =
    useFormContext<PromotionFormValues>();
  const { value, error, touched, setValue, setTouched } = useField(
    'targetVehicleModels'
  );
  const isModelIncludedOnInitialValues = (vehicleModel: Model) =>
    initialValues.targetVehicleModels.includes(vehicleModel);
  const isPromotionSavedWithAllVehicleModels =
    initialValues.targetVehicleModels.length === allVehicleModelsList.length;
  const editingExpiredPromotion =
    values.editMode && values.stateType === PromotionStateType.EXPIRED;

  if (values.promotionTypeMode !== 'discount') {
    return <></>;
  }

  return (
    <MultiSelectField
      id="targetVehicleModels"
      name={'targetVehicleModels'}
      options={vehicleModelsOptions}
      value={isPromotionSavedWithAllVehicleModels ? [] : value}
      disabled={isSubmitting || values.editMode || editingExpiredPromotion}
      placeholder={formatMessage(messages.allVehicles)}
      onChange={(selectedOptions, actionMeta) => {
        let values: string[] = [];

        switch (actionMeta.action) {
          case 'remove-value':
          case 'pop-value':
            if (
              isModelIncludedOnInitialValues(
                actionMeta.removedValue.value as Model
              )
            ) {
              return;
            }

            values = selectedOptions.map(({ value }) => value);
            break;
          case 'clear':
            values = initialValues.targetVehicleModels;
            break;
          default:
            values = selectedOptions.map(({ value }) => value);
            break;
        }

        setValue(values);
      }}
      onBlur={() => setTouched(true)}
      isClearable={value.some(
        (model: Model) => !isModelIncludedOnInitialValues(model)
      )}
      label={
        <InputLabel htmlFor="targetVehicleModels">
          <FormattedMessage {...messages.elegibleVehicles} />
        </InputLabel>
      }
      error={touched && error ? <InputError>{error}</InputError> : undefined}
    />
  );
};
