import { FormattedMessage, useIntl } from 'react-intl';
import { FormButton, FormHelpers } from '@cooltra/form';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { usePassMutation } from '@cooltra/api';
import { useNavigate } from 'react-router-dom';
import { useRouteState } from '@cooltra/navigation';

import { BackLink, Four0Three } from '~/common';
import { useNotification } from '~/hooks';

import {
  formatPassFormValues,
  initialPassFormValues,
  PassForm,
  PassFormValues,
} from '../PassForm';
import { PassFormFields } from '../PassFormFields';

import messages from './messages';

export const CreatePass = () => {
  const navigate = useNavigate();
  const routeState = useRouteState<'from'>();

  const { hasPermission } = useAuthClaimsQuery();
  const { mutateAsync } = usePassMutation();
  const { formatMessage } = useIntl();
  const { addSuccessNotification, addErrorNotification } = useNotification();

  if (!hasPermission('write:offers')) {
    return <Four0Three />;
  }

  const onSubmit = (
    values: PassFormValues,
    { resetForm }: FormHelpers<PassFormValues>
  ) =>
    mutateAsync(formatPassFormValues(values))
      .then(({ passId }) => {
        resetForm(initialPassFormValues);
        addSuccessNotification(formatMessage(messages.success));
        navigate(`/offers/passes/${passId}/edit`);
      })
      .catch(() => addErrorNotification());

  const navigateBack = () =>
    routeState?.from === '/offers/passes'
      ? navigate(-1)
      : navigate('/offers/passes');

  return (
    <div className="container min-w-screen-sm max-w-3xl py-12">
      <BackLink onClick={navigateBack}>
        <FormattedMessage {...messages.backToPasses} />
      </BackLink>
      <h1 className="text-3xl mb-9">
        <FormattedMessage {...messages.createPass} />
      </h1>
      <PassForm initialValues={initialPassFormValues} onSubmit={onSubmit}>
        <PassFormFields />
        <div className="flex justify-end">
          <FormButton>
            <FormattedMessage {...messages.createPass} />
          </FormButton>
        </div>
      </PassForm>
    </div>
  );
};
