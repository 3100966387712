import { IntlShape } from 'react-intl';

import { createSetError, validateDate, validateRequired } from '~/validation';
import { TechnicalInspectionFormValues } from '~/common';

import messages from './messages';

type TechnicalInspectionFormFieldName = keyof TechnicalInspectionFormValues;
type TechnicalFormErrors = Partial<
  Record<TechnicalInspectionFormFieldName, string | undefined>
>;
export const editServiceFormValidation =
  (intl: IntlShape) => (values: TechnicalInspectionFormValues) => {
    const errors: TechnicalFormErrors = {};
    const setError = createSetError(errors);

    if (values.servicedBy || values.servicedAt) {
      setError('servicedBy', validateRequired(intl, values.servicedBy));
      setError('servicedAt', validateRequired(intl, values.servicedAt));
    }

    if (values.scheduledAt) {
      setError(
        'scheduledAt',
        validateDate(intl, values.scheduledAt)
          ? intl.formatMessage(messages.invalidFormat)
          : undefined
      );
    }

    if (values.servicedAt) {
      setError(
        'servicedAt',
        validateDate(intl, values.servicedAt)
          ? intl.formatMessage(messages.invalidFormat)
          : undefined
      );
    }

    return errors;
  };
