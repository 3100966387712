import { useState } from 'react';

import { useRoleNames } from '../useRoleNames/useRoleNames';

export type MapView = 'by-status' | 'by-battery';

const localStorageKey = 'map-view';

export const usePreferredMapView = (): [
  MapView,
  (mapView: MapView) => void
] => {
  const roleNames = useRoleNames();

  const getDefaultMapViewByRole = (): MapView => {
    if (roleNames.includes('Swapper')) {
      return 'by-battery';
    }

    return 'by-status';
  };

  const isMapViewValueValid = (mapView: string | null): boolean => {
    if (!mapView) {
      return false;
    }

    const validMapViewValues: { [key in MapView]: boolean } = {
      'by-battery': true,
      'by-status': true,
    };

    return validMapViewValues[mapView as MapView];
  };

  const getPreferredMapView = (): MapView => {
    const savedMapView: MapView | null = localStorage.getItem(
      localStorageKey
    ) as MapView;

    return isMapViewValueValid(savedMapView)
      ? savedMapView
      : getDefaultMapViewByRole();
  };

  const [preferredMapView, setPreferredMapView] = useState<MapView>(
    getPreferredMapView()
  );

  const updateMapView = (mapView: MapView) => {
    localStorage.setItem(localStorageKey, mapView);
    setPreferredMapView(mapView);
  };

  return [preferredMapView, updateMapView];
};
