import { defineMessages } from 'react-intl';

export default defineMessages({
  mainDriver: {
    defaultMessage: 'Main driver',
  },
  additionalDriver: {
    defaultMessage: 'Additional driver',
  },
});
