import { defineMessages } from 'react-intl';

export default defineMessages({
  promotions: {
    defaultMessage: 'Promotions',
  },
  cooltraForEmployees: {
    defaultMessage: 'Cooltra for employees',
  },
  stationBased: {
    defaultMessage: 'Station-based',
  },
  packs: {
    defaultMessage: 'Packs',
  },
  passes: {
    defaultMessage: 'Passes',
  },
});
