import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { VehicleRowLayout } from '../VehicleRowLayout';

import messages from './messages';

export const VehiclesListHeader = () => (
  <VehicleRowLayout
    vehicle={
      <ListColumnLabel>
        <FormattedMessage {...messages.vehicle} />
      </ListColumnLabel>
    }
    servicePoint={
      <ListColumnLabel>
        <FormattedMessage {...messages.servicePoint} />
      </ListColumnLabel>
    }
  />
);
