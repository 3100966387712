import { Legend } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  FormDateInputField,
  FormTimeInputField,
} from '../../../../FormattedInput';

import messages from './messages';

export type ValidUntilProps = {
  disabled: boolean;
};

export const ValidUntil = ({ disabled }: ValidUntilProps) => {
  const { formatMessage } = useIntl();

  return (
    <fieldset className="flex gap-2" data-testid="VALID_UNTIL">
      <Legend className="mb-1">
        <FormattedMessage {...messages.redeemableUntil} />
      </Legend>
      <FormDateInputField
        disabled={disabled}
        className="w-32"
        name="validUntilDate"
        aria-label={formatMessage(messages.date)}
      />
      <FormTimeInputField
        disabled={disabled}
        className="w-20"
        name="validUntilTime"
        aria-label={formatMessage(messages.time)}
      />
    </fieldset>
  );
};
