import { UserCredit } from '@cooltra/api';
import { Card } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';

import { Amount, MultilineDateTime, RoundOperatorInitials } from '~/common';
import {
  isCreditDeleted,
  isCreditExpired,
  isCreditUsed,
} from '~/utils/credits';

import { CreditRowLayout } from '../CreditRowLayout';
import { CreditState } from '../CreditState/CreditState';

import messages from './messages';

export type CreditCardProps = {
  credit: UserCredit;
  onDelete: () => void;
};

export const CreditCard = ({
  credit: {
    createdAt,
    title,
    createdBy,
    amount,
    remainingAmount,
    expiresAt,
    deletedAt,
    deletedBy,
  },
  onDelete,
}: CreditCardProps) => (
  <Card
    className={classNames(
      'text-sm text-neutral-800',
      (isCreditDeleted(deletedAt) ||
        isCreditExpired(expiresAt) ||
        isCreditUsed(remainingAmount)) &&
        'opacity-50'
    )}
  >
    <CreditRowLayout
      className="h-16"
      created={
        <div className="flex items-center gap-3">
          {createdBy && <RoundOperatorInitials operatorId={createdBy} />}
          <MultilineDateTime date={createdAt} />
        </div>
      }
      title={<span>{title}</span>}
      amount={<Amount className="font-semibold" {...amount} />}
      remainingAmount={<Amount {...remainingAmount} />}
      expiresAt={
        expiresAt ? (
          <MultilineDateTime date={expiresAt} />
        ) : (
          <FormattedMessage {...messages.noExpiration} />
        )
      }
      state={
        <CreditState
          deletedAt={deletedAt}
          deletedBy={deletedBy}
          expiresAt={expiresAt}
          remainingAmount={remainingAmount}
          onDelete={onDelete}
        />
      }
    />
  </Card>
);
