import { Model } from '@cooltra/api';
import { ProgressBar } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { chargePerLevelRanges } from '@cooltra/vehicle-utils';

export type BatteryStateProps = {
  value: number;
  className?: string;
  model: Model;
};

export const BatteryState = ({
  value,
  className,
  model,
}: BatteryStateProps) => {
  const chargePerLevelRange = chargePerLevelRanges[model];
  const batterySteps = {
    [chargePerLevelRange.low.max]: 'bg-danger-400',
    [chargePerLevelRange.medium.max]: 'bg-warning-600',
    [chargePerLevelRange.neutral.max]: 'bg-neutral-alternative-800',
    [chargePerLevelRange.high.max]: 'bg-success-300',
  };

  return (
    <div className={classNames('flex w-20 items-center gap-1', className)}>
      <p className="text-right text-xs font-semibold text-neutral-800">
        {value}%
      </p>
      <div className="h-3 w-full">
        <ProgressBar value={value} steps={batterySteps} />
      </div>
    </div>
  );
};
