import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { FormSelectField } from '@cooltra/form';

import { FormFieldWithName } from '~/utils/form';

import { useSystemOptions } from '../../../System';

import messages from './messages';

export const System = ({ name = 'system' }: FormFieldWithName) => {
  const options = useSystemOptions();
  return (
    <FormSelectField
      name={name}
      id="system"
      label={
        <InputLabel htmlFor="system">
          <FormattedMessage {...messages.system} />
        </InputLabel>
      }
      options={options}
    />
  );
};
