import { defineMessages } from 'react-intl';

export default defineMessages({
  tags: {
    defaultMessage: 'Tags',
  },
  noTags: {
    defaultMessage: 'No tags',
  },
});
