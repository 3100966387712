import { classNames } from '@cooltra/utils';

export type RentalTimelineTickProps = {
  variant?: 'neutral' | 'danger';
};

export const RentalTimelineTick = ({
  variant = 'neutral',
}: RentalTimelineTickProps) => (
  <span
    className={classNames(
      'block w-3 h-0.5 relative translate-x-1/2 opacity-20',
      variant === 'neutral' ? 'bg-neutral-700' : 'bg-danger-700'
    )}
  />
);
