import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';
import { useContractPendingDepositMutation } from '@cooltra/station-based-api';

import { StripeDepositQRContext } from '../../StripeDepositQRProvider/StripeDepositQRProvider';
import { DepositFormValues } from '../../deposit-form';
import messages from '../messages';

type StripeQRButtonProps = {
  contractId: string;
  amount: string;
  onCloseModal: () => void;
};

export const StripeQRButton = ({
  contractId,
  amount,
  onCloseModal,
}: StripeQRButtonProps) => {
  const { mutateAsync, isPending } =
    useContractPendingDepositMutation(contractId);
  const { initialValues, resetForm } = useFormContext<DepositFormValues>();
  const { openWithDepositId } = useContext(StripeDepositQRContext);

  const handleStripeQRSubmit = async () =>
    mutateAsync({
      amount: Number(amount),
    }).then(({ depositId }) => {
      openWithDepositId(depositId);
      resetForm(initialValues);
      onCloseModal();
    });

  return (
    <Button onClick={handleStripeQRSubmit} loading={isPending} emphasis="high">
      <FormattedMessage {...messages.seeStripeQR} />
    </Button>
  );
};
