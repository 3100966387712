import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '../../List';
import { SuspensionRowLayout } from '../SuspensionRowLayout/SuspensionRowLayout';

import messages from './messages';

export type SuspensionsListHeaderProps = {
  showClear: boolean;
};

export const SuspensionsListHeader = ({
  showClear,
}: SuspensionsListHeaderProps) => (
  <SuspensionRowLayout
    type={
      <ListColumnLabel>
        <FormattedMessage {...messages.type} />
      </ListColumnLabel>
    }
    reason={
      <ListColumnLabel>
        <FormattedMessage {...messages.reason} />
      </ListColumnLabel>
    }
    created={
      <ListColumnLabel>
        <FormattedMessage {...messages.created} />
      </ListColumnLabel>
    }
    clear={showClear && <span />}
  />
);
