import { ChangeEvent, forwardRef, useEffect, useRef } from 'react';
import {
  InputError,
  InputField,
  InputFieldProps,
  InputLabel,
  Spinner,
} from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useCheckUserQuery } from '@cooltra/api';
import { useField, useFormContext } from '@cooltra/form';

import messages from './messages';

export type ValidatedDriverLicenseNumberProps = Omit<
  InputFieldProps,
  'valid' | 'invalid' | 'onChange' | 'onBlur' | 'value' | 'error' | 'name'
> & {
  userId: string;
};

export const ValidatedDriverLicenseNumber = forwardRef<
  HTMLInputElement,
  ValidatedDriverLicenseNumberProps
>(({ userId, ...rest }, ref) => {
  const timeout = useRef<NodeJS.Timeout>();

  const { isSubmitting, isFormDisabled } = useFormContext();
  const { value, error, touched, setValue, setTouched } = useField(
    'driverLicenseNumber'
  );

  const { refetch, data, fetchStatus } = useCheckUserQuery(
    userId,
    {
      driverLicenseNumber: value,
    },
    {
      enabled: false,
    }
  );

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      target.value && !error && refetch();
      setTouched(true);
    }, 1000);
  };

  const handleBlur = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      value && !error && refetch();
    }
    setTouched(true);
  };

  useEffect(
    () => () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    },
    []
  );

  return (
    <InputField
      error={
        data?.driverLicenseNumber !== false && touched && error ? (
          <InputError>{error}</InputError>
        ) : undefined
      }
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      ref={ref}
      disabled={isFormDisabled || isSubmitting}
      trailingIcon={fetchStatus === 'fetching' && <Spinner />}
      id="driver-license-number"
      label={
        <InputLabel htmlFor="driver-license-number">
          <FormattedMessage {...messages.driverLicenseNumber} />
        </InputLabel>
      }
      {...rest}
    />
  );
});
