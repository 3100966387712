import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteService: {
    defaultMessage: 'Delete service',
  },
  success: {
    defaultMessage: 'You have successfully deleted the service!',
  },
});
