import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'No rental passes found',
  },
  content: {
    defaultMessage: 'This user has not yet redeemed any rental pass',
  },
});
