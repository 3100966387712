import { FormattedMessage, useIntl } from 'react-intl';
import { useDeletePenaltyMutation } from '@cooltra/station-based-api';
import { Button } from '@cooltra/ui';

import { useNotification } from '~/hooks';

import messages from './messages';

export type RemovePenaltyButtonProps = {
  contractId: string;
  penaltyId: string;
  disabled?: boolean;
};

export const RemovePenaltyButton = ({
  contractId,
  penaltyId,
  disabled = false,
}: RemovePenaltyButtonProps) => {
  const intl = useIntl();
  const { mutateAsync, isPending } = useDeletePenaltyMutation(contractId);
  const { addSuccessNotification, addErrorNotification } = useNotification();

  const removePenalty = (penaltyId: string) => () => {
    mutateAsync({ penaltyId })
      .then(() => {
        addSuccessNotification(
          intl.formatMessage(messages.removedSuccessfully)
        );
      })
      .catch(() => {
        addErrorNotification();
      });
  };

  return (
    <Button
      variant="danger"
      emphasis="low"
      size="sm"
      loading={isPending}
      onClick={removePenalty(penaltyId)}
      disabled={disabled}
    >
      <FormattedMessage {...messages.remove} />
    </Button>
  );
};
