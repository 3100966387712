import { defineMessages } from 'react-intl';

export default defineMessages({
  changeLocation: {
    defaultMessage: 'Change location',
  },
  success: {
    defaultMessage: `Vehicles were moved to "{location}" successfully`,
  },
});
