import { FormattedMessage } from 'react-intl';
import { RouterNavLink } from '@cooltra/navigation';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { stringifyParams } from '@cooltra/utils';

import messages from '../messages';

export const Vehicles = () => {
  const { hasPermission } = useAuthClaimsQuery();

  if (!hasPermission('read:vehicles')) {
    return <></>;
  }

  const defaultVehiclesParams = {
    system: [],
    model: [],
    telematics: [],
    location: [],
    sort: 'externalId.asc',
    cityHallPublished: '',
    minBatteryCharge: 0,
    maxBatteryCharge: 100,
    keyword: '',
    taskOperatorGroupId: [],
    taskOperatorId: [],
    tagId: [],
    operationalCondition: [],
  };

  return (
    <li>
      <RouterNavLink to={`/vehicles?${stringifyParams(defaultVehiclesParams)}`}>
        <FormattedMessage {...messages.vehicles} />
      </RouterNavLink>
    </li>
  );
};
