import { defineMessages } from 'react-intl';
import { AccessoryName } from '@cooltra/utils';

export default defineMessages<AccessoryName>({
  FAST_FERRY_TO_FORMENTERA_BALEARIA: {
    defaultMessage: 'Ferry to Formentera: Discover (30 min)',
  },
  SECOND_HELMET: {
    defaultMessage: 'Second helmet',
  },
  GLOVES: {
    defaultMessage: 'Gloves',
  },
  STICKERS_CUSTOMIZATION: {
    defaultMessage: 'Stickers customization',
  },
  ASISTENCIA_EXTENDIDA: {
    defaultMessage: 'Extended assistance',
  },
  CONDUCTOR_ADICIONAL: {
    defaultMessage: 'Additional driver',
  },
  COMFORT_PACK: {
    defaultMessage: 'Pack Comfort',
  },
  FERRY_TO_FORMENTERA_SEA_EXPERIENCE: {
    defaultMessage: 'Ferry to Formentera: Sea Experience (45 min)',
  },
  BUNDLE_FERRY_TO_FORMENTERA: {
    defaultMessage: 'Scooter & Economic Ferry Formentera (Sea Experience)',
  },
  SCOOTER_PICK_UP: {
    defaultMessage: 'Return',
  },
  TICKETS_JULIA_TRAVEL: {
    defaultMessage: 'Julia Travel Tickets',
  },
  SCOOTER_DELIVERY: {
    defaultMessage: 'Delivery',
  },
  DELIVERIES: {
    defaultMessage: 'Deliveries',
  },
  LOCK: {
    defaultMessage: 'Lock',
    id: 'accessoriesLock',
  },
  SILLA_PARA_NIÑOS: {
    defaultMessage: 'Child seat',
  },
  CANCELACION_LAST_MINUTE: {
    defaultMessage: 'Last minute cancellation',
  },
  TOP_CASE: {
    defaultMessage: 'Top case',
  },
  OTHER_EXTRAS: {
    defaultMessage: 'Other extras',
  },
  OTHER_ACCESSORIES: {
    defaultMessage: 'Other accessories',
  },
  SOPORTE_TABLA_DE_SURF: {
    defaultMessage: 'Surfboard rack',
  },
  PHONE_HOLDER: {
    defaultMessage: 'Mobile phone holder',
  },
  WINDSHIELD: {
    defaultMessage: 'Windshield',
  },
  PACK_SECOND_HELMET_TOP_CASE_LOCK: {
    defaultMessage: 'Second Helmet + Trunk + Lock Pack',
  },
  SCOOTER_LEG_COVER_BLANKET: {
    defaultMessage: 'Scooter windshield blanket',
  },
  GPS: {
    defaultMessage: 'GPS',
  },
});
