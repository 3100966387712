import { defineMessages } from 'react-intl';

export default defineMessages({
  minutesAgo: {
    defaultMessage: '{minutes, plural, one {# minute} other {# minutes}} ago',
  },
  lessThanAMinuteAgo: {
    defaultMessage: 'less than a minute ago',
  },
  hoursAgo: {
    defaultMessage: '{hours, plural, one {# hour} other {# hours}} ago',
  },
});
