import { StationBasedLanguageCode } from '@cooltra/countries';
import { FormInputField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { HTMLAttributes, forwardRef } from 'react';

import { titleLabels } from './messages';

export type TranslationSectionProps = HTMLAttributes<HTMLDivElement> & {
  language: StationBasedLanguageCode;
  disabled: boolean;
};

export const TranslationsSection = forwardRef<
  HTMLDivElement,
  TranslationSectionProps
>(({ language, disabled, ...rest }, ref) => (
  <div {...rest}>
    <div className="grid gap-y-10 px-10 pb-10 pt-2" ref={ref}>
      <FormInputField
        name={`titleTranslations.${language}`}
        id={`title-translations-${language}`}
        disabled={disabled}
        label={
          <InputLabel htmlFor={`title-translations-${language}`}>
            <FormattedMessage {...titleLabels[language]} />
          </InputLabel>
        }
      />
    </div>
  </div>
));
