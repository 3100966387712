import { defineMessages } from 'react-intl';

export default defineMessages({
  promotion: {
    defaultMessage: 'Promotion',
  },
  subscription: {
    defaultMessage: 'Subscription',
  },
  redeemableFrom: {
    defaultMessage: 'Redeemable from',
  },
  redeemableUntil: {
    defaultMessage: 'Redeemable until',
  },
  uses: {
    defaultMessage: 'Uses',
  },
  state: {
    defaultMessage: 'State',
  },
});
