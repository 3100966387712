import { useIncidentQuery } from '@cooltra/api';

import { IncidentCard, IncidentCardLoading } from './IncidentCard';

export type ProblemReportIncidentProps = {
  incidentId: string;
};

export const ProblemReportIncident = ({
  incidentId,
}: ProblemReportIncidentProps) => {
  const { data: incident } = useIncidentQuery(incidentId);
  return incident ? (
    <IncidentCard incident={incident} />
  ) : (
    <IncidentCardLoading />
  );
};
