import { defineMessages } from 'react-intl';

export default defineMessages({
  saveChanges: {
    defaultMessage: 'Save changes',
  },
  duplicated: {
    defaultMessage: 'It has already been taken',
  },
  success: {
    defaultMessage: 'You have successfully edited the vehicle!',
  },
  vehicleInformation: {
    defaultMessage: 'Vehicle information',
  },
});
