import { HTMLAttributes } from 'react';
import { Icon } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';

export type PhotoIconProps = HTMLAttributes<HTMLDivElement>;

export const PhotoIcon = ({ children, className, ...rest }: PhotoIconProps) => (
  <div
    className={classNames(
      'relative w-8 h-8 overflow-hidden rounded-md',
      className
    )}
    {...rest}
  >
    <span className="absolute inset-0 bg-neutral-1000 opacity-30" />
    <Icon className="absolute inset-0 mx-auto my-auto text-neutral-0 text-xl">
      {children}
    </Icon>
  </div>
);
