import { useAddCreditMutation } from '@cooltra/api';
import { useIntl } from 'react-intl';
import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';
import { format, parse } from 'date-fns';

import { useNotification } from '~/hooks';
import { dateFormat } from '~/utils/date';

import { addCreditFormValues, validateAddCreditForm } from './add-credit-form';
import messages from './messages';

export type AddCreditFormProps = PropsWithChildren<{
  userId: string;
  title: string;
  onSuccess: () => void;
}>;

export const AddCreditForm = ({
  userId,
  title,
  children,
  onSuccess,
}: AddCreditFormProps) => {
  const intl = useIntl();
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const { mutateAsync } = useAddCreditMutation(userId);
  addCreditFormValues.title = title;

  return (
    <FormProvider
      initialValues={addCreditFormValues}
      validate={validateAddCreditForm(intl)}
      onSubmit={(values, { resetForm }) =>
        mutateAsync({
          ...values,
          expiresAt: values.expiresAt
            ? format(
                parse(values.expiresAt, dateFormat, new Date()),
                'yyyy-MM-dd'
              )
            : '',
        })
          .then(() => {
            resetForm(addCreditFormValues);
            addSuccessNotification(
              intl.formatMessage(messages.creditSuccessfullyAdded)
            );
            onSuccess();
          })
          .catch(() =>
            addErrorNotification(
              intl.formatMessage(messages.somethingWentWrong)
            )
          )
      }
    >
      {children}
    </FormProvider>
  );
};
