import { System } from '@cooltra/auth-api';
import { useFormContext } from '@cooltra/form';
import { Modal, ModalProps } from '@cooltra/ui';

import {
  ChargeUserFormValues,
  createChargeUserInitialFormValues,
} from './charge-user-form';

export type ChargeUserModalProps = ModalProps & {
  system: System;
};

export const ChargeUserModal = ({
  isOpen,
  system,
  ...rest
}: ChargeUserModalProps) => {
  const { resetForm } = useFormContext<ChargeUserFormValues>();

  return (
    <Modal
      className="max-w-lg min-w-md"
      isOpen={isOpen}
      onAfterClose={() => resetForm(createChargeUserInitialFormValues(system))}
      {...rest}
    />
  );
};
