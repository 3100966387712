import { defineMessages } from 'react-intl';

export default defineMessages({
  delivery: {
    defaultMessage: 'Delivery',
  },
  return: {
    defaultMessage: 'Return',
  },
  dateAndTime: {
    defaultMessage: 'Date and time',
  },
});
