import { useIntl } from 'react-intl';

import {
  FormPatternInputField,
  FormPatternInputFieldProps,
} from '../FormPatternInputField';

import messages from './messages';

export type FormDateInputFieldProps = Omit<
  FormPatternInputFieldProps,
  'placeholder' | 'format'
>;

export const FormDateInputField = (props: FormDateInputFieldProps) => {
  const { formatMessage } = useIntl();

  return (
    <FormPatternInputField
      placeholder={formatMessage(messages.placeholder)}
      format="##-##-####"
      mask="_"
      {...props}
    />
  );
};
