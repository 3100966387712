import { FormattedMessage, FormattedTime } from 'react-intl';
import { classNames } from '@cooltra/utils';
import { VehicleRelease } from '@cooltra/api';
import { RouterLink } from '@cooltra/navigation';

import { WorkActionDot } from '../WorkActionDot/WorkActionDot';
import { WorkActionRowLayout } from '../WorkActionRowLayout/WorkActionRowLayout';

import messages from './messages';

type VehicleReleasedRowProps = {
  vehicleRelease: VehicleRelease;
};

export const VehicleReleasedRow = ({
  vehicleRelease,
}: VehicleReleasedRowProps) => {
  return (
    <div className="relative">
      <WorkActionRowLayout
        time={
          <span
            className={classNames(
              'text-sm tabular-nums font-semibold text-neutral-800'
            )}
          >
            <FormattedTime
              value={vehicleRelease.createdAt}
              hour="2-digit"
              hourCycle="h23"
              minute="2-digit"
            />
          </span>
        }
        timeline={<WorkActionDot />}
        content={
          <div className="flex flex-col gap-2">
            <span className={classNames('block font-semibold text-sm mt-0.5')}>
              <FormattedMessage {...messages.releasedVehicle} />
            </span>
            <RouterLink
              to={`/vehicles/${vehicleRelease.vehicleId}`}
              target="_blank"
              className="block text-xs top-1 relative text-neutral-500"
            >
              {vehicleRelease.vehicleExternalId}
            </RouterLink>
          </div>
        }
      />
    </div>
  );
};
