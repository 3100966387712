import { defineMessages } from 'react-intl';

export default defineMessages({
  status: {
    defaultMessage: 'Status',
  },
  open: {
    defaultMessage: 'Open',
  },
  closed: {
    defaultMessage: 'Closed',
  },
  placeholder: {
    defaultMessage: 'All',
  },
});
