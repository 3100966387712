import { FormattedMessage, useIntl } from 'react-intl';
import { FormMultiSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';

import { IdleFrom as FiltersIdleFrom } from '~/libs/vehicles-filters';

import messages from './messages';

const options: FiltersIdleFrom[] = [
  '1-20h',
  '20-24h',
  '1-2d',
  '2-3d',
  '3-4d',
  '4-5d',
  '5-10d',
  '+10d',
];

export const IdleFrom = () => {
  const { formatMessage } = useIntl();
  return (
    <FormMultiSelectField
      isClearable
      placeholder={formatMessage(messages.placeholder)}
      id="idle-from"
      name="idleFrom"
      options={options.map((option) => ({
        label: option,
        value: option,
      }))}
      label={
        <InputLabel htmlFor="idle-from">
          <FormattedMessage {...messages.idleHours} />
        </InputLabel>
      }
    />
  );
};
