import { useFormContext } from '@cooltra/form';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';
import { ContractStatus } from '@cooltra/station-based-api';
import { isSameDay } from 'date-fns';

import { useContractFlags } from '~/hooks';
import { FormQRCode, RouterPrompt, SaveButton } from '~/common';

import { ContractDetailsFormValues } from '../contract-details-form';

import messages from './messages';

export type SaveContractDetailsProps = {
  contractStatus: ContractStatus;
  isSuccess: boolean;
};

export const SaveContractDetails = ({
  contractStatus,
  isSuccess,
}: SaveContractDetailsProps) => {
  const [isModalOpen, { toggleOn, toggleOff }] = useToggle();

  const { initialValues, values, isValid, isSubmitting, handleSubmit } =
    useFormContext<ContractDetailsFormValues>();

  const isSameChannel =
    !!initialValues.type && !!values.type && initialValues.type === values.type;

  const isSamePickUpPoint =
    !!initialValues.pickup &&
    !!values.pickup &&
    initialValues.pickup === values.pickup;

  const isSamePickUpDate =
    !!initialValues.pickUpDate &&
    !!values.pickUpDate &&
    isSameDay(initialValues.pickUpDate, values.pickUpDate);

  const isSameDropOffDate =
    !!initialValues.dropOffDate &&
    !!values.dropOffDate &&
    isSameDay(initialValues.dropOffDate, values.dropOffDate);

  const handleConfirm = () => handleSubmit().then(toggleOff);

  const { areDetailsEditable, hasPendingTicket, canExtendContract } =
    useContractFlags();

  const pristine =
    (initialValues.type === values.type || isSameChannel) &&
    initialValues.pickup === values.pickup &&
    (initialValues.pickUpDate === values.pickUpDate || isSamePickUpDate) &&
    (initialValues.dropOffDate === values.dropOffDate || isSameDropOffDate) &&
    initialValues.pickUpTime === values.pickUpTime &&
    initialValues.dropOffTime === values.dropOffTime;

  const canUpdateDetails =
    (areDetailsEditable || canExtendContract) && isValid && !pristine;

  const isBookedWithPendingTicket =
    contractStatus === 'BOOKED' && hasPendingTicket;

  const isNotBookedOrNew = !['NEW', 'BOOKED'].includes(contractStatus);

  const isNotTheSameData =
    !isSamePickUpPoint || !isSamePickUpDate || !isSameDropOffDate;

  const showConfirmationModal =
    (isBookedWithPendingTicket || (isNotBookedOrNew && isNotTheSameData)) &&
    !canExtendContract;

  return (
    <>
      <RouterPrompt
        isBlocking={!pristine}
        onConfirm={handleSubmit}
        isConfirming={isSubmitting}
      />
      <SaveButton
        className="btn-shadow fixed bottom-8 right-28 -mr-2"
        type={showConfirmationModal ? 'button' : 'submit'}
        loading={isSubmitting}
        isSaved={isSuccess && pristine}
        disabled={!canUpdateDetails}
        onClick={showConfirmationModal ? toggleOn : undefined}
      />
      <Modal
        className="max-w-sm min-w-xs"
        isOpen={isModalOpen}
        onRequestClose={toggleOff}
      >
        <div className="text-center pb-8 px-8">
          <h6 className="text-neutral-700 text-lg font-semibold mb-2">
            <FormattedMessage {...messages.confirmation} />
          </h6>
          <p className="text-neutral-600 text-sm mb-8">
            <FormattedMessage {...messages.disclaimer} />
          </p>
          <div className="flex justify-center gap-4">
            <Button onClick={toggleOff}>
              <FormattedMessage {...messages.cancel} />
            </Button>
            <Button
              loading={isSubmitting}
              emphasis="high"
              onClick={handleConfirm}
            >
              <FormattedMessage {...messages.confirm} />
            </Button>
          </div>
        </div>
      </Modal>
      <FormQRCode dirty={!pristine} />
    </>
  );
};
