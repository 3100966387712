import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { RentalPassRowLayout } from '../RentalPassRowLayout';

import messages from './messages';

export const RentalPassesListHeader = () => (
  <RentalPassRowLayout
    title={
      <ListColumnLabel>
        <FormattedMessage {...messages.title} />
      </ListColumnLabel>
    }
    price={
      <ListColumnLabel>
        <FormattedMessage {...messages.price} />
      </ListColumnLabel>
    }
    validFrom={
      <ListColumnLabel>
        <FormattedMessage {...messages.validFrom} />
      </ListColumnLabel>
    }
    validUntil={
      <ListColumnLabel>
        <FormattedMessage {...messages.validUntil} />
      </ListColumnLabel>
    }
    status={
      <ListColumnLabel>
        <FormattedMessage {...messages.status} />
      </ListColumnLabel>
    }
  />
);
