import { RentalPassesSort, RentalPassState } from '@cooltra/api';

export type PassFiltersFormValues = {
  keyword: string;
  state: RentalPassState;
  sort: RentalPassesSort;
};

export const initialValues: PassFiltersFormValues = {
  keyword: '',
  state: '' as RentalPassState,
  sort: 'createdAt.desc',
};
