import { TimelineWorkUnitClosed } from '@cooltra/api';
import { TimelineItem } from '@cooltra/ui';
import { FormattedMessage, FormattedTime } from 'react-intl';

import messages from './messages';

export type WorkUnitBatterySwappedProps = Pick<
  TimelineWorkUnitClosed,
  'createdAt'
>;

export const WorkUnitBatterySwapped = ({
  createdAt,
}: WorkUnitBatterySwappedProps) => (
  <TimelineItem
    timelineClassName="translate-y-1.5"
    isLast={false}
    header={
      <div className="flex gap-2 text-sm mb-4">
        <span className="text-neutral-400">
          <FormattedTime
            value={createdAt}
            hour="2-digit"
            hourCycle="h23"
            minute="2-digit"
          />
        </span>
        <span className="text-neutral-800">
          <FormattedMessage {...messages.swapBattery} />
        </span>
      </div>
    }
  />
);
