import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { RouterButton } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';

import { Counter } from '~/common';

import messages from './messages';

export type OpenVehicleTasksHeaderProps = {
  vehicleId: string;
  count?: number;
};

export const OpenVehicleTasksHeader = ({
  count,
  vehicleId,
}: OpenVehicleTasksHeaderProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  return (
    <>
      <div className="h-10 w-full flex items-center justify-between mb-6">
        <div className="flex items-center">
          <h2 className="text-base text-neutral-500 font-semibold mr-3">
            <FormattedMessage {...messages.heading} />
          </h2>
          {!!count && <Counter>{count}</Counter>}
        </div>
        <div data-testid="VEHICLE_OPEN_TASKS_HEADER">
          {hasPermission('write:tasks') && (
            <RouterButton
              size="sm"
              emphasis="high"
              to={`/tasks/new?vehicleId=${vehicleId}`}
            >
              <FormattedMessage {...messages.createTask} />
            </RouterButton>
          )}
        </div>
      </div>
    </>
  );
};
