import {
  Reservation,
  useReservationsQuery,
  useReservationsTotal,
} from '@cooltra/api';
import { InfiniteList } from '@cooltra/ui';
import { useParams } from 'react-router-dom';
import { useFormContext } from '@cooltra/form';
import { useEffect } from 'react';

import {
  ErrorPage,
  FilterFields,
  NoSearchResults,
  ReservationsTotal,
} from '~/common';
import { useNavigateWithQueryParams } from '~/hooks';

import { VehicleReservationsListHeader } from './VehicleReservationsListHeader';
import {
  VehicleReservationCard,
  VehicleReservationCardLoading,
} from './VehicleReservationCard';
import { VehicleRentalsFiltersFormValues } from './vehicle-reservations-filters-form';

export const VehicleReservationsList = () => {
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const { vehicleId = '' } = useParams<'vehicleId'>();

  const { values } = useFormContext<VehicleRentalsFiltersFormValues>();

  const { isError, isLoading, data, fetchNextPage, isFetchingNextPage } =
    useReservationsQuery({ vehicleId, ...values }, { enabled: !!vehicleId });

  useEffect(() => {
    navigateWithQueryParams(values);
  }, [navigateWithQueryParams, values]);

  const reservations = data?.pages
    ? ([] as Reservation[]).concat.apply([], data.pages)
    : [];

  const reservationsTotal = useReservationsTotal({ vehicleId, ...values }) || 0;

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div
      className="container min-w-3xl max-w-5xl py-12"
      data-testid="VEHICLE_RESERVATIONS"
    >
      <div className="flex mb-10">
        <FilterFields.Created />
      </div>
      <ReservationsTotal
        loading={isLoading}
        total={reservationsTotal}
        className="mb-6"
      />
      <InfiniteList
        fetchMore={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        hasMore={reservations.length < reservationsTotal}
        isLoading={isLoading}
        idProp="reservationId"
        data={reservations}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<VehicleReservationsListHeader />}
        renderLoadingRow={<VehicleReservationCardLoading />}
        renderRow={(reservation: Reservation) => (
          <VehicleReservationCard {...reservation} />
        )}
      />
    </div>
  );
};
