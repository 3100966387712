import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { FormInputField } from '@cooltra/form';

import messages from './messages';

export const Amount = () => (
  <FormInputField
    name="amount"
    id="amount"
    label={
      <InputLabel htmlFor="amount">
        <FormattedMessage {...messages.label} /> (€)
      </InputLabel>
    }
  />
);
