import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type RentalIncidentRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  incident: ReactNode;
  assignee: ReactNode;
  lastUpdated: ReactNode;
};

export const RentalIncidentRowLayout = ({
  incident,
  assignee,
  lastUpdated,
  className,
  ...rest
}: RentalIncidentRowLayoutProps) => (
  <div className={classNames('flex', 'h-full', 'w-full', className)} {...rest}>
    <div className="flex items-center w-full px-8 gap-4">
      <div className="flex-1 overflow-hidden pr-10">{incident}</div>
      <div className="w-48 shrink-0">{assignee}</div>
      <div className="w-48 shrink-0">{lastUpdated}</div>
    </div>
  </div>
);
