import { Task } from '@cooltra/api';
import { useOperatorsQuery } from '@cooltra/auth-api';
import { Placeholder } from '@cooltra/ui';

import { operatorGroups } from '~/utils/operators';

export type OperatorOrGroupProps = Pick<Task, 'operatorId' | 'operatorGroupId'>;

export const OperatorOrGroup = ({
  operatorId,
  operatorGroupId,
}: OperatorOrGroupProps) => {
  const { data, isLoading } = useOperatorsQuery();

  const operatorGroupIdName = Object.values(operatorGroups).find(
    ({ operatorGroupId: operatorGroupIdToCompare }) =>
      operatorGroupIdToCompare === operatorGroupId
  )?.name;

  return isLoading ? (
    <Placeholder className="w-16 h-2" />
  ) : operatorId ? (
    <span className="block text-sm font-medium text-neutral-700">
      {data?.find(({ externalId }) => operatorId === externalId)?.name}
    </span>
  ) : (
    <span className="block text-sm text-neutral-500">
      {operatorGroupIdName}
    </span>
  );
};
