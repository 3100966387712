import { ImgHTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { Image } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { useProblemReportImage } from '@cooltra/api';
import { MdOpenInFull } from 'react-icons/md';

import { useRotation } from '~/hooks';
import { PhotoIcon, PhotoModal } from '~/common';

import messages from './messages';

type Dimension = 'width' | 'height';

const photoSize: Record<Dimension, string> = {
  width: 'w-40',
  height: 'h-40',
};

const hasChangedAxis = (rotation: number) =>
  rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;

const getPhotoClasses = (rotation: number) => {
  const constrainedDimension = hasChangedAxis(rotation) ? 'width' : 'height';
  return classNames(
    'object-contain',
    photoSize[constrainedDimension],
    constrainedDimension === 'height' ? 'max-w-full' : 'max-h-full'
  );
};

export type PhotoProps = ImgHTMLAttributes<HTMLImageElement> & {
  name: string;
};

export const Photo = ({ name, className, ...rest }: PhotoProps) => {
  const fetchedImageData = useProblemReportImage(name);

  const { formatMessage } = useIntl();

  const [isOpen, { toggleOff, toggleOn }] = useToggle();

  const { rotation, rotationClassName, rotateLeft, rotateRight } =
    useRotation();

  const imageData = fetchedImageData
    ? `data:image/jpeg;base64,${fetchedImageData}`
    : '';

  return (
    <div className={classNames(photoSize.height, className)}>
      <div
        tabIndex={0}
        className="group relative bg-neutral-100 h-full outline-none rounded-md"
      >
        <div className="h-full flex items-center justify-center">
          <Image
            alt={formatMessage(messages.alt)}
            src={imageData}
            className={classNames(getPhotoClasses(rotation), rotationClassName)}
            {...rest}
          />
        </div>
        <div className="opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 transition-opacity">
          {imageData && (
            <button
              type="button"
              onClick={toggleOn}
              className="flex items-start justify-start h-full w-full absolute inset-0 p-2"
            >
              <PhotoIcon aria-label={formatMessage(messages.fullScreen)}>
                <MdOpenInFull />
              </PhotoIcon>
            </button>
          )}
        </div>
      </div>
      <PhotoModal
        isOpen={isOpen}
        onRequestClose={toggleOff}
        rotateLeft={rotateLeft}
        rotateRight={rotateRight}
        className={rotationClassName}
        imageData={imageData}
        {...rest}
      />
    </div>
  );
};
