import { useNavigate } from 'react-router-dom';
import { useRouteState } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';

import { BackLink, RouterBackLink } from '~/common';

import messages from './messages';

export const PromotionBackLink = () => {
  const routeState = useRouteState<'from'>();
  const navigate = useNavigate();

  if (!routeState?.from) {
    return (
      <RouterBackLink to="/offers/station-based">
        <FormattedMessage {...messages.backToPromotionsList} />
      </RouterBackLink>
    );
  }

  const navigateBack = () => navigate(-1);

  return <BackLink onClick={navigateBack} />;
};
