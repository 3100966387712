import { Form, FormButton } from '@cooltra/form';
import { Button, InputLabel, Modal } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { FormDateInputField } from '~/common';

import { SubscriptionForm } from '../SubscriptionForm';
import { SubscriptionSelectField } from '../SubscriptionSelectField/SubscriptionSelectField';

import messages from './messages';

export type NewSubscriptionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
};

export const NewSubscriptionModal = ({
  isOpen,
  onClose,
  userId,
}: NewSubscriptionModalProps) => (
  <Modal className="max-w-lg min-w-md" isOpen={isOpen} onRequestClose={onClose}>
    <div data-testid="NEW_SUBSCRIPTION_MODAL" className="pb-12 px-12">
      <h6 className="text-neutral-400 text-lg mb-6">
        <FormattedMessage {...messages.newSubscription} />
      </h6>
      <SubscriptionForm userId={userId} onSuccess={onClose}>
        <Form className="flex flex-col gap-6">
          <SubscriptionSelectField />
          <div className="w-1/2">
            <FormDateInputField
              name="validFrom"
              id="validFrom"
              label={
                <InputLabel htmlFor="validFrom" className="mb-1">
                  <FormattedMessage {...messages.validFrom} />
                </InputLabel>
              }
            />
          </div>
          <div className="flex mt-6 gap-4">
            <Button type="button" className="w-full" onClick={onClose}>
              <FormattedMessage {...messages.cancel} />
            </Button>
            <FormButton disableInvalid className="w-full" emphasis="high">
              <FormattedMessage {...messages.save} />
            </FormButton>
          </div>
        </Form>
      </SubscriptionForm>
    </div>
  </Modal>
);
