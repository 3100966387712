import { FormattedMessage } from 'react-intl';

import { StaticField } from '../../StaticField';

import messages from './messages';

export type DescriptionStaticProps = {
  value: string;
};

export const DescriptionStatic = ({ value }: DescriptionStaticProps) => (
  <StaticField
    renderLabel={<FormattedMessage {...messages.description} />}
    renderValue={value}
  />
);
