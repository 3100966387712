import { FormattedMessage } from 'react-intl';

import { EmptyListMessageCard } from '../../List';

import messages from './messages';

export const NoSuspensions = () => (
  <EmptyListMessageCard>
    <FormattedMessage {...messages.noSuspensions} />
  </EmptyListMessageCard>
);
