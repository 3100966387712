import { defineMessages } from 'react-intl';

export default defineMessages({
  idleVehicleTime: {
    defaultMessage: 'Idle vehicle time',
  },
  parkedOnTheSidewalk: {
    defaultMessage: 'Parked on the sidewalk',
  },
  rental: {
    defaultMessage: 'Rental',
  },
  vehicle: {
    defaultMessage: 'Vehicle',
  },
  no: {
    defaultMessage: 'No',
  },
  yes: {
    defaultMessage: 'Yes',
  },
  hoursAgo: {
    defaultMessage: '{hours, plural, one {# hour} other {# hours}} ago',
  },
  streetViewDoesNotExist: {
    defaultMessage:
      'Street view does not exist for the current vehicle position',
  },
});
