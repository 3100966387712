import { List } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { InsuranceDepositPrices } from '@cooltra/station-based-api';

import { NoSearchResults } from '~/common';

import { DepositsHeader } from './DepositsHeader';
import { DepositsCardLoading } from './DepositsCardLoading';
import { DepositsCard } from './DepositsCard';

type DepositsProps = {
  depositsPrice: InsuranceDepositPrices[];
  isLoading: boolean;
};

export const Deposits = ({ depositsPrice, isLoading }: DepositsProps) => {
  return (
    <div className={classNames('container min-w-3xl max-w-6xl py-12', 'pb-48')}>
      <List
        loadingRows={depositsPrice.length}
        isLoading={isLoading}
        idProp="insuranceId"
        data={depositsPrice}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<DepositsHeader />}
        renderLoadingRow={<DepositsCardLoading />}
        renderRow={(depositPrice) => <DepositsCard {...depositPrice} />}
      />
    </div>
  );
};
