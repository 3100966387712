import { ReactNode } from 'react';
import { RouterTab } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { stringifyParams } from '@cooltra/utils';

import { Four0Four, Four0Three } from '~/common';
import { usePreferredSystem } from '~/libs/preferred-system';
import { threeDaysAgo } from '~/utils/date';

import { Rental } from '../../Rental';
import { ReservationsList } from '../ReservationsList/ReservationsList';
import { RentalsFiltersForm, RentalsList } from '../RentalsList';
import { ReservationsFiltersForm } from '../ReservationsList';

import messages from './messages';

export const Rentals = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { system } = usePreferredSystem();

  const defaultReservationsParams = {
    system: [system],
    state: ['ACTIVE', 'CHECKING_IN'],
    minDate: threeDaysAgo,
    maxDate: '',
  };

  const defaultRentalsParams = {
    system: [],
    state: ['ACTIVE'],
    minDate: threeDaysAgo,
    maxDate: '',
    sort: 'pickUp.desc',
  };

  if (!hasPermission('read:rentals')) {
    return <Four0Three />;
  }

  const getListLayout = (ListComponent: ReactNode) => {
    return (
      <div className="container min-w-5xl max-w-6xl py-12">
        <div className="flex px-4 gap-12">
          <RouterTab
            to={`/rentals?${stringifyParams(defaultRentalsParams)}`}
            end
          >
            <FormattedMessage {...messages.rentals} />
          </RouterTab>
          <RouterTab
            to={`/rentals/reservations?${stringifyParams(
              defaultReservationsParams
            )}`}
            end
          >
            <FormattedMessage {...messages.reservations} />
          </RouterTab>
        </div>
        {ListComponent}
      </div>
    );
  };

  return (
    <Routes>
      <Route
        path="/"
        element={getListLayout(
          <RentalsFiltersForm>
            <RentalsList />
          </RentalsFiltersForm>
        )}
      />
      <Route
        path="/reservations"
        element={getListLayout(
          <ReservationsFiltersForm>
            <ReservationsList />
          </ReservationsFiltersForm>
        )}
      />
      <Route path="/:rentalId/*" element={<Rental />} />
      <Route path="*" element={<Four0Four />} />
    </Routes>
  );
};
