import { useIntl } from 'react-intl';

import {
  FormDateInputField,
  FormTimeInputField,
} from '../../../../FormattedInput';

import messages from './messages';

type FormDateInputDateAndTimeFieldProps = {
  disabled: boolean;
  names: {
    date: string;
    time: string;
  };
  ariaLabels?: {
    date: string;
    time: string;
  };
};

export const FormDateInputDateAndTimeField = ({
  disabled,
  names,
  ariaLabels,
}: FormDateInputDateAndTimeFieldProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <FormDateInputField
        className="w-32"
        name={names.date}
        disabled={disabled}
        aria-label={ariaLabels ? ariaLabels.date : formatMessage(messages.date)}
      />
      <FormTimeInputField
        className="w-20"
        name={names.time}
        disabled={disabled}
        aria-label={ariaLabels ? ariaLabels.time : formatMessage(messages.time)}
      />
    </>
  );
};
