import { defineMessages } from 'react-intl';

export default defineMessages({
  system: {
    defaultMessage: 'Home system',
  },
  name: {
    defaultMessage: 'Name',
  },
  status: {
    defaultMessage: 'Status',
  },
  statusUpdatedSince: {
    defaultMessage: 'Since',
  },
  provider: {
    defaultMessage: 'Provider',
  },
});
