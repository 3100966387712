import { UserStateType } from '@cooltra/api';
import { LanguageCode, languages } from '@cooltra/countries';

export const canUserBeReviewed = (state?: UserStateType) =>
  state &&
  ['AWAITING_REVIEW', 'CORRECTIONS_REQUESTED', 'REJECTED'].includes(state);

export function isSupportedLanguage(
  isoCode: string | LanguageCode
): isoCode is LanguageCode {
  return languages[isoCode as LanguageCode] !== undefined;
}
