import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { DepositsRowLayout } from './DepositsRowLayout';
import messages from './messages';

export const DepositsHeader = () => (
  <DepositsRowLayout
    coverage={
      <ListColumnLabel>
        <FormattedMessage {...messages.coverage} />
      </ListColumnLabel>
    }
    eBike={
      <ListColumnLabel>
        <FormattedMessage {...messages.eBike} />
      </ListColumnLabel>
    }
    standard={
      <ListColumnLabel>
        <FormattedMessage {...messages.standard} />
      </ListColumnLabel>
    }
    plus={
      <ListColumnLabel>
        <FormattedMessage {...messages.plus} />
      </ListColumnLabel>
    }
    premium={
      <ListColumnLabel>
        <FormattedMessage {...messages.premium} />
      </ListColumnLabel>
    }
    superpremium={
      <ListColumnLabel>
        <FormattedMessage {...messages.superpremium} />
      </ListColumnLabel>
    }
  />
);
