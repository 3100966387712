import { MaasUser } from '@cooltra/api';
import { Card } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { UserFields } from '~/common';

import { DeleteUser } from '../DeleteUser/DeleteUser';

import messages from './messages';

export type PersonalDetailsProps = {
  maasUser: MaasUser;
};

export const PersonalDetails = ({
  maasUser: { firstName, lastName, email, system, language, deletedAt },
}: PersonalDetailsProps) => (
  <div>
    <Card className="mb-5">
      <div className="p-10" data-testid="MAAS_USER_PERSONAL_DETAILS">
        <h2 className="text-xl font-semibold text-neutral-400 mb-8">
          <FormattedMessage {...messages.personalDetails} />
        </h2>
        <div className="grid grid-cols-2 gap-x-5 gap-y-10">
          {firstName && <UserFields.FirstNameStatic value={firstName} />}
          {lastName && <UserFields.LastNameStatic value={lastName} />}
          {system && <UserFields.SystemStatic value={system} />}
          {language && <UserFields.LanguageStatic value={language} />}
        </div>
      </div>
    </Card>
    {!deletedAt && <DeleteUser email={email} />}
  </div>
);
