import { Card, Placeholder } from '@cooltra/ui';

import { AlarmRowLayout } from '../AlarmRowLayout/AlarmRowLayout';

export const AlarmCardLoading = () => (
  <Card className="h-24 flex items-center">
    <AlarmRowLayout
      vehicle={
        <div className="flex items-center">
          <Placeholder className="h-10 w-10 rounded-full shrink-0" />
          <div className="pl-6">
            <Placeholder className="h-2 w-32 mb-3" />
            <Placeholder className="h-2 w-24" />
          </div>
        </div>
      }
      alarms={
        <div className="flex gap-2">
          <Placeholder className="h-6 w-20 mb-3 rounded-full" />
          <Placeholder className="h-6 w-20 mb-3 rounded-full" />
        </div>
      }
      duration={
        <div className="flex flex-col items-end">
          <Placeholder className="h-2 w-32 mb-3" />
          <Placeholder className="h-2 w-20" />
        </div>
      }
    />
  </Card>
);
