import { UserSuspensionType } from '@cooltra/api';
import { defineMessages } from 'react-intl';

export default defineMessages<UserSuspensionType>({
  MANUAL: {
    defaultMessage: 'Manual',
  },
  AUTO: {
    defaultMessage: 'Automatic',
  },
});
