import { Routes, Route, useParams } from 'react-router-dom';
import { useRentalQuery } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { SpinnerScreen } from '@cooltra/ui';

import { ErrorPage, Four0Three, SidebarLayout } from '~/common';
import { thirtySeconds } from '~/utils/date';

import { Sidebar, SidebarLoading } from '../Sidebar';
import { RentalOverview } from '../RentalOverview';
import { RentalProblemReports } from '../RentalProblemReports';
import { RentalIncidents } from '../RentalIncidents';
import { TrafficOffenseReport } from '../TrafficOffenseReport';

export const Rental = () => {
  const { rentalId = '' } = useParams<'rentalId'>();

  const {
    data: rental,
    isError,
    error,
  } = useRentalQuery(rentalId, {
    refetchInterval: ({ state }) =>
      state.data?.state === 'ACTIVE' ? thirtySeconds : false,
    retry: false,
  });

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <SidebarLayout
      sidebar={
        <div>{rental ? <Sidebar rental={rental} /> : <SidebarLoading />}</div>
      }
    >
      <Routes>
        <Route
          path="/"
          element={
            rental ? <RentalOverview rental={rental} /> : <SpinnerScreen />
          }
        />
        <Route
          path="/problem-reports"
          element={
            rental ? (
              <RentalProblemReports rental={rental} />
            ) : (
              <SpinnerScreen />
            )
          }
        />
        <Route
          path="/incidents"
          element={
            rental ? <RentalIncidents rental={rental} /> : <SpinnerScreen />
          }
        />
        <Route
          path="/traffic-offense-report"
          element={
            rental ? (
              <TrafficOffenseReport rental={rental} />
            ) : (
              <SpinnerScreen />
            )
          }
        />
      </Routes>
    </SidebarLayout>
  );
};
