import { useIntl } from 'react-intl';
import { FormRadioGroupField, useFormContext } from '@cooltra/form';

import { PromotionFormValues, PromotionTypeMode } from '../../PromotionForm';

import messages from './messages';

export const PromotionTypeSelectorMode = () => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext<PromotionFormValues>();
  const { editMode } = values;
  const promotionTypeModeList: PromotionTypeMode[] = ['credit', 'discount'];

  return (
    <FormRadioGroupField
      name="promotionTypeMode"
      innerClassName="flex flex-row gap-6"
      legend={<></>}
      disabled={editMode}
      items={promotionTypeModeList.map((promotionTypeMode) => ({
        value: promotionTypeMode,
        label: formatMessage(messages[promotionTypeMode]),
      }))}
    />
  );
};
