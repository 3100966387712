import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage:
      '{pickUps, plural, one {# delivery} other {# deliveries}} | {dropOffs, plural, one {# return} other {# returns}}',
  },
  titleDeliveries: {
    defaultMessage: '{pickUps, plural, one {# delivery} other {# deliveries}}',
  },
  titleReturns: {
    defaultMessage: '{dropOffs, plural, one {# return} other {# returns}}',
  },
  deliveries: {
    defaultMessage: 'Deliveries',
  },
  returns: {
    defaultMessage: 'Returns',
  },
});
