import { BadgePlaceholder, Card, Placeholder } from '@cooltra/ui';

import { OpenVehicleProblemReportLayout } from './OpenVehicleProblemReportLayout';

export const OpenVehicleProblemReportCardLoading = () => (
  <Card className="h-28 py-0.5 flex">
    <OpenVehicleProblemReportLayout
      problem={
        <>
          <BadgePlaceholder className="w-20 mb-4" />
          <Placeholder className="w-28 h-2" />
        </>
      }
      context={
        <>
          <Placeholder className="h-2 w-16 mb-3" />
          <Placeholder className="h-2 w-12" />
        </>
      }
      created={
        <>
          <Placeholder className="h-2 w-36 mb-3" />
          <Placeholder className="h-2 w-28" />
        </>
      }
    />
  </Card>
);
