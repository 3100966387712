import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Title',
  },
  price: {
    defaultMessage: 'Price',
  },
  validFrom: {
    defaultMessage: 'From',
  },
  validUntil: {
    defaultMessage: 'Until',
  },
  status: {
    defaultMessage: 'Status',
  },
});
