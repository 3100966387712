import { useIntl } from 'react-intl';
import { MdSearch } from 'react-icons/md';

import { FilterFields } from '~/common';

import messages from './messages';

export const VehiclesSearch = () => {
  const intl = useIntl();

  return (
    <FilterFields.Keyword
      placeholder={intl.formatMessage(messages.placeholder)}
      leadingIcon={<MdSearch className="text-lg" />}
    />
  );
};
