import { defineMessages } from 'react-intl';

export default defineMessages({
  backToPasses: {
    defaultMessage: 'Back to passes',
  },
  createPass: {
    defaultMessage: 'Create pass',
  },
  success: {
    defaultMessage: 'You have successfully created a new pass!',
  },
});
