import { ProblemReportListItem } from '@cooltra/api';
import { classNames } from '@cooltra/utils';
import { RouterCardLink, RouterLink } from '@cooltra/navigation';

import {
  DateTime,
  ModelImage,
  Context,
  ByUser,
  ProblemReportCardInfo,
  ProblemReportTaskCount,
} from '~/common';

import { ProblemReportRowLayout } from '../ProblemReportRowLayout';

export type ProblemReportCardProps = {
  problemReport: ProblemReportListItem;
};

export const ProblemReportCard = ({
  problemReport: {
    problemReportId,
    tags,
    description,
    open,
    vehicle,
    numberImages,
    rentalId,
    reservationId,
    createdAt,
    createdBy,
  },
}: ProblemReportCardProps) => (
  <RouterCardLink
    isMultiAction
    to={`/problem-reports/${problemReportId}`}
    className={classNames(open ? 'opacity-100' : 'opacity-50')}
  >
    <ProblemReportRowLayout
      className="min-h-28 py-4"
      problem={
        <ProblemReportCardInfo
          open={open}
          numberImages={numberImages}
          description={description}
          tags={tags}
        />
      }
      context={<Context rentalId={rentalId} reservationId={reservationId} />}
      created={
        <div>
          <DateTime date={createdAt} />
          <ByUser user={createdBy} />
        </div>
      }
      vehicle={
        <RouterLink
          to={`/vehicles/${vehicle.vehicleId}`}
          className="w-full block"
        >
          <span className="text-sm font-medium text-right">
            <span className="flex items-center justify-end gap-1 h-5">
              <ModelImage
                className="h-6"
                model={vehicle.model}
                identificationNumber={vehicle.identificationNumber}
              />
              <span className="truncate">{vehicle.externalId}</span>
            </span>
            <span className="flex items-center justify-end gap-1 text-xs text-neutral-400 font-normal">
              <ProblemReportTaskCount openTasksCount={vehicle.numberTasks} />
            </span>
          </span>
        </RouterLink>
      }
    />
  </RouterCardLink>
);
