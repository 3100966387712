import { MdConstruction, MdOutlineExpandMore } from 'react-icons/md';
import { Icon } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { ButtonHTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';

import { ByOperator } from '~/common';

import messages from './messages';

export type WorkUnitStartedProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'children'
> & {
  operatorId: string;
  isActive: boolean;
};

export const WorkUnitStarted = ({
  operatorId,
  isActive,
  className,
  ...rest
}: WorkUnitStartedProps) => (
  <button
    className={classNames(
      'flex justify-between items-center w-full focus-visible:ring focus-visible:ring-inset focus-visible:ring-primary-200 transition-shadow p-5 rounded-md',
      className
    )}
    {...rest}
  >
    <div className="flex items-center gap-2">
      <Icon className="text-neutral-400 text-xl">
        <MdConstruction />
      </Icon>
      <span className="text-neutral-1000 font-semibold">
        <FormattedMessage
          {...messages.workStarted}
          values={{
            byOperator: <ByOperator id={operatorId} />,
          }}
        />
      </span>
    </div>
    <Icon
      className={classNames(
        'text-xl text-neutral-500 relative transition-transform',
        isActive ? 'rotate-180' : 'rotate-0'
      )}
    >
      <MdOutlineExpandMore />
    </Icon>
  </button>
);
