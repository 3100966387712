import { ThreeStateValue } from '@cooltra/api';

export type VehicleTasksFiltersFormValues = {
  minDate: string;
  maxDate: string;
  open: ThreeStateValue;
};

export const vehicleTasksFiltersInitialValues: VehicleTasksFiltersFormValues = {
  minDate: '',
  maxDate: '',
  open: '',
};
