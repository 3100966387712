import { Placeholder } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type ClosedTasksProps = {
  closedTasks?: number;
};

export const ClosedTasks = ({ closedTasks }: ClosedTasksProps) => (
  <div className="flex flex-col">
    {closedTasks !== undefined ? (
      <span className="text-md text-neutral-1000 font-semibold">
        {closedTasks}
      </span>
    ) : (
      <Placeholder className="w-6 h-5 mx-auto mb-1" />
    )}
    <span className="text-xs text-neutral-300">
      <FormattedMessage {...messages.closedTasks} />
    </span>
  </div>
);
