import { Card, Divider } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { isBike } from '@cooltra/vehicle-utils';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import {
  useVehicleStatusQuery,
  Telematics as TelematicsType,
} from '@cooltra/api';

import {
  Sync,
  Honk,
  UnlockTopCase,
  Lock,
  Unlock,
  GpsReboot,
  OpenSaddle,
  IgnitionOn,
  IgnitionOff,
  EnableAlarm,
  DisableAlarm,
  PowerOff,
  UnlockBattery,
} from '~/common';
import { useVehicleQuery } from '~/hooks';
import {
  ActionElement,
  FreeFloatModels,
} from '~/common/Vehicle/Telematics/ActionButtons/types';

import * as Items from '../Items';

import messages from './messages';

export type TelematicsInformationProps = {
  vehicleId: string;
};

const actions: Record<FreeFloatModels, ActionElement[]> = {
  ASKOLL_ES2: [Honk, UnlockTopCase, Lock, Unlock],
  NIU_N1S: [
    Honk,
    UnlockTopCase,
    Lock,
    Unlock,
    GpsReboot,
    OpenSaddle,
    IgnitionOn,
    IgnitionOff,
    EnableAlarm,
    DisableAlarm,
  ],
  NIU_N1S_25: [
    Honk,
    UnlockTopCase,
    Lock,
    Unlock,
    GpsReboot,
    OpenSaddle,
    IgnitionOn,
    IgnitionOff,
    EnableAlarm,
    DisableAlarm,
  ],
  ARCADE_X2C: [Lock, Unlock, PowerOff],
  SEGWAY_A300: [Honk, Lock, Unlock, UnlockBattery],
};

export const TelematicsInformation = ({
  vehicleId,
}: TelematicsInformationProps) => {
  const { hasPermission } = useAuthClaimsQuery();
  const { data: vehicle } = useVehicleQuery(vehicleId);
  const { data: vehicleStatus, ...vehicleStatusQuery } = useVehicleStatusQuery(
    vehicleId,
    {
      retry: false,
    }
  );

  const fieldProps = {
    vehicleStatus,
    model: vehicle?.model,
    telematics: vehicle?.telematics,
    error: vehicleStatusQuery.error,
  };

  const getActions = (telematics: TelematicsType | undefined) => {
    switch (telematics) {
      case 'INVERS':
      case 'ASTRA':
        return actions.ASKOLL_ES2;
      case 'NIU':
        return actions.NIU_N1S;
      case 'AXA':
        return actions.ARCADE_X2C;
      case 'SEGWAY':
        return actions.SEGWAY_A300;
      default:
        return actions.ASKOLL_ES2;
    }
  };

  const vehicleActions = getActions(vehicle?.telematics);

  return (
    <div data-testid="TELEMATICS_INFORMATION_CONTENT">
      <div className="h-10 flex items-center mb-6 justify-between">
        <div className="flex items-center">
          <h2 className="text-base text-neutral-500 font-semibold">
            <FormattedMessage {...messages.telematics} />
          </h2>
          <Items.Connection
            {...fieldProps}
            isLoading={vehicleStatusQuery.isLoading}
          />
        </div>
        <Sync vehicleId={vehicleId} emphasis="high" isDropdown={false} />
      </div>
      <Card>
        <div className="p-8 pb-6">
          <ul>
            <Items.LastSync {...fieldProps} telematics={vehicle?.telematics} />
            <Divider />
            <Items.Power {...fieldProps} />
            <Divider />
            <Items.TelematicsBattery {...fieldProps} />
            <Divider />
            {vehicle && isBike(vehicle) && (
              <>
                <Items.BoardVoltage {...fieldProps} />
                <Divider />
              </>
            )}
            <Items.LockStatus {...fieldProps} />
            {vehicle && vehicle.telematics !== 'AXA' && (
              <>
                <Divider />
                <Items.CentralStand {...fieldProps} />
                <Divider />
                <Items.Helmet {...fieldProps} />
              </>
            )}
            {vehicle && vehicle.telematics !== 'AXA' && (
              <>
                <Divider />
                <Items.TopCase {...fieldProps} />
              </>
            )}
            {vehicle && vehicle.telematics == 'NIU' && (
              <>
                <Divider />
                <Items.TopCaseLock {...fieldProps} />
                <Divider />
                <Items.Saddle {...fieldProps} />
                <Divider />
                <Items.Alarm {...fieldProps} />
              </>
            )}
          </ul>
          <div className="grid grid-cols-2 mt-4 -m-1 gap-2">
            {hasPermission('action:vehicles') && vehicle && (
              <>
                {vehicleActions.map((ActionButton, i) => (
                  <ActionButton
                    key={`action-${i}`}
                    vehicleId={vehicleId}
                    showIcon={true}
                    isDropdown={false}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};
