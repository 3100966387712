import { useIntl } from 'react-intl';
import { MdLockOutline } from 'react-icons/md';

import { ErrorPage } from '../ErrorPage';

import messages from './messages';

export const Four0Three = () => {
  const { formatMessage } = useIntl();
  return (
    <ErrorPage
      icon={<MdLockOutline />}
      title={formatMessage(messages.title)}
      error={formatMessage(messages.content)}
    />
  );
};
