import { useMaasUserQuery } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { RouterTab } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import { SuspensionAlert, ErrorPage, Four0Three } from '~/common';

import { PersonalDetails, PersonalDetailsLoading } from '../PersonalDetails';

import messages from './messages';

export const Profile = () => {
  const { id = '' } = useParams<'id'>();

  const { data: maasUser, isError, error } = useMaasUserQuery(id);

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <>
      <SuspensionAlert />
      <div
        className="container min-w-screen-sm max-w-3xl py-12"
        data-testid="MAAS_USER"
      >
        <div className="flex px-4 gap-12">
          <RouterTab to="personal-details">
            <FormattedMessage {...messages.personalDetails} />
          </RouterTab>
        </div>
        <Routes>
          <Route
            path="personal-details"
            element={
              maasUser ? (
                <PersonalDetails maasUser={maasUser} />
              ) : (
                <PersonalDetailsLoading />
              )
            }
          />
          <Route
            path="*"
            element={<Navigate replace to="../personal-details" />}
          />
        </Routes>
      </div>
    </>
  );
};
