import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md';
import { UserDiscount, UserPack, UserRentalPass } from '@cooltra/api';

export type OffersNavigatorProps = {
  currentIndex: number;
  offers?: (UserDiscount | UserPack | UserRentalPass)[];
  onChangeCurrentIndex: (newCurrentIndex: number) => void;
};

export const OffersNavigator = ({
  currentIndex,
  onChangeCurrentIndex,
  offers,
}: OffersNavigatorProps) => {
  if (!offers || !Array.isArray(offers) || offers.length <= 1) {
    return <span />;
  }

  const next = () => {
    let nextPosition: number;

    if (currentIndex === offers.length - 1) {
      nextPosition = 0;
    } else {
      nextPosition = currentIndex + 1;
    }

    onChangeCurrentIndex(nextPosition);
  };
  const prev = () => {
    let prevPosition: number;

    if (currentIndex === 0) {
      prevPosition = offers.length - 1;
    } else {
      prevPosition = currentIndex - 1;
    }

    onChangeCurrentIndex(prevPosition);
  };

  return (
    <div className="flex items-center justify-between pt-6">
      <span className="text-sm text-neutral-300">
        {currentIndex + 1}/{offers.length}
      </span>
      <div className="flex gap-2 text-primary-500">
        <button aria-label="Prev" onClick={prev}>
          <MdOutlineChevronLeft className="cursor-pointer" />
        </button>
        <button aria-label="Next" onClick={next}>
          <MdOutlineChevronRight className="cursor-pointer" />
        </button>
      </div>
    </div>
  );
};
