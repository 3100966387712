import { Button, ButtonProps } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from '@cooltra/form';

import { ReviewFormValues } from '../../review-form-types';
import { useHandleSubmit } from '../useHandleSubmit';

import messages from './messages';

export type ActivateButtonProps = Omit<
  ButtonProps,
  'disabled' | 'emphasis' | 'children' | 'onClick'
> & {
  onActivate: () => void;
};

export const ActivateButton = ({
  onActivate,
  loading,
  ...rest
}: ActivateButtonProps) => {
  const { dirty } = useFormContext<ReviewFormValues>();
  const handleClick = useHandleSubmit(onActivate);

  return (
    <Button
      disabled={!dirty}
      loading={loading}
      emphasis="high"
      onClick={handleClick}
      {...rest}
    >
      <FormattedMessage {...messages.activateButton} />
    </Button>
  );
};
