import { AlgorithmPrice, CoveragePricesItem } from '@cooltra/station-based-api';

import { CoveragePriceFormValues } from './form-details';

export const getFormValuesFromCoverageDetails = (
  coveragePrice: CoveragePricesItem
): CoveragePriceFormValues => {
  const getAlgorithmPrice = (): AlgorithmPrice => {
    if (!coveragePrice) {
      return 'BASE';
    }

    return coveragePrice.algorithmPrice;
  };

  const getPriceValue = (): string => {
    if (!coveragePrice) {
      return '';
    }

    return coveragePrice.price.value.toString();
  };

  return {
    price: getPriceValue(),
    algorithmName: getAlgorithmPrice(),
  };
};
