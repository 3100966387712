import { useRouteState } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { matchPath } from 'react-router-dom';
import { Provider } from '@cooltra/api';

import { RouterBackLink } from '~/common';

import messages from './messages';

export type InvoiceBackLink = {
  userId: string;
  provider: Provider | null;
};

export const InvoiceBackLink = ({ userId, provider }: InvoiceBackLink) => {
  const routeState = useRouteState<'from'>();

  const isMaasUser = provider != 'COOLTRA';

  const invoicesUrl = isMaasUser
    ? `/maas/users/${userId}/account/billing/invoices`
    : `/users/${userId}/account/billing/invoices`;

  if (!routeState?.from) {
    return (
      <RouterBackLink to={invoicesUrl}>
        <FormattedMessage {...messages.backToInvoices} />
      </RouterBackLink>
    );
  }

  const hasComeFromRental = !!matchPath('rentals/:rentalId', routeState.from);

  return (
    <RouterBackLink to={hasComeFromRental ? routeState.from : invoicesUrl}>
      {hasComeFromRental ? (
        <FormattedMessage {...messages.backToRental} />
      ) : (
        <FormattedMessage {...messages.backToInvoices} />
      )}
    </RouterBackLink>
  );
};
