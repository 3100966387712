import { defineMessages } from 'react-intl';
import { InvoiceType } from '@cooltra/api';

export default defineMessages<InvoiceType>({
  INVOICE: {
    defaultMessage: 'Invoice',
  },
  CREDIT_NOTE: {
    defaultMessage: 'Credit note',
  },
  FOREIGNER_INVOICE: {
    defaultMessage: 'Foreigner invoice',
  },
  FOREIGNER_CREDIT_NOTE: {
    defaultMessage: 'Foreigner credit note',
  },
});
