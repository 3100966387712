import { defineMessages } from 'react-intl';

export default defineMessages({
  days: {
    defaultMessage: '{totalDays, plural, one {# day} other {# days}}',
  },
  coverageAndAccessories: {
    defaultMessage: 'Coverages and accessories',
  },
  contractHistory: {
    defaultMessage: 'Contract history',
  },
  contract: {
    defaultMessage: 'Contract',
  },
});
