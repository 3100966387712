import { useOperatorsQuery } from '@cooltra/auth-api';
import { FormattedMessage } from 'react-intl';

import { StaticField, StaticFieldLoading } from '../../StaticField';

import messages from './messages';

export type OperatorStaticProps = {
  value: string;
};

export const OperatorStatic = ({ value }: OperatorStaticProps) => {
  const { data = [], isLoading } = useOperatorsQuery();

  return isLoading ? (
    <StaticFieldLoading />
  ) : (
    <StaticField
      renderLabel={<FormattedMessage {...messages.operator} />}
      renderValue={data.find(({ externalId }) => value === externalId)?.name}
    />
  );
};
