import { ParkingReview } from '@cooltra/api';
import { defineMessages } from 'react-intl';

export default defineMessages<ParkingReview>({
  PARKED_CORRECTLY: {
    defaultMessage: 'Parking correctly',
  },
  MAYBE_PARKED_INCORRECTLY: {
    defaultMessage: 'Maybe parked incorrectly',
  },
  PARKED_INCORRECTLY: {
    defaultMessage: 'Parking incorrectly',
  },
  INCORRECT_PHOTO: {
    defaultMessage: 'Incorrect photo',
  },
});
