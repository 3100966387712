import { UserListItem } from '@cooltra/api';
import { capitalize, classNames } from '@cooltra/utils';
import { RouterCardLink } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';

import { MaasLogo, UserStateBadge } from '~/common';
import { cities } from '~/common/System/messages';

import { UserRowLayout } from '../UserRowLayout';

export type UserCardProps = {
  user: UserListItem;
};

export const UserCard = ({
  user: { userId, name, system, state, provider },
}: UserCardProps) => {
  const capitalizedFullName = name
    .split(' ')
    .map((name) => capitalize(name.toLowerCase()))
    .join(' ');

  return (
    <RouterCardLink
      data-testid={`USER_LIST_ITEM_${userId}`}
      to={
        provider === 'COOLTRA'
          ? `/users/${userId}/account/overview`
          : `/maas/users/${userId}/account/profile/personal-details`
      }
      className={classNames(
        'w-full',
        state.value === 'DELETED' ? 'opacity-50' : 'opacity-100'
      )}
    >
      <UserRowLayout
        className="min-h-28 py-4"
        system={
          <span className="text-sm">
            <FormattedMessage {...cities[system]} />
          </span>
        }
        name={
          <span className="text-md font-semibold">{capitalizedFullName}</span>
        }
        provider={<MaasLogo className="h-4" provider={provider} />}
        status={<UserStateBadge state={state.value} />}
      />
    </RouterCardLink>
  );
};
