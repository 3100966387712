import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';

export type StatusContainerProps = HTMLAttributes<HTMLDivElement>;

export const StatusContainer = ({
  children,
  className,
}: StatusContainerProps) => (
  <div
    className={classNames(
      'flex shrink-0 items-center text-xs font-medium gap-0.5',
      className
    )}
  >
    {children}
  </div>
);
