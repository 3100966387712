import { Rental } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { Amount } from '~/common';

import messages from './messages';

export type PricingStartingProps = Pick<Rental, 'pricing'>;

export const PricingStarting = ({ pricing }: PricingStartingProps) => {
  if (!pricing || pricing.startingPrice.value == 0) {
    return <></>;
  }

  return (
    <div
      className="flex justify-between items-center"
      data-testid="RENTAL_PRICING_SUMMARY_STARTING"
    >
      <span className="text-sm text-neutral-400">
        <FormattedMessage {...messages.starting} />
      </span>
      <Amount
        className="text-sm"
        value={pricing.startingPrice.value}
        currency={pricing.startingPrice.currency}
      />
    </div>
  );
};
