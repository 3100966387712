import { defineMessages } from 'react-intl';

export default defineMessages({
  pending: {
    defaultMessage: 'Pending',
  },
  dropOffLocation: {
    defaultMessage: 'Dropoff location',
  },
});
