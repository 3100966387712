import { FormattedMessage } from 'react-intl';
import { RouterNavLink } from '@cooltra/navigation';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { stringifyParams } from '@cooltra/utils';

import messages from '../messages';

export const Users = () => {
  const { hasPermission } = useAuthClaimsQuery();

  if (!hasPermission('read:users')) {
    return <></>;
  }

  const defaultUserParams = {
    keyword: '',
    system: [],
    state: ['AWAITING_REVIEW'],
    provider: 'COOLTRA',
  };

  return (
    <li>
      <RouterNavLink to={`/users?${stringifyParams(defaultUserParams)}`}>
        <FormattedMessage {...messages.users} />
      </RouterNavLink>
    </li>
  );
};
