import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';
import { useLocation } from 'react-router-dom';
import { getParams } from '@cooltra/utils';

import {
  incidentsFiltersInitialValues,
  IncidentsFiltersFormValues,
} from './incidents-filters-form';

const { open } = incidentsFiltersInitialValues;

export const IncidentsFiltersForm = ({ children }: PropsWithChildren) => {
  const { search } = useLocation();
  const param = getParams<keyof IncidentsFiltersFormValues>(search);

  const initialValues = {
    open: param.string('open') || open,
  } as IncidentsFiltersFormValues;

  return <FormProvider initialValues={initialValues}>{children}</FormProvider>;
};
