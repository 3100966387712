import { KeyboardEventHandler } from 'react';
import { InputField, InputFieldProps } from '@cooltra/ui';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { stringifyParams } from '@cooltra/utils';
import { MdSearch } from 'react-icons/md';

import messages from './messages';

type UserInputSearchProps = Omit<InputFieldProps, 'onKeyDown'>;

export const UserInputSearch = (props: UserInputSearchProps) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const keyword = e.currentTarget.value.trim();

    if (e.key === 'Enter' && keyword != '') {
      navigate(
        `/users/search?${stringifyParams({
          keyword,
        })}`
      );
    }
  };

  return (
    <InputField
      type="search"
      className="text-left"
      placeholder={formatMessage(messages.placeholder)}
      leadingIcon={<MdSearch className="text-lg text-neutral-400" />}
      onKeyDown={handleKeyDown}
      {...props}
    />
  );
};
