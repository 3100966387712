import { Vehicle } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { StaticField } from '~/common';

import messages from './messages';

export type ExternalIdStaticProps = {
  value: Vehicle['externalId'];
};

export const ExternalIdStatic = ({ value }: ExternalIdStaticProps) => (
  <StaticField
    renderLabel={<FormattedMessage {...messages.externalId} />}
    renderValue={value}
  />
);
