import { MdOutlineChevronRight } from 'react-icons/md';
import { Icon } from '@cooltra/ui';
import { PropsWithChildren } from 'react';

export const SidebarLinkContent = ({ children }: PropsWithChildren) => (
  <>
    <span className="w-full flex">
      <span className="flex-1 flex">{children}</span>
      <Icon>
        <MdOutlineChevronRight className="text-xl" />
      </Icon>
    </span>
  </>
);
