import { useContext } from 'react';
import { RouterCardLink } from '@cooltra/navigation';
import { Divider } from '@cooltra/ui';
import { PricingGroupPricesListItem } from '@cooltra/station-based-api';
import { classNames } from '@cooltra/utils';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { parse } from 'date-fns';

import { PriceColumn } from '~/common';

import { PriceListTypeContext } from '../../../PriceListTypeContext';
import { PriceListsByPricingGroupRowLayout } from '../PriceListsByPricingGroupRowLayout';
import { ExportPriceList } from '../ExportPriceList/ExportPriceList';

import messages from './messages';

type PriceListsByPricingGroupCardProps = PricingGroupPricesListItem & {
  current: boolean;
};

const LEAP_YEAR = 2024;

export const PriceListsByPricingGroupCard = ({
  id,
  dateFrom,
  dateUntil,
  prices,
  current,
}: PriceListsByPricingGroupCardProps) => {
  const { type } = useContext(PriceListTypeContext);

  return (
    <RouterCardLink
      to={`/price-lists/${type.toLowerCase()}/vehicle-types/${id}`}
      data-testid="PRICE_LISTS_BY_PRICING_GROUP_CARD"
      className={classNames(current && 'bg-success-50')}
      isMultiAction
    >
      <PriceListsByPricingGroupRowLayout
        className="min-h-20"
        appliesIn={
          <div className="flex items-center gap-2">
            <span className="text-sm text-neutral-800 font-semibold">
              <FormattedDate
                value={parse(
                  `${dateFrom}-${LEAP_YEAR}`,
                  'MM-dd-yyyy',
                  new Date()
                )}
                day="2-digit"
                month="short"
              />
            </span>
            <div className="w-4">
              <Divider />
            </div>
            <span className="text-sm text-neutral-800  font-semibold">
              <FormattedDate
                value={parse(
                  `${dateUntil}-${LEAP_YEAR}`,
                  'MM-dd-yyyy',
                  new Date()
                )}
                day="2-digit"
                month="short"
              />
            </span>
            {current && (
              <span className="text-sm text-success-500">
                <FormattedMessage {...messages.current} />
              </span>
            )}
          </div>
        }
        eBike={
          prices.EBIKE ? (
            <PriceColumn
              price={prices.EBIKE.price}
              algorithmPrice={prices.EBIKE.algorithmPrice}
              priceList={prices.EBIKE.priceList}
            />
          ) : (
            <span />
          )
        }
        standard={
          prices.STANDARD ? (
            <PriceColumn
              price={prices.STANDARD.price}
              algorithmPrice={prices.STANDARD.algorithmPrice}
              priceList={prices.STANDARD.priceList}
            />
          ) : (
            <span />
          )
        }
        plus={
          prices.PLUS ? (
            <PriceColumn
              price={prices.PLUS.price}
              algorithmPrice={prices.PLUS.algorithmPrice}
              priceList={prices.PLUS.priceList}
            />
          ) : (
            <span />
          )
        }
        premium={
          prices.PREMIUM ? (
            <PriceColumn
              price={prices.PREMIUM.price}
              algorithmPrice={prices.PREMIUM.algorithmPrice}
              priceList={prices.PREMIUM.priceList}
            />
          ) : (
            <span />
          )
        }
        superPremium={
          prices.SUPER ? (
            <PriceColumn
              price={prices.SUPER.price}
              algorithmPrice={prices.SUPER.algorithmPrice}
              priceList={prices.SUPER.priceList}
            />
          ) : (
            <span />
          )
        }
        exportPrices={<ExportPriceList prices={prices} />}
      />
    </RouterCardLink>
  );
};
