import { OperationalCondition, OperationalState } from '@cooltra/api';

export type BackgroundCircleProps = {
  operationalState: OperationalState | null | undefined;
  operationalConditions: OperationalCondition[] | null | undefined;
};

export const BackgroundCircle = ({
  operationalState,
  operationalConditions,
}: BackgroundCircleProps) => {
  const getColors = () => {
    if (operationalConditions?.includes('RENTED')) {
      return {
        borderColor: 'fill-primary-50',
        backgroundColor: 'fill-primary-500',
      };
    }
    if (operationalConditions?.includes('RESERVED')) {
      return {
        borderColor: 'fill-primary-50',
        backgroundColor: 'fill-primary-300',
      };
    }
    if (operationalState === 'IN_MAINTENANCE') {
      return {
        borderColor: 'fill-warning-50',
        backgroundColor: 'fill-warning-500',
      };
    }
    if (operationalState === 'NOT_OPERATIONAL') {
      return {
        borderColor: 'fill-danger-50',
        backgroundColor: 'fill-danger-500',
      };
    }
    if (operationalState === 'OPERATIONAL') {
      return {
        borderColor: 'fill-success-50',
        backgroundColor: 'fill-success-500',
      };
    }
    return {
      borderColor: 'fill-neutral-50',
      backgroundColor: 'fill-neutral-100',
    };
  };

  const { borderColor, backgroundColor } = getColors();

  return (
    <g>
      <circle cx="72" cy="72" r="72" className={borderColor}></circle>
      <circle cx="72" cy="72" r="60" className={backgroundColor}></circle>
    </g>
  );
};
