import { ReservationColumnLabel } from '../Reservation';

import { UserReservationRowLayout } from './UserReservationRowLayout';

export const UserReservationsListHeader = () => (
  <UserReservationRowLayout
    createdAt={<ReservationColumnLabel name="createdAt" />}
    expiresAt={<ReservationColumnLabel name="expiresAt" />}
    vehicle={<ReservationColumnLabel name="vehicle" />}
    system={<ReservationColumnLabel name="system" />}
    checkIn={<ReservationColumnLabel name="checkIn" />}
    problemReports={<ReservationColumnLabel name="problemReports" />}
    state={<ReservationColumnLabel name="state" />}
  />
);
