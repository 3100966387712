import { OperationalState } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { Badge, BadgeVariant } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';

import messages from './messages';

export type OperationalStateBadgeProps = {
  operationalState: OperationalState;
  className?: string;
};

export const OperationalStateBadge = ({
  operationalState,
  className,
}: OperationalStateBadgeProps) => {
  let variant: BadgeVariant = 'neutral';

  switch (operationalState) {
    case 'OPERATIONAL':
      variant = 'success';
      break;
    case 'NOT_OPERATIONAL':
      variant = 'danger';
      break;
    case 'IN_MAINTENANCE':
      variant = 'warning';
      break;
  }

  return messages[operationalState] ? (
    <Badge
      className={classNames(className)}
      variant={variant}
      emphasis="medium"
    >
      <FormattedMessage {...messages[operationalState]} />
    </Badge>
  ) : (
    <></>
  );
};
