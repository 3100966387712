import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type CreditRowLayoutProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'title'
> & {
  created: ReactNode;
  title: ReactNode;
  amount: ReactNode;
  remainingAmount: ReactNode;
  expiresAt: ReactNode;
  state: ReactNode;
};

export const CreditRowLayout = ({
  created,
  title,
  amount,
  remainingAmount,
  expiresAt,
  state,
  className,
  ...rest
}: CreditRowLayoutProps) => (
  <div
    className={classNames('pl-6 pr-4 flex items-center gap-4', className)}
    {...rest}
  >
    <div className="w-44 shrink-0">{created}</div>
    <div className="flex-1 shrink-0">{title}</div>
    <div className="w-24 shrink-0 text-right">{amount}</div>
    <div className="w-32 shrink-0 text-right pr-8">{remainingAmount}</div>
    <div className="w-32 shrink-0">{expiresAt}</div>
    <div className="w-20 shrink-0 flex justify-end">{state}</div>
  </div>
);
