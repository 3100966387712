import { FormattedMessage } from 'react-intl';
import { countryOptions } from '@cooltra/countries';
import { InputLabel } from '@cooltra/ui';
import { FormSelectField } from '@cooltra/form';

import messages from './messages';

export const IdCardCountry = () => (
  <FormSelectField
    id="tax-id-country-field"
    name="taxIdCountry"
    isClearable
    options={countryOptions}
    label={
      <InputLabel htmlFor="tax-id-country-field">
        <FormattedMessage {...messages.idCountry} />
      </InputLabel>
    }
  />
);
