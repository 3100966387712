import { InputError } from '@cooltra/ui';
import { useField, useFormContext } from '@cooltra/form';

import { PatternInputField, PatternInputFieldProps } from './PatternInputField';

export type FormPatternInputFieldProps = Omit<
  PatternInputFieldProps,
  | 'valid'
  | 'invalid'
  | 'onChange'
  | 'onBlur'
  | 'value'
  | 'error'
  | 'name'
  | 'defaultValue'
> & {
  name: string;
};

export const FormPatternInputField = ({
  name,
  disabled,
  ...rest
}: FormPatternInputFieldProps) => {
  const { isSubmitting, isFormDisabled } = useFormContext();
  const { value, setValue, setTouched, error, touched } = useField(name);

  return (
    <PatternInputField
      error={touched && error ? <InputError>{error}</InputError> : undefined}
      onChange={({ target: { value } }) => setValue(value.trim())}
      onBlur={() => setTouched(true)}
      value={value}
      name={name}
      disabled={isFormDisabled || isSubmitting || disabled}
      {...rest}
    />
  );
};
