import { defineMessages } from 'react-intl';

export default defineMessages({
  youHaveSuccessfullyCreatedThePromotion: {
    defaultMessage: 'You have successfully created a new promotion!',
  },
  title: {
    defaultMessage: 'Create subscription promotion',
  },
  submitButton: {
    defaultMessage: 'Create promotion',
  },
  badFormattedFields: {
    defaultMessage:
      'This promotion cannot be created, please check that every field is correct',
  },
  duplicatedCodePrefixOrInvalidSubscription: {
    defaultMessage:
      'This promotion cannot be created, code prefix maybe be duplicated or subscription invalid',
  },
});
