import { defineMessages } from 'react-intl';

export default defineMessages({
  enableAlarm: {
    defaultMessage: 'Enable Alarm',
  },
  successOnEnableAlarmVehicle: {
    defaultMessage: 'Enable alarm action has been triggered successfully',
  },
});
