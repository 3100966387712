import { defineMessages } from 'react-intl';

export default defineMessages({
  revokeSessions: {
    defaultMessage: 'Revoke sessions',
  },
  sessionsSuccessfullyRevoked: {
    defaultMessage: 'Sessions successfully revoked',
  },
  errorOnRevokeSessions: {
    defaultMessage:
      'There was a problem at revoking sessions from the user, try refreshing the page',
  },
  revokeSessionsModalTitle: {
    defaultMessage: 'Revoke sessions',
  },
  revokeSessionsModalContent: {
    defaultMessage: 'Are you sure you want to revoke all user sessions?',
  },
});
