import { IntlShape } from 'react-intl';
import { Contract, ContractUser } from '@cooltra/station-based-api';

import {
  createSetError,
  validateAdult,
  validateDate,
  validateEmail,
  validateMaxLength,
  validateExpirationDate,
  validateName,
  validatePhone,
  validateRequired,
  validateStationBasedId,
  validateStationBasedTaxId,
} from '~/validation';
import { Language } from '~/lang/constants';

export type DriverDetailsFormValues = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  language: Language | '';
  zip: string;
  street: string;
  city: string;
  country: string;
  dateOfBirth: string;
  idCardNumber: string;
  idCardExpirationDate: string;
  taxIdNumber: string;
  taxIdCountry: string;
  driverLicenseNumber: string;
  driverLicenseExpirationDate: string;
  driverLicenseCountry: string;
  selfie: string;
  idCardFront: string;
  idCardBack: string;
  driverLicenseFront: string;
  driverLicenseBack: string;
};

type DriverDetailsFormFieldName = keyof DriverDetailsFormValues;

type DriverDetailsFormErrors = Partial<
  Record<DriverDetailsFormFieldName, string | undefined>
>;

const requiredFields: DriverDetailsFormFieldName[] = [
  'firstName',
  'lastName',
  'phone',
  'language',
];

const nameFields: DriverDetailsFormFieldName[] = ['firstName', 'lastName'];

export const validateDriverDetailsForm =
  (intl: IntlShape, servicePointCountry?: string) =>
  (values: DriverDetailsFormValues): DriverDetailsFormErrors => {
    const errors: DriverDetailsFormErrors = {};
    const setError = createSetError(errors);

    nameFields.forEach((fieldName) => {
      setError(fieldName, validateName(intl, values[fieldName]));
    });
    requiredFields.forEach((fieldName) => {
      setError(fieldName, validateRequired(intl, values[fieldName]));
    });
    setError('phone', validatePhone(intl, values.phone));
    setError('email', validateEmail(intl, values.email));

    if (values.zip) {
      setError('zip', validateMaxLength(intl, values.zip, 10));
    }

    if (values.dateOfBirth) {
      setError('dateOfBirth', validateAdult(intl, values.dateOfBirth));
      setError('dateOfBirth', validateDate(intl, values.dateOfBirth));
    }

    if (values.taxIdCountry) {
      if (values.idCardNumber) {
        setError(
          'idCardNumber',
          validateStationBasedId(intl, values.idCardNumber, values.taxIdCountry)
        );
      }
      if (values.taxIdNumber) {
        setError(
          'taxIdNumber',
          validateStationBasedTaxId(
            intl,
            values.taxIdNumber,
            values.taxIdCountry,
            servicePointCountry
          )
        );
      }
    }
    if (values.driverLicenseExpirationDate) {
      setError(
        'driverLicenseExpirationDate',
        validateExpirationDate(intl, values.driverLicenseExpirationDate)
      );
      setError(
        'driverLicenseExpirationDate',
        validateDate(intl, values.driverLicenseExpirationDate)
      );
    }
    if (values.idCardExpirationDate) {
      setError(
        'idCardExpirationDate',
        validateDate(intl, values.idCardExpirationDate)
      );
    }

    return errors;
  };

export const getDriverFormInitialValues = ({
  user,
}: Contract): DriverDetailsFormValues => ({
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  phone: user?.phone || '',
  email: user?.email || '',
  language: (user?.language as Language) || '',
  zip: user?.zip || '',
  street: user?.street || '',
  city: user?.city || '',
  country: user?.country || '',
  dateOfBirth: user?.dateOfBirth || '',
  idCardNumber: user?.idCardNumber || '',
  idCardExpirationDate: user?.idCardExpirationDate || '',
  taxIdNumber: user?.taxIdNumber || '',
  taxIdCountry: user?.taxIdCountry || '',
  driverLicenseNumber: user?.driverLicenseNumber || '',
  driverLicenseExpirationDate: user?.driverLicenseExpirationDate || '',
  driverLicenseCountry: user?.driverLicenseCountry || '',
  selfie: user?.photos?.selfie || '',
  idCardFront: user?.photos?.idCardFront || '',
  idCardBack: user?.photos?.idCardBack || '',
  driverLicenseFront: user?.photos?.driverLicenseFront || '',
  driverLicenseBack: user?.photos?.driverLicenseBack || '',
});

export const formatDriverDetailsPayload = ({
  firstName,
  lastName,
  phone,
  email,
  language,
  zip,
  city,
  country,
  street,
  dateOfBirth,
  idCardNumber,
  idCardExpirationDate,
  taxIdNumber,
  taxIdCountry,
  driverLicenseNumber,
  driverLicenseExpirationDate,
  driverLicenseCountry,
  idCardBack,
  idCardFront,
  driverLicenseBack,
  driverLicenseFront,
  selfie,
}: DriverDetailsFormValues): ContractUser => ({
  firstName,
  lastName,
  phone,
  email,
  language,
  zip: zip || undefined,
  city: city || undefined,
  country: country || undefined,
  street: street || undefined,
  dateOfBirth: dateOfBirth || undefined,
  idCardNumber: idCardNumber || undefined,
  idCardExpirationDate: idCardExpirationDate || undefined,
  taxIdNumber: taxIdNumber || undefined,
  taxIdCountry: taxIdCountry || undefined,
  driverLicenseNumber: driverLicenseNumber || undefined,
  driverLicenseExpirationDate: driverLicenseExpirationDate || undefined,
  driverLicenseCountry: driverLicenseCountry || undefined,
  ...(selfie ||
  idCardFront ||
  idCardBack ||
  driverLicenseBack ||
  driverLicenseFront
    ? {
        photos: {
          selfie: selfie || undefined,
          idCardFront: idCardFront || undefined,
          idCardBack: idCardBack || undefined,
          driverLicenseFront: driverLicenseFront || undefined,
          driverLicenseBack: driverLicenseBack || undefined,
        },
      }
    : { photos: undefined }),
});
