import { defineMessages } from 'react-intl';

export default defineMessages({
  fullScreen: {
    defaultMessage: 'Full screen',
  },
  uploadPhoto: {
    defaultMessage: 'Upload photo',
  },
  takePhoto: {
    defaultMessage: 'Take photo',
  },
  removePhoto: {
    defaultMessage: 'Remove',
  },
  uploadingError: {
    defaultMessage: "Something went wrong! We couldn't upload the image",
  },
});
