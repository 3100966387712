import { defineMessages } from 'react-intl';

export default defineMessages({
  selectHomeSystemPlaceholder: {
    defaultMessage: 'Select home system',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  save: {
    defaultMessage: 'Save',
  },
  selectNewHomeSystem: {
    defaultMessage: 'Select new home system',
  },
  noHomeSystem: {
    defaultMessage: 'There is no home system',
  },
});
