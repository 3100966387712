import { Route, Routes } from 'react-router-dom';

import { PermissionCheck } from '~/common';

import { CreateService } from './CreateService/CreateService';
import { EditService } from './EditService/EditService';

export const Services = () => (
  <Routes>
    <Route
      path="new"
      element={
        <PermissionCheck permission="write:services">
          <CreateService />
        </PermissionCheck>
      }
    />
    <Route
      path="/:serviceId/*"
      element={
        <PermissionCheck permission="write:services">
          <EditService />
        </PermissionCheck>
      }
    />
  </Routes>
);
