import { useNavigate } from 'react-router-dom';
import { Card } from '@cooltra/ui';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouteState } from '@cooltra/navigation';

import { BackLink } from '~/common';

import messages from './messages';

export type WriteLocationLayoutProps = {
  renderTitle: ReactNode;
  renderContent: ReactNode;
  renderFooter: ReactNode;
};

export const WriteLocationLayout = ({
  renderTitle,
  renderContent,
  renderFooter,
}: WriteLocationLayoutProps) => {
  const navigate = useNavigate();
  const routeState = useRouteState<'from'>();
  const navigateBack = () =>
    routeState?.from === '/settings/locations' ? navigate(-1) : navigate('..');

  return (
    <div
      className="container min-w-screen-sm max-w-2xl py-12"
      data-testid="WRITE_LOCATION"
    >
      <BackLink onClick={navigateBack}>
        <FormattedMessage {...messages.backLink} />
      </BackLink>
      <h1 className="text-3xl mb-9">{renderTitle}</h1>
      <Card className="mb-5">
        <div className="flex flex-col p-14 gap-y-14">{renderContent}</div>
      </Card>
      <div className="flex justify-between items-center">{renderFooter}</div>
    </div>
  );
};
