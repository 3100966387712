import { useToggle } from '@cooltra/hooks';
import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { NewSubscriptionModal } from '../NewSubscriptionModal/NewSubscriptionModal';

import messages from './messages';

export type NewSubscriptionProps = {
  userId: string;
};

export const NewSubscription = ({ userId }: NewSubscriptionProps) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  return (
    <>
      <NewSubscriptionModal
        isOpen={isOpen}
        onClose={toggleOff}
        userId={userId}
      />
      <Button emphasis="high" size="md" onClick={toggleOn}>
        <FormattedMessage {...messages.newSubscription} />
      </Button>
    </>
  );
};
