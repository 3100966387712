import { differenceInDays, differenceInHours } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type IdleForProps = {
  date: string | null | undefined;
};

export const IdleFor = ({ date }: IdleForProps) => {
  if (!date) {
    return null;
  }

  const idleHours = differenceInHours(new Date(), new Date(date));
  const idleDays = differenceInDays(new Date(), new Date(date));

  return idleHours < 24 ? (
    <FormattedMessage {...messages.hours} values={{ hours: idleHours }} />
  ) : (
    <FormattedMessage {...messages.days} values={{ days: idleDays }} />
  );
};
