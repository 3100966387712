import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';

export type HeaderCellProps = HTMLAttributes<HTMLDivElement>;

export const HeaderCell = ({ className, ...rest }: HeaderCellProps) => (
  <div
    className={classNames(
      'border-neutral-100 h-10 flex items-center justify-center',
      className
    )}
    {...rest}
  />
);
