import { FormattedMessage } from 'react-intl';
import { Icon, Tooltip } from '@cooltra/ui';
import { forwardRef } from 'react';
import { getErrorStatus } from '@cooltra/axios';
import { MdErrorOutline } from 'react-icons/md';

import { TimeAgo } from '~/common';

import { TelematicsFieldProps } from '../types';

import messages from './messages';
import { ConnectionBadge } from './ConnectionBadge';

export type ConnectionProps = TelematicsFieldProps & {
  isLoading: boolean;
};

export const Connection = forwardRef<HTMLSpanElement, ConnectionProps>(
  ({ vehicleStatus, error, isLoading }, ref) => {
    if (getErrorStatus(error) === 404) {
      return <></>;
    }

    if (isLoading) {
      return (
        <ConnectionBadge status="loading">
          <FormattedMessage {...messages.loading} />
        </ConnectionBadge>
      );
    }

    if (!vehicleStatus) {
      return <></>;
    }

    if (vehicleStatus.offline) {
      return (
        <div className="flex gap-1">
          <ConnectionBadge status="offline">
            <FormattedMessage {...messages.offline} />
          </ConnectionBadge>
          {vehicleStatus.updatedAt && (
            <Tooltip
              button={
                <span className="flex" ref={ref}>
                  <Icon
                    data-testid="OFFLINE_SINCE"
                    className="pr-1.5 text-neutral-400"
                  >
                    <MdErrorOutline />
                  </Icon>
                </span>
              }
              content={
                <p className="py-2 px-3 text-xs text-neutral-600">
                  <FormattedMessage
                    {...messages.since}
                    values={{
                      timeInThePast: (
                        <TimeAgo ISODate={vehicleStatus.updatedAt} />
                      ),
                    }}
                  />
                </p>
              }
            />
          )}
        </div>
      );
    }

    return (
      <ConnectionBadge status="online">
        <FormattedMessage {...messages.online} />
      </ConnectionBadge>
    );
  }
);
