import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type OtherRentalRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  rentalId: ReactNode;
  system: ReactNode;
  pickUp: ReactNode;
  dropOff: ReactNode;
  state: ReactNode;
};

export const OtherRentalRowLayout = ({
  rentalId,
  system,
  pickUp,
  dropOff,
  state,
  className,
  ...rest
}: OtherRentalRowLayoutProps) => (
  <div
    className={classNames('px-6 grid grid-cols-5 items-center', className)}
    {...rest}
  >
    {rentalId}
    {system}
    {pickUp}
    {dropOff}
    <div className="text-center">{state}</div>
  </div>
);
