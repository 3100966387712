import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Open problem reports',
  },
  noProblemReports: {
    defaultMessage: 'There are no open problem reports for this vehicle',
  },
  error: {
    defaultMessage: 'There has been a problem loading open problem reports',
  },
});
