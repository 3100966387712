import { FormattedMessage } from 'react-intl';
import { Contract } from '@cooltra/station-based-api';
import { getInsuranceName } from '@cooltra/utils';
import { Badge, Divider } from '@cooltra/ui';

import { AccessoryTag } from '~/common';
import { isCustomDelivery, isCustomReturn } from '~/utils/accessories';

import messages from './messages';

type ExtrasProps = Pick<Contract, 'insurance' | 'accessories'>;

export const Extras = ({ insurance, accessories }: ExtrasProps) => {
  if (!insurance && !accessories) {
    return null;
  }

  const regularAccessories = accessories?.filter(
    ({ name }) => !isCustomReturn(name) && !isCustomDelivery(name)
  );

  const customAccessories = accessories?.filter(
    ({ name }) => isCustomReturn(name) || isCustomDelivery(name)
  );

  return (
    <div className="pt-10 pb-3">
      <span className="block text-sm text-neutral-600 mb-6">
        <FormattedMessage {...messages.coverageAndAccessories} />
      </span>
      <div className="gap-4 flex">
        {insurance && (
          <Badge key={insurance} emphasis="low">
            {getInsuranceName(insurance)}
          </Badge>
        )}
        {!!regularAccessories?.length && (
          <>
            <div className="py-1">
              <Divider direction="vertical" className="h-full" />
            </div>
            <ul className="flex flex-wrap gap-2">
              {regularAccessories.map(({ id, name, quantity }) => (
                <li key={id}>
                  <AccessoryTag name={name} quantity={quantity} />
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      {!!customAccessories?.length && (
        <ul className="flex flex-col gap-2 mt-4">
          {customAccessories
            .sort()
            .reverse()
            .map(({ id, name, quantity, info }) => (
              <li key={id} className="flex gap-2 items-center">
                <AccessoryTag name={name} quantity={quantity} />
                <span className="text-sm text-neutral-500">{info}</span>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
