import { defineMessages } from 'react-intl';

export default defineMessages({
  takeVehicle: {
    defaultMessage: 'Take',
  },
  somethingWentWrong: {
    defaultMessage: 'Something went wrong!',
  },
  successfullyTaken: {
    defaultMessage: 'The vehicle has been successfully taken',
  },
  notAuthorizedToPerformTakeOnVehicle: {
    defaultMessage:
      'You are not authorized to perform a take action on a vehicle',
  },
  cannotTake: {
    defaultMessage:
      'Check in Electric Feel if the vehicle is in a reservation or rental',
  },
});
