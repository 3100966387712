import { BadgePlaceholder, Card, Placeholder } from '@cooltra/ui';

import { RentalPassRowLayout } from '../RentalPassRowLayout';

export const RentalPassCardLoading = () => (
  <Card>
    <RentalPassRowLayout
      className="h-16"
      title={<Placeholder className="h-2 w-24" />}
      price={<Placeholder className="h-2 w-12" />}
      validFrom={<Placeholder className="h-2 w-32" />}
      validUntil={<Placeholder className="h-2 w-32" />}
      status={<BadgePlaceholder className="h-2 w-20" />}
    />
  </Card>
);
