import { FormattedMessage } from 'react-intl';
import { capitalize } from '@cooltra/utils';

import { StaticField } from '../../../StaticField/StaticField';

import messages from './messages';

export type FirstNameStaticProps = {
  value: string;
};

export const FirstNameStatic = ({ value }: FirstNameStaticProps) => (
  <StaticField
    renderLabel={<FormattedMessage {...messages.firstName} />}
    renderValue={capitalize(value.toLowerCase())}
  />
);
