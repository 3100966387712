import { defineMessages } from 'react-intl';

export default defineMessages({
  nextRental: {
    defaultMessage: 'Next rental',
  },
  previousRental: {
    defaultMessage: 'Previous rental',
  },
});
