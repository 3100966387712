import { Card, Divider } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import {
  StationBasedPromotionFields,
  StationBasedTranslations,
} from '~/common';

import messages from '../messages';

import { WebOffersDetails } from './WebOffersDetails';
import { CouponDetails } from './CouponDetails';

export const StationBasedFormFields = () => (
  <Card className="w-full min-w-[750px] mb-5">
    <div className="p-10">
      <h2 className="text-xl font-semibold text-neutral-400 mb-8">
        <FormattedMessage {...messages.type} />
      </h2>
      <StationBasedPromotionFields.PromotionTargetSelector />
    </div>
    <Divider className="my-3" />
    <WebOffersDetails />
    <CouponDetails />
    <Divider className="my-3" />
    <div className="p-10 mb-8">
      <h2 className="text-xl font-semibold text-neutral-400 mb-8">
        <FormattedMessage {...messages.validity} />
      </h2>
      <div className="grid gap-y-10">
        <div className="flex gap-x-8 items-start" data-testid="VALIDITY_DATES">
          <StationBasedPromotionFields.ValidFrom />
          <StationBasedPromotionFields.ValidUntil />
        </div>
      </div>
    </div>
    <Divider className="my-3" />
    <div className="p-10">
      <h2 className="text-xl font-semibold text-neutral-400 mb-8">
        <FormattedMessage {...messages.appliesTo} />
      </h2>
      <div className="mb-6">
        <StationBasedPromotionFields.PricingGroups />
      </div>
      <div className="mb-6 pt-2">
        <StationBasedPromotionFields.VehicleTypes />
      </div>
      <div className="mb-6 pt-2">
        <StationBasedPromotionFields.Accessories />
      </div>
      <div className="mb-6 pt-2">
        <StationBasedPromotionFields.Insurances />
      </div>
    </div>
    <StationBasedTranslations />
  </Card>
);
