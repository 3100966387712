import { FormattedMessage, useIntl } from 'react-intl';
import { Placeholder } from '@cooltra/ui';
import { getErrorStatus } from '@cooltra/axios';

import { NoDataAvailable } from '../../NoDataAvailable/NoDataAvailable';
import { TelematicsItemLayout } from '../TelematicsItemLayout/TelematicsItemLayout';
import { TelematicsFieldProps } from '../types';

import messages from './messages';

export const Power = ({ vehicleStatus, error }: TelematicsFieldProps) => {
  const { formatMessage } = useIntl();

  if (getErrorStatus(error) === 404) {
    return (
      <TelematicsItemLayout
        title={formatMessage(messages.power)}
        value={<NoDataAvailable />}
      />
    );
  }

  if (!vehicleStatus) {
    return (
      <TelematicsItemLayout
        title={formatMessage(messages.power)}
        value={<Placeholder className="w-20 h-2" />}
      />
    );
  }

  if (vehicleStatus.ignition === null) {
    return (
      <TelematicsItemLayout
        title={formatMessage(messages.power)}
        value={<NoDataAvailable />}
      />
    );
  }

  const updatedAt =
    vehicleStatus.updatedAt === vehicleStatus.ignition.updatedAt
      ? undefined
      : vehicleStatus.ignition.updatedAt;

  if (!vehicleStatus.ignition.value) {
    return (
      <TelematicsItemLayout
        title={formatMessage(messages.power)}
        value={
          <span className="text-sm text-success-500 font-semibold block">
            <FormattedMessage {...messages.powerOff} />
          </span>
        }
        updatedAt={updatedAt}
      />
    );
  }

  return (
    <TelematicsItemLayout
      title={formatMessage(messages.power)}
      value={
        <span className="text-sm text-danger-500 font-semibold block">
          <FormattedMessage {...messages.powerOn} />
        </span>
      }
      updatedAt={updatedAt}
    />
  );
};
