import { Layer } from 'react-map-gl';

import {
  singleSelectedVehiclePointLayer,
  singleVehiclePointLayer,
} from '../layers/higher-layers';
import {
  singleVehicleExpandedBackgroundLayer,
  singleVehicleExpandedBigBackgroundLayer,
  singleVehicleExpandedBigLayer,
  singleVehicleExpandedLayer,
} from '../layers/lower-layers';
import { VehicleMapSource } from '../../../../VehiclesMap/types';

export const vehiclesMapSources: VehicleMapSource[] = [
  {
    id: 'high-view-of-batteries-single-vehicles',
    filter: ['all', ['==', ['get', 'isMatchWithFilters'], true]],
    cluster: false,
    layers: [
      <Layer
        key="singleSelectedVehiclePointLayer"
        {...singleSelectedVehiclePointLayer}
      />,
      <Layer key="singleVehiclePointLayer" {...singleVehiclePointLayer} />,
    ],
  },
  {
    id: 'battery-view-single-vehicles',
    cluster: false,
    layers: [
      <Layer
        key="singleVehicleExpandedBackgroundLayer"
        {...singleVehicleExpandedBackgroundLayer}
      />,
      <Layer
        key="singleVehicleExpandedLayer"
        {...singleVehicleExpandedLayer}
      />,
      <Layer
        key="singleVehicleExpandedBigBackgroundLayer"
        {...singleVehicleExpandedBigBackgroundLayer}
      />,
      <Layer
        key="singleVehicleExpandedBigLayer"
        {...singleVehicleExpandedBigLayer}
      />,
    ],
  },
];
