import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type ProblemReportTaskCountProps = {
  openTasksCount: number;
};

export const ProblemReportTaskCount = ({
  openTasksCount,
}: ProblemReportTaskCountProps) => {
  if (!openTasksCount || openTasksCount === 0) {
    return <></>;
  }

  return (
    <FormattedMessage
      {...messages.tasksOpen}
      values={{ numberOfOpenTasks: openTasksCount }}
    />
  );
};
