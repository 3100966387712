import { PromotionListItem } from '@cooltra/station-based-api';
import { Badge } from '@cooltra/ui';
import { isFuture, isPast } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type StationBasedStateProps = Pick<
  PromotionListItem,
  'validFrom' | 'validUntil'
>;

export enum StationBasedStateType {
  EXPIRED = 'EXPIRED',
  ACTIVE = 'ACTIVE',
  SCHEDULED = 'SCHEDULED',
}

export const getStationBasedStateType = ({
  validFrom,
  validUntil,
}: StationBasedStateProps) => {
  if (validUntil && isPast(new Date(validUntil))) {
    return StationBasedStateType.EXPIRED;
  }

  if (validFrom && isFuture(new Date(validFrom))) {
    return StationBasedStateType.SCHEDULED;
  }

  return StationBasedStateType.ACTIVE;
};

export const StationBasedState = ({
  validFrom,
  validUntil,
}: StationBasedStateProps) => {
  const promotionStateType = getStationBasedStateType({
    validFrom,
    validUntil,
  });

  if (promotionStateType === StationBasedStateType.EXPIRED) {
    return (
      <Badge emphasis="low">
        <FormattedMessage {...messages.expired} />
      </Badge>
    );
  }

  if (promotionStateType === StationBasedStateType.SCHEDULED) {
    return (
      <Badge>
        <FormattedMessage {...messages.scheduled} />
      </Badge>
    );
  }

  return (
    <Badge variant="success">
      <FormattedMessage {...messages.active} />
    </Badge>
  );
};
