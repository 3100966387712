import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type OpenVehicleTaskCardLayoutProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'content'
> & {
  content: ReactNode;
  updated?: ReactNode;
  assignee: ReactNode;
  blocking: ReactNode;
};

export const OpenVehicleTaskCardLayout = ({
  content,
  updated,
  assignee,
  blocking,
  className,
  ...rest
}: OpenVehicleTaskCardLayoutProps) => (
  <div
    className={classNames('px-8', 'py-4', 'w-full', 'min-h-28', className)}
    {...rest}
  >
    <div className="mb-4 max-w-full">{content}</div>
    <div className="grid grid-cols-12 w-full gap-4">
      <div className="col-span-5">{updated}</div>
      <div className="col-span-4">{assignee}</div>
      <div className="col-span-3 flex items-end justify-end">{blocking}</div>
    </div>
  </div>
);
