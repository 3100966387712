import { Rental, RentalListItem, useRentalsQuery } from '@cooltra/api';
import { addDays, format, subDays } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { dateFormat } from '~/utils/date';

import { OtherRentalCard } from '../OtherRentalCard';
import { OtherRentalsListHeader } from '../OtherRentalsListHeader';

import messages from './messages';

export type OtherRentalsProps = {
  baseRental: Rental;
};

export const OtherRentals = ({ baseRental }: OtherRentalsProps) => {
  const {
    pickUp,
    vehicle: { vehicleId },
  } = baseRental;

  const { isError, isLoading, data } = useRentalsQuery({
    minDate: format(subDays(new Date(pickUp.createdAt), 10), dateFormat),
    maxDate: format(addDays(new Date(pickUp.createdAt), 10), dateFormat),
    vehicleId,
    limit: 1000,
  });

  const rentals = data?.pages
    ? ([] as RentalListItem[]).concat.apply([], data.pages)
    : [];

  if (isLoading || isError) {
    return null;
  }

  const baseRentalIndex = rentals.findIndex(
    (rental) => rental.rentalId === baseRental.rentalId
  );

  const previousRental = rentals[baseRentalIndex + 1];
  const followingRental = rentals[baseRentalIndex - 1];

  if (!previousRental && !followingRental) {
    return null;
  }

  return (
    <div
      className="mb-20 flex flex-col gap-8"
      data-testid="TRAFFIC_OFFENSE_OTHER_RENTALS"
    >
      {followingRental && (
        <div data-testid="TRAFFIC_OFFENSE_NEXT_RENTAL">
          <h2 className="text-base text-neutral-600 mb-4">
            <FormattedMessage {...messages.nextRental} />
          </h2>
          <OtherRentalsListHeader />
          <OtherRentalCard rental={followingRental} />
        </div>
      )}
      {previousRental && (
        <div data-testid="TRAFFIC_OFFENSE_PREVIOUS_RENTAL">
          <h2 className="text-base text-neutral-600 mb-4">
            <FormattedMessage {...messages.previousRental} />
          </h2>
          <OtherRentalsListHeader />
          <OtherRentalCard rental={previousRental} />
        </div>
      )}
    </div>
  );
};
