import { useIntl } from 'react-intl';
import { FormRadioGroupField, useFormContext } from '@cooltra/form';

import { PromotionFormValues, PromotionCodeMode } from '../../PromotionForm';

import messages from './messages';

export const PromotionCodeSelectorMode = () => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext<PromotionFormValues>();
  const { editMode } = values;
  const promotionCodeModeList: PromotionCodeMode[] = [
    'single-code',
    'multi-code',
  ];

  return (
    <FormRadioGroupField
      name="promotionCodeMode"
      innerClassName="flex flex-row gap-6"
      legend={<></>}
      disabled={editMode}
      items={promotionCodeModeList.map((promotionCodeMode) => ({
        value: promotionCodeMode,
        label: formatMessage(messages[promotionCodeMode]),
      }))}
    />
  );
};
