import { defineMessages } from 'react-intl';

export default defineMessages({
  activate: {
    defaultMessage: 'Sign and activate',
  },
  successfulContractActivation: {
    defaultMessage: 'Contract activated successfully',
  },
  vehicleAssignedToAnotherActiveContract: {
    defaultMessage: 'The vehicle is assigned to another active contract',
  },
});
