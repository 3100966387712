import { defineMessages } from 'react-intl';

export default defineMessages({
  restriction: {
    defaultMessage: 'Restriction',
  },
  back: {
    defaultMessage: 'Back',
  },
  createRestriction: {
    defaultMessage: 'Create restriction',
  },
  success: {
    defaultMessage: 'You have successfully created a new geofence restriction!',
  },
});
