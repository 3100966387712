import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Edit location',
  },
  submitButton: {
    defaultMessage: 'Save changes',
  },
  homeSystems: {
    defaultMessage: 'Home systems',
  },
  conflictError: {
    defaultMessage: 'The location with this name already exists',
  },
  success: {
    defaultMessage: 'You have successfully edited the location!',
  },
});
