import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { VehicleTechnicalInspectionRowLayout } from '../VehicleTechnicalInspectionRowLayout/VehicleTechnicalInspectionRowLayout';

import messages from './messages';

export const VehicleTechnicalInspectionsListHeader = () => (
  <div className="px-6">
    <VehicleTechnicalInspectionRowLayout
      scheduledAt={
        <ListColumnLabel>
          <FormattedMessage {...messages.scheduledAt} />
        </ListColumnLabel>
      }
      serviced={
        <ListColumnLabel>
          <FormattedMessage {...messages.serviced} />
        </ListColumnLabel>
      }
      state={
        <ListColumnLabel>
          <FormattedMessage {...messages.state} />
        </ListColumnLabel>
      }
    />
  </div>
);
