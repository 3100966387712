import { ProblemReportColumnLabel } from '~/common';

import { RentalProblemReportRowLayout } from './RentalProblemReportRowLayout';

export const RentalProblemReportsListHeader = () => (
  <RentalProblemReportRowLayout
    problem={<ProblemReportColumnLabel name="problem" />}
    context={<ProblemReportColumnLabel name="context" />}
    created={<ProblemReportColumnLabel name="created" />}
    tasks={<ProblemReportColumnLabel name="tasks" className="text-center" />}
  />
);
