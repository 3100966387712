import { Tooltip } from '@cooltra/ui';
import { TaskListItem } from '@cooltra/api';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { classNames } from '@cooltra/utils';
import { Link } from 'react-router-dom';
import { RouterCardLink } from '@cooltra/navigation';

import {
  OperatorOrGroup,
  ByOperator,
  DateTime,
  ModelImage,
  TaskCardTitle,
  TaskTags,
} from '~/common';

import { TaskRowLayout } from '../TaskRowLayout';

import { VehicleTooltip } from './VehicleTooltip';
import messages from './messages';

export type TaskCardProps = {
  task: Omit<TaskListItem, 'vehicle'>;
  vehicle: TaskListItem['vehicle'];
};

export const TaskCard = forwardRef<HTMLDivElement, TaskCardProps>(
  (
    {
      task: {
        id,
        blocking,
        operatorGroupId,
        operatorId,
        tagIds,
        title,
        updatedAt,
        updatedBy,
        open,
        system,
      },
      vehicle,
    },
    ref
  ) => (
    <RouterCardLink
      isMultiAction
      to={`/tasks/${id}`}
      className={classNames(open ? 'opacity-100' : 'opacity-50')}
    >
      <TaskRowLayout
        className="min-h-28 py-4"
        task={
          <div data-testid={open ? 'OPEN_TASK' : 'CLOSED_TASK'}>
            <TaskCardTitle>{title}</TaskCardTitle>
            <TaskTags tagIds={tagIds} blocking={blocking} />
          </div>
        }
        assignee={
          <div className="text-sm text-neutral-400">
            <OperatorOrGroup
              operatorId={operatorId}
              operatorGroupId={operatorGroupId}
            />
          </div>
        }
        updated={
          <div>
            <DateTime date={updatedAt} />
            <ByOperator id={updatedBy} />
          </div>
        }
        context={
          <div className="inline-block">
            <span className="block text-xs text-neutral-400 text-center">
              <FormattedMessage {...messages.vehicle} />
            </span>
            <Tooltip
              button={
                <div ref={ref} className="inline-block">
                  <Link to={`/vehicles/${vehicle.vehicleId}`}>
                    <ModelImage
                      data-testid="TASK_VEHICLE_MODEL"
                      className="w-16 mt-1"
                      model={vehicle.model}
                      identificationNumber={vehicle.identificationNumber}
                    />
                  </Link>
                </div>
              }
              content={
                <VehicleTooltip
                  {...vehicle}
                  system={system}
                  identificationNumber={vehicle.identificationNumber}
                />
              }
            />
          </div>
        }
      />
    </RouterCardLink>
  )
);
