import { classNames } from '@cooltra/utils';

export const WorkUnitDot = ({
  value,
  skipped,
}: {
  value: number;
  skipped: boolean;
}) => (
  <div
    className={classNames(
      'relative inline-block w-5 h-5 box-content -top-1.5 rounded-full shrink-0 text-neutral-100 leading-5 text-xs text-center',
      skipped ? 'bg-neutral-400' : 'bg-neutral-1000'
    )}
  >
    {value}
  </div>
);
