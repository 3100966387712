import { Checks } from './Checks/Checks';
import { Geolocation } from './Geolocation/Geolocation';
import { InTransit } from './InTransit/InTransit';
import { ParkingPhoto } from './ParkingPhoto/ParkingPhoto';
import { LockCode } from './LockCode/LockCode';
import { ServicePoint } from './ServicePoint/ServicePoint';

export const VehicleFormFields = {
  Checks,
  Geolocation,
  InTransit,
  ParkingPhoto,
  LockCode,
  ServicePoint,
};
