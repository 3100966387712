import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import {
  FormDateInputField,
  FormDateInputFieldProps,
} from '../../FormattedInput';

import messages from './messages';

export type ExpirationDateProps = Omit<
  FormDateInputFieldProps,
  'name' | 'id' | 'label'
>;

export const IdCardExpirationDate = (props: ExpirationDateProps) => (
  <FormDateInputField
    id="id-card-expiration-date"
    label={
      <InputLabel htmlFor="id-card-expiration-date">
        <FormattedMessage {...messages.idCardExpirationDate} />
      </InputLabel>
    }
    name="idCardExpirationDate"
    {...props}
  />
);
