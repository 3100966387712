import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Icon, InputLabel } from '@cooltra/ui';
import {
  FormButton,
  FormCheckboxField,
  FormInputField,
  useFormContext,
} from '@cooltra/form';
import { MdClose, MdEuro } from 'react-icons/md';

import { FormAmountInputField } from '~/common';

import { ManualAdjustmentValues } from './manual-adjustment-form';
import messages from './messages';

export type ManualAdjustmentProps = {
  onClose: () => void;
};

const MAX_LENGTH_DESCRIPTION = 100;

export const ManualAdjustment = ({ onClose }: ManualAdjustmentProps) => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext<ManualAdjustmentValues>();

  return (
    <div className="flex justify-end items-stretch gap-4">
      <FormCheckboxField
        className="mt-2.5"
        name="isNegative"
        id="is-negative"
        label={
          <InputLabel emphasis="low" htmlFor="is-negative">
            <FormattedMessage {...messages.negativeValue} />
          </InputLabel>
        }
      />
      <FormInputField
        name="description"
        maxLength={MAX_LENGTH_DESCRIPTION}
        placeholder={formatMessage(messages.addManualAdjustment)}
        className="w-72"
      />
      <FormAmountInputField
        name="value"
        placeholder="0.00"
        className="w-24"
        prefix={values.isNegative ? '-' : ''}
        trailingIcon={<MdEuro />}
      />
      <FormButton disableInvalid>
        <FormattedMessage {...messages.addAdjustment} />
      </FormButton>
      <Button variant="neutral" emphasis="low" square={true} onClick={onClose}>
        <Icon aria-label={formatMessage(messages.close)} className="text-2xl">
          <MdClose />
        </Icon>
      </Button>
    </div>
  );
};
