import { FormattedMessage } from 'react-intl';
import {
  CurrentActivityVehicleRental,
  CurrentActivityVehicleReservation,
} from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { RouterLink } from '@cooltra/navigation';
import { shortenId } from '@cooltra/utils';

import { UserLink } from '~/common';

import messages from './messages';

export type CurrentRentalOrReservationProps = {
  rental: CurrentActivityVehicleRental | null;
  reservation: CurrentActivityVehicleReservation | null;
};

export const CurrentRentalOrReservation = ({
  rental,
  reservation,
}: CurrentRentalOrReservationProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  const canViewRentals = hasPermission('read:rentals');

  if (!canViewRentals) {
    return (
      <span className="text-neutral-300 text-sm">
        <FormattedMessage {...messages.inARentalOrReservation} />
      </span>
    );
  }

  if (reservation) {
    const {
      user: { provider, userId, name },
      reservationId,
    } = reservation;
    return (
      <div>
        <span className="text-xs text-success-600 block">
          <FormattedMessage {...messages.inAReservation} />
        </span>
        <div className="h-6 mt-1 text-sm">
          <FormattedMessage
            {...messages.reservationIn}
            values={{
              reservationShortId: (
                <span className="font-semibold">
                  #{shortenId(reservationId)}
                </span>
              ),
              userName: (
                <UserLink
                  userId={userId}
                  provider={provider}
                  className="font-semibold"
                >
                  {name}
                </UserLink>
              ),
            }}
          />
        </div>
      </div>
    );
  }

  if (rental) {
    const {
      user: { provider, userId, name },
      rentalId,
    } = rental;
    return (
      <div>
        <span className="text-xs text-success-600 block">
          <FormattedMessage {...messages.inARental} />
        </span>
        <div className="h-6 mt-1 text-sm">
          <FormattedMessage
            {...messages.rentalIn}
            values={{
              rentalShortId: (
                <RouterLink
                  className="font-semibold"
                  to={`/rentals/${rentalId}`}
                >
                  #{shortenId(rentalId)}
                </RouterLink>
              ),
              userName: (
                <UserLink
                  provider={provider}
                  userId={userId}
                  className="font-semibold"
                >
                  {name}
                </UserLink>
              ),
            }}
          />
        </div>
      </div>
    );
  }

  return <></>;
};
