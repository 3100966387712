import { useSearchParams } from 'react-router-dom';

import { ServiceFields } from '~/common';

export const CreateServiceForm = () => {
  const [searchParams] = useSearchParams();
  const vehicleId = searchParams.get('vehicleId') || '';

  return (
    <div data-testid="CREATE_SERVICE" className="flex flex-col gap-y-6 gap-x-8">
      <div className="w-full flex gap-4 justify-strech">
        <ServiceFields.ScheduledMileage vehicleId={vehicleId} />
        <ServiceFields.ScheduledAt optional />
      </div>
    </div>
  );
};
