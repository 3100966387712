import { WorkShiftSummary } from '@cooltra/api/src/modules/work-shifts/types';

import * as Stats from '../ShiftSummary/ShiftStats/Stats';

export type ShiftStatsProps = {
  summary: Pick<
    WorkShiftSummary,
    | 'batterySwaps'
    | 'vehiclesOperational'
    | 'vehiclesWorked'
    | 'vehiclesSkipped'
    | 'energyAdded'
    | 'closedTasks'
    | 'openedTasks'
    | 'averageEnergyAdded'
    | 'totalTakes'
    | 'totalRecollectionTakes'
    | 'totalRebalanceTakes'
    | 'totalMoreOptionTakes'
  >;
};

export const ShiftStats = ({
  summary: {
    batterySwaps,
    vehiclesOperational,
    vehiclesWorked,
    vehiclesSkipped,
    energyAdded,
    closedTasks,
    openedTasks,
    averageEnergyAdded,
    totalTakes,
    totalRecollectionTakes,
    totalRebalanceTakes,
    totalMoreOptionTakes,
  },
}: ShiftStatsProps) => (
  <div className="grid grid-cols-6 grid-rows-2 gap-2 text-center items-center">
    <Stats.TotalTakes totalTakes={totalTakes} />
    <Stats.RebalanceTakes rebalanceTakes={totalRebalanceTakes} />
    <Stats.Swaps batterySwaps={batterySwaps} />
    <Stats.VehiclesWorked vehiclesWorked={vehiclesWorked} />
    <Stats.EnergyAdded energyAdded={energyAdded} />
    <Stats.OpenedTasks openedTasks={openedTasks} />
    <Stats.MoreReasonsTakes moreReasonsTakes={totalMoreOptionTakes} />
    <Stats.RecollectionTakes recollectionTakes={totalRecollectionTakes} />
    <Stats.Operational vehiclesOperational={vehiclesOperational} />
    <Stats.VehiclesSkipped vehiclesSkipped={vehiclesSkipped} />
    <Stats.AverageEnergy averageEnergyAdded={averageEnergyAdded} />
    <Stats.ClosedTasks closedTasks={closedTasks} />
  </div>
);
