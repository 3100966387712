import { HTMLAttributes, ReactNode } from 'react';
import { classNames } from '@cooltra/utils';

export type VehicleRentalRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  pickUp: ReactNode;
  dropOff?: ReactNode;
  duration: ReactNode;
  user: ReactNode;
  system: ReactNode;
  incidents: ReactNode;
  state: ReactNode;
};

export const VehicleRentalRowLayout = ({
  pickUp,
  dropOff,
  duration,
  user,
  system,
  incidents,
  state,
  className,
  ...rest
}: VehicleRentalRowLayoutProps) => (
  <div
    className={classNames(
      'w-full flex items-center gap-4 pl-6 pr-4',
      className
    )}
    {...rest}
  >
    <div className="w-32">{pickUp}</div>
    <div className="w-32">{dropOff}</div>
    <div className="w-20 ml-4">{duration}</div>
    <div className="w-40">{user}</div>
    <div className="flex-1 text-center">{system}</div>
    <div className="w-28 text-center">{incidents}</div>
    <div className="w-32 text-center">{state}</div>
  </div>
);
