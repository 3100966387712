import { Placeholder } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type EnergyAddedProps = {
  energyAdded?: number;
};

export const EnergyAdded = ({ energyAdded }: EnergyAddedProps) => (
  <div className="flex flex-col">
    {energyAdded !== undefined ? (
      <span className="text-md text-neutral-1000 font-semibold">
        {energyAdded}%
      </span>
    ) : (
      <Placeholder className="w-6 h-5 mx-auto mb-1" />
    )}
    <span className="text-xs text-neutral-300">
      <FormattedMessage {...messages.energyAdded} />
    </span>
  </div>
);
