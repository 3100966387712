import { FormattedMessage } from 'react-intl';
import { InputLabel } from '@cooltra/ui';
import { countryOptions } from '@cooltra/countries';
import { FormSelectField } from '@cooltra/form';

import messages from './messages';

export const DriverLicenseCountry = () => (
  <FormSelectField
    id="driver-license-country-field"
    name="driverLicenseCountry"
    isClearable
    options={countryOptions}
    label={
      <InputLabel htmlFor="driver-license-country-field">
        <FormattedMessage {...messages.driverLicenseCountry} />
      </InputLabel>
    }
  />
);
