import { VehicleProblemReportListItem } from '@cooltra/api';
import { RouterCardLink } from '@cooltra/navigation';

import { DateTime, Context, ProblemReportCardInfo, ByUser } from '~/common';

import { OpenVehicleProblemReportLayout } from './OpenVehicleProblemReportLayout';

export type OpenVehicleProblemReportCardProps = {
  problemReport: VehicleProblemReportListItem;
};

export const OpenVehicleProblemReportCard = ({
  problemReport: {
    problemReportId,
    tags,
    description,
    numberImages,
    rentalId,
    reservationId,
    createdAt,
    createdBy,
    open,
  },
}: OpenVehicleProblemReportCardProps) => (
  <RouterCardLink isMultiAction to={`/problem-reports/${problemReportId}`}>
    <OpenVehicleProblemReportLayout
      className="min-h-28 py-4"
      problem={
        <ProblemReportCardInfo
          open={open}
          numberImages={numberImages}
          description={description}
          tags={tags}
        />
      }
      context={<Context rentalId={rentalId} reservationId={reservationId} />}
      created={
        <div>
          <DateTime date={createdAt} />
          <ByUser user={createdBy} />
        </div>
      }
    />
  </RouterCardLink>
);
