import { Legend } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  FormDateInputField,
  FormTimeInputField,
} from '../../../../FormattedInput';

import messages from './messages';

export const RedeemableFrom = ({ disabled }: { disabled: boolean }) => {
  const { formatMessage } = useIntl();

  return (
    <fieldset className="flex gap-2" data-testid="REDEEMABLE_FROM">
      <Legend className="mb-1">
        <FormattedMessage {...messages.redeemableFrom} />
      </Legend>
      <FormDateInputField
        className="w-32"
        name="redeemableFromDate"
        disabled={disabled}
        aria-label={formatMessage(messages.date)}
      />
      <FormTimeInputField
        className="w-20"
        name="redeemableFromTime"
        disabled={disabled}
        aria-label={formatMessage(messages.time)}
      />
    </fieldset>
  );
};
