import { OperationalCondition, OperationalState } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { Badge, BadgeVariant } from '@cooltra/ui';
import { classNames, getKeys } from '@cooltra/utils';
import { stateOperationalConditions } from '@cooltra/vehicle-utils';

import messages from './messages';

export type OperationalConditionBadgeProps = {
  operationalCondition: OperationalCondition;
  className?: string;
};

const operationalConditionBadges: {
  [key in OperationalState]: BadgeVariant;
} = {
  OPERATIONAL: 'success',
  NOT_OPERATIONAL: 'danger',
  IN_MAINTENANCE: 'warning',
};

export const OperationalConditionBadge = ({
  operationalCondition,
  className,
}: OperationalConditionBadgeProps) => {
  const operationalState = getKeys(stateOperationalConditions).find((state) =>
    stateOperationalConditions[state].includes(operationalCondition)
  );

  const badgeVariant = operationalState
    ? operationalConditionBadges[operationalState]
    : 'neutral';

  return messages[operationalCondition] ? (
    <Badge
      className={classNames(className)}
      variant={badgeVariant}
      emphasis="low"
    >
      <FormattedMessage {...messages[operationalCondition]} />
    </Badge>
  ) : (
    <></>
  );
};
