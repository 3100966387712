import { Provider } from '@cooltra/api';

export const providerNames: { [key in Provider]: string } = {
  COOLTRA: 'Cooltra',
  FREENOW: 'Freenow',
  IMBRIC: 'Imbric',
  SIXT: 'Sixt',
  RENFE: 'Renfe',
  CABIFY: 'Cabify',
  URBI: 'Urbi',
  TELEPASS: 'Telepass',
  UMOB: 'Umob',
};

export const isMaas = (provider: Provider) => provider !== 'COOLTRA';
