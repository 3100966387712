import { FormattedMessage } from 'react-intl';
import { InputLabel } from '@cooltra/ui';
import { FormNativeSelectField, useFormContext } from '@cooltra/form';

import { ContractDetailsFormValues } from '../../contract-details-form';
import { daySlots } from '../utils';

import messages from './messages';

export const PickupTime = () => {
  const {
    values: { pickUpDate, contractStatus },
  } = useFormContext<ContractDetailsFormValues>();

  if (!pickUpDate) {
    return null;
  }

  return (
    <FormNativeSelectField
      className="w-40"
      disabled={contractStatus === 'ACTIVE'}
      options={[
        { label: '', value: '', disabled: true },
        ...daySlots.map((option) => ({
          value: option,
          label: option,
        })),
      ]}
      id="delivery-time"
      label={
        <InputLabel htmlFor="delivery-time">
          <FormattedMessage {...messages.delivery} />
        </InputLabel>
      }
      name="pickUpTime"
    />
  );
};
