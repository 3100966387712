import {
  BadgePlaceholder,
  ButtonPlaceholder,
  Card,
  Placeholder,
} from '@cooltra/ui';

import { SuspensionRowLayout } from '../SuspensionRowLayout/SuspensionRowLayout';

export const SuspensionCardLoading = () => (
  <Card className="h-24 py-0.5 flex">
    <SuspensionRowLayout
      type={<BadgePlaceholder className="w-20" />}
      reason={<Placeholder className="h-2 w-36" />}
      created={
        <>
          <Placeholder className="h-2 w-36 mb-3" />
          <Placeholder className="h-2 w-28" />
        </>
      }
      clear={<ButtonPlaceholder size="sm" className="w-24" />}
    />
  </Card>
);
