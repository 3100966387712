import { defineMessages } from 'react-intl';

export default defineMessages({
  issuedAt: {
    defaultMessage: 'Issued at',
  },
  type: {
    defaultMessage: 'Type',
  },
  invoiceNumber: {
    defaultMessage: 'Number',
  },
  legalEntity: {
    defaultMessage: 'Legal entity',
  },
  total: {
    defaultMessage: 'Total',
  },
  state: {
    defaultMessage: 'State',
  },
});
