import { defineMessages } from 'react-intl';
import { ApprovalReviewType } from '@cooltra/api';

export default defineMessages<ApprovalReviewType>({
  APPROVED: {
    defaultMessage: 'Approved',
  },
  REJECTED: {
    defaultMessage: 'Rejected',
  },
  CORRECTIONS_REQUESTED: {
    defaultMessage: 'Corrections requested',
  },
});
