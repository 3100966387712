import { MdError } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { Card } from '@cooltra/ui';
import { ContractDocument } from '@cooltra/station-based-api';

import { ByOperator, DateTime } from '~/common';

import messages from './messages';
import { CancelButton } from './CancelButton';

export type SentProps = {
  document: ContractDocument;
  contractId: string;
};

export const Sent = ({
  document: { sentBy, sentAt, signedAt, id },
  contractId,
}: SentProps) => (
  <Card key={sentAt || signedAt} className="flex items-center p-5 gap-7">
    <div className="flex-1 flex items-center gap-4 pl-2">
      <MdError
        className="text-warning-500 text-xl"
        data-testid="PENDING_TO_SIGN_DOCUMENT"
      />
      <h4 className="font-semibold text-neutral-800 text-base">
        <FormattedMessage {...messages.contract} />
        <p className="flex items-center gap-1 font-normal text-warning-700 text-sm">
          <FormattedMessage {...messages.pendingSignature} />
        </p>
      </h4>
    </div>
    <div className="flex flex-col items-end">
      <p className="flex items-center gap-1 text-neutral-600 text-sm">
        <FormattedMessage
          {...messages.sentOn}
          values={{
            date: (
              <DateTime
                date={sentAt}
                dateClassName="text-neutral-600 text-sm"
                timeClassName="text-neutral-600 text-sm"
              />
            ),
          }}
        />
      </p>
      <ByOperator id={sentBy} />
    </div>
    <CancelButton contractId={contractId} documentId={id} />
  </Card>
);
