import { ReactNode } from 'react';

export type TooltipItemLayoutProps = {
  title: string;
  value: ReactNode;
};

export const TooltipItemLayout = ({ title, value }: TooltipItemLayoutProps) => (
  <li className="flex gap-8 justify-between items-center">
    <p className="text-xs text-neutral-400">{title}</p>
    {typeof value === 'string' ? (
      <p className="text-xs font-semibold text-neutral-800">{value}</p>
    ) : (
      value
    )}
  </li>
);
