import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { usePackQuery, usePatchPackMutation } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Card, Spinner } from '@cooltra/ui';
import { FormButton, FormHelpers } from '@cooltra/form';
import { useRouteState } from '@cooltra/navigation';

import { BackLink, Four0Four, Four0Three, Log } from '~/common';
import { useNotification } from '~/hooks';

import { PackState } from '../PackState/PackState';
import { formatPackFormValues, PackForm, PackFormValues } from '../PackForm';
import { PackFormFields } from '../PackFormFields';

import messages from './messages';
import { parsePack } from './parse-pack';

export const EditPack = () => {
  const navigate = useNavigate();
  const routeState = useRouteState<'from'>();

  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthClaimsQuery();
  const { packId = '' } = useParams<'packId'>();
  const { data: pack, isLoading } = usePackQuery(packId);

  const { mutateAsync } = usePatchPackMutation(packId);
  const { addErrorNotification, addSuccessNotification } = useNotification();
  if (!hasPermission('write:offers')) {
    return <Four0Three />;
  }

  if (isLoading) {
    return (
      <div className="w-full text-center mt-8">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!pack) {
    return <Four0Four />;
  }

  const navigateBack = () =>
    routeState?.from === '/offers/packs'
      ? navigate(-1)
      : navigate('/offers/packs');

  const onSubmitPackForm = (
    values: PackFormValues,
    { resetForm }: FormHelpers<PackFormValues>
  ) =>
    mutateAsync(formatPackFormValues(values))
      .then(() => {
        resetForm(values);
        addSuccessNotification(formatMessage(messages.updatedSuccess));
      })
      .catch(() => addErrorNotification());

  const {
    titleTranslations,
    state,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
  } = pack;

  return (
    <div className="container min-w-screen-sm max-w-5xl py-12">
      <div className="mb-9">
        <BackLink onClick={navigateBack}>
          <FormattedMessage {...messages.backToPacks} />
        </BackLink>
        <div className="flex items-center gap-4">
          <h1 className="text-3xl">{titleTranslations.en}</h1>
          <PackState state={state} />
        </div>
      </div>

      <PackForm
        formType="edit"
        initialValues={parsePack(pack)}
        disabled={pack.state !== 'SCHEDULED'}
        onSubmit={onSubmitPackForm}
      >
        <div className="flex gap-6 items-start">
          <div>
            <PackFormFields formType="edit" />
            <div className="flex justify-end">
              <FormButton>
                <FormattedMessage {...messages.saveChanges} />
              </FormButton>
            </div>
          </div>
          <Card className="flex flex-col gap-4 p-10 w-64">
            <Log
              user="operator"
              action="create"
              userId={createdBy}
              date={createdAt}
            />
            {updatedBy && updatedAt && (
              <Log
                user="operator"
                action="update"
                userId={updatedBy}
                date={updatedAt}
              />
            )}
          </Card>
        </div>
      </PackForm>
    </div>
  );
};
