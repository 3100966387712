import { useAuthClaimsQuery } from '@cooltra/auth-api';

import { Four0Three } from '~/common';

import { UsersSearch } from '../UsersSearch/UserSearch';
import { UsersOverview } from '../UsersOverview/UsersOverview';

export const UsersLanding = () => {
  const { hasPermission } = useAuthClaimsQuery();

  if (!hasPermission('read:users')) {
    return <Four0Three />;
  }

  return (
    <>
      <UsersSearch />
      <UsersOverview />
    </>
  );
};
