import { FormProvider, FormProviderProps } from '@cooltra/form';
import { useIntl } from 'react-intl';

import {
  rentalManualCloseFormInitialValues,
  RentalManualCloseFormValues,
  validateRentalManualCloseForm,
} from './rental-manual-close-form';

export type RentalManualCloseFormProps = Omit<
  FormProviderProps<RentalManualCloseFormValues>,
  'initialValues' | 'validate'
> & {
  rentalCreatedAt: string;
};

export const RentalManualCloseForm = ({
  children,
  rentalCreatedAt,
  ...rest
}: RentalManualCloseFormProps) => {
  const intl = useIntl();

  return (
    <FormProvider
      initialValues={rentalManualCloseFormInitialValues}
      validate={validateRentalManualCloseForm(intl, rentalCreatedAt)}
      {...rest}
    >
      {children}
    </FormProvider>
  );
};
