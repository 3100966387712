import { Button } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { MdSend } from 'react-icons/md';
import { useSendCheckInReminderEmailMutation } from '@cooltra/station-based-api';

import { ConfirmModal } from '~/common';
import { useContractFlags, useNotification } from '~/hooks';

import messages from './messages';

export type SendCheckInEmailProps = {
  contractId: string;
};

export const SendCheckInEmail = ({ contractId }: SendCheckInEmailProps) => {
  const { formatMessage } = useIntl();

  const { canSendEmail } = useContractFlags();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const { mutateAsync, isPending } =
    useSendCheckInReminderEmailMutation(contractId);

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleSendEmail = () => {
    mutateAsync()
      .then(() => {
        addSuccessNotification(formatMessage(messages.success));
        toggleOff();
      })
      .catch(() => {
        addErrorNotification();
      });
  };

  if (!canSendEmail) {
    return null;
  }

  return (
    <div className="flex justify-end mb-4">
      <Button size="sm" onClick={toggleOn} trailingIcon={<MdSend />}>
        <FormattedMessage {...messages.sendCheckInEmail} />
      </Button>
      <ConfirmModal
        className="max-w-md min-w-md"
        isOpen={isOpen}
        title={formatMessage(messages.modalTitle)}
        content={formatMessage(messages.modalContent)}
        onClose={toggleOff}
        onConfirm={handleSendEmail}
        loading={isPending}
      />
    </div>
  );
};
