import { FormattedMessage } from 'react-intl';
import { Icon, Tooltip } from '@cooltra/ui';
import { MdInfoOutline } from 'react-icons/md';

import messages from './messages';

export type WarningTooltipProps = {
  isDepositWithheld: boolean;
  isContractStatusBooked: boolean;
};

export const WarningTooltip = ({
  isDepositWithheld,
  isContractStatusBooked,
}: WarningTooltipProps) => {
  if (!isDepositWithheld || !isContractStatusBooked) {
    return null;
  }

  return (
    <Tooltip
      button={
        <button>
          <Icon className="text-xl text-warning-600">
            <MdInfoOutline />
          </Icon>
        </button>
      }
      content={
        <div className="p-4 max-w-60 text-center">
          <p>
            <FormattedMessage {...messages.depositWithheld} />
          </p>
        </div>
      }
    />
  );
};
