import { Rental, useRentalQuery } from '@cooltra/api';
import { Card, Divider, TimelineItem } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { capitalize } from '@cooltra/utils';

import {
  DropOff,
  PickUp,
  RentalBillableDuration,
  RentalTotalDistance,
} from '~/common';

import { Pair } from './Pair';
import messages from './messages';
import { RentalDurationWithMinutes } from './RentalDurationWithMinutes/RentalDurationMinutes';

export type TripSummaryProps = {
  rental: Rental;
};

export const TripSummary = ({ rental }: TripSummaryProps) => {
  const { dataUpdatedAt } = useRentalQuery(rental.rentalId);

  return (
    <Card
      className="pt-6 pb-8 px-8 flex flex-col h-full"
      data-testid="TRIP_SUMMARY"
    >
      <h2 className="text-base text-neutral-500 font-semibold mb-8">
        <FormattedMessage {...messages.tripSummary} />
      </h2>
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <Divider />
          <Pair
            label={<FormattedMessage {...messages.subscription} />}
            value={
              rental.subscription ? (
                capitalize(rental.subscription)
              ) : (
                <FormattedMessage {...messages.comingSoon} />
              )
            }
          />
          <Divider />
          <Pair
            label={<FormattedMessage {...messages.duration} />}
            value={
              <RentalDurationWithMinutes
                startDate={rental.pickUp.createdAt}
                finishDate={
                  rental.dropOff?.createdAt || new Date(dataUpdatedAt)
                }
              />
            }
          />
          <Divider />
          {rental.pricing && (
            <>
              <Pair
                label={<FormattedMessage {...messages.billableDuration} />}
                value={
                  <RentalBillableDuration
                    billableDurationInSeconds={
                      rental.pricing?.billableDuration || 0
                    }
                  />
                }
              />
              <Divider />
            </>
          )}
          <Pair
            label={<FormattedMessage {...messages.totalDistance} />}
            value={
              <RentalTotalDistance
                vehicleId={rental.vehicle.vehicleId}
                startMileage={rental.pickUp.mileage}
                finishMileage={rental.dropOff?.mileage}
              />
            }
          />
        </div>
        <div className="flex-1 flex justify-center">
          <div>
            <TimelineItem
              data-testid="PICK_UP"
              header={
                <span className="font-semibold">
                  <FormattedMessage {...messages.pickUp} />
                </span>
              }
              timelineClassName="translate-y-2.5"
            >
              <div className="mt-1 pb-10">
                <PickUp pickUp={rental.pickUp} />
              </div>
            </TimelineItem>
            {rental.dropOff ? (
              <TimelineItem
                data-testid="DROP_OFF"
                header={
                  <span className="font-semibold">
                    <FormattedMessage {...messages.dropOff} />
                  </span>
                }
                isLast
                timelineClassName="translate-y-2.5"
                className="relative"
                contentClassName={rental.dropOff.parkingPhoto && 'pl-16 ml-2'}
              >
                <div className="mt-1">
                  <DropOff dropOff={rental.dropOff} />
                </div>
              </TimelineItem>
            ) : (
              <TimelineItem
                data-testid="DROP_OFF"
                header={
                  <span className="font-semibold text-success-600">
                    <FormattedMessage {...messages.inProgress} />
                  </span>
                }
                isLast
                timelineClassName="translate-y-2.5"
              >
                <div className="mt-1">
                  <span className="text-sm">
                    <FormattedMessage {...messages.now} />
                  </span>
                </div>
              </TimelineItem>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
