import {
  AvailableVehicle,
  useContractAvailableVehiclesQuery,
} from '@cooltra/station-based-api';
import { Divider, Input, List, Modal } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import { MdSearch } from 'react-icons/md';

import { NoSearchResults } from '~/common';

import { AvailableVehicleCard } from '../AvailableVehicleCard/AvailableVehicleCard';
import { AvailableVehicleCardLoading } from '../AvailableVehicleCard/AvailableVehicleCardLoading';

import messages from './messages';

export type AssignLicensePlateModalProps = {
  isOpen: boolean;
  vehicleTypeId: string;
  vehicleTypeName: string;
  contractId: string;
  onClose: () => void;
};

export const AssignLicensePlateModal = ({
  isOpen,
  vehicleTypeId,
  vehicleTypeName,
  contractId,
  onClose,
}: AssignLicensePlateModalProps) => {
  const { formatMessage } = useIntl();
  const [keyword, setKeyword] = useState('');

  const {
    data: vehicles,
    isLoading,
    isError,
  } = useContractAvailableVehiclesQuery(contractId, vehicleTypeId);

  const vehiclesList = vehicles || [];

  const filteredVehicles = vehiclesList.filter(({ licensePlate }) =>
    licensePlate.toLowerCase().includes(keyword.toLowerCase())
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className="px-12">
        <h2 className="text-xl font-semibold text-neutral-800 mb-7">
          <FormattedMessage
            {...messages.orSimilar}
            values={{
              vehicleTypeName,
            }}
          />
        </h2>
        {isError ? (
          <div
            className="min-w-2xl text-center pb-36 pt-20 text-neutral-700"
            data-testid="ASSIGN_LICENSE_PLATE_MODAL_ERROR"
          >
            <h2 className="text-2xl mb-2">
              <FormattedMessage {...messages.emptyTitle} />
            </h2>
            <p className="text-base">
              <FormattedMessage {...messages.errorMsg} />
            </p>
          </div>
        ) : (
          <div
            data-testid="ASSIGN_LICENSE_PLATE_MODAL"
            className="min-w-2xl pb-12"
          >
            {vehiclesList.length === 0 && (
              <div className="text-center pb-32 pt-20 text-neutral-700">
                <h2 className="text-2xl mb-2">
                  <FormattedMessage {...messages.emptyTitle} />
                </h2>
                <p className="text-base">
                  <FormattedMessage {...messages.emptyContent} />
                </p>
              </div>
            )}
            {vehiclesList.length > 0 && (
              <>
                <Input
                  className="w-64 mb-6"
                  placeholder={formatMessage(messages.searchByLicensePlate)}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  leadingIcon={
                    <MdSearch className="text-lg text-neutral-400" />
                  }
                />
                <div className="h-96">
                  <List
                    idProp="licensePlate"
                    data={filteredVehicles}
                    isLoading={isLoading}
                    loadingRows={4}
                    className="overflow-auto max-h-full"
                    renderEmptyMessage={<NoSearchResults />}
                    renderRow={(vehicle: AvailableVehicle, index) => (
                      <>
                        <AvailableVehicleCard
                          contractId={contractId}
                          vehicle={vehicle}
                          onClose={onClose}
                        />
                        {index < filteredVehicles.length - 1 && <Divider />}
                      </>
                    )}
                    renderLoadingRow={
                      <>
                        <AvailableVehicleCardLoading />
                        <Divider
                          direction="horizontal"
                          className="mt-2 w-full"
                        />
                      </>
                    }
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
