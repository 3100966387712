import { FormattedMessage } from 'react-intl';

import { Pair } from '../../Pair';

import messages from './messages';

export type MileagePairProps = {
  mileage: number | undefined;
  isLoading: boolean;
};

export const MileagePair = ({ mileage, isLoading }: MileagePairProps) => {
  if (isLoading) {
    return (
      <div>
        <Pair.Label className="mb-1">
          <FormattedMessage {...messages.currentMileage} />
        </Pair.Label>
        <Pair.ValueLoading />
      </div>
    );
  }

  if (!mileage) {
    return <></>;
  }

  return (
    <div>
      <Pair.Label className="mb-1">
        <FormattedMessage {...messages.currentMileage} />
      </Pair.Label>
      <Pair.Value>{mileage} kms</Pair.Value>
    </div>
  );
};
