import { IntlShape } from 'react-intl';

import {
  createSetError,
  validateDate,
  validateRequired,
  validateTime,
} from '~/validation';

import { StationBasedFormValues } from './station-based-form';
import messages from './messages';

type PromotionFormFieldName = keyof StationBasedFormValues;

type PromotionFormErrors = Partial<
  Record<PromotionFormFieldName, string | undefined>
>;
const validateSpanishTitleMandatory = (
  values: StationBasedFormValues,
  intl: IntlShape,
  setError: (
    errorKey: keyof StationBasedFormValues,
    message: string | undefined
  ) => void
) => {
  const spanishTitleErrorMessage = validateRequired(
    intl,
    values['titleTranslations.es']
  );
  setError('titleTranslations.es', spanishTitleErrorMessage);
};

const validateWebOfferFields = (
  values: StationBasedFormValues,
  intl: IntlShape,
  setError: (
    errorKey: keyof StationBasedFormValues,
    message: string | undefined
  ) => void
) => {
  setError('discount', validateRequired(intl, values['discount']));

  const hasExceedDiscountValues =
    values.discount && Number(values.discount) > 100;
  if (hasExceedDiscountValues) {
    setError('discount', intl.formatMessage(messages.exceedDiscountValue));
  }
};

const validateCouponFields = (
  values: StationBasedFormValues,
  intl: IntlShape,
  setError: (
    errorKey: keyof StationBasedFormValues,
    message: string | undefined
  ) => void
) => {
  setError('code', validateRequired(intl, values['code']));
  if (values.couponType === 'CREDIT') {
    setError('credit.value', validateRequired(intl, values['credit.value']));
  }
  if (values.couponType === 'DISCOUNT') {
    setError('discount', validateRequired(intl, values.discount));
  }
};

const validateWebOfferAppliesTo = (
  values: StationBasedFormValues,
  intl: IntlShape,
  setError: (
    errorKey: keyof StationBasedFormValues,
    message: string | undefined
  ) => void
) => {
  if (
    values.enableAccessories === 'ACCESSORIES_NO' &&
    values.enableVehicleTypes === 'VEHICLE_TYPES_NO' &&
    values.enableInsurances === 'INSURANCES_NO'
  ) {
    setError(
      'enableVehicleTypes',
      intl.formatMessage(messages.oneOfThemMustBeSelected)
    );
    setError(
      'enableAccessories',
      intl.formatMessage(messages.oneOfThemMustBeSelected)
    );
    setError(
      'enableInsurances',
      intl.formatMessage(messages.oneOfThemMustBeSelected)
    );
  }
};

export const validateStationBasedPromotionForm =
  (intl: IntlShape) => (values: StationBasedFormValues) => {
    const errors: PromotionFormErrors = {};
    const setError = createSetError(errors);

    validateRequired(intl, values.promotionTarget);

    if (values.promotionTarget === 'WEB_OFFER') {
      validateWebOfferFields(values, intl, setError);
    }

    if (values.promotionTarget === 'COUPON') {
      validateCouponFields(values, intl, setError);
    }

    setError('validUntilDate', validateDate(intl, values.validUntilDate));
    setError('validUntilTime', validateTime(intl, values.validUntilTime));

    setError('validFromDate', validateDate(intl, values.validFromDate));
    setError('validFromTime', validateTime(intl, values.validFromTime));

    if (values.promotionTarget === 'WEB_OFFER') {
      validateWebOfferAppliesTo(values, intl, setError);
    }

    validateSpanishTitleMandatory(values, intl, setError);

    return errors;
  };
