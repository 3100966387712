import { RentalColumnLabel } from '~/common';

import { RentalRowLayout } from './RentalRowLayout';

export const RentalsListHeader = () => (
  <RentalRowLayout
    data-testid="RENTALS_LIST_HEADER"
    pickUp={<RentalColumnLabel name="pickUp" />}
    dropOff={<RentalColumnLabel name="dropOff" />}
    duration={<RentalColumnLabel name="duration" />}
    vehicle={<RentalColumnLabel name="vehicle" />}
    user={<RentalColumnLabel name="user" />}
    system={<RentalColumnLabel name="system" />}
    incidents={<RentalColumnLabel name="incidents" />}
    state={<RentalColumnLabel name="state" />}
  />
);
