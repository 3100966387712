import { defineMessages } from 'react-intl';

export default defineMessages({
  rental: {
    defaultMessage: 'Rental',
  },
  reservation: {
    defaultMessage: 'Reservation',
  },
});
