import { useToggle } from '@cooltra/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from '@cooltra/form';
import { Button, UploadButton } from '@cooltra/ui';
import { useUploadVehicleParkingPhoto } from '@cooltra/station-based-api';
import { ChangeEvent, useState } from 'react';

import { CameraModal } from '~/common';
import { useNotification } from '~/hooks';

import { VehicleFormValues } from '../../../vehicle-form';

import { ConfirmationModal } from './ConfirmationModal';
import { ParkingPhotoView } from './ParkingPhotoView';
import messages from './messages';

export const ParkingPhotoField = () => {
  const { formatMessage } = useIntl();
  const {
    values: { parkingPhoto },
    setValues,
  } = useFormContext<VehicleFormValues>();
  const [
    isCameraModalOpen,
    { toggleOn: openCameraModal, toggleOff: closeCameraModal },
  ] = useToggle();
  const [
    isConfirmationModalOpen,
    { toggleOn: openConfirmationModal, toggleOff: closeConfirmationModal },
  ] = useToggle();
  const [tempParkingPhotoId, setTempParkingPhotoId] = useState<string | null>(
    null
  );

  const { addErrorNotification } = useNotification();

  const parkingPhotoMutation = useUploadVehicleParkingPhoto();

  const confirmParkingPhoto = () => {
    if (!tempParkingPhotoId) return;

    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        setValues({
          parkingPhoto: tempParkingPhotoId,
          geolocation: {
            latitude: coords.latitude,
            longitude: coords.longitude,
          },
          inTransit: false,
        });
      },
      () => {
        setValues({
          parkingPhoto: tempParkingPhotoId,
          geolocation: null,
          inTransit: false,
        });
      }
    );
    setTempParkingPhotoId(null);
    closeConfirmationModal();
    closeCameraModal();
  };

  const cancelParkingPhoto = () => {
    setTempParkingPhotoId(null);
    closeConfirmationModal();
    closeCameraModal();
  };

  const setParkingPhotoFromCamera = (newParkingPhotoId: string) => {
    setTempParkingPhotoId(newParkingPhotoId);
    openConfirmationModal();
  };

  const uploadParkingPhoto = async ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    const file = target?.files?.[0];

    if (!file) return;

    try {
      const formData = new FormData();
      formData.append('image', file);

      const response = await parkingPhotoMutation.mutateAsync(formData);
      setParkingPhotoFromCamera(response.id);
    } catch (_) {
      addErrorNotification(formatMessage(messages.uploadingError));
    }
  };

  return (
    <>
      {parkingPhoto ? (
        <ParkingPhotoView />
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-[444px] bg-neutral-100 rounded-lg gap-2">
          <Button onClick={openCameraModal} emphasis="high">
            <FormattedMessage {...messages.takePicture} />
          </Button>
          <UploadButton
            id="upload-parking-image"
            emphasis="low"
            accept=".png,.jpg,.jpeg"
            loading={parkingPhotoMutation.isPending}
            onChange={uploadParkingPhoto}
          >
            <FormattedMessage {...messages.uploadPhoto} />
          </UploadButton>
        </div>
      )}
      <CameraModal
        fileType="zeus-parking-photo-image"
        isOpen={isCameraModalOpen}
        onChange={setParkingPhotoFromCamera}
        onSave={parkingPhotoMutation.mutateAsync}
        onRequestClose={closeCameraModal}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onConfirm={confirmParkingPhoto}
        onRequestClose={cancelParkingPhoto}
      />
    </>
  );
};
