import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'No tasks found',
  },
  content: {
    defaultMessage: 'This vehicles has not been assigned any task yet',
  },
});
