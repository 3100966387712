import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from '@cooltra/form';

import { DocumentsFormValues } from '../documents-form';

import messages from './messages';

export type RestoreDriverLicenseProps = {
  onRestore: () => void;
};

export const RestoreDriverLicense = ({
  onRestore,
}: RestoreDriverLicenseProps) => {
  const { setValues, initialValues } = useFormContext<DocumentsFormValues>();

  const restoreDriverLicense = () => {
    onRestore();
    setValues({
      driverLicenseNumber: initialValues.driverLicenseNumber,
      driverLicenseFront: initialValues.driverLicenseFront,
      driverLicenseBack: initialValues.driverLicenseBack,
      driverLicenseExpirationDate: initialValues.driverLicenseExpirationDate,
      driverLicenseCountry: initialValues.driverLicenseCountry,
      driverLicenseCategory: initialValues.driverLicenseCategory,
    });
  };

  return (
    <Button type="button" size="sm" onClick={restoreDriverLicense}>
      <FormattedMessage {...messages.restore} />
    </Button>
  );
};
