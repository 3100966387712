import { Card, Divider } from '@cooltra/ui';

import { PriceListFields } from './Form/Fields';

export const VehicleTypePriceListDetailsFormFields = () => {
  return (
    <Card className="pt-12 mt-8">
      <div className="px-12 pb-12 flex gap-4 items-center">
        <PriceListFields.DateRange />
      </div>
      <Divider />
      <div className="px-12">
        <PriceListFields.ProductCategory productCategory="EBIKE" />
        <Divider />
        <PriceListFields.ProductCategory productCategory="STANDARD" />
        <Divider />
        <PriceListFields.ProductCategory productCategory="PLUS" />
        <Divider />
        <PriceListFields.ProductCategory productCategory="PREMIUM" />
        <Divider />
        <PriceListFields.ProductCategory productCategory="SUPER" />
      </div>
    </Card>
  );
};
