import { defineMessages } from 'react-intl';

export default defineMessages({
  voltage: {
    defaultMessage: 'Avg. voltage',
  },
  range: {
    defaultMessage: 'Range',
  },
  battery: {
    defaultMessage: 'Battery',
  },
});
