import { FormattedMessage } from 'react-intl';
import { capitalize } from '@cooltra/utils';

import { StaticField } from '../../../StaticField/StaticField';

import messages from './messages';

export type LastNameStaticProps = {
  value: string;
};

export const LastNameStatic = ({ value }: LastNameStaticProps) => (
  <StaticField
    renderLabel={<FormattedMessage {...messages.lastName} />}
    renderValue={capitalize(value.toLowerCase())}
  />
);
