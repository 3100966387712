import { FormattedMessage } from 'react-intl';

import { VehiclesFilterFields } from './Fields';
import messages from './messages';

export const VehicleFilters = () => (
  <div className="mb-14">
    <span className="font-semibold text-base text-neutral-700 mb-2 block">
      <FormattedMessage {...messages.filters} />
    </span>
    <div className="w-96">
      <VehiclesFilterFields.ServicePointIdField />
    </div>
  </div>
);
