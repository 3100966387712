import {
  FormNumberInputField,
  FormNumberInputFieldProps,
} from './NumberInputField/FormNumberInputField';

export type FormPhoneFieldProps = Omit<FormNumberInputFieldProps, 'format'>;

export const FormPhoneField = (props: FormPhoneFieldProps) => {
  const format = (val: string) => {
    if (val.length === 0) {
      return '';
    }
    if (val.length > 16) {
      return `+${val.substring(0, 16)}`;
    }
    return `+${val}`;
  };

  return <FormNumberInputField format={format} {...props} />;
};
