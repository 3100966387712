import { defineMessages } from 'react-intl';

export default defineMessages({
  created: {
    defaultMessage: 'Added',
  },
  title: {
    defaultMessage: 'Title',
  },
  amount: {
    defaultMessage: 'Value',
  },
  remainingAmount: {
    defaultMessage: 'Remaining',
  },
  expiresAt: {
    defaultMessage: 'Expires',
  },
  deleted: {
    defaultMessage: 'Deleted',
  },
});
