import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    defaultMessage: 'City Hall license',
  },
  placeholder: {
    defaultMessage: 'All',
  },
  withLicense: {
    defaultMessage: 'With license',
  },
  withoutLicense: {
    defaultMessage: 'Without license',
  },
});
