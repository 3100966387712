import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type AlarmRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  vehicle: ReactNode;
  alarms: ReactNode;
  duration: ReactNode;
};

export const AlarmRowLayout = ({
  vehicle,
  alarms,
  duration,
  className,
  ...rest
}: AlarmRowLayoutProps) => (
  <div className={classNames('w-full flex', className)} {...rest}>
    <div className="w-full flex gap-4 px-8 items-center">
      <div className="w-64">{vehicle}</div>
      <div className="flex-1 shrink-0">{alarms}</div>
      <div className="w-40 flex justify-end">{duration}</div>
    </div>
  </div>
);
