import { defineMessages } from 'react-intl';

export default defineMessages({
  activeBonusZones: {
    defaultMessage: '{total, plural, one {# active} other {# active}}',
  },
  inactiveBonusZones: {
    defaultMessage: '{total, plural, one {# inactive} other {# inactive}}',
  },
  error: {
    defaultMessage: 'Something went wrong!',
  },
  loading: {
    defaultMessage: 'Loading...',
  },
});
