import { FormattedMessage } from 'react-intl';
import { MdOutlineFileDownload, MdOutlineFileUpload } from 'react-icons/md';

import messages from './messages';
import { StatusContainer } from './StatusContainer';

type CentralStandProps = {
  down?: boolean | undefined;
};

export const CentralStand = ({ down }: CentralStandProps) => {
  const getContent = () => {
    if (down === undefined) {
      return <></>;
    }

    if (down) {
      return (
        <>
          <MdOutlineFileDownload className="w-auto h-5 text-success-600" />
          <FormattedMessage {...messages.down} />
        </>
      );
    }

    return (
      <>
        <MdOutlineFileUpload className="w-auto h-5 text-neutral-200" />
        <span className="text-neutral-300">
          <FormattedMessage {...messages.up} />
        </span>
      </>
    );
  };

  return <StatusContainer>{getContent()}</StatusContainer>;
};
