import { FormattedMessage } from 'react-intl';
import { Button, Modal } from '@cooltra/ui';

import messages from './messages';

type ConfirmationModalProps = {
  isOpen: boolean;
  onConfirm: () => void;
  onRequestClose: () => void;
};

export const ConfirmationModal = ({
  isOpen,
  onConfirm,
  onRequestClose,
}: ConfirmationModalProps) => {
  return (
    <Modal
      className="max-w-sm min-w-xs"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <div className="text-center pb-8 px-8">
        <h6 className="text-neutral-700 text-lg font-semibold mb-2">
          <FormattedMessage {...messages.saveParkingPhotoConfirmation} />
        </h6>
        <p className="text-neutral-600 text-sm mb-8">
          <FormattedMessage {...messages.disclaimer} />
        </p>
        <div className="flex justify-center gap-4">
          <Button onClick={onRequestClose}>
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button emphasis="high" onClick={onConfirm}>
            <FormattedMessage {...messages.confirm} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
