import { defineMessages } from 'react-intl';

export default defineMessages({
  vehicle: {
    defaultMessage: 'Vehicle',
  },
  vehicleNotFoundTitle: {
    defaultMessage: 'There are not vehicle types available',
  },
  vehicleNotFoundContent: {
    defaultMessage:
      'We did not find any vehicle type for the current selected service point and dates',
  },
});
