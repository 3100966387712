import { FormattedMessage } from 'react-intl';

import { FormDateInputField, OptionalFieldLabel } from '~/common';

import messages from './messages';

export const InsuranceExpiration = () => (
  <FormDateInputField
    name="insuranceExpiresAt"
    id="insurance-expiration"
    label={
      <OptionalFieldLabel htmlFor="insurance-expiration">
        <FormattedMessage {...messages.insuranceExpiration} />
      </OptionalFieldLabel>
    }
  />
);
