import {
  RentalParkingReview,
  useReviewRentalParkingMutation,
  useVehicleParkedNext,
  useVehicleParkedTotal,
  useVehicleBonusToggleMutation,
  useVehicleBonusTotalQuery,
} from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { useFormContext } from '@cooltra/form';
import { Button, ButtonEmphasis, ButtonVariant } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';

import { useNotification } from '~/hooks';

import { ParkedVehiclesReviewFormValues } from '../ParkedVehiclesReviewForm';

import messages, { rentalParkingReviewMessages } from './messages';

const buttons: {
  variant: ButtonVariant;
  emphasis: ButtonEmphasis;
  review: RentalParkingReview;
}[] = [
  {
    variant: 'danger',
    emphasis: 'high',
    review: 'PARKED_INCORRECTLY',
  },
  {
    variant: 'danger',
    emphasis: 'medium',
    review: 'INCORRECT_PHOTO',
  },
  {
    variant: 'danger',
    emphasis: 'medium',
    review: 'MAYBE_PARKED_INCORRECTLY',
  },
  {
    variant: 'success',
    emphasis: 'high',
    review: 'PARKED_CORRECTLY',
  },
];

export type VehicleBonusFloatingMenuProps = {
  increment: () => void;
  counter: number;
};

export const VehicleBonusFloatingMenu = ({
  increment,
  counter,
}: VehicleBonusFloatingMenuProps) => {
  const { values } = useFormContext<ParkedVehiclesReviewFormValues>();
  const { addErrorNotification } = useNotification();
  const intl = useIntl();

  const { data: vehicleParkedNext, ...vehicleParkedNextQuery } =
    useVehicleParkedNext(values, {
      retry: false,
      enabled: false,
    });

  const { data: vehiclesParkedTotal, ...vehiclesParkedTotalQuery } =
    useVehicleParkedTotal(values, {
      retry: false,
      enabled: false,
    });

  const { data: vehicleBonusTotal, ...vehicleBonusTotalQuery } =
    useVehicleBonusTotalQuery(values.system, {
      enabled: false,
    });

  const { mutateAsync, isSuccess, variables, reset, isPending } =
    useReviewRentalParkingMutation(vehicleParkedNext?.rentalId || '');

  const vehicleBonusMutation = useVehicleBonusToggleMutation(
    vehicleParkedNext?.vehicle.vehicleId || ''
  );

  const incrementCounterAndRefetch = () => {
    increment();
    vehicleParkedNextQuery.refetch();
    if (vehiclesParkedTotal?.total === counter) {
      vehiclesParkedTotalQuery.refetch();
    }
  };

  const createClickHandler = (parkingReview: RentalParkingReview) => () => {
    vehicleBonusTotalQuery.refetch();
    mutateAsync({ parkingReview })
      .then(() => {
        if (parkingReview === 'PARKED_CORRECTLY') {
          incrementCounterAndRefetch();
        }
      })
      .catch(() => addErrorNotification());
  };

  const handleIngoringBonus = () => {
    if (variables?.parkingReview) {
      reset();
      incrementCounterAndRefetch();
    }
  };

  const handleAddingBonus = () =>
    vehicleBonusMutation
      .mutateAsync({ bonusEnabled: true })
      .then(() => {
        reset();
        incrementCounterAndRefetch();
      })
      .catch((error) => {
        let errorNotification;

        switch (getErrorStatus(error)) {
          case 403:
            errorNotification = intl.formatMessage(messages.permanentBonus);
            break;
          case 409:
            errorNotification = intl.formatMessage(messages.limitReached);
            break;
          default:
            errorNotification = undefined;
            break;
        }

        addErrorNotification(errorNotification);
      });

  return (
    <div className="w-full fixed bottom-0 left-0 bg-neutral-0 border-t border-neutral-50 shadow-2xl">
      <div className="w-[70rem] flex items-center justify-between mx-auto">
        {isSuccess &&
        variables?.parkingReview &&
        variables?.parkingReview !== 'PARKED_CORRECTLY' ? (
          <>
            <div>
              <span className="block text-danger-600 font-semibold">
                <FormattedMessage
                  {...rentalParkingReviewMessages[variables.parkingReview]}
                />
              </span>
              <span className="block text-neutral-800">
                <FormattedMessage
                  {...messages.shouldAddBonus}
                  values={{
                    totalAndLimit: (
                      <span className="font-semibold">
                        {vehicleBonusTotal?.total}/{vehicleBonusTotal?.limit}
                      </span>
                    ),
                  }}
                />
              </span>
            </div>
            <div className="flex gap-6 py-4">
              <Button emphasis="low" size="lg" onClick={handleIngoringBonus}>
                <FormattedMessage {...messages.dontAddBonus} />
              </Button>
              <Button
                emphasis="high"
                size="lg"
                loading={vehicleBonusMutation.isPending}
                onClick={handleAddingBonus}
              >
                <FormattedMessage {...messages.addBonus} />
              </Button>
            </div>
          </>
        ) : (
          <>
            <p className="text-neutral-800">
              <FormattedMessage {...messages.howIsVehicleParked} />
            </p>
            <div className="flex gap-6 py-4">
              {buttons.map(({ variant, emphasis, review }) => (
                <Button
                  key={review}
                  size="lg"
                  loading={isPending && variables?.parkingReview === review}
                  emphasis={emphasis}
                  variant={variant}
                  onClick={createClickHandler(review)}
                >
                  <FormattedMessage {...rentalParkingReviewMessages[review]} />
                </Button>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
