import { CheckboxField, InputLabel, RadioGroupCustomField } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { classNames, Money } from '@cooltra/utils';
import { ReactNode, useMemo } from 'react';
import {
  MdBackupTable,
  MdCreditCard,
  MdPayments,
  MdQrCode,
} from 'react-icons/md';
import { FormInputField, useFormContext } from '@cooltra/form';
import { useToggle } from '@cooltra/hooks';

import { PaymentMethod, PayTicketFormValues } from '../PayTicketFormValues';
import { BillingServicePointSelector } from '../../BillingServicePointSelector/BillingServicePointSelector';

import messages from './messages';
import { SubmitButton } from './SubmitButton/SubmitButton';

export type PaymentMethodOption = {
  label: string;
  value: PaymentMethod;
};

export type PayTicketFormProps = {
  hasLinkedPaymentMethod: boolean;
  amount: Money;
  operationNumber?: string | null;
  lastFourDigits?: string | null;
  isLoading: boolean;
  closeModal: VoidFunction;
  contractId: string;
};

export const PayTicketForm = ({
  hasLinkedPaymentMethod,
  amount,
  isLoading,
  closeModal,
  contractId,
}: PayTicketFormProps) => {
  const { formatMessage } = useIntl();
  const [isConfirmed, { toggle, toggleOff }] = useToggle();
  const {
    setValues,
    values: {
      paymentMethod,
      lastFourDigits,
      operationNumber,
      billingServicePointId,
    },
  } = useFormContext<PayTicketFormValues>();

  const paymentMethods: PaymentMethodOption[] = [
    {
      label: formatMessage(messages.tpv),
      value: 'TPV',
    },
    {
      label: formatMessage(messages.cash),
      value: 'CASH',
    },
  ];

  const paymentMethodWithWeb: PaymentMethodOption[] = [
    {
      label: formatMessage(messages.webPayment),
      value: 'CARD',
    },
    ...paymentMethods,
  ];

  paymentMethods.push({
    label: formatMessage(messages.stripeQr),
    value: 'STRIPE_QR',
  });

  const paymentMethodOptions = hasLinkedPaymentMethod
    ? paymentMethodWithWeb
    : paymentMethods;

  const methodIcons: Record<PaymentMethod, ReactNode> = {
    TPV: <MdBackupTable className="text-2xl" />,
    CARD: <MdCreditCard className="text-2xl" />,
    CASH: <MdPayments className="text-2xl" />,
    STRIPE_QR: <MdQrCode className="text-2xl" />,
  };

  const onChangePaymentMethod = (value: string) => {
    toggleOff();
    setValues({ paymentMethod: value as PaymentMethod });
  };

  const isButtonDisabled = useMemo(() => {
    if (paymentMethod === 'CARD') {
      return false;
    }

    if (!billingServicePointId) {
      return true;
    }

    return (
      (!isConfirmed && paymentMethod === 'CASH') ||
      ((!operationNumber || !lastFourDigits) && paymentMethod === 'TPV')
    );
  }, [
    paymentMethod,
    isConfirmed,
    operationNumber,
    lastFourDigits,
    billingServicePointId,
  ]);

  return (
    <div className="min-w-xl px-24 pb-12 flex flex-col gap-2 items-center">
      <RadioGroupCustomField
        className="w-full"
        id="deposit-method"
        legend={
          <label
            htmlFor="deposit-method"
            className="block text-xl font-semibold text-neutral-700 mb-8 text-center"
          >
            <FormattedMessage {...messages.title} />
          </label>
        }
        items={paymentMethodOptions}
        renderRadio={({ label, checked, value }) => (
          <div
            className={classNames(
              'p-6 flex gap-2 w-full font-semibold transition-colors',
              checked && 'text-primary-500'
            )}
          >
            {methodIcons[value as PaymentMethod]}
            <span>{label}</span>
          </div>
        )}
        onChange={onChangePaymentMethod}
        value={paymentMethod}
      />

      {paymentMethod === 'CASH' && (
        <CheckboxField
          id="confirm-cash-payment"
          checked={isConfirmed}
          label={
            <InputLabel emphasis="low" htmlFor="confirm-cash-payment">
              <FormattedMessage {...messages.confirmPayment} />
            </InputLabel>
          }
          onChange={toggle}
          className="mt-9 self-start"
        />
      )}
      {paymentMethod === 'TPV' && (
        <>
          <FormInputField
            id="operationNumber"
            name="operationNumber"
            className="mt-8 w-full"
            label={
              <InputLabel htmlFor="operationNumber">
                <FormattedMessage {...messages.operationNumber} />
              </InputLabel>
            }
          />
          <FormInputField
            id="lastFourDigits"
            name="lastFourDigits"
            className="mt-4 w-full"
            label={
              <InputLabel htmlFor="lastFourDigits">
                <FormattedMessage {...messages.lastFourDigits} />
              </InputLabel>
            }
          />
        </>
      )}
      <div className="pt-4 w-full">
        <BillingServicePointSelector />
      </div>

      <SubmitButton
        contractId={contractId}
        disabled={isButtonDisabled}
        isLoading={isLoading}
        amount={amount}
        closeModal={closeModal}
      />
    </div>
  );
};
