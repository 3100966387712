import { ButtonPlaceholder, Card, Placeholder } from '@cooltra/ui';

import { UninvoicedChargeRowLayout } from '../UninvoicedChargeRowLayout';

export const UninvoicedChargeCardLoading = () => (
  <Card className="py-5">
    <UninvoicedChargeRowLayout
      createdAt={
        <div className="flex flex-col gap-2.5 my-2">
          <Placeholder className="h-2 w-12" />
          <Placeholder className="h-2 w-20" />
        </div>
      }
      rentalId={<Placeholder className="h-2 w-24" />}
      legalEntity={<Placeholder className="h-2 w-40" />}
      total={
        <div className="flex justify-end">
          <Placeholder className="h-2 w-12" />
        </div>
      }
      price={
        <div className="flex justify-end">
          <Placeholder className="h-2 w-12" />
        </div>
      }
      state={<ButtonPlaceholder size="sm" className="w-20" />}
    />
  </Card>
);
