import { FormattedMessage } from 'react-intl';
import { InputLabel } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';
import { useVehicleStatusQuery } from '@cooltra/api';

import { FormNumberInputField, ServiceFormValues } from '~/common';

import messages from './messages';

export type ScheduledMileageProps = {
  vehicleId: string;
};

export const ScheduledMileage = ({ vehicleId }: ScheduledMileageProps) => {
  const { data: vehicleStatus } = useVehicleStatusQuery(vehicleId, {
    enabled: !!vehicleId,
  });
  const {
    values: { scheduledMileage },
  } = useFormContext<ServiceFormValues>();

  return (
    <div className="w-full flex flex-col">
      <FormNumberInputField
        name="scheduledMileage"
        id="scheduledMileage"
        placeholder="10000 km"
        label={
          <InputLabel htmlFor="scheduledMileage">
            <FormattedMessage {...messages.scheduledMileage} />
          </InputLabel>
        }
      />
      {scheduledMileage &&
      Number(scheduledMileage) < Number(vehicleStatus?.mileage?.value) ? (
        <sub className="text-sm text-warning-600">
          <FormattedMessage {...messages.warningMileage} />
        </sub>
      ) : (
        <></>
      )}
    </div>
  );
};
