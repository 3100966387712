import { Vehicle } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { StaticField } from '~/common';

import messages from './messages';

export type TelematicsIdStaticProps = {
  value: Vehicle['telematicsId'];
};

export const TelematicsIdStatic = ({ value }: TelematicsIdStaticProps) => (
  <StaticField
    renderLabel={<FormattedMessage {...messages.telematicsId} />}
    renderValue={value}
  />
);
