import { defineMessages } from 'react-intl';

export default defineMessages({
  redeemableFrom: {
    defaultMessage: 'Valid from',
  },
  redeemableUntil: {
    defaultMessage: 'Valid until',
  },
  date: {
    defaultMessage: 'Date',
  },
  time: {
    defaultMessage: 'Time',
  },
});
