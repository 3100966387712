import { Provider } from '@cooltra/api';
import { capitalize, classNames } from '@cooltra/utils';

import { MaasLogo } from '../../Maas';

import { UserLink } from './UserLink';

export type UserListItemLinkProps = {
  userId: string;
  name: string;
  provider: Provider;
};

export const UserListItemLink = ({
  userId,
  name,
  provider,
}: UserListItemLinkProps) => {
  const capitalizedFullName = name
    .split(' ')
    .map((name) => capitalize(name.toLowerCase()))
    .join(' ');

  return (
    <div
      className={classNames(
        provider === 'COOLTRA' ? 'text-sm' : 'text-xs',
        'truncate'
      )}
    >
      <UserLink
        className="inline font-medium max-w-full"
        provider={provider}
        userId={userId}
      >
        <span className="truncate">{capitalizedFullName}</span>
        <MaasLogo className="h-3.5 mt-0.5 mb-1" provider={provider} />
      </UserLink>
    </div>
  );
};
