import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Price list',
  },
  savePriceList: {
    defaultMessage: 'Save price list',
  },
  updateSuccessfully: {
    defaultMessage: 'The price list has been saved successfully',
  },
  badFormattedFields: {
    defaultMessage:
      'This price list cannot be created, please check that every field is correct',
  },
});
