import { RouterNavLink } from '@cooltra/navigation';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

export const POS = () => {
  const { hasPermission } = useAuthClaimsQuery();

  if (!hasPermission('view:pos')) {
    return <></>;
  }

  return (
    <li>
      <RouterNavLink to="/pos">POS</RouterNavLink>
    </li>
  );
};
