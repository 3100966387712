import { defineMessages } from 'react-intl';

export default defineMessages({
  accountDetailsHeader: {
    defaultMessage: 'Account details',
  },
  email: {
    defaultMessage: 'Email',
  },
  password: {
    defaultMessage: 'Password',
  },
  resetPassword: {
    defaultMessage: 'Reset password',
  },
  languageLabel: {
    defaultMessage: 'Language',
  },
});
