import { FormattedMessage, useIntl } from 'react-intl';
import { FormButton, FormHelpers } from '@cooltra/form';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { usePackMutation } from '@cooltra/api';
import { useNavigate } from 'react-router-dom';
import { useRouteState } from '@cooltra/navigation';

import { BackLink, Four0Three } from '~/common';
import { useNotification } from '~/hooks';

import {
  formatPackFormValues,
  initialPackFormValues,
  PackForm,
  PackFormValues,
} from '../PackForm';
import { PackFormFields } from '../PackFormFields';

import messages from './messages';

export const CreatePack = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { mutateAsync } = usePackMutation();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const routeState = useRouteState<'from'>();

  if (!hasPermission('write:offers')) {
    return <Four0Three />;
  }

  const onSubmitPackForm = (
    values: PackFormValues,
    { resetForm }: FormHelpers<PackFormValues>
  ) =>
    mutateAsync(formatPackFormValues(values))
      .then(({ packId }) => {
        resetForm(initialPackFormValues);
        addSuccessNotification(
          formatMessage(messages.youHaveSuccessfullyCreatedThePack)
        );
        navigate(`/offers/packs/${packId}/edit`);
      })
      .catch(() => addErrorNotification());

  const navigateBack = () =>
    routeState?.from === '/offers/packs'
      ? navigate(-1)
      : navigate('/offers/packs');

  return (
    <div className="container min-w-screen-sm max-w-3xl py-12">
      <BackLink onClick={navigateBack}>
        <FormattedMessage {...messages.backToPacks} />
      </BackLink>
      <h1 className="text-3xl mb-9">
        <FormattedMessage {...messages.title} />
      </h1>
      <PackForm
        formType="create"
        initialValues={initialPackFormValues}
        onSubmit={onSubmitPackForm}
      >
        <PackFormFields formType="create" />
        <div className="flex justify-end">
          <FormButton>
            <FormattedMessage {...messages.createPack} />
          </FormButton>
        </div>
      </PackForm>
    </div>
  );
};
