import { Route, Routes } from 'react-router-dom';

import { Promotions } from './Promotions/Promotions';
import { StationBased } from './StationBased/StationBased';
import { CooltraForEmployees } from './CooltraForEmployees/CooltraForEmployees';
import { Packs } from './Packs/Packs';
import { Passes } from './Passes/Passes';

export const Offers = () => (
  <Routes>
    <Route path="/promotions/*" element={<Promotions />} />
    <Route path="/station-based/*" element={<StationBased />} />
    <Route path="/cooltra-for-employees/*" element={<CooltraForEmployees />} />
    <Route path="/packs/*" element={<Packs />} />
    <Route path="/passes/*" element={<Passes />} />
  </Routes>
);
