import { defineMessages } from 'react-intl';

export default defineMessages({
  users: {
    defaultMessage: 'users',
  },
  oldestUserWait: {
    defaultMessage: 'Oldest user wait',
  },
  reviewUsers: {
    defaultMessage: 'Review users',
  },
  tryAgainLater: {
    defaultMessage:
      'The summary of users in awaiting review by home system is not available now. Please try again later.',
  },
});
