import { defineMessages } from 'react-intl';

export default defineMessages({
  backToPacks: {
    defaultMessage: 'Back to packs',
  },
  saveChanges: {
    defaultMessage: 'Save changes',
  },
  updatedSuccess: {
    defaultMessage: 'You have successfully updated the pack!',
  },
});
