import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type ListItemButtonProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  isSelected?: boolean;
  isDeselected?: boolean;
  className?: string;
  onClick: () => void;
  disabled?: boolean;
};

export const ListItemButton = ({
  onClick,
  disabled = false,
  isSelected,
  isDeselected,
  leadingIcon,
  trailingIcon,
  className,
  children,
  ...rest
}: ListItemButtonProps) => (
  <div>
    <button
      onClick={onClick}
      className={classNames(
        'w-full justify-start transition-colors',
        !isSelected &&
          !isDeselected &&
          'bg-neutral-0 hover:bg-neutral-50 focus-visible:bg-neutral-50',
        isSelected &&
          'bg-primary-50 hover:bg-primary-100 focus-visible:bg-primary-100',
        isDeselected &&
          'bg-danger-50 hover:bg-danger-100 focus-visible:bg-danger-100'
      )}
      disabled={disabled}
    >
      <div className={classNames('h-8 px-4 w-full', className)} {...rest}>
        <div
          className="flex items-center h-full"
          data-testid={
            isSelected
              ? 'SELECTED_LIST_ITEM'
              : isDeselected
              ? 'DESELECTED_LIST_ITEM'
              : undefined
          }
        >
          {leadingIcon}
          <div className="flex-1 text-left truncate">{children}</div>
          {trailingIcon}
        </div>
      </div>
    </button>
  </div>
);
