import { FormattedMessage } from 'react-intl';
import { ContractPayment } from '@cooltra/station-based-api';
import { getInsuranceName, InsuranceName } from '@cooltra/utils';

import { Amount } from '~/common';

import messages from './messages';

type CoverageProps = {
  className?: string;
  payment?: ContractPayment;
};

export const Coverage = ({ className, payment }: CoverageProps) => {
  if (!payment || !payment.insurance) return null;

  const coverageName = getInsuranceName(
    payment.insurance.name as InsuranceName
  );

  return (
    <div className={className}>
      <h3>
        <span className="text-sm font-semibold text-neutral-800">
          <FormattedMessage {...messages.coverage} />
        </span>
      </h3>
      <div className="flex justify-between my-2 gap-8 text-neutral-800">
        <span>
          <FormattedMessage
            {...messages.days}
            values={{
              name: coverageName,
              contractDays: payment.insurance.days,
            }}
          />
        </span>
        <div>
          <Amount {...payment.insurance.price} />
        </div>
      </div>
    </div>
  );
};
