import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    defaultMessage: 'Batteries unbalanced',
  },
  placeholder: {
    defaultMessage: 'All',
  },
  unbalanced: {
    defaultMessage: 'Unbalanced',
  },
  balanced: {
    defaultMessage: 'Balanced',
  },
});
