import { Route, Routes } from 'react-router-dom';

import { Four0Four } from '~/common';

import { ContractsList } from './ContractsList/ContractsList';

export const Contracts = () => (
  <Routes>
    <Route path="/" element={<ContractsList />} />
    <Route path="*" element={<Four0Four />} />
  </Routes>
);
