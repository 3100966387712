import { defineMessages } from 'react-intl';

export default defineMessages({
  selectVehicle: {
    defaultMessage: 'Select vehicle',
  },
  inALocation: {
    defaultMessage: 'In a location',
  },
  since: {
    defaultMessage: 'since',
  },
  noTasks: {
    defaultMessage: 'None',
  },
  tasks: {
    defaultMessage: 'tasks',
  },
  blocking: {
    defaultMessage: 'blocking',
  },
  takenBy: {
    defaultMessage: 'Taken by',
  },
  workInProgress: {
    defaultMessage: 'Work in progress',
  },
  inspection: {
    defaultMessage: 'Inspection:',
  },
  service: {
    defaultMessage: 'Service:',
  },
});
