import { FormattedMessage, useIntl } from 'react-intl';
import { Legend } from '@cooltra/ui';
import { DeliveredAccessories } from '@cooltra/station-based-api';
import { FormCheckboxGroupField } from '@cooltra/form';

import { getAccessoryNameMessage } from '~/utils/accessories';

import messages from './messages';

export type AccessoriesProps = {
  accessories: DeliveredAccessories[] | null;
};

export const Accessories = ({ accessories }: AccessoriesProps) => {
  const intl = useIntl();

  if (!accessories || accessories.length === 0) {
    return (
      <div>
        <Legend>
          <FormattedMessage {...messages.accessories} />
        </Legend>
        <p className="mt-1 text-sm text-neutral-400">
          <FormattedMessage {...messages.thereAreNoAccessories} />
        </p>
      </div>
    );
  }

  return (
    <FormCheckboxGroupField
      innerClassName=""
      name="accessories"
      legend={
        <Legend>
          <FormattedMessage {...messages.accessories} />
        </Legend>
      }
      items={
        accessories.map(({ name }, index) => ({
          name: index.toString(),
          label: intl.formatMessage(getAccessoryNameMessage(name)),
        })) || []
      }
    />
  );
};
