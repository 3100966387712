import { MdClose } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ContractPayment,
  useRemoveContractManualAdjustmentMutation,
} from '@cooltra/station-based-api';
import { Icon } from '@cooltra/ui';

import { useNotification } from '~/hooks';
import { Amount } from '~/common';

import messages from './messages';

type ValueAdjustmentProps = {
  contractId: string;
  className?: string;
  payment?: ContractPayment;
};

export const ValueAdjustment = ({
  contractId,
  className,
  payment,
}: ValueAdjustmentProps) => {
  const { formatMessage } = useIntl();
  const { mutateAsync } = useRemoveContractManualAdjustmentMutation(contractId);
  const { addSuccessNotification, addErrorNotification } = useNotification();

  const handleRemoveTicketItem = (ticketItemId: string) => () =>
    mutateAsync({ manualAdjustmentTicketItemId: ticketItemId })
      .then(() => {
        addSuccessNotification(
          formatMessage(messages.removeSuccessNotification)
        );
      })
      .catch(() => {
        addErrorNotification();
      });

  if (
    !payment ||
    !payment.manualAdjustments ||
    payment.manualAdjustments.length === 0
  ) {
    return null;
  }

  return (
    <div className={className}>
      <h3>
        <span className="text-sm font-semibold text-neutral-800">
          <FormattedMessage {...messages.valueAdjustment} />
        </span>
      </h3>
      <ul>
        {payment.manualAdjustments.map((manualAdjustment) => (
          <li
            key={manualAdjustment.ticketItemId}
            data-testid={manualAdjustment.ticketItemId}
            className="flex justify-between my-2 gap-8 text-neutral-800"
          >
            <div>{manualAdjustment.description}</div>
            <div className="relative">
              <Amount {...manualAdjustment.value} />
              {payment.status === 'PENDING' && (
                <button
                  className="tap-highlight absolute -right-8"
                  onClick={handleRemoveTicketItem(
                    manualAdjustment.ticketItemId
                  )}
                >
                  <Icon
                    aria-label={formatMessage(messages.remove)}
                    className="text-danger-500 font-semibold text-2xl"
                  >
                    <MdClose />
                  </Icon>
                </button>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
