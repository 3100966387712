import { ThreeStateValue, ProblemReportsSort } from '@cooltra/api';

export type UserProblemReportsFiltersFormValues = {
  open: ThreeStateValue;
  minDate: string;
  maxDate: string;
  sort: ProblemReportsSort;
};

export const userProblemReportsFiltersInitialValues: UserProblemReportsFiltersFormValues =
  {
    open: '',
    minDate: '',
    maxDate: '',
    sort: 'createdAt.desc',
  };
