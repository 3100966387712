import { useIntl } from 'react-intl';
import { VehicleStatus } from '@cooltra/api';

import { NoDataAvailable } from '../../NoDataAvailable/NoDataAvailable';
import { TelematicsItemLayout } from '../TelematicsItemLayout/TelematicsItemLayout';

import messages from './messages';

export const Range = ({ range }: Pick<VehicleStatus, 'range'>) => {
  const { formatMessage } = useIntl();

  if (range === null) {
    return (
      <TelematicsItemLayout
        title={formatMessage(messages.range)}
        value={<NoDataAvailable />}
        noPadding
      />
    );
  }

  return (
    <TelematicsItemLayout
      title={formatMessage(messages.range)}
      value={`${range.value} ${range.unit}`}
      noPadding
    />
  );
};
