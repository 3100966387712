import { Task, Vehicle } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { ScrollToTop } from '@cooltra/ui';

import {
  DividedCard,
  Log,
  ProblemReportPair,
  SystemPair,
  VehiclePair,
  TaskBackLink,
  IssueFields,
  ClosedBadge,
  TaskFields,
} from '~/common';

import { Comments } from '../Comments/Comments';

import messages from './messages';

export type ViewTaskProps = {
  task: Task;
  vehicle: Vehicle;
};

export const ViewTask = ({
  task: {
    taskId,
    vehicleId,
    title,
    operatorGroupId,
    operatorId,
    description,
    tagIds,
    blocking,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    open,
    problemReportId,
    system,
  },
  vehicle: { model, externalId, identificationNumber },
}: ViewTaskProps) => (
  <div className="pb-32">
    <ScrollToTop />
    <DividedCard
      className="min-w-4xl max-w-5xl py-12"
      data-testid="VIEW_TASK"
      renderHeader={
        <>
          <TaskBackLink vehicleId={vehicleId} />
          <h2 className="text-3xl">
            <FormattedMessage {...messages.title} />
          </h2>
          {!open && (
            <div className="mt-4">
              <ClosedBadge />
            </div>
          )}
        </>
      }
      renderLeft={
        <div className="flex h-full flex-col gap-12 justify-between">
          <div className="flex flex-col gap-6 mb-28">
            <VehiclePair
              vehicleId={vehicleId}
              model={model}
              externalId={externalId}
              identificationNumber={identificationNumber}
            />
            <SystemPair system={system} />
            {problemReportId && (
              <ProblemReportPair problemReportId={problemReportId} />
            )}
          </div>
          <div className="flex flex-col gap-6">
            <Log
              user="operator"
              action="create"
              userId={createdBy}
              date={createdAt}
            />
            {updatedAt && updatedBy && (
              <Log
                user="operator"
                action="update"
                userId={updatedBy}
                date={updatedAt}
              />
            )}
          </div>
        </div>
      }
      renderRight={
        <div className="flex flex-col gap-8">
          <TaskFields.TitleStatic value={title} />
          <div className="grid grid-cols-2 gap-5">
            <IssueFields.AssigneeStatic value={operatorGroupId} />
            <IssueFields.OperatorStatic value={operatorId} />
          </div>
          <div className="mb-2">
            <TaskFields.TagsStatic value={tagIds} />
          </div>
          <IssueFields.DescriptionStatic value={description} />
          <TaskFields.BlockingStatic value={blocking} />
        </div>
      }
    />
    <div className="container min-w-4xl max-w-5xl" data-testid="TASK_COMMENTS">
      <h3 className="mb-12 text-2xl text-neutral-600">
        <FormattedMessage {...messages.taskComments} />
      </h3>
      <Comments taskId={taskId} />
    </div>
  </div>
);
