import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { UninvoicedChargeRowLayout } from '../UninvoicedChargeRowLayout';

import messages from './messages';

export const UninvoicedChargesListHeader = () => (
  <UninvoicedChargeRowLayout
    createdAt={
      <ListColumnLabel>
        <FormattedMessage {...messages.createdAt} />
      </ListColumnLabel>
    }
    rentalId={
      <ListColumnLabel>
        <FormattedMessage {...messages.rentalId} />
      </ListColumnLabel>
    }
    legalEntity={
      <ListColumnLabel>
        <FormattedMessage {...messages.legalEntity} />
      </ListColumnLabel>
    }
    total={
      <ListColumnLabel>
        <FormattedMessage {...messages.totalPrice} />
      </ListColumnLabel>
    }
    price={
      <ListColumnLabel>
        <FormattedMessage {...messages.totalCharges} />
      </ListColumnLabel>
    }
  />
);
