import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Problem report',
  },
  reportType: {
    defaultMessage: 'Report type',
  },
  userDescription: {
    defaultMessage: 'User description',
  },
  attachedImages: {
    defaultMessage: 'Attached images',
  },
  closeReport: {
    defaultMessage: 'Close report',
  },
  addTask: {
    defaultMessage: 'Add task',
  },
  addIncident: {
    defaultMessage: 'Add incident',
  },
  tasks: {
    defaultMessage: 'Task',
  },
  incidents: {
    defaultMessage: 'Incidents',
  },
});
