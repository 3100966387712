import { ButtonHTMLAttributes } from 'react';
import { Icon } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';

export type PhotoIconButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const PhotoIconButton = ({
  children,
  className,
  ...rest
}: PhotoIconButtonProps) => (
  <button
    type="button"
    className={classNames(
      'text-neutral-0 border-neutral-0 border p-2 rounded-md focus-visible:ring focus-visible:ring-primary-200 transition-shadow text-xl',
      className
    )}
    {...rest}
  >
    <Icon>{children}</Icon>
  </button>
);
