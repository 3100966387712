import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { MdSearch } from 'react-icons/md';
import { Form, FormInputField, FormProvider } from '@cooltra/form';

import { useNavigateWithQueryParams } from '~/hooks';

import messages from './messages';

export const ContractsInputSearch = () => {
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const initialKeyword = searchParams.get('keyword') || '';

  const navigateWithQueryParams = useNavigateWithQueryParams();

  const onSubmit = async ({ keyword }: { keyword: string }) =>
    navigateWithQueryParams({ keyword });

  return (
    <FormProvider
      onSubmit={onSubmit}
      initialValues={{ keyword: initialKeyword }}
    >
      <Form>
        <FormInputField
          autoFocus
          type="search"
          name="keyword"
          className="w-1/3"
          placeholder={formatMessage(messages.placeholder)}
          leadingIcon={<MdSearch className="text-lg text-neutral-400" />}
        />
      </Form>
    </FormProvider>
  );
};
