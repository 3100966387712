import {
  useProblemReportsQuery,
  FetchProblemReportsRequestParams,
  useProblemReportsTotal,
} from '@cooltra/api';
import { RouterLink } from '@cooltra/navigation';
import { Icon, Placeholder } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { MdOutlineChevronRight } from 'react-icons/md';

import { NotAvailable } from '~/common';

import messages from './messages';

export const UserProblemReportsTotal = () => {
  const { id = '' } = useParams<'id'>();

  const params: FetchProblemReportsRequestParams = {
    userId: id,
    page: 1,
    limit: 1,
  };
  const { isLoading, isError } = useProblemReportsQuery(params);

  const total = useProblemReportsTotal(params);

  return (
    <div className="flex flex-col" data-testid="USER_PROBLEM_REPORTS_TOTAL">
      <RouterLink
        to="../problem-reports"
        className="inline-flex text-xs whitespace-nowrap mb-3"
      >
        <FormattedMessage {...messages.totalReports} />
        <Icon className="pl-1 text-base">
          <MdOutlineChevronRight />
        </Icon>
      </RouterLink>
      <div className="text-neutral-800 font-semibold text-3xl">
        {isError ? (
          <NotAvailable />
        ) : isLoading ? (
          <div className="h-9 flex items-center">
            <Placeholder className="h-3 w-12" />
          </div>
        ) : (
          <span className="block">{total}</span>
        )}
      </div>
    </div>
  );
};
