import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';
import { useLocation } from 'react-router-dom';
import { getParams } from '@cooltra/utils';

import {
  PromotionsFiltersFormValues,
  initialPromotionsFiltersFormValues,
} from './promotions-filters-form';

const { state, keyword, type } = initialPromotionsFiltersFormValues;

export const PromotionsFiltersForm = ({ children }: PropsWithChildren) => {
  const { search } = useLocation();
  const params = getParams<keyof PromotionsFiltersFormValues>(search);

  const initialValues = {
    state: params.string('state') || state,
    keyword: params.string('keyword') || keyword,
    type: params.string('type') || type,
  } as PromotionsFiltersFormValues;

  return <FormProvider initialValues={initialValues}>{children}</FormProvider>;
};
