import { defineMessages } from 'react-intl';

export default defineMessages({
  tpv: {
    defaultMessage: 'TPV La Caixa',
  },
  stripeQr: {
    defaultMessage: 'Charge ticket with Stripe QR',
  },
  cash: {
    defaultMessage: 'Cash',
  },
  webPayment: {
    defaultMessage: 'Stripe card',
  },
  title: {
    defaultMessage: 'Payment method',
  },
  confirmPayment: {
    defaultMessage: 'I confirm that the client has paid',
  },
  operationNumber: {
    defaultMessage: 'Operation number',
  },
  lastFourDigits: {
    defaultMessage: 'Last 4 digits of card',
  },
  servicePointWarning: {
    defaultMessage: 'This charge will be assigned to {servicePoint}',
  },
});
