import { HTMLAttributes, ReactNode } from 'react';
import { classNames } from '@cooltra/utils';

export type StaticFieldProps = HTMLAttributes<HTMLDivElement> & {
  renderLabel: ReactNode;
  renderValue: ReactNode;
};

export const StaticField = ({
  renderLabel,
  renderValue,
  className,
  ...rest
}: StaticFieldProps) => (
  <div className={classNames('flex', 'flex-col', 'gap-4', className)} {...rest}>
    <span className="block text-neutral-700 text-base font-semibold h-5">
      {renderLabel}
    </span>
    <span className="block text-neutral-1000 text-sm min-h-5">
      {renderValue || '-'}
    </span>
  </div>
);
