import { InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormMultiSelectField } from '@cooltra/form';

import { useModelOptions } from '../../../Vehicle';

import messages from './messages';

export const Model = () => {
  const { formatMessage } = useIntl();
  const modelOptions = useModelOptions();
  return (
    <FormMultiSelectField
      name="model"
      id="model"
      label={
        <InputLabel htmlFor="model">
          <FormattedMessage {...messages.model} />
        </InputLabel>
      }
      placeholder={formatMessage(messages.placeholder)}
      options={modelOptions}
    />
  );
};
