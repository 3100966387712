import { CalendarHeadCell } from './CalendarHeadCell';

export const DesktopCalendarHead = () => (
  <thead>
    <tr className="mb-4 grid grid-cols-7">
      <CalendarHeadCell weekDay="01" as="th" />
      <CalendarHeadCell weekDay="02" as="th" />
      <CalendarHeadCell weekDay="03" as="th" />
      <CalendarHeadCell weekDay="04" as="th" />
      <CalendarHeadCell weekDay="05" as="th" />
      <CalendarHeadCell weekDay="06" as="th" />
      <CalendarHeadCell weekDay="07" as="th" />
    </tr>
  </thead>
);
