import { Rental } from '@cooltra/api';
import { Card } from '@cooltra/ui';

import { RentalMap as CommonRentalMap } from '~/common';

import { Markers } from './Markers';

const isValidGeolocation = (
  lng: number | null | undefined,
  lat: number | null | undefined
) => lat && lng;

export type RentalMapProps = {
  rental: Rental;
};

export const RentalMap = ({ rental }: RentalMapProps) => {
  const { dropOff, pickUp, checkOutFailures, vehicle } = rental;

  const isRentalDropOffUserGeolocationValid = isValidGeolocation(
    dropOff?.userLongitude,
    dropOff?.userLatitude
  );

  const checkoutFailureBounds: [number, number][] = checkOutFailures
    .filter(({ userLongitude, userLatitude }) =>
      isValidGeolocation(userLongitude, userLatitude)
    )
    .map(({ userLongitude, userLatitude }) => [userLongitude, userLatitude]);

  const mapBounds = [
    isValidGeolocation(pickUp.userLongitude, pickUp.userLatitude) && [
      pickUp.userLongitude,
      pickUp.userLatitude,
    ],
    ...checkoutFailureBounds,
    dropOff &&
      isRentalDropOffUserGeolocationValid && [
        dropOff.userLongitude,
        dropOff.userLatitude,
      ],
  ].filter(Boolean) as [number, number][];

  return (
    <Card
      data-testid="RENTAL_MAP"
      className="h-[30rem] flex flex-col overflow-hidden"
    >
      <CommonRentalMap id="rental-map" rental={rental} mapBounds={mapBounds}>
        {pickUp.userLatitude && pickUp.userLongitude && (
          <Markers.UserPickUpMarker
            latitude={pickUp.userLatitude}
            longitude={pickUp.userLongitude}
          />
        )}
        {dropOff?.userLatitude &&
          dropOff.userLongitude &&
          isRentalDropOffUserGeolocationValid && (
            <Markers.UserDropOffMarker
              latitude={dropOff.userLatitude}
              longitude={dropOff.userLongitude}
            />
          )}
        {checkOutFailures.map((failure, index) => (
          <Markers.CheckOutFailureMarker
            key={failure.userLatitude + failure.userLongitude + index}
            latitude={failure.userLatitude}
            longitude={failure.userLongitude}
          />
        ))}
        {!dropOff && (
          <Markers.VehicleCurrentPositionMarker vehicleId={vehicle.vehicleId} />
        )}
      </CommonRentalMap>
    </Card>
  );
};
