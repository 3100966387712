import { Routes, Route } from 'react-router-dom';

import { AlarmsFiltersForm } from './AlarmsList/AlarmsFiltersForm';
import { AlarmsList } from './AlarmsList/AlarmsList';

export const Alarms = () => (
  <Routes>
    <Route
      path="/*"
      element={
        <AlarmsFiltersForm>
          <AlarmsList />
        </AlarmsFiltersForm>
      }
    />
  </Routes>
);
