import { BadgePlaceholder, Card, Placeholder } from '@cooltra/ui';

import { VehicleRentalRowLayout } from '../VehicleRentalRowLayout';

export const VehicleRentalCardLoading = () => (
  <Card className="py-5 flex items-center">
    <VehicleRentalRowLayout
      pickUp={
        <div className="flex flex-col gap-3 py-0.5">
          <Placeholder className="w-20 h-2" />
          <Placeholder className="w-28 h-2" />
        </div>
      }
      dropOff={
        <div className="flex flex-col gap-3">
          <Placeholder className="w-20 h-2" />
          <Placeholder className="w-28 h-2" />
        </div>
      }
      duration={<Placeholder className="w-16 h-2" />}
      user={<Placeholder className="w-32 h-2" />}
      system={<Placeholder className="w-16 h-2" />}
      incidents={<Placeholder className="w-14 h-2 mx-auto" />}
      state={<BadgePlaceholder className="w-20 mx-auto" />}
    />
  </Card>
);
