import { FormattedMessage } from 'react-intl';
import { FormInputField } from '@cooltra/form';

import { OptionalFieldLabel } from '~/common';

import messages from './messages';

export const TelematicsId = () => (
  <FormInputField
    name="telematicsId"
    id="telematics-id"
    label={
      <OptionalFieldLabel htmlFor="telematics-id">
        <FormattedMessage {...messages.telematicsId} />
      </OptionalFieldLabel>
    }
  />
);
