import { useRef, useState, RefObject } from 'react';

type AccordionHeaderProps = {
  'aria-controls': string;
  'aria-expanded': boolean;
  id: string;
  key: string;
  onClick: () => void;
};

type GetAccordionHeaderProps = (
  id: string,
  index: number
) => AccordionHeaderProps;

type AccordionSectionStyles = {
  overflow: string;
  transition: string;
  height: string;
};

type AccordionSectionProps = {
  'aria-hidden': boolean;
  'aria-labelledby': string;
  id: string;
  key: string;
  ref: (node: HTMLDivElement) => void;
  role: string;
  style: AccordionSectionStyles;
};

type GetAccordionSectionProps = (
  id: string,
  index: number
) => AccordionSectionProps;

type IsActiveAccordionSection = (index: number) => boolean;

type ActiveSections = number[];

export const useAccordion = (initialActiveSections: ActiveSections = []) => {
  const sectionsRefs = useRef<RefObject<HTMLDivElement>['current'][]>(
    []
  ).current;

  const getSectionRef = (index: number) => (node: HTMLDivElement) => {
    if (node === null) {
      sectionsRefs.splice(index, 1);
    } else {
      sectionsRefs[index] = node;
    }
  };

  const [activeSections, updateActiveSections] = useState<ActiveSections>(
    initialActiveSections
  );
  const isActiveSection: IsActiveAccordionSection = (index) =>
    activeSections.includes(index);

  const getSectionStyle = (index: number) => {
    const sectionRef = sectionsRefs[index];
    const baseStyle = {
      overflow: 'hidden',
      transition: 'all 200ms ease-in-out',
    };
    if (!sectionRef || !isActiveSection(index)) {
      return { ...baseStyle, height: '0px', opacity: 0 };
    }
    return { ...baseStyle, height: `${sectionRef.clientHeight}px`, opacity: 1 };
  };

  const toggleAccordionSection = (index: number) => {
    updateActiveSections((prevSections) =>
      prevSections.includes(index)
        ? prevSections.filter((i) => i !== index)
        : prevSections.concat(index)
    );
  };

  const getOnClick = (index: number) => () => toggleAccordionSection(index);

  const getLinkingId = (id: string) => `${id}-section`;

  const getHeaderProps: GetAccordionHeaderProps = (id, index) => ({
    'aria-controls': getLinkingId(id),
    'aria-expanded': isActiveSection(index),
    id,
    key: id,
    onClick: getOnClick(index),
  });

  const getSectionProps: GetAccordionSectionProps = (id, index) => ({
    'aria-hidden': !isActiveSection(index),
    'aria-labelledby': id,
    id: getLinkingId(id),
    key: getLinkingId(id),
    ref: getSectionRef(index),
    role: 'region',
    style: getSectionStyle(index),
  });

  return {
    getHeaderProps,
    getSectionProps,
    isActiveSection,
  };
};
