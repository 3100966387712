import { defineMessages } from 'react-intl';

export default defineMessages({
  payments: {
    defaultMessage: 'Payments',
  },
  save: {
    defaultMessage: 'Save to Etendo',
  },
  modalTitle: {
    defaultMessage: 'Save contract to Etendo',
  },
  modalContent: {
    defaultMessage:
      'Are you sure you want to save this contract to Etendo? You cannot modify it later here',
  },
  success: {
    defaultMessage: 'You have successfully send contract to Etendo',
  },
});
