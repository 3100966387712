import { defineMessages } from 'react-intl';

export default defineMessages({
  accessories: {
    defaultMessage: 'Accessories',
  },
  thereAreNoAccessories: {
    defaultMessage: 'There are no accessories to display',
  },
});
