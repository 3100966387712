import { defineMessages } from 'react-intl';
import { UserDiscountState } from '@cooltra/api';

export default defineMessages({
  discountOf: {
    defaultMessage: '{discountPercentage}% discount',
  },
});

export const statusMessages = defineMessages<UserDiscountState>({
  ACTIVE: {
    defaultMessage: 'Active',
  },
  SCHEDULED: {
    defaultMessage: 'Scheduled',
  },
});
