import { CopyButton } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

import messages from './messages';

export type SidebarItemProps = HTMLAttributes<HTMLDivElement> & {
  value: ReactNode;
  label?: ReactNode;
  copyValue?: string;
};

export const SidebarItem = ({
  value,
  label,
  copyValue,
  className,
  ...rest
}: SidebarItemProps) => (
  <div className={classNames('flex', 'items-center', className)} {...rest}>
    <span className="text-neutral-500 text-xs inline-block truncate max-w-full pt-1">
      {label || <FormattedMessage {...messages.label} />}
      <span> {value}</span>
    </span>
    {copyValue && <CopyButton className="text-neutral-700" value={copyValue} />}
  </div>
);
