import { RentalState, RentalsSort } from '@cooltra/api';
import { System } from '@cooltra/auth-api';

import { threeDaysAgo } from '~/utils/date';

export type RentalsFiltersFormValues = {
  system: System[];
  state: RentalState[];
  minDate: string;
  maxDate: string;
  sort: RentalsSort;
};

export const initialRentalsFiltersFormValues: RentalsFiltersFormValues = {
  system: [],
  state: ['ACTIVE'],
  minDate: threeDaysAgo,
  maxDate: '',
  sort: 'pickUp.desc',
};
