export type VehicleCategory =
  | 'EBIKE'
  | 'STANDARD'
  | 'PLUS'
  | 'PREMIUM'
  | 'SUPER_PREMIUM';

type PricesProductCategoryPriceValue = `prices.${VehicleCategory}.price.value`;

export type DepositsFormValues = Record<
  PricesProductCategoryPriceValue,
  string
>;

export const depositsInitialFormValues: DepositsFormValues = {
  'prices.EBIKE.price.value': '',
  'prices.PREMIUM.price.value': '',
  'prices.STANDARD.price.value': '',
  'prices.PLUS.price.value': '',
  'prices.SUPER_PREMIUM.price.value': '',
};
