import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'No incidents found',
  },
  content: {
    defaultMessage: 'This user does not have any incidents yet',
  },
});
