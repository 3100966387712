import { defineMessages } from 'react-intl';

export default defineMessages({
  parkingReview: {
    defaultMessage: 'Parking review',
  },
  placeholder: {
    defaultMessage: 'All',
  },
});
