import { useFormContext } from '@cooltra/form';

import { CooltraForEmployeesFormValues } from '~/common';

import { CodesToCSV } from '../../../CodesToCSV/CodesToCSV';

export const CodesToCSVLink = () => {
  const {
    values: { codes },
  } = useFormContext<CooltraForEmployeesFormValues>();

  return <CodesToCSV codes={codes} />;
};
