import { useUserSessionsQuery } from '@cooltra/api';
import { Card, Placeholder } from '@cooltra/ui';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { useParams } from 'react-router-dom';

import { NotAvailable, SessionDevice, SessionRevokedBadge } from '~/common';

import messages from './messages';

export const LastSession = () => {
  const { id = '' } = useParams<'id'>();

  const { data: sessions, isLoading, isError } = useUserSessionsQuery(id);

  const lastSession = sessions?.[0];

  const getContent = () => {
    if (isLoading) {
      return (
        <>
          <Placeholder className="h-2 w-36 mt-6 mb-6" />
          <Placeholder className="h-2 w-48" />
        </>
      );
    }

    if (isError || !lastSession) {
      return (
        <NotAvailable className="text-neutral-800 font-semibold text-3xl" />
      );
    }

    if (lastSession) {
      return (
        <div>
          <div className="flex items-center mb-1">
            <span className="text-lg">
              <span className="text-neutral-800 font-semibold">
                <FormattedDate
                  value={new Date(lastSession.date)}
                  day="2-digit"
                  month="short"
                  year="numeric"
                />
              </span>
              <span> - </span>
              <span className="text-neutral-600">
                <FormattedTime
                  value={new Date(lastSession.date)}
                  hour="2-digit"
                  hourCycle="h23"
                  minute="2-digit"
                />
              </span>
            </span>
          </div>
          <div className="flex items-center gap-8 justify-between">
            <SessionDevice
              className="text-base"
              classNameValue="text-xs mt-2"
              session={lastSession}
            />
            {lastSession.expired && <SessionRevokedBadge />}
          </div>
        </div>
      );
    }

    return <></>;
  };

  return (
    <Card className="px-6 pt-6 pb-4 h-full">
      <div data-testid="LAST_SESSION">
        <span className="block text-neutral-400 text-xs mb-3">
          <FormattedMessage {...messages.lastSession} />
        </span>
        {getContent()}
      </div>
    </Card>
  );
};
