import { FormattedMessage } from 'react-intl';
import { Button, Card } from '@cooltra/ui';
import {
  Contract,
  ContractAvailableAccessory,
  ContractPayment,
  useContractQuery,
  usePatchContractAccessoriesMutation,
} from '@cooltra/station-based-api';
import { accessoryImages } from '@cooltra/utils';
import { useToggle } from '@cooltra/hooks';
import { useEffect } from 'react';

import { useAccessoryName, useContractFlags, useNotification } from '~/hooks';
import { Amount } from '~/common';

import { Added } from '../Added/Added';
import { AccessoryInfoModal } from '../AccessoryInfoModal/AccessoryInfoModal';
import { SecondDriverWarningModal } from '../SecondDriverWarningModal/SecondDriverWarningModal';

import { WarningTooltip } from './WarningTooltip';
import messages from './messages';

export type AccessoryTypeCardProps = ContractAvailableAccessory & {
  contract: Contract;
  payments: ContractPayment[];
  quantity?: number;
  withInfoModal?: boolean;
  info?: string;
};

export const AccessoryTypeCard = ({
  contract,
  payments,
  accessoryId,
  quantity = 0,
  name,
  price,
  limit,
  withInfoModal = false,
  info,
}: AccessoryTypeCardProps) => {
  const getAccessoryName = useAccessoryName();
  const [isModalOpen, { toggleOn: openModal, toggleOff: closeModal }] =
    useToggle();
  const [isAdding, { toggleOn: startAdding, toggleOff: endAdding }] =
    useToggle();
  const [isRemoving, { toggleOn: startRemoving, toggleOff: endRemoving }] =
    useToggle();
  const [
    isConfirmModalOpen,
    { toggleOn: openConfirmModal, toggleOff: closeConfirmModal },
  ] = useToggle();
  const { isSignedOrPendingSignature } = useContractFlags();

  const { mutateAsync, isPending } = usePatchContractAccessoriesMutation(
    contract.contractId
  );
  const { isRefetching } = useContractQuery(contract.contractId, {
    enabled: false,
  });

  const { addErrorNotification } = useNotification();

  const removeOne = () => {
    startRemoving();
    mutateAsync({ accessoryId, quantity: quantity - 1 }).catch(() =>
      addErrorNotification()
    );
  };

  const handleRemoveOne = () => {
    if (name === 'CONDUCTOR_ADICIONAL') {
      return openConfirmModal();
    }
    removeOne();
  };

  useEffect(() => {
    if (!isPending && !isRefetching) {
      endAdding();
      endRemoving();
    }
  }, [endAdding, endRemoving, isAdding, isPending, isRefetching, isRemoving]);

  const addOne = () => {
    startAdding();
    mutateAsync({ accessoryId, quantity: quantity + 1 }).catch(() =>
      addErrorNotification()
    );
  };

  const getIsAccessoryPaidFor = () =>
    payments
      .filter(({ status }) => status === 'PAID')
      .some(({ accessories }) =>
        accessories?.some(({ id }) => id === accessoryId)
      );

  const getIsAcessoryAwaitingPayment = () =>
    payments
      .filter(({ status }) => status === 'AWAITING_PAYMENT')
      .some(({ accessories }) =>
        accessories?.some(({ id }) => id === accessoryId)
      );

  const getCanEditAccessory = () => {
    if (isSignedOrPendingSignature) {
      return false;
    }

    if (contract.status === 'ACTIVE') {
      return false;
    }

    const contractIncludesAccessory = !!contract.accessories?.find(
      ({ id }) => id === accessoryId
    );

    if (!contractIncludesAccessory) {
      return true;
    }

    const isAccessoryAwaitingPayment = getIsAcessoryAwaitingPayment();

    if (isAccessoryAwaitingPayment) {
      return false;
    }

    const isAccessoryPaidFor = getIsAccessoryPaidFor();

    return !isAccessoryPaidFor;
  };

  const canEditAccessory = getCanEditAccessory();
  const isAccessoryPaidFor = getIsAccessoryPaidFor();
  const isAccessoryAwaitingPayment = getIsAcessoryAwaitingPayment();

  return (
    <>
      <SecondDriverWarningModal
        isOpen={isConfirmModalOpen}
        onConfirm={removeOne}
        onRequestClose={closeConfirmModal}
        isPending={isRemoving}
      />
      <Card data-testid={`ACCESSORY_${name}`} className="bg-neutral-0 p-8">
        <div>
          <h2 className="mb-4 text-lg font-semibold text-neutral-800">
            {getAccessoryName(name)}
          </h2>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img className="w-24 mr-6" src={accessoryImages[name]} alt={name} />
            <div className="flex flex-col">
              <span className="mb-2 text-3xl font-semibold text-neutral-800">
                <Amount {...price.total} />
              </span>
              {info ? (
                <span className="block text-neutral-500 text-sm">{info}</span>
              ) : (
                <span className="text-base font-medium text-neutral-600">
                  <FormattedMessage
                    {...messages.dailyPrice}
                    values={{
                      amount: <Amount {...price.daily} />,
                    }}
                  />
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end h-11">
          {limit === 1 ? (
            quantity === 1 ? (
              <div className="flex w-full justify-between gap-2 items-center">
                <div className="flex items-center gap-2">
                  <Button
                    variant="danger"
                    emphasis="low"
                    onClick={handleRemoveOne}
                    disabled={!canEditAccessory}
                    loading={isRemoving}
                  >
                    <FormattedMessage {...messages.remove} />
                  </Button>
                  <WarningTooltip
                    isAccessoryPaidFor={isAccessoryPaidFor}
                    isAccessoryAwaitingPayment={isAccessoryAwaitingPayment}
                    isContractStatusActive={contract.status === 'ACTIVE'}
                  />
                </div>
                <Added />
              </div>
            ) : (
              <>
                <Button
                  disabled={!canEditAccessory}
                  loading={!withInfoModal && isAdding}
                  onClick={withInfoModal ? openModal : addOne}
                >
                  <FormattedMessage {...messages.add} />
                </Button>
                {withInfoModal && (
                  <AccessoryInfoModal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contractId={contract.contractId}
                    accessoryId={accessoryId}
                  />
                )}
              </>
            )
          ) : (
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-2">
                <Button
                  disabled={quantity === 0 || !canEditAccessory}
                  onClick={handleRemoveOne}
                  loading={isRemoving}
                >
                  -
                </Button>
                <span
                  data-testid="ACCESSORY_COUNTER"
                  className="w-8 text-center"
                >
                  {quantity}
                </span>
                <Button
                  disabled={quantity >= limit || !canEditAccessory}
                  onClick={addOne}
                  loading={isAdding}
                >
                  +
                </Button>
              </div>
              <WarningTooltip
                isAccessoryPaidFor={isAccessoryPaidFor}
                isAccessoryAwaitingPayment={isAccessoryAwaitingPayment}
                isContractStatusActive={contract.status === 'ACTIVE'}
              />
            </div>
          )}
        </div>
      </Card>
    </>
  );
};
