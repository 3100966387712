import { useIntl } from 'react-intl';
import { Button, Icon } from '@cooltra/ui';
import { MdBattery4Bar } from 'react-icons/md';

import { MapView } from '~/hooks';

import messages from './messages';

export type OpsMapSwitchViewProps = {
  mapView: MapView;
  updateMapView: (mapView: MapView) => void;
};

export const OpsMapSwitchView = ({
  mapView,
  updateMapView,
}: OpsMapSwitchViewProps) => {
  const { formatMessage } = useIntl();
  const isBatteryView = mapView === 'by-battery';

  const handleOnSwitchView = () => {
    if (isBatteryView) {
      updateMapView('by-status');
    } else {
      updateMapView('by-battery');
    }
  };

  return (
    <div className="bg-neutral-0 shadow rounded-md">
      <Button
        className="bg-neutral-0 rounded-md"
        onClick={handleOnSwitchView}
        aria-label={
          isBatteryView
            ? formatMessage(messages.byStatus)
            : formatMessage(messages.byBattery)
        }
        square
      >
        <Icon className="text-xl">
          {isBatteryView ? (
            <MdBattery4Bar className="text-primary-400" />
          ) : (
            <MdBattery4Bar className="text-neutral-500" />
          )}
        </Icon>
      </Button>
    </div>
  );
};
