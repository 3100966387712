import { InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSelectField } from '@cooltra/form';

import { operatorGroups } from '~/utils/operators';

import messages from './messages';

const groups = [operatorGroups.MARKETING, operatorGroups.RETENTION];

export const OperatorGroupId = () => {
  const { formatMessage } = useIntl();
  return (
    <FormSelectField
      name="operatorGroupId"
      id="operator-group-id"
      isClearable
      placeholder={formatMessage(messages.placeholder)}
      label={
        <InputLabel htmlFor="operator-group-id">
          <FormattedMessage {...messages.label} />
        </InputLabel>
      }
      options={groups.map((group) => ({
        value: group.operatorGroupId,
        label: group.name,
      }))}
    />
  );
};
