import { defineMessages } from 'react-intl';

export default defineMessages({
  vehicle: {
    defaultMessage: 'Vehicle',
  },
  alarm: {
    defaultMessage: 'Alarm',
  },
  rental: {
    defaultMessage: 'Rental',
  },
  homeSystem: {
    defaultMessage: 'HS',
  },
  startedAt: {
    defaultMessage: 'Started at',
  },
});
