import { Placeholder } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type RebalanceTakesProps = {
  rebalanceTakes: number;
};

export const RebalanceTakes = ({ rebalanceTakes }: RebalanceTakesProps) => {
  return (
    <div className="flex flex-col">
      {rebalanceTakes !== undefined ? (
        <span className="text-md text-neutral-1000 font-semibold">
          {rebalanceTakes}
        </span>
      ) : (
        <Placeholder className="w-6 h-5 mx-auto mb-1" />
      )}
      <span className="text-xs text-neutral-300">
        <FormattedMessage {...messages.rebalanceTakes} />
      </span>
    </div>
  );
};
