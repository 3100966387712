import { FormattedMessage } from 'react-intl';
import { Badge, Divider } from '@cooltra/ui';
import { UserDiscount } from '@cooltra/api';
import { capitalize } from '@cooltra/utils';

import * as DiscountFields from '../DiscountFields';

import messages, { statusMessages } from './messages';

export const Discount = ({
  title,
  description,
  validUntil,
  validFrom,
  discountPercentage,
  state,
  rentalsCap,
  rentalsUsed,
  targetVehicleModels,
  discountUsed,
  discountCap,
  discountCapPerRental,
}: UserDiscount) => (
  <>
    <div className="mb-3">
      <Badge variant={state === 'ACTIVE' ? 'success' : 'primary'}>
        <FormattedMessage {...statusMessages[state]} />
      </Badge>
    </div>
    <div className="pb-6">
      <h4 className="text-xl mb-2">{capitalize(title)}</h4>
      <p className="text-base font-semibold text-neutral-700">
        <FormattedMessage
          {...messages.discountOf}
          values={{ discountPercentage }}
        />
      </p>
      {description && <p className="text-xs text-neutral-400">{description}</p>}
    </div>
    <div className="flex flex-col flex-1">
      <div className="flex flex-col gap-1 flex-1">
        <div className="flex gap-1 items-center text-xs">
          <DiscountFields.DiscountValidity
            state={state}
            validFrom={validFrom}
            validUntil={validUntil}
          />
        </div>
        {rentalsCap && (
          <div className="items-center text-xs">
            <DiscountFields.RentalCap
              rentalsCap={rentalsCap}
              rentalsUsed={rentalsUsed}
            />
          </div>
        )}
        {targetVehicleModels.length > 0 && (
          <div className="items-center text-xs">
            <DiscountFields.TargetVehicleModels
              targetVehicleModels={targetVehicleModels}
            />
          </div>
        )}
        {discountCap && discountUsed && (
          <div className="items-center text-xs">
            <DiscountFields.DiscountCap
              discountCap={discountCap}
              discountUsed={discountUsed}
            />
          </div>
        )}
        {discountCapPerRental && (
          <div className="items-center text-xs">
            <DiscountFields.DiscountCapPerRental
              discountCapPerRental={discountCapPerRental}
            />
          </div>
        )}
      </div>
      <Divider />
    </div>
  </>
);
