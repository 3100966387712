import { FormattedMessage } from 'react-intl';
import { RentalInvoiceItem } from '@cooltra/api';
import { shortenId } from '@cooltra/utils';
import { RouterLink } from '@cooltra/navigation';

import {
  Amount,
  DateTime,
  RefundInvoiceItem,
  CancelInvoiceItem,
  CanceledItemLabel,
  RefundedItemLabel,
} from '~/common';

import { InvoiceItemRowLayout } from '../InvoiceItemRowLayout';
import messages from '../messages';

export const RentalItem = ({
  id,
  chargedAt,
  chargeState,
  rentalId,
  total,
  price,
  type,
  isStateColumnDisplayed,
  refundable,
  cancelable,
}: RentalInvoiceItem & {
  isStateColumnDisplayed: boolean;
}) => (
  <InvoiceItemRowLayout
    className="text-sm text-neutral-800 py-4"
    chargedAt={<DateTime date={chargedAt} />}
    item={
      <FormattedMessage
        tagName="span"
        {...messages.rental}
        values={{
          rentalId: (
            <RouterLink to={`/rentals/${rentalId}`}>{`#${shortenId(
              rentalId
            )}`}</RouterLink>
          ),
        }}
      />
    }
    total={<Amount className="block text-right" {...total} />}
    price={<Amount className="block text-right" {...price} />}
    state={
      isStateColumnDisplayed && (
        <>
          {chargeState === 'REFUNDED' ? (
            <RefundedItemLabel />
          ) : refundable ? (
            <RefundInvoiceItem id={id} type={type} />
          ) : null}
          {chargeState === 'CANCELLED' ? (
            <CanceledItemLabel />
          ) : cancelable ? (
            <CancelInvoiceItem id={id} type={type} />
          ) : null}
        </>
      )
    }
  />
);
