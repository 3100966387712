import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'No incidents found',
  },
  content: {
    defaultMessage: 'This rental does not have any incidents',
  },
});
