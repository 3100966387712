import { AlarmType } from '@cooltra/api';
import { RouterBaseLink, RouterBaseLinkProps } from '@cooltra/navigation';
import { Placeholder } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';

import messages, { alarmTypes } from './messages';

export type AlarmTypeFilterLink = RouterBaseLinkProps & {
  isLoading: boolean;
  isActive: boolean;
  type?: AlarmType;
  count: number;
};

export const AlarmTypeFilterLink = ({
  isActive,
  isLoading,
  type,
  count,
  ...rest
}: AlarmTypeFilterLink) => (
  <RouterBaseLink
    data-testid={`${type}_ALARM_FILTER`}
    className={classNames(
      'shrink-0 flex items-center justify-center w-32 h-24 bg-neutral-0 shadow-sm hover:shadow-md active:shadow-md transition-all rounded-lg',
      isActive && 'ring ring-inset ring-primary-500'
    )}
    {...rest}
  >
    <div className="flex flex-col items-center gap-3 pt-2">
      {isLoading ? (
        <div className="h-9 flex items-center">
          <Placeholder className="h-2 w-10" />
        </div>
      ) : (
        <span className="text-neutral-900 font-semibold text-3xl">{count}</span>
      )}
      <span
        className={classNames(
          'text-xs',
          isActive
            ? 'text-primary-700 font-semibold'
            : 'text-neutral-500 font-normal'
        )}
      >
        {type ? (
          <FormattedMessage {...alarmTypes[type]} />
        ) : (
          <FormattedMessage {...messages.all} />
        )}
      </span>
    </div>
  </RouterBaseLink>
);
