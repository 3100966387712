import { CopyButton } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';

export type SidebarLicensePlateItemProps = HTMLAttributes<HTMLDivElement> & {
  licensePlate: string;
};

export const SidebarLicensePlateItem = ({
  licensePlate,
  className,
  ...rest
}: SidebarLicensePlateItemProps) => (
  <div className={classNames('flex', 'items-center', className)} {...rest}>
    <span className="text-neutral-500 text-xs inline-block truncate max-w-full pt-1">
      {licensePlate}
    </span>
    <CopyButton className="text-neutral-700" value={licensePlate} />
  </div>
);
