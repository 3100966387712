import { useFormContext } from '@cooltra/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Legend } from '@cooltra/ui';

import { PromotionFormValues } from '../../PromotionForm';
import { PromotionStateType } from '../../PromotionState/PromotionState';

import messages from './messages';
import { FormDateInputDateAndTimeField } from './FormDateInputDateAndTimeField';

export const RangeDateField = () => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext<PromotionFormValues>();
  const { editMode, stateType } = values;
  const editingExpiredPromotion =
    editMode && stateType === PromotionStateType.EXPIRED;

  return (
    <>
      <div className="flex flex-col gap-x-2">
        <Legend className="mb-1">
          <FormattedMessage {...messages.consumableFrom} />
        </Legend>
        <div
          className="flex items-center gap-2"
          data-testid="RANGE_FROM_DATE_FIELD"
        >
          <FormDateInputDateAndTimeField
            disabled={
              editingExpiredPromotion || values.promotionTypeMode !== 'discount'
            }
            names={{
              date: 'benefitFromDate',
              time: 'benefitFromTime',
            }}
            ariaLabels={{
              date: formatMessage(messages.fromDate),
              time: formatMessage(messages.fromTime),
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-x-2">
        <Legend className="mb-1">
          <FormattedMessage {...messages.consumableUntil} />
        </Legend>
        <div
          className="flex items-center gap-2"
          data-testid="RANGE_UNTIL_DATE_FIELD"
        >
          <FormDateInputDateAndTimeField
            disabled={values.benefitDoesntExpire || editingExpiredPromotion}
            names={{
              date: 'benefitUntilDate',
              time: 'benefitUntilTime',
            }}
            ariaLabels={{
              date: formatMessage(messages.untilDate),
              time: formatMessage(messages.untilTime),
            }}
          />
        </div>
      </div>
    </>
  );
};
