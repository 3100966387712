import { defineMessages } from 'react-intl';

export default defineMessages({
  vehiclesAvailable: {
    defaultMessage: 'Operational vehicles',
  },
  activeRentals: {
    defaultMessage: 'Active rentals',
  },
  activeReservations: {
    defaultMessage: 'Active reservations',
  },
  rentalsToday: {
    defaultMessage: 'Rentals today',
  },
  activeVehicleBonus: {
    defaultMessage: 'Active bonus vehicles',
  },
});
