import { stationBasedLanguages } from '@cooltra/countries';
import { Divider } from '@cooltra/ui';
import { getKeys } from '@cooltra/utils';
import { useFormContext } from '@cooltra/form';

import { useAccordion } from '~/hooks';

import { StationBasedStateType } from '../StationBasedState/StationBasedState';
import { StationBasedFormValues } from '../StationBasedForm';

import { TranslationSectionHeader } from './TranslationSectionHeader';
import { TranslationsSection } from './TranslationSection';

export const StationBasedTranslations = () => {
  const { getHeaderProps, getSectionProps, isActiveSection } = useAccordion([
    0,
  ]);
  const { values } = useFormContext<StationBasedFormValues>();
  const { editMode, stateType } = values;
  const disabled = editMode && stateType === StationBasedStateType.EXPIRED;

  return (
    <div>
      {getKeys(stationBasedLanguages)
        .sort((language) => (language === 'es' ? -1 : 1))
        .map((language, index) => {
          const id = `${language}-accordion-item`;
          const { onClick, ...headerPropsRest } = getHeaderProps(id, index);
          const { ref: sectionRef, ...sectionPropsRest } = getSectionProps(
            id,
            index
          );
          const { key: headerKey, ...headerProps } = headerPropsRest;
          const { key: sectionKey, ...sectionProps } = sectionPropsRest;

          return (
            <div key={id}>
              <Divider />
              <TranslationSectionHeader
                key={headerKey}
                isActive={isActiveSection(index)}
                language={language}
                onClick={onClick}
                {...headerProps}
              />
              <TranslationsSection
                key={sectionKey}
                language={language}
                ref={sectionRef}
                disabled={disabled}
                {...sectionProps}
              />
            </div>
          );
        })}
    </div>
  );
};
