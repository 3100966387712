export type ParserUserAgentResult = {
  os: string | undefined;
  parsedValue: string;
};

const getOsFromOsInfo = (osInfo: string) => {
  if (osInfo.toUpperCase().includes('ANDROID')) {
    return 'android';
  }

  if (osInfo.toUpperCase().includes('IOS')) {
    return 'ios';
  }

  return undefined;
};

export const parseUserAgent = (userAgent: string): ParserUserAgentResult => {
  const [appInfo, deviceInfo] = userAgent.replace(')', '').split(' (');
  const version = appInfo.split('/')[1];
  const [, buildInfo, osInfo, device] = deviceInfo.split('; ');
  const buildNumber = buildInfo.split(':')[1];
  const os = getOsFromOsInfo(osInfo);

  return {
    os,
    parsedValue: `${osInfo}, v${version} (${buildNumber}), ${device}`,
  };
};
