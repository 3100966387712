import { usePricingDepositsPricesQuery } from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';

import { ErrorPage, Four0Three } from '~/common';

import { Deposits } from './Deposits';

export const DepositsPriceList = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { data, isLoading, isError, error } = usePricingDepositsPricesQuery({
    enabled: hasPermission('read:prices'),
  });

  if (!hasPermission('read:prices')) {
    return <Four0Three />;
  }

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  const depositsPrice =
    data === undefined
      ? []
      : data.sort((a, b) => a.insuranceName.localeCompare(b.insuranceName));

  return (
    <div className="container min-w-5xl max-w-6xl py-12">
      <Deposits depositsPrice={depositsPrice} isLoading={isLoading} />
    </div>
  );
};
