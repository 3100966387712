import { Button } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';
import { useDeleteVehicleTechnicalInspectionMutation } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { useToggle } from '@cooltra/hooks';

import { useNotification } from '~/hooks';
import { DeleteModal } from '~/common';

import messages from './messages';

type DeleteTechnicalInspectionProps = {
  vehicleId: string;
};

export const DeleteTechnicalInspection = ({
  vehicleId,
}: DeleteTechnicalInspectionProps) => {
  const { formatMessage } = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const { technicalInspectionId = '' } = useParams<'technicalInspectionId'>();
  const navigate = useNavigate();

  const { hasPermission } = useAuthClaimsQuery();

  const { mutateAsync, isPending } =
    useDeleteVehicleTechnicalInspectionMutation(
      vehicleId,
      technicalInspectionId
    );
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleConfirm = () =>
    mutateAsync()
      .then(() => {
        toggleOff();
        addSuccessNotification(formatMessage(messages.success));
        navigate(`/vehicles/${vehicleId}/maintenance/technical-inspections`);
      })
      .catch(() => {
        toggleOff();
        addErrorNotification();
      });

  return (
    <div className="flex gap-4">
      {hasPermission('delete:services') && (
        <>
          <DeleteModal
            onClose={toggleOff}
            onConfirm={handleConfirm}
            isOpen={isOpen}
            isDeleting={isPending}
          />
          <Button variant="danger" emphasis="low" onClick={toggleOn}>
            <FormattedMessage {...messages.deleteTechnicalInspection} />
          </Button>
        </>
      )}
    </div>
  );
};
