import { defineMessages } from 'react-intl';

export default defineMessages({
  sharedWithCityHall: {
    defaultMessage: 'Shared with the City Hall',
  },
  battery: {
    defaultMessage: 'Battery',
  },
  mileage: {
    defaultMessage: 'Mileage',
  },
});
