import { BadgePlaceholder, Card, Placeholder } from '@cooltra/ui';

import { InvoiceRowLayout } from '../InvoiceRowLayout';

export const InvoiceCardLoading = () => (
  <Card className="py-5">
    <InvoiceRowLayout
      issuedAt={<Placeholder className="h-2 w-24" />}
      type={<Placeholder className="h-2 w-20" />}
      invoiceNumber={<Placeholder className="h-2 w-24" />}
      legalEntity={<Placeholder className="h-2 w-40" />}
      total={
        <div className="flex justify-end">
          <Placeholder className="h-2 w-12" />
        </div>
      }
      state={
        <div className="flex justify-center">
          <BadgePlaceholder className="w-20" />
        </div>
      }
      invoicePdf={<BadgePlaceholder className="w-16" />}
    />
  </Card>
);
