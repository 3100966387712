import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { PriceListsByPricingGroupRowLayout } from '../PriceListsByPricingGroupRowLayout';

import messages from './messages';

export const PriceListsByPricingGroupHeader = () => (
  <PriceListsByPricingGroupRowLayout
    appliesIn={
      <ListColumnLabel>
        <FormattedMessage {...messages.appliesIn} />
      </ListColumnLabel>
    }
    eBike={
      <ListColumnLabel>
        <FormattedMessage {...messages.eBike} />
      </ListColumnLabel>
    }
    standard={
      <ListColumnLabel>
        <FormattedMessage {...messages.standard} />
      </ListColumnLabel>
    }
    plus={
      <ListColumnLabel>
        <FormattedMessage {...messages.plus} />
      </ListColumnLabel>
    }
    premium={
      <ListColumnLabel>
        <FormattedMessage {...messages.premium} />
      </ListColumnLabel>
    }
    superPremium={
      <ListColumnLabel>
        <FormattedMessage {...messages.superPremium} />
      </ListColumnLabel>
    }
    exportPrices={<div />}
  />
);
