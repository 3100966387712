import { useIntl } from 'react-intl';
import { MdOutlineSentimentDissatisfied } from 'react-icons/md';

import { ErrorPage } from '../ErrorPage';

import messages from './messages';

export const Four0Four = () => {
  const { formatMessage } = useIntl();
  return (
    <ErrorPage
      icon={<MdOutlineSentimentDissatisfied />}
      title={formatMessage(messages.title)}
      error={formatMessage(messages.content)}
    />
  );
};
