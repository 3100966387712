import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import {
  getStationBasedLanguageOptions,
  StationBasedLanguageCode,
} from '@cooltra/countries';
import { FormSelectField } from '@cooltra/form';

import messages from './messages';

export type LanguageProps = {
  skip?: StationBasedLanguageCode[];
};

export const Language = ({ skip = [] }: LanguageProps) => (
  <FormSelectField
    name="language"
    id="language"
    label={
      <InputLabel htmlFor="language">
        <FormattedMessage {...messages.language} />
      </InputLabel>
    }
    options={getStationBasedLanguageOptions(skip)}
  />
);
