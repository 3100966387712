import { useFormContext } from '@cooltra/form';

import { ReviewFormValues } from '../review-form-types';

export const useHandleSubmit = (request: () => void) => {
  const { isValid, touchAll } = useFormContext<ReviewFormValues>();

  const handleClick = () => {
    if (!isValid) {
      touchAll();
    } else {
      request();
    }
  };

  return handleClick;
};
