import { RentalParkingReview } from '@cooltra/api';
import { defineMessages } from 'react-intl';

export default defineMessages({
  howIsVehicleParked: {
    defaultMessage: 'How is this vehicle parked?',
  },
  shouldAddBonus: {
    defaultMessage: 'Currently {totalAndLimit} vehicle bonus. Add bonus?',
  },
  dontAddBonus: {
    defaultMessage: "Don't add",
  },
  addBonus: {
    defaultMessage: 'Add bonus',
  },
  limitReached: {
    defaultMessage:
      'Max number of bonus vehicles is reached for this home system',
  },
  permanentBonus: {
    defaultMessage:
      'This vehicle has a permanent bonus that cannot be disabled',
  },
});

export const rentalParkingReviewMessages = defineMessages<RentalParkingReview>({
  PARKED_INCORRECTLY: {
    defaultMessage: 'Poorly',
  },
  INCORRECT_PHOTO: {
    defaultMessage: 'Bad photo',
  },
  MAYBE_PARKED_INCORRECTLY: {
    defaultMessage: "Can't identify",
  },
  PARKED_CORRECTLY: {
    defaultMessage: 'Well',
  },
});
