import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type DiscountRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  offer: ReactNode;
  code: ReactNode;
  discountPercentage: ReactNode;
  redeemedAt: ReactNode;
  validFrom: ReactNode;
  validUntil: ReactNode;
  state: ReactNode;
};

export const DiscountRowLayout = ({
  offer,
  code,
  discountPercentage,
  redeemedAt,
  validFrom,
  validUntil,
  state,
  className,
  ...rest
}: DiscountRowLayoutProps) => (
  <div
    className={classNames('pl-6 pr-4 flex items-center gap-4', className)}
    {...rest}
  >
    <div className="w-60 shrink-0">{offer}</div>
    <div className="w-24 shrink-0">{code}</div>
    <div className="w-20 shrink-0 text-right pr-6">{discountPercentage}</div>
    <div className="w-36 shrink-0">{redeemedAt}</div>
    <div className="w-36 shrink-0">{validFrom}</div>
    <div className="w-36 shrink-0">{validUntil}</div>
    <div className="w-24 shrink-0 flex justify-center">{state}</div>
  </div>
);
