import { classNames } from '@cooltra/utils';
import { format } from 'date-fns';
import { DayContentProps } from 'react-day-picker';
import { useFormContext } from '@cooltra/form';

import { ContractDetailsFormValues } from '../../contract-details-form';

import { DayCircle } from './DayCircle';

export const DayContent = ({
  activeModifiers: { range_start, range_end, selected, today, disabled },
  date,
}: DayContentProps) => {
  const {
    values: { dropOffDate },
  } = useFormContext<ContractDetailsFormValues>();

  if ((range_start && !dropOffDate) || (range_end && range_start)) {
    return (
      <DayCircle
        data-testid={`CALENDAR_DAY_${format(date, 'yyyy-MM-dd')}`}
        today={!!today}
        active={true}
        disabled={disabled}
      >
        {format(date, 'd')}
      </DayCircle>
    );
  }

  if (range_end || range_start) {
    return (
      <span className="relative flex h-full w-full justify-center">
        {range_start && (
          <span className="absolute right-0 top-0 h-full w-1/2 bg-primary-50" />
        )}
        {range_end && (
          <span className="absolute left-0 top-0 h-full w-1/2 bg-primary-50" />
        )}
        <DayCircle
          data-testid={`CALENDAR_DAY_${format(date, 'yyyy-MM-dd')}`}
          today={!!today}
          active={true}
          disabled={disabled}
        >
          {format(date, 'd')}
        </DayCircle>
      </span>
    );
  }

  return (
    <span
      className={classNames(
        'flex h-full w-full items-center justify-center text-neutral-800',
        selected && 'bg-primary-50'
      )}
    >
      <DayCircle
        data-testid={`CALENDAR_DAY_${format(date, 'yyyy-MM-dd')}`}
        today={!!today}
        active={false}
        disabled={disabled}
      >
        {format(date, 'd')}
      </DayCircle>
    </span>
  );
};
