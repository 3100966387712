import { defineMessages } from 'react-intl';

export default defineMessages({
  idCardFront: {
    defaultMessage: 'Front of the ID card',
  },
  idCardBack: {
    defaultMessage: 'Back of the ID card',
  },
  driverLicenseFront: {
    defaultMessage: 'Front of the driver license',
  },
  driverLicenseBack: {
    defaultMessage: 'Back of the driver license',
  },
});
