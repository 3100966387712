import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type PackRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  code: ReactNode;
  price: ReactNode;
  credit: ReactNode;
  validFrom: ReactNode | null;
  validUntil: ReactNode | null;
  state: ReactNode;
};

export const PackRowLayout = ({
  code,
  price,
  credit,
  validFrom,
  validUntil,
  state,
  className,
  ...rest
}: PackRowLayoutProps) => (
  <div className={classNames('flex h-full w-full', className)} {...rest}>
    <div className="flex items-center w-full px-8 gap-4">
      <div className="flex-1">{code}</div>
      <div className="w-32 flex shrink-0">{price}</div>
      <div className="w-32 flex shrink-0">{credit}</div>
      <div className="w-32 shrink-0">{validFrom}</div>
      <div className="w-32 shrink-0">{validUntil}</div>
      <div className="w-24 flex justify-center shrink-0">{state}</div>
    </div>
  </div>
);
