import { defineMessages } from 'react-intl';

export default defineMessages({
  overview: {
    defaultMessage: 'Overview',
  },
  details: {
    defaultMessage: 'Details',
  },
  driver: {
    defaultMessage: 'Driver',
  },
  vehicle: {
    defaultMessage: 'Vehicle',
  },
  extras: {
    defaultMessage: 'Extras',
  },
  payment: {
    defaultMessage: 'Payments',
  },
  checksDamages: {
    defaultMessage: 'Checks and damages',
  },
});
