import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Placeholder } from '@cooltra/ui';
import ReactMapGl, { Marker } from 'react-map-gl';
import { VehicleParkedNext } from '@cooltra/api';
import { MdLocationOn } from 'react-icons/md';

import { getEnvVariable } from '~/utils/environment';
import { isTestingEnv } from '~/utils/e2e';
import { useReverseGeocode } from '~/hooks';

import { GoogleStreetViewMap } from '../GoogleStreetViewMap';

import messages from './messages';

type MapsView = 'by-street-view' | 'by-mapbox';

export const Maps = ({
  vehicle: { dropOff },
}: Pick<VehicleParkedNext, 'vehicle'>) => {
  const [mapView, setMapView] = useState<MapsView>('by-street-view');
  const [streetViewExist, setStreetViewExist] = useState<boolean>(true);
  const latitude = dropOff?.latitude || 0;
  const longitude = dropOff?.longitude || 0;
  const hasGeolocation = !!longitude && !!latitude;
  const { data: address } = useReverseGeocode(
    { latitude, longitude },
    { enabled: hasGeolocation && !isTestingEnv() }
  );

  const handleOnSelectStreetView = () => {
    setMapView('by-street-view');
  };
  const handleOnSelectMapboxView = () => {
    setMapView('by-mapbox');
  };
  const handleOnStreetViewDoesNotExist = () => {
    setStreetViewExist(false);
    setMapView('by-mapbox');
  };

  const isTest = isTestingEnv();

  if (!dropOff) {
    return <></>;
  }

  if (isTestingEnv()) {
    return <Placeholder className="w-full h-52" />;
  }

  const isStreetView = mapView === 'by-street-view';
  const isMapboxView = mapView === 'by-mapbox';

  return (
    <div className="relative">
      <div className="w-full flex justify-center absolute top-2 z-20">
        <div className="flex gap-2 p-2 bg-neutral-0 rounded-xl shadow">
          <Button
            variant="primary"
            role="switch"
            aria-checked={isStreetView}
            emphasis={isStreetView ? 'high' : 'low'}
            size="sm"
            onClick={handleOnSelectStreetView}
          >
            <FormattedMessage {...messages.streetView} />
          </Button>
          <Button
            variant="primary"
            role="switch"
            aria-checked={isMapboxView}
            emphasis={isMapboxView ? 'high' : 'low'}
            size="sm"
            onClick={handleOnSelectMapboxView}
          >
            <FormattedMessage {...messages.map} />
          </Button>
        </div>
      </div>
      {mapView === 'by-street-view' && (
        <>
          {streetViewExist ? (
            <div className="w-full h-96">
              <GoogleStreetViewMap
                latitude={dropOff.latitude}
                longitude={dropOff.longitude}
                onStreetViewDoesNotExist={handleOnStreetViewDoesNotExist}
              />
            </div>
          ) : (
            <div className="w-full h-96 text-center flex flex-col justify-center border-b border-b-neutral-100">
              <p className="text-neutral-400">
                <FormattedMessage {...messages.streetViewDoesNotExist} />
              </p>
            </div>
          )}
        </>
      )}
      {mapView === 'by-mapbox' && (
        <div className="w-full h-96">
          <ReactMapGl
            id="vehicle-fine-geolocation-map"
            mapboxAccessToken={
              isTest ? '' : getEnvVariable('MAPBOX_ACCESS_TOKEN')
            }
            mapStyle={isTest ? '' : getEnvVariable('MAPBOX_STYLE')}
            initialViewState={{
              longitude: dropOff.longitude,
              latitude: dropOff.latitude,
              zoom: 16,
              bearing: 0,
              pitch: 0,
            }}
            style={{ width: '100%', height: '100%' }}
            scrollZoom
            attributionControl={false}
            testMode={isTest}
          >
            <Marker latitude={dropOff.latitude} longitude={dropOff.longitude} />
          </ReactMapGl>
        </div>
      )}
      <p className="flex items-center p-4 gap-2 text-sm text-neutral-400">
        <MdLocationOn />
        <span>{address}</span>
      </p>
    </div>
  );
};
