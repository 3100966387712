import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { FormNumberInputField } from '~/common';

import { BillingServicePointSelector } from '../../../BillingServicePointSelector/BillingServicePointSelector';

import messages from './messages';

export const FormTPV = () => (
  <>
    <FormNumberInputField
      id="operation-number"
      name="operationNumber"
      className="mt-8 w-full"
      label={
        <InputLabel htmlFor="operation-number">
          <FormattedMessage {...messages.operationNumber} />
        </InputLabel>
      }
    />
    <FormNumberInputField
      id="last-four-digits"
      name="lastFourDigits"
      className="mt-4 w-full"
      label={
        <InputLabel htmlFor="last-four-digits">
          <FormattedMessage {...messages.lastFourDigits} />
        </InputLabel>
      }
    />
    <div className="w-full pt-4">
      <BillingServicePointSelector />
    </div>
  </>
);
