export type CsvPriceListRowKeys<Type extends string> = Type | 'day';

export type CsvPriceListItem<Type extends string> = Record<
  CsvPriceListRowKeys<Type>,
  string | undefined
>;

export const priceListsToCsv = <Type extends string>(
  arr: CsvPriceListItem<Type>[],
  categories: CsvPriceListRowKeys<Type>[]
) => {
  const categoriesWithDay: CsvPriceListRowKeys<Type>[] = ['day', ...categories];
  const keysRow = categoriesWithDay.map((header) => header).join(',');

  const dataRows = arr
    .map((priceList) =>
      categoriesWithDay
        .map((category) => {
          const value = priceList[category];
          return typeof value === 'string' && value.includes(',')
            ? `"${value}"`
            : value;
        })
        .join(',')
    )
    .join('\n');
  return [keysRow, dataRows].join('\n');
};
