import { useField } from '@cooltra/form';

import { RestrictionFeature } from '../RestrictionFeature/RestrictionFeature';

export const RestrictionPreview = () => {
  const { error, value } = useField('polygon');

  if (error) {
    return null;
  }

  const geoJson = JSON.parse(value);

  const coordinates = geoJson?.features[0]?.geometry?.coordinates[0];

  if (!coordinates) {
    return null;
  }

  return (
    <RestrictionFeature
      enabled
      layerId="restriction-preview"
      coordinates={coordinates}
      geofenceRestrictionId="restriction-preview"
    />
  );
};
