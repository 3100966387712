import { PromotionState, PromotionsSort } from '@cooltra/api';

export type PromotionsFiltersFormValues = {
  state: PromotionState;
  keyword: string;
  sort: PromotionsSort;
  operatorGroupId: string;
  type: string;
};

export const initialPromotionsFiltersFormValues: PromotionsFiltersFormValues = {
  state: '' as PromotionState,
  keyword: '',
  sort: 'createdAt.desc',
  operatorGroupId: '',
  type: '',
};
