import { defineMessages } from 'react-intl';

export default defineMessages({
  pricingSummary: {
    defaultMessage: 'Charge',
  },
  missingRentalPricing: {
    defaultMessage:
      'For some reason we do not have the information about the pricing of this rental, please contact IT to find a solution',
  },
  compensate: {
    defaultMessage: 'Compensate',
  },
  compensateTitle: {
    defaultMessage: 'Compensate rental #',
  },
});
