import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Add new location',
  },
  submitButton: {
    defaultMessage: 'Create new location',
  },
  conflictError: {
    defaultMessage: 'The location with this name already exists',
  },
  success: {
    defaultMessage: 'You have successfully created new location!',
  },
});
