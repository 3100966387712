import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Marker, Point, useMap } from 'react-map-gl';
import { BonusZone as BonusZoneModel } from '@cooltra/api';
import { System } from '@cooltra/auth-api';
import { useToggle } from '@cooltra/hooks';

import { Amount } from '../../Amount/Amount';
import { Circle } from '../../Circle/Circle';

import { BonusZone, BonusZoneStyle } from './BonusZone';
import {
  getBonusZoneFillLayerId,
  getBonusZoneSourceId,
} from './get-bonus-zone-layer-id';
import messages from './messages';
import './bonus-zone.css';

export type BonusZoneWithMarkerProps = {
  bonusZone: BonusZoneModel;
  system: System;
  styles?: BonusZoneStyle;
};

export const BonusZoneWithMarker = ({
  bonusZone,
  system,
  styles,
}: BonusZoneWithMarkerProps) => {
  const { current: map } = useMap();
  const [showMarker, { toggleOn, toggleOff }] = useToggle();

  useEffect(() => {
    if (map) {
      const bonusZoneFillLayerId = getBonusZoneFillLayerId(
        bonusZone.bonusZoneId
      );
      const getNonBonusZoneSources = (point: Point) => {
        const clickedSources = map
          .queryRenderedFeatures(point)
          .map((features) => features.source)
          .filter((sources) => sources !== 'composite');

        return clickedSources.filter(
          (sources) =>
            sources !== getBonusZoneSourceId(bonusZone.bonusZoneId, system)
        );
      };

      map.on('click', function (e) {
        const clickedLayerIds = map
          .queryRenderedFeatures(e.point)
          .map((features) => features.layer.id);

        if (!clickedLayerIds.includes(bonusZoneFillLayerId)) {
          toggleOff();
        }
      });

      map.on('click', bonusZoneFillLayerId, (e) => {
        e.preventDefault();
        const nonBonusZoneSources = getNonBonusZoneSources(e.point);

        if (nonBonusZoneSources.length === 0 && !showMarker) {
          toggleOn();
        }
      });

      map.on('mouseenter', bonusZoneFillLayerId, (e) => {
        const nonBonusZoneSources = getNonBonusZoneSources(e.point);

        if (nonBonusZoneSources.length === 0) {
          map.getCanvas().style.cursor = 'pointer';
        }
      });

      map.on('mouseleave', bonusZoneFillLayerId, () => {
        map.getCanvas().style.cursor = '';
      });
    }
  }, [
    bonusZone.bonusZoneId,
    bonusZone.marker,
    map,
    showMarker,
    system,
    toggleOff,
    toggleOn,
  ]);

  return (
    <>
      <BonusZone
        bonusZone={bonusZone}
        system={system}
        styles={styles}
        withNorthernPointMarker
      />
      {showMarker && (
        <Marker
          latitude={bonusZone.marker.latitude}
          longitude={bonusZone.marker.longitude}
        >
          <div className="relative bg-neutral-0 rounded-xl shadow-md flex items-center justify-between p-4 gap-4">
            <Circle className="w-12 h-12 p-2 shrink-0 bg-neutral-400 text-neutral-0 text-3xl">
              <span className="relative -left-0.5">-€</span>
            </Circle>
            <div>
              <p className="max-w-[12rem] text-base font-semibold">
                <FormattedMessage
                  {...messages.bonusZone}
                  values={{
                    bonusZoneTitle: bonusZone.title,
                  }}
                />
              </p>
              <span className="text-sm text-neutral-800 tracking-tight">
                <FormattedMessage
                  {...messages.discount}
                  values={{
                    bonusZoneDiscount: (
                      <Amount
                        className="text-sm"
                        value={-bonusZone.discount}
                        currency="EUR"
                      />
                    ),
                  }}
                />
              </span>
              <div className="absolute left-0 -bottom-2.5 w-full flex items-center justify-center text-center">
                <div className="triangle" />
              </div>
            </div>
          </div>
        </Marker>
      )}
    </>
  );
};
