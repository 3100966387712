import { defineMessages } from 'react-intl';

export default defineMessages({
  geolocation: {
    defaultMessage: 'Geolocation',
  },
  getLocation: {
    defaultMessage: 'Get location',
  },
  updateLocation: {
    defaultMessage: 'Update location',
  },
  deniedPermissions: {
    defaultMessage:
      'Permission for geolocation is denied. Check your settings.',
  },
  removeLocation: {
    defaultMessage: 'Remove',
  },
});
