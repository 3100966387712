import { HTMLAttributes } from 'react';
import { classNames } from '@cooltra/utils';

import { OngoingRentalTotalDistance } from './OngoingRentalTotalDistance';

export type RentalTotalDistanceProps = HTMLAttributes<HTMLSpanElement> & {
  vehicleId: string;
  startMileage: number;
  finishMileage?: number;
};

export const RentalTotalDistance = ({
  vehicleId,
  startMileage,
  finishMileage,
  className,
}: RentalTotalDistanceProps) => {
  if (!finishMileage) {
    return (
      <OngoingRentalTotalDistance
        vehicleId={vehicleId}
        startMileage={startMileage}
      />
    );
  }

  const totalMileageInMts = finishMileage - startMileage;

  if (totalMileageInMts < 1000) {
    return (
      <span className={classNames('block tabular-nums', className)}>
        {totalMileageInMts.toFixed(1)} mts
      </span>
    );
  }

  const totalMileageInKms = totalMileageInMts / 1000;

  return (
    <span className={classNames('block tabular-nums', className)}>
      {totalMileageInKms.toFixed(1)} kms
    </span>
  );
};
