import { useOperatorsQuery } from '@cooltra/auth-api';
import { Placeholder } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type WithOperatorProps = Omit<HTMLAttributes<HTMLSpanElement>, 'id'> & {
  id: string | undefined | null;
};

export const WithOperator = ({ id, className, ...rest }: WithOperatorProps) => {
  const { data } = useOperatorsQuery();

  if (!data || !id) {
    return (
      <div className="h-5 flex items-center">
        <Placeholder className="h-2 w-20" />
      </div>
    );
  }

  return (
    <span
      className={classNames(
        'text-sm',
        'text-neutral-600',
        'inline-flex',
        'max-w-full',
        className
      )}
      {...rest}
    >
      <span className="mr-1">
        <FormattedMessage {...messages.with} />
      </span>
      <span className="text-neutral-800 font-medium truncate">
        {data?.find(({ externalId }) => id === externalId)?.name}
      </span>
    </span>
  );
};
