import { FormattedMessage, useIntl } from 'react-intl';
import { Rental, useRentalPriceEstimationQuery } from '@cooltra/api';
import { Divider, Placeholder } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';
import { keepPreviousData } from '@tanstack/react-query';

import { useDebounce } from '~/hooks';
import { Amount } from '~/common';

import { RentalManualCloseFormValues } from '../RentalManualCloseForm';

import { Pair } from './Pair';
import messages from './messages';

export type RentalPriceEstimationProps = {
  rental: Rental;
};

export const RentalPriceEstimation = ({
  rental,
}: RentalPriceEstimationProps) => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext<RentalManualCloseFormValues>();

  const debounceMinutes = useDebounce(values.billableDuration, 500);
  const { data: priceEstimation, isError } = useRentalPriceEstimationQuery(
    rental.rentalId,
    debounceMinutes ? { billableDuration: debounceMinutes } : {},
    { placeholderData: keepPreviousData }
  );

  if (isError) {
    return (
      <p className="w-full text-center text-sm text-neutral-300 py-4">
        <FormattedMessage {...messages.errorOnRentalEstimationPrice} />
      </p>
    );
  }

  if (!priceEstimation) {
    return (
      <>
        <Pair
          label={<Placeholder className="w-20 h-3" />}
          value={<Placeholder className="w-16 h-3" />}
        />
        <Divider />
        <Pair
          label={<Placeholder className="w-20 h-3" />}
          value={<Placeholder className="w-16 h-3" />}
        />
        <Divider />
        <Pair
          label={<Placeholder className="w-20 h-3" />}
          value={<Placeholder className="w-16 h-3" />}
        />
        <Divider />
      </>
    );
  }

  const {
    currentPrice,
    manualPrice,
    creditUsed,
    totalPrice,
    startingPrice,
    bonusZoneDiscount,
    discount,
    rentalPassDiscount,
    vehicleBonusDiscount,
  } = priceEstimation;

  const getTotalPrice = () => {
    if (!manualPrice) {
      return <Amount {...currentPrice} />;
    }

    return (
      <>
        {currentPrice.value !== manualPrice.value && (
          <Amount
            {...currentPrice}
            className="line-through text-sm text-neutral-400"
          />
        )}
        <Amount {...manualPrice} />
      </>
    );
  };

  return (
    <>
      <Pair
        label={formatMessage(messages.totalPrice)}
        className="h-auto box-border pt-4 pb-2"
        valueClassName="text-md font-semibold"
        value={
          <div
            data-testid="RENTAL_TOTAL_PRICE"
            className="flex items-center gap-4"
          >
            {getTotalPrice()}
          </div>
        }
      />
      <Pair
        label={formatMessage(messages.startingPrice)}
        className="h-auto box-border pb-4"
        labelClassName="text-neutral-400"
        valueClassName="text-xs"
        value={
          <Amount
            data-testid="RENTAL_STARTING_PRICE"
            value={startingPrice.value}
            currency={startingPrice.currency}
          />
        }
      />
      {bonusZoneDiscount.value > 0 && (
        <Pair
          label={formatMessage(messages.bonusZone)}
          className="h-auto box-border pb-4"
          labelClassName="text-neutral-400"
          valueClassName="text-xs"
          value={
            <Amount
              data-testid="RENTAL_BONUS_AREA"
              value={bonusZoneDiscount.value}
              currency={bonusZoneDiscount.currency}
              negative
            />
          }
        />
      )}
      {discount && (
        <Pair
          label={discount.title}
          className="h-auto box-border pb-4"
          labelClassName="text-neutral-400"
          valueClassName="text-xs"
          value={
            <Amount
              data-testid="RENTAL_DISCOUNT_PROMOTION"
              value={discount.amount.value}
              currency={discount.amount.currency}
              negative
            />
          }
        />
      )}
      {rentalPassDiscount.value > 0 && (
        <Pair
          label={formatMessage(messages.rentalPassDiscount)}
          className="h-auto box-border pb-4"
          labelClassName="text-neutral-400"
          valueClassName="text-xs"
          value={
            <Amount
              data-testid="RENTAL_PASS"
              value={rentalPassDiscount.value}
              currency={rentalPassDiscount.currency}
              negative
            />
          }
        />
      )}
      {vehicleBonusDiscount.value > 0 && (
        <Pair
          label={formatMessage(messages.vehicleBonusDiscount)}
          className="h-auto box-border pb-4"
          labelClassName="text-neutral-400"
          valueClassName="text-xs"
          value={
            <Amount
              data-testid="RENTAL_VEHICLE_BONUS"
              value={vehicleBonusDiscount.value}
              currency={vehicleBonusDiscount.currency}
              negative
            />
          }
        />
      )}
      <Divider />
      <Pair
        label={formatMessage(messages.credit)}
        value={<Amount {...creditUsed} data-testid="RENTAL_CREDIT" />}
      />
      <Divider />
      <Pair
        label={
          <span className="font-semibold text-neutral-1000">
            {formatMessage(messages.total)}
          </span>
        }
        value={
          <Amount
            {...totalPrice}
            data-testid="RENTAL_TOTAL"
            className="font-semibold text-neutral-1000"
          />
        }
      />
      <Divider />
    </>
  );
};
