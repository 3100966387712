import { RouterButton } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { List } from '@cooltra/ui';
import { getErrorStatus } from '@cooltra/axios';
import { useCooltraForEmployeePromotionsQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import { ErrorPage, Four0Three, NoSearchResults } from '~/common';

import { OffersNavigationTabs } from '../../OffersNavigationTabs/OffersNavigationTabs';

import { CooltraForEmployeeListHeader } from './ListHeader/CooltraForEmployeeListHeader';
import { CooltraForEmployeeCard, CooltraForEmployeeCardLoading } from './Card';
import messages from './messages';

export const CooltraForEmployeesList = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { data, isLoading, isError, error } =
    useCooltraForEmployeePromotionsQuery({
      enabled: hasPermission('read:cooltra_for_employees'),
    });

  if (!hasPermission('read:cooltra_for_employees')) {
    return <Four0Three />;
  }

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div className="container min-w-5xl max-w-6xl py-12">
      <div className="flex items-center justify-between mb-6">
        <OffersNavigationTabs />
        {hasPermission('write:cooltra_for_employees') && (
          <RouterButton emphasis="high" to="/offers/cooltra-for-employees/new">
            <FormattedMessage {...messages.createCooltraForEmployees} />
          </RouterButton>
        )}
      </div>
      <div className="flex justify-between items-center mb-12"></div>
      <List
        isLoading={isLoading}
        loadingRows={4}
        idProp="cooltraForEmployeePromotionId"
        data={data?.cooltraForEmployeePromotions || []}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<CooltraForEmployeeListHeader />}
        renderLoadingRow={<CooltraForEmployeeCardLoading />}
        renderRow={(cooltraForEmployeePromotion) => (
          <CooltraForEmployeeCard
            cooltraForEmployeePromotion={cooltraForEmployeePromotion}
          />
        )}
      />
    </div>
  );
};
