import { defineMessages } from 'react-intl';

export default defineMessages({
  successOnTransferringTheVehicle: {
    defaultMessage: 'The vehicle has been successfully transferred',
  },
  changeLocation: {
    defaultMessage: 'Change location',
  },
  vehicleInformation: {
    defaultMessage: 'Vehicle information',
  },
  userDoesNotHavePermissionsToTransferVehicle: {
    defaultMessage: 'You do not have permission to transfer the vehicle',
  },
  somethingWentWrong: {
    defaultMessage: 'Something went wrong!',
  },
  cannotTransfer: {
    defaultMessage:
      'Check in Electric Feel if the vehicle is in a reservation or rental',
  },
  sendingVehicleToALocationWithDifferentHomeSystem: {
    defaultMessage:
      'Error to send a vehicle to a location with different Home System',
  },
  missingFields: {
    defaultMessage: 'There are some missing fields',
  },
});
