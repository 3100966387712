import { defineMessages } from 'react-intl';

export default defineMessages({
  selectLocationPlaceholder: {
    defaultMessage: 'Select location',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  save: {
    defaultMessage: 'Save',
  },
  selectNewLocation: {
    defaultMessage: 'Select new location',
  },
  noLocations: {
    defaultMessage: 'There is no locations',
  },
});
