import { FormattedMessage, useIntl } from 'react-intl';
import { InputError, InputLabel, SelectField } from '@cooltra/ui';
import { useField, useFormContext } from '@cooltra/form';
import { InvoiceCategory, Provider } from '@cooltra/api';
import { getKeys } from '@cooltra/utils';

import { ChargeUserFormValues } from '../../ChargeUser/charge-user-form';

import messages, { categories } from './messages';

export type CategoryProps = {
  provider: Provider;
};

export const Category = ({ provider }: CategoryProps) => {
  const { isSubmitting, isFormDisabled, setValues, values } =
    useFormContext<ChargeUserFormValues>();
  const { value, error, touched, setTouched } = useField('category');
  const { formatMessage } = useIntl();
  const options = getKeys(categories)
    .filter((category) => category != 'ADMIN_COSTS_CRANE' || provider != 'SIXT')
    .map((category) => ({
      label: formatMessage(categories[category]),
      value: category,
    }));

  return (
    <SelectField
      id="category"
      error={touched && error ? <InputError>{error}</InputError> : undefined}
      onChange={(val) => {
        const category = val as InvoiceCategory;
        setValues({
          ...values,
          category,
        });
      }}
      value={value}
      onBlur={() => {
        setTouched(true);
      }}
      disabled={isFormDisabled || isSubmitting}
      noOptionsMessage={() => <></>}
      options={options}
      label={
        <InputLabel htmlFor="category">
          <FormattedMessage {...messages.label} />
        </InputLabel>
      }
    />
  );
};
