import { defineMessages } from 'react-intl';

export default defineMessages({
  rejectButton: {
    defaultMessage: 'Reject',
  },
  conflictErrorMessage: {
    defaultMessage:
      'The user details have been updated in the meantime. Please refresh the page.',
  },
  title: {
    defaultMessage: "You're about to bypass the standard review process",
  },
  content: {
    defaultMessage:
      "Please reject the user this way only if there is no other way you can do so and if you're completely sure that the user should be rejected.",
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  confirm: {
    defaultMessage: 'Reject user',
  },
  userRejected: {
    defaultMessage: 'User has been rejected',
  },
  errorMessage: {
    defaultMessage: 'Something went wrong!',
  },
});
