import { useFormContext } from '@cooltra/form';

import { SaveButton } from '~/common';

import { VehicleFormValues } from '../vehicle-form';

export type SaveVehicleDetailsProps = {
  isSuccess: boolean;
};

export const SaveVehicleDetailsButton = ({
  isSuccess,
}: SaveVehicleDetailsProps) => {
  const { isValid, dirty, isSubmitting } = useFormContext<VehicleFormValues>();

  return (
    <SaveButton
      className="btn-shadow fixed bottom-8 right-28 -mr-2 z-40"
      type="submit"
      loading={isSubmitting}
      isSaved={isSuccess && !dirty}
      disabled={!isValid || !dirty}
    />
  );
};
