import { VehiclesFiltersForm } from '~/libs/vehicles-filters';
import { MapCenterProvider } from '~/libs/map-center';

import { OpsMapView } from './OpsMapView';

export const OpsMap = () => (
  <VehiclesFiltersForm>
    <MapCenterProvider>
      <OpsMapView />
    </MapCenterProvider>
  </VehiclesFiltersForm>
);
