import { ContractAccessory } from '@cooltra/station-based-api';
import { Badge } from '@cooltra/ui';
import { MdAirportShuttle } from 'react-icons/md';

import { useAccessoryName } from '~/hooks';
import { isCustomDelivery, isCustomReturn } from '~/utils/accessories';

export type AccessoryTagProps = Pick<ContractAccessory, 'name' | 'quantity'>;

export const AccessoryTag = ({ name, quantity }: AccessoryTagProps) => {
  const getAccessoryName = useAccessoryName();

  const isReturn = isCustomReturn(name);
  const isDelivery = isCustomDelivery(name);

  return (
    <Badge emphasis={isReturn || isDelivery ? 'medium' : 'low'}>
      {isReturn && (
        <MdAirportShuttle className="inline-flex mr-1.5 text-base text-neutral-500" />
      )}
      {isDelivery && (
        <MdAirportShuttle className="inline-flex mr-1.5 -scale-x-100 text-base text-neutral-500" />
      )}
      {getAccessoryName(name)} {quantity > 1 && `x${quantity}`}
    </Badge>
  );
};
