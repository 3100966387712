import { defineMessages } from 'react-intl';

export default defineMessages({
  checksIn: {
    defaultMessage: 'Delivery checks',
  },
  checksOut: {
    defaultMessage: 'Return checks',
  },
  lastRegistry: {
    defaultMessage: 'Last registry',
  },
  lastRegistryMileage: {
    defaultMessage: '{mileage} Kms',
  },
  fuelPenalty: {
    defaultMessage: 'Fuel penalty',
  },
  openDetails: {
    defaultMessage: 'Go to vehicle assigned {licensePlate}',
  },
});
