import { ProblemReportListItem } from '@cooltra/api';
import { classNames } from '@cooltra/utils';
import { RouterCardLink, RouterLink } from '@cooltra/navigation';

import { ModelImage, Context, ProblemReportCardInfo, Log } from '~/common';

import { OpenUserProblemReportLayout } from './OpenUserProblemReportLayout';

export type OpenUserProblemReportCardProps = {
  problemReport: ProblemReportListItem;
};

export const OpenUserProblemReportCard = ({
  problemReport: {
    problemReportId,
    tags,
    description,
    open,
    vehicle,
    numberImages,
    rentalId,
    reservationId,
    createdAt,
  },
}: OpenUserProblemReportCardProps) => (
  <RouterCardLink
    isMultiAction
    to={`/problem-reports/${problemReportId}`}
    className={classNames(open ? 'opacity-100' : 'opacity-50')}
  >
    <OpenUserProblemReportLayout
      className="min-h-28 py-4"
      problem={
        <ProblemReportCardInfo
          open={open}
          numberImages={numberImages}
          description={description}
          tags={tags}
        />
      }
      context={<Context rentalId={rentalId} reservationId={reservationId} />}
      created={<Log action="create" date={createdAt} />}
      vehicle={
        <RouterLink
          to={`/vehicles/${vehicle.vehicleId}`}
          className="w-full block"
        >
          <span className="text-right text-sm font-medium">
            <span className="flex items-center justify-end gap-1 h-5">
              <ModelImage
                className="h-6"
                model={vehicle.model}
                identificationNumber={vehicle.identificationNumber}
              />
              <span className="truncate">{vehicle.externalId}</span>
            </span>
          </span>
        </RouterLink>
      }
    />
  </RouterCardLink>
);
