import { Placeholder } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type TotalTakesProps = {
  totalTakes: number;
};

export const TotalTakes = ({ totalTakes }: TotalTakesProps) => {
  return (
    <div className="flex flex-col">
      {totalTakes !== undefined ? (
        <span className="text-md text-neutral-1000 font-semibold">
          {totalTakes}
        </span>
      ) : (
        <Placeholder className="w-6 h-5 mx-auto mb-1" />
      )}
      <span className="text-xs text-neutral-300">
        <FormattedMessage {...messages.totalTakes} />
      </span>
    </div>
  );
};
