import { UserRentalPass } from '@cooltra/api';
import { Badge, Divider } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { capitalize } from '@cooltra/utils';

import { DateTime } from '~/common';

import messages, { statusMessages } from './messages';

export const RentalPass = ({
  title,
  validFrom,
  validUntil,
}: UserRentalPass) => (
  <>
    <div className="mb-3">
      <Badge variant="success">
        <FormattedMessage {...statusMessages['ACTIVE']} />
      </Badge>
    </div>
    <div className="pb-6">
      <h4 className="text-xl mb-2">{capitalize(title)}</h4>
    </div>
    <div className="flex flex-col flex-1">
      <div className="flex flex-col gap-1 flex-1">
        <div className="flex flex-col gap-4 text-xs">
          <div>
            <FormattedMessage {...messages.validFrom} />
            <span className="font-semibold">
              <DateTime date={validFrom} />
            </span>
          </div>
          <div>
            <FormattedMessage {...messages.validUntil} />
            <span className="font-semibold">
              <DateTime date={validUntil} />
            </span>
          </div>
        </div>
      </div>
      <Divider />
    </div>
  </>
);
