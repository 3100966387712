import { Card, Divider } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import {
  useUserDetailsMutation,
  UserDetailsPayload,
  User,
  checkUser,
} from '@cooltra/api';
import {
  ZeusErrorData,
  getErrorStatus,
  getZeusErrorMessage,
  getRequestConfig,
} from '@cooltra/axios';
import { FormButton, FormProvider, Form, FormHelpers } from '@cooltra/form';
import { MdOutlineChevronRight } from 'react-icons/md';

import { useNotification } from '~/hooks';
import { UnsavedChangesPrompt, UserFields, AddressFields } from '~/common';

import { DeleteUser } from '../DeleteUser/DeleteUser';

import {
  validatePersonalDetailsForm,
  getPersonalDetailsInitialValues,
  PersonalDetailsFormValues,
  getPersonalDetailsPayload,
} from './personal-details-form';
import messages from './messages';

export type PersonalDetailsProps = {
  user: User;
};

export const PersonalDetailsForm = ({ user }: PersonalDetailsProps) => {
  const intl = useIntl();
  const { id = '' } = useParams<'id'>();

  const mutation = useUserDetailsMutation(id);

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const isDeleted = user.state === 'DELETED';

  const { getAccessTokenSilently } = useAuth0();

  const handleSubmit = async (
    values: PersonalDetailsFormValues,
    { resetForm, setErrors }: FormHelpers<PersonalDetailsFormValues>
  ) =>
    getAccessTokenSilently()
      .then((accessToken) =>
        checkUser(
          id,
          { email: values.email, phone: values.phone },
          getRequestConfig(accessToken)
        )
      )
      .then((valid) => {
        if (valid.email && valid.phone) {
          return mutation.mutateAsync(
            getPersonalDetailsPayload(
              values,
              user.version
            ) as UserDetailsPayload
          );
        }
        const duplicatedMessage = intl.formatMessage(messages.duplicated);
        setErrors({
          email: valid.email === false ? duplicatedMessage : undefined,
          phone: valid.phone === false ? duplicatedMessage : undefined,
        });
        return false as unknown;
      })
      .then((result) => {
        if (result !== false) {
          addSuccessNotification(
            intl.formatMessage(messages.submissionSuccess)
          );
          resetForm(values);
        }
      })
      .catch((error: AxiosError<ZeusErrorData>) => {
        if (getErrorStatus(error) === 409) {
          addErrorNotification(
            intl.formatMessage(messages.conflictErrorMessage)
          );
        } else {
          const errorMessage = getZeusErrorMessage(error);
          addErrorNotification(errorMessage);
        }
      });

  return (
    <FormProvider
      initialValues={getPersonalDetailsInitialValues(user)}
      onSubmit={handleSubmit}
      validate={validatePersonalDetailsForm(intl)}
    >
      <Form>
        <div data-testid="EDIT_USER_PERSONAL_DETAILS">
          <Card className="mb-5">
            <div className="p-10">
              <h2 className="text-xl font-semibold text-neutral-400 mb-8">
                <FormattedMessage {...messages.personalDetails} />
              </h2>
              <div className="grid grid-cols-6 gap-x-5 gap-y-10">
                <div className="col-span-3">
                  <UserFields.FirstName />
                </div>
                <div className="col-span-3">
                  <UserFields.LastName />
                </div>
                <div className="col-span-3">
                  <UserFields.System />
                </div>
                <div className="col-span-3">
                  <UserFields.Language />
                </div>
                <div className="col-span-2">
                  <UserFields.DateOfBirth />
                </div>
              </div>
            </div>
            <Divider className="my-3" />
            <div className="p-10 mb-5">
              <h2 className="text-xl font-semibold text-neutral-400 mb-8">
                <FormattedMessage {...messages.contactAndAddress} />
              </h2>
              <div className="grid grid-cols-6 gap-x-5 gap-y-10">
                <div className="col-span-6">
                  <UserFields.Email />
                </div>
                <div className="col-span-4">
                  <AddressFields.Street />
                </div>
                <div className="col-span-2">
                  <AddressFields.Zip />
                </div>
                <div className="col-span-3">
                  <AddressFields.City />
                </div>
                <div className="col-span-3">
                  <AddressFields.Country />
                </div>
                <div className="col-span-2">
                  <UserFields.MobilePhone />
                </div>
              </div>
            </div>
          </Card>
          <UnsavedChangesPrompt />
          <div className="flex justify-between">
            {!isDeleted ? <DeleteUser email={user.email} /> : <div />}
            <FormButton
              trailingIcon={<MdOutlineChevronRight className="text-xl" />}
            >
              <FormattedMessage {...messages.submitBtn} />
            </FormButton>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};
