import { isToday } from 'date-fns';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

import messages from './messages';

export type MultilineDateTimeProps = {
  date: Date | string;
  emphasize?: 'hour' | 'date';
};

const DateComponent = ({ date }: { date: Date | string }) => {
  const dateObj = new Date(date);
  return isToday(dateObj) ? (
    <FormattedMessage {...messages.today} />
  ) : (
    <FormattedDate value={dateObj} day="2-digit" month="short" year="numeric" />
  );
};

const TimeComponent = ({ date }: { date: Date | string }) => (
  <FormattedTime
    value={new Date(date)}
    hour="2-digit"
    hourCycle="h23"
    minute="2-digit"
  />
);

export const MultilineDateTime = ({
  date,
  emphasize = 'hour',
}: MultilineDateTimeProps) =>
  emphasize === 'hour' ? (
    <div className="tabular-nums text-sm">
      <span className="text-neutral-800 font-semibold block">
        <TimeComponent date={date} />
      </span>
      <span className="text-neutral-600 block">
        <DateComponent date={date} />
      </span>
    </div>
  ) : (
    <div className="tabular-nums text-sm">
      <span className="text-neutral-800 font-semibold block">
        <DateComponent date={date} />
      </span>
      <span className="text-neutral-600 block">
        <TimeComponent date={date} />
      </span>
    </div>
  );
