import { defineMessages } from 'react-intl';

export default defineMessages({
  pack: {
    defaultMessage: 'Pack {title}',
  },
  rental: {
    defaultMessage: 'Rental {rentalId}',
  },
});
