import { FormattedMessage } from 'react-intl';
import { Button, Modal } from '@cooltra/ui';
import { Form, useFormContext } from '@cooltra/form';

import { useContractFlags } from '~/hooks';

import { DepositFormValues } from '../deposit-form';

import { FormRadioGroupDepositsField } from './FormRadioGroupDepositsField';
import { FormTPV } from './FormTPV';
import { SubmitButton } from './SubmitButton/SubmitButton';
import messages from './messages';

type ContractDepositModalProps = {
  contractId: string;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  isLoading: boolean;
  hasLinkedPaymentMethod: boolean;
  onSubmit: (
    values: DepositFormValues,
    resetForm: (values: DepositFormValues) => void
  ) => Promise<void>;
};

export const ContractDepositModal = ({
  contractId,
  isOpen,
  onClose,
  onOpen,
  isLoading,
  hasLinkedPaymentMethod,
  onSubmit,
}: ContractDepositModalProps) => {
  const { values } = useFormContext<DepositFormValues>();
  const { amount, method } = values;
  const { isSignedOrPendingSignature } = useContractFlags();

  const isButtonDisabled = isSignedOrPendingSignature || !amount || isLoading;

  return (
    <div data-testid="CONTRACT_DEPOSIT_MODAL" className="flex justify-end mt-6">
      <Button disabled={isButtonDisabled} emphasis="high" onClick={onOpen}>
        <FormattedMessage {...messages.retainDeposit} />
      </Button>
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <Form className="min-w-xl px-12 pb-12 flex flex-col gap-2 items-center">
          <div className="w-full">
            <FormRadioGroupDepositsField
              amount={amount}
              hasLinkedPaymentMethod={hasLinkedPaymentMethod}
            />
          </div>
          {method === 'TPV' && <FormTPV />}
          <div className="flex gap-4 mt-8">
            <SubmitButton
              contractId={contractId}
              isLoading={isLoading}
              onSubmit={onSubmit}
              onCloseModal={onClose}
            />
          </div>
        </Form>
      </Modal>
    </div>
  );
};
