import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type ManualAdjustmentButtonProps = {
  disabled: boolean;
  onClick: VoidFunction;
};

export const ManualAdjustmentButton = ({
  disabled,
  onClick,
}: ManualAdjustmentButtonProps) => (
  <Button disabled={disabled} onClick={onClick}>
    <FormattedMessage {...messages.adjustTotal} />
  </Button>
);
