import { defineMessages } from 'react-intl';

export default defineMessages({
  shouldCancelSign: {
    defaultMessage:
      'This contract cannot be modified until it is signed or the signature request is cancelled',
  },
  awaitingToBeActivated: {
    defaultMessage: 'This contract cannot be modified until it is activated',
  },
  alertTitle: {
    defaultMessage: 'Before editing the contract:',
  },
});
