import {
  PriceTypeByProductCategory,
  AlgorithmPrice,
  UpdatePricingGroupPricesPayload,
} from '@cooltra/station-based-api';
import { Currency } from '@cooltra/utils';
import { format, parse } from 'date-fns';

import { dateFormat } from '~/utils/date';

import { PriceListFormValues } from './Form';

const getPriceTypeByProductCategory = (
  type: string,
  priceValue: string,
  priceCurrency: Currency = 'EUR'
): PriceTypeByProductCategory => {
  return {
    price: {
      value: Number(priceValue),
      currency: priceCurrency,
    },
    algorithmPrice: type.replace(
      /^((?!(BASE|AGGRESSIVE|MODERATE|LINEAR|EXTRA|FIXED)).)*/,
      ''
    ) as AlgorithmPrice,
  };
};

const LEAP_YEAR = 2024;

export const getPriceListPayload = (
  values: PriceListFormValues
): UpdatePricingGroupPricesPayload => {
  const parsedDateFrom = parse(
    `${values.dateFrom}-${LEAP_YEAR}`,
    dateFormat,
    new Date()
  );

  const payload: UpdatePricingGroupPricesPayload = {
    dateFrom: `${format(parsedDateFrom, 'MM-dd')}`,
    prices: {},
  };

  if (values['prices.EBIKE.type'] && values['prices.EBIKE.price.value']) {
    payload['prices']['EBIKE'] = getPriceTypeByProductCategory(
      values['prices.EBIKE.type'],
      values['prices.EBIKE.price.value']
    );
  }

  if (values['prices.PREMIUM.type'] && values['prices.PREMIUM.price.value']) {
    payload['prices']['PREMIUM'] = getPriceTypeByProductCategory(
      values['prices.PREMIUM.type'],
      values['prices.PREMIUM.price.value']
    );
  }

  if (values['prices.STANDARD.type'] && values['prices.STANDARD.price.value']) {
    payload['prices']['STANDARD'] = getPriceTypeByProductCategory(
      values['prices.STANDARD.type'],
      values['prices.STANDARD.price.value']
    );
  }

  if (values['prices.PLUS.type'] && values['prices.PLUS.price.value']) {
    payload['prices']['PLUS'] = getPriceTypeByProductCategory(
      values['prices.PLUS.type'],
      values['prices.PLUS.price.value']
    );
  }

  if (values['prices.SUPER.type'] && values['prices.SUPER.price.value']) {
    payload['prices']['SUPER'] = getPriceTypeByProductCategory(
      values['prices.SUPER.type'],
      values['prices.SUPER.price.value']
    );
  }

  return payload;
};
