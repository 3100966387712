import { MultiPolygon, VehicleType } from '@cooltra/api';
import { Layer, Source } from 'react-map-gl';

import { geofenceBorderLayer, geofenceLayer } from './layer';

type GeofencesMapSource = {
  id: string;
  layers: JSX.Element[];
  layerId: string;
  data: any[];
};

export const getGeofenceMapSource = (
  geofence: MultiPolygon,
  vehicleType: VehicleType
): GeofencesMapSource => {
  const geofenceSourceId = 'geofences';
  const layerId = `${geofenceLayer.id}`;
  const geofenceLayerObjectData = {
    ...geofenceLayer,
    source: geofenceSourceId,
    id: layerId,
  };

  return {
    id: geofenceSourceId,
    layerId,
    data: [
      {
        type: 'Feature',
        properties: {
          vehicleType,
        },
        geometry: geofence,
      },
    ],
    layers: [
      <Layer key="geofenceLayer" {...geofenceLayerObjectData} />,
      <Layer key="geofenceBorderLayer" {...geofenceBorderLayer} />,
    ],
  };
};

type GeofencesProps = {
  geofences: MultiPolygon | undefined;
  vehicleType: VehicleType;
};

const DEFAULT_GEOFENCE_MULTI_POLYGON: MultiPolygon = {
  type: 'MultiPolygon',
  coordinates: [],
};

export const Geofences = ({ geofences, vehicleType }: GeofencesProps) => {
  const source = getGeofenceMapSource(
    geofences || DEFAULT_GEOFENCE_MULTI_POLYGON,
    vehicleType
  );

  return (
    <Source
      key={source.id}
      id={source.id}
      type="geojson"
      buffer={10}
      data={{
        type: 'FeatureCollection',
        features: source.data,
      }}
    >
      {source.layers}
    </Source>
  );
};
