import { useEffect, useState } from 'react';

const screens = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

export const useMediaQuery = (screen: keyof typeof screens) => {
  const [matches, setMatches] = useState(window.innerWidth >= screens[screen]);

  useEffect(() => {
    const matchQueryList = window.matchMedia(
      `(min-width: ${screens[screen]}px)`
    );
    function handleChange(e: MediaQueryListEvent) {
      setMatches(e.matches);
    }
    matchQueryList.addEventListener('change', handleChange);
    return () => {
      matchQueryList.removeEventListener('change', handleChange);
    };
  }, [screen]);

  return matches;
};
