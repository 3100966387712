import { ReactNode } from 'react';

type PairValueProps = {
  value: string | number | ReactNode;
  title: string;
};

export const PairValue = ({ value, title }: PairValueProps) => (
  <div className="flex flex-col gap-2 items-center">
    <span className="font-semibold text-4xl"> {value} </span>
    <span className="text-sm text-neutral-400"> {title} </span>
  </div>
);
