type Dimension = 'width' | 'height';

export type PhotoSize = 'sm' | 'md' | 'lg';

export const photoSize: Record<PhotoSize, Record<Dimension, string>> = {
  sm: {
    width: 'w-28',
    height: 'h-28',
  },
  md: {
    width: 'w-60',
    height: 'h-60',
  },
  lg: {
    width: 'user-photo-w-lg',
    height: 'user-photo-h-lg',
  },
};
