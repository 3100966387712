import { useField } from '@cooltra/form';

import { BonusZoneFeature } from '../BonusZoneFeature/BonusZoneFeature';

export const BonusZonePreview = () => {
  const { error, value } = useField('polygon');

  if (error) {
    return null;
  }

  const geoJson = JSON.parse(value);

  const coordinates =
    geoJson?.features?.[0]?.geometry?.coordinates?.[0] ||
    geoJson?.coordinates?.[0];

  if (!coordinates) {
    return null;
  }

  return (
    <BonusZoneFeature
      enabled
      layerId="bonus-zone-preview"
      coordinates={coordinates}
      bonusZoneId="bonus-zone-preview"
    />
  );
};
