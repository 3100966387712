import { useField, useFormContext } from '@cooltra/form';
import { classNames } from '@cooltra/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { NumberFormatBase } from 'react-number-format';
import { Input } from '@cooltra/ui';

import messages from './messages';

export const BillableDurationField = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();

  const { isSubmitting, isFormDisabled } = useFormContext();
  const { value, setValue, setTouched, error, touched } =
    useField<string>('billableDuration');

  return (
    <div className="flex justify-between items-center h-16">
      <label htmlFor="billable-duration">
        <span className="text-neutral-400 text-sm">
          <FormattedMessage {...messages.label} />
        </span>
      </label>
      <NumberFormatBase
        customInput={Input}
        id="billable-duration"
        className={classNames(
          'input h-8 w-36',
          touched && error ? 'input-invalid' : 'input-normal'
        )}
        value={value}
        invalid={touched && !!error}
        onChange={({ target: { value } }) => setValue(value)}
        onBlur={() => setTouched(true)}
        placeholder={formatMessage(messages.placeholder)}
        disabled={
          !hasPermission('write:rentals') || isFormDisabled || isSubmitting
        }
      />
    </div>
  );
};
