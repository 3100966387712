import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { usePatchPromotionMutation, usePromotionQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Card, Spinner } from '@cooltra/ui';
import { FormButton, FormHelpers } from '@cooltra/form';
import { capitalize } from '@cooltra/utils';

import {
  Four0Four,
  Four0Three,
  Log,
  PromotionForm,
  PromotionFormValues,
  PromotionState,
  PromotionStateType,
  UnsavedChangesPrompt,
} from '~/common';
import { useNotification } from '~/hooks';

import { PromotionBackLink } from '../BackLink/PromotionBackLink';
import { PromotionFormFields } from '../PromotionFormFields';
import { getFormValuesFromPromotion } from '../get-form-values-from-promotion';

import { getEditPromotionPayload } from './get-edit-promotion-payload';
import messages from './messages';

export const EditPromotion = () => {
  const { promotionId = '' } = useParams<'promotionId'>();
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthClaimsQuery();
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const {
    data: promotion,
    isLoading,
    refetch,
  } = usePromotionQuery(promotionId);
  const { mutateAsync } = usePatchPromotionMutation(promotionId);

  if (!hasPermission('write:offers')) {
    return <Four0Three />;
  }

  if (isLoading) {
    return (
      <div className="w-full text-center mt-8">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!promotion) {
    return <Four0Four />;
  }

  const onSubmitPromotionForm = (
    values: PromotionFormValues,
    { resetForm }: FormHelpers<PromotionFormValues>
  ) => {
    const payload = getEditPromotionPayload(values);

    return mutateAsync(payload)
      .then(async () => {
        resetForm(values);
        addSuccessNotification(
          formatMessage(messages.youHaveSuccessfullyUpdatedThePromotion)
        );
        await refetch();
      })
      .catch(({ response }) => {
        switch (response.status) {
          case 422:
            if (formValues.promotionCodeMode == 'multi-code') {
              addErrorNotification(
                formatMessage(messages.updateMultiCodeError)
              );
            } else {
              addErrorNotification(
                formatMessage(messages.updateSingleCodeError)
              );
            }
            break;
          default:
            addErrorNotification();
            break;
        }
      });
  };

  const formValues = getFormValuesFromPromotion(promotion, true);
  const {
    couponsUsed,
    createdAt,
    validUntil,
    updatedBy,
    titleTranslations,
    validFrom,
    updatedAt,
    createdBy,
  } = promotion;

  return (
    <div
      className="container min-w-screen-sm max-w-5xl py-12"
      data-testid="EDIT_PROMOTION"
    >
      <div className="mb-9">
        <PromotionBackLink />
        <div className="flex items-center gap-4">
          <h1 className="text-3xl">{capitalize(titleTranslations.en || '')}</h1>
          <PromotionState validFrom={validFrom} validUntil={validUntil} />
        </div>
      </div>
      <div className="flex px-4 gap-12">
        <div className="tab tab-active">
          <FormattedMessage {...messages.promotionRules} />
        </div>
        <div className="tab text-neutral-400">
          <FormattedMessage
            {...messages.couponsUsed}
            values={{
              amount: couponsUsed || 0,
            }}
          />
        </div>
      </div>
      <PromotionForm
        initialValues={formValues}
        onSubmit={onSubmitPromotionForm}
      >
        <div className="flex gap-6 items-start">
          <div>
            <PromotionFormFields />
            {formValues.stateType !== PromotionStateType.EXPIRED && (
              <div className="flex justify-end">
                <FormButton>
                  <FormattedMessage {...messages.submitButton} />
                </FormButton>
              </div>
            )}
          </div>
          <Card className="flex flex-col gap-4 p-10">
            <Log
              user="operator"
              action="create"
              userId={createdBy}
              date={createdAt}
            />
            {updatedBy && updatedAt && (
              <Log
                user="operator"
                action="update"
                userId={updatedBy}
                date={updatedAt}
              />
            )}
          </Card>
          <UnsavedChangesPrompt />
        </div>
      </PromotionForm>
    </div>
  );
};
