import { Vehicle } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { RouterSidebarNavLink } from '@cooltra/navigation';
import { Divider, Icon, Tooltip } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { MdLocationOn, MdCheck } from 'react-icons/md';

import {
  ModelImage,
  ModelName,
  OperationalConditionBadge,
  OperationalStateBadge,
  Pair,
  SidebarHeading,
  SidebarIconItem,
  SidebarItem,
  SidebarLicensePlateItem,
  SidebarLinkContent,
  SystemCity,
} from '~/common';

import { TechnicalInspectionScheduledAt } from '../TechnicalInspectionScheduledAt/TechnicalInspectionScheduledAt';
import { ServiceMileage } from '../ServiceMileage/ServiceMileage';
import { Mileage } from '../Mileage/Mileage';
import { TechnicalInspectionOutOfTimeBadge } from '../TechnicalInspectionOutOfTimeBadge/TechnicalInspectionOutOfTimeBadge';
import { ServiceOutOfMileageBadge } from '../ServiceOutOfMileageBadge/ServiceOutOfMileageBadge';

import messages from './messages';

export type SidebarProps = {
  vehicle: Vehicle;
};

export const Sidebar = ({
  vehicle: {
    model,
    vehicleId,
    externalId,
    system,
    cityHallPublished,
    operationalConditions = [],
    operationalState,
    licensePlate,
    identificationNumber,
    telematicsId,
    services,
    technicalInspections,
  },
}: SidebarProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  const areRentalsEnabled = hasPermission('read:rentals');
  const modelSuffix = () => {
    if (model === 'NIU_N1S') return '45 km/h';
    else if (model === 'NIU_N1S_25') return '25 km/h';
    else return '';
  };

  return (
    <div data-testid="VEHICLE_SIDEBAR" className="pb-10">
      {cityHallPublished && (
        <div className="w-full flex align-middle bg-neutral-100 text-neutral-400 text-xs py-3 px-8">
          <Icon className="mr-2">
            <MdCheck />
          </Icon>
          <FormattedMessage {...messages.sharedWithCityHall} />
        </div>
      )}
      <div className="flex flex-wrap justify-start items-center gap-2 mt-8 mb-2 px-8">
        <OperationalStateBadge
          operationalState={operationalState}
          className="text-xs"
        />
        {services && <ServiceOutOfMileageBadge {...services} />}
        {technicalInspections && (
          <TechnicalInspectionOutOfTimeBadge {...technicalInspections} />
        )}
        {operationalConditions
          .filter(
            (operationalCondition) => operationalCondition != 'OPERATIONAL'
          )
          .map((operationalCondition) => (
            <OperationalConditionBadge
              key={operationalCondition}
              className="text-xs"
              operationalCondition={operationalCondition}
            />
          ))}
      </div>
      <div className="px-8 mt-6">
        <div className="mb-7 flex items-center gap-2">
          <Tooltip
            button={
              <ModelImage
                className="h-16 w-auto -ml-3"
                model={model}
                identificationNumber={identificationNumber}
              />
            }
            content={
              <div className="px-5 py-4">
                <Pair.Label>
                  <FormattedMessage {...messages.model} />
                </Pair.Label>
                <Pair.Value>
                  <ModelName model={model} />
                </Pair.Value>
              </div>
            }
          />
          <div className="flex flex-col">
            <SidebarHeading className="text-xl mt-4">
              {externalId}
            </SidebarHeading>
            <span className="text-xs text-neutral-500">{modelSuffix()}</span>
          </div>
        </div>

        <Mileage vehicleId={vehicleId} />
        <ServiceMileage
          nextMileageTo={services?.mileageToService}
          remainingMileageTo={services?.remainingMileageToService}
          label={<FormattedMessage {...messages.nextService} />}
        />
        <TechnicalInspectionScheduledAt
          dateToTechnicalInspection={
            technicalInspections?.dateToTechnicalInspection || null
          }
          remainingDaysToTechnicalInspection={
            technicalInspections?.remainingDaysToTechnicalInspection !=
            undefined
              ? technicalInspections.remainingDaysToTechnicalInspection
              : null
          }
          label={<FormattedMessage {...messages.nextInspection} />}
        />
        <SidebarLicensePlateItem className="mb-2" licensePlate={licensePlate} />
        <SidebarItem
          className="mb-2"
          copyValue={identificationNumber}
          value={identificationNumber}
          label={<FormattedMessage {...messages.vehicleIdentificationNumber} />}
        />
        <SidebarItem
          className="mb-2"
          copyValue={telematicsId}
          value={telematicsId}
          label={<FormattedMessage {...messages.telematics} />}
        />
        <SidebarItem copyValue={vehicleId} className="mb-6" value={vehicleId} />
        <div className="flex mb-9 items-center">
          <SidebarIconItem
            icon={<MdLocationOn />}
            label={<SystemCity system={system} />}
          />
        </div>
      </div>
      <ul>
        <li>
          <Divider />
          <RouterSidebarNavLink to={`/vehicles/${vehicleId}`} end>
            <SidebarLinkContent>
              <FormattedMessage {...messages.overview} />
            </SidebarLinkContent>
          </RouterSidebarNavLink>
          <Divider />
        </li>
        <li>
          <RouterSidebarNavLink to="info">
            <SidebarLinkContent>
              <FormattedMessage {...messages.vehicleInformation} />
            </SidebarLinkContent>
          </RouterSidebarNavLink>
          <Divider />
        </li>
        {hasPermission('read:tasks') ? (
          <li>
            <RouterSidebarNavLink to="tasks">
              <SidebarLinkContent>
                <FormattedMessage {...messages.tasks} />
              </SidebarLinkContent>
            </RouterSidebarNavLink>
            <Divider />
          </li>
        ) : null}
        {areRentalsEnabled && (
          <>
            <li>
              <RouterSidebarNavLink to="rentals">
                <SidebarLinkContent>
                  <FormattedMessage {...messages.rentals} />
                </SidebarLinkContent>
              </RouterSidebarNavLink>
              <Divider />
            </li>
            <li>
              <RouterSidebarNavLink to="reservations">
                <SidebarLinkContent>
                  <FormattedMessage {...messages.reservations} />
                </SidebarLinkContent>
              </RouterSidebarNavLink>
              <Divider />
            </li>
          </>
        )}
        {hasPermission('read:problem_reports') && (
          <li>
            <RouterSidebarNavLink to="problem-reports">
              <SidebarLinkContent>
                <FormattedMessage {...messages.reports} />
              </SidebarLinkContent>
            </RouterSidebarNavLink>
            <Divider />
          </li>
        )}
        <li>
          <RouterSidebarNavLink to="maintenance/services">
            <SidebarLinkContent>
              <FormattedMessage {...messages.services} />
            </SidebarLinkContent>
          </RouterSidebarNavLink>
          <Divider />
        </li>
      </ul>
    </div>
  );
};
