import { Divider, Placeholder } from '@cooltra/ui';
import { Fragment } from 'react';
import { consecutiveNumbers } from '@cooltra/utils';

export type SidebarLinksLoadingProps = {
  linksNumber: number;
};

export const SidebarLinksLoading = ({
  linksNumber,
}: SidebarLinksLoadingProps) => (
  <div>
    <Divider />
    {consecutiveNumbers(linksNumber).map((num) => (
      <Fragment key={`sidebar-link-loading-${num}`}>
        <Placeholder className="h-4 w-44 my-5 mx-8" />
        <Divider />
      </Fragment>
    ))}
  </div>
);
