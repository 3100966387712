import { Placeholder } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';

export type ValueLoadingProps = HTMLAttributes<HTMLSpanElement>;

export const ValueLoading = ({ className, ...rest }: ValueLoadingProps) => (
  <div
    className={classNames('h-5', 'flex', 'items-center', className)}
    {...rest}
  >
    <Placeholder className="h-2 w-24" />
  </div>
);
