import { FormattedMessage } from 'react-intl';
import { ContractPayment } from '@cooltra/station-based-api';
import { classNames } from '@cooltra/utils';

import { Amount } from '~/common';

import messages from './messages';

export type TotalProps = {
  className?: string;
  payment?: ContractPayment;
};

export const Total = ({ className, payment }: TotalProps) => {
  if (!payment) return null;

  return (
    <div className={classNames('flex flex-col gap-2', className)}>
      {payment.totalOriginalPrice && !payment.deposit && (
        <div className="w-full flex justify-between my-2 gap-8 text-neutral-800">
          <div>
            <FormattedMessage {...messages.totalOriginalPrice} />
          </div>
          <div className="relative">
            <Amount {...payment.totalOriginalPrice} />
          </div>
        </div>
      )}
      {payment.couponDiscount && (
        <div className="w-full flex justify-between my-2 gap-8 text-neutral-800">
          <div>
            <FormattedMessage {...messages.couponDiscount} />
          </div>
          <div className="relative">
            <Amount {...payment.couponDiscount} />
          </div>
        </div>
      )}
      <div className="flex justify-between gap-8">
        <h3 className="text-xl text-neutral-800">
          <FormattedMessage {...messages.total} />
        </h3>
        <span className="text-xl font-semibold text-neutral-800">
          <Amount {...payment.totalPrice} />
        </span>
      </div>
    </div>
  );
};
