import {
  MouseEventHandler,
  ImgHTMLAttributes,
  ChangeEvent,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { MdOutlineChevronRight, MdOpenInFull } from 'react-icons/md';
import { UploadButton, Icon, Image } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { useFetchDocument, useDocumentMutation } from '@cooltra/api';
import { useField, useFormContext } from '@cooltra/form';

import { useNotification, useRotation } from '~/hooks';
import { PhotoIcon, PhotoModal, PhotoSide, PhotoSideLabel } from '~/common';
import { readFile } from '~/utils/file';

import { photoSize } from './photo-classes';
import messages from './messages';

export type PhotoProps = ImgHTMLAttributes<HTMLImageElement> & {
  name: string;
  side?: PhotoSide;
};

const hasChangedAxis = (rotation: number) =>
  rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;

const getPhotoClasses = (rotation: number) => {
  const constrainedDimension = hasChangedAxis(rotation) ? 'width' : 'height';
  return classNames(
    'object-contain',
    photoSize[constrainedDimension],
    constrainedDimension === 'height' ? 'max-w-full' : 'max-h-full'
  );
};

export const Photo = ({ name, className, side, ...rest }: PhotoProps) => {
  const { value, setValue } = useField(name);
  const { isSubmitting, isFormDisabled } = useFormContext();
  const [uploadedImageData, setUploadedImageData] = useState('');
  const fetchedImageData = useFetchDocument(value);
  const mutation = useDocumentMutation();

  const { addErrorNotification } = useNotification();

  const { formatMessage } = useIntl();

  const [isOpen, { toggleOff, toggleOn }] = useToggle();
  const openModal: MouseEventHandler<HTMLButtonElement> = (e) => {
    // prevent the modal from focusing on the last active element
    // otherwise the photo toolbar is being jerked up and down upon closing
    e.currentTarget.blur();
    toggleOn();
  };

  const { rotation, rotationClassName, rotateLeft, rotateRight } =
    useRotation();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    openModal(e);
  };

  const handleFileUpload = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const file = target?.files?.[0];
    if (file) {
      readFile(file)
        .then((blob) => {
          const formData = new FormData();
          formData.append('image', file);
          return Promise.all([blob, mutation.mutateAsync(formData)]);
        })
        .then(([blob, { id }]) => {
          setUploadedImageData(blob);
          setValue(id);
        })
        .catch(() =>
          addErrorNotification(formatMessage(messages.uploadingError))
        );
    }
  };

  const imageData = uploadedImageData
    ? uploadedImageData
    : fetchedImageData
      ? `data:image/jpeg;base64,${fetchedImageData}`
      : '';

  return (
    <div className={classNames(photoSize.height, className)}>
      <div
        tabIndex={0}
        className="group relative bg-neutral-100 h-full outline-none"
      >
        <div className="h-full flex items-center justify-center">
          {value && (
            <Image
              src={imageData}
              className={classNames(
                getPhotoClasses(rotation),
                rotationClassName
              )}
              {...rest}
            />
          )}
        </div>
        {side !== undefined && (
          <div className="absolute top-2 right-2">
            <PhotoSideLabel side={side} />
          </div>
        )}
        <div className="opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 transition-opacity">
          {imageData && (
            <button
              type="button"
              onClick={handleClick}
              className="flex items-start justify-start h-full w-full absolute inset-0 p-2"
            >
              <PhotoIcon aria-label={formatMessage(messages.fullScreen)}>
                <MdOpenInFull />
              </PhotoIcon>
            </button>
          )}
          <div className="absolute bottom-2 right-2">
            <UploadButton
              accept=".png,.jpg,.jpeg"
              disabled={isFormDisabled || isSubmitting}
              id={`upload-${name}-image`}
              loading={mutation.isPending}
              trailingIcon={
                <Icon className="mt-0.5 text-lg">
                  <MdOutlineChevronRight />
                </Icon>
              }
              onChange={handleFileUpload}
              size="sm"
            >
              {imageData ? (
                <FormattedMessage {...messages.changeImage} />
              ) : (
                <FormattedMessage {...messages.uploadImage} />
              )}
            </UploadButton>
          </div>
        </div>
      </div>
      <PhotoModal
        isOpen={isOpen}
        onRequestClose={toggleOff}
        rotateLeft={rotateLeft}
        rotateRight={rotateRight}
        className={rotationClassName}
        imageData={imageData}
        {...rest}
      />
    </div>
  );
};
