import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Card, Divider } from '@cooltra/ui';

import { UserLifespan } from '../UserLifespan/UserLifespan';
import { UserProblemReportsTotal } from '../UserProblemReportsTotal/UserProblemReportsTotal';

export const ReportsTotalAndLifespan = () => {
  const { hasPermission } = useAuthClaimsQuery();

  return (
    <Card className="p-6 h-full">
      <div className="flex gap-6 h-full">
        {hasPermission('read:problem_reports') && (
          <>
            <UserProblemReportsTotal />
            <Divider direction="vertical" />
          </>
        )}
        <UserLifespan />
      </div>
    </Card>
  );
};
