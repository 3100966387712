import { defineMessages } from 'react-intl';

export default defineMessages({
  fullScreen: {
    defaultMessage: 'Full screen',
  },
  changeImage: {
    defaultMessage: 'Change image',
  },
  uploadImage: {
    defaultMessage: 'Upload image',
  },
  uploadingError: {
    defaultMessage: "Something went wrong! We couldn't upload the image",
  },
});
