import { LanguageCode } from '@cooltra/countries';
import { defineMessages } from 'react-intl';

export const headings = defineMessages<LanguageCode>({
  en: {
    defaultMessage: 'English content',
  },
  es: {
    defaultMessage: 'Spanish content',
  },
  fr: {
    defaultMessage: 'French content',
  },
  it: {
    defaultMessage: 'Italian content',
  },
  pt: {
    defaultMessage: 'Portuguese content',
  },
  ca: {
    defaultMessage: 'Catalan content',
  },
  nl: {
    defaultMessage: 'Dutch content',
  },
});

export const titleLabels = defineMessages<LanguageCode>({
  en: {
    defaultMessage: 'English title',
  },
  es: {
    defaultMessage: 'Spanish title',
  },
  fr: {
    defaultMessage: 'French title',
  },
  it: {
    defaultMessage: 'Italian title',
  },
  pt: {
    defaultMessage: 'Portuguese title',
  },
  ca: {
    defaultMessage: 'Catalan title',
  },
  nl: {
    defaultMessage: 'Dutch title',
  },
});

export const descriptionLabels = defineMessages<LanguageCode>({
  en: {
    defaultMessage: 'English description',
  },
  fr: {
    defaultMessage: 'French description',
  },
  it: {
    defaultMessage: 'Italian description',
  },
  es: {
    defaultMessage: 'Spanish description',
  },
  pt: {
    defaultMessage: 'Portuguese description',
  },
  ca: {
    defaultMessage: 'Catalan description',
  },
  nl: {
    defaultMessage: 'Dutch description',
  },
});
