import { IncidentColumnLabel } from './IncidentColumnLabel/IncidentColumnLabel';
import { IncidentRowLayout } from './IncidentRowLayout';

export const IncidentsListHeader = () => (
  <IncidentRowLayout
    incident={<IncidentColumnLabel name="incident" />}
    assignee={<IncidentColumnLabel name="assignee" />}
    lastUpdated={<IncidentColumnLabel name="updated" />}
    context={<IncidentColumnLabel name="context" className="text-center" />}
  />
);
