import { Contract } from '@cooltra/station-based-api';
import { FormattedMessage } from 'react-intl';
import { MdError } from 'react-icons/md';
import { PropsWithChildren } from 'react';

import { Amount } from '~/common';
import { useContractFlags } from '~/hooks';

import messages from './messages';

const Title = () => (
  <span className="text-neutral-500 text-xs">
    <FormattedMessage {...messages.deposit} />
  </span>
);

const WarningIcon = () => (
  <MdError
    className="text-warning-500 text-xl"
    data-testid="PENDING_DEPOSIT_ACTION"
  />
);

const Container = (props: PropsWithChildren) => (
  <div
    className="flex flex-col"
    data-testid="NAVBAR_DEPOSIT_SECTION"
    {...props}
  />
);

const AmountText = (props: PropsWithChildren) => (
  <span className="text-neutral-800 text-xl font-semibold" {...props} />
);

export type DepositAmountProps = {
  contract: Contract;
};

export const DepositAmount = ({ contract }: DepositAmountProps) => {
  const { deposit, coverage, status } = contract;
  const {
    requiresDeposit,
    hasDepositBeenWithheld,
    shouldReleaseDeposit,
    hasDepositBeenReleasedAutomatically,
  } = useContractFlags();

  if (
    hasDepositBeenReleasedAutomatically ||
    ((status === 'ACTIVE' || status === 'CLOSED') && !!deposit?.releasedAt)
  ) {
    return (
      <Container>
        <Title />
        <AmountText>
          <Amount value={0} currency="EUR" />
        </AmountText>
      </Container>
    );
  }

  if (hasDepositBeenWithheld) {
    return (
      <Container>
        <Title />
        <AmountText>
          <span className="flex items-center gap-1">
            <Amount {...deposit!.amount} />
            {shouldReleaseDeposit && <WarningIcon />}
          </span>
        </AmountText>
      </Container>
    );
  }

  if (coverage?.deposit) {
    return (
      <Container>
        <Title />
        <AmountText>
          <span className="flex items-center gap-1">
            <Amount {...coverage.deposit} />
            {requiresDeposit && <WarningIcon />}
          </span>
        </AmountText>
      </Container>
    );
  }

  return (
    <Container>
      <Title />
      <AmountText>-</AmountText>
    </Container>
  );
};
