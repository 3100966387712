import { Button } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';
import { useDeleteMaasUserMutation } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { useToggle } from '@cooltra/hooks';
import { getZeusErrorMessage } from '@cooltra/axios';

import { useNotification } from '~/hooks';
import { DeleteModal } from '~/common';

import messages from './messages';

type DeleteUserProps = {
  email: string | null;
};

export const DeleteUser = ({ email }: DeleteUserProps) => {
  const { formatMessage } = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const { id = '' } = useParams<'id'>();
  const navigate = useNavigate();

  const { hasPermission } = useAuthClaimsQuery();

  const { mutateAsync, isPending } = useDeleteMaasUserMutation(id, '');
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleConfirm = () =>
    mutateAsync()
      .then(() => {
        toggleOff();
        addSuccessNotification(formatMessage(messages.success));
        navigate(`/users/search?keyword=${email}`);
      })
      .catch((error) => {
        toggleOff();
        const zeusErrorMessage = getZeusErrorMessage(error);
        addErrorNotification(zeusErrorMessage);
      });

  return (
    <div className="flex gap-4">
      {hasPermission('delete:users') && (
        <>
          <DeleteModal
            onClose={toggleOff}
            onConfirm={handleConfirm}
            isOpen={isOpen}
            isDeleting={isPending}
          />
          <Button variant="danger" emphasis="low" onClick={toggleOn}>
            <FormattedMessage {...messages.deleteBtn} />
          </Button>
        </>
      )}
    </div>
  );
};
