import { Card, Divider } from '@cooltra/ui';

import { PriceListFields } from './Form/Fields';
import { VehicleCategory } from './Form';

type DisabledVehicleTypes = {
  enabledVehicleCategories: VehicleCategory[];
};

export const DepositsPriceListDetailsFormFields = ({
  enabledVehicleCategories,
}: DisabledVehicleTypes) => {
  return (
    <Card className="pt-12 mt-8">
      <div className="px-12">
        <PriceListFields.VehicleCategory
          vehicleCategory="EBIKE"
          shouldBeEnabled={enabledVehicleCategories.includes('EBIKE')}
        />
        <Divider />
        <PriceListFields.VehicleCategory
          vehicleCategory="STANDARD"
          shouldBeEnabled={enabledVehicleCategories.includes('STANDARD')}
        />
        <Divider />
        <PriceListFields.VehicleCategory
          vehicleCategory="PLUS"
          shouldBeEnabled={enabledVehicleCategories.includes('PLUS')}
        />
        <Divider />
        <PriceListFields.VehicleCategory
          vehicleCategory="PREMIUM"
          shouldBeEnabled={enabledVehicleCategories.includes('PREMIUM')}
        />
        <Divider />
        <PriceListFields.VehicleCategory
          vehicleCategory="SUPER_PREMIUM"
          shouldBeEnabled={enabledVehicleCategories.includes('SUPER_PREMIUM')}
        />
      </div>
    </Card>
  );
};
