import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { useServicePointsQuery } from '@cooltra/station-based-api';
import { useField, useFormContext } from '@cooltra/form';
import {
  Button,
  InputError,
  InputLabel,
  Modal,
  SelectField,
} from '@cooltra/ui';

import { VehicleFormValues } from '../../vehicle-form';

import messages from './messages';

export const ServicePoint = () => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const [tempServicePointId, setTempServicePointId] = useState<string | null>(
    null
  );
  const { isSubmitting, isFormDisabled, setValues, values } =
    useFormContext<VehicleFormValues>();

  const { value, error, touched, setTouched } =
    useField<string>('servicePointId');

  const { data: servicePoints = [] } = useServicePointsQuery({
    enabled: false,
  });

  const changeServicePointAndClearData = () => {
    if (!tempServicePointId) return;

    setValues({
      ...values,
      servicePointId: tempServicePointId,
      geolocation: null,
      parkingPhoto: null,
    });
    setTempServicePointId(null);
    toggleOff();
  };

  const changeServicePoint = () => {
    if (!tempServicePointId) return;

    setValues({
      ...values,
      servicePointId: tempServicePointId,
    });
    setTempServicePointId(null);
    toggleOff();
  };

  const handleServicePointChange = (servicePointId: string) => {
    if (servicePointId === values.servicePointId) return;

    setTempServicePointId(servicePointId);

    if (values.parkingPhoto || values.geolocation) {
      toggleOn();
    } else {
      setValues({
        ...values,
        servicePointId: servicePointId,
      });
    }
  };

  const servicePointsOptions = servicePoints
    ? servicePoints
        .map(({ name, id }) => ({
          label: name,
          value: id,
        }))
        .sort((servicePoint, nextServicePoint) =>
          servicePoint.label.localeCompare(nextServicePoint.label)
        )
    : [];

  return (
    <>
      <Modal
        className="max-w-lg min-w-xs"
        isOpen={isOpen}
        onRequestClose={toggleOff}
      >
        <div className="text-center pb-8 px-8">
          <h6 className="text-neutral-700 text-lg font-semibold mb-2">
            <FormattedMessage {...messages.modalTitle} />
          </h6>
          <p className="text-neutral-600 text-sm mb-8">
            <FormattedMessage {...messages.modalContent} />
          </p>
          <div className="flex justify-center gap-4">
            <Button onClick={changeServicePoint}>
              <FormattedMessage {...messages.resetServicePointButton} />
            </Button>
            <Button
              emphasis="high"
              disabled={!tempServicePointId}
              onClick={changeServicePointAndClearData}
            >
              <FormattedMessage {...messages.resetEverything} />
            </Button>
          </div>
        </div>
      </Modal>
      <SelectField
        id="service-point-id"
        label={
          <InputLabel htmlFor="service-point-id">
            <FormattedMessage {...messages.servicePoint} />
          </InputLabel>
        }
        name="servicePointId"
        key={`servicePointId-${value}`}
        className="flex-1"
        options={servicePointsOptions}
        error={touched && error ? <InputError>{error}</InputError> : undefined}
        onChange={handleServicePointChange}
        value={value}
        onBlur={() => setTouched(true)}
        disabled={isSubmitting || isFormDisabled}
      />
    </>
  );
};
