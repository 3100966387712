import { IncidentColumnLabel } from '~/common';

import { RentalIncidentRowLayout } from './RentalIncidentRowLayout';

export const RentalIncidentsListHeader = () => (
  <RentalIncidentRowLayout
    incident={<IncidentColumnLabel name="incident" />}
    assignee={<IncidentColumnLabel name="assignee" />}
    lastUpdated={<IncidentColumnLabel name="updated" />}
  />
);
