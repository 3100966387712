import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Photos',
  },
  arePhotosValid: {
    defaultMessage: 'Are these photos valid?',
  },
  isFraud: {
    defaultMessage: 'Are they fraudulent?',
  },
  isValidSelfie: {
    defaultMessage: 'Is it a valid selfie?',
  },
  isValidIdPhoto: {
    defaultMessage: 'Is it a valid photo of the ID?',
  },
  isValidDriverLicensePhoto: {
    defaultMessage: 'Is it a valid photo of the driver license?',
  },
  valid: {
    defaultMessage: 'Valid',
  },
  invalid: {
    defaultMessage: 'Invalid',
  },
  fraudulent: {
    defaultMessage: 'Fraudulent',
  },
  legitimate: {
    defaultMessage: 'Legitimate',
  },
  yes: {
    defaultMessage: 'Yes',
  },
  no: {
    defaultMessage: 'No',
  },
  ignore: {
    defaultMessage: 'Ignore',
  },
  tooltipBody: {
    defaultMessage: 'The photos are valid if they meet these criteria:',
  },
  tooltipItemOne: {
    defaultMessage: 'It is the same person on all of the images',
  },
  tooltipItemTwo: {
    defaultMessage:
      'They are of good quality and all the necessary information can be read',
  },
  tooltipItemThree: {
    defaultMessage: 'There is no suspicion of fraud',
  },
});
