import { useState } from 'react';
import { useIntl } from 'react-intl';
import { classNames } from '@cooltra/utils';
import { UserPhotos } from '@cooltra/api';

import { UserPhoto } from '../../UserPhoto';

import messages from './messages';

export type PhotosGridProps = {
  photos: UserPhotos;
  isDriverLicenseIgnored: boolean;
};

export const PhotosGrid = ({
  photos: { idCardFront, idCardBack, driverLicenseFront, driverLicenseBack },
  isDriverLicenseIgnored,
}: PhotosGridProps) => {
  const [largePhoto, setLargePhoto] = useState('');
  const { formatMessage } = useIntl();

  const handlePhotoClick = (photo: string) => () =>
    setLargePhoto((currentPhoto) => (currentPhoto === photo ? '' : photo));

  const getDisplayClassName = (photo: string) =>
    largePhoto === photo || !largePhoto ? 'block' : 'hidden';

  return (
    <div
      className={classNames(
        largePhoto ? 'grid-cols-1' : 'grid-cols-2',
        'grid gap-2'
      )}
    >
      {idCardFront && (
        <UserPhoto
          className={getDisplayClassName(idCardFront)}
          onClick={handlePhotoClick(idCardFront)}
          alt={formatMessage(messages.idCardFront)}
          imageKey={idCardFront}
        />
      )}
      {idCardBack && (
        <UserPhoto
          className={getDisplayClassName(idCardBack)}
          onClick={handlePhotoClick(idCardBack)}
          alt={formatMessage(messages.idCardBack)}
          imageKey={idCardBack}
        />
      )}
      {driverLicenseFront && !isDriverLicenseIgnored && (
        <UserPhoto
          className={getDisplayClassName(driverLicenseFront)}
          onClick={handlePhotoClick(driverLicenseFront)}
          alt={formatMessage(messages.driverLicenseFront)}
          imageKey={driverLicenseFront}
        />
      )}
      {driverLicenseBack && !isDriverLicenseIgnored && (
        <UserPhoto
          className={getDisplayClassName(driverLicenseBack)}
          onClick={handlePhotoClick(driverLicenseBack)}
          alt={formatMessage(messages.driverLicenseBack)}
          imageKey={driverLicenseBack}
        />
      )}
    </div>
  );
};
