import { FormattedMessage } from 'react-intl';

import { ListTotal, ListTotalProps } from '~/common';

import messages from './messages';

export type PassesTotalProps = Omit<ListTotalProps, 'children'> & {
  total: number;
};

export const PassesTotal = ({ total, loading, ...rest }: PassesTotalProps) => (
  <ListTotal loading={loading} {...rest}>
    <FormattedMessage
      {...messages.passes}
      values={{
        total,
      }}
    />
  </ListTotal>
);
