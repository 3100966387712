import { Promotion } from '@cooltra/station-based-api';
import { format } from 'date-fns';

import { dateFormat, timeFormat } from '~/utils/date';
import {
  EnableGenericAppliesTo,
  getStationBasedStateType,
  StationBasedFormValues,
} from '~/common';

export const getFormValuesFromPromotion = (
  promotion: Promotion
): StationBasedFormValues => {
  const stateType = getStationBasedStateType({
    validFrom: promotion.validFrom,
    validUntil: promotion.validUntil,
  });
  let enableGenericAppliesTo: EnableGenericAppliesTo = 'enableVehicleTypes';

  if (promotion.accessories) {
    enableGenericAppliesTo = 'enableAccessories';
  }

  if (promotion.insurances) {
    enableGenericAppliesTo = 'enableInsurances';
  }

  return {
    'credit.value': promotion.credit?.value || '',
    couponType:
      promotion.target === 'COUPON'
        ? promotion.discount
          ? 'DISCOUNT'
          : 'CREDIT'
        : '',
    about: promotion.about || '',
    code: promotion.code || '',
    discount: promotion.discount || '',
    promotionTarget: promotion.target,
    stateType,
    validFromDate: format(new Date(promotion.validFrom), dateFormat),
    validFromTime: format(new Date(promotion.validFrom), timeFormat),
    validUntilDate: format(new Date(promotion.validUntil), dateFormat),
    validUntilTime: format(new Date(promotion.validUntil), timeFormat),
    accessories: promotion.accessories || [],
    enableAccessories: promotion.accessories
      ? 'ACCESSORIES_YES'
      : 'ACCESSORIES_NO',
    insurances: promotion.insurances || [],
    enableInsurances: promotion.insurances ? 'INSURANCES_YES' : 'INSURANCES_NO',
    pricingGroups: promotion.pricingGroups,
    enableVehicleTypes: promotion.vehicleTypes
      ? 'VEHICLE_TYPES_YES'
      : 'VEHICLE_TYPES_NO',
    vehicleTypes: promotion.vehicleTypes || [],
    'titleTranslations.en': promotion.titleTranslations.en
      ? promotion.titleTranslations.en
      : '',
    'titleTranslations.es': promotion.titleTranslations.es
      ? promotion.titleTranslations.es
      : '',
    'titleTranslations.it': promotion.titleTranslations.it
      ? promotion.titleTranslations.it
      : '',
    'titleTranslations.pt': promotion.titleTranslations.pt
      ? promotion.titleTranslations.pt
      : '',
    'titleTranslations.fr': promotion.titleTranslations.fr
      ? promotion.titleTranslations.fr
      : '',
    'titleTranslations.ca': promotion.titleTranslations.ca
      ? promotion.titleTranslations.ca
      : '',
    'titleTranslations.de': promotion.titleTranslations.de
      ? promotion.titleTranslations.de
      : '',
    enableGenericAppliesTo,
    editMode: true,
  };
};
