import { Routes, Route } from 'react-router-dom';

import { VehiclesFiltersForm } from '~/libs/vehicles-filters';

import { OpsMap } from '../OpsMap';
import { Alarms } from '../Alarms';
import { Vehicle } from '../Vehicle';

import { VehiclesList } from './VehiclesList/VehiclesList';

export const Vehicles = () => (
  <Routes>
    <Route
      path="/"
      element={
        <VehiclesFiltersForm>
          <VehiclesList />
        </VehiclesFiltersForm>
      }
    />
    <Route path="/map" element={<OpsMap />} />
    <Route path="/alarms/*" element={<Alarms />} />
    <Route path="/:vehicleId/*" element={<Vehicle />} />
  </Routes>
);
