import { format } from 'date-fns';
import { Pack } from '@cooltra/api';

import { dateFormat, timeFormat } from '~/utils/date';

import { PackFormValues } from '../PackForm';

export const parsePack = ({
  code,
  price,
  credit,
  validFrom,
  validUntil,
  benefitUntil,
  benefitExpiresAfterDays,
  maxPurchasesPerUser,
  targetHomeSystems,
  titleTranslations,
  descriptionTranslations,
  targetUserIds,
}: Pack): PackFormValues => ({
  code: code,
  price: price.value.toString(),
  credit: credit.value.toString(),
  validFromDate: validFrom ? format(validFrom, dateFormat) : '',
  validFromTime: validFrom ? format(validFrom, timeFormat) : '',
  validUntilDate: validUntil ? format(validUntil, dateFormat) : '',
  validUntilTime: validUntil ? format(validUntil, timeFormat) : '',
  packDoesntExpire: validUntil === null,
  benefitDoesntExpire: !benefitUntil && !benefitExpiresAfterDays,
  benefitUntilDate: benefitUntil ? format(benefitUntil, dateFormat) : '',
  benefitUntilTime: benefitUntil ? format(benefitUntil, timeFormat) : '',
  benefitExpiresAfterDays: benefitExpiresAfterDays
    ? benefitExpiresAfterDays.toString()
    : '',
  benefitExpirationMode: benefitExpiresAfterDays
    ? 'after-days'
    : benefitUntil
      ? 'specific-date'
      : '',
  maxPurchasesPerUser: maxPurchasesPerUser.toString(),
  targetHomeSystems: targetHomeSystems,
  targetUserIds: targetUserIds.join('\n'),
  'titleTranslations.en': titleTranslations.en,
  'titleTranslations.es': titleTranslations.es || '',
  'titleTranslations.it': titleTranslations.it || '',
  'titleTranslations.pt': titleTranslations.pt || '',
  'titleTranslations.fr': titleTranslations.fr || '',
  'titleTranslations.ca': titleTranslations.ca || '',
  'titleTranslations.nl': titleTranslations.nl || '',
  'descriptionTranslations.en': descriptionTranslations.en,
  'descriptionTranslations.es': descriptionTranslations.es || '',
  'descriptionTranslations.it': descriptionTranslations.it || '',
  'descriptionTranslations.pt': descriptionTranslations.pt || '',
  'descriptionTranslations.fr': descriptionTranslations.fr || '',
  'descriptionTranslations.ca': descriptionTranslations.ca || '',
  'descriptionTranslations.nl': descriptionTranslations.nl || '',
});
