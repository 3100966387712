import { defineMessages } from 'react-intl';

export default defineMessages({
  emptyTitle: {
    defaultMessage: 'No vehicles available',
  },
  emptyContent: {
    defaultMessage: 'There are no vehicles available to be assigned',
  },
  orSimilar: {
    defaultMessage: '{vehicleTypeName} or similar',
  },
  errorMsg: {
    defaultMessage: 'Unable to get available vehicles',
  },
  searchByLicensePlate: {
    defaultMessage: 'Search by license plate',
  },
});
