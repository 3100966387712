import { defineMessages } from 'react-intl';
import { ReservationState } from '@cooltra/api';

export default defineMessages<ReservationState>({
  USED: {
    defaultMessage: 'Used',
  },
  FAILED: {
    defaultMessage: 'Failed',
  },
  ACTIVE: {
    defaultMessage: 'Active',
  },
  CANCELED: {
    defaultMessage: 'Canceled',
  },
  EXPIRED: {
    defaultMessage: 'Expired',
  },
  CHECKING_IN: {
    defaultMessage: 'Checking-in',
  },
});
