import { defineMessages } from 'react-intl';

export default defineMessages({
  tryAgain: {
    defaultMessage: 'Retake',
  },
  useCurrent: {
    defaultMessage: 'Continue',
  },
  closeButton: {
    defaultMessage: 'Close camera',
  },
  takePicture: {
    defaultMessage: 'Take picture',
  },
});
