import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { Route, Routes, useParams } from 'react-router-dom';
import { SpinnerScreen } from '@cooltra/ui';

import { ErrorPage, Four0Four, Four0Three, SidebarLayout } from '~/common';
import { useVehicleQuery } from '~/hooks';

import { EditVehicleForm } from '../EditVehicle';
import { Sidebar, SidebarLoading } from '../Sidebar';
import { ViewVehicle } from '../ViewVehicle/ViewVehicle';
import { VehicleOverview } from '../VehicleOverview';
import { VehicleTasks } from '../VehicleTasks';
import { VehicleProblemReports } from '../VehicleProblemReports';
import { VehicleReservations } from '../VehicleReservations';
import { VehicleRentals } from '../VehicleRentals';
import { VehicleMaintenance } from '../VehicleMaintenance';

export const Vehicle = () => {
  const { vehicleId = '' } = useParams<'vehicleId'>();
  const { hasPermission } = useAuthClaimsQuery();
  const { data: vehicle, isError, error } = useVehicleQuery(vehicleId);

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  const editVehicle = vehicle ? (
    <EditVehicleForm vehicle={vehicle} />
  ) : (
    <SpinnerScreen />
  );

  const viewVehicle = vehicle ? (
    <ViewVehicle vehicle={vehicle} />
  ) : (
    <SpinnerScreen />
  );

  return (
    <SidebarLayout
      sidebar={
        <div>
          {vehicle ? <Sidebar vehicle={vehicle} /> : <SidebarLoading />}
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<VehicleOverview vehicleId={vehicleId} />} />
        <Route
          path="/info"
          element={hasPermission('write:vehicles') ? editVehicle : viewVehicle}
        />
        <Route
          path="/maintenance/*"
          element={
            vehicle && <VehicleMaintenance vehicleId={vehicle?.vehicleId} />
          }
        />
        <Route path="/tasks" element={<VehicleTasks />} />
        <Route path="/problem-reports" element={<VehicleProblemReports />} />
        <Route path="/reservations" element={<VehicleReservations />} />
        <Route path="/rentals" element={<VehicleRentals />} />
        <Route path="*" element={<Four0Four />} />
      </Routes>
    </SidebarLayout>
  );
};
