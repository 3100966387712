import { isSameMonth } from 'date-fns';
import { forwardRef, PropsWithChildren } from 'react';
import { CaptionProps, useNavigation } from 'react-day-picker';
import { useIntl } from 'react-intl';
import { Button } from '@cooltra/ui';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';

import messages from './messages';

export const CalendarNavigation = forwardRef<
  HTMLSpanElement,
  PropsWithChildren<CaptionProps>
>(({ children, displayMonth }, ref) => {
  const { formatMessage } = useIntl();

  const { displayMonths, goToMonth, nextMonth, previousMonth } =
    useNavigation();

  const [firstMonth, secondMonth] = displayMonths;

  const renderButton = () => {
    if (previousMonth && isSameMonth(firstMonth, displayMonth)) {
      return (
        <Button
          className="absolute left-0"
          emphasis="low"
          square
          onClick={(e) => {
            e.preventDefault();
            goToMonth(previousMonth);
          }}
          aria-label={formatMessage(messages.goToPreviousMonth)}
        >
          <MdArrowBackIosNew size={24} />
        </Button>
      );
    }

    if (nextMonth && isSameMonth(secondMonth, displayMonth)) {
      return (
        <Button
          className="absolute right-0"
          emphasis="low"
          square
          onClick={(e) => {
            e.preventDefault();
            goToMonth(nextMonth);
          }}
          aria-label={formatMessage(messages.goToNextMonth)}
        >
          <MdArrowForwardIos size={24} />
        </Button>
      );
    }

    return null;
  };

  return (
    <div className="relative flex h-10 w-full items-center mb-4 justify-center">
      {renderButton()}
      <span
        className="block text-lg font-semibold capitalize text-neutral-700"
        ref={ref}
      >
        {children}
      </span>
    </div>
  );
});
