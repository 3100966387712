import {
  usePatchIncidentMutation,
  useIncidentStatusMutation,
  Incident,
  useIncidentCommentMutation,
  useIncidentCommentsQuery,
} from '@cooltra/api';
import { ScrollToTop } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, FormProvider, FormButton, FormHelpers } from '@cooltra/form';

import {
  DividedCard,
  SystemPair,
  Log,
  ProblemReportPair,
  CommentFormValues,
  commentInitialFormValues,
  CommentForm,
  IssueFields,
  Context,
  ClosedBadge,
  IncidentBackLink,
} from '~/common';
import { useNotification } from '~/hooks';

import { IncidentFormValues, validateIncidentForm } from '../../incident-form';
import * as IncidentFields from '../../IncidentFields';
import { Comments } from '../../Comments/Comments';
import { ToggleStatusButton } from '../ToggleStatusButton/ToggleStatusButton';

import messages from './messages';

export type EditIncidentProps = {
  incident: Incident;
};

export const EditIncident = ({ incident }: EditIncidentProps) => {
  const intl = useIntl();

  const {
    incidentId,
    open = true,
    createdBy,
    createdAt,
    updatedAt,
    updatedBy,
    problemReportId,
    rentalId,
    system,
    title,
    description,
    operatorGroupId,
    operatorId,
  } = incident;

  const incidentMutation = usePatchIncidentMutation(incidentId);

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleSubmit = (
    values: IncidentFormValues,
    { resetForm }: FormHelpers<IncidentFormValues>
  ) =>
    incidentMutation
      .mutateAsync({
        system,
        ...values,
      })
      .then(() => {
        resetForm(values);
        addSuccessNotification(intl.formatMessage(messages.success));
      })
      .catch(() => addErrorNotification());

  const incidentStatusMutation = useIncidentStatusMutation(incidentId);

  const handleTogglingIncidentStatus = () =>
    incidentStatusMutation
      .mutateAsync({ open: !open, system })
      .catch(() => addErrorNotification());

  const { mutateAsync } = useIncidentCommentMutation(incidentId);

  const { data: comments } = useIncidentCommentsQuery(incidentId);

  const handlePostingComment = (
    values: CommentFormValues,
    { resetForm }: FormHelpers<CommentFormValues>
  ) =>
    mutateAsync(values)
      .then(() => resetForm(commentInitialFormValues))
      .catch(() => addErrorNotification());

  return (
    <div className="pb-32">
      <ScrollToTop />
      <FormProvider
        initialValues={{
          title,
          description,
          operatorGroupId,
          operatorId,
        }}
        disabled={!open}
        validate={validateIncidentForm(intl)}
        onSubmit={handleSubmit}
      >
        <Form>
          <DividedCard
            className="min-w-4xl max-w-5xl py-12"
            data-testid="EDIT_INCIDENT"
            renderHeader={
              <>
                <div className="flex gap-8 justify-between items-end">
                  <div>
                    <IncidentBackLink problemReportId={problemReportId} />
                    <h2 className="text-3xl">
                      <FormattedMessage {...messages.title} />
                    </h2>
                  </div>
                  <div className="flex gap-4">
                    <ToggleStatusButton
                      onClick={handleTogglingIncidentStatus}
                      loading={incidentStatusMutation.isPending}
                      isTaskOpen={open}
                    />
                    {open && (
                      <FormButton>
                        <FormattedMessage {...messages.editIncident} />
                      </FormButton>
                    )}
                  </div>
                </div>
                {!open && (
                  <div className="mt-4">
                    <ClosedBadge />
                  </div>
                )}
              </>
            }
            renderLeft={
              <div className="flex h-full flex-col gap-12 justify-between">
                <div className="flex flex-col gap-6">
                  <Context rentalId={rentalId} />
                  <SystemPair system={system} />
                  {problemReportId && (
                    <ProblemReportPair problemReportId={problemReportId} />
                  )}
                </div>
                <div className="flex flex-col gap-6">
                  <Log
                    user="operator"
                    action="create"
                    userId={createdBy}
                    date={createdAt}
                  />
                  {updatedBy && updatedAt && (
                    <Log
                      user="operator"
                      action="update"
                      userId={updatedBy}
                      date={updatedAt}
                    />
                  )}
                </div>
              </div>
            }
            renderRight={
              <div className="flex flex-col gap-y-6 gap-x-8">
                <IncidentFields.Title system={system} />
                <div className="grid grid-cols-2 gap-x-8">
                  <IssueFields.Assignee />
                  <IssueFields.Operator systems={[system]} />
                </div>
                <IssueFields.Description disabled />
              </div>
            }
          />
        </Form>
      </FormProvider>
      <div className="container min-w-4xl max-w-5xl">
        <div className="grid grid-cols-3">
          <div className="col-span-1">
            <CommentForm
              disabled={!!comments && comments.length > 100}
              onSubmit={handlePostingComment}
            />
          </div>
          <div className="col-span-2 pl-14">
            <Comments incidentId={incidentId} />
          </div>
        </div>
      </div>
    </div>
  );
};
