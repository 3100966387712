import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type SessionRowLayout = HTMLAttributes<HTMLDivElement> & {
  date: ReactNode;
  deviceAndVersion: ReactNode;
};

export const SessionRowLayout = ({
  date,
  deviceAndVersion,
  className,
  ...rest
}: SessionRowLayout) => (
  <div
    className={classNames(
      'w-full grid grid-cols-3 px-5 h-full items-center',
      className
    )}
    {...rest}
  >
    <div className="col-span-1">{date}</div>
    <div className="col-span-2">{deviceAndVersion}</div>
  </div>
);
