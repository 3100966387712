import { defineMessages } from 'react-intl';

export default defineMessages({
  markInvoiceAsPaid: {
    defaultMessage: 'Mark invoice as paid',
  },
  comment: {
    defaultMessage: 'Comment',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  save: {
    defaultMessage: 'Save',
  },
  success: {
    defaultMessage: 'You have successfully marked the invoice as paid',
  },
});
