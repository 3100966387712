import { Card, Placeholder } from '@cooltra/ui';

import { VehicleRowLayout } from '../VehicleRowLayout';

export const VehicleLoadingCard = () => (
  <Card className="h-24">
    <VehicleRowLayout
      vehicle={
        <span className="flex flex-col gap-3">
          <Placeholder className="w-20 h-2" />
          <Placeholder className="w-28 h-2" />
        </span>
      }
      servicePoint={<Placeholder className="w-32 h-2" />}
    />
  </Card>
);
