import { RouterLink } from '@cooltra/navigation';
import { Vehicle } from '@cooltra/api';

import { ModelImage } from '../Model';

export type VehicleLinkProps = {
  vehicle: Pick<
    Vehicle,
    'vehicleId' | 'model' | 'identificationNumber' | 'externalId'
  >;
};

export const VehicleLink = ({
  vehicle: { vehicleId, model, identificationNumber, externalId },
}: VehicleLinkProps) => (
  <RouterLink
    className="inline-flex items-center text-sm font-medium"
    to={`/vehicles/${vehicleId}`}
  >
    <ModelImage
      className="w-10"
      model={model}
      identificationNumber={identificationNumber}
    />
    <span className="truncate inline-block mt-0.5">{externalId}</span>
  </RouterLink>
);
