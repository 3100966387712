import { FormattedMessage } from 'react-intl';
import {
  WorkShift,
  WorkShiftWorkUnit,
} from '@cooltra/api/src/modules/work-shifts/types';
import { VehicleRelease, VehicleTake } from '@cooltra/api';

import { sortByDate } from '~/utils/date';

import { VehicleReleasedRow } from '../VehicleReleasedRow/VehicleReleasedRow';
import { NoWorkAction } from '../NoWorkAction/NoWorkAction';
import { VehicleTakenRow } from '../VehicleTakenRow/VehicleTakenRow';
import { WorkUnitRow } from '../WorkUnitRow/WorkUnitRow';

import messages from './messages';

type ShiftTimelineProps = {
  workShift: WorkShift;
};

type TimelineItemAction = WorkShiftWorkUnit | VehicleRelease | VehicleTake;

type TimelineItem = {
  createdAt: string;
  action: TimelineItemAction;
};

function isWorkUnit(action: TimelineItemAction): action is WorkShiftWorkUnit {
  return (action as WorkShiftWorkUnit).workUnitId !== undefined;
}

function isTake(action: TimelineItemAction): action is VehicleTake {
  return (action as VehicleTake).reason !== undefined;
}

export const ShiftTimeline = ({ workShift }: ShiftTimelineProps) => {
  const items: TimelineItem[] = [
    ...workShift.workUnits.map((workUnit) => ({
      action: workUnit,
      createdAt: workUnit.createdAt,
    })),
    ...workShift.releases.map((release) => ({
      action: release,
      createdAt: release.createdAt,
    })),
    ...workShift.takes.map((take) => ({
      action: take,
      createdAt: take.createdAt,
    })),
  ].sort(sortByDate(false));

  return (
    <div className="py-10 pl-4 pr-8 bg-neutral-50">
      <div className="relative">
        {items.length > 0 && (
          <div className="h-full w-px absolute top-0 left-16 bg-neutral-200 ml-2" />
        )}
        <ul className="flex flex-col" data-testid="SHIFT_TIMELINE">
          {items.length === 0 ? (
            <p className="w-full mt-5 text-center text-neutral-500">
              <FormattedMessage {...messages.thereAreNoActions} />
            </p>
          ) : (
            <>
              {items.map((item, idx) => {
                const nextCreatedAt = items[idx + 1]?.action.createdAt;

                if (isWorkUnit(item.action)) {
                  const workUnitPosition = workShift.workUnits.indexOf(
                    item.action
                  );
                  return (
                    <>
                      <WorkUnitRow
                        key={item.action.workUnitId}
                        workUnit={item.action}
                        workUnitPosition={workUnitPosition}
                      />
                      {item.action.finishedAt && nextCreatedAt && (
                        <NoWorkAction
                          lastFinishedAt={item.action.finishedAt}
                          nextCreatedAt={nextCreatedAt}
                        />
                      )}
                      {!item.action.finishedAt && nextCreatedAt && (
                        <NoWorkAction
                          lastFinishedAt={item.action.createdAt}
                          nextCreatedAt={nextCreatedAt}
                        />
                      )}
                    </>
                  );
                }
                if (isTake(item.action)) {
                  return (
                    <>
                      <VehicleTakenRow
                        key={item.action.createdAt}
                        vehicleTake={item.action}
                      />
                      <NoWorkAction
                        lastFinishedAt={item.action.createdAt}
                        nextCreatedAt={nextCreatedAt}
                      />
                    </>
                  );
                }
                return (
                  <>
                    <VehicleReleasedRow
                      key={item.action.createdAt}
                      vehicleRelease={item.action}
                    />
                    <NoWorkAction
                      lastFinishedAt={item.action.createdAt}
                      nextCreatedAt={nextCreatedAt}
                    />
                  </>
                );
              })}
            </>
          )}
        </ul>
      </div>
    </div>
  );
};
