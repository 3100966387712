import { useAuth0 } from '@auth0/auth0-react';
import { setUser } from '@sentry/react';
import { useIntl } from 'react-intl';
import { MdLogout } from 'react-icons/md';

import messages from '../messages';

export const LogOut = () => {
  const { formatMessage } = useIntl();
  const { logout } = useAuth0();

  const handleLogOut = () => {
    setUser(null);
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <li>
      <button
        className="nav-link focus-visible:ring focus-visible:ring-primary-200 ring-inset"
        type="button"
        onClick={handleLogOut}
        aria-label={formatMessage(messages.logout)}
      >
        <span className="text-lg leading-0">
          <MdLogout />
        </span>
      </button>
    </li>
  );
};
