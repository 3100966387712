import {
  PromotionListItem,
  usePromotionsQuery,
  usePromotionsTotal,
} from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { useFormContext } from '@cooltra/form';
import { Card, InfiniteList } from '@cooltra/ui';
import { RouterButton } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { useEffect, useMemo } from 'react';

import {
  ErrorPage,
  Four0Three,
  NoSearchResults,
  ResetFiltersButton,
  SortFields,
} from '~/common';
import { useNavigateWithQueryParams } from '~/hooks';

import { PromotionsTotal } from '../Total/PromotionsTotal';
import { PromotionsListHeader } from '../ListHeader/PromotionsListHeader';
import { PromotionCard, PromotionCardLoading } from '../Card';
import {
  initialPromotionsFiltersFormValues,
  PromotionsFiltersFormValues,
} from '../promotions-filters-form';
import * as PromotionsFilterFields from '../FilterFields';
import { OffersNavigationTabs } from '../../../OffersNavigationTabs/OffersNavigationTabs';

import messages from './messages';

export const PromotionsList = () => {
  const navigateWithQueryParams = useNavigateWithQueryParams();

  const { hasPermission } = useAuthClaimsQuery();

  const { values } = useFormContext<PromotionsFiltersFormValues>();
  const valuesWithLimit = useMemo(
    () => ({
      ...values,
      limit: 20,
    }),
    [values]
  );

  const { data, isLoading, fetchNextPage, isFetchingNextPage, isError, error } =
    usePromotionsQuery(valuesWithLimit);

  useEffect(() => {
    navigateWithQueryParams(valuesWithLimit);
  }, [navigateWithQueryParams, valuesWithLimit]);

  const promotions = data?.pages
    ? ([] as PromotionListItem[]).concat.apply([], data.pages)
    : [];

  const promotionsTotal = usePromotionsTotal(valuesWithLimit) || 0;

  if (!hasPermission('read:offers')) {
    return <Four0Three />;
  }

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div className="container min-w-5xl max-w-6xl py-12">
      <div className="flex items-center justify-between mb-6">
        <OffersNavigationTabs />
        {hasPermission('write:offers') && (
          <RouterButton emphasis="high" to="/offers/promotions/new">
            <FormattedMessage {...messages.createPromotion} />
          </RouterButton>
        )}
      </div>
      <Card className="p-8 mb-10">
        <div className="grid grid-cols-3 gap-y-6 gap-x-8 mb-12">
          <PromotionsFilterFields.Code />
          <PromotionsFilterFields.State />
          <PromotionsFilterFields.OperatorGroupId />
          <PromotionsFilterFields.Type />
        </div>
        <div className="flex justify-end items-end">
          <ResetFiltersButton
            initialValues={initialPromotionsFiltersFormValues}
          />
        </div>
      </Card>
      <div className="flex justify-between items-center mb-12">
        <PromotionsTotal loading={isLoading} total={promotionsTotal} />
        <SortFields.CreatedAt />
      </div>
      <InfiniteList
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        fetchMore={fetchNextPage}
        hasMore={promotions.length < promotionsTotal}
        idProp="promotionId"
        data={promotions}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<PromotionsListHeader />}
        renderLoadingRow={<PromotionCardLoading />}
        renderRow={(promotion: PromotionListItem) => (
          <PromotionCard promotion={promotion} />
        )}
      />
    </div>
  );
};
