import { defineMessages } from 'react-intl';

import { LogAction } from './types';

export default defineMessages<LogAction>({
  create: {
    defaultMessage: 'Created on',
  },
  update: {
    defaultMessage: 'Updated on',
  },
  issue: {
    defaultMessage: 'Issued at',
  },
});
