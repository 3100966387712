import { InputError, Textarea } from '@cooltra/ui';
import { useField, useFormContext } from '@cooltra/form';

export type CommentFieldProps = {
  label: string;
};

export const CommentField = ({ label }: CommentFieldProps) => {
  const { isSubmitting, isFormDisabled } = useFormContext();
  const { value, setValue, setTouched, error, touched } = useField('comment');

  return (
    <div>
      <Textarea
        aria-label={label}
        onChange={({ target: { value } }) => setValue(value)}
        onBlur={() => setTouched(true)}
        value={value}
        rows={4}
        disabled={isFormDisabled || isSubmitting}
      />
      {touched && error && (
        <div className="flex flex-row items-end mt-1">
          <InputError>{error}</InputError>
        </div>
      )}
    </div>
  );
};
