import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { FormCheckboxField } from '@cooltra/form';

import messages from './messages';

export const CityHall = () => (
  <FormCheckboxField
    id="city-hall"
    label={
      <InputLabel emphasis="low" htmlFor="city-hall">
        <FormattedMessage {...messages.cityHall} />
      </InputLabel>
    }
    name="cityHallPublished"
  />
);
