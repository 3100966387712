import { Icon } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import { MdArrowDownward } from 'react-icons/md';

import { saveCsvFile } from '~/utils/file';
import { dateFormat } from '~/utils/date';

import messages from './messages';

type CodesToCSVProps = {
  codes?: string[];
  isLoading?: boolean;
};

export const CodesToCSV = ({ codes, isLoading }: CodesToCSVProps) => {
  if (isLoading) {
    return (
      <button className="link-primary text-xs" disabled>
        <Icon className="mr-1">
          <MdArrowDownward />
        </Icon>
        <FormattedMessage {...messages.loadingCodes} />
      </button>
    );
  }

  if (!codes || codes.length === 0) {
    return <></>;
  }

  const dataWithHeader = ['codes', ...codes];
  const downloadCodes = () =>
    saveCsvFile(
      `promotion-codes-${format(new Date(), dateFormat)}.csv`,
      dataWithHeader?.join('\n')
    );

  return (
    <button
      type="button"
      className="link-primary text-xs"
      onClick={downloadCodes}
    >
      <Icon className="mr-1">
        <MdArrowDownward />
      </Icon>
      <FormattedMessage {...messages.downloadCodes} />
    </button>
  );
};
