import { Divider } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type ShiftRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  operator: ReactNode;
  duration: ReactNode;
  summary: ReactNode;
};

export const ShiftRowLayout = ({
  operator,
  duration,
  summary,
  className,
  ...rest
}: ShiftRowLayoutProps) => (
  <div className={classNames('flex h-full w-full', className)} {...rest}>
    <div className="flex items-center w-full px-8 gap-4">
      <div className="flex-1">{operator}</div>
      <div className="flex-1">{duration}</div>
      <Divider direction="vertical" />
      <div className="w-80 shrink-0">{summary}</div>
    </div>
  </div>
);
