import { FormattedMessage, useIntl } from 'react-intl';
import { useReleaseVehicleMutation, Vehicle } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { AxiosError } from 'axios';
import { Button } from '@cooltra/ui';

import { useNotification } from '~/hooks';
import { useVehiclePolling } from '~/libs/polling-data';

import messages from './messages';

export type ReleaseProps = Pick<Vehicle, 'vehicleId'>;

export const Release = ({ vehicleId }: ReleaseProps) => {
  const { formatMessage } = useIntl();
  const { activatePollingVehicle } = useVehiclePolling();
  const { hasPermission } = useAuthClaimsQuery();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const { isPending, mutate } = useReleaseVehicleMutation(vehicleId, {
    onSuccess: () => {
      addSuccessNotification(formatMessage(messages.successfullyReleased));
      activatePollingVehicle(vehicleId);
    },
    onError: (error: AxiosError) => {
      let errorNotification;

      switch (getErrorStatus(error)) {
        case 400:
          errorNotification = formatMessage(messages.errorCouldNotBeReleased);
          break;
        case 401:
          errorNotification = formatMessage(
            messages.notAuthorizedToPerformReleaseOnVehicle
          );
          break;
        default:
          errorNotification = formatMessage(messages.somethingWentWrong);
          break;
      }

      addErrorNotification(errorNotification);
    },
  });

  const releaseVehicle = () => mutate();

  return (
    <div data-testid="RELEASE_VEHICLE">
      {hasPermission('transfer:vehicles') && (
        <Button
          size="sm"
          emphasis="high"
          loading={isPending}
          disabled={isPending}
          onClick={releaseVehicle}
        >
          <FormattedMessage {...messages.releaseVehicle} />
        </Button>
      )}
    </div>
  );
};
