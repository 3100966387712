import { Placeholder } from '@cooltra/ui';
import { useIntl } from 'react-intl';

import { PairValue } from './PairValue';
import messages from './messages';

type ActiveVehicleBonusItemProps = {
  totalVehicleBonus: number;
  isLoading: boolean;
};

export const ActiveVehicleBonusItem = ({
  totalVehicleBonus,
  isLoading,
}: ActiveVehicleBonusItemProps) => {
  const { formatMessage } = useIntl();

  if (isLoading) {
    return (
      <PairValue
        value={
          <div className="h-10 flex items-center">
            <Placeholder className="w-12 h-3" />
          </div>
        }
        title={formatMessage(messages.activeVehicleBonus)}
      />
    );
  }

  return (
    <PairValue
      value={totalVehicleBonus}
      title={formatMessage(messages.activeVehicleBonus)}
    />
  );
};
