import {
  ContractServiceType,
  ContractStatus,
} from '@cooltra/station-based-api';

export type FilterFormValues = {
  serviceType: ContractServiceType | '';
  servicePointId: string[];
  status: ContractStatus[];
};

export const filterInitialFormValues: FilterFormValues = {
  serviceType: '',
  servicePointId: [],
  status: [],
};
