import {
  RejectPayload,
  RejectReason,
  RequestChangesPayload,
  RequestChangesReason,
} from '@cooltra/api';

import { ReviewFormValues } from './review-form-types';

export const getBaseReviewPayload = (
  {
    zip,
    street,
    city,
    country,
    idCardNumber,
    dateOfBirth,
    taxIdNumber,
    taxIdCountry,
    driverLicenseExpirationDate,
    driverLicenseCategory,
    driverLicenseCountry,
    driverLicenseNumber,
    comments,
  }: ReviewFormValues,
  version: number
) => ({
  address: {
    zip,
    street,
    city,
    country,
  },
  idCardNumber,
  dateOfBirth,
  taxIdNumber,
  taxIdCountry,
  driverLicenseExpirationDate,
  driverLicenseCategory,
  driverLicenseCountry,
  driverLicenseNumber,
  comments,
  version,
});

export const getActivatePayload = getBaseReviewPayload;

export const getRequestChangesPayload = (
  values: ReviewFormValues,
  reasons: RequestChangesReason[],
  version: number
): RequestChangesPayload => ({
  ...getActivatePayload(values, version),
  driverLicenseExpirationDate: reasons.includes('expired driver license')
    ? ''
    : values.driverLicenseExpirationDate,
  reasons,
});

export const getRejectPayload = (
  values: ReviewFormValues,
  reasons: RejectReason[],
  version: number
): RejectPayload => {
  const isOverride = reasons.includes('other');
  const isDuplicatedId = reasons.includes('DUPLICATED_ID_NUMBER');
  const isDuplicatedDriverLicense = reasons.includes(
    'DUPLICATED_DRIVER_LICENSE'
  );
  const {
    driverLicenseExpirationDate,
    idCardNumber,
    taxIdCountry,
    taxIdNumber,
  } = values;
  return {
    ...getActivatePayload(values, version),
    reasons,
    driverLicenseExpirationDate: isOverride ? '' : driverLicenseExpirationDate,
    taxIdCountry: isOverride ? '' : taxIdCountry,
    taxIdNumber: isOverride ? '' : taxIdNumber,
    idCardNumber: isDuplicatedId ? '' : idCardNumber,
    driverLicenseNumber: isDuplicatedDriverLicense
      ? ''
      : values.driverLicenseNumber,
  };
};
