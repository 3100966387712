import { defineMessages } from 'react-intl';

export default defineMessages({
  assignee: {
    defaultMessage: 'Assignee',
  },
  operators: {
    defaultMessage: 'Operators',
  },
  operatorGroups: {
    defaultMessage: 'Operator groups',
  },
  placeholder: {
    defaultMessage: 'All',
  },
});
