import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Technical Inspection',
  },
  createTechnicalInspections: {
    defaultMessage: 'Create technical inspection',
  },
  success: {
    defaultMessage: 'You have successfully created a new technical inspection!',
  },
  badFormattedFields: {
    defaultMessage:
      'This service cannot be created, please check that every field is correct',
  },
});
