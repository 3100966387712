import { defineMessages } from 'react-intl';

export default defineMessages({
  unlockBattery: {
    defaultMessage: 'Unlock battery',
  },
  successOnUnlockBatteryVehicle: {
    defaultMessage: "Battery's vehicle has been successfully unlocked",
  },
});
