import { classNames } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type ServiceScheduledMileageValueProps = {
  nextMileageTo: number;
  remainingMileageTo: number;
  className?: string;
};

export const ServiceScheduledMileageValue = ({
  nextMileageTo,
  remainingMileageTo,
  className,
}: ServiceScheduledMileageValueProps) => {
  const isMileageExceeded = remainingMileageTo < 0;

  return (
    <span className={classNames('', className)}>
      <span>{nextMileageTo} km</span>
      {isMileageExceeded ? (
        <span className="text-danger-500 ml-1">
          (
          <FormattedMessage
            {...messages.exceededBy}
            values={{ km: Math.abs(remainingMileageTo) }}
          />
          )
        </span>
      ) : (
        <span className="ml-1">
          (
          <FormattedMessage
            {...messages.inKm}
            values={{ km: remainingMileageTo }}
          />
          )
        </span>
      )}
    </span>
  );
};
