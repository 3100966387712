import { MessageDescriptor } from 'react-intl';
import { AccessoryName } from '@cooltra/utils';

import messages from './messages';

export const getAccessoryNameMessage = (
  accessoryName: AccessoryName
): MessageDescriptor => messages[accessoryName];

export const isCustomReturn = (name: AccessoryName) =>
  name === 'SCOOTER_PICK_UP';

export const isCustomDelivery = (name: AccessoryName) =>
  name === 'SCOOTER_DELIVERY';
