import { useState, MouseEventHandler, ImgHTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { MdOpenInFull, MdRotateLeft, MdRotateRight } from 'react-icons/md';
import { classNames } from '@cooltra/utils';
import { Image } from '@cooltra/ui';
import { useFetchDocument } from '@cooltra/api';

import { useRotation } from '~/hooks';

import { PhotoModal } from '../../Photo';

import { PhotoIconButton } from './PhotoIconButton';
import { photoSize, PhotoSize } from './photo-classes';
import messages from './messages';
import './photo.css';

export type UserPhotoProps = ImgHTMLAttributes<HTMLImageElement> & {
  isFixedSize?: boolean;
  initialSize?: PhotoSize;
  className?: string;
  imageKey: string;
  onClick?: () => void;
};

const hasChangedAxis = (rotation: number) =>
  rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;

const getPhotoClasses = (rotation: number, size: PhotoSize) => {
  const constrainedDimension = hasChangedAxis(rotation) ? 'width' : 'height';
  return classNames(
    'object-contain',
    photoSize[size][constrainedDimension],
    constrainedDimension === 'height' ? 'w-auto' : 'h-auto'
  );
};

export const UserPhoto = ({
  initialSize = 'md',
  imageKey,
  className,
  onClick,
  isFixedSize = false,
  ...rest
}: UserPhotoProps) => {
  const imageData = useFetchDocument(imageKey);

  const { formatMessage } = useIntl();

  const [isOpen, { toggleOff, toggleOn }] = useToggle();
  const openModal: MouseEventHandler<HTMLButtonElement> = (e) => {
    // prevent the modal from focusing on the last active element
    // otherwise the photo toolbar is being jerked up and down upon closing
    e.currentTarget.blur();
    toggleOn();
  };

  const { rotation, rotationClassName, rotateLeft, rotateRight } =
    useRotation();

  const [size, setSize] = useState(initialSize);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (isFixedSize) {
      openModal(e);
    } else {
      if (size === 'sm') {
        openModal(e);
      }
      if (size === 'md') {
        setSize('lg');
      }
      if (size === 'lg') {
        setSize('md');
      }
    }
    onClick && onClick();
  };

  const imageDataWithBase64 = `data:image/jpeg;base64,${imageData}`;

  return (
    <div className={classNames(photoSize[size].height, className)}>
      <div
        tabIndex={0}
        className="group relative bg-neutral-100 overflow-hidden rounded-md outline-none h-full"
      >
        <div className="h-full flex items-center justify-center">
          <Image
            src={imageDataWithBase64}
            className={classNames(
              getPhotoClasses(rotation, size),
              rotationClassName
            )}
            {...rest}
          />
        </div>
        <div className="h-full w-full absolute inset-0 rounded-md">
          <div className="flex flex-col h-full">
            <div className="grow w-full focus:ring focus:ring-primary-200 p-1">
              <button
                type="button"
                aria-label={formatMessage(messages.zoomIn)}
                onClick={handleClick}
                className="w-full h-full rounded-md focus-visible:ring focus-visible:ring-primary-200 transition-shadow"
              />
            </div>
            <div className="opacity-0 group-hover:opacity-60 group-focus-within:opacity-60 flex gap-2 p-3 justify-center bg-neutral-1000 rounded-md transition-opacity">
              <PhotoIconButton
                aria-label={formatMessage(messages.fullScreen)}
                onClick={openModal}
              >
                <MdOpenInFull />
              </PhotoIconButton>
              <PhotoIconButton
                aria-label={formatMessage(messages.rotateLeft)}
                onClick={rotateLeft}
              >
                <MdRotateLeft />
              </PhotoIconButton>
              <PhotoIconButton
                aria-label={formatMessage(messages.rotateRight)}
                onClick={rotateRight}
              >
                <MdRotateRight />
              </PhotoIconButton>
            </div>
          </div>
        </div>
      </div>
      <PhotoModal
        isOpen={isOpen}
        onRequestClose={toggleOff}
        rotateLeft={rotateLeft}
        rotateRight={rotateRight}
        className={rotationClassName}
        imageData={imageDataWithBase64}
        {...rest}
      />
    </div>
  );
};
