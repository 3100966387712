import { defineMessages } from 'react-intl';

export default defineMessages({
  missingUserData: {
    defaultMessage: 'Missing user data',
  },
  missingVehicleData: {
    defaultMessage: 'Missing vehicle data',
  },
});
