import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { CreditRowLayout } from '../CreditRowLayout';

import messages from './messages';

export const CreditsListHeader = () => (
  <CreditRowLayout
    data-testid="CREDITS_LIST_HEADER"
    created={
      <ListColumnLabel>
        <FormattedMessage {...messages.created} />
      </ListColumnLabel>
    }
    title={
      <ListColumnLabel>
        <FormattedMessage {...messages.title} />
      </ListColumnLabel>
    }
    amount={
      <ListColumnLabel>
        <FormattedMessage {...messages.amount} />
      </ListColumnLabel>
    }
    remainingAmount={
      <ListColumnLabel>
        <FormattedMessage {...messages.remainingAmount} />
      </ListColumnLabel>
    }
    expiresAt={
      <ListColumnLabel>
        <FormattedMessage {...messages.expiresAt} />
      </ListColumnLabel>
    }
    state={<></>}
  />
);
