import { defineMessages } from 'react-intl';

export default defineMessages({
  dailyPrice: {
    defaultMessage: '{amount} / day',
  },
  add: {
    defaultMessage: 'Add',
  },
  depositWithheld: {
    defaultMessage: 'You must return deposit before upgrading the insurance',
  },
});
