import { useIntl } from 'react-intl';
import { getKeys } from '@cooltra/utils';

import { parkingReviews } from './messages';

export const useParkingReviewOptions = () => {
  const { formatMessage } = useIntl();
  return getKeys(parkingReviews)
    .map((parkingReview) => ({
      value: parkingReview,
      label: formatMessage(parkingReviews[parkingReview]),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
