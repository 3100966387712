import { Vehicle } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { DividedCard, Log } from '~/common';

import * as VehicleFields from '../VehicleFields';

import messages from './messages';

export type ViewVehicleProps = {
  vehicle: Vehicle;
};

export const ViewVehicle = ({
  vehicle: {
    createdAt,
    externalId,
    model,
    licensePlate,
    telematics,
    telematicsId,
    identificationNumber,
    insuranceExpiresAt,
    cityHallPublished,
  },
}: ViewVehicleProps) => (
  <DividedCard
    className="min-w-4xl max-w-5xl py-12"
    data-testid="VIEW_VEHICLE"
    renderHeader={
      <h2 className="text-base text-neutral-500 font-semibold">
        <FormattedMessage {...messages.vehicleInformation} />
      </h2>
    }
    renderLeft={<Log action="create" date={createdAt} />}
    renderRight={
      <div className="grid grid-cols-2 gap-12">
        <VehicleFields.ModelStatic value={model} />
        <VehicleFields.TelematicsStatic value={telematics} />
        <VehicleFields.ExternalIdStatic value={externalId} />
        <VehicleFields.LicensePlateStatic value={licensePlate} />
        <VehicleFields.TelematicsIdStatic value={telematicsId} />
        <VehicleFields.IdNumberStatic value={identificationNumber} />
        <VehicleFields.InsuranceExpirationStatic value={insuranceExpiresAt} />
        <div className="col-span-2">
          <VehicleFields.CityHallStatic value={cityHallPublished} />
        </div>
      </div>
    }
  />
);
