import { Card, Divider } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { CooltraForEmployeesFields } from '~/common';

import messages from './messages';

export const CooltraForEmployeesFormFields = () => (
  <Card className="w-full min-w-[750px] mb-5">
    <div className="p-10">
      <h2 className="text-xl font-semibold text-neutral-400 mb-4">
        <FormattedMessage {...messages.promotion} />
      </h2>
      <div className="grid grid-cols-2 gap-6">
        <CooltraForEmployeesFields.Title />
        <CooltraForEmployeesFields.Subscription />
        <CooltraForEmployeesFields.CompanyName />
        <CooltraForEmployeesFields.CompanyFiscalName />
      </div>
    </div>
    <Divider className="my-3" />
    <div className="p-10 mb-8">
      <h2 className="text-xl font-semibold text-neutral-400 mb-8">
        <FormattedMessage {...messages.promotionCode} />
      </h2>
      <div className="flex gap-6 items-center">
        <CooltraForEmployeesFields.CodePrefix />
        <CooltraForEmployeesFields.NumberOfCodes />
        <div className="mt-5">
          <CooltraForEmployeesFields.CodesToCSVLink />
        </div>
      </div>
    </div>
    <Divider className="my-3" />
    <div className="p-10 mb-8">
      <h2 className="text-xl font-semibold text-neutral-400 mb-8">
        <FormattedMessage {...messages.validityAndUse} />
      </h2>
      <div className="grid gap-y-10">
        <CooltraForEmployeesFields.RedeemableDates />
        <CooltraForEmployeesFields.ConsumableDate />
      </div>
    </div>
  </Card>
);
