import { Route, Routes, Navigate } from 'react-router-dom';

import { SidebarLayout } from '~/common';

import { Tags } from '../../Tags';
import { Locations } from '../../Locations';

import { Sidebar } from './Sidebar';

export const Settings = () => (
  <SidebarLayout sidebar={<Sidebar />}>
    <Routes>
      <Route path="/locations/*" element={<Locations />} />
      <Route path="/tags/*" element={<Tags />} />
      <Route path="*" element={<Navigate to="locations" />} />
    </Routes>
  </SidebarLayout>
);
