import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { MdSearch } from 'react-icons/md';
import { InputField } from '@cooltra/ui';
import { useEffect, useState } from 'react';
import { useToggle } from '@cooltra/hooks';

import { useDebounce, useNavigateWithQueryParams } from '~/hooks';

import messages from './messages';

export const VehiclesInputSearch = () => {
  const { formatMessage } = useIntl();
  const [isLoading, { toggleOn, toggleOff }] = useToggle();
  const [searchParams] = useSearchParams();
  const initialKeyword = searchParams.get('keyword') || '';
  const [keyword, setKeyword] = useState(initialKeyword);

  const keywordDebounce = useDebounce(keyword, 1500);

  const navigateWithQueryParams = useNavigateWithQueryParams();

  useEffect(() => {
    toggleOn();
    const handleFilter = async ({ keyword }: { keyword: string }) => {
      if (keyword === '') {
        navigateWithQueryParams({});
      }
      navigateWithQueryParams({ keyword });
    };

    handleFilter({ keyword: keywordDebounce });
    toggleOff();
  }, [keywordDebounce, navigateWithQueryParams, toggleOff, toggleOn]);

  return (
    <InputField
      name="keyword"
      className="w-1/3"
      value={keyword}
      disabled={isLoading}
      onChange={(e) => setKeyword(e.target.value)}
      placeholder={formatMessage(messages.placeholder)}
      leadingIcon={<MdSearch className="text-lg text-neutral-400" />}
    />
  );
};
