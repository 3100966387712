import { defineMessages } from 'react-intl';

export default defineMessages({
  errorTitle: {
    defaultMessage: "Oops, you can't do this!",
  },
  error: {
    defaultMessage: 'This user is not awaiting review',
  },
});
