import { usePromotionCodesQuery } from '@cooltra/api';
import { useFormContext } from '@cooltra/form';
import { useParams } from 'react-router-dom';

import { CodesToCSV } from '../../../CodesToCSV/CodesToCSV';
import { PromotionFormValues } from '../../PromotionForm';

export const DownloadCodes = () => {
  const { values } = useFormContext<PromotionFormValues>();
  const { promotionId = '' } = useParams<'promotionId'>();
  const { data, isLoading } = usePromotionCodesQuery(promotionId, {
    enabled: Boolean(promotionId) && values.editMode,
  });

  if (!values.editMode) {
    return <></>;
  }

  return <CodesToCSV codes={data} isLoading={isLoading} />;
};
