import { Icon } from '@cooltra/ui';
import { useIntl } from 'react-intl';
import { stringifyParams } from '@cooltra/utils';
import { RouterBaseLink } from '@cooltra/navigation';
import { MdOutlineChevronLeft } from 'react-icons/md';

import { useVehiclesFilters } from '~/libs/vehicles-filters';

import messages from './messages';

export const GoToListViewButton = () => {
  const { formatMessage } = useIntl();
  const { values } = useVehiclesFilters();

  return (
    <RouterBaseLink
      className="bg-neutral-0 shadow rounded-md h-11 w-11 text-center"
      to={`/vehicles?${stringifyParams(values)}`}
      aria-label={formatMessage(messages.listView)}
    >
      <Icon className="text-2xl text-neutral-500">
        <MdOutlineChevronLeft />
      </Icon>
    </RouterBaseLink>
  );
};
