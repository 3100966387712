import { useFormContext } from '@cooltra/form';

import { SaveButton, SaveButtonProps } from './SaveButton';

export const FormSaveButton = ({
  className,
  disabled,
  isSaved,
  ...rest
}: SaveButtonProps) => {
  const { isSubmitting, dirty, isValid, isFormDisabled } = useFormContext();

  return (
    <SaveButton
      type="submit"
      isSaved={isSaved && !dirty}
      loading={isSubmitting}
      disabled={isFormDisabled || !dirty || !isValid || disabled}
      emphasis="high"
      className={className}
      {...rest}
    />
  );
};
