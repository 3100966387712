import { algorithmPrices } from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Card, InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormInputField, FormSelectField } from '@cooltra/form';

import { algorithmNamesMessages } from '~/common';

import messages from './messages';

export const AccessoryPriceListDetailsFormFields = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();

  return (
    <Card className="mt-8">
      <div className="px-12">
        <div data-testid={`ACCESSORY_PRICE`} className="flex py-12 gap-10">
          <FormInputField
            name={`price`}
            id="accessory_price"
            className="w-24"
            placeholder="30"
            disabled={!hasPermission('write:prices')}
            label={
              <InputLabel htmlFor="accessory_price">
                <FormattedMessage {...messages.price} /> (€)
              </InputLabel>
            }
          />
          <FormSelectField
            name={`algorithmName`}
            id={`accessory_price_algorithm`}
            className="w-52"
            label={
              <InputLabel htmlFor={`accessory_price_algorithm`}>
                <FormattedMessage {...messages.algorithmForDiscount} />
              </InputLabel>
            }
            placeholder={formatMessage(messages.placeholder)}
            disabled={!hasPermission('write:prices')}
            options={algorithmPrices.map((algorithmType) => ({
              value: `${algorithmType}`,
              label: formatMessage(algorithmNamesMessages[algorithmType]),
            }))}
          />
        </div>
      </div>
    </Card>
  );
};
