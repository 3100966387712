import { SelectField, InputLabel, InputError } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useServicePointsQuery } from '@cooltra/station-based-api';
import { useField } from '@cooltra/form';

import messages from './messages';

type ServicePointIdOption = {
  label: string;
  value: string;
};

export const BillingServicePointSelector = () => {
  const intl = useIntl();
  const { data: servicePoints, isLoading } = useServicePointsQuery({
    enabled: false,
  });
  const { value, setValue, touched, error, setTouched } = useField(
    'billingServicePointId'
  );

  const servicePointsOptions: ServicePointIdOption[] = servicePoints
    ? servicePoints
        .map((servicePoint) => ({
          label: servicePoint.name,
          value: servicePoint.id,
        }))
        .sort((servicePoint, nextServicePoint) =>
          servicePoint.label.localeCompare(nextServicePoint.label)
        )
    : [];

  return (
    <div>
      <SelectField
        id="billingServicePointId"
        name="billingServicePointId"
        error={touched && error ? <InputError>{error}</InputError> : undefined}
        isLoading={isLoading}
        value={value}
        label={
          <InputLabel htmlFor="billingServicePointId" className="shrink-0">
            <FormattedMessage {...messages.currentServicePoint} />
          </InputLabel>
        }
        options={servicePointsOptions}
        placeholder={intl.formatMessage(messages.all)}
        onBlur={() => setTouched(true)}
        onChange={(selectedOption) => {
          setValue(selectedOption);
        }}
      />
    </div>
  );
};
