import { Placeholder } from '@cooltra/ui';
import { ReactNode } from 'react';

import { StaticField } from './StaticField';

export type StaticFieldLoadingProps = {
  renderValue?: ReactNode;
};

export const StaticFieldLoading = ({
  renderValue,
}: StaticFieldLoadingProps) => (
  <StaticField
    renderLabel={<Placeholder className="h-3 w-20" />}
    renderValue={renderValue || <Placeholder className="h-3 w-36" />}
  />
);
