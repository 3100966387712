import { useFormContext } from '@cooltra/form';
import { Legend } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';

import { PromotionStateType } from '../../PromotionState/PromotionState';
import { PromotionFormValues } from '../../PromotionForm';
import {
  FormDateInputField,
  FormTimeInputField,
} from '../../../../FormattedInput';

import messages from './messages';

export const ValidFrom = () => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext<PromotionFormValues>();
  const { editMode, stateType } = values;
  const editingNoScheduledPromotion =
    editMode && stateType !== PromotionStateType.SCHEDULED;

  return (
    <fieldset className="flex gap-2" data-testid="VALID_FROM">
      <Legend className="mb-1">
        <FormattedMessage {...messages.redeemableFrom} />
      </Legend>
      <FormDateInputField
        className="w-32"
        name="validFromDate"
        disabled={editingNoScheduledPromotion}
        aria-label={formatMessage(messages.date)}
      />
      <FormTimeInputField
        className="w-20"
        name="validFromTime"
        disabled={editingNoScheduledPromotion}
        aria-label={formatMessage(messages.time)}
      />
    </fieldset>
  );
};
