import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Incident details',
  },
  incidentComments: {
    defaultMessage: 'Comments',
  },
});
