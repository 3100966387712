import { useIntl } from 'react-intl';
import { FormInputField } from '@cooltra/form';
import { MdSearch } from 'react-icons/md';

import messages from './messages';

export const UsersFormInputSearch = () => {
  const { formatMessage } = useIntl();

  return (
    <FormInputField
      id="keyword-search"
      className="w-80 text-left"
      name="keyword"
      placeholder={formatMessage(messages.placeholder)}
      leadingIcon={<MdSearch className="text-lg text-neutral-400" />}
    />
  );
};
