import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    defaultMessage: 'Vehicle bonus',
  },
  placeholder: {
    defaultMessage: 'All',
  },
  enabled: {
    defaultMessage: 'Enabled',
  },
  disabled: {
    defaultMessage: 'Disabled',
  },
});
