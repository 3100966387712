import {
  UserStateType,
  UserSuspension,
  useUserSuspensionsQuery,
} from '@cooltra/api';
import { List } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import {
  ClearedSuspensionCard,
  ClearedSuspensionsListHeader,
  ErrorPage,
  NoSuspensions,
  SuspensionCard,
  SuspensionCardLoading,
  SuspensionModal,
  SuspensionsListHeader,
} from '~/common';
import { sortByDate } from '~/utils/date';

import messages from './messages';

export type SuspensionsProps = {
  userId: string;
  userState?: UserStateType;
};

export const Suspensions = ({ userId, userState }: SuspensionsProps) => {
  const {
    data: suspensions = [],
    isLoading,
    isError,
  } = useUserSuspensionsQuery(userId);

  const isUserDeleted = userState === 'DELETED';

  const activeSuspensions = suspensions
    .filter(({ clearedAt }) => !clearedAt)
    .sort(sortByDate());

  const clearedSuspensions = suspensions.filter(({ clearedAt }) => clearedAt);

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div
      className="container min-w-3xl max-w-5xl py-12"
      data-testid="USER_SUSPENSIONS"
    >
      <div className="flex justify-between items-center mb-12">
        <h2 className="text-base text-neutral-500 font-semibold">
          <FormattedMessage {...messages.activeSuspensions} />
        </h2>
        {(userState == 'ACTIVATED' || userState == 'SUSPENDED') && (
          <SuspensionModal userId={userId} />
        )}
      </div>
      <div data-testid="USER_ACTIVE_SUSPENSIONS" className="mb-16">
        <List
          loadingRows={2}
          isLoading={isLoading}
          idProp="suspensionId"
          data={activeSuspensions}
          renderEmptyMessage={<NoSuspensions />}
          renderHeader={<SuspensionsListHeader showClear={!isUserDeleted} />}
          renderLoadingRow={<SuspensionCardLoading />}
          renderRow={(suspension: UserSuspension) => (
            <SuspensionCard
              suspension={suspension}
              userId={userId}
              isUserDeleted={isUserDeleted}
            />
          )}
        />
      </div>
      <div data-testid="USER_CLEARED_SUSPENSIONS">
        {!!clearedSuspensions.length && (
          <h2 className="text-base text-neutral-500 font-semibold mb-12">
            <FormattedMessage {...messages.clearedSuspensions} />
          </h2>
        )}
        <List
          loadingRows={0}
          isLoading={isLoading}
          idProp="suspensionId"
          data={clearedSuspensions}
          renderHeader={<ClearedSuspensionsListHeader />}
          renderRow={(suspension: UserSuspension) => (
            <ClearedSuspensionCard suspension={suspension} />
          )}
        />
      </div>
    </div>
  );
};
