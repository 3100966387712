import { defineMessages } from 'react-intl';

export default defineMessages({
  licensePlate: {
    defaultMessage: 'License plate',
  },
  pickUp: {
    defaultMessage: 'Pickup',
  },
  dropOff: {
    defaultMessage: 'Dropoff',
  },
  duration: {
    defaultMessage: 'Duration',
  },
  vehicle: {
    defaultMessage: 'Vehicle',
  },
  user: {
    defaultMessage: 'User',
  },
  system: {
    defaultMessage: 'System',
  },
  incidents: {
    defaultMessage: 'Incidents',
  },
  state: {
    defaultMessage: 'Status',
  },
});
