import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { checkVehicle, usePatchVehicleMutation, Vehicle } from '@cooltra/api';
import { getRequestConfig } from '@cooltra/axios';
import { useAuth0 } from '@auth0/auth0-react';
import { ScrollToTop } from '@cooltra/ui';
import { FormButton, Form, FormProvider, FormHelpers } from '@cooltra/form';

import { DividedCard, Log } from '~/common';
import { useNotification } from '~/hooks';

import * as VehicleFields from '../../VehicleFields';

import {
  EditVehicleFormValues,
  getVehicleInitialValues,
  validateEditVehicleForm,
} from './edit-vehicle-form';
import messages from './messages';

export type EditVehicleFormProps = {
  vehicle: Vehicle;
};

export const EditVehicleForm = ({ vehicle }: EditVehicleFormProps) => {
  const intl = useIntl();

  const { getAccessTokenSilently } = useAuth0();
  const { vehicleId = '' } = useParams<'vehicleId'>();

  const { mutateAsync } = usePatchVehicleMutation(vehicleId);
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleSubmit = (
    values: EditVehicleFormValues,
    { setErrors, resetForm }: FormHelpers<EditVehicleFormValues>
  ) => {
    const { externalId, telematicsId, licensePlate } = values;
    return getAccessTokenSilently()
      .then((accessToken) =>
        checkVehicle(
          vehicleId,
          { externalId, telematicsId, licensePlate },
          getRequestConfig(accessToken)
        )
      )
      .then((valid) => {
        if (valid.externalId && valid.telematicsId && valid.licensePlate) {
          return mutateAsync(values);
        }
        const duplicatedMessage = intl.formatMessage(messages.duplicated);
        setErrors({
          externalId:
            valid.externalId === false ? duplicatedMessage : undefined,
          telematicsId:
            valid.telematicsId === false ? duplicatedMessage : undefined,
          licensePlate:
            valid.licensePlate === false ? duplicatedMessage : undefined,
        });
        return false as unknown;
      })
      .then((result) => {
        if (result !== false) {
          resetForm(values);
          addSuccessNotification(intl.formatMessage(messages.success));
        }
      })
      .catch(() => {
        addErrorNotification();
      });
  };

  return (
    <FormProvider
      initialValues={getVehicleInitialValues(vehicle)}
      validate={validateEditVehicleForm(intl)}
      onSubmit={handleSubmit}
    >
      <Form>
        <ScrollToTop />
        <DividedCard
          className="min-w-4xl max-w-5xl py-12"
          data-testid="EDIT_VEHICLE"
          renderHeader={
            <div className="flex justify-between items-center gap-10">
              <h2 className="text-base text-neutral-500 font-semibold">
                <FormattedMessage {...messages.vehicleInformation} />
              </h2>
            </div>
          }
          renderRight={
            <div className="grid grid-cols-2 gap-y-6 gap-x-8">
              <VehicleFields.Model />
              <VehicleFields.Telematics />
              <VehicleFields.ExternalId />
              <VehicleFields.LicensePlate />
              <VehicleFields.TelematicsId />
              <VehicleFields.IdNumber disabled />
              <VehicleFields.InsuranceExpiration />
              <div className="col-span-2">
                <VehicleFields.CityHall />
              </div>
            </div>
          }
          renderLeft={<Log action="create" date={vehicle?.createdAt} />}
          renderFooter={
            <>
              <span />
              <FormButton>
                <FormattedMessage {...messages.saveChanges} />
              </FormButton>
            </>
          }
        />
      </Form>
    </FormProvider>
  );
};
