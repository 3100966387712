import { Route, Routes } from 'react-router-dom';

import { CreatePack } from './CreatePack/CreatePack';
import { PacksList } from './PacksList/PacksList/PacksList';
import { PacksFiltersForm } from './PacksFiltersForm';
import { EditPack } from './EditPack/EditPack';

export const Packs = () => (
  <Routes>
    <Route
      path="/"
      element={
        <PacksFiltersForm>
          <PacksList />
        </PacksFiltersForm>
      }
    />
    <Route path="/new" element={<CreatePack />} />
    <Route path="/:packId/edit" element={<EditPack />} />
  </Routes>
);
