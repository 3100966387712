import { ReactNode } from 'react';

import { getEnvVariable } from '~/utils/environment';
import { isTestingEnv } from '~/utils/e2e';

import { Auth0ProviderWithHistory } from './Auth0ProviderWithHistory';
import { MockAuth0Provider } from './MockAuth0Provider';

export type Auth0ProviderProps = {
  children: ReactNode;
};

export const Auth0Provider = (props: Auth0ProviderProps) =>
  isTestingEnv() && getEnvVariable('NODE_ENV') === 'development' ? (
    <MockAuth0Provider {...props} />
  ) : (
    <Auth0ProviderWithHistory {...props} />
  );
