import { FormattedMessage } from 'react-intl';
import { FormInputField, useFormContext } from '@cooltra/form';

import { PromotionFormValues } from '../../PromotionForm';
import { PromotionStateType } from '../../PromotionState/PromotionState';
import { OptionalFieldLabel } from '../../../../OptionalFieldLabel/OptionalFieldLabel';

import messages from './messages';

export const About = () => {
  const { values } = useFormContext<PromotionFormValues>();
  const editingExpiredPromotion =
    values.editMode && values.stateType === PromotionStateType.EXPIRED;

  return (
    <FormInputField
      name="about"
      id="about"
      disabled={editingExpiredPromotion}
      label={
        <OptionalFieldLabel htmlFor="about">
          <FormattedMessage {...messages.about} />
        </OptionalFieldLabel>
      }
    />
  );
};
