import { Vehicle } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { StaticField, TelematicsName } from '~/common';

import messages from './messages';

export type TelematicsStaticProps = {
  value: Vehicle['telematics'];
};

export const TelematicsStatic = ({ value }: TelematicsStaticProps) => (
  <StaticField
    renderLabel={<FormattedMessage {...messages.telematics} />}
    renderValue={<TelematicsName telematics={value} />}
  />
);
