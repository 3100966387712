import { Placeholder } from '@cooltra/ui';
import { FetchRentalsRequestParams, useRentalsQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';

import { useVehiclesFilters } from '~/libs/vehicles-filters';
import { getInnerJoinBetweenSystemsAndClaimsSystems } from '~/utils/system';
import { dateFormat } from '~/utils/date';

import { PairValue } from './PairValue';
import messages from './messages';

export const RentalsToday = () => {
  const { values } = useVehiclesFilters();
  const { data: claims } = useAuthClaimsQuery();
  const today = new Date();
  const queryParams: FetchRentalsRequestParams = {
    system: getInnerJoinBetweenSystemsAndClaimsSystems(values?.system, claims),
    minDate: format(today, dateFormat),
  };
  const { isLoading } = useRentalsQuery(queryParams);
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  if (isLoading) {
    return (
      <PairValue
        value={
          <div className="h-10 flex items-center">
            <Placeholder className="w-12 h-3" />
          </div>
        }
        title={formatMessage(messages.rentalsToday)}
      />
    );
  }

  const rentalsTotals = queryClient.getQueryData([
    'rentals-total',
    queryParams,
  ]);

  return (
    <PairValue
      value={Number.isInteger(rentalsTotals) ? Number(rentalsTotals) : 0}
      title={formatMessage(messages.rentalsToday)}
    />
  );
};
