import { Tooltip } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type FailedCheckInProps = {
  error: string;
};

export const FailedCheckIn = ({ error }: FailedCheckInProps) => (
  <Tooltip
    button={
      <span className="self-start text-danger-700 text-sm text-center">
        <FormattedMessage {...messages.failed} />
      </span>
    }
    content={<div className="px-5 py-4">{error}</div>}
  />
);
