import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { FormInputField } from '@cooltra/form';

import { FormFieldWithName } from '~/utils/form';

import messages from './messages';

export const LastName = ({ name = 'lastName' }: FormFieldWithName) => (
  <FormInputField
    name={name}
    id="last-name"
    label={
      <InputLabel htmlFor="last-name">
        <FormattedMessage {...messages.lastName} />
      </InputLabel>
    }
  />
);
