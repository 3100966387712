import { defineMessages } from 'react-intl';

export default defineMessages({
  createdAt: {
    defaultMessage: 'Created at',
  },
  item: {
    defaultMessage: 'Item',
  },
  rentalId: {
    defaultMessage: 'Rental',
  },
  legalEntity: {
    defaultMessage: 'Legal entity',
  },
  totalPrice: {
    defaultMessage: 'Total price',
  },
  totalCharges: {
    defaultMessage: 'Total charges',
  },
});
