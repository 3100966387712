import { useVehicleStatusQuery } from '@cooltra/api';

import { MileagePair } from '~/common';

export type VehicleMileageProps = {
  vehicleId: string;
};

export const VehicleMileage = ({ vehicleId }: VehicleMileageProps) => {
  const { data: vehicleStatus, isLoading: isVehicleStatusLoading } =
    useVehicleStatusQuery(vehicleId);

  return (
    <MileagePair
      mileage={vehicleStatus?.mileage?.value}
      isLoading={isVehicleStatusLoading}
    />
  );
};
