export const noNumbers = /^[^0-9]+$/;

export const dateRegex =
  /^(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-](19|20)\d\d$/;

export const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

export const emailRegex = /^[a-zA-Z0-9%_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export const phoneRegex = /^([+0-9]){8,17}$/;

export const amountRegex = /^\d+(\.\d{1,2})?$/;

export const amountWithoutDecimalsRegex = /^\d+$/;

export const noSpecialCharacters = /^[a-z A-Z0-9]*$/;
