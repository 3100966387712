import { defineMessages } from 'react-intl';

export default defineMessages({
  backToPacks: {
    defaultMessage: 'Back to packs',
  },
  title: {
    defaultMessage: 'Create pack',
  },
  createPack: {
    defaultMessage: 'Create pack',
  },
  youHaveSuccessfullyCreatedThePack: {
    defaultMessage: 'You have successfully created a new pack!',
  },
});
