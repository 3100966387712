import { Button, Modal } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { Form, FormButton } from '@cooltra/form';

import { CreateTechnicalInspectionForm } from './CreateTechnicalInspectionForm';
import messages from './messages';

export type CreateTechnicalInspectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  loading?: boolean;
};

export const CreateTechnicalInspectionModal = ({
  isOpen,
  onClose,
  loading,
}: CreateTechnicalInspectionModalProps) => (
  <Modal
    className="w-[36rem] p-8"
    isOpen={isOpen}
    onRequestClose={onClose}
    header={
      <h6 className="text-neutral-400 text-lg mt-5 font-semibold">
        <FormattedMessage {...messages.completedTechnicalInspection} />
      </h6>
    }
  >
    <Form>
      <div
        data-testid="CREATE_TECHNICAL_INSPECTION_MODAL"
        className="pb-4 px-4"
      >
        <CreateTechnicalInspectionForm />
        <div className="flex justify-center gap-4 mt-10">
          <Button className="flex-1" onClick={onClose}>
            <FormattedMessage {...messages.cancel} />
          </Button>
          <FormButton
            data-testid="BUTTON_TO_CREATE_TECHNICAL_INSPECTION"
            className="flex-1"
            emphasis="high"
            disabled={loading}
            loading={loading}
          >
            <FormattedMessage {...messages.save} />
          </FormButton>
        </div>
      </div>
    </Form>
  </Modal>
);
