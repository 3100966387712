import { BadgePlaceholder, Card, Placeholder } from '@cooltra/ui';

import { DiscountRowLayout } from '../DiscountRowLayout';

export const DiscountCardLoading = () => (
  <Card>
    <DiscountRowLayout
      className="h-16"
      offer={<Placeholder className="w-24 h-2" />}
      code={<Placeholder className="h-2 w-10" />}
      discountPercentage={
        <div className="flex justify-end">
          <Placeholder className="h-2 w-10" />
        </div>
      }
      redeemedAt={
        <div className="flex flex-col gap-3">
          <Placeholder className="w-12 h-2" />
          <Placeholder className="w-24 h-2" />
        </div>
      }
      validFrom={
        <div className="flex flex-col gap-3">
          <Placeholder className="w-12 h-2" />
          <Placeholder className="w-24 h-2" />
        </div>
      }
      validUntil={
        <div className="flex flex-col gap-3">
          <Placeholder className="w-12 h-2" />
          <Placeholder className="w-24 h-2" />
        </div>
      }
      state={<BadgePlaceholder className="w-20" />}
    />
  </Card>
);
