import { useVehicleTasksQuery, VehicleTaskListItem } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { useFormContext } from '@cooltra/form';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { RouterButton } from '@cooltra/navigation';
import { List } from '@cooltra/ui';
import { equal } from '@cooltra/utils';
import { useEffect } from 'react';

import {
  FilterFields,
  ErrorPage,
  Four0Three,
  NoSearchResults,
  TasksTotal,
} from '~/common';
import { useNavigateWithQueryParams } from '~/hooks';

import { VehicleTasksListHeader } from '../VehicleTasksListHeader';
import { VehicleTaskCard, VehicleTaskCardLoading } from '../VehicleTaskCard';
import {
  VehicleTasksFiltersFormValues,
  vehicleTasksFiltersInitialValues,
} from '../vehicle-tasks-filters-form';
import { NoVehicleTasks } from '../NoVehicleTasks/NoVehicleTasks';

import messages from './messages';

export const VehicleTasksList = () => {
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const { vehicleId = '' } = useParams<'vehicleId'>();

  const { hasPermission } = useAuthClaimsQuery();

  const { values } = useFormContext<VehicleTasksFiltersFormValues>();

  const {
    data: tasks = [],
    isLoading,
    isError,
    error,
  } = useVehicleTasksQuery(vehicleId, values);

  useEffect(() => {
    navigateWithQueryParams(values);
  }, [navigateWithQueryParams, values]);

  const tasksTotal = tasks?.length || 0;

  const vehicleHasNoTasks =
    !isLoading &&
    !tasks?.length &&
    equal(values, vehicleTasksFiltersInitialValues);

  if (!hasPermission('read:tasks')) {
    return <Four0Three />;
  }

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div
      className="container min-w-4xl max-w-5xl py-12"
      data-testid="VEHICLE_TASKS"
    >
      <div className="flex justify-between gap-8 items-end mb-10">
        <div className="flex gap-8">
          {!vehicleHasNoTasks && (
            <>
              <div className="w-60">
                <FilterFields.Status />
              </div>
              <FilterFields.Updated />
            </>
          )}
        </div>
        {hasPermission('write:tasks') && (
          <RouterButton
            emphasis="high"
            to={`/tasks/new?vehicleId=${vehicleId}`}
          >
            <FormattedMessage {...messages.createTask} />
          </RouterButton>
        )}
      </div>
      {vehicleHasNoTasks ? (
        <NoVehicleTasks />
      ) : (
        <>
          <TasksTotal
            loading={isLoading}
            total={tasksTotal}
            className="mb-10"
          />
          <List
            loadingRows={20}
            isLoading={isLoading}
            idProp="id"
            data={tasks}
            renderEmptyMessage={<NoSearchResults />}
            renderHeader={<VehicleTasksListHeader />}
            renderLoadingRow={<VehicleTaskCardLoading />}
            renderRow={(task: VehicleTaskListItem) => (
              <VehicleTaskCard task={task} />
            )}
          />
        </>
      )}
    </div>
  );
};
