import { Layer, Popup, Source } from 'react-map-gl';
import { BonusZone } from '@cooltra/api';

import { MapLine } from '~/common';

const bonusZoneFeatureStyles = {
  fillColor: 'rgba(202, 109, 22, 0.2)',
  fillOutlineColor: '#333',
  lineColor: '#CA6D16',
};

export type BonusZoneFeatureProps = {
  layerId: string;
  coordinates: [latitude: number, longitude: number][];
  isFocused?: boolean;
} & Partial<Pick<BonusZone, 'title' | 'marker' | 'bonusZoneId' | 'enabled'>>;

export const BonusZoneFeature = ({
  title,
  marker,
  bonusZoneId,
  coordinates,
  layerId,
  enabled = false,
  isFocused = false,
}: BonusZoneFeatureProps) => {
  const sourceFillId = `bonus-zone-fill-${bonusZoneId}`;
  const sourceBordersId = `bonus-zone-border-${bonusZoneId}`;

  if (!enabled && !isFocused) {
    return <></>;
  }

  return (
    <>
      <Source
        id={sourceFillId}
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                id: bonusZoneId,
                type: 'bonus',
              },
              geometry: {
                coordinates: [coordinates],
                type: 'Polygon',
              },
            },
          ],
        }}
      >
        {isFocused && marker && (
          <Popup
            longitude={marker.longitude}
            latitude={marker.latitude}
            closeButton={false}
            maxWidth="none"
          >
            {title}
          </Popup>
        )}
        <Layer
          interactive
          id={layerId}
          source={sourceFillId}
          type="fill"
          paint={{
            'fill-translate': [0, 0],
            'fill-outline-color': bonusZoneFeatureStyles.fillOutlineColor,
            'fill-color': bonusZoneFeatureStyles.fillColor,
          }}
        />
      </Source>
      <MapLine
        sourceId={sourceBordersId}
        coordinates={coordinates}
        paint={{
          'line-color': bonusZoneFeatureStyles.lineColor,
          'line-width': 2,
          'line-dasharray': enabled ? [] : [2, 2],
        }}
      />
    </>
  );
};
