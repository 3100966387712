import { Divider } from '@cooltra/ui';
import { WorkShift } from '@cooltra/api';

import { ShiftOperator, ShiftDuration } from '~/common';

import { ShiftTimeline } from '../ShiftTimeline';
import { ShiftMap } from '../ShiftMap/ShiftMap';
import { ShiftRouteCoordinatesProvider } from '../ShiftMap/shift-route-coordinates-provider';

import './shift.css';
import { ShiftStats } from './ShiftStats';

type ShiftProps = {
  workShift: WorkShift;
};

export const Shift = ({ workShift }: ShiftProps) => {
  if (!workShift.summary) {
    return <></>;
  }

  const operatorHomeSystem = workShift.summary.system;
  const operatorHomeSystems = workShift.summary.systems;
  const { summary } = workShift;

  return (
    <div className="h-screen-content min-w-6xl">
      <div
        data-testid="SHIFT_SUMMARY"
        className="z-20 relative justify-between items-center flex py-8 shadow-md bg-neutral-0"
      >
        <div className="flex-1 px-8">
          <ShiftOperator
            operatorId={workShift.operatorId}
            homeSystem={operatorHomeSystem}
            homeSystems={operatorHomeSystems}
          />
        </div>
        <Divider direction="vertical" className="h-20" />
        <div className="flex-1 px-8">
          <ShiftDuration
            createdAt={summary.createdAt}
            finishedAt={summary.finishedAt}
            automaticallyClosed={summary.automaticallyClosed}
          />
        </div>
        <Divider direction="vertical" className="h-20" />
        <div className="flex-1.5 px-8">
          <ShiftStats summary={summary} />
        </div>
      </div>
      <div className="shift-content flex">
        <aside className="max-h-full overflow-auto min-w-md shadow-md relative z-10">
          <ShiftTimeline workShift={workShift} />
        </aside>
        <main className="w-full h-full relative z-0">
          <ShiftRouteCoordinatesProvider workUnits={workShift.workUnits}>
            <ShiftMap />
          </ShiftRouteCoordinatesProvider>
        </main>
      </div>
    </div>
  );
};
