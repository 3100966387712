import { defineMessages } from 'react-intl';

export default defineMessages({
  promotion: {
    defaultMessage: 'Promotion overview',
  },
  promotionCode: {
    defaultMessage: 'Promotion code',
  },
  validityAndUse: {
    defaultMessage: 'Validity & use',
  },
});
