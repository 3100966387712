import { TimelineWorkUnitClosed } from '@cooltra/api';
import { TimelineItem } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { FormattedMessage, FormattedTime } from 'react-intl';

import messages from './messages';

export type WorkUnitClosedProps = Pick<
  TimelineWorkUnitClosed,
  'createdAt' | 'comment'
> & {
  isLast: boolean;
};

export const WorkUnitClosed = ({
  createdAt,
  comment,
  isLast,
}: WorkUnitClosedProps) => (
  <TimelineItem
    timelineClassName="translate-y-1.5"
    isLast={isLast}
    header={
      <div className={classNames('flex gap-2 text-sm', !comment && 'mb-4')}>
        <span className="text-neutral-400">
          <FormattedTime
            value={createdAt}
            hour="2-digit"
            hourCycle="h23"
            minute="2-digit"
          />
        </span>
        <span className="text-neutral-800">
          <FormattedMessage {...messages.finishWork} />
        </span>
      </div>
    }
  >
    {comment && (
      <p className="text-xs italic text-neutral-400 mb-4">{comment}</p>
    )}
  </TimelineItem>
);
