import { defineMessages } from 'react-intl';

export default defineMessages({
  statusHistory: {
    defaultMessage: 'Status history',
  },
  newReview: {
    defaultMessage: 'New review',
  },
});
