import { MdError } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { Card } from '@cooltra/ui';
import { ContractAnnex } from '@cooltra/station-based-api';

import messages, { cancelledAnnexTypesMessages } from './messages';

type NewProps = {
  annex: ContractAnnex;
};

export const Cancelled = ({ annex: { id, days, type } }: NewProps) => (
  <Card key={id} className="flex items-center justify-between p-5 gap-7">
    <div className="flex-1 flex items-center gap-4 pl-2">
      <MdError
        className="text-danger-400 text-xl"
        data-testid="CANCELLED_ANNEX"
      />
      <h4 className="font-semibold text-neutral-800 text-base">
        <FormattedMessage {...messages.annex} />
        <p className="flex items-center gap-1 font-normal text-danger-700 text-sm">
          <FormattedMessage
            {...cancelledAnnexTypesMessages[type]}
            values={{ days }}
          />
        </p>
      </h4>
    </div>
  </Card>
);
