import { Badge, BadgeVariant } from '@cooltra/ui';
import { HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { ApprovalReviewType } from '@cooltra/api';

import messages from './messages';

const stateVariants: { [key in ApprovalReviewType]: BadgeVariant } = {
  APPROVED: 'success',
  REJECTED: 'danger',
  CORRECTIONS_REQUESTED: 'warning',
};

export type UserApprovalReviewStateBadgeProps =
  HTMLAttributes<HTMLSpanElement> & {
    state: ApprovalReviewType;
  };

export const UserApprovalReviewStateBadge = ({
  state,
  ...rest
}: UserApprovalReviewStateBadgeProps) => (
  <Badge variant={stateVariants[state]} {...rest}>
    <FormattedMessage {...messages[state]} />
  </Badge>
);
