import { useIntl } from 'react-intl';

import { ConfirmModal } from '../ConfirmModal/ConfirmModal';

import messages from './messages';

export type DeleteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isDeleting?: boolean;
};

export const DeleteModal = ({
  isOpen,
  onClose,
  onConfirm,
  isDeleting = false,
}: DeleteModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <ConfirmModal
      destructive
      dataTestId="DELETE_MODAL"
      title={formatMessage(messages.title)}
      content={formatMessage(messages.content)}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      loading={isDeleting}
    />
  );
};
