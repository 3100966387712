import { BadgePlaceholder, Placeholder } from '@cooltra/ui';

import {
  SidebarHeadingLoading,
  SidebarIconItemLoading,
  SidebarLinksLoading,
} from '~/common';

export const SidebarLoading = () => (
  <>
    <div className="px-8 mt-8">
      <BadgePlaceholder className="w-24 mb-10" />
      <SidebarHeadingLoading className="mb-3" />
      <Placeholder className="h-3 w-24 mb-2" />
      <Placeholder className="h-3 w-32 mb-6" />
      <Placeholder className="rounded-full w-10 h-10 mb-2" />
      <Placeholder className="h-3 w-28 mb-2" />
      <Placeholder className="h-3 w-16 mb-8" />
      <Placeholder className="h-3 w-16 mb-2" />
      <Placeholder className="h-3 w-32 mb-12" />
      <SidebarIconItemLoading className="mb-12" />
    </div>
    <SidebarLinksLoading linksNumber={4} />
  </>
);
