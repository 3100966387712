import {
  InvoiceItem,
  useInvoiceQuery,
  useRefundInvoiceItemMutation,
} from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Button } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { isBefore, parse } from 'date-fns';
import { useParams } from 'react-router-dom';

import { dateFormat } from '~/utils/date';
import { useNotification, usePolling } from '~/hooks';

import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';

import messages from './messages';

export const isElectricFeelInvoice = (paidAt: string) => {
  const cutoffDate = parse('19-12-2022', dateFormat, new Date());
  return isBefore(new Date(paidAt), cutoffDate);
};

export type RefundInvoiceItemProps = Pick<InvoiceItem, 'id' | 'type'>;

export const RefundInvoiceItem = ({ id, type }: RefundInvoiceItemProps) => {
  const { formatMessage } = useIntl();
  const { invoiceId = '' } = useParams();

  const [isModalOpen, { toggleOn, toggleOff }] = useToggle();
  const { hasPermission } = useAuthClaimsQuery();

  const { data: invoice, refetch } = useInvoiceQuery(invoiceId);

  const { addErrorNotification } = useNotification();
  const { mutateAsync, isSuccess, isPending, reset } =
    useRefundInvoiceItemMutation(invoiceId, id, { type });

  const isItemRefunded =
    invoice?.items.find((item) => item.id === id)?.chargeState === 'REFUNDED';

  const { isPolling } = usePolling({
    fn: refetch,
    onSettle: () => {
      reset();
      toggleOff();
    },
    interval: isSuccess && isItemRefunded ? 2000 : 0,
  });

  const handleConfirm = () => mutateAsync().catch(() => addErrorNotification());

  if (!invoice) {
    return <></>;
  }

  if (!hasPermission('write:invoices')) {
    return <span data-testid="EMPTY_REFUND_BUTTON" />;
  }

  if (!invoice.paidAt || isElectricFeelInvoice(invoice.paidAt)) {
    return <></>;
  }

  return (
    <>
      <ConfirmModal
        isOpen={isModalOpen}
        title={formatMessage(messages.refundItem)}
        content={formatMessage(messages.areYouSureToRefund)}
        onClose={toggleOff}
        onConfirm={handleConfirm}
        loading={isPending || isPolling}
      />
      <Button size="sm" emphasis="medium" onClick={toggleOn}>
        <FormattedMessage {...messages.refund} />
      </Button>
    </>
  );
};
