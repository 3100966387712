import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { getErrorStatus } from '@cooltra/axios';
import {
  Rental,
  useCloseRentalMutation,
  useRentalTransactionQuery,
} from '@cooltra/api';
import { Card, Divider } from '@cooltra/ui';
import { AxiosError } from 'axios';
import { FormButton, useFormContext } from '@cooltra/form';
import { FormEvent, useEffect } from 'react';

import { useNotification } from '~/hooks';

import { RentalPriceEstimation } from '../RentalPriceEstimation/RentalPriceEstimation';
import { RentalManualCloseFormValues } from '../RentalManualCloseForm';
import { BillableDurationField } from '../BillableDurationField/BillableDurationField';
import { RentalPricingSummary } from '../../RentalPricingSummary';

import messages from './messages';

export type RentalManualCloseProps = {
  rental: Rental;
};

export const RentalManualClose = ({ rental }: RentalManualCloseProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { addErrorNotification } = useNotification();

  const { values, setSubmitting, touchAll, isValid } =
    useFormContext<RentalManualCloseFormValues>();

  const { data: closeRentalTransaction, mutateAsync } = useCloseRentalMutation(
    rental.rentalId
  );

  const { data: transaction, isSuccess } = useRentalTransactionQuery(
    String(closeRentalTransaction?.data.tid),
    {
      enabled: Boolean(closeRentalTransaction?.data.tid),
      refetchInterval: ({ state }) => {
        const { data: transaction } = state;
        if (!transaction || transaction.status === 'waiting') {
          return 1000;
        } else {
          setSubmitting(false);
          return false;
        }
      },
    }
  );

  useEffect(() => {
    if (transaction && isSuccess) {
      let errorNotification;
      if (transaction.result?.status === 500) {
        errorNotification = formatMessage(messages.somethingWentWrong);
      }

      if (transaction.result?.status === 409) {
        errorNotification = formatMessage(
          messages.closeActionAlreadyInProgress
        );
      }

      if (transaction.result?.body?.error === 'telematics_error') {
        errorNotification = formatMessage(messages.theVehicleCouldNotBeLocked);
      }

      if (errorNotification) {
        addErrorNotification(errorNotification);
      } else {
        queryClient.invalidateQueries({
          queryKey: ['rental', rental.rentalId],
        });
        queryClient.invalidateQueries({
          queryKey: ['rental-timeline', rental.rentalId],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, rental.rentalId, transaction]);

  const handleClosingRental = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    touchAll();

    if (isValid) {
      setSubmitting(true);
      return mutateAsync({
        minutes: Number(values.billableDuration),
        system: rental.system,
      }).catch((error: AxiosError) => {
        let errorNotification;

        switch (getErrorStatus(error)) {
          case 401:
            errorNotification = formatMessage(messages.notAuthorisedError);
            break;
          case 404:
            errorNotification = formatMessage(messages.rentalNotFoundError);
            break;
          case 422:
            errorNotification = formatMessage(messages.missingMinutesError);
            break;
          default:
            errorNotification = formatMessage(messages.somethingWentWrong);
            break;
        }
        setSubmitting(false);
        addErrorNotification(errorNotification);
      });
    }
  };

  return (
    <Card className="px-6 pt-4 pb-6 h-full" data-testid="RENTAL_MANUAL_CLOSE">
      {transaction?.status === 'complete' || rental.dropOff?.createdAt ? (
        <RentalPricingSummary rental={rental} />
      ) : (
        <form onSubmit={handleClosingRental}>
          <BillableDurationField />
          <Divider />
          <RentalPriceEstimation rental={rental} />
          <div className="mt-6 text-right">
            <FormButton variant="danger" size="sm">
              <FormattedMessage {...messages.closeRental} />
            </FormButton>
          </div>
        </form>
      )}
    </Card>
  );
};
