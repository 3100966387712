import { defineMessages } from 'react-intl';

export default defineMessages({
  takePicture: {
    defaultMessage: 'Take picture',
  },
  changePicture: {
    defaultMessage: 'Change picture',
  },
});
