import {
  format,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  isToday,
} from 'date-fns';
import { HTMLAttributes, ReactNode } from 'react';
import { FormattedDate } from 'react-intl';
import { classNames } from '@cooltra/utils';

import { dateFormat } from '~/utils/date';

import { CalendarCell, CalendarLabel, HeaderCell } from '../Calendar';

const weekStartsOn = 1;

export const getMonthData = (date: Date) => {
  const monthStart = startOfMonth(date);
  const monthEnd = endOfMonth(date);
  const startDate = startOfWeek(monthStart, { weekStartsOn });
  const endDate = endOfWeek(monthEnd, { weekStartsOn });

  const dateInterval = { start: startDate, end: endDate };
  const dates = eachDayOfInterval(dateInterval);

  const weeks: Date[][] = [];
  let week: Date[] = [];

  dates.forEach((date, index) => {
    week.push(date);

    if (week.length === 7 || index === dates.length - 1) {
      weeks.push(week);
      week = [];
    }
  });

  return weeks;
};

export type MonthlyCalendarProps = HTMLAttributes<HTMLDivElement> & {
  displayDate?: Date;
  renderDay: (date: Date) => ReactNode;
};

export const MonthlyCalendar = ({
  displayDate = new Date(),
  renderDay,
  className,
  ...rest
}: MonthlyCalendarProps) => {
  const monthData = getMonthData(displayDate);

  return (
    <div
      className={classNames(
        'h-full overflow-scroll no-scrollbar pb-8',
        className
      )}
      {...rest}
    >
      <div className="bg-neutral-0">
        <div className="grid grid-cols-7 sticky top-0 z-10 bg-neutral-0">
          {monthData[0].map((date) => (
            <HeaderCell
              className="first:border-l border-r border-b border-t"
              key={`row-label-${format(date, dateFormat)}`}
            >
              <CalendarLabel>
                <FormattedDate weekday="short" value={date} />
              </CalendarLabel>
            </HeaderCell>
          ))}
        </div>
        {monthData.map((week, index) => (
          <div
            key={`monthly-calendar-week-${index}`}
            className="grid grid-cols-7"
          >
            {week.map((date) => (
              <CalendarCell
                className="first:border-l border-r border-b p-1 h-44"
                key={`monthly-calendar-day-${format(date, dateFormat)}`}
              >
                <div className="flex justify-end">
                  <span
                    className={classNames(
                      'w-6 h-6 rounded-full flex justify-center items-center text-xs font-medium',
                      isToday(date)
                        ? 'text-neutral-0 bg-neutral-800'
                        : 'text-neutral-400 bg-neutral-0'
                    )}
                  >
                    <FormattedDate value={date} day="2-digit" />
                  </span>
                </div>
                {renderDay(date)}
              </CalendarCell>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
