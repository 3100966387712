import { defineMessages } from 'react-intl';

import { ReviewOutcome } from './types';

export default defineMessages({
  activateButton: {
    defaultMessage: 'Activate',
  },
  conflictErrorMessage: {
    defaultMessage:
      'The user details have been updated in the meantime. Please refresh the page.',
  },
  errorMessage: {
    defaultMessage: 'Something went wrong!',
  },
});

export const reviewOutcomeTexts = defineMessages<ReviewOutcome>({
  rejected: {
    defaultMessage: 'User has been rejected',
  },
  requestedChanges: {
    defaultMessage: 'Changes have been requested',
  },
  activated: {
    defaultMessage: 'User has been activated',
  },
});
