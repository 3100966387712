import { FormattedMessage } from 'react-intl';
import { useFormContext, FormAutocompleteField } from '@cooltra/form';
import { useTaskTemplatesQuery } from '@cooltra/api';
import { System } from '@cooltra/auth-api';

import {
  IncidentFormValues,
  incidentInitialFormValues,
} from '../../incident-form';

import messages from './messages';

export type TitleProps = {
  system?: System;
};

export const Title = ({ system }: TitleProps) => {
  const { setValues, isFormDisabled } = useFormContext<IncidentFormValues>();
  const { isLoading, data } = useTaskTemplatesQuery(
    {
      system,
      type: 'incident',
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleSelectedItemChange = ({
    selectedItem,
  }: {
    selectedItem?: string | null | undefined;
  }) => {
    if (!selectedItem) {
      return;
    }
    const selectedTaskTemplate = data?.find(
      ({ title }) => title === selectedItem
    );
    if (selectedTaskTemplate) {
      setValues({
        ...incidentInitialFormValues,
        ...selectedTaskTemplate,
      });
    }
  };

  return (
    <FormAutocompleteField
      data-testid="INCIDENT_TITLE_FIELD"
      name="title"
      disabled={isFormDisabled || isLoading}
      onSelectedItemChange={handleSelectedItemChange}
      items={data?.map(({ title }) => title) || []}
      label={<FormattedMessage {...messages.title} />}
    />
  );
};
