import { defineMessages } from 'react-intl';

export default defineMessages({
  rentalPaused: {
    defaultMessage: 'Rental paused',
  },
  rentalResumed: {
    defaultMessage: 'Rental resumed',
  },
});
