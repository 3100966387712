import { FormattedMessage } from 'react-intl';

import messages from './messages';
import { ParkingPhotoField } from './ParkingPhotoField/ParkingPhotoField';

export const ParkingPhoto = () => (
  <div className="max-w-xs w-full h-full">
    <h2 className="font-semibold text-xl text-neutral-800 mb-5">
      <FormattedMessage {...messages.picture} />
    </h2>
    <ParkingPhotoField />
  </div>
);
