import { FormattedMessage } from 'react-intl';
import { InputError, InputLabel, SelectField } from '@cooltra/ui';
import { useField, useFormContext } from '@cooltra/form';
import { useOperatorsQuery } from '@cooltra/auth-api';

import { operatorGroups } from '~/utils/operators';

import messages from './messages';

type FormWithOperator = {
  operatorId: string;
  operatorGroupId: string;
};

export const Assignee = () => {
  const { isSubmitting, setValues, values, isFormDisabled } =
    useFormContext<FormWithOperator>();

  const {
    value: operatorGroupId,
    error,
    touched,
    setTouched,
  } = useField('operatorGroupId');

  const { data = [], isLoading } = useOperatorsQuery();

  const handleChange = (val: string) => {
    const chosenOperator = data.find(
      ({ externalId }) => externalId === values.operatorId
    );
    if (chosenOperator?.operatorGroupIds.includes(val)) {
      setValues({ operatorGroupId: val });
    } else {
      setValues({ operatorGroupId: val, operatorId: '' });
    }
  };

  return (
    <SelectField
      key={`assignee-${operatorGroupId}`}
      isLoading={isLoading}
      noOptionsMessage={() => <></>}
      error={touched && error ? <InputError>{error}</InputError> : undefined}
      id="task-assignee"
      disabled={isFormDisabled || isSubmitting}
      isClearable
      options={Object.values(operatorGroups).map(
        ({ name, operatorGroupId }) => ({
          label: name,
          value: operatorGroupId,
        })
      )}
      label={
        <InputLabel htmlFor="task-assignee">
          <FormattedMessage {...messages.assignee} />
        </InputLabel>
      }
      onChange={handleChange}
      value={operatorGroupId}
      onBlur={() => {
        setTouched(true);
      }}
    />
  );
};
