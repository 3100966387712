import { PackState as ApiPackState } from '@cooltra/api';
import { Badge } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type PackStateProps = {
  state: ApiPackState;
};

export const PackState = ({ state }: PackStateProps) => {
  if (state === 'EXPIRED') {
    return (
      <Badge emphasis="low">
        <FormattedMessage {...messages.expired} />
      </Badge>
    );
  }

  if (state === 'SCHEDULED') {
    return (
      <Badge>
        <FormattedMessage {...messages.scheduled} />
      </Badge>
    );
  }

  return (
    <Badge variant="success">
      <FormattedMessage {...messages.active} />
    </Badge>
  );
};
