import { useFormContext } from '@cooltra/form';

import { RouterPrompt } from '../RouterPrompt/RouterPrompt';

export const UnsavedChangesPrompt = () => {
  const { dirty, handleSubmit, isSubmitting, isValid, touchAll } =
    useFormContext();

  const handleConfirm = () => {
    if (isValid) {
      return handleSubmit();
    } else {
      touchAll();
      return Promise.reject();
    }
  };

  return (
    <RouterPrompt
      isBlocking={dirty}
      onConfirm={handleConfirm}
      isConfirming={isSubmitting}
    />
  );
};
