import { ReservationColumnLabel } from '~/common';

import { ReservationRowLayout } from './ReservationRowLayout';

export const ReservationsListHeader = () => (
  <ReservationRowLayout
    data-testid="RESERVATIONS_LIST_HEADER"
    createdAt={<ReservationColumnLabel name="createdAt" />}
    expiresAt={<ReservationColumnLabel name="expiresAt" />}
    user={<ReservationColumnLabel name="user" />}
    vehicle={<ReservationColumnLabel name="vehicle" />}
    system={<ReservationColumnLabel name="system" />}
    checkIn={<ReservationColumnLabel name="checkIn" />}
    problemReports={<ReservationColumnLabel name="problemReports" />}
    state={<ReservationColumnLabel name="state" />}
  />
);
