import { FormattedMessage, useIntl } from 'react-intl';
import { Legend, Link } from '@cooltra/ui';
import { System } from '@cooltra/auth-api';
import {
  useFormContext,
  FormRadioButtonGroupField,
  FormLockValue,
} from '@cooltra/form';
import { MdBadge } from 'react-icons/md';

import { SystemCountry } from '../../../System';
import { UserFields } from '../../UserFields';
import * as IdCardFields from '../../IdCardFields';
import { ReviewFormValues } from '../review-form-types';
import { CardLayout } from '../CardLayout/CardLayout';

import messages from './messages';

export type IdCheckProps = {
  system: System;
  userId: string;
};

export const IdCheck = ({ system, userId }: IdCheckProps) => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext<ReviewFormValues>();

  return (
    <CardLayout
      data-testid="ID_CHECK"
      icon={<MdBadge />}
      heading={<FormattedMessage {...messages.heading} />}
    >
      <div className="grid gap-5">
        <FormRadioButtonGroupField
          legend={
            <Legend
              tooltipProps={{
                interactive: true,
                delay: [0, 200],
              }}
              tooltip={
                <div className="max-w-sm">
                  <p className="text-sm font-normal leading-relaxed">
                    <FormattedMessage {...messages.tooltipBody} />
                  </p>
                  <p className="text-sm font-normal leading-relaxed align-middle">
                    <FormattedMessage
                      {...messages.tooltipHelp}
                      values={{
                        csTableLink: (
                          <Link
                            target="_blank"
                            href="https://cooltra-plugin.web.app/permisos"
                            className="text-sm font-semibold"
                          >
                            <FormattedMessage {...messages.tooltipLink} />
                          </Link>
                        ),
                      }}
                    />
                  </p>
                </div>
              }
            >
              <FormattedMessage
                {...messages.isValidId}
                values={{ country: <SystemCountry system={system} /> }}
              />
            </Legend>
          }
          name="isValidId"
          items={[
            {
              value: false,
              variant: 'invalid',
              label: formatMessage(messages.invalid),
              id: 'id-invalid',
            },
            {
              value: true,
              variant: 'valid',
              label: formatMessage(messages.valid),
              id: 'id-valid',
            },
          ]}
        />
        {values.isValidId && (
          <>
            <IdCardFields.ValidatedIdNumber userId={userId} />
            <UserFields.DateOfBirth />
            <FormLockValue
              id="id-same-as-tax-id"
              label={formatMessage(messages.idSameAsTaxId)}
              fromField="idCardNumber"
              toField="taxIdNumber"
            >
              {(locked) => <IdCardFields.TaxIdNumber disabled={locked} />}
            </FormLockValue>
            <IdCardFields.IdCardCountry />
          </>
        )}
      </div>
    </CardLayout>
  );
};
