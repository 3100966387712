import { defineMessages } from 'react-intl';

export default defineMessages({
  selectPricingGroup: {
    defaultMessage: 'Select pricing group',
  },
  pricingGroup: {
    defaultMessage: 'Pricing group',
  },
});
