import { defineMessages } from 'react-intl';

export default defineMessages({
  entryChannel: {
    defaultMessage: 'Entry channel',
  },
  delivery: {
    defaultMessage: 'Delivery',
  },
  return: {
    defaultMessage: 'Return',
  },
  servicePoint: {
    defaultMessage: 'Service point',
  },
  dateAndTime: {
    defaultMessage: 'Date and time',
  },
  conflict: {
    defaultMessage:
      'New service point dont have the same vehicle type or accesories. You must refund paid tickets and release deposits.',
  },
});
