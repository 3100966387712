import { Placeholder } from '@cooltra/ui';
import { FormattedDate, FormattedTime } from 'react-intl';
import { classNames } from '@cooltra/utils';

export type DateTimeProps = {
  date: string | undefined | null;
  dateClassName?: string;
  timeClassName?: string;
};

export const DateTime = ({
  date,
  dateClassName,
  timeClassName,
}: DateTimeProps) =>
  date ? (
    <span className="block text-sm truncate">
      <span
        className={classNames(dateClassName || 'text-neutral-800 font-medium')}
      >
        <FormattedDate
          value={new Date(date)}
          day="2-digit"
          month="short"
          year="numeric"
        />
      </span>
      <span className={classNames(timeClassName || 'text-neutral-600')}>
        {' '}
        -{' '}
      </span>
      <span className={classNames(timeClassName || 'text-neutral-600')}>
        <FormattedTime
          value={new Date(date)}
          hour="2-digit"
          hourCycle="h23"
          minute="2-digit"
        />
      </span>
    </span>
  ) : (
    <div className="h-5 flex items-center">
      <Placeholder className="h-2 w-32" />
    </div>
  );
