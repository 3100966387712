import {
  Reservation,
  useReservationsQuery,
  useReservationsTotal,
} from '@cooltra/api';
import { InfiniteList } from '@cooltra/ui';
import { useParams } from 'react-router-dom';

import { sortByDate } from '~/utils/date';

import { ReservationsTotal } from '../Reservation';
import { ErrorPage } from '../ErrorPage';

import { NoUserReservations } from './NoUserReservations/NoUserReservations';
import { UserReservationsListHeader } from './UserReservationsListHeader';
import {
  UserReservationCard,
  UserReservationCardLoading,
} from './UserReservationCard';

export const UserReservations = () => {
  const { id: userId = '' } = useParams<'id'>();

  const { isError, isLoading, data, fetchNextPage, isFetchingNextPage } =
    useReservationsQuery({ userId }, { enabled: !!userId });

  const reservations = data?.pages
    ? ([] as Reservation[]).concat.apply([], data.pages)
    : [];

  const reservationsTotal = useReservationsTotal({ userId }) || 0;

  const noReservationsFound = !isLoading && !reservations?.length;

  if (isError) {
    return <ErrorPage />;
  }

  if (noReservationsFound) {
    return <NoUserReservations />;
  }

  const sortedReservations = reservations.sort(sortByDate());

  return (
    <div
      className="container min-w-3xl max-w-5xl py-12"
      data-testid="USER_RESERVATIONS"
    >
      <ReservationsTotal
        total={reservationsTotal}
        loading={isLoading}
        className="mb-6"
      />
      <InfiniteList
        isLoading={isLoading}
        idProp="reservationId"
        fetchMore={fetchNextPage}
        hasMore={reservations.length < reservationsTotal}
        isFetchingNextPage={isFetchingNextPage}
        data={sortedReservations}
        renderHeader={<UserReservationsListHeader />}
        renderLoadingRow={<UserReservationCardLoading />}
        renderRow={(reservation: Reservation) => (
          <UserReservationCard reservation={reservation} userId={userId} />
        )}
      />
    </div>
  );
};
