import { Vehicle } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { StaticField } from '~/common';

import messages from './messages';

export type IdNumberStaticProps = {
  value: Vehicle['identificationNumber'];
};

export const IdNumberStatic = ({ value }: IdNumberStaticProps) => (
  <StaticField
    renderLabel={<FormattedMessage {...messages.identificationNumber} />}
    renderValue={value}
  />
);
