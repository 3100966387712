import { Image, Placeholder } from '@cooltra/ui';
import { useFetchDocument } from '@cooltra/api';
import { classNames } from '@cooltra/utils';
import { useToggle } from '@cooltra/hooks';

import { PhotoModal } from '~/common';
import { useRotation } from '~/hooks';

type PhotoCardProps = {
  parkingPhoto: string;
};

export const PhotoCard = ({ parkingPhoto }: PhotoCardProps) => {
  const parkingPhotoImageData = useFetchDocument(parkingPhoto);
  const [parkingPhotoModalOpen, { toggleOn, toggleOff }] = useToggle();
  const landscapeRotations = [90, -90, 270, -270];
  const { rotation, rotationClassName, rotateLeft, rotateRight } =
    useRotation();
  const isLandscapeMode = landscapeRotations.includes(rotation);

  if (!parkingPhotoImageData) {
    return <Placeholder className="w-full h-[48rem]" />;
  }

  const parkingPhotoBase64 = parkingPhotoImageData
    ? `data:image/jpeg;base64,${parkingPhotoImageData}`
    : '';

  return (
    <div className="w-full h-[48rem]">
      <Image
        src={parkingPhotoBase64}
        data-testid={
          parkingPhotoBase64 ? 'RENTAL_PHOTO_LOADED' : 'RENTAL_PHOTO'
        }
        className={classNames(
          'cursor-pointer transition-opacity hover:opacity-60 object-contain',
          isLandscapeMode ? 'w-[48rem] h-full max-w-none' : 'w-full h-[48rem]',
          rotationClassName
        )}
        onClick={toggleOn}
      />
      <PhotoModal
        isOpen={parkingPhotoModalOpen}
        onRequestClose={toggleOff}
        rotateLeft={rotateLeft}
        rotateRight={rotateRight}
        className={rotationClassName}
        imageData={parkingPhotoBase64}
      />
    </div>
  );
};
