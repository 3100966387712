import { defineMessages } from 'react-intl';

export default defineMessages({
  selectedResults: {
    defaultMessage:
      'Selected {selected} of {total, plural, one {# vehicle} other {# vehicles}}',
  },
  result: {
    defaultMessage: '{total, plural, one {# vehicle} other {# vehicles}}',
  },
});
