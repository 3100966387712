import { ReservationColumnLabel } from '~/common';

import { VehicleReservationRowLayout } from './VehicleReservationRowLayout';

export const VehicleReservationsListHeader = () => (
  <VehicleReservationRowLayout
    createdAt={<ReservationColumnLabel name="createdAt" />}
    expiresAt={<ReservationColumnLabel name="expiresAt" />}
    user={<ReservationColumnLabel name="user" />}
    system={<ReservationColumnLabel name="system" />}
    checkIn={<ReservationColumnLabel name="checkIn" />}
    problemReports={<ReservationColumnLabel name="problemReports" />}
    state={<ReservationColumnLabel name="state" />}
  />
);
