import { FormattedMessage } from 'react-intl';
import { RouterLink } from '@cooltra/navigation';
import { shortenId } from '@cooltra/utils';
import { Divider } from '@cooltra/ui';
import { VehicleParkedNext } from '@cooltra/api';
import { differenceInHours } from 'date-fns';
import { MdCancel, MdCheckCircle } from 'react-icons/md';

import { TimeAgo } from '~/common';

import messages from './messages';

export const Features = ({
  rentalId,
  vehicle,
}: Pick<VehicleParkedNext, 'rentalId' | 'vehicle'>) => {
  const idleFromInHours = differenceInHours(
    new Date(),
    new Date(vehicle.idleFrom)
  );
  const hoursInADay = 24;

  return (
    <ul className="p-8 flex flex-col gap-4" data-testid="RENTALS_DATA_FEATURES">
      <li className="flex justify-between text-sm">
        <span className="text-neutral-500">
          <FormattedMessage {...messages.idleVehicleTime} />
        </span>
        <span className="text-neutral-900 font-semibold">
          {idleFromInHours <= hoursInADay ? (
            <TimeAgo ISODate={vehicle.idleFrom} />
          ) : (
            <FormattedMessage
              {...messages.hoursAgo}
              values={{ hours: idleFromInHours }}
            />
          )}
        </span>
      </li>
      <Divider />
      <li className="flex justify-between text-sm">
        <span className="text-neutral-500">
          <FormattedMessage {...messages.parkedOnTheSidewalk} />
        </span>
        {vehicle.sidewalkParked ? (
          <span className="text-danger-600 flex gap-1 items-center">
            <MdCancel />
            <FormattedMessage {...messages.yes} />
          </span>
        ) : (
          <span className="text-success-700 flex gap-1 items-center">
            <MdCheckCircle />
            <FormattedMessage {...messages.no} />
          </span>
        )}
      </li>
      <Divider />
      <li className="flex justify-between text-sm">
        <span className="text-neutral-500">
          <FormattedMessage {...messages.rental} />
        </span>
        <RouterLink to={`/rentals/${rentalId}`}>
          #{shortenId(rentalId)}
        </RouterLink>
      </li>
      <Divider />
      <li className="flex justify-between text-sm">
        <span className="text-neutral-500">
          <FormattedMessage {...messages.vehicle} />
        </span>
        <RouterLink to={`/vehicles/${vehicle.vehicleId}`}>
          {vehicle.licensePlate}
        </RouterLink>
      </li>
    </ul>
  );
};
