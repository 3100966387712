import { defineMessages } from 'react-intl';

export default defineMessages({
  seeMap: {
    defaultMessage: 'See map',
  },
  noGeolocation: {
    defaultMessage: 'This vehicle cannot be geolocated',
  },
  vehicleGeolocation: {
    defaultMessage: 'Vehicle geolocation',
  },
});
