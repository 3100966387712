import { defineMessages } from 'react-intl';

export default defineMessages({
  swaps: {
    defaultMessage: 'swaps',
  },
  vehiclesWorked: {
    defaultMessage: 'vehicles worked',
  },
  vehiclesSkipped: {
    defaultMessage: 'vehicles skipped',
  },
  energyAdded: {
    defaultMessage: 'energy added',
  },
  operational: {
    defaultMessage: 'operational',
  },
  averageEnergy: {
    defaultMessage: 'average energy',
  },
  closedTasks: {
    defaultMessage: 'closed tasks',
  },
  openedTasks: {
    defaultMessage: 'opened tasks',
  },
  recollectionTakes: {
    defaultMessage: 'recollection takes',
  },
  moreReasonsTakes: {
    defaultMessage: 'more reasons takes',
  },
  rebalanceTakes: {
    defaultMessage: 'rebalance takes',
  },
  totalTakes: {
    defaultMessage: 'total takes',
  },
});
