import { defineMessages } from 'react-intl';

export default defineMessages({
  contract: {
    defaultMessage: 'Contract',
  },
  signed: {
    defaultMessage: 'Signed',
  },
  signedByEmail: {
    defaultMessage: 'Signed by email',
  },
  signedOn: {
    defaultMessage: 'Signed on {date}',
  },
  seeContract: {
    defaultMessage: 'See contract',
  },
  by: {
    defaultMessage: 'by',
  },
});
