import { defineMessages } from 'react-intl';

export default defineMessages({
  depositTitle: {
    defaultMessage: 'Deposit',
  },
  coverage: {
    defaultMessage: 'Coverage',
  },
  eBike: {
    defaultMessage: 'Ebike',
  },
  standard: {
    defaultMessage: 'Standard',
  },
  plus: {
    defaultMessage: 'Plus',
  },
  premium: {
    defaultMessage: 'Premium',
  },
  superpremium: {
    defaultMessage: 'Super premium',
  },
});
