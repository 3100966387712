import { defineMessages } from 'react-intl';

export default defineMessages({
  sendCheckInEmail: {
    defaultMessage: 'Send check-in email',
  },
  success: {
    defaultMessage: 'Check-in email sent',
  },
  modalTitle: {
    defaultMessage: 'Send check-in email',
  },
  modalContent: {
    defaultMessage: 'Are you sure you want to send the check-in email?',
  },
});
