import { IntlShape } from 'react-intl';

import { createSetError, validateAmountWithoutDecimals } from '~/validation';

import { DepositsFormValues, VehicleCategory } from './form-details';

type DepositsFormFieldName = keyof DepositsFormValues;

type DepositsFormErrors = Partial<
  Record<DepositsFormFieldName, string | undefined>
>;

export const validateForm =
  (intl: IntlShape, enabledVehicleCategories: VehicleCategory[]) =>
  (values: DepositsFormValues) => {
    const errors: DepositsFormErrors = {};
    const setError = createSetError(errors);

    enabledVehicleCategories.forEach((vehicleCategory) => {
      setError(
        `prices.${vehicleCategory}.price.value`,
        validateAmountWithoutDecimals(
          intl,
          values[`prices.${vehicleCategory}.price.value`]
        )
      );
    });

    return errors;
  };
