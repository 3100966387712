import { FormattedMessage } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import {
  InputLabel,
  InputField,
  SelectField,
  TopLoadingBar,
} from '@cooltra/ui';
import { usePreferredLanguage } from '@cooltra/preferred-language';
import { getLanguageOptions } from '@cooltra/countries';

import { SectionLayout } from '../SectionLayout/SectionLayout';

import messages from './messages';

export const DetailsForm = () => {
  const { user } = useAuth0();
  const email = user?.email || '';

  const { updateLanguage, language, loadingLanguage } = usePreferredLanguage();

  const handleUpdatingLocale = (lang: string) => {
    updateLanguage(lang);
  };

  return (
    <>
      {loadingLanguage && <TopLoadingBar />}
      <SectionLayout
        renderHeader={
          <div className="flex items-center">
            <h4 className="text-xl font-semibold text-neutral-500">
              <FormattedMessage {...messages.accountDetailsHeader} />
            </h4>
          </div>
        }
        renderLeftContent={
          <div className="flex flex-col gap-6">
            <SelectField
              id="language"
              value={language}
              options={getLanguageOptions(['ca', 'pt', 'nl'])}
              onChange={handleUpdatingLocale}
              label={
                <InputLabel htmlFor="language">
                  <FormattedMessage {...messages.languageLabel} />
                </InputLabel>
              }
            />
            <InputField
              name="email"
              type="email"
              disabled
              value={email}
              label={
                <InputLabel htmlFor="email">
                  <FormattedMessage {...messages.email} />
                </InputLabel>
              }
            />
            <div>
              <span className="block text-neutral-800 font-semibold text-base mb-2">
                <FormattedMessage {...messages.password} />
              </span>
              <a
                href="https://account.activedirectory.windowsazure.com/ChangePassword.aspx"
                className="btn btn-variant-primary--medium btn-size-md"
              >
                <FormattedMessage {...messages.resetPassword} />
              </a>
            </div>
          </div>
        }
      />
    </>
  );
};
