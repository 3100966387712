import {
  useVehicleQuery,
  useUpdateVehicleMutation,
} from '@cooltra/station-based-api';
import { Card, SpinnerScreen } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Form, FormHelpers, FormProvider } from '@cooltra/form';

import { ErrorPage } from '~/common';
import { useNotification } from '~/hooks';

import {
  formatFormValues,
  getInitialValues,
  validate,
  VehicleFormValues,
  SaveVehicleDetailsButton,
  VehicleFormFields,
} from '../VehicleForm';
import { VehiclesLayout } from '../../VehiclesLayout/VehiclesLayout';

import { VehicleCheckAndDamages } from './VehicleCheckAndDamages';
import { VehicleDetailsNavbar } from './VehicleDetailsNavbar';
import messages from './messages';

export const VehicleDetails = () => {
  const intl = useIntl();
  const { vehicleId = '' } = useParams<'vehicleId'>();

  const { data: vehicle, isError } = useVehicleQuery(vehicleId);

  const { addErrorNotification } = useNotification();

  const { mutateAsync, isSuccess } = useUpdateVehicleMutation(vehicleId);

  const handleSubmit = (
    values: VehicleFormValues,
    { resetForm }: FormHelpers<VehicleFormValues>
  ) =>
    mutateAsync(formatFormValues(values))
      .then(() => resetForm(values))
      .catch(() => addErrorNotification());

  if (isError) {
    return <ErrorPage />;
  }

  if (!vehicle) {
    return <SpinnerScreen />;
  }

  return (
    <VehiclesLayout
      renderNavbar={<VehicleDetailsNavbar vehicle={vehicle} />}
      renderContent={
        <FormProvider
          initialValues={getInitialValues(vehicle)}
          onSubmit={handleSubmit}
          validate={validate(intl)}
        >
          <div className="mx-auto max-w-4xl pt-4 pb-10">
            <Form>
              <h2 className="font-semibold text-xl text-neutral-800 mb-5">
                <FormattedMessage {...messages.vehicleData} />
              </h2>
              <Card className="p-4 grid grid-cols-2">
                <div className="py-4 pl-4 pr-8 border-solid border-r border-neutral-100 flex gap-6">
                  <VehicleFormFields.ServicePoint />
                  <VehicleFormFields.LockCode />
                </div>
                <div className="py-4 pl-8 pr-4">
                  <VehicleFormFields.InTransit />
                </div>
              </Card>
              <div className="flex flex-row justify-between gap-7 mt-16">
                <VehicleFormFields.ParkingPhoto />
                <VehicleFormFields.Geolocation vehicleId={vehicleId} />
              </div>
              <VehicleCheckAndDamages vehicle={vehicle} />
              <SaveVehicleDetailsButton isSuccess={isSuccess} />
            </Form>
          </div>
        </FormProvider>
      }
    />
  );
};
