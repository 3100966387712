import { defineMessages } from 'react-intl';
import { InvoiceState } from '@cooltra/api';

export default defineMessages<InvoiceState>({
  OPEN: {
    defaultMessage: 'Open',
  },
  PAID: {
    defaultMessage: 'Paid',
  },
  FAILED: {
    defaultMessage: 'Failed',
  },
  PROCESSING: {
    defaultMessage: 'Processing',
  },
});
