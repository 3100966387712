import { FormattedMessage, useIntl } from 'react-intl';
import { Badge, BadgeVariant, Icon } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { useField } from '@cooltra/form';
import { stateOperationalConditions } from '@cooltra/vehicle-utils';
import {
  OperationalState,
  OperationalCondition as ApiOperationalCondition,
} from '@cooltra/api';
import { MdCheck, MdClose } from 'react-icons/md';

import {
  ExtendedOperationalCondition,
  useVehiclesFilters,
} from '~/libs/vehicles-filters';

import {
  OperationalConditionSelect,
  OperationalConditionSelectGroup,
} from './OperationalConditionSelect';
import messages, {
  groupMessages,
  operationalConditionMessages,
} from './messages';
import { ListItemButton } from './ListItemButton';

const operationalConditionBadges: {
  [key in OperationalState]: BadgeVariant;
} = {
  OPERATIONAL: 'success',
  NOT_OPERATIONAL: 'danger',
  IN_MAINTENANCE: 'warning',
};

const operationalConditionDotClasses: {
  [key in OperationalState]: string;
} = {
  OPERATIONAL: 'bg-success-200',
  NOT_OPERATIONAL: 'bg-danger-200',
  IN_MAINTENANCE: 'bg-warning-300',
};

export const OperationalCondition = () => {
  const { formatMessage } = useIntl();
  const { setValues } = useVehiclesFilters();
  const { value } = useField<ExtendedOperationalCondition[]>(
    'operationalCondition'
  );

  const isEntireGroupSelected = (group: OperationalState) =>
    stateOperationalConditions[group].every((condition) =>
      value.includes(condition)
    );

  const isEntireGroupDeselected = (group: OperationalState) =>
    stateOperationalConditions[group].every((condition) =>
      value.includes(`!${condition}`)
    );

  const filterOutGroupOptions = (group: OperationalState) =>
    value.filter(
      (val) =>
        !stateOperationalConditions[group].includes(
          val.replace('!', '') as ApiOperationalCondition
        )
    );

  const handleGroupClick = (group: OperationalState) => () => {
    if (isEntireGroupSelected(group)) {
      setValues({
        operationalCondition: [
          ...filterOutGroupOptions(group),
          ...stateOperationalConditions[group].map(
            (condition) => `!${condition}` as ExtendedOperationalCondition
          ),
        ],
      });
    } else if (isEntireGroupDeselected(group)) {
      setValues({
        operationalCondition: filterOutGroupOptions(group),
      });
    } else {
      setValues({
        operationalCondition: [
          ...filterOutGroupOptions(group),
          ...stateOperationalConditions[group],
        ],
      });
    }
  };

  const options: OperationalConditionSelectGroup[] = [
    {
      label: formatMessage(groupMessages.OPERATIONAL),
      group: 'OPERATIONAL',
      options: stateOperationalConditions.OPERATIONAL.filter(
        (value) => value != 'OPERATIONAL'
      ).map((value) => ({
        value,
        label: formatMessage(operationalConditionMessages[value]),
        group: 'OPERATIONAL',
      })),
    },
    {
      label: formatMessage(groupMessages.NOT_OPERATIONAL),
      group: 'NOT_OPERATIONAL',
      options: stateOperationalConditions.NOT_OPERATIONAL.map((value) => ({
        value,
        label: formatMessage(operationalConditionMessages[value]),
        group: 'NOT_OPERATIONAL',
      })),
    },
    {
      label: formatMessage(groupMessages.IN_MAINTENANCE),
      group: 'IN_MAINTENANCE',
      options: stateOperationalConditions.IN_MAINTENANCE.map((value) => ({
        value,
        label: formatMessage(operationalConditionMessages[value]),
        group: 'IN_MAINTENANCE',
      })),
    },
  ];

  return (
    <OperationalConditionSelect
      renderLabel={<FormattedMessage {...messages.label} />}
      values={value}
      onChange={(value) =>
        setValues({
          operationalCondition: value,
        })
      }
      renderOption={({ label, group }) => (
        <div>
          <span
            className={classNames(
              'inline-block 2 h-2 w-2 rounded-full mr-2.5 mb-px',
              operationalConditionDotClasses[group]
            )}
          />
          <span className="text-neutral-600 text-sm">{label}</span>
        </div>
      )}
      renderGroupLabel={({ group, label }) => (
        <ListItemButton
          className="py-6"
          key={group}
          onClick={handleGroupClick(group)}
          data-testid={group}
          isSelected={isEntireGroupSelected(group)}
          isDeselected={isEntireGroupDeselected(group)}
          trailingIcon={
            isEntireGroupSelected(group) ? (
              <Icon className="text-xl text-primary-500">
                <MdCheck />
              </Icon>
            ) : isEntireGroupDeselected(group) ? (
              <Icon className="text-xl text-danger-500">
                <MdClose />
              </Icon>
            ) : undefined
          }
        >
          <Badge variant={operationalConditionBadges[group]}>{label}</Badge>
        </ListItemButton>
      )}
      options={options}
    />
  );
};
