import { defineMessages } from 'react-intl';

export default defineMessages({
  signAnnex: {
    defaultMessage: 'Sign annex',
  },
  annexSignedSuccessfully: {
    defaultMessage: 'Annex signed successfully',
  },
});
