import { Badge } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const SessionRevokedBadge = () => (
  <Badge>
    <FormattedMessage {...messages.revoked} />
  </Badge>
);
