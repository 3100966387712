import {
  Contract,
  useSignedContractPdfByDocumentIdQuery,
} from '@cooltra/station-based-api';
import { FormattedMessage } from 'react-intl';
import { Button } from '@cooltra/ui';
import { useEffect } from 'react';

import { useContractFlags, useNotification } from '~/hooks';

import messages from './messages';

export type SeeContractPDFButtonProps = Pick<
  Contract,
  'contractId' | 'isMigrated'
> & {
  documentId: string;
};

export const SeeContractPDFButton = ({
  contractId,
  documentId,
  isMigrated,
}: SeeContractPDFButtonProps) => {
  const { addErrorNotification } = useNotification();
  const { hasBeenModifiedInEtendo } = useContractFlags();
  const { fetchStatus, refetch, error } = useSignedContractPdfByDocumentIdQuery(
    contractId,
    documentId,
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (error) {
      addErrorNotification();
    }
  }, [addErrorNotification, error]);

  if (isMigrated || hasBeenModifiedInEtendo) {
    return null;
  }

  return (
    <Button
      size="md"
      onClick={() => refetch()}
      loading={fetchStatus === 'fetching'}
      emphasis="medium"
    >
      <FormattedMessage {...messages.seeContract} />
    </Button>
  );
};
