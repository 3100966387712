import { useContext } from 'react';
import { getErrorStatus } from '@cooltra/axios';
import {
  UpdatePricingInsurancePricesPayload,
  usePricingCoveragePricesQuery,
  usePricingInsurancePricesMutation,
} from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Spinner } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { FormButton, FormHelpers } from '@cooltra/form';
import { capitalize } from '@cooltra/utils';

import { ErrorPage, Four0Four, Four0Three } from '~/common';
import { useNotification } from '~/hooks';

import { PriceListTypeContext } from '../PriceListTypeContext';

import { CoveragePriceListDetailsForm } from './Form/CoveratePriceListDetailsForm';
import { CoveragePriceListDetailsFormFields } from './CoveragePriceListDetailsFormFields';
import {
  CoveragePriceFormValues,
  coveragePriceFormInitialValues,
} from './Form/form-details';
import messages from './messages';
import { getFormValuesFromCoverageDetails } from './Form/get-form-values-from-coverage-details';
import { CoveragePriceListDetailsBackLink } from './CoveragePriceListDetailsBackLink';

export const CoveragePriceDetails = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();
  const { type } = useContext(PriceListTypeContext);

  const { coverageId = '' } = useParams<'coverageId'>();
  const {
    data: coveragePrice,
    isLoading,
    isError,
    error,
  } = usePricingCoveragePricesQuery(
    coverageId,
    { type },
    {
      enabled: hasPermission('read:prices'),
    }
  );

  const { mutateAsync } = usePricingInsurancePricesMutation(coverageId);
  const { addSuccessNotification, addErrorNotification } = useNotification();

  if (!hasPermission('read:prices')) {
    return <Four0Three />;
  }

  if (isLoading) {
    return (
      <div className="w-full text-center mt-8">
        <Spinner size="lg" />
      </div>
    );
  }

  if (isError && getErrorStatus(error) === 404) {
    return <Four0Four />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (!coveragePrice) {
    return <Four0Four />;
  }

  const getCoveragePricePayload = (
    values: CoveragePriceFormValues
  ): UpdatePricingInsurancePricesPayload => {
    return {
      price: {
        value: Number(values.price),
        currency: 'EUR',
      },
      algorithmPrice: values.algorithmName,
      type,
    };
  };

  const handleOnSubmit = (
    values: CoveragePriceFormValues,
    { resetForm }: FormHelpers<CoveragePriceFormValues>
  ) => {
    const payload = getCoveragePricePayload(values);

    return mutateAsync(payload)
      .then(() => {
        resetForm(values);
        addSuccessNotification(formatMessage(messages.updateSuccessfully));
      })
      .catch(({ response }) => {
        switch (response.status) {
          case 400:
            addErrorNotification(formatMessage(messages.badFormattedFields));
            break;
          default:
            addErrorNotification();
            break;
        }
      });
  };

  return (
    <div className="container min-w-3xl max-w-4xl py-12 pb-32">
      <CoveragePriceListDetailsForm
        initialValues={{
          ...coveragePriceFormInitialValues,
          ...getFormValuesFromCoverageDetails(coveragePrice),
        }}
        onSubmit={handleOnSubmit}
      >
        <>
          <CoveragePriceListDetailsBackLink />
          <div className="flex items-center justify-between">
            <h2 className="text-3xl">
              {capitalize(coveragePrice.name.toLowerCase())}
            </h2>
            {hasPermission('write:prices') && (
              <FormButton>
                <FormattedMessage {...messages.savePriceList} />
              </FormButton>
            )}
          </div>
          <CoveragePriceListDetailsFormFields />
        </>
      </CoveragePriceListDetailsForm>
    </div>
  );
};
