import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type WorkActionRowLayoutProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'content'
> & {
  time?: ReactNode;
  timeline?: ReactNode;
  content?: ReactNode;
  isLast?: boolean;
};

export const WorkActionRowLayout = ({
  className,
  time,
  timeline,
  content,
  isLast = false,
  ...rest
}: WorkActionRowLayoutProps) => (
  <div className={classNames('flex items-stretch', className)} {...rest}>
    <div className="w-12 mt-0.5 shrink-0 flex justify-end">{time}</div>
    <div className="w-12 mt-2 shrink-0 flex justify-center relative">
      {isLast && (
        <div className="absolute bottom-0 h-full w-10 bg-neutral-50" />
      )}
      {timeline}
    </div>
    <div className="flex-1 text-left">{content}</div>
  </div>
);
