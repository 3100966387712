import { Form, FormProvider, FormProviderProps } from '@cooltra/form';
import { useIntl } from 'react-intl';

import { PriceListFormValues } from './vehicle-type-price-list-details-form';
import { validateForm } from './vehicle-type-price-list-details-form-validations';

export type PriceListFormProps = Omit<
  FormProviderProps<PriceListFormValues>,
  'validate'
>;

export const VehicleTypePriceListDetailsForm = ({
  onSubmit,
  initialValues,
  children,
}: PriceListFormProps) => {
  const intl = useIntl();

  return (
    <FormProvider
      initialValues={initialValues}
      validate={validateForm(intl)}
      onSubmit={onSubmit}
    >
      <Form>{children}</Form>
    </FormProvider>
  );
};
