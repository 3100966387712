import { FormattedMessage, useIntl } from 'react-intl';
import { InputLabel, GroupedSelectField } from '@cooltra/ui';
import { useAuth0 } from '@auth0/auth0-react';

import { operatorGroups } from '~/utils/operators';
import { useVehiclesFilters } from '~/libs/vehicles-filters';

import messages from './messages';

export const Assignee = () => {
  const { formatMessage } = useIntl();
  const { user } = useAuth0();
  const { values, setValues } = useVehiclesFilters();

  return (
    <GroupedSelectField
      key={`${values.taskOperatorGroupId}-${values.taskOperatorId}`}
      id="vehicle-task-assignee"
      isClearable
      placeholder={formatMessage(messages.placeholder)}
      options={[
        {
          label: formatMessage(messages.operators),
          options: [
            {
              label: 'Me',
              value: user?.sub || '',
              group: 'taskOperatorId',
            },
          ],
        },
        {
          label: formatMessage(messages.operatorGroups),
          options: Object.values(operatorGroups).map(
            ({ name, operatorGroupId }) => ({
              label: name,
              value: operatorGroupId,
              group: 'taskOperatorGroupId',
            })
          ),
        },
      ]}
      onChange={(item) => {
        if (!item) {
          setValues({
            ...values,
            taskOperatorId: [],
            taskOperatorGroupId: [],
          });
          return;
        }
        const { group, value } = item;
        setValues({
          ...values,
          taskOperatorId: group === 'taskOperatorId' ? [value] : [],
          taskOperatorGroupId: group === 'taskOperatorGroupId' ? [value] : [],
        });
      }}
      value={values.taskOperatorId[0] || values.taskOperatorGroupId[0] || ''}
      label={
        <InputLabel htmlFor="vehicle-task-assignee">
          <FormattedMessage {...messages.assignee} />
        </InputLabel>
      }
    />
  );
};
