import {
  UserUninvoicedCharge,
  useUserUninvoicedChargesQuery,
} from '@cooltra/api';
import { List } from '@cooltra/ui';

import { ErrorPage } from '~/common';

import {
  UninvoicedChargeCard,
  UninvoicedChargeCardLoading,
} from '../UninvoicedChargeCard';
import { UninvoicedChargesListHeader } from '../UninvoicedChargesListHeader/UninvoicedChargesListHeader';
import { NoUninvoicedCharges } from '../NoUninvoicedCharges/NoUninvoicedCharges';

export type UninvoicedChargesProps = {
  userId: string;
};

export const UninvoicedCharges = ({ userId }: UninvoicedChargesProps) => {
  const { data, isLoading, isError } = useUserUninvoicedChargesQuery(userId);

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div data-testid="USER_UNINVOICED_CHARGES" className="pt-10">
      <List
        loadingRows={4}
        isLoading={isLoading}
        idProp="id"
        data={data || []}
        renderEmptyMessage={<NoUninvoicedCharges />}
        renderHeader={<UninvoicedChargesListHeader />}
        renderLoadingRow={<UninvoicedChargeCardLoading />}
        renderRow={(uninvoicedCharge: UserUninvoicedCharge) => (
          <UninvoicedChargeCard uninvoicedCharge={uninvoicedCharge} />
        )}
      />
    </div>
  );
};
