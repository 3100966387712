import { HTMLAttributes } from 'react';
import { classNames } from '@cooltra/utils';

export type ValueProps = HTMLAttributes<HTMLSpanElement>;

export const Value = ({ className, ...rest }: ValueProps) => (
  <span
    className={classNames(
      'block text-neutral-800 text-sm font-medium',
      className
    )}
    {...rest}
  />
);
