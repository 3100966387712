import { defineMessages } from 'react-intl';

export default defineMessages({
  contractNumber: {
    defaultMessage: 'Contract',
  },
  customer: {
    defaultMessage: 'Customer',
  },
  date: {
    defaultMessage: 'Date',
  },
  vehicle: {
    defaultMessage: 'Vehicle',
  },
  status: {
    defaultMessage: 'Status',
  },
});
