import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { RouterButton } from '@cooltra/navigation';
import { useUserStatesQuery, UserStateType } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { MdOutlineChevronRight } from 'react-icons/md';

import { ErrorPage } from '~/common';
import { canUserBeReviewed } from '~/utils/users';

import { StatesLoading } from '../StatesLoading';
import { TimelineStates } from '../TimelineStates/TimelineStates';

import messages from './messages';

export type StatesProps = {
  state?: UserStateType;
};

export const States = ({ state }: StatesProps) => {
  const { id = '' } = useParams<'id'>();
  const { data, isError } = useUserStatesQuery(id);
  const { hasPermission } = useAuthClaimsQuery();

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div
      className="container min-w-screen-sm max-w-3xl py-12"
      data-testid="USER_STATES"
    >
      <div className="w-full mb-6 flex items-center justify-between">
        <h1 className="text-base text-neutral-600">
          <FormattedMessage {...messages.statusHistory} />
        </h1>
        {hasPermission('write:users') && canUserBeReviewed(state) && (
          <RouterButton
            to="../../review"
            emphasis="high"
            trailingIcon={<MdOutlineChevronRight className="text-xl" />}
          >
            <FormattedMessage {...messages.newReview} />
          </RouterButton>
        )}
      </div>
      {data ? <TimelineStates states={data} /> : <StatesLoading />}
    </div>
  );
};
