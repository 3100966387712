import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckboxField, InputLabel } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';

import { PromotionStateType } from '../../PromotionState/PromotionState';
import { PromotionFormValues } from '../../PromotionForm';

import { ValidFrom } from './ValidFrom';
import { ValidUntil } from './ValidUntil';
import messages from './messages';

export const ValidityDates = () => {
  const { values, setValues, isSubmitting } =
    useFormContext<PromotionFormValues>();
  const { editMode, stateType } = values;
  const editingExpiredPromotion =
    editMode && stateType === PromotionStateType.EXPIRED;

  const handleChange = ({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setValues({
        validUntilDate: '',
        validUntilTime: '',
        promotionDoesntExpire: checked,
      });
    } else {
      setValues({ promotionDoesntExpire: checked });
    }
  };

  return (
    <div className="flex gap-x-8 items-start" data-testid="VALIDITY_DATES">
      <ValidFrom />
      <ValidUntil
        disabled={editingExpiredPromotion || values.promotionDoesntExpire}
      />
      <CheckboxField
        className="mt-8"
        id="promotion-expires"
        checked={values.promotionDoesntExpire}
        label={
          <InputLabel emphasis="low" htmlFor="promotion-expires">
            <FormattedMessage {...messages.doesntExpire} />
          </InputLabel>
        }
        disabled={editingExpiredPromotion || isSubmitting}
        onChange={handleChange}
      />
    </div>
  );
};
