import { allHomeSystemsList } from '@cooltra/api';
import { System } from '@cooltra/auth-api';
import { useIntl } from 'react-intl';

import { cities } from './messages';

export const useAllSystemOptions = () => {
  const { formatMessage } = useIntl();

  return allHomeSystemsList
    .map((system) => ({
      value: system,
      label: formatMessage(cities[system as System]),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
