import { FormattedMessage, useIntl } from 'react-intl';
import { Legend } from '@cooltra/ui';
import { useFormContext, FormRadioButtonGroupField } from '@cooltra/form';
import { MdImage } from 'react-icons/md';

import { ReviewFormValues } from '../review-form-types';
import { CardLayout } from '../CardLayout/CardLayout';

import messages from './messages';

export type PhotosCheckProps = {
  hasDriverLicense: boolean;
  hasIdentityCard: boolean;
};

export const PhotosCheck = ({
  hasDriverLicense,
  hasIdentityCard,
}: PhotosCheckProps) => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext<ReviewFormValues>();

  return (
    <CardLayout
      data-testid="PHOTOS_CHECK"
      icon={<MdImage />}
      heading={<FormattedMessage {...messages.heading} />}
    >
      <div className="grid gap-6">
        <FormRadioButtonGroupField
          legend={
            <Legend
              tooltip={
                <div className="max-w-sm">
                  <p className="text-sm leading-relaxed mb-2">
                    <FormattedMessage {...messages.tooltipBody} />
                  </p>
                  <ul className="list-disc pl-4">
                    <li className="text-sm leading-relaxed">
                      <FormattedMessage {...messages.tooltipItemOne} />
                    </li>
                    <li className="text-sm leading-relaxed">
                      <FormattedMessage {...messages.tooltipItemTwo} />
                    </li>
                    <li className="text-sm leading-relaxed">
                      <FormattedMessage {...messages.tooltipItemThree} />
                    </li>
                  </ul>
                </div>
              }
            >
              <FormattedMessage {...messages.arePhotosValid} />
            </Legend>
          }
          name="arePhotosValid"
          items={[
            {
              value: false,
              variant: 'invalid',
              label: formatMessage(messages.invalid),
              id: 'photos-invalid',
            },
            {
              value: true,
              variant: 'valid',
              label: formatMessage(messages.valid),
              id: 'photos-valid',
            },
          ]}
        />
        {values?.arePhotosValid === false && (
          <>
            <FormRadioButtonGroupField
              legend={
                <Legend>
                  <FormattedMessage {...messages.isFraud} />
                </Legend>
              }
              name="isFraud"
              items={[
                {
                  value: true,
                  variant: 'invalid',
                  label: formatMessage(messages.fraudulent),
                  id: 'fraudulent-photos',
                },
                {
                  value: false,
                  variant: 'valid',
                  label: formatMessage(messages.legitimate),
                  id: 'legitimate-photos',
                },
              ]}
            />
            <div data-testid="VALID_SELFIE_FIELD">
              <FormRadioButtonGroupField
                legend={
                  <Legend>
                    <FormattedMessage {...messages.isValidSelfie} />
                  </Legend>
                }
                name="isValidSelfie"
                items={[
                  {
                    value: false,
                    variant: 'invalid',
                    label: formatMessage(messages.no),
                    id: 'invalid-selfie',
                  },
                  {
                    value: true,
                    variant: 'valid',
                    label: formatMessage(messages.yes),
                    id: 'valid-selfie',
                  },
                ]}
              />
            </div>
            {hasIdentityCard && (
              <div data-testid="VALID_ID_PHOTO_FIELD">
                <FormRadioButtonGroupField
                  legend={
                    <Legend>
                      <FormattedMessage {...messages.isValidIdPhoto} />
                    </Legend>
                  }
                  name="isValidIdPhoto"
                  items={[
                    {
                      value: false,
                      variant: 'invalid',
                      label: formatMessage(messages.no),
                      id: 'invalid-id-photo',
                    },
                    {
                      value: true,
                      variant: 'valid',
                      label: formatMessage(messages.yes),
                      id: 'valid-id-photo',
                    },
                  ]}
                />
              </div>
            )}
            {hasDriverLicense && (
              <div data-testid="VALID_DL_PHOTO_FIELD">
                <FormRadioButtonGroupField
                  legend={
                    <Legend>
                      <FormattedMessage
                        {...messages.isValidDriverLicensePhoto}
                      />
                    </Legend>
                  }
                  name="isValidDriverLicensePhoto"
                  items={[
                    {
                      value: false,
                      variant: 'invalid',
                      label: formatMessage(messages.no),
                      id: 'invalid-driver-license-photo',
                    },
                    {
                      value: 'ignore',
                      label: formatMessage(messages.ignore),
                      id: 'ignore-driver-license-photo',
                    },
                    {
                      value: true,
                      variant: 'valid',
                      label: formatMessage(messages.yes),
                      id: 'valid-driver-license-photo',
                    },
                  ]}
                />
              </div>
            )}
          </>
        )}
      </div>
    </CardLayout>
  );
};
