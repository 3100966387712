import { PromotionTarget } from '@cooltra/station-based-api';
import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    defaultMessage: 'Type',
  },
  placeholder: {
    defaultMessage: 'All',
  },
});

export const promotionsTypeMessages = defineMessages<PromotionTarget>({
  COUPON: {
    defaultMessage: 'Coupon',
  },
  WEB_OFFER: {
    defaultMessage: 'Web offer',
  },
});
