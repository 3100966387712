import { createContext } from 'react';

export type SelectVehicleContextShape = {
  vehicleId?: string;
  selectVehicle: (vehicleId: string) => void;
  deselectVehicle: () => void;
};

export const SelectVehicleContext = createContext<
  SelectVehicleContextShape | undefined
>(undefined);
