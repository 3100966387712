import { InputLabel, MultiRange } from '@cooltra/ui';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useVehiclesFilters } from '~/libs/vehicles-filters';

import messages from './messages';

let TIMER_ID: any = null;

export const Battery = () => {
  const { values, setValues } = useVehiclesFilters();
  const [internalValues, setInternalValues] = useState({
    min: 0,
    max: 100,
  });

  const handleChange = ({ min, max }: { min: number; max: number }) => {
    setInternalValues({
      min,
      max,
    });

    clearTimeout(TIMER_ID);

    TIMER_ID = setTimeout(() => {
      setValues({
        ...values,
        minBatteryCharge: min,
        maxBatteryCharge: max,
      });
    }, 500);
  };

  useEffect(() => {
    setInternalValues({
      min: values.minBatteryCharge,
      max: values.maxBatteryCharge,
    });
  }, [values.minBatteryCharge, values.maxBatteryCharge]);

  return (
    <div>
      <InputLabel htmlFor="battery-level">
        <FormattedMessage {...messages.batteryLevel} />
      </InputLabel>
      <div className="mt-2.5">
        <MultiRange
          min={internalValues.min}
          max={internalValues.max}
          id="battery-level"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
