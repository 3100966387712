import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { FormProvider } from '@cooltra/form';
import { getParams } from '@cooltra/utils';

import {
  VehiclesFiltersFormValues,
  vehiclesFiltersInitialValues,
} from './vehicles-filters-form';

const {
  system,
  model,
  telematics,
  location,
  sort,
  cityHallPublished,
  minBatteryCharge,
  maxBatteryCharge,
  keyword,
  taskOperatorGroupId,
  taskOperatorId,
  tagId,
  operationalCondition,
  withOperator,
  bonusEnabled,
  parkingReview,
  idleFrom,
  unbalanced,
} = vehiclesFiltersInitialValues;

export const VehiclesFiltersForm = ({ children }: PropsWithChildren) => {
  const { search } = useLocation();
  const params = getParams<keyof VehiclesFiltersFormValues>(search);

  const initialValues = {
    system: params.array('system').length ? params.array('system') : system,
    model: params.array('model').length ? params.array('model') : model,
    telematics: params.array('telematics').length
      ? params.array('telematics')
      : telematics,
    location: params.array('location').length
      ? params.array('location')
      : location,
    sort: params.string('sort') || sort,
    cityHallPublished: params.string('cityHallPublished') || cityHallPublished,
    minBatteryCharge: params.numeric('minBatteryCharge') || minBatteryCharge,
    maxBatteryCharge: params.numeric('maxBatteryCharge') || maxBatteryCharge,
    keyword: params.string('keyword') || keyword,
    taskOperatorGroupId: params.array('taskOperatorGroupId').length
      ? params.array('taskOperatorGroupId')
      : taskOperatorGroupId,
    taskOperatorId: params.array('taskOperatorId').length
      ? params.array('taskOperatorId')
      : taskOperatorId,
    tagId: params.array('tagId').length ? params.array('tagId') : tagId,
    operationalCondition: params.array('operationalCondition').length
      ? params.array('operationalCondition')
      : operationalCondition,
    withOperator: params.string('withOperator') || withOperator,
    bonusEnabled: params.string('bonusEnabled') || bonusEnabled,
    parkingReview: params.array('parkingReview').length
      ? params.array('parkingReview')
      : parkingReview,
    idleFrom: params.array('idleFrom').length
      ? params.array('idleFrom')
      : idleFrom,
    unbalanced: params.string('unbalanced') || unbalanced,
  } as VehiclesFiltersFormValues;

  return <FormProvider initialValues={initialValues}>{children}</FormProvider>;
};
