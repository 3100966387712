import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Invoice {invoiceNumber}',
  },
  titleForCreditsNote: {
    defaultMessage: 'Credit note {invoiceNumber}',
  },
  refreshThePageForUpdates: {
    defaultMessage:
      'A new attempt has been started. Refresh the page for updates.',
  },
});
