import { ProblemReportColumnLabel } from '~/common';

import { ProblemReportRowLayout } from './ProblemReportRowLayout';

export const ProblemReportsListHeader = () => (
  <ProblemReportRowLayout
    problem={<ProblemReportColumnLabel name="problem" />}
    context={<ProblemReportColumnLabel name="context" />}
    created={<ProblemReportColumnLabel name="created" />}
    vehicle={
      <ProblemReportColumnLabel name="vehicle" className="text-center" />
    }
  />
);
