import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';
import { useLocation } from 'react-router-dom';
import { getParams } from '@cooltra/utils';

import {
  TasksFiltersFormValues,
  tasksFiltersInitialValues,
} from './tasks-filters-form';

const { system, operatorGroupId, operatorId, tagId, open, blocking, sort } =
  tasksFiltersInitialValues;

export const TasksFiltersForm = ({ children }: PropsWithChildren) => {
  const { search } = useLocation();
  const params = getParams<keyof TasksFiltersFormValues>(search);

  const initialValues = {
    system: params.array('system').length ? params.array('system') : system,
    operatorGroupId: params.string('operatorGroupId') || operatorGroupId,
    operatorId: params.string('operatorId') || operatorId,
    tagId: params.array('tagId').length ? params.array('tagId') : tagId,
    open: params.string('open') || open,
    blocking: params.string('blocking')
      ? params.string('blocking') === 'true'
      : blocking,
    sort: params.string('sort') || sort,
  } as TasksFiltersFormValues;

  return <FormProvider initialValues={initialValues}>{children}</FormProvider>;
};
