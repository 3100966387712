export const Bike = () => (
  <>
    <g clipPath="url(#clip0_3067_16678)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M45.873 53c9.129 0 15.873 6.783 15.873 15.964 0 8.711-7.212 15.964-15.873 15.964S30 77.675 30 68.964C30 60.254 37.212 53 45.873 53zm.234 4.175c-6.402 0-11.722 5.069-11.722 11.319 0 6.182 5.721 11.79 11.722 11.79 5.91 0 11.254-5.522 11.254-11.79 0-6.513-4.646-11.32-11.254-11.32zM97.906 53c9.129 0 15.873 6.783 15.873 15.964 0 8.711-7.212 15.964-15.873 15.964s-15.873-7.253-15.873-15.964c0-8.71 7.212-15.964 15.873-15.964zm.234 4.175c-6.402 0-11.722 5.069-11.722 11.319 0 6.182 5.721 11.79 11.722 11.79 5.909 0 11.254-5.522 11.254-11.79 0-6.513-4.646-11.32-11.254-11.32z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M87.646 32.206c.76 0 1.49.622 1.615 1.23l.017.225 2.498 8.002c.73-.783 1.84-1.326 3.13-1.326 2.312 0 2.312 6.395 0 6.395a5.99 5.99 0 01-1.613-.21l6.38 20.424a2.824 2.824 0 011.069 2.216 2.815 2.815 0 01-2.807 2.822 2.814 2.814 0 01-2.806-2.822c0-.644.215-1.238.576-1.713l-5.578-18.1-16.788 17.436a4.232 4.232 0 011.094 2.847c0 2.338-1.885 4.234-4.21 4.234-.157 0-.313-.009-.465-.026l-.002.026-24.321-1.882-.042-.003a2.902 2.902 0 01-2.528-2.884c0-.397.08-.776.223-1.122l-.018-.23.114-.296.08-.134 18.15-20.619-1.48-4.48-.468.133c-1.727.434-3.852.067-4.17.008l-.04-.008c-2.694-.451-3.663-3.938-.426-4.216l.427-.018 12.282.001c1.483.005 2.269.052 2.684.47.468 1.411-1.403 1.882-2.806 1.882-1.388 0-2.692.253-3.876.581l7.261 21.996L88.644 44.53l-2.475-8.032h-4.604c-.756 0-1.509-.48-1.888-1.195l-.138-.32-.08-.305-.028-.326c0-1.212.644-1.98 1.75-2.122l.384-.024h6.08zM63.007 51.523L49.732 66.398l17.886-.9-4.61-13.975z"
        clipRule="evenodd"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_3067_16678">
        <path fill="#fff" d="M0 0H144V144H0z"></path>
      </clipPath>
    </defs>
  </>
);
