import { defineMessages } from 'react-intl';

export default defineMessages({
  type: {
    defaultMessage: 'Type',
  },
  reason: {
    defaultMessage: 'Reason',
  },
  clearance: {
    defaultMessage: 'Clearance',
  },
});
