import { defineMessages } from 'react-intl';

export default defineMessages({
  front: {
    defaultMessage: 'Front',
  },
  back: {
    defaultMessage: 'Back',
  },
});
