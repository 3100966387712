import { Placeholder, ToggleField } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { getErrorStatus } from '@cooltra/axios';
import { useVehicleBonusToggleMutation, Vehicle } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { ChangeEvent } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useNotification, useVehicleQuery } from '~/hooks';
import { useVehiclesFilters } from '~/libs/vehicles-filters';

import messages from './messages';
import star from './assets/star.png';

export type VehicleBonusListItemProps = {
  vehicleId: string;
};

export const VehicleBonusListItem = ({
  vehicleId,
}: VehicleBonusListItemProps) => {
  const intl = useIntl();

  const queryClient = useQueryClient();

  const { values } = useVehiclesFilters();

  const { data: claims } = useAuthClaimsQuery();
  const { data: vehicle, isLoading: isVehicleLoading } = useVehicleQuery(
    vehicleId,
    {
      enabled: !!vehicleId,
    }
  );
  const { mutateAsync } = useVehicleBonusToggleMutation(vehicleId);
  const { addErrorNotification } = useNotification();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const vehicle = queryClient.getQueryData<Vehicle>(['vehicle', vehicleId]);
    if (!vehicle) {
      return;
    }

    const bonusEnabled = e.target.checked;
    queryClient.setQueryData<Vehicle>(['vehicle', vehicleId], {
      ...vehicle,
      bonusEnabled,
    });

    mutateAsync({ bonusEnabled })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['vehicles', { system: values.system }],
        });
      })
      .catch((error) => {
        let errorNotification;

        switch (getErrorStatus(error)) {
          case 403:
            errorNotification = intl.formatMessage(messages.permanentBonus);
            break;
          case 409:
            errorNotification = intl.formatMessage(messages.limitReached);
            break;
          default:
            errorNotification = undefined;
            break;
        }

        addErrorNotification(errorNotification);

        queryClient.setQueryData<Vehicle>(['vehicle', vehicleId], {
          ...vehicle,
          bonusEnabled: !bonusEnabled,
        });
      });
  };

  const renderVehicleBonus = () => {
    if (isVehicleLoading) {
      return <Placeholder className="w-32 h-3" />;
    }

    if (claims?.permissions.includes('write:vehicle_bonus')) {
      return (
        <ToggleField
          data-testid="VEHICLE_BONUS_TEST"
          id="toggle-vehicle-bonus"
          checked={vehicle?.bonusEnabled}
          onChange={handleChange}
        />
      );
    }

    return (
      <span className="text-sm text-neutral-500">
        {vehicle?.bonusEnabled ? (
          <FormattedMessage {...messages.yes} />
        ) : (
          <FormattedMessage {...messages.no} />
        )}
      </span>
    );
  };

  return (
    <li className="py-4 border-b border-solid border-neutral-100">
      <div className="flex justify-between items-center gap-4">
        <div className="flex">
          <div className="w-5">
            <img src={star} />
          </div>
          <span className="pl-2 pt-px inline-block text-sm text-neutral-400">
            <FormattedMessage {...messages.vehicleBonus} />
          </span>
        </div>
        {renderVehicleBonus()}
      </div>
    </li>
  );
};
