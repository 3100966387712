import { defineMessages } from 'react-intl';

export default defineMessages({
  consumableUntil: {
    defaultMessage: 'Consumable until',
  },
  date: {
    defaultMessage: 'Date',
  },
  time: {
    defaultMessage: 'Time',
  },
  doesntExpire: {
    defaultMessage: "Doesn't expire",
  },
});
