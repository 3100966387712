import { Divider } from '@cooltra/ui';
import { VehicleListItem } from '@cooltra/api';

import {
  ActiveRentalsItems,
  ActiveReservationsItems,
  AvailableVehiclesItem,
  RentalsToday,
  ActiveVehicleBonusItem,
} from './LegendItem';
import { useFilterVehiclesForLegend } from './useFilterVehiclesForLegend';

type OperationalFleetLegendProps = {
  vehicles: VehicleListItem[];
  isLoading: boolean;
};

export const OperationalFleetLegend = ({
  vehicles,
  isLoading,
}: OperationalFleetLegendProps) => {
  const {
    data: { filteredVehicles: vehiclesForLegend },
  } = useFilterVehiclesForLegend(vehicles);

  const totalAvailableVehicles = vehiclesForLegend.filter((vehicles) =>
    vehicles.operationalConditions.includes('AVAILABLE')
  ).length;
  const totalActiveReservations = vehiclesForLegend.filter((vehicles) =>
    vehicles.operationalConditions.includes('RESERVED')
  ).length;
  const totalActiveRentals = vehiclesForLegend.filter((vehicles) =>
    vehicles.operationalConditions.includes('RENTED')
  ).length;
  const totalVehicleBonus = vehiclesForLegend.filter(
    (vehicle) => vehicle.bonusEnabled == true
  ).length;

  const totalOperationalFleetVehicles =
    totalAvailableVehicles + totalActiveReservations + totalActiveRentals;

  return (
    <div className="flex justify-end absolute top-24 right-10 shadow">
      <div className="flex flex-col gap-6 px-10 py-12 bg-neutral-0 rounded-lg">
        <AvailableVehiclesItem
          totalAvailableVehicles={totalOperationalFleetVehicles}
          isLoading={isLoading}
        />
        <Divider />
        <ActiveReservationsItems
          totalActiveReservations={totalActiveReservations}
          isLoading={isLoading}
        />
        <Divider />
        <ActiveRentalsItems
          totalActiveRentals={totalActiveRentals}
          isLoading={isLoading}
        />
        <Divider />
        <RentalsToday />
        <Divider />
        <ActiveVehicleBonusItem
          totalVehicleBonus={totalVehicleBonus}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
