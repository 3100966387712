import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Tasks',
  },
  createTask: {
    defaultMessage: 'Create task',
  },
});
