import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';

type CounterVariant = 'neutral' | 'primary';

const counterBackground: { [key in CounterVariant]: string } = {
  neutral: 'bg-neutral-400',
  primary: 'bg-primary-300',
};

export type CounterProps = HTMLAttributes<HTMLSpanElement> & {
  variant?: 'neutral' | 'primary';
};

export const Counter = ({
  children,
  className,
  variant = 'neutral',
}: CounterProps) => (
  <span
    className={classNames(
      'flex items-center justify-center w-6 h-6 text-neutral-0 font-semibold rounded',
      counterBackground[variant],
      className
    )}
  >
    {children}
  </span>
);
