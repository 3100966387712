import { FormButton, FormHelpers } from '@cooltra/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
  usePatchPromotionCouponMutation,
  usePatchPromotionWebOfferMutation,
  usePromotionQuery,
} from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Card, Spinner } from '@cooltra/ui';
import { capitalize } from '@cooltra/utils';

import {
  Four0Four,
  Four0Three,
  Log,
  StationBasedFormValues,
  StationBasedPromotionForm,
  StationBasedState,
  StationBasedStateType,
  UnsavedChangesPrompt,
} from '~/common';
import { useNotification } from '~/hooks';

import { StationBasedFormFields } from '../StationBasedFormFields/StationBasedFormFields';
import { PromotionBackLink } from '../BackLink/PromotionBackLink';

import { getFormValuesFromPromotion } from './get-form-values-from-station-based-promotion';
import {
  getUpdateStationBasedPromotionCouponPayload,
  getUpdateStationBasedPromotionWebOfferPayload,
} from './get-update-station-base-promotion-payload';
import messages from './messages';

export const EditStationBasedPromotion = () => {
  const { promotionId = '' } = useParams<'promotionId'>();
  const { hasPermission } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const {
    data: promotion,
    isLoading,
    refetch,
  } = usePromotionQuery(promotionId);
  const { mutateAsync: webOfferMutateAsync, isPending: isWebOfferPending } =
    usePatchPromotionWebOfferMutation(promotionId);
  const { mutateAsync: couponMutateAsync, isPending: isCouponPending } =
    usePatchPromotionCouponMutation(promotionId);

  if (!hasPermission('read:station_based_offers')) {
    return <Four0Three />;
  }

  if (isLoading) {
    return (
      <div className="w-full text-center mt-8">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!promotion) {
    return <Four0Four />;
  }

  const onSubmitWebOfferPromotionForm = (
    values: StationBasedFormValues,
    { resetForm }: FormHelpers<StationBasedFormValues>
  ) => {
    const payload = getUpdateStationBasedPromotionWebOfferPayload(values);

    return webOfferMutateAsync(payload)
      .then(async () => {
        resetForm(values);
        addSuccessNotification(
          formatMessage(messages.youHaveSuccessfullyUpdatedThePromotion)
        );
        await refetch();
      })
      .catch(({ response }) => {
        switch (response.status) {
          case 400:
            addErrorNotification(formatMessage(messages.wrongFieldsError));
            break;
          default:
            addErrorNotification();
            break;
        }
      });
  };

  const onSubmitCouponPromotionForm = (
    values: StationBasedFormValues,
    { resetForm }: FormHelpers<StationBasedFormValues>
  ) => {
    const payload = getUpdateStationBasedPromotionCouponPayload(values);

    return couponMutateAsync(payload)
      .then(async () => {
        resetForm(values);
        addSuccessNotification(
          formatMessage(messages.youHaveSuccessfullyUpdatedThePromotion)
        );
        await refetch();
      })
      .catch(({ response }) => {
        switch (response.status) {
          case 422:
            addErrorNotification(formatMessage(messages.codeDuplicated));
            break;
          case 400:
            addErrorNotification(formatMessage(messages.wrongFieldsError));
            break;
          default:
            addErrorNotification();
            break;
        }
      });
  };

  const onSubmitPromotionForm = (
    values: StationBasedFormValues,
    formHelpers: FormHelpers<StationBasedFormValues>
  ) => {
    if (values.promotionTarget === 'WEB_OFFER') {
      return onSubmitWebOfferPromotionForm(values, formHelpers);
    }

    if (values.promotionTarget === 'COUPON') {
      return onSubmitCouponPromotionForm(values, formHelpers);
    }

    return Promise.reject();
  };

  const formValues = getFormValuesFromPromotion(promotion);
  const {
    createdAt,
    validUntil,
    updatedBy,
    titleTranslations,
    validFrom,
    updatedAt,
    createdBy,
    couponsUsed,
  } = promotion;

  return (
    <div
      className="container min-w-screen-sm max-w-5xl py-12"
      data-testid="EDIT_STATION_BASED_PROMOTION"
    >
      <div className="mb-9">
        <PromotionBackLink />
        <div className="flex items-center gap-4">
          <h1 className="text-3xl">{capitalize(titleTranslations.es || '')}</h1>
          <StationBasedState validFrom={validFrom} validUntil={validUntil} />
        </div>
      </div>
      <div className="flex px-4 gap-12">
        <div className="tab tab-active">
          <FormattedMessage {...messages.promotionRules} />
        </div>
        <div className="tab text-neutral-400">
          <FormattedMessage
            {...messages.couponsUsed}
            values={{
              amount: couponsUsed || 0,
            }}
          />
        </div>
      </div>
      <StationBasedPromotionForm
        initialValues={formValues}
        onSubmit={onSubmitPromotionForm}
      >
        <div className="flex gap-6 items-start">
          <div>
            <StationBasedFormFields />
            {hasPermission('write:station_based_offers') &&
              formValues.stateType !== StationBasedStateType.EXPIRED && (
                <div className="flex justify-end">
                  <FormButton loading={isWebOfferPending || isCouponPending}>
                    <FormattedMessage {...messages.submitButton} />
                  </FormButton>
                </div>
              )}
          </div>
          <Card className="flex flex-col gap-4 p-10">
            <Log
              user="operator"
              action="create"
              userId={createdBy}
              date={createdAt}
            />
            {updatedBy && updatedAt && (
              <Log
                user="operator"
                action="update"
                userId={updatedBy}
                date={updatedAt}
              />
            )}
          </Card>
          <UnsavedChangesPrompt />
        </div>
      </StationBasedPromotionForm>
    </div>
  );
};
