import { createContext } from 'react';

export type FocusedZoneContextShape = {
  restrictionIdFocused?: string;
  setRestrictionIdFocused: (restrictionIdFocused?: string) => void;
  bonusZoneIdFocused?: string;
  setBonusZoneIdFocused: (restrictionIdFocused?: string) => void;
};

export const FocusedZoneContext = createContext<
  FocusedZoneContextShape | undefined
>(undefined);
