import { BadgePlaceholder, Card, Placeholder } from '@cooltra/ui';

import { VehicleReservationRowLayout } from '../VehicleReservationRowLayout';

export const VehicleReservationCardLoading = () => (
  <Card className="py-5 flex items-center">
    <VehicleReservationRowLayout
      createdAt={
        <div className="flex flex-col gap-3 py-0.5">
          <Placeholder className="w-10 h-2" />
          <Placeholder className="w-20 h-2" />
        </div>
      }
      spacer={<div className="w-full h-px bg-neutral-200" />}
      expiresAt={
        <div className="flex flex-col gap-3">
          <Placeholder className="w-10 h-2" />
          <Placeholder className="w-20 h-2" />
        </div>
      }
      user={<Placeholder className="w-24 h-2" />}
      system={<Placeholder className="w-14 h-2" />}
      checkIn={<Placeholder className="w-14 h-2 mx-auto" />}
      problemReports={<Placeholder className="w-14 h-2 mx-auto" />}
      state={<BadgePlaceholder className="w-20 mx-auto" />}
    />
  </Card>
);
