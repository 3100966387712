import { Route, Routes } from 'react-router-dom';

import { CreatePass } from './CreatePass/CreatePass';
import { PassList } from './PassList/PassList/PassList';
import { PassFiltersForm } from './PassFiltersForm';
import { EditPass } from './EditPass/EditPass';

export const Passes = () => (
  <Routes>
    <Route
      path="/"
      element={
        <PassFiltersForm>
          <PassList />
        </PassFiltersForm>
      }
    />
    <Route path="/new" element={<CreatePass />} />
    <Route path="/:passId/edit" element={<EditPass />} />
  </Routes>
);
