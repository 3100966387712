import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type ClearedSuspensionRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  type: ReactNode;
  reason: ReactNode;
  divider?: ReactNode;
  clearance: ReactNode;
};

export const ClearedSuspensionRowLayout = ({
  type,
  reason,
  divider,
  clearance,
  className,
  ...rest
}: ClearedSuspensionRowLayoutProps) => (
  <div className={classNames('flex', 'h-full', className)} {...rest}>
    <div className="flex w-full px-8 gap-4">
      <div className="w-32 shrink-0">{type}</div>
      <div className="flex w-full gap-8">
        <div className="flex-1">{reason}</div>
        <div>{divider}</div>
        <div className="flex-1">{clearance}</div>
      </div>
    </div>
  </div>
);
