import { InvoiceLegalEntity } from '@cooltra/api';

export const legalEntityNames: Record<InvoiceLegalEntity, string> = {
  COOLTRA_SPAIN: 'Cooltra Motosharing, S.L.U',
  COOLTRA_ITALY: 'eCooltra Scootersharing Italia srl',
  COOLTRA_FRANCE: 'Cooltra Scooter Sharing France SARL',
  COOLTRA_PORTUGAL: 'eCooltra Scootersharing PT',
  COOLTRA_GERMANY: 'Cooltra Germany',
  COOLTRA_SWITZERLAND: 'Cooltra Switzerland',
  FELYX_NETHERLANDS: 'Felyx Netherlands B.V.',
  FELYX_BELGIUM: 'Felyx Belgium BV',
};

export const legalEntityTaxRates: Record<InvoiceLegalEntity, number[]> = {
  COOLTRA_SPAIN: [0.21, 0],
  COOLTRA_ITALY: [0.22, 0],
  COOLTRA_FRANCE: [0.2, 0],
  COOLTRA_PORTUGAL: [0.23, 0],
  COOLTRA_GERMANY: [0.19, 0],
  COOLTRA_SWITZERLAND: [0.077, 0],
  FELYX_NETHERLANDS: [0.21, 0],
  FELYX_BELGIUM: [0.21, 0],
};

export const getLegalEntityTaxRates = (legalEntity: InvoiceLegalEntity) =>
  legalEntityTaxRates[legalEntity];

export const getLegalEntityDefaultTaxRate = (legalEntity: InvoiceLegalEntity) =>
  getLegalEntityTaxRates(legalEntity)[0].toString();
