import { PricingGroupPricesListItem } from '@cooltra/station-based-api';
import { List } from '@cooltra/ui';

import { NoSearchResults } from '~/common';

import { PriceListsByPricingGroupHeader } from './PriceListsByPriceGroupHeader/PriceListsByPricingGroupHeader';
import { PriceListsByPricingGroupCard } from './PriceListsByPriceGroupCard/PriceListsByPricingGroupCard';
import { PriceListsByPricingGroupCardLoading } from './PriceListsByPriceGroupCard/PriceListsByPricingGroupCardLoading';

type PriceListsByPricingGroupProps = {
  pricingGroupPricesListItems: PricingGroupPricesListItem[];
  isLoading: boolean;
};

export const PriceListsByPriceGroup = ({
  pricingGroupPricesListItems,
  isLoading,
}: PriceListsByPricingGroupProps) => (
  <div>
    <List
      loadingRows={4}
      isLoading={isLoading}
      idProp="id"
      data={pricingGroupPricesListItems}
      renderEmptyMessage={<NoSearchResults />}
      renderHeader={<PriceListsByPricingGroupHeader />}
      renderLoadingRow={<PriceListsByPricingGroupCardLoading />}
      renderRow={(pricingGroupPricesListItem, index) => (
        <PriceListsByPricingGroupCard
          {...pricingGroupPricesListItem}
          current={index === 0}
        />
      )}
    />
  </div>
);
