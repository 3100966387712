import { FormattedMessage } from 'react-intl';

import { useLocationName } from '~/hooks';
import { TimeAgo } from '~/common';

import messages from './messages';

export type LocationProps = {
  locationId: string;
  inLocationFrom?: string;
};

export const Location = ({ locationId, inLocationFrom }: LocationProps) => {
  const locationName = useLocationName(locationId);

  if (!locationId) {
    return <span />;
  }

  return (
    <div
      data-testid="VEHICLE_LIST_ITEM_LOCATION"
      className="h-24 w-full flex flex-col justify-center"
    >
      <span className="block text-xs mb-0.5">
        <span className="text-neutral-900">
          <FormattedMessage {...messages.inALocation} />{' '}
        </span>
        {inLocationFrom && (
          <span className="text-neutral-400">
            <FormattedMessage {...messages.since} />{' '}
            <TimeAgo ISODate={inLocationFrom} />
          </span>
        )}
      </span>
      <span className="block text-xs text-neutral-900">{locationName}</span>
    </div>
  );
};
