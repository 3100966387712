import { ReactNode } from 'react';

export type PairProps = {
  label: ReactNode;
  value: ReactNode;
};

export const Pair = ({ label, value }: PairProps) => {
  return (
    <div className="flex justify-between items-center py-4">
      <span className="text-neutral-400 text-sm">{label}</span>
      <span className="text-neutral-1000 text-md font-semibold">{value}</span>
    </div>
  );
};
