import { HTMLAttributes } from 'react';
import { classNames } from '@cooltra/utils';
import { useVehicleStatusQuery } from '@cooltra/api';

import { thirtySeconds } from '~/utils/date';

export type OngoingRentalTotalDistanceProps =
  HTMLAttributes<HTMLSpanElement> & {
    vehicleId: string;
    startMileage: number;
  };

export const OngoingRentalTotalDistance = ({
  vehicleId,
  startMileage,
  className,
}: OngoingRentalTotalDistanceProps) => {
  const { data: vehicleStatus, isLoading } = useVehicleStatusQuery(vehicleId, {
    refetchInterval: thirtySeconds,
  });

  if (!vehicleStatus || !vehicleStatus.mileage?.value || isLoading) {
    return <></>;
  }

  const { value: currentMileage } = vehicleStatus.mileage;
  const startMileageInKms = startMileage / 1000;

  const totalMileageInKms = currentMileage - startMileageInKms;

  if (totalMileageInKms < 1) {
    const totalMileageInMts = totalMileageInKms * 100;

    return (
      <span className={classNames('block tabular-nums', className)}>
        {totalMileageInMts} mts
      </span>
    );
  }

  return (
    <span className={classNames('block tabular-nums', className)}>
      {totalMileageInKms.toFixed(1)} kms
    </span>
  );
};
