import { FormattedMessage, useIntl } from 'react-intl';
import { FormMultiSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { useServicePointsQuery } from '@cooltra/station-based-api';

import messages from './messages';

const oneDay = 1000 * 60 * 60 * 24;

type ServicePointIdOption = {
  label: string;
  value: string;
};

export const ServicePointIdField = () => {
  const intl = useIntl();
  const { data: servicePoints = [] } = useServicePointsQuery({
    staleTime: oneDay,
  });

  const servicePointsOptions: ServicePointIdOption[] = servicePoints
    .map((servicePoint) => ({
      label: servicePoint.name,
      value: servicePoint.id,
    }))
    .sort((servicePoint, nextServicePoint) =>
      servicePoint.label.localeCompare(nextServicePoint.label)
    );

  return (
    <FormMultiSelectField
      id="servicePointId"
      name="servicePointId"
      label={
        <InputLabel htmlFor="servicePointId" className="shrink-0">
          <FormattedMessage {...messages.servicePoint} />
        </InputLabel>
      }
      options={servicePointsOptions}
      placeholder={intl.formatMessage(messages.all)}
    />
  );
};
