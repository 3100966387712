import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Form, FormButton } from '@cooltra/form';
import { getErrorStatus } from '@cooltra/axios';
import {
  UpdateVehicleTechnicalInspectionPayload,
  useUpdateVehicleTechnicalInspectionMutation,
  useVehicleQuery,
  useVehicleTechnicalInspectionQuery,
} from '@cooltra/api';
import { SpinnerScreen } from '@cooltra/ui';
import { useAuth0 } from '@auth0/auth0-react';

import {
  DividedCard,
  ErrorPage,
  Four0Three,
  Log,
  TechnicalInspectionBackLink,
  TechnicalInspectionFormProvider,
  TechnicalInspectionFormValues,
  VehiclePair,
} from '~/common';
import { useNotification } from '~/hooks';

import { DeleteTechnicalInspection } from '../DeleteTechnicalInspection/DeleteTechnicalInspection';

import messages from './messages';
import { EditTechnicalInspectionForm } from './EditTechnicalInspectionForm';
import { editServiceFormValidation } from './edit-service-form-validation';
import { VehicleMileage } from './VehicleMileage';

export const EditTechnicalInspection = () => {
  const { user } = useAuth0();

  const { technicalInspectionId = '' } = useParams<'technicalInspectionId'>();
  const intl = useIntl();
  const { formatMessage } = intl;
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const {
    data: technicalInspection,
    isError: isServiceError,
    error: serviceError,
    isLoadingError: isServiceLoading,
  } = useVehicleTechnicalInspectionQuery(technicalInspectionId);

  const enabled = !!technicalInspection?.vehicleId;
  const {
    data: vehicle,
    isError: isVehicleError,
    error: vehicleError,
    isLoading: isVehicleLoading,
  } = useVehicleQuery(technicalInspection?.vehicleId || '', {
    enabled,
  });

  const { mutateAsync, isPending } =
    useUpdateVehicleTechnicalInspectionMutation(
      String(technicalInspection?.vehicleId),
      technicalInspectionId
    );

  const handleOnSubmit = (values: TechnicalInspectionFormValues) => {
    const payload: UpdateVehicleTechnicalInspectionPayload = {
      scheduledAt: values.scheduledAt || null,
      servicedAt: values.servicedAt || null,
      servicedBy: values.servicedBy || null,
    };

    return mutateAsync(payload)
      .then(() => {
        addSuccessNotification(formatMessage(messages.success));
      })
      .catch(({ response }) => {
        switch (response.status) {
          case 400:
            addErrorNotification(formatMessage(messages.badFormattedFields));
            break;
          default:
            addErrorNotification();
            break;
        }
      });
  };

  if (
    (isServiceError && getErrorStatus(serviceError) === 403) ||
    (isVehicleError && getErrorStatus(vehicleError) === 403)
  ) {
    return <Four0Three />;
  }

  if (isServiceLoading || isVehicleLoading) {
    return <SpinnerScreen />;
  }

  if (!technicalInspection || !vehicle) {
    return <ErrorPage />;
  }

  return (
    <TechnicalInspectionFormProvider
      onSubmit={handleOnSubmit}
      validate={editServiceFormValidation(intl)}
      initialValues={{
        scheduledAt: technicalInspection.scheduledAt || '',
        servicedAt: technicalInspection.servicedAt || '',
        servicedBy: technicalInspection.servicedBy || user?.sub || '',
      }}
    >
      <Form>
        <DividedCard
          className="min-w-2xl max-w-4xl pt-12 pb-20"
          renderHeader={
            <div className="flex items-end justify-between">
              <div>
                <TechnicalInspectionBackLink vehicleId={vehicle?.vehicleId} />
                <h1 className="text-3xl">
                  <FormattedMessage {...messages.title} />
                </h1>
              </div>
              <FormButton disabled={isPending} loading={isPending}>
                <FormattedMessage {...messages.editService} />
              </FormButton>
            </div>
          }
          renderLeft={
            <div className="h-full flex flex-col justify-between">
              <div className="flex flex-col gap-9">
                <VehiclePair
                  vehicleId={vehicle.vehicleId}
                  model={vehicle.model}
                  externalId={vehicle?.externalId}
                  identificationNumber={vehicle?.identificationNumber}
                />
                {vehicle.vehicleId && (
                  <VehicleMileage vehicleId={vehicle.vehicleId} />
                )}
              </div>
              <div className="flex flex-col gap-6">
                <Log
                  user="operator"
                  action="create"
                  userId={technicalInspection.createdBy}
                  date={technicalInspection.createdAt}
                />
                {technicalInspection.updatedBy &&
                  technicalInspection.updatedAt && (
                    <Log
                      user="operator"
                      action="update"
                      userId={technicalInspection.updatedBy}
                      date={technicalInspection.updatedAt}
                    />
                  )}
              </div>
            </div>
          }
          renderRight={<EditTechnicalInspectionForm />}
          renderFooter={
            <DeleteTechnicalInspection vehicleId={vehicle.vehicleId} />
          }
        />
      </Form>
    </TechnicalInspectionFormProvider>
  );
};
