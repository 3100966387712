import { defineMessages } from 'react-intl';

export default defineMessages({
  vehicleBonus: {
    defaultMessage: 'Vehicle bonus',
  },
  parkingReview: {
    defaultMessage: 'Parking review',
  },
  idleFor: {
    defaultMessage: 'Idle for',
  },
  limitReached: {
    defaultMessage:
      'Max number of bonus vehicles is reached for this home system',
  },
  permanentBonus: {
    defaultMessage:
      'This vehicle has a permanent bonus that cannot be disabled',
  },
  yes: {
    defaultMessage: 'Yes',
  },
  no: {
    defaultMessage: 'No',
  },
});
