import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type ChargeAttemptRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  updatedAt: ReactNode;
  state: ReactNode;
  failureReason: ReactNode;
};

export const ChargeAttemptRowLayout = ({
  updatedAt,
  state,
  failureReason,
  className,
  ...rest
}: ChargeAttemptRowLayoutProps) => (
  <div className={classNames('flex items-center gap-4', className)} {...rest}>
    <div className="w-40 shrink-0">{updatedAt}</div>
    <div className="w-32 shrink-0">{state}</div>
    <div className="flex-1 shrink-0">{failureReason}</div>
  </div>
);
