import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { FormattedMessage } from 'react-intl';
import { RouterTab } from '@cooltra/navigation';
import { stringifyParams } from '@cooltra/utils';

import messages from './messages';

export const OffersNavigationTabs = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const canSeePromotionsList = hasPermission('read:offers');

  const defaultPromotionsParams = {
    keyword: '',
    state: '',
    sort: 'createdAt.desc',
  };
  const params = stringifyParams(defaultPromotionsParams);

  return (
    <div className="flex flex-1 shrink-0 gap-10">
      {canSeePromotionsList && (
        <RouterTab to={`/offers/promotions?${params}`}>
          <FormattedMessage {...messages.promotions} />
        </RouterTab>
      )}
      {hasPermission('read:cooltra_for_employees') && (
        <RouterTab to={`/offers/cooltra-for-employees?${params}`}>
          <FormattedMessage {...messages.cooltraForEmployees} />
        </RouterTab>
      )}
      {hasPermission('read:station_based_offers') && (
        <RouterTab to="/offers/station-based">
          <FormattedMessage {...messages.stationBased} />
        </RouterTab>
      )}
      {canSeePromotionsList && (
        <>
          <RouterTab to={`/offers/packs?${params}`}>
            <FormattedMessage {...messages.packs} />
          </RouterTab>
          <RouterTab to={`/offers/passes?${params}`}>
            <FormattedMessage {...messages.passes} />
          </RouterTab>
        </>
      )}
    </div>
  );
};
