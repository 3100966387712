import { Placeholder } from '@cooltra/ui';
import { useIntl } from 'react-intl';

import { PairValue } from './PairValue';
import messages from './messages';

type ActiveRentalsItemsProps = {
  totalActiveRentals: number;
  isLoading: boolean;
};

export const ActiveRentalsItems = ({
  totalActiveRentals,
  isLoading,
}: ActiveRentalsItemsProps) => {
  const { formatMessage } = useIntl();

  if (isLoading) {
    return (
      <PairValue
        value={
          <div className="h-10 flex items-center">
            <Placeholder className="w-12 h-3" />
          </div>
        }
        title={formatMessage(messages.activeRentals)}
      />
    );
  }

  return (
    <PairValue
      value={totalActiveRentals}
      title={formatMessage(messages.activeRentals)}
    />
  );
};
