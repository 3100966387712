import { PromotionState, PromotionTarget } from '@cooltra/station-based-api';

export type PromotionsFiltersFormValues = {
  state: PromotionState;
  keyword: string;
  type: PromotionTarget;
};

export const initialPromotionsFiltersFormValues: PromotionsFiltersFormValues = {
  state: '' as PromotionState,
  keyword: '',
  type: '' as PromotionTarget,
};
