import { Assignee, AssigneeStatic } from './Assignee';
import { Description, DescriptionStatic } from './Description';
import { Operator, OperatorStatic } from './Operator';

export const IssueFields = {
  Assignee,
  AssigneeStatic,
  Description,
  DescriptionStatic,
  Operator,
  OperatorStatic,
};
