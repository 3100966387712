import { defineMessages } from 'react-intl';
import { RentalPricingPaymentStatus } from '@cooltra/api';

export default defineMessages<RentalPricingPaymentStatus>({
  OPEN: {
    defaultMessage: 'Open',
  },
  PAID: {
    defaultMessage: 'Paid',
  },
  REFUNDED: {
    defaultMessage: 'Refunded',
  },
  CANCELLED: {
    defaultMessage: 'Canceled',
  },
});
