import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';

const formatTimeDifference = (
  hoursDifference: number,
  minutesDifference: number,
  secondsDifference: number
): string => {
  const timeAsList = [
    hoursDifference > 0 ? hoursDifference + 'h' : null,
    minutesDifference > 0 ? minutesDifference + 'm' : null,
    secondsDifference > 0 ? secondsDifference + 's' : null,
  ];

  return timeAsList.filter((time) => time).join(' ');
};

export const calculateTimeDifference = (date: Date, dateToCompare: Date) => {
  const hoursDifference = differenceInHours(dateToCompare, date);
  const minutesDifference = differenceInMinutes(dateToCompare, date);
  const secondsDifference = differenceInSeconds(dateToCompare, date);

  if (hoursDifference > 0) {
    const remainingMinutes = minutesDifference % 60;
    const remainingSeconds = secondsDifference % 60;

    return formatTimeDifference(
      hoursDifference,
      remainingMinutes,
      remainingSeconds
    );
  }

  if (minutesDifference > 0) {
    const remainingSeconds = secondsDifference % 60;

    return formatTimeDifference(0, minutesDifference, remainingSeconds);
  }

  return formatTimeDifference(0, 0, secondsDifference);
};
