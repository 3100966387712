import { Accessories } from './Accessories/Accessories';
import { LevelSelector } from './LevelSelector/LevelSelector';
import { Levels } from './LevelSelector/Levels';
import { Mileage } from './Mileage/Mileage';

export const ChecksFields = {
  Accessories,
  LevelSelector,
  Levels,
  Mileage,
};
