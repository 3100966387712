import {
  Contract,
  useSendConfirmationEmailMutation,
} from '@cooltra/station-based-api';
import { Button, Card, Divider } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { MdSend } from 'react-icons/md';

import { ConfirmModal, Log } from '~/common';
import { getContractTypeName } from '~/utils/contract';
import { useContractFlags, useNotification } from '~/hooks';

import * as ContractOverviewItems from '../ContractOverviewItems';

import messages from './messages';

export type ContractOverviewProps = {
  contract: Contract;
};

export const ContractOverview = ({ contract }: ContractOverviewProps) => {
  const {
    contractId,
    details,
    type,
    vehicle,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    insurance,
    accessories,
    status,
  } = contract;
  const { formatMessage } = useIntl();

  const { canSendEmail } = useContractFlags();

  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const { mutateAsync, isPending } =
    useSendConfirmationEmailMutation(contractId);

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleSendEmail = () => {
    mutateAsync()
      .then(() => {
        addSuccessNotification(formatMessage(messages.emailConfirmationSent));
        toggleOff();
      })
      .catch(() => {
        addErrorNotification();
      });
  };

  return (
    <div
      className="mx-auto max-w-4xl pt-4 pb-10"
      data-testid="CONTRACT_OVERVIEW"
    >
      {canSendEmail && (
        <div className="flex justify-end mb-4">
          <Button size="sm" onClick={toggleOn} trailingIcon={<MdSend />}>
            <FormattedMessage {...messages.sendEmailConfirmation} />
          </Button>
          <ConfirmModal
            className="max-w-md min-w-md"
            isOpen={isOpen}
            title={formatMessage(messages.sendEmailConfirmation)}
            content={formatMessage(messages.areYouSureToSendEmailConfirmation)}
            onClose={toggleOff}
            onConfirm={handleSendEmail}
            loading={isPending}
          />
        </div>
      )}
      <Card className="flex">
        {type && (
          <div className="absolute top-0 right-0 bg-neutral-100/20 rounded-bl-lg py-3 px-5">
            <span className="text-sm font-semibold text-neutral-500">
              {getContractTypeName(type)}
            </span>
          </div>
        )}
        <div className="w-48 lg:w-60 shrink-0 px-8 lg:px-10 py-8 bg-neutral-100/20 flex flex-col gap-64">
          <ContractOverviewItems.User contract={contract} />
        </div>
        <div className="w-full p-8">
          <ContractOverviewItems.Details details={details} />
          {vehicle && <Divider />}
          <ContractOverviewItems.Vehicle vehicle={vehicle} status={status} />
          {(accessories || insurance) && <Divider />}
          <ContractOverviewItems.Extras
            insurance={insurance}
            accessories={accessories}
          />
        </div>
      </Card>
      <div className="flex pt-5 gap-10">
        {createdAt && (
          <div className="max-w-56 truncate">
            <Log
              action="create"
              user="operator"
              userId={createdBy}
              date={createdAt}
            />
          </div>
        )}
        {updatedAt && updatedBy && (
          <>
            <div className="py-1">
              <Divider direction="vertical" className="h-full" />
            </div>
            <div className="max-w-56 truncate">
              <Log
                action="update"
                user="operator"
                userId={updatedBy}
                date={updatedAt}
              />
            </div>
          </>
        )}
      </div>
      <div className="py-12">
        <ContractOverviewItems.ContractHistory contract={contract} />
      </div>
    </div>
  );
};
