import { Money } from '@cooltra/utils';
import { useIntl } from 'react-intl';
import { MdError } from 'react-icons/md';

import { Amount } from '~/common';

import messages from './messages';

export const PendingAmount = ({ amount }: { amount: Money }) => {
  const intl = useIntl();

  return (
    <div className="flex flex-col">
      <span className="text-neutral-500 text-xs">
        {intl.formatMessage(messages.pendingAmount)}
      </span>
      <span className="text-neutral-800 text-xl font-semibold">
        <span className="flex items-center gap-1">
          <Amount value={amount.value} currency={amount.currency} />
          {amount.value > 0 && (
            <MdError
              className="text-warning-500 text-xl"
              data-testid="PENDING_PAYMENT"
            />
          )}
        </span>
      </span>
    </div>
  );
};
