import { FormProvider, Form, FormProviderProps } from '@cooltra/form';
import { useIntl } from 'react-intl';

import {
  RestrictionFormValues,
  validateRestrictionForm,
} from './restriction-form';

export type RestrictionFormProps = Omit<
  FormProviderProps<RestrictionFormValues>,
  'validate'
>;

export const RestrictionForm = ({
  onSubmit,
  initialValues,
  children,
}: RestrictionFormProps) => {
  const intl = useIntl();

  return (
    <FormProvider
      initialValues={initialValues}
      validate={validateRestrictionForm(intl)}
      onSubmit={onSubmit}
    >
      <Form>{children}</Form>
    </FormProvider>
  );
};
