import { defineMessages } from 'react-intl';

export default defineMessages({
  contract: {
    defaultMessage: 'Contract',
  },
  pendingSignature: {
    defaultMessage: 'Pending signature',
  },
  sentOn: {
    defaultMessage: 'Sent on {date}',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
});
