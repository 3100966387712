import { PatternFormat, PatternFormatProps } from 'react-number-format';
import { Input, InputProps } from '@cooltra/ui';
import { ReactNode } from 'react';

export type PatternInputFieldProps = PatternFormatProps<InputProps> & {
  error?: ReactNode;
  label?: ReactNode;
};

export const PatternInputField = ({
  error,
  label,
  className,
  ...rest
}: PatternInputFieldProps) => (
  <div className={className}>
    {label && <div className="flex flex-row items-end mb-1">{label}</div>}
    <PatternFormat customInput={Input} invalid={!!error} {...rest} />
    {error && <div className="flex flex-row items-end mt-1">{error}</div>}
  </div>
);
