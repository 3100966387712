import { Card, Divider, Placeholder } from '@cooltra/ui';
import { consecutiveNumbers } from '@cooltra/utils';

import { ShiftRowLayout } from '../ShiftRowLayout';

export const ShiftCardLoading = () => (
  <Card className="h-28 flex items-center">
    <ShiftRowLayout
      operator={
        <div className="flex flex-col gap-2.5">
          <Placeholder className="h-2 w-16" />
          <Placeholder className="h-2 w-24" />
        </div>
      }
      duration={
        <div className="inline-block">
          <div className="flex items-center h-4 mt-2">
            <Divider />
          </div>
          <div className="flex items-center gap-4 mt-4">
            <Placeholder className="h-2 w-32" />
            <div className="w-4">
              <Divider />
            </div>
            <Placeholder className="h-2 w-32" />
          </div>
        </div>
      }
      summary={
        <div className="grid grid-cols-3 gap-y-4">
          {consecutiveNumbers(6).map((i) => (
            <div
              key={`shift-card-loading-${i}`}
              className="flex items-center flex-col gap-2.5"
            >
              <Placeholder className="h-2 w-6" />
              <Placeholder className="h-2 w-20" />
            </div>
          ))}
        </div>
      }
    />
  </Card>
);
