import { UserSession } from '@cooltra/api';
import { Card } from '@cooltra/ui';

import { DateTime, SessionDevice, SessionRevokedBadge } from '~/common';

import { SessionRowLayout } from '../SessionRowLayout';

export type SessionCardProps = {
  session: UserSession;
};

export const SessionCard = ({ session }: SessionCardProps) => {
  const { date, expired } = session;

  return (
    <Card className="h-16">
      <SessionRowLayout
        date={<DateTime date={date} />}
        deviceAndVersion={
          <div className="flex gap-2 items-center justify-between">
            <SessionDevice
              className="text-xs whitespace-nowrap"
              classNameValue="truncate"
              session={session}
            />
            {expired && <SessionRevokedBadge />}
          </div>
        }
      />
    </Card>
  );
};
