import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Task details',
  },
  editTask: {
    defaultMessage: 'Save changes',
  },
  success: {
    defaultMessage: 'You have successfully edited the task!',
  },
});
