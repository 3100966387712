import { Button, ButtonProps } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type ApplyFiltersButtonProps = Pick<ButtonProps, 'onClick'>;

export const ApplyFiltersButton = ({ onClick }: ApplyFiltersButtonProps) => (
  <Button type="submit" emphasis="high" onClick={onClick}>
    <FormattedMessage {...messages.applyFilters} />
  </Button>
);
