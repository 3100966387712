import { UserSubscriptionState } from '@cooltra/api';
import { Icon } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { MdCheck } from 'react-icons/md';

import messages from './messages';

export type SubscriptionStateProps = {
  state: UserSubscriptionState;
};

export const SubscriptionState = ({ state }: SubscriptionStateProps) => {
  if (state === 'ACTIVE') {
    return (
      <span className="text-success-600 inline-flex items-center">
        <Icon className="mr-0.5">
          <MdCheck />
        </Icon>
        <span>
          <FormattedMessage {...messages.active} />
        </span>
      </span>
    );
  }

  return (
    <span className="text-neutral-800">
      {state === 'EXPIRED' ? (
        <FormattedMessage {...messages.expired} />
      ) : (
        <FormattedMessage {...messages.next} />
      )}
    </span>
  );
};
