import { useIntl } from 'react-intl';

import { SortField } from '../SortField/SortField';

import messages from './messages';

const options = [
  {
    label: messages.oldestFirst,
    value: 'updatedAt.asc',
  },
  {
    label: messages.newestFirst,
    value: 'updatedAt.desc',
  },
];

export const UpdatedAt = () => {
  const { formatMessage } = useIntl();

  const translatedOptions = options.map(({ label, value }) => ({
    label: formatMessage(label),
    value,
  }));

  return <SortField options={translatedOptions} />;
};
