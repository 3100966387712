import { Card } from '@cooltra/ui';
import { AxiosError } from 'axios';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { MdOutlineChevronRight } from 'react-icons/md';
import { useUserBillingAddressMutation, User } from '@cooltra/api';
import {
  getErrorStatus,
  getZeusErrorMessage,
  ZeusErrorData,
} from '@cooltra/axios';
import { Form, FormProvider, FormHelpers, FormButton } from '@cooltra/form';

import { useNotification } from '~/hooks';
import { UnsavedChangesPrompt, AddressFields } from '~/common';

import { CompanyName } from '../CompanyName/CompanyName';

import {
  validateBillingAddressForm,
  BillingAddressFormValues,
  getDocumentsFormInitialValues,
  getUserDocumentsPayload,
} from './billing-address-form';
import messages from './messages';

export type BillingAddressProps = {
  user: User;
};

export const BillingAddressForm = ({ user }: BillingAddressProps) => {
  const intl = useIntl();

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const { id = '' } = useParams<'id'>();
  const mutation = useUserBillingAddressMutation(id);

  const handleSubmit = (
    values: BillingAddressFormValues,
    { resetForm }: FormHelpers<BillingAddressFormValues>
  ) =>
    mutation
      .mutateAsync(getUserDocumentsPayload(values, user.version))
      .then(() => {
        addSuccessNotification(intl.formatMessage(messages.submissionSuccess));
        resetForm(values);
      })
      .catch((error: AxiosError<ZeusErrorData>) => {
        if (getErrorStatus(error) === 409) {
          addErrorNotification(
            intl.formatMessage(messages.conflictErrorMessage)
          );
        } else {
          const errorMessage = getZeusErrorMessage(error);
          addErrorNotification(errorMessage);
        }
      });

  return (
    <FormProvider
      initialValues={getDocumentsFormInitialValues(user)}
      onSubmit={handleSubmit}
      validate={validateBillingAddressForm(intl)}
    >
      <Form>
        <div data-testid="EDIT_USER_BILLING_ADDRESS">
          <Card className="mb-5">
            <div className="p-10 mb-5">
              <div className="grid grid-cols-6 gap-x-5 gap-y-10">
                <div className="col-span-6">
                  <CompanyName />
                </div>
                <div className="col-span-4">
                  <AddressFields.Street />
                </div>
                <div className="col-span-2">
                  <AddressFields.Zip />
                </div>
                <div className="col-span-3">
                  <AddressFields.City />
                </div>
                <div className="col-span-3">
                  <AddressFields.Country />
                </div>
              </div>
            </div>
          </Card>
          <UnsavedChangesPrompt />
          <div className="flex justify-end">
            <FormButton
              trailingIcon={<MdOutlineChevronRight className="text-xl" />}
            >
              <FormattedMessage {...messages.submitBtn} />
            </FormButton>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};
