import { WorkShiftSummary } from '@cooltra/api/src/modules/work-shifts/types';

import * as Stats from '../../../../common/Shift/ShiftSummary/ShiftStats/Stats';

export type ShiftStatsProps = {
  summary: Pick<
    WorkShiftSummary,
    | 'batterySwaps'
    | 'vehiclesOperational'
    | 'vehiclesWorked'
    | 'energyAdded'
    | 'closedTasks'
    | 'averageEnergyAdded'
  >;
};

export const ShiftStats = ({
  summary: {
    batterySwaps,
    vehiclesOperational,
    vehiclesWorked,
    energyAdded,
    closedTasks,
    averageEnergyAdded,
  },
}: ShiftStatsProps) => (
  <div className="grid grid-cols-3 grid-rows-2 gap-2 text-center items-center">
    <Stats.Swaps batterySwaps={batterySwaps} />
    <Stats.VehiclesWorked vehiclesWorked={vehiclesWorked} />
    <Stats.EnergyAdded energyAdded={energyAdded} />
    <Stats.Operational vehiclesOperational={vehiclesOperational} />
    <Stats.ClosedTasks closedTasks={closedTasks} />
    <Stats.AverageEnergy averageEnergyAdded={averageEnergyAdded} />
  </div>
);
