import { FormattedMessage } from 'react-intl';

import { ServiceFields } from '~/common';

import messages from './messages';

export const CreateTechnicalInspectionForm = () => {
  return (
    <div>
      <div className="mt-5">
        <div className="w-full grid grid-cols-2 gap-4">
          <ServiceFields.ServicedBy />
          <ServiceFields.ServicedAt />
        </div>
      </div>
      <div className="mt-6">
        <h6 className="text-neutral-400 text-lg font-semibold">
          <FormattedMessage {...messages.newTechnicalInspection} />
        </h6>
        <div className="flex flex-col gap-y-6 mt-4">
          <div className="w-1/2">
            <ServiceFields.ScheduledAt optional={false} />
          </div>
        </div>
      </div>
    </div>
  );
};
