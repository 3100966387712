import { UserDiscountListItemState } from '@cooltra/api';
import { Badge } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type UserDiscountStateProps = {
  state: UserDiscountListItemState;
};

export const UserDiscountState = ({ state }: UserDiscountStateProps) => {
  if (state === 'EXPIRED') {
    return (
      <Badge emphasis="low">
        <FormattedMessage {...messages.expired} />
      </Badge>
    );
  }

  if (state === 'SCHEDULED') {
    return (
      <Badge>
        <FormattedMessage {...messages.scheduled} />
      </Badge>
    );
  }

  if (state === 'USED') {
    return (
      <Badge emphasis="low">
        <FormattedMessage {...messages.used} />
      </Badge>
    );
  }

  return (
    <Badge variant="success">
      <FormattedMessage {...messages.active} />
    </Badge>
  );
};
