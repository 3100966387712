import { defineMessages } from 'react-intl';

export default defineMessages({
  sinceRentalActive: {
    defaultMessage: 'Since rental active: {rentalActiveOccurredOn}',
  },
  sinceRentalActiveWithMinutes: {
    defaultMessage:
      'Since rental active: {rentalActiveOccurredOn} ({rentalActiveOccurredOnInMinutes} min)',
  },
});
