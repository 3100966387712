import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { FormInputField, FormInputFieldProps } from '@cooltra/form';

import messages from './messages';

export type IdNumberProps = Omit<FormInputFieldProps, 'name' | 'id' | 'label'>;

export const IdNumber = (props: IdNumberProps) => (
  <FormInputField
    name="idCardNumber"
    id="id-card-number"
    label={
      <InputLabel htmlFor="id-card-number">
        <FormattedMessage {...messages.idCardNumber} />
      </InputLabel>
    }
    {...props}
  />
);
