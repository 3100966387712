import { ReactNode } from 'react';
import { classNames } from '@cooltra/utils';

export type PairProps = {
  label: ReactNode;
  value: ReactNode;
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
};

export const Pair = ({
  label,
  value,
  className,
  labelClassName,
  valueClassName,
}: PairProps) => (
  <div
    className={classNames('flex justify-between items-center h-14', className)}
  >
    <span className={classNames('text-sm', labelClassName)}>{label}</span>
    <span className={classNames('text-neutral-600 text-md', valueClassName)}>
      {value}
    </span>
  </div>
);
