import { HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type NotAvailableProps = Omit<
  HTMLAttributes<HTMLSpanElement>,
  'children'
>;

export const NotAvailable = (props: NotAvailableProps) => (
  <span {...props}>
    <FormattedMessage {...messages.notAvailable} />
  </span>
);
