import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@cooltra/ui';
import { useUpdateVehiclesHomeSystemMutation } from '@cooltra/api';
import { System, useAuthClaimsQuery } from '@cooltra/auth-api';
import { useToggle } from '@cooltra/hooks';

import { ChangeVehiclesHomeSystemModal, useSystemOptions } from '~/common';
import { useNotification } from '~/hooks';

import messages from './messages';

export type MoveToHomeSystemProps = {
  vehicleIds: string[];
  refetchVehicles: () => void;
  disabled: boolean;
};

export const MoveToHomeSystem = ({
  vehicleIds,
  refetchVehicles,
  disabled,
}: MoveToHomeSystemProps) => {
  const { formatMessage } = useIntl();
  const [isOpen, { toggleOff, toggleOn }] = useToggle();

  const systemOptions = useSystemOptions();
  const { hasPermission } = useAuthClaimsQuery();

  const { addErrorNotification, addSuccessNotification } = useNotification();
  const { isPending, mutateAsync } = useUpdateVehiclesHomeSystemMutation();

  const handleOnSave = (homeSystem: System) =>
    mutateAsync({
      vehicleIds,
      homeSystem,
    })
      .then(() => {
        addSuccessNotification(
          formatMessage(messages.success, { homeSystem: homeSystem })
        );
        refetchVehicles();
        toggleOff();
      })
      .catch(() => addErrorNotification());

  if (
    systemOptions.length <= 1 ||
    !hasPermission('bulk:vehicles_home_system')
  ) {
    return null;
  }

  return (
    <>
      <ChangeVehiclesHomeSystemModal
        title={formatMessage(messages.moveHomeSystem)}
        onSave={handleOnSave}
        onClose={toggleOff}
        isOpen={isOpen}
        loading={isPending}
      />
      <Button emphasis="high" size="sm" onClick={toggleOn} disabled={disabled}>
        <FormattedMessage {...messages.moveHomeSystem} />
      </Button>
    </>
  );
};
