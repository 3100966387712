import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Unsaved changes',
  },
  content: {
    defaultMessage:
      "It looks like you're in the middle of something and you haven't saved your changes",
  },
  cancelBtn: {
    defaultMessage: 'Discard changes',
  },
  confirmBtn: {
    defaultMessage: 'Save & continue',
  },
});
