import { defineMessages } from 'react-intl';

export default defineMessages({
  active: {
    defaultMessage: 'Active',
  },
  expired: {
    defaultMessage: 'Expired',
  },
  scheduled: {
    defaultMessage: 'Scheduled',
  },
});
