import {
  Task,
  usePatchTaskMutation,
  useTaskCommentMutation,
  useTaskCommentsQuery,
  useTaskStatusMutation,
  Vehicle,
} from '@cooltra/api';
import { ScrollToTop } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, FormButton, FormHelpers } from '@cooltra/form';

import {
  DividedCard,
  VehiclePair,
  SystemPair,
  Log,
  ProblemReportPair,
  TaskBackLink,
  CommentFormValues,
  commentInitialFormValues,
  CommentForm,
  ClosedBadge,
  IssueFields,
  TaskFields,
  TaskFormValues,
  TaskFormProvider,
} from '~/common';
import { useNotification } from '~/hooks';
import { useVehiclePolling } from '~/libs/polling-data';

import { Comments } from '../../Comments/Comments';
import { ToggleStatusButton } from '../ToggleStatusButton/ToggleStatusButton';

import messages from './messages';

export type EditTaskProps = {
  task: Task;
  vehicle: Vehicle;
};

export const EditTask = ({ task, vehicle }: EditTaskProps) => {
  const intl = useIntl();
  const { vehicleId } = task;
  const { model, externalId } = vehicle;

  const taskMutation = usePatchTaskMutation(task.taskId);
  const { activatePollingVehicle } = useVehiclePolling();

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const {
    taskId,
    open,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    problemReportId,
    system,
    ...taskInitialFormValues
  } = task;

  const handleSubmit = (
    values: TaskFormValues,
    { resetForm }: FormHelpers<TaskFormValues>
  ) =>
    taskMutation
      .mutateAsync({
        vehicleId,
        vehicleExternalId: externalId,
        problemReportId,
        ...values,
      })
      .then(() => {
        resetForm(values);
        addSuccessNotification(intl.formatMessage(messages.success));
        if (values.blocking) {
          activatePollingVehicle(vehicleId);
        }
      })
      .catch(() => {
        addErrorNotification();
      });

  const taskStatusMutation = useTaskStatusMutation(taskId);

  const handleTogglingTaskStatus = () =>
    taskStatusMutation
      .mutateAsync({ open: !open })
      .then(() => {
        if (task.blocking) {
          activatePollingVehicle(vehicleId);
        }
      })
      .catch(() => addErrorNotification());

  const { mutateAsync } = useTaskCommentMutation(taskId);

  const { data: comments } = useTaskCommentsQuery(taskId);

  const handlePostingComment = (
    values: CommentFormValues,
    { resetForm }: FormHelpers<CommentFormValues>
  ) =>
    mutateAsync(values)
      .then(() => resetForm(commentInitialFormValues))
      .catch(() => addErrorNotification());

  return (
    <div className="pb-32">
      <ScrollToTop />
      <TaskFormProvider
        disabled={!task.open}
        initialValues={taskInitialFormValues}
        onSubmit={handleSubmit}
      >
        <Form>
          <DividedCard
            className="min-w-4xl max-w-5xl py-12"
            data-testid="EDIT_TASK"
            renderHeader={
              <>
                <div className="flex gap-8 justify-between items-end">
                  <div>
                    <TaskBackLink vehicleId={vehicleId} />
                    <h2 className="text-3xl">
                      <FormattedMessage {...messages.title} />
                    </h2>
                  </div>
                  <div className="flex gap-4">
                    <ToggleStatusButton
                      onClick={handleTogglingTaskStatus}
                      loading={taskStatusMutation.isPending}
                      isTaskOpen={open}
                    />
                    {open && (
                      <FormButton disabled={!vehicle}>
                        <FormattedMessage {...messages.editTask} />
                      </FormButton>
                    )}
                  </div>
                </div>
                {!open && (
                  <div className="mt-4">
                    <ClosedBadge />
                  </div>
                )}
              </>
            }
            renderLeft={
              <div className="flex h-full flex-col gap-12 justify-between">
                <div className="flex flex-col gap-6">
                  <VehiclePair
                    vehicleId={vehicleId}
                    model={model}
                    externalId={externalId}
                    identificationNumber={vehicle.identificationNumber}
                  />
                  <SystemPair system={system} />
                  {problemReportId && (
                    <ProblemReportPair problemReportId={problemReportId} />
                  )}
                </div>
                <div className="flex flex-col gap-6">
                  <Log
                    user="operator"
                    action="create"
                    userId={createdBy}
                    date={createdAt}
                  />
                  {updatedBy && updatedAt && (
                    <Log
                      user="operator"
                      action="update"
                      userId={updatedBy}
                      date={updatedAt}
                    />
                  )}
                </div>
              </div>
            }
            renderRight={
              <div className="flex flex-col gap-y-6 gap-x-8">
                <TaskFields.Title system={system} />
                <div className="grid grid-cols-2 gap-x-8">
                  <IssueFields.Assignee />
                  <IssueFields.Operator systems={[system]} />
                </div>
                <TaskFields.Tags />
                <IssueFields.Description />
                <TaskFields.Blocking />
              </div>
            }
          />
        </Form>
      </TaskFormProvider>
      <div className="container min-w-4xl max-w-5xl">
        <div className="grid grid-cols-3">
          <div className="col-span-1">
            <CommentForm
              disabled={!!comments && comments.length > 100}
              onSubmit={handlePostingComment}
            />
          </div>
          <div className="col-span-2 pl-14" data-testid="TASK_COMMENTS">
            <Comments taskId={taskId} />
          </div>
        </div>
      </div>
    </div>
  );
};
