import { FormattedMessage } from 'react-intl';
import { InputLabel } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';

import { FormNumberInputField } from '../../../../FormattedInput';
import { PromotionFormValues } from '../../PromotionForm';
import { PromotionStateType } from '../../PromotionState/PromotionState';

import messages from './messages';

type MaxUsesProps = {
  className: string;
};

export const MaxUses = ({ className }: MaxUsesProps) => {
  const { values } = useFormContext<PromotionFormValues>();
  const { editMode, stateType, promotionCodeMode } = values;
  const editingExpiredPromotion =
    editMode && stateType === PromotionStateType.EXPIRED;

  if (promotionCodeMode !== 'single-code') {
    return <></>;
  }

  return (
    <div className={className}>
      <FormNumberInputField
        name="maxUses"
        id="max-uses"
        disabled={editingExpiredPromotion}
        label={
          <InputLabel htmlFor="max-uses">
            <FormattedMessage {...messages.maxUses} />
          </InputLabel>
        }
      />
    </div>
  );
};
