import { Icon } from '@cooltra/ui';
import { useIntl } from 'react-intl';
import { UserSession } from '@cooltra/api';
import { HTMLAttributes } from 'react';
import { classNames } from '@cooltra/utils';

import { CustomIcon } from '../../CustomIcon';

import messages from './messages';
import { parseUserAgent } from './parse-user-agent';

export type SessionDeviceProps = HTMLAttributes<HTMLSpanElement> & {
  session: UserSession;
  classNameValue?: string;
};

export const SessionDevice = ({
  session,
  className,
  classNameValue,
  ...rest
}: SessionDeviceProps) => {
  const { formatMessage } = useIntl();

  const getParsedUserAgent = (session: UserSession) => {
    try {
      return parseUserAgent(session.device);
    } catch (err) {
      return {
        os: undefined,
        parsedValue: formatMessage(messages.unknownClient),
      };
    }
  };

  const { os, parsedValue } = getParsedUserAgent(session);

  return (
    <span
      className={classNames(
        'flex items-center text-neutral-400 max-w-full',
        className
      )}
      {...rest}
    >
      {os === 'ios' ? (
        <Icon className="text-neutral-300 text-lg pr-1" data-testid="IOS_ICON">
          <CustomIcon.Ios />
        </Icon>
      ) : os === 'android' ? (
        <Icon
          className="text-neutral-300 text-lg pr-1"
          data-testid="ANDROID_ICON"
        >
          <CustomIcon.Android />
        </Icon>
      ) : null}
      <span className={classNameValue}>{parsedValue}</span>
    </span>
  );
};
