import { useIntl } from 'react-intl';
import { Form, FormProvider, FormProviderProps } from '@cooltra/form';

import { CooltraForEmployeesFormValues } from './cooltra-for-employees-form';
import { validateCooltraForEmployeesForm } from './cooltra-for-employees-form-validation';

export type CooltraForEmployeesFormProps = Omit<
  FormProviderProps<CooltraForEmployeesFormValues>,
  'validate'
>;

export const CooltraForEmployeesForm = ({
  onSubmit,
  initialValues,
  children,
}: CooltraForEmployeesFormProps) => {
  const intl = useIntl();

  return (
    <FormProvider
      initialValues={initialValues}
      validate={validateCooltraForEmployeesForm(intl)}
      onSubmit={onSubmit}
    >
      <Form>{children}</Form>
    </FormProvider>
  );
};
