import { defineMessages } from 'react-intl';

export default defineMessages({
  vehicleTypes: {
    defaultMessage: 'Vehicle types',
  },
  accessoriesAndCoverage: {
    defaultMessage: 'Accessories and coverages',
  },
  deposits: {
    defaultMessage: 'Deposits',
  },
});
