import { Contract } from '@cooltra/station-based-api';

export type UserProps = {
  contract: Contract;
};

export const User = ({ contract }: UserProps) => {
  const { user } = contract;

  if (!user) return null;

  return (
    <div>
      <span className="block mb-2 text-lg font-semibold text-neutral-800">
        {user.firstName} {user.lastName}
      </span>
      <span className="block mb-1 text-xs text-neutral-800">{user.phone}</span>
      <span className="block text-xs text-neutral-800">{user.email}</span>
    </div>
  );
};
