import { Icon } from '@cooltra/ui';
import { ReactNode } from 'react';

export type UserDataItemProps = {
  icon: ReactNode;
  label: ReactNode;
};

export const UserDataItem = ({ icon, label }: UserDataItemProps) => (
  <span className="flex items-center gap-2">
    <Icon className="text-neutral-300">{icon}</Icon>
    <span className="text-sm text-neutral-800">{label}</span>
  </span>
);
