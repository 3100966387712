import { FormattedMessage } from 'react-intl';
import { RouterTab } from '@cooltra/navigation';
import { Route, Routes } from 'react-router-dom';
import { Provider } from '@cooltra/api';
import { System, useAuthClaimsQuery } from '@cooltra/auth-api';

import { ChargeUser, Invoices } from '~/common';

import messages from './messages';

export type BillingProps = {
  userId: string;
  system: System;
  provider: Provider;
};

export const Billing = ({ userId, system, provider }: BillingProps) => {
  const { hasPermission } = useAuthClaimsQuery();
  const canWriteInvoices = hasPermission('write:invoices');

  return (
    <div
      className="container min-w-3xl max-w-6xl py-12"
      data-testid="USER_BILLING"
    >
      <div className="flex gap-8">
        <div className="flex-1 flex gap-6">
          <RouterTab className="py-2 truncate" to="invoices">
            <FormattedMessage {...messages.invoices} />
          </RouterTab>
        </div>
        <div>
          <Routes>
            {canWriteInvoices && (
              <Route
                path="invoices"
                element={
                  <ChargeUser
                    userId={userId}
                    system={system}
                    provider={provider}
                  />
                }
              />
            )}
          </Routes>
        </div>
      </div>
      <Routes>
        <Route path="*" element={<Invoices userId={userId} />} />
      </Routes>
    </div>
  );
};
