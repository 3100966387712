import { FormattedMessage } from 'react-intl';
import { Placeholder } from '@cooltra/ui';
import { Telematics } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';

import { TelematicsName, TimeAgo } from '~/common';

import { TelematicsItemLayout } from '../TelematicsItemLayout/TelematicsItemLayout';
import { TelematicsFieldProps } from '../types';

import messages from './messages';

export type LastSyncProps = TelematicsFieldProps & {
  telematics?: Telematics;
};

export const LastSync = ({
  vehicleStatus,
  error,
  telematics,
}: LastSyncProps) => (
  <TelematicsItemLayout
    title={
      telematics ? (
        <p className="text-base font-semibold">
          <TelematicsName telematics={telematics} />
        </p>
      ) : (
        <Placeholder className="w-20 h-2" />
      )
    }
    value={
      getErrorStatus(error) === 404 ? (
        <></>
      ) : vehicleStatus ? (
        <p className="text-xs text-neutral-300">
          <FormattedMessage
            {...messages.lastSync}
            values={{
              timeInThePast: <TimeAgo ISODate={vehicleStatus?.updatedAt} />,
            }}
          />
        </p>
      ) : (
        <Placeholder className="w-20 h-2" />
      )
    }
  />
);
