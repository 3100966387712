import { defineMessages } from 'react-intl';

export default defineMessages({
  addTask: {
    defaultMessage: 'Add task',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  save: {
    defaultMessage: 'Save',
  },
  success: {
    defaultMessage: 'Tasks have been successfully created!',
  },
});
