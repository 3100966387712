import { defineMessages } from 'react-intl';

export default defineMessages({
  validFrom: {
    defaultMessage: 'Purchasable from',
  },
  validUntil: {
    defaultMessage: 'Purchasable until',
  },
  date: {
    defaultMessage: 'Date',
  },
  time: {
    defaultMessage: 'Time',
  },
  doesntExpire: {
    defaultMessage: "Doesn't expire",
  },
});
