import { About } from './About/About';
import { Code } from './Code/Code';
import { BenefitExpiresAt } from './BenefitExpiresAt/BenefitExpiresAt';
import { CreditValue } from './CreditValue/CreditValue';
import { Discount } from './Discount/Discount';
import { DownloadCodes } from './DownloadCodes/DownloadCodes';
import { MaxUses } from './MaxUses/MaxUses';
import { MultiCode } from './MultiCode/MultiCode';
import { PromotionCodeSelectorMode } from './PromotionCodeSelectorMode/PromotionCodeSelectorMode';
import { PromotionTypeSelectorMode } from './PromotionTypeSelectorMode/PromotionTypeSelectorMode';
import { Registration } from './Registration/Registration';
import { TargetHomeSystems } from './TargetHomeSystems/TargetHomeSystems';
import { TargetVehicleModels } from './TargetVehicleModels/TargetVehicleModels';
import { ValidityDates } from './ValidityDates/ValidityDates';
import { UserCanRedeemMoreThanOneCode } from './UserCanRedeemMoreThanOneCode/UserCanRedeemMoreThanOneCode';

export const PromotionFields = {
  About,
  Code,
  BenefitExpiresAt,
  CreditValue,
  Discount,
  DownloadCodes,
  MaxUses,
  MultiCode,
  PromotionCodeSelectorMode,
  PromotionTypeSelectorMode,
  Registration,
  ValidityDates,
  UserCanRedeemMoreThanOneCode,
  TargetHomeSystems,
  TargetVehicleModels,
};
