import { FormattedMessage, useIntl } from 'react-intl';
import { Invoice, useUserQuery, useInvoicePdfQuery } from '@cooltra/api';
import { RouterLink } from '@cooltra/navigation';
import { Button } from '@cooltra/ui';
import { shortenId } from '@cooltra/utils';
import { useEffect } from 'react';

import {
  Amount,
  ByOperator,
  DateTime,
  InvoiceLegalEntity,
  Log,
  Pair,
  SidebarItem,
  UserLink,
} from '~/common';
import { useNotification } from '~/hooks';
import { isCreditNote } from '~/utils/invoice';

import messages from './messages';

export type InvoiceSummaryProps = {
  invoice: Invoice;
  invoiceId: string;
};

export const InvoiceSummary = ({
  invoice: {
    total,
    legalEntity,
    paidAt,
    issuedAt,
    issuedBy,
    userId,
    originalInvoiceId,
    type,
    markAsPaidBy,
    markAsPaidComment,
  },
  invoiceId,
}: InvoiceSummaryProps) => {
  const { data: user } = useUserQuery(userId);
  const { addErrorNotification } = useNotification();
  const { formatMessage } = useIntl();
  const { fetchStatus, refetch, error } = useInvoicePdfQuery(invoiceId, {
    enabled: false,
  });

  useEffect(() => {
    if (error) {
      switch (error.response?.status) {
        case 404:
          addErrorNotification(formatMessage(messages.pdfDoesNotExist));
          break;
        default:
          addErrorNotification(
            formatMessage(messages.somethingWentWrongOnDownloadingPdf)
          );
          break;
      }
    }
  }, [addErrorNotification, error, formatMessage]);

  return (
    <div className="flex flex-col gap-7 pb-3" data-testid="INVOICE_SUMMARY">
      <SidebarItem
        copyValue={invoiceId}
        label={<FormattedMessage {...messages.id} />}
        value={invoiceId}
      />
      <div>
        <Pair.Label>
          <FormattedMessage {...messages.total} />
        </Pair.Label>
        <Amount
          {...total}
          className="text-2xl font-semibold text-neutral-800"
        />
      </div>
      <div>
        <Pair.Label>
          <FormattedMessage {...messages.legalEntity} />
        </Pair.Label>
        <Pair.Value>
          <InvoiceLegalEntity legalEntity={legalEntity} />
        </Pair.Value>
      </div>
      <div>
        <Pair.Label>
          <FormattedMessage {...messages.recipient} />
        </Pair.Label>
        <UserLink provider="COOLTRA" userId={userId} className="text-sm">
          {user?.name}
        </UserLink>
      </div>
      <div>
        <Log user="operator" action="issue" userId={issuedBy} date={issuedAt} />
      </div>
      {paidAt && (
        <div>
          <Pair.Label>
            <FormattedMessage {...messages.paid} />
          </Pair.Label>
          <Pair.Value>
            <DateTime date={paidAt} />
          </Pair.Value>
        </div>
      )}
      {markAsPaidBy && (
        <div>
          <Pair.Label>
            <FormattedMessage {...messages.markedAsPaid} />
          </Pair.Label>
          <Pair.Value>
            <ByOperator id={markAsPaidBy} />
          </Pair.Value>
        </div>
      )}
      {markAsPaidComment && (
        <div>
          <Pair.Label>
            <FormattedMessage {...messages.comment} />
          </Pair.Label>
          <Pair.Value>{markAsPaidComment}</Pair.Value>
        </div>
      )}
      {isCreditNote(type) && (
        <div className="mt-2">
          <Pair.Label>
            <FormattedMessage {...messages.refundsInvoice} />
          </Pair.Label>
          <Pair.Value>
            <RouterLink to={`/invoices/${originalInvoiceId}`}>
              #{shortenId(originalInvoiceId)}
            </RouterLink>
          </Pair.Value>
        </div>
      )}
      <div className="mt-2">
        <Button
          size="sm"
          onClick={() => refetch()}
          loading={fetchStatus === 'fetching'}
          emphasis="medium"
        >
          <FormattedMessage {...messages.invoicePdf} />
        </Button>
      </div>
    </div>
  );
};
