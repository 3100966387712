import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    defaultMessage: 'Promotion search',
  },
  placeholder: {
    defaultMessage: 'Code, title, description or about',
  },
});
