import { useState } from 'react';
import { Modal } from '@cooltra/ui';
import { RouterLink } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type CookiesModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const localStorageKey = 'zeus-cooltra-cookies-consent';

const getSavedCookiesConsent = (): boolean | null =>
  Boolean(localStorage.getItem(localStorageKey));

const saveCookiesConsent = (hasAgreed: boolean) => {
  localStorage.setItem(localStorageKey, hasAgreed.toString());
};

export const CookiesModal = () => {
  const [cookiesConsent, setCookiesConsent] = useState(
    getSavedCookiesConsent() || false
  );

  const handleClose = () => {
    setCookiesConsent(true);
    saveCookiesConsent(true);
  };

  return (
    <Modal type="bottom" isOpen={!cookiesConsent} onRequestClose={handleClose}>
      <p className="text-base text-neutral-700 pb-6 px-8">
        <FormattedMessage
          {...messages.disclaimer}
          values={{
            cookiesPolicy: (
              <RouterLink onClick={handleClose} to="/cookies-policy">
                <FormattedMessage {...messages.cookiesPolicy} />
              </RouterLink>
            ),
          }}
        />
      </p>
    </Modal>
  );
};
