import { FormattedMessage } from 'react-intl';

import messages from './messages';

type TasksProps = {
  openTasksNumber: number;
  blockingTasksNumber: number;
};

export const Tasks = ({ openTasksNumber, blockingTasksNumber }: TasksProps) => {
  if (openTasksNumber == 0) {
    return (
      <div className="flex items-center h-full">
        <p className="text-xs" data-testid="VEHICLE_LIST_ITEM_TASKS">
          <span className="text-neutral-300">
            <FormattedMessage {...messages.noTasks} />
          </span>
        </p>
      </div>
    );
  }

  const BlockingTaskJSX = () => {
    if (blockingTasksNumber == 0) {
      return <></>;
    }

    return (
      <span className="font-semibold">
        , {blockingTasksNumber} <FormattedMessage {...messages.blocking} />
      </span>
    );
  };

  return (
    <div className="flex items-center h-full">
      <p className="text-xs" data-testid="VEHICLE_LIST_ITEM_TASKS">
        <span className="text-neutral-900">
          {openTasksNumber} <FormattedMessage {...messages.tasks} />
          <BlockingTaskJSX />
        </span>
      </p>
    </div>
  );
};
