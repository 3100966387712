import { FormProvider, FormProviderProps } from '@cooltra/form';

import { ServiceFormValues } from './service-form';

export const ServiceFormProvider = ({
  onSubmit,
  initialValues,
  children,
  validate,
}: FormProviderProps<ServiceFormValues>) => {
  return (
    <FormProvider
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {children}
    </FormProvider>
  );
};
