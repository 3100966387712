import {
  UserSubscriptionListItem,
  useUserSubscriptionsQuery,
} from '@cooltra/api';
import { List } from '@cooltra/ui';

import { ErrorPage } from '~/common';

import { SubscriptionCard, SubscriptionCardLoading } from '../SubscriptionCard';
import { SubscriptionsListHeader } from '../SubscriptionsListHeader/SubscriptionsListHeader';

export type SubscriptionsProps = {
  userId: string;
};

export const Subscriptions = ({ userId }: SubscriptionsProps) => {
  const { data, isLoading, isError } = useUserSubscriptionsQuery(userId);

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div data-testid="USER_SUBSCRIPTIONS" className="pt-10">
      <List
        loadingRows={4}
        isLoading={isLoading}
        idProp="validFrom"
        data={data?.subscriptions || []}
        renderHeader={<SubscriptionsListHeader />}
        renderLoadingRow={<SubscriptionCardLoading />}
        renderRow={(subscription: UserSubscriptionListItem) => (
          <SubscriptionCard subscription={subscription} />
        )}
      />
    </div>
  );
};
