import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { Model, modelTelematics } from '@cooltra/api';
import { useField, FormSelectField } from '@cooltra/form';

import { useTelematicsOptions } from '~/common';

import messages from './messages';

export const Telematics = () => {
  const telematicsOptions = useTelematicsOptions();
  const { value: model } = useField<Model>('model');

  const optionValues = model
    ? telematicsOptions.filter(({ value }) =>
        modelTelematics[model].includes(value)
      )
    : telematicsOptions;

  return (
    <FormSelectField
      id="telematics"
      label={
        <InputLabel htmlFor="telematics">
          <FormattedMessage {...messages.telematics} />
        </InputLabel>
      }
      options={optionValues}
      name="telematics"
    />
  );
};
