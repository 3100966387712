import { languages } from '@cooltra/countries';
import { Divider } from '@cooltra/ui';
import { getKeys } from '@cooltra/utils';
import { useFormContext } from '@cooltra/form';

import { useAccordion } from '~/hooks';

import { TranslationSectionHeader } from './TranslationSectionHeader';
import { TranslationsSection } from './TranslationSection';

export type TranslationsProps = {
  disabled?: boolean;
  requireDescription?: boolean;
  includeDescription?: boolean;
};

export const Translations = ({
  disabled = false,
  requireDescription = false,
  includeDescription = true,
}: TranslationsProps) => {
  const { isFormDisabled } = useFormContext();
  const { getHeaderProps, getSectionProps, isActiveSection } = useAccordion([
    0,
  ]);

  return (
    <div>
      {getKeys(languages).map((language, index) => {
        const id = `${language}-accordion-item`;
        const { onClick, ...headerPropsRest } = getHeaderProps(id, index);
        const { ref: sectionRef, ...sectionPropsRest } = getSectionProps(
          id,
          index
        );
        const { key: headerKey, ...headerProps } = headerPropsRest;
        const { key: sectionKey, ...sectionProps } = sectionPropsRest;
        return (
          <div key={id}>
            <Divider />
            <TranslationSectionHeader
              isActive={isActiveSection(index)}
              language={language}
              onClick={onClick}
              key={headerKey}
              {...headerProps}
            />
            <TranslationsSection
              key={sectionKey}
              language={language}
              ref={sectionRef}
              disabled={isFormDisabled || disabled}
              requireDescription={requireDescription}
              includeDescription={includeDescription}
              {...sectionProps}
            />
          </div>
        );
      })}
    </div>
  );
};
