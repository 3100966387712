import { Button, Icon } from '@cooltra/ui';
import { MdRefresh } from 'react-icons/md';
import { ButtonHTMLAttributes } from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';

type RefreshButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isLoading: boolean;
};

export const RefreshButton = ({ isLoading, ...rest }: RefreshButtonProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className="bg-neutral-0 shadow rounded-md">
      <Button
        className="bg-neutral-0 rounded-md"
        loading={isLoading}
        {...rest}
        aria-label={formatMessage(messages.refresh)}
        square
      >
        <Icon className="text-xl text-neutral-500">
          <MdRefresh />
        </Icon>
      </Button>
    </div>
  );
};
