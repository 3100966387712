import { IntlShape } from 'react-intl';
import { Incident } from '@cooltra/api';

import { validateRequired, createSetError } from '~/validation';

export type IncidentFormValues = Pick<
  Incident,
  'title' | 'operatorGroupId' | 'operatorId' | 'description'
>;

type IncidentFormFieldName = keyof IncidentFormValues;

type IncidentFormErrors = Partial<
  Record<IncidentFormFieldName, string | undefined>
>;

export const validateIncidentForm =
  (intl: IntlShape) => (values: IncidentFormValues) => {
    const errors: IncidentFormErrors = {};
    const setError = createSetError(errors);

    const titleErrorMessage = validateRequired(intl, values.title);
    setError('title', titleErrorMessage);

    const operatorGroupIdErrorMessage = validateRequired(
      intl,
      values.operatorGroupId
    );
    setError('operatorGroupId', operatorGroupIdErrorMessage);

    return errors;
  };

export const incidentInitialFormValues: IncidentFormValues = {
  title: '',
  operatorGroupId: '',
  operatorId: '',
  description: '',
};
