import { defineMessages } from 'react-intl';

export default defineMessages({
  currentServicePoint: {
    defaultMessage: 'Billing service point',
  },
  servicePointNotSetted: {
    defaultMessage: 'You must select a billing service point',
  },
  all: {
    defaultMessage: 'All',
  },
});
