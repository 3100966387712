import { parseISO } from 'date-fns';
import { createElement, HTMLAttributes } from 'react';
import { FormattedDate, useIntl } from 'react-intl';

type TagMap = {
  li: HTMLAttributes<HTMLLIElement>;
  th: HTMLAttributes<HTMLTableCellElement>;
};

export type CalendarHeadCell<Tag extends keyof TagMap> = Omit<
  TagMap[Tag],
  'children'
> & {
  as: Tag;
  weekDay: string;
};

export const CalendarHeadCell = <Tag extends keyof TagMap>({
  weekDay,
  as,
}: CalendarHeadCell<Tag>) => {
  const { formatDate } = useIntl();

  const date = parseISO(`2024-01-${weekDay}`);

  const props = {
    scope: 'col',
    'aria-label': formatDate(date, {
      weekday: 'long',
    }),
    className: 'text-center text-sm font-medium uppercase text-neutral-400',
  };

  const children = <FormattedDate weekday="short" value={date} />;

  return createElement(as, props, children);
};
