import { Rental } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { Amount } from '~/common';

import messages from './messages';

export type PricingRidingProps = Pick<Rental, 'pricing'>;

export const PricingRiding = ({ pricing }: PricingRidingProps) => {
  if (!pricing) {
    return <></>;
  }

  return (
    <div
      className="flex justify-between items-center"
      data-testid="RENTAL_PRICING_SUMMARY_RIDING"
    >
      <span className="text-sm text-neutral-400">
        <FormattedMessage
          {...messages.riding}
          values={{
            charge: (
              <Amount
                value={pricing.riding.charge}
                currency={pricing.riding.price.currency}
              />
            ),
            durationInMinutes: pricing.riding.duration,
          }}
        />
      </span>
      <Amount
        className="text-sm"
        value={pricing.riding.price.value}
        currency={pricing.riding.price.currency}
      />
    </div>
  );
};
