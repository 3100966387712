import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { FormInputField } from '@cooltra/form';

import messages from './messages';

export const Zip = () => (
  <FormInputField
    name="zip"
    id="zip"
    label={
      <InputLabel htmlFor="zip">
        <FormattedMessage {...messages.zip} />
      </InputLabel>
    }
  />
);
