import { VehicleListItem } from '@cooltra/api';

const defaultMapper: [
  string,
  (vehicle: VehicleListItem) => string | number | boolean,
][] = [
  ['home_system', ({ system }) => system],
  ['model', ({ model }) => model],
  ['telematics', ({ telematics }) => telematics],
  ['external_id', ({ externalId }) => externalId],
  ['telematics_id', ({ telematicsId }) => telematicsId],
  ['license_plate', ({ licensePlate }) => licensePlate],
  [
    'vehicle_identification_number',
    ({ identificationNumber }) => identificationNumber,
  ],
  ['insurance_expiration', ({ insuranceExpiresAt }) => insuranceExpiresAt],
  ['city_hall_published', ({ cityHallPublished }) => cityHallPublished],
  ['vehicle_id', ({ vehicleId }) => vehicleId],
  ['mileage', ({ status }) => status.mileage?.value || 0],
  [
    'mileage_to_next_service',
    ({ services }) => services?.mileageToService || '',
  ],
  [
    'remaining_mileage_to_next_service',
    ({ services }) => services?.remainingMileageToService || '',
  ],
  [
    'date_next_technical_inspection',
    ({ technicalInspections }) =>
      technicalInspections?.dateToTechnicalInspection || '',
  ],
];

export const vehiclesToCsv = (
  arr: VehicleListItem[],
  mapper: [
    string,
    (vehicle: VehicleListItem) => string | number | boolean,
  ][] = defaultMapper
) => {
  const keysRow = mapper.map(([header]) => header).join(',');

  const dataRows = arr
    .map((vehicle) =>
      mapper
        .map(([, select]) => {
          const value = select(vehicle);
          return typeof value === 'string' && value.includes(',')
            ? `"${value}"`
            : value;
        })
        .join(',')
    )
    .join('\n');
  return [keysRow, dataRows].join('\n');
};
