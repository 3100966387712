import { VehicleListItem } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import {
  ServiceScheduledMileageValue,
  TechnicalInspectionScheduledAtValue,
} from '~/common';

import messages from './messages';

export const Services = ({
  services,
  technicalInspections,
}: Pick<VehicleListItem, 'services' | 'technicalInspections'>) => {
  if (!services && !technicalInspections) {
    return <></>;
  }

  const hasNextTechnicalInspection = Boolean(
    technicalInspections?.dateToTechnicalInspection !== null &&
      technicalInspections?.remainingDaysToTechnicalInspection !== null
  );

  return (
    <div className="h-full gap-2">
      {services &&
        services?.mileageToService !== null &&
        services?.remainingMileageToService !== null && (
          <p className="flex gap-1 text-xs text-neutral-900 mb-0.5">
            <span className="text-neutral-400">
              <FormattedMessage {...messages.service} />
            </span>
            <span>
              <ServiceScheduledMileageValue
                nextMileageTo={services.mileageToService}
                remainingMileageTo={services.remainingMileageToService}
              />
            </span>
          </p>
        )}
      {technicalInspections && hasNextTechnicalInspection && (
        <p className="flex gap-1 text-xs text-neutral-900">
          <span className="text-neutral-400">
            <FormattedMessage {...messages.inspection} />
          </span>
          <span>
            <TechnicalInspectionScheduledAtValue
              date={new Date(technicalInspections.dateToTechnicalInspection)}
              remainingDays={
                technicalInspections.remainingDaysToTechnicalInspection
              }
            />
          </span>
        </p>
      )}
    </div>
  );
};
