import { defineMessages } from 'react-intl';

export default defineMessages({
  addSuspension: {
    defaultMessage: 'Add suspension',
  },
  comment: {
    defaultMessage: 'Comment',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  success: {
    defaultMessage: 'You have successfully suspended the user!',
  },
});
