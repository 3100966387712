import { Model } from '@cooltra/api';

import { BatteryState } from '~/common';

import { StatusContainer } from './StatusContainer';

type BatteryProps = {
  batteryCharge: number | undefined;
  model: Model;
};

export const Battery = ({ batteryCharge, model }: BatteryProps) => (
  <StatusContainer>
    {batteryCharge !== undefined && (
      <BatteryState value={batteryCharge} className="w-16" model={model} />
    )}
  </StatusContainer>
);
