import { Rental } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { Amount } from '~/common';

import messages from './messages';

export type PricingCreditProps = Pick<Rental, 'pricing'>;

export const PricingCredit = ({ pricing }: PricingCreditProps) => {
  if (!pricing) {
    return <></>;
  }

  return (
    <div
      className="flex justify-between items-center py-4"
      data-testid="RENTAL_PRICING_SUMMARY_CREDIT"
    >
      <span className="text-sm">
        <FormattedMessage {...messages.credit} />
      </span>
      <Amount
        className="text-sm"
        value={-pricing.creditUsed.value}
        currency={pricing.creditUsed.currency}
      />
    </div>
  );
};
