import { Contract } from '@cooltra/station-based-api';

import { ContractPendingSignAlert, Damages } from '~/common';
import { useContractFlags } from '~/hooks';

import { ChecksIn, ChecksOut } from '../Checks';
import { Penalties } from '../Penalties/Penalties';

export type ContractCheckAndDamagesProps = {
  contract: Contract;
};

export const ContractCheckAndDamages = ({
  contract,
}: ContractCheckAndDamagesProps) => {
  const { isSignedOrPendingSignature } = useContractFlags();

  const hasToDisplayChecksOut =
    contract.status === 'ACTIVE' || contract.status === 'CLOSED';
  const hasToDisplayPenalties =
    contract.status === 'ACTIVE' || contract.status === 'CLOSED';
  const isEnabled = ['ACTIVE', 'BOOKED', 'DRAFT'].includes(contract.status);
  const isDisabled = !!isSignedOrPendingSignature || !isEnabled;

  return (
    <>
      <div className="mx-auto max-w-4xl pt-4 pb-4">
        <ContractPendingSignAlert />
      </div>
      {hasToDisplayChecksOut ? (
        <ChecksOut contract={contract} disabled={isDisabled} />
      ) : (
        <ChecksIn contract={contract} disabled={isDisabled} />
      )}
      {hasToDisplayPenalties && (
        <Penalties contract={contract} disabled={isDisabled} />
      )}
      <Damages
        vehicleId={contract.vehicle?.assignedVehicle?.vehicleId}
        disabled={isDisabled}
      />
    </>
  );
};
