import { Legend } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';
import { FormattedMessage, useIntl } from 'react-intl';

import { StationBasedFormValues, StationBasedStateType } from '~/common';

import {
  FormDateInputField,
  FormTimeInputField,
} from '../../../../FormattedInput';

import messages from './messages';

export const ValidUntil = () => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext<StationBasedFormValues>();
  const { editMode, stateType } = values;
  const editingExpiredPromotion =
    editMode && stateType === StationBasedStateType.EXPIRED;

  return (
    <fieldset className="flex gap-2" data-testid="VALID_UNTIL">
      <Legend className="mb-1">
        <FormattedMessage {...messages.redeemableUntil} />
      </Legend>
      <FormDateInputField
        disabled={editingExpiredPromotion}
        className="w-32"
        name="validUntilDate"
        aria-label={formatMessage(messages.date)}
      />
      <FormTimeInputField
        disabled={editingExpiredPromotion}
        className="w-20"
        name="validUntilTime"
        aria-label={formatMessage(messages.time)}
      />
    </fieldset>
  );
};
