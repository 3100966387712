import { defineMessages } from 'react-intl';

export default defineMessages({
  addCredit: {
    defaultMessage: 'Add credit',
  },
  creditTitle: {
    defaultMessage: 'Credit title',
  },
  title: {
    defaultMessage: 'Title',
  },
  amount: {
    defaultMessage: 'Amount',
  },
  validUntil: {
    defaultMessage: 'Valid until',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  save: {
    defaultMessage: 'Save',
  },
});
