import { Card, Placeholder } from '@cooltra/ui';

import { CreditRowLayout } from '../CreditRowLayout';

export const CreditCardLoading = () => (
  <Card>
    <CreditRowLayout
      className="h-16"
      created={
        <div className="flex items-center gap-3">
          <Placeholder className="w-8 h-8 rounded-full" />
          <div className="flex flex-col gap-2.5">
            <Placeholder className="h-2 w-12" />
            <Placeholder className="h-2 w-20" />
          </div>
        </div>
      }
      title={<Placeholder className="h-2 w-40" />}
      amount={
        <div className="flex justify-end">
          <Placeholder className="h-2 w-12" />
        </div>
      }
      remainingAmount={
        <div className="flex justify-end">
          <Placeholder className="h-2 w-12" />
        </div>
      }
      expiresAt={
        <div className="flex flex-col gap-2.5">
          <Placeholder className="h-2 w-12" />
          <Placeholder className="h-2 w-20" />
        </div>
      }
      state={<Placeholder className="h-2 w-12 mr-4" />}
    />
  </Card>
);
