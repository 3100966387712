import { useVehicleQuery, useVehicleStatusQuery } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { useToggle } from '@cooltra/hooks';
import { Icon, Modal, Placeholder } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { MdLocationOn } from 'react-icons/md';

import { isTestingEnv } from '~/utils/e2e';
import { useReverseGeocode } from '~/hooks';

import { GeolocationMap } from '../GeolocationMap/GeolocationMap';

import messages from './messages';

export type GeolocationProps = {
  vehicleId: string;
};

export const Geolocation = ({ vehicleId }: GeolocationProps) => {
  const [isOpen, { toggleOff, toggleOn }] = useToggle();
  const { data: status, error } = useVehicleStatusQuery(vehicleId);
  const { data: vehicle } = useVehicleQuery(vehicleId);

  const latitude = status?.geoLocation?.latitude || 0;
  const longitude = status?.geoLocation?.longitude || 0;

  const hasGeolocation = !!longitude && !!latitude;

  const {
    data: address,
    fetchStatus,
    isLoading,
  } = useReverseGeocode(
    { latitude, longitude },
    { enabled: hasGeolocation && !isTestingEnv(), staleTime: Infinity }
  );

  if (getErrorStatus(error) === 404) {
    return (
      <span className="text-xs text-neutral-600 flex">
        <FormattedMessage {...messages.noGeolocation} />
      </span>
    );
  }

  if (!status || !vehicle || (fetchStatus !== 'idle' && isLoading)) {
    return <Placeholder className="h-2 my-1 w-32" />;
  }

  const { geoLocation } = status;

  if (geoLocation === null) {
    return (
      <span className="text-xs text-neutral-600 flex">
        <FormattedMessage {...messages.noGeolocation} />
      </span>
    );
  }

  return (
    <>
      <button className="link link-primary flex max-w-full" onClick={toggleOn}>
        <Icon className="text-neutral-300 mr-1.5">
          <MdLocationOn />
        </Icon>
        <span className="truncate text-left text-xs">
          <div>{address || <FormattedMessage {...messages.seeMap} />}</div>
          <div>
            ({longitude.toFixed(5)}, {latitude.toFixed(5)})
          </div>
        </span>
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleOff}
        className="max-w-screen-lg mb-14 h-4/5 w-4/5 overflow-hidden"
        header={
          <h6 className="text-sm text-neutral-700">
            <FormattedMessage {...messages.vehicleGeolocation} />
          </h6>
        }
      >
        <GeolocationMap status={status} vehicle={vehicle} />
      </Modal>
    </>
  );
};
