import { City, CityStatic } from './City';
import { Country, CountryStatic } from './Country';
import { Street, StreetStatic } from './Street';
import { Zip, ZipStatic } from './Zip';

export const AddressFields = {
  City,
  CityStatic,
  Country,
  CountryStatic,
  Street,
  StreetStatic,
  Zip,
  ZipStatic,
};
