import { FormattedMessage } from 'react-intl';

import { ListTotal, ListTotalProps } from '../../List';

import messages from './messages';

export type RentalsTotalProps = Omit<ListTotalProps, 'children'> & {
  total: number;
};

export const RentalsTotal = ({
  total,
  loading,
  ...rest
}: RentalsTotalProps) => (
  <ListTotal loading={loading} {...rest}>
    <FormattedMessage
      {...messages.rentals}
      values={{
        total,
      }}
    />
  </ListTotal>
);
