import { UserColumnLabel } from '~/common';

import { UserRowLayout } from './UserRowLayout';

export const UsersListHeader = () => (
  <UserRowLayout
    system={<UserColumnLabel name="system" />}
    name={<UserColumnLabel name="name" />}
    provider={<UserColumnLabel name="provider" />}
    status={
      <UserColumnLabel name="status" className="-ml-1 text-center block" />
    }
  />
);
