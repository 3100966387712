import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    defaultMessage: 'Search for keyword',
  },
  placeholder: {
    defaultMessage:
      'License plate, External ID, Internal ID, Telematics ID or Chassis number',
  },
});
