import { ReactNode } from 'react';

export type TaskRowLayoutProps = {
  task: ReactNode;
  created: ReactNode;
  updated: ReactNode;
};

export const TaskRowLayout = ({
  task,
  updated,
  created,
}: TaskRowLayoutProps) => (
  <div className="flex h-full w-full">
    <div className="flex items-center gap-4 w-full px-8">
      <div className="flex-1">{task}</div>
      <div className="w-48 shrink-0">{updated}</div>
      <div className="w-40 shrink-0">{created}</div>
    </div>
  </div>
);
