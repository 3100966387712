import { FormattedMessage } from 'react-intl';
import { System, useOperatorsQuery } from '@cooltra/auth-api';
import { useField, FormSelectField, useFormContext } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';

import {
  canAccessHomeSystem,
  isBlocked,
  isOperatorGroup,
} from '~/utils/operators';

import { OptionalFieldLabel } from '../../OptionalFieldLabel/OptionalFieldLabel';

import messages from './messages';

export type OperatorProps = {
  systems?: System[];
  optional?: boolean;
};

export const Operator = ({ optional = true, systems }: OperatorProps) => {
  const { isFormDisabled } = useFormContext();
  const { value: operatorGroupId } = useField<string>('operatorGroupId');
  const { data = [], isLoading } = useOperatorsQuery();

  return (
    <FormSelectField
      isLoading={isLoading}
      noOptionsMessage={() => <FormattedMessage {...messages.noOperators} />}
      id="task-operator"
      name="operatorId"
      disabled={isFormDisabled}
      isClearable
      options={data
        .filter(isBlocked(false))
        .filter(canAccessHomeSystem(systems ? systems : []))
        .filter(isOperatorGroup(operatorGroupId ? [operatorGroupId] : []))
        .sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
        .map(({ externalId, name }) => ({
          label: name,
          value: externalId,
        }))}
      label={
        optional ? (
          <OptionalFieldLabel htmlFor="task-operator">
            <FormattedMessage {...messages.operator} />
          </OptionalFieldLabel>
        ) : (
          <InputLabel htmlFor="task-operator">
            <FormattedMessage {...messages.operator} />
          </InputLabel>
        )
      }
    />
  );
};
