import { ManualInvoiceItem } from '@cooltra/api';

import {
  Amount,
  CanceledItemLabel,
  CancelInvoiceItem,
  DateTime,
  RefundedItemLabel,
  RefundInvoiceItem,
} from '~/common';

import { InvoiceItemRowLayout } from '../InvoiceItemRowLayout';

export const ManualItem = ({
  id,
  chargedAt,
  title,
  total,
  price,
  chargeState,
  type,
  isStateColumnDisplayed,
  refundable,
  cancelable,
}: ManualInvoiceItem & {
  isStateColumnDisplayed: boolean;
}) => (
  <InvoiceItemRowLayout
    className="text-sm text-neutral-800 py-4"
    chargedAt={<DateTime date={chargedAt} />}
    item={<span>{title}</span>}
    total={<Amount className="block text-right" {...total} />}
    price={<Amount className="block text-right" {...price} />}
    state={
      isStateColumnDisplayed && (
        <>
          {chargeState === 'REFUNDED' ? (
            <RefundedItemLabel />
          ) : refundable ? (
            <RefundInvoiceItem id={id} type={type} />
          ) : null}
          {chargeState === 'CANCELLED' ? (
            <CanceledItemLabel />
          ) : cancelable ? (
            <CancelInvoiceItem id={id} type={type} />
          ) : null}
        </>
      )
    }
  />
);
