import type { SVGProps } from 'react';

export const ClickAndRide = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 23c-.262 0-.525-.045-.787-.136a2.086 2.086 0 0 1-.704-.407 43.233 43.233 0 0 1-3.234-3.179c-.938-1.032-1.72-2.033-2.348-3.002-.629-.969-1.107-1.902-1.435-2.798C3.164 12.582 3 11.726 3 10.911c0-2.717.905-4.881 2.714-6.493C7.524 2.806 9.62 2 12 2s4.477.806 6.286 2.418C20.096 6.03 21 8.194 21 10.91c0 .815-.164 1.67-.492 2.567-.328.896-.806 1.83-1.435 2.798-.628.97-1.41 1.97-2.348 3.002a43.227 43.227 0 0 1-3.234 3.179c-.207.18-.441.317-.704.407-.262.09-.525.136-.787.136Zm0-12.307c.619 0 1.148-.212 1.59-.638.44-.426.66-.937.66-1.535 0-.598-.22-1.11-.66-1.535A2.206 2.206 0 0 0 12 6.347c-.619 0-1.148.213-1.59.638-.44.426-.66.937-.66 1.535 0 .598.22 1.11.66 1.535.442.426.971.638 1.59.638Zm0 5.434c.787 0 1.533-.154 2.236-.462a5.848 5.848 0 0 0 1.87-1.304.887.887 0 0 0 .211-.313.982.982 0 0 0 .07-.366c0-.2-.051-.38-.154-.544a1.321 1.321 0 0 0-.408-.407 8.866 8.866 0 0 0-1.856-.706A7.916 7.916 0 0 0 12 11.78c-.675 0-1.331.082-1.969.245a8.867 8.867 0 0 0-1.856.706.979.979 0 0 0-.394.38 1.07 1.07 0 0 0-.14.544c0 .126.018.249.056.366a.973.973 0 0 0 .197.34 5.848 5.848 0 0 0 1.87 1.304 5.513 5.513 0 0 0 2.236.462Z"
    />
  </svg>
);
