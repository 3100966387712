import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';
import { useLocation } from 'react-router-dom';
import { getParams } from '@cooltra/utils';

import { useNavigateWithQueryParams } from '~/hooks';

import {
  initialReservationsFiltersFormValues,
  ReservationsFiltersFormValues,
} from './reservations-filters-form';

const { system, state, minDate, maxDate } =
  initialReservationsFiltersFormValues;

export const useReservationsFiltersFromUrl = () => {
  const { search } = useLocation();

  const params = getParams<keyof ReservationsFiltersFormValues>(search);

  return {
    system: params.array('system').length ? params.array('system') : system,
    state: params.array('state').length ? params.array('state') : state,
    minDate: params.string('minDate') || minDate,
    maxDate: params.string('maxDate') || maxDate,
  } as ReservationsFiltersFormValues;
};

export const ReservationsFiltersForm = ({ children }: PropsWithChildren) => {
  const navigateWithQueryParams = useNavigateWithQueryParams();

  const values = useReservationsFiltersFromUrl();

  const onSubmit = async (values: ReservationsFiltersFormValues) =>
    navigateWithQueryParams(values);

  return (
    <FormProvider initialValues={values} onSubmit={onSubmit}>
      {children}
    </FormProvider>
  );
};
