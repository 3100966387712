import { defineMessages } from 'react-intl';

export default defineMessages({
  chargeAttempts: {
    defaultMessage: 'Charge attempts',
  },
  createdAt: {
    defaultMessage: 'Created at',
  },
  state: {
    defaultMessage: 'State',
  },
  failureReason: {
    defaultMessage: 'Details',
  },
});
