import { CheckOutFailureMarker } from './CheckOutFailureMarker';
import { UserDropOffMarker } from './UserDropOffMarker';
import { UserPickUpMarker } from './UserPickUpMarker';
import { VehicleCurrentPositionMarker } from './VehicleCurrentPositionMarker/VehicleCurrentPositionMarker';

export const Markers = {
  CheckOutFailureMarker,
  UserDropOffMarker,
  UserPickUpMarker,
  VehicleCurrentPositionMarker,
};
