import { Button, Modal } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useCallbackPrompt } from '@cooltra/navigation';

import messages from './messages';

export type RouterPromptProps = {
  isBlocking: boolean;
  onConfirm?: () => Promise<void>;
  isConfirming?: boolean;
};

export const RouterPrompt = ({
  isBlocking,
  onConfirm,
  isConfirming = false,
}: RouterPromptProps) => {
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isBlocking);

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm().then(confirmNavigation).catch(cancelNavigation);
    } else {
      confirmNavigation();
    }
  };

  return (
    <Modal
      className="max-w-md"
      isOpen={showPrompt}
      onRequestClose={cancelNavigation}
    >
      <div className="text-center pb-8 px-8" data-testid="ROUTER_PROMPT">
        <h6 className="text-neutral-700 text-lg font-semibold mb-2">
          <FormattedMessage {...messages.title} />
        </h6>
        <p className="text-neutral-600 text-sm mb-8">
          <FormattedMessage {...messages.content} />
        </p>
        <div className="flex justify-center gap-4">
          <Button variant="danger" onClick={confirmNavigation}>
            <FormattedMessage {...messages.cancelBtn} />
          </Button>
          <Button
            loading={isConfirming}
            emphasis="high"
            onClick={handleConfirm}
          >
            <FormattedMessage {...messages.confirmBtn} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
