import { IntlShape } from 'react-intl';

import {
  createSetError,
  validateDate,
  validateRequired,
  validateTime,
} from '~/validation';

import { PassFormErrors, PassFormValues } from './pass-form';
import messages from './messages';

type SetError = (
  errorKey: keyof PassFormValues,
  message: string | undefined
) => void;

export const validateBaseFields = (
  existingCodes: string[],
  values: PassFormValues,
  intl: IntlShape,
  setError: SetError
) => {
  const codeErrorMessage = validateRequired(intl, values.code);
  setError('code', codeErrorMessage);

  if (existingCodes.includes(values.code)) {
    setError('code', intl.formatMessage(messages.duplicatedCode));
  }

  const priceErrorMessage = validateRequired(intl, values.price);
  setError('price', priceErrorMessage);
};

export const validateUsage = (
  values: PassFormValues,
  intl: IntlShape,
  setError: SetError
) => {
  const validFromDateErrorMessage = validateDate(intl, values.validFromDate);
  setError('validFromDate', validFromDateErrorMessage);

  const validFromTimeErrorMessage = validateTime(intl, values.validFromTime);
  setError('validFromTime', validFromTimeErrorMessage);

  if (!values.passDoesntExpire) {
    const validUntilDateErrorMessage = validateDate(
      intl,
      values.validUntilDate
    );
    setError('validUntilDate', validUntilDateErrorMessage);
    const validUntilTimeErrorMessage = validateTime(
      intl,
      values.validUntilTime
    );
    setError('validUntilTime', validUntilTimeErrorMessage);
  }

  const benefitExpiresAfterHoursErrorMessage = validateRequired(
    intl,
    values.benefitExpiresAfterHours
  );
  setError('benefitExpiresAfterHours', benefitExpiresAfterHoursErrorMessage);
};

const validateTitles = (
  values: PassFormValues,
  intl: IntlShape,
  setError: SetError
) => {
  const englishTitleErrorMessage = validateRequired(
    intl,
    values['titleTranslations.en']
  );
  setError('titleTranslations.en', englishTitleErrorMessage);
};

export const validatePassForm =
  (intl: IntlShape, existingCodes: string[]) => (values: PassFormValues) => {
    const errors: PassFormErrors = {};
    const setError = createSetError(errors);

    validateBaseFields(existingCodes, values, intl, setError);
    validateUsage(values, intl, setError);
    validateTitles(values, intl, setError);

    return errors;
  };
