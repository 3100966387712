import Modal from 'react-modal';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NotificationsProvider } from '@cooltra/notifications';
import { PreferredLanguageProvider } from '@cooltra/preferred-language';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { configureAxios } from '@cooltra/axios';
import axios from 'axios';

import { getEnvVariable } from '~/utils/environment';
import { defaultLanguage, supportedLanguages } from '~/lang/constants';
import { isTestingEnv } from '~/utils/e2e';

import { MainWithAuthentication } from '../Main/Main';

import { Auth0Provider } from './Auth0Provider';
import { GoogleAnalytics } from './GoogleAnalytics';

import '../../index.css';

export const axiosInstance = configureAxios(getEnvVariable('API'));

Modal.setAppElement('#app');

const importLanguage = (language: string) =>
  axios.get(`/lang/data/${language}.json`).then(({ data }) => data);

const isTest = isTestingEnv();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: isTest ? false : 3,
      refetchOnWindowFocus: !isTest,
    },
  },
});

const App = () => (
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      {!isTest && <ReactQueryDevtools buttonPosition="bottom-left" />}
      <Auth0Provider>
        {getEnvVariable('ENVIRONMENT') === 'production' && <GoogleAnalytics />}
        <PreferredLanguageProvider
          defaultLanguage={defaultLanguage}
          supportedLanguages={isTest ? ['en'] : supportedLanguages}
          localStorageKey="zeus-web-language"
          fetchTranslations={importLanguage}
        >
          <NotificationsProvider dismissAfter={isTest ? 500 : 5000}>
            <Routes>
              <Route path="*" element={<MainWithAuthentication />} />
            </Routes>
          </NotificationsProvider>
        </PreferredLanguageProvider>
      </Auth0Provider>
    </QueryClientProvider>
  </BrowserRouter>
);

export default App;
