import { useToggle } from '@cooltra/hooks';
import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { AddCreditModal } from '~/common';

import messages from './messages';

export type AddCreditProps = {
  userId: string;
};

export const AddCredit = ({ userId }: AddCreditProps) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  return (
    <>
      <AddCreditModal
        isOpen={isOpen}
        onClose={toggleOff}
        userId={userId}
        title={''}
      />
      <Button emphasis="high" size="md" onClick={toggleOn}>
        <FormattedMessage {...messages.addCredit} />
      </Button>
    </>
  );
};
