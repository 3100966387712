import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useField } from '@cooltra/form';

import messages from './messages';

export const SidewalkParked = () => {
  const { value, setValue } = useField<string>('sidewalkParked');

  const updateValue = () => {
    if (value) {
      setValue('');
    } else {
      setValue('true');
    }
  };

  return (
    <Button onClick={updateValue} emphasis={value ? 'high' : 'medium'}>
      <FormattedMessage {...messages.sidewalkParked} />
    </Button>
  );
};
