import { BadgePlaceholder, Card, Placeholder } from '@cooltra/ui';

import { OpenUserProblemReportLayout } from './OpenUserProblemReportLayout';

export const OpenUserProblemReportCardLoading = () => (
  <Card className="h-28 py-0.5 flex">
    <OpenUserProblemReportLayout
      problem={
        <div>
          <BadgePlaceholder className="w-20 mb-4" />
          <Placeholder className="w-28 h-2" />
        </div>
      }
      context={
        <div>
          <Placeholder className="h-2 w-14 mb-3" />
          <Placeholder className="h-2 w-20" />
        </div>
      }
      created={
        <div>
          <Placeholder className="h-2 w-20 mb-3" />
          <Placeholder className="h-2 w-32" />
        </div>
      }
      vehicle={
        <div className="flex flex-col items-end">
          <Placeholder className="h-2 w-32 mb-3" />
          <Placeholder className="h-2 w-14" />
        </div>
      }
    />
  </Card>
);
