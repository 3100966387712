import { RentalColumnLabel } from '~/common';

import { VehicleRentalRowLayout } from './VehicleRentalRowLayout';

export const VehicleRentalsListHeader = () => (
  <VehicleRentalRowLayout
    pickUp={<RentalColumnLabel name="pickUp" />}
    dropOff={<RentalColumnLabel name="dropOff" />}
    duration={<RentalColumnLabel name="duration" />}
    user={<RentalColumnLabel name="user" />}
    system={<RentalColumnLabel name="system" />}
    incidents={<RentalColumnLabel name="incidents" />}
    state={<RentalColumnLabel name="state" />}
  />
);
