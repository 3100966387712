import { HTMLAttributes } from 'react';
import { classNames } from '@cooltra/utils';

type RentalBillableDurationProps = HTMLAttributes<HTMLSpanElement> & {
  billableDurationInSeconds: number;
};

export const RentalBillableDuration = ({
  billableDurationInSeconds,
  className,
}: RentalBillableDurationProps) => {
  const hours = Math.floor(billableDurationInSeconds / (60 * 60));
  const minutes = Math.floor(billableDurationInSeconds / 60) - hours * 60;
  const seconds = billableDurationInSeconds % 60;

  const padWith0 = (num: number) => num.toString().padStart(2, '0');

  return (
    <span className={classNames('block tabular-nums', className)}>
      {`${padWith0(hours)}:${padWith0(minutes)}:${padWith0(seconds)}`}
    </span>
  );
};
