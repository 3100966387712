import { today } from '~/utils/date';

export type CooltraForEmployeesFormValues = {
  title: string;
  subscription: string;
  companyName: string;
  companyFiscalName: string | '';
  codePrefix: string;
  numberOfCodes: number | '';
  codes: string[];
  redeemableFromDate: string;
  redeemableFromTime: string;
  redeemableUntilDate: string;
  redeemableUntilTime: string;
  consumableUntilDate: string;
  consumableUntilTime: string;
  redeemableDoesNotExpire: boolean;
  consumableDoesNotExpire: boolean;
  editMode: boolean;
};

export const cooltraForEmployeesInitialFormValues: CooltraForEmployeesFormValues =
  {
    title: '',
    subscription: '',
    companyName: '',
    companyFiscalName: '',
    codePrefix: '',
    numberOfCodes: '',
    codes: [],
    redeemableFromDate: today,
    redeemableFromTime: '00:00',
    redeemableUntilDate: '',
    redeemableUntilTime: '23:59',
    consumableUntilDate: '',
    consumableUntilTime: '23:59',
    redeemableDoesNotExpire: false,
    consumableDoesNotExpire: false,
    editMode: false,
  };
