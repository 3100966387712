import { Divider } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { RouterSidebarNavLink } from '@cooltra/navigation';

import { SidebarLinkContent, SidebarHeading } from '~/common';

import messages from './messages';

export const Sidebar = () => (
  <div className="w-72 pt-14 pb-20" data-testid="PRICE_LISTS_SIDEBAR">
    <div className="px-8 mt-14 mb-7">
      <SidebarHeading className="mb-4">
        <FormattedMessage {...messages.priceLists} />
      </SidebarHeading>
    </div>
    <ul>
      <li>
        <Divider />
        <RouterSidebarNavLink to="web">
          <SidebarLinkContent>
            <FormattedMessage {...messages.web} />
          </SidebarLinkContent>
        </RouterSidebarNavLink>
      </li>
      <li>
        <Divider />
        <RouterSidebarNavLink to="pos">
          <SidebarLinkContent>
            <FormattedMessage {...messages.pos} />
          </SidebarLinkContent>
        </RouterSidebarNavLink>
      </li>
      <li>
        <Divider />
        <RouterSidebarNavLink to="deposits">
          <SidebarLinkContent>
            <FormattedMessage {...messages.deposits} />
          </SidebarLinkContent>
        </RouterSidebarNavLink>
        <Divider />
      </li>
    </ul>
  </div>
);
