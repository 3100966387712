import { FormattedMessage } from 'react-intl';
import { ContractAvailablePenalty } from '@cooltra/station-based-api';
import { Icon, Tooltip } from '@cooltra/ui';
import { MdInfoOutline } from 'react-icons/md';

import { Amount } from '~/common';

import messages from './messages';

export type PenaltyPriceVariationTooltipProps = {
  penalty: ContractAvailablePenalty;
};

export const PenaltyPriceVariationTooltip = ({
  penalty,
}: PenaltyPriceVariationTooltipProps) => (
  <Tooltip
    button={
      <button>
        <Icon className="text-xl text-neutral-500">
          <MdInfoOutline />
        </Icon>
      </button>
    }
    content={
      <div className="p-4 flex flex-col gap-0.5 max-w-60 text-center">
        <p>
          <FormattedMessage
            {...messages.penaltyPriceMayVary}
            values={{
              minAmount: (
                <Amount className="font-semibold" {...penalty.price.min} />
              ),
              maxAmount: (
                <Amount className="font-semibold" {...penalty.price.max} />
              ),
            }}
          />
        </p>
        <p>
          <FormattedMessage {...messages.ifNeededAdjustOnPaymentStep} />
        </p>
      </div>
    }
  />
);
