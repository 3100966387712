import { TimelineItem } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { UserState } from '@cooltra/api';
import { useOperatorsQuery } from '@cooltra/auth-api';
import { classNames } from '@cooltra/utils';

import { UnitStateHeader } from '../UnitStateHeader';

import messages from './messages';

export type UnitStateProps = {
  state: UserState;
  isLast: boolean;
};

export const UnitState = ({ state, isLast }: UnitStateProps) => {
  const { data: operators = [] } = useOperatorsQuery();

  const operatorName = operators.find(
    ({ externalId }) => externalId === state.operatorId
  )?.name;

  const hasToDisplayReasonsSection = state.reasons.length > 0;
  const hasToDisplayCommentSection = state.comment;
  const hasToDisplayTextSection =
    hasToDisplayReasonsSection || hasToDisplayCommentSection;

  return (
    <TimelineItem
      key={state.createdAt}
      header={<UnitStateHeader createdAt={state.createdAt} type={state.type} />}
      isLast={isLast}
      timelineClassName="translate-y-3"
    >
      {hasToDisplayTextSection && (
        <div className="pt-4">
          {hasToDisplayReasonsSection && (
            <p className="text-sm pt-1 font-semibold text-neutral-500">
              {state.reasons.join(', ')}
            </p>
          )}
          {hasToDisplayCommentSection && (
            <p className="text-xs pt-1 italic text-neutral-500">
              {state.comment}
            </p>
          )}
        </div>
      )}
      <p
        className={classNames(
          'text-sm',
          'pt-4',
          'text-neutral-500',
          !isLast && 'pb-8'
        )}
      >
        {operatorName && (
          <FormattedMessage
            {...messages.byOperator}
            values={{
              operator: operatorName,
            }}
          />
        )}
      </p>
    </TimelineItem>
  );
};
