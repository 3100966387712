import { useState } from 'react';
import {
  SubscriptionCharge,
  useHomeSystemSubscriptionsChargesMutation,
  UpdateHomeSystemSubscriptionsChargesPayload,
} from '@cooltra/api';
import { Button, Card } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';

import { useNotification } from '~/hooks';
import { CustomIcon } from '~/common';
import { cities } from '~/common/System/messages';

import { SubscriptionChargeRowLayout } from './SubscriptionChargeRowLayout';
import messages from './messages';

type SubscriptionChargeCardProps = {
  subscriptionCharge: SubscriptionCharge;
  isEditable: boolean;
};

export const SubscriptionChargeCard = ({
  subscriptionCharge: {
    system,
    charges: {
      scooter: { riding },
    },
  },
  isEditable,
}: SubscriptionChargeCardProps) => {
  const ridingValue = riding.value.toFixed(2);
  const { mutateAsync, isPending } =
    useHomeSystemSubscriptionsChargesMutation(system);
  const [newRidingValue, setNewRidingValue] = useState(Number(ridingValue));
  const queryClient = useQueryClient();
  const { addErrorNotification } = useNotification();

  const increaseRidingValue = () => {
    setNewRidingValue(newRidingValue + 0.01);
  };
  const decreaseRidingValue = () => {
    if (newRidingValue <= 0) {
      return;
    }

    setNewRidingValue(newRidingValue - 0.01);
  };
  const isSaveButtonEnable =
    isEditable && Number(ridingValue) != Number(newRidingValue.toFixed(2));

  const saveRidingPrice = () => {
    const payload: UpdateHomeSystemSubscriptionsChargesPayload = {
      charges: {
        scooter: {
          riding: {
            value: Number(newRidingValue.toFixed(2)),
            currency: 'EUR',
          },
        },
      },
    };

    mutateAsync(payload)
      .then(async () => {
        await queryClient.invalidateQueries({
          queryKey: ['subscription-charges', 'basic'],
        });
      })
      .catch(() => addErrorNotification());
  };

  return (
    <Card>
      <SubscriptionChargeRowLayout
        className="min-h-28 py-4"
        system={
          <span className="text-lg font-semibold text-neutral-700">
            <FormattedMessage {...cities[system]} />
          </span>
        }
        price={
          <div className="flex items-center gap-4">
            <CustomIcon.Motorcycle className="w-7 h-7 text-neutral-300" />
            <div className="text-lg">
              <span className="text-neutral-700 font-semibold">
                {ridingValue}
              </span>
              <span className="text-neutral-300"> € </span>
            </div>
          </div>
        }
        inputPrice={
          <div className="flex items-center">
            <Button
              className="rounded-none rounded-l-lg px-3"
              onClick={decreaseRidingValue}
              disabled={!isEditable || isPending}
            >
              -
            </Button>
            <div className="w-24 h-11 flex items-center justify-center gap-6 border border-neutral-100 text-neutral-300">
              <span> {newRidingValue.toFixed(2).replace('-0', '0')} </span>
              <span> € </span>
            </div>
            <Button
              className="rounded-none rounded-r-lg px-3"
              onClick={increaseRidingValue}
              disabled={!isEditable || isPending}
            >
              +
            </Button>
          </div>
        }
        save={
          <Button
            variant="primary"
            emphasis="high"
            size="sm"
            onClick={saveRidingPrice}
            disabled={!isSaveButtonEnable || isPending}
            loading={isPending}
          >
            <FormattedMessage {...messages.save} />
          </Button>
        }
      />
    </Card>
  );
};
