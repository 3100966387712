import { RouterBaseNavLink, RouterBaseNavLinkProps } from '@cooltra/navigation';
import { classNames } from '@cooltra/utils';

export const NavigationLink = (props: RouterBaseNavLinkProps) => (
  <RouterBaseNavLink
    className={({ isActive }) =>
      classNames(
        'w-full aspect-square rounded-md flex items-center text-2xl',
        isActive ? 'text-neutral-50 bg-primary-600' : 'text-neutral-300'
      )
    }
    {...props}
  />
);
