import { defineMessages } from 'react-intl';

export default defineMessages({
  waitingStripeWithhold: {
    defaultMessage: 'Pending charge (awaiting for payment)',
  },
  title: {
    defaultMessage: 'Charges at store',
  },
  createdAt: {
    defaultMessage: 'Created on ',
  },
  showStripeQR: {
    defaultMessage: 'Show QR',
  },
  verify: {
    defaultMessage: 'Verify payment',
  },
});
