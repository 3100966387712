import { FormattedMessage } from 'react-intl';
import { Reservation } from '@cooltra/api';
import { Card } from '@cooltra/ui';
import { RouterLink } from '@cooltra/navigation';
import { shortenId } from '@cooltra/utils';

import { cities } from '~/common/System/messages';

import { VehicleLink } from '../../Vehicle';
import { MultilineDateTime } from '../../DateTime';
import {
  FailedCheckIn,
  ReservationStateBadge,
  ReservationProblemReportsBadge,
  ReservationSystem,
} from '../../Reservation';
import { UserReservationRowLayout } from '../UserReservationRowLayout';

import messages from './messages';

export type UserReservationCardProps = {
  reservation: Reservation;
  userId: string;
};

export const UserReservationCard = ({
  reservation: {
    rentalId,
    createdAt,
    expiresAt,
    lastCheckinFailure,
    state,
    system,
    vehicle,
    problemReportIds,
  },
  userId,
}: UserReservationCardProps) => (
  <Card className="relative py-4 flex items-center">
    {state === 'ACTIVE' && (
      <div className="absolute bg-success-50 opacity-40 inset-0" />
    )}
    <UserReservationRowLayout
      createdAt={<MultilineDateTime date={createdAt} />}
      spacer={<div className="w-full h-px bg-neutral-200" />}
      expiresAt={<MultilineDateTime date={expiresAt} />}
      vehicle={<VehicleLink vehicle={vehicle} />}
      system={
        <ReservationSystem>
          <FormattedMessage {...cities[system]} />
        </ReservationSystem>
      }
      checkIn={
        <div className="text-center">
          {rentalId ? (
            <div className="text-sm">
              <span>
                <FormattedMessage {...messages.rental} />
              </span>
              <RouterLink to={`/rentals/${rentalId}`} className="font-medium">
                #{shortenId(rentalId)}
              </RouterLink>
            </div>
          ) : (
            lastCheckinFailure && <FailedCheckIn error={lastCheckinFailure} />
          )}
        </div>
      }
      problemReports={
        <ReservationProblemReportsBadge
          problemReportIds={problemReportIds}
          userId={userId}
        />
      }
      state={<ReservationStateBadge state={state} />}
    />
  </Card>
);
