import { Form, FormHelpers, FormProvider } from '@cooltra/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card } from '@cooltra/ui';
import {
  Contract,
  usePatchContractSecondDriverMutation,
} from '@cooltra/station-based-api';

import {
  DriverLicenseForm,
  FormQRCode,
  FormSaveButton,
  UnsavedChangesPrompt,
  UserFields,
} from '~/common';
import { useNotification } from '~/hooks';

import { Photo } from '../Photo/Photo';
import { DriverLicenseNumber } from '../DriverFields/DriverLicenseNumber';

import messages from './messages';
import {
  SecondDriverDetailsFormValues,
  formatSecondDriverDetailsPayload,
  getSecondDriverFormInitialValues,
  validateSecondDriverDetailsForm,
} from './contract-second-driver-details-form';

export type ContractSecondDriverDetailsFormProps = {
  contract: Contract;
  disabled: boolean;
};

export const ContractSecondDriverDetailsForm = ({
  contract,
  disabled,
}: ContractSecondDriverDetailsFormProps) => {
  const intl = useIntl();

  const { contractId, vehicle } = contract;

  const isEbike = vehicle?.category === 'EBIKE';

  const { mutateAsync, isSuccess } =
    usePatchContractSecondDriverMutation(contractId);

  const { addErrorNotification } = useNotification();

  const handleSubmit = (
    values: SecondDriverDetailsFormValues,
    { resetForm }: FormHelpers<SecondDriverDetailsFormValues>
  ) =>
    mutateAsync(formatSecondDriverDetailsPayload(values))
      .then(() => resetForm(values))
      .catch(() => addErrorNotification());

  return (
    <div>
      <FormProvider
        initialValues={getSecondDriverFormInitialValues(contract)}
        onSubmit={handleSubmit}
        validate={validateSecondDriverDetailsForm(intl)}
        disabled={disabled}
      >
        <UnsavedChangesPrompt />
        <Form>
          <div className="mx-auto max-w-4xl pb-10">
            <h2 className="text-xl text-neutral-700 mb-4">
              <FormattedMessage {...messages.personalInformation} />
            </h2>
            <Card className="pt-8 pb-14 px-10 mb-28 grid grid-cols-2 gap-x-11 gap-y-10">
              <UserFields.FirstName />
              <UserFields.LastName />
            </Card>
            {!isEbike && (
              <>
                <h2 className="text-xl text-neutral-700 mb-6">
                  <FormattedMessage {...messages.driverLicense} />
                </h2>
                <Card
                  data-testid="DRIVER_LICENSE_FORM_FIELDS"
                  className="px-10 pt-10 pb-8 flex gap-14 mb-16"
                >
                  <div className="flex flex-col gap-6 w-72 shrink-0">
                    <Photo name="driverLicenseFront" side="front" />
                    <Photo name="driverLicenseBack" side="back" />
                  </div>
                  <div>
                    <div className="flex flex-col gap-y-10">
                      <div className="w-64 flex flex-col gap-y-10">
                        <DriverLicenseNumber />
                        <DriverLicenseForm.ExpirationDate optional={true} />
                        <DriverLicenseForm.DriverLicenseCountry />
                      </div>
                    </div>
                  </div>
                </Card>
              </>
            )}
          </div>
          {!disabled && (
            <FormSaveButton
              className="btn-shadow fixed bottom-8 right-28 -mr-2"
              isSaved={isSuccess}
            />
          )}
        </Form>
        <FormQRCode />
      </FormProvider>
    </div>
  );
};
