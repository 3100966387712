import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '@cooltra/ui';
import {
  Contract,
  ContractPayment,
  useContractAvailableAccessoriesQuery,
  useContractAvailableInsurancesQuery,
} from '@cooltra/station-based-api';

import { ContractPendingSignAlert, ErrorPage } from '~/common';

import { InsuranceTypeCard } from './InsuranceTypeCard/InsuranceTypeCard';
import { AccessoryTypeCard } from './AccessoryTypeCard/AccessoryTypeCard';
import messages from './messages';

export type ContractExtrasProps = {
  contract: Contract;
  payments: ContractPayment[];
};

export const ContractExtras = ({ contract, payments }: ContractExtrasProps) => {
  const { contractId = '' } = useParams();

  const {
    data: insurances = [],
    isLoading: isInsurancesLoading,
    isError: isInsurancesError,
  } = useContractAvailableInsurancesQuery(contractId, {
    enabled: !!contractId,
  });

  const {
    data: accessories = [],
    isLoading: isAccessoriesLoading,
    isError: isAccessoriesError,
  } = useContractAvailableAccessoriesQuery(contractId, {
    enabled: !!contractId,
  });

  if (isInsurancesLoading || isAccessoriesLoading) {
    return (
      <div className="w-full text-center mt-8">
        <Spinner size="lg" />
      </div>
    );
  }

  if (isInsurancesError || isAccessoriesError) {
    return <ErrorPage />;
  }

  return (
    <div>
      <div className="mx-auto max-w-4xl pt-4 pb-10">
        <ContractPendingSignAlert />
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl">
            <FormattedMessage {...messages.coverage} />
          </h2>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {insurances
            .sort(({ name }) => (name === 'BASIC' ? -1 : 1))
            .map(({ insuranceId, name, price }) => (
              <InsuranceTypeCard
                contract={contract}
                payments={payments}
                key={insuranceId}
                insuranceId={insuranceId}
                name={name}
                price={price}
                isAdded={contract?.insurance === name}
              />
            ))}
        </div>
        <div className="mt-14 flex items-center justify-between mb-6">
          <h2 className="text-xl">
            <FormattedMessage {...messages.accessories} />
          </h2>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {accessories.map(({ accessoryId, name, price, limit }) => {
            const contractAccessory = contract?.accessories?.find(
              ({ id }) => id === accessoryId
            );

            return (
              <AccessoryTypeCard
                withInfoModal={
                  name === 'SCOOTER_DELIVERY' || name === 'SCOOTER_PICK_UP'
                }
                contract={contract}
                payments={payments}
                quantity={contractAccessory?.quantity || 0}
                info={contractAccessory?.info}
                key={accessoryId}
                accessoryId={accessoryId}
                name={name}
                price={price}
                limit={limit}
              />
            );
          })}
          {contract?.accessories?.map(({ id, name, quantity, info }) => {
            if (accessories.some(({ accessoryId }) => accessoryId === id)) {
              return null;
            }

            return (
              <AccessoryTypeCard
                withInfoModal={
                  name === 'SCOOTER_DELIVERY' || name === 'SCOOTER_PICK_UP'
                }
                contract={contract}
                payments={payments}
                quantity={quantity || 0}
                info={info}
                key={id}
                accessoryId={id}
                name={name}
                price={{
                  daily: { value: 0, currency: 'EUR' },
                  total: { value: 0, currency: 'EUR' },
                }}
                limit={quantity}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
