import { defineMessages } from 'react-intl';

export default defineMessages({
  noOpenTasks: {
    defaultMessage: 'There are no open tasks for this vehicle',
  },
  error: {
    defaultMessage: 'There has been a problem loading open tasks',
  },
});
