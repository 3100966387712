import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type ListTotalProps = HTMLAttributes<HTMLSpanElement> & {
  loading: boolean;
};

export const ListTotal = ({
  children,
  loading,
  className,
  ...rest
}: ListTotalProps) => (
  <span
    className={classNames(
      'block text-base font-semibold text-neutral-600',
      className
    )}
    {...rest}
  >
    {loading ? <FormattedMessage {...messages.loading} /> : children}
  </span>
);
