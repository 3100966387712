import { Vehicle } from '@cooltra/api';
import { useOperatorsQuery } from '@cooltra/auth-api';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type WorkUnitByProps = Pick<Vehicle, 'workUnitBy'>;

export const WorkUnitBy = ({ workUnitBy }: WorkUnitByProps) => {
  const { data: operators } = useOperatorsQuery();
  const operatorName = operators?.find(
    ({ externalId }) => externalId === workUnitBy
  )?.name;

  return (
    <div className="w-full bg-neutral-100 text-neutral-700 text-xs text-center rounded-t-xl p-4">
      <FormattedMessage
        {...messages.workInProgress}
        values={{
          operatorName: <span className="font-semibold"> {operatorName} </span>,
        }}
      />
    </div>
  );
};
