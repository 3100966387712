import { FormMultiSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAllSystemOptions } from '~/common';

import messages from './messages';

export const TargetHomeSystems = () => {
  const { formatMessage } = useIntl();
  const options = useAllSystemOptions().filter(
    (homeSystem) => homeSystem.value !== 'Berlin'
  );

  return (
    <FormMultiSelectField
      name="targetHomeSystems"
      id="target-home-systems"
      className="w-96"
      placeholder={formatMessage(messages.allHomeSystems)}
      options={options}
      label={
        <InputLabel htmlFor="target-home-systems">
          <FormattedMessage {...messages.homeSystems} />
        </InputLabel>
      }
    />
  );
};
