import { InvoiceState as ApiInvoiceState } from '@cooltra/api';
import { Badge, BadgeProps, BadgeVariant } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

const stateBadgeVariants: Record<ApiInvoiceState, BadgeVariant> = {
  OPEN: 'warning',
  PROCESSING: 'warning',
  PAID: 'success',
  FAILED: 'danger',
};

export type InvoiceStateProps = Omit<
  BadgeProps,
  'children' | 'variant' | 'emphasis'
> & {
  state: ApiInvoiceState;
};

export const InvoiceState = ({ state, ...rest }: InvoiceStateProps) => (
  <Badge variant={stateBadgeVariants[state]} {...rest}>
    <FormattedMessage {...messages[state]} />
  </Badge>
);
