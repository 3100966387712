import { defineMessages } from 'react-intl';

export default defineMessages({
  closeContract: {
    defaultMessage: 'Close contract',
  },
  areYouSureYouWantToCloseThisContract: {
    defaultMessage: 'Are you sure you want to close this contract?',
  },
  depositPendingRelease: {
    defaultMessage: 'The deposit is pending release',
  },
  success: {
    defaultMessage: 'The contract was closed successfully',
  },
});
