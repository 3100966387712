import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type TaskRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  task: ReactNode;
  assignee: ReactNode;
  updated: ReactNode;
  context: ReactNode;
};

export const TaskRowLayout = ({
  task,
  assignee,
  updated,
  context,
  className,
  ...rest
}: TaskRowLayoutProps) => (
  <div className={classNames('flex', 'h-full', 'w-full', className)} {...rest}>
    <div className="flex items-center w-full px-8 gap-4">
      <div className="flex-1">{task}</div>
      <div className="w-40 shrink-0">{assignee}</div>
      <div className="w-48 shrink-0">{updated}</div>
      <div className="w-20 shrink-0">{context}</div>
    </div>
  </div>
);
