import { InvoiceListItem } from '@cooltra/api';
import { RouterCardLink } from '@cooltra/navigation';

import {
  Amount,
  DateTime,
  InvoiceLegalEntity,
  InvoicePdf,
  InvoiceState,
  InvoiceType,
} from '~/common';

import { InvoiceRowLayout } from '../InvoiceRowLayout';

export type InvoiceCardProps = {
  invoice: InvoiceListItem;
};

export const InvoiceCard = ({
  invoice: {
    invoiceId,
    issuedAt,
    type,
    invoiceNumber,
    legalEntity,
    total,
    state,
  },
}: InvoiceCardProps) => (
  <RouterCardLink
    to={`/invoices/${invoiceId}`}
    className="py-5 text-sm text-neutral-800"
    isMultiAction
  >
    <InvoiceRowLayout
      issuedAt={<DateTime date={issuedAt} />}
      type={<InvoiceType type={type} />}
      invoiceNumber={<span>{invoiceNumber}</span>}
      legalEntity={<InvoiceLegalEntity legalEntity={legalEntity} />}
      total={<Amount {...total} />}
      state={<InvoiceState state={state} />}
      invoicePdf={<InvoicePdf invoiceId={invoiceId} type={type} />}
    />
  </RouterCardLink>
);
