import { defineMessages } from 'react-intl';

export default defineMessages({
  deposit: {
    defaultMessage: 'Deposit',
  },
  successNotification: {
    defaultMessage: 'Deposit retained successfully',
  },
  stripeDepositFailed: {
    defaultMessage:
      'Unable to retain deposit using Stripe card. Please try another payment method.',
  },
});
