import { defineMessages } from 'react-intl';

export default defineMessages({
  createdAt: {
    defaultMessage: 'Created at',
  },
  expiresAt: {
    defaultMessage: 'Expires at',
  },
  vehicle: {
    defaultMessage: 'Vehicle',
  },
  system: {
    defaultMessage: 'System',
  },
  checkIn: {
    defaultMessage: 'Check in',
  },
  problemReports: {
    defaultMessage: 'Problem reports',
  },
  state: {
    defaultMessage: 'Status',
  },
  user: {
    defaultMessage: 'User',
  },
});
