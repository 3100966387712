import { System } from '@cooltra/auth-api';
import { forwardRef, HTMLAttributes } from 'react';

import { useSystemLabel } from '../system-label';

import madrid from './assets/madrid.png';
import milan from './assets/milan.png';
import paris from './assets/paris.png';
import rome from './assets/rome.png';
import turin from './assets/turin.png';
import fallback from './assets/default.png';
import lisbon from './assets/lisbon.png';
import malaga from './assets/malaga.png';
import mallorca from './assets/mallorca.png';
import seville from './assets/seville.png';
import valencia from './assets/valencia.png';
import barcelona from './assets/barcelona.png';
import amsterdam from './assets/amsterdam.png';
import breda from './assets/breda.png';
import brussels from './assets/brussels.png';
import den_bosch from './assets/den_bosch.png';
import the_hague from './assets/the_hague.png';
import enschede from './assets/enschede.png';
import groningen from './assets/groningen.png';
import haarlem from './assets/haarlem.png';
import nijmegen from './assets/nijmegen.png';
import rotterdam from './assets/rotterdam.png';
import tilburg from './assets/tilburg.png';
import zwolle from './assets/zwolle.png';
import hilversum from './assets/hilversum.png';
import antwerp from './assets/antwerp.png';
import arnhem from './assets/arnhem.png';

const systemPhotos: { [key in System]: string } = {
  Berlin: fallback,
  Lisbon: lisbon,
  Madrid: madrid,
  Malaga: malaga,
  Mallorca: mallorca,
  Milan: milan,
  Munich: fallback,
  Paris: paris,
  Rome: rome,
  Seville: seville,
  Turin: turin,
  Valencia: valencia,
  Zurich: fallback,
  Barcelona: barcelona,
  Amsterdam: amsterdam,
  Breda: breda,
  Brussels: brussels,
  Den_bosch: den_bosch,
  The_hague: the_hague,
  Enschede: enschede,
  Groningen: groningen,
  Haarlem: haarlem,
  Nijmegen: nijmegen,
  Rotterdam: rotterdam,
  Tilburg: tilburg,
  Zwolle: zwolle,
  Hilversum: hilversum,
  Antwerp: antwerp,
  Arnhem: arnhem,
};

export type HomeSystemImageProps = Omit<
  HTMLAttributes<HTMLImageElement>,
  'src' | 'alt'
> & { system: System };

export const SystemImage = forwardRef<HTMLImageElement, HomeSystemImageProps>(
  ({ system, ...rest }, ref) => {
    const { getSystemLabel } = useSystemLabel();

    return (
      <img
        ref={ref}
        alt={getSystemLabel(system)}
        src={systemPhotos[system]}
        {...rest}
      />
    );
  }
);
