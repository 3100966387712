import { IntlShape } from 'react-intl';

import { isPast, today } from '~/utils/date';
import { createSetError, validateDate, validateRequired } from '~/validation';

import messages from './messages';

export type SubscriptionFormValues = {
  subscriptionId: string;
  validFrom: string;
};

export const subscriptionInitialFormValues: SubscriptionFormValues = {
  subscriptionId: '',
  validFrom: today,
};

type SubscriptionFormFieldName = keyof SubscriptionFormValues;

type SubscriptionFormErrors = Partial<
  Record<SubscriptionFormFieldName, string | undefined>
>;

export const validateSubscriptionForm =
  (intl: IntlShape) => (values: SubscriptionFormValues) => {
    const errors: SubscriptionFormErrors = {};
    const setError = createSetError(errors);

    const idErrorMessage = validateRequired(intl, values.subscriptionId);
    setError('subscriptionId', idErrorMessage);

    setError('validFrom', validateRequired(intl, values.validFrom));

    if (values.validFrom !== '') {
      setError('validFrom', validateDate(intl, values.validFrom));

      if (values.validFrom.length === 10) {
        setError(
          'validFrom',
          isPast(values.validFrom)
            ? intl.formatMessage(messages.dateInThePastError)
            : undefined
        );
      }
    }

    return errors;
  };
