import { defineMessages } from 'react-intl';

export default defineMessages({
  takePicture: {
    defaultMessage: 'Take picture',
  },
  changePicture: {
    defaultMessage: 'Change picture',
  },
  deletePicture: {
    defaultMessage: 'Delete',
  },
  uploadPhoto: {
    defaultMessage: 'Upload photo',
  },
  uploadingError: {
    defaultMessage: "Something went wrong! We couldn't upload the image",
  },
  saveParkingPhotoConfirmation: {
    defaultMessage: 'Are you sure you want to save this parking photo?',
  },
  disclaimer: {
    defaultMessage:
      'If you confirm, the geolocation will be updated and "Vehicle in transit" will be deactivated.',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  confirm: {
    defaultMessage: 'Confirm',
  },
});
