import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card } from '@cooltra/ui';
import { MdGpsFixed } from 'react-icons/md';
import { useField } from '@cooltra/form';
import { useToggle } from '@cooltra/hooks';

import { useNotification } from '~/hooks';

import { VehicleGeolocationFormValue } from '../../index';

import { VehicleMap } from './VehicleMap';
import messages from './messages';

export type VehicleGeolocationProps = {
  vehicleId: string;
};

export const Geolocation = ({ vehicleId }: VehicleGeolocationProps) => {
  const { addErrorNotification } = useNotification();
  const { formatMessage } = useIntl();

  const { value: location, setValue } =
    useField<VehicleGeolocationFormValue>('geolocation');

  const [isLoading, { toggleOn, toggleOff }] = useToggle(false);

  const removeLocation = () => {
    setValue(null);
  };

  const getLocation = () => {
    toggleOn();
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        setValue({
          latitude: coords.latitude,
          longitude: coords.longitude,
        });
        toggleOff();
      },
      (e) => {
        toggleOff();
        if (e.code === 1) {
          return addErrorNotification(
            formatMessage(messages.deniedPermissions)
          );
        }
        addErrorNotification();
      }
    );
  };

  const getContent = () => {
    if (location) {
      return (
        <div className="w-full h-[444px] relative">
          <VehicleMap id={vehicleId}>
            <div className="absolute left-3 bottom-3">
              <Button
                className="relative z-10"
                variant="danger"
                size="sm"
                onClick={removeLocation}
              >
                <FormattedMessage {...messages.removeLocation} />
              </Button>
            </div>
          </VehicleMap>
        </div>
      );
    }

    return (
      <Card className="bg-neutral-100 flex items-center justify-center h-[444px] ">
        <Button
          onClick={getLocation}
          loading={isLoading}
          size="md"
          emphasis="high"
        >
          <span className="flex flex-row items-center gap-2">
            <MdGpsFixed />
            <FormattedMessage {...messages.getLocation} />
          </span>
        </Button>
      </Card>
    );
  };

  return (
    <div className="max-w-xl w-full">
      <h2 className="font-semibold text-xl text-neutral-800 mb-5">
        <FormattedMessage {...messages.geolocation} />
      </h2>
      <Card className="bg-neutral-100 h-[444px] overflow-hidden">
        {getContent()}
      </Card>
    </div>
  );
};
