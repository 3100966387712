import { Android } from './Android';
import { ClickAndRide } from './ClickAndRide';
import { Ios } from './Ios';
import { LockOpen } from './LockOpen';
import { Motorcycle } from './Motorcycle';
import { Pin } from './Pin';

export const CustomIcon = {
  Android,
  ClickAndRide,
  Ios,
  LockOpen,
  Motorcycle,
  Pin,
};
