import * as Tabs from './Tabs';
import './navigation.css';

export const Navigation = () => (
  <>
    <div className="navigation-space" />
    <div className="shadow-md fixed w-full z-20 top-0 bg-primary-700">
      <div className="flex justify-between">
        <ul className="flex">
          <Tabs.CooltraLogo />
          <Tabs.Users />
          <Tabs.Locations />
          <Tabs.Vehicles />
          <Tabs.Rentals />
          <Tabs.Tasks />
          <Tabs.ProblemReports />
          <Tabs.Incidents />
          <Tabs.WorkUnits />
          <Tabs.Offers />
          <Tabs.Zones />
          <Tabs.Prices />
          <Tabs.POS />
        </ul>
        <ul className="flex">
          <Tabs.Account />
          <Tabs.Settings />
          <Tabs.LogOut />
        </ul>
      </div>
    </div>
  </>
);
