import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@cooltra/ui';
import { setUser } from '@sentry/react';
import { useIntl } from 'react-intl';
import { MdLockOutline } from 'react-icons/md';

import { ErrorPage } from '~/common';

import messages from './messages';

export const NoSystemPermission = () => {
  const { formatMessage } = useIntl();
  const { logout } = useAuth0();

  const handleLogOut = () => {
    setUser(null);
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <div className="min-h-full bg-neutral-50">
      <ErrorPage
        icon={<MdLockOutline />}
        title={formatMessage(messages.title)}
        error={formatMessage(messages.content)}
        footer={
          <Button onClick={handleLogOut}>
            {formatMessage(messages.logout)}
          </Button>
        }
      />
    </div>
  );
};
