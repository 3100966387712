import { ContractStatus } from '@cooltra/station-based-api';
import { BadgeVariant } from '@cooltra/ui';
import { getKeys } from '@cooltra/utils';
import { MessageDescriptor } from 'react-intl';

import messages from './messages';

export const contractStatusData: Record<
  ContractStatus,
  {
    label: MessageDescriptor;
    variant: BadgeVariant;
  }
> = {
  NEW: {
    label: messages.NEW,
    variant: 'neutral',
  },
  CANCELED: {
    label: messages.CANCELED,
    variant: 'danger',
  },
  NO_SHOW: {
    label: messages.NO_SHOW,
    variant: 'danger',
  },
  DRAFT: {
    label: messages.DRAFT,
    variant: 'neutral',
  },
  BOOKED: {
    label: messages.BOOKED,
    variant: 'warning',
  },
  ACTIVE: {
    label: messages.ACTIVE,
    variant: 'success',
  },
  CLOSED: {
    label: messages.CLOSED,
    variant: 'neutral',
  },
};

export const allContractStatuses = getKeys(contractStatusData);

export const getContractStatusMessage = (status: ContractStatus) =>
  contractStatusData[status].label;

export const getContractStatusVariant = (status: ContractStatus) =>
  contractStatusData[status].variant;
