import { DamageType, VehiclePart } from '@cooltra/station-based-api';
import { IntlShape } from 'react-intl';

import { createSetError, validateRequired } from '~/validation';

export type AddDamageFormValues = {
  vehiclePart: VehiclePart | '';
  type: DamageType | '';
  imageId: string;
};

export const initialValues: AddDamageFormValues = {
  vehiclePart: '',
  type: '',
  imageId: '',
};

type AddDamageFormFieldName = keyof AddDamageFormValues;

type AddDamageFormErrors = Partial<
  Record<AddDamageFormFieldName, string | undefined>
>;

export const validateAddDamageForm =
  (intl: IntlShape) => (values: AddDamageFormValues) => {
    const errors: AddDamageFormErrors = {};
    const setError = createSetError(errors);

    setError('vehiclePart', validateRequired(intl, values.vehiclePart));
    setError('type', validateRequired(intl, values.type));
    setError('imageId', validateRequired(intl, values.imageId));

    return errors;
  };
