import { defineMessages } from 'react-intl';

export default defineMessages({
  packOverview: {
    defaultMessage: 'Pack overview',
  },
  code: {
    defaultMessage: 'Code',
  },
  price: {
    defaultMessage: 'Price (€)',
  },
  credit: {
    defaultMessage: 'Credit (€)',
  },
  validity: {
    defaultMessage: 'Validity & use',
  },
  maxPurchasesPerUser: {
    defaultMessage: 'Max purchases per user',
  },
  targetGroup: {
    defaultMessage: 'Target group',
  },
  targetUserIds: {
    defaultMessage: 'User IDs',
  },
  targetUserIdsPlaceholder: {
    defaultMessage: 'Paste user IDs separated by a new line here',
  },
});
