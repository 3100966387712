import { defineMessages } from 'react-intl';

export default defineMessages({
  downloadCodes: {
    defaultMessage: 'Download Codes (.csv)',
  },
  loadingCodes: {
    defaultMessage: 'Loading codes...',
  },
});
