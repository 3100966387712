import { defineMessages } from 'react-intl';

export default defineMessages({
  telematics: {
    defaultMessage: 'Sensors',
  },
  issue: {
    defaultMessage: 'issue',
  },
  issues: {
    defaultMessage: 'issues',
  },
  noIssues: {
    defaultMessage: 'Normal',
  },
});
