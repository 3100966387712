import { FormattedMessage } from 'react-intl';
import { AppUserLanguage } from '@cooltra/api';

import { isSupportedLanguage } from '~/utils/users';

import messages from './messages';

export type LanguageProps = {
  language: AppUserLanguage | string;
};

export const UserLanguage = ({ language }: LanguageProps) => {
  const descriptor = isSupportedLanguage(language)
    ? messages[language]
    : messages.en;
  return <FormattedMessage {...descriptor} />;
};
