import { useRentalInvoiceQuery } from '@cooltra/api';
import { RouterLink } from '@cooltra/navigation';
import { Icon } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { MdChevronRight } from 'react-icons/md';

import messages from './messages';

export type RentalInvoiceLinkProps = {
  rentalId: string;
};

export const RentalInvoiceLink = ({ rentalId }: RentalInvoiceLinkProps) => {
  const { data } = useRentalInvoiceQuery(rentalId, {
    retry: false,
  });

  if (!data?.invoiceId) {
    return null;
  }

  return (
    <RouterLink
      to={`/invoices/${data.invoiceId}`}
      className="text-xs flex justify-start w-full gap-2 mt-6"
    >
      <FormattedMessage {...messages.seeInvoice} />
      <Icon className="text-base">
        <MdChevronRight />
      </Icon>
    </RouterLink>
  );
};
