import { InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSelectField } from '@cooltra/form';
import { PromotionTarget } from '@cooltra/station-based-api';

import messages, { promotionsTypeMessages } from './messages';

const promotionType: PromotionTarget[] = ['COUPON', 'WEB_OFFER'];

export const Type = () => {
  const { formatMessage } = useIntl();
  return (
    <FormSelectField
      name="type"
      id="type"
      isClearable
      placeholder={formatMessage(messages.placeholder)}
      label={
        <InputLabel htmlFor="type">
          <FormattedMessage {...messages.label} />
        </InputLabel>
      }
      options={promotionType.map((type) => ({
        value: type,
        label: formatMessage(promotionsTypeMessages[type]),
      }))}
    />
  );
};
