import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { RouterButton } from '@cooltra/navigation';
import { Card } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const Home = () => {
  const { hasPermission } = useAuthClaimsQuery();

  const hasSubscriptionChargesReadPermission = hasPermission(
    'read:subscription_charges'
  );
  const hasOperatorWriteParkingTinderPermission = hasPermission(
    'write:fines_management'
  );

  return (
    <div
      className="container min-w-lg max-w-xl py-20 shrink-0"
      data-testid="HOME"
    >
      <Card className="relative p-14">
        <h1 className="text-3xl mb-6 text-neutral-800">
          <FormattedMessage {...messages.title} />
        </h1>
        <p className="text text-neutral-800">
          <FormattedMessage {...messages.intro} />
        </p>
      </Card>
      {hasOperatorWriteParkingTinderPermission && (
        <Card className="relative p-10 pb-12 mt-4">
          <h3 className="text-xl mb-6 text-neutral-800">
            <FormattedMessage {...messages.parkedVehicles} />
          </h3>
          <ul>
            <RouterButton emphasis="high" to="/fines">
              <FormattedMessage {...messages.startParkingTinder} />
            </RouterButton>
          </ul>
        </Card>
      )}
      {hasSubscriptionChargesReadPermission && (
        <Card className="relative p-10 pb-12 mt-4">
          <h3 className="text-xl mb-6 text-neutral-800">
            <FormattedMessage {...messages.pricing} />
          </h3>
          <ul>
            <RouterButton emphasis="high" to="/pricing">
              <FormattedMessage {...messages.subscriptionCharges} />
            </RouterButton>
          </ul>
        </Card>
      )}
    </div>
  );
};
