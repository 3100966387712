import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  useCloseProblemReportMutation,
  useProblemReportQuery,
  useTaskMutation,
} from '@cooltra/api';
import { Button, TopLoadingBar } from '@cooltra/ui';
import { Form, useFormContext } from '@cooltra/form';
import { useRouteState } from '@cooltra/navigation';
import { AxiosError } from 'axios';

import {
  DividedCard,
  ErrorPage,
  ProblemReportPair,
  SystemPair,
  TaskBackLink,
  TaskFormValues,
  VehiclePair,
} from '~/common';
import { useNotification, useVehicleQuery } from '~/hooks';
import { useVehiclePolling } from '~/libs/polling-data';

import { CreateTaskForm } from './CreateTaskForm';
import messages from './messages';

export const CreateTask = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const routeState = useRouteState<'from'>();
  const { activatePollingVehicle } = useVehiclePolling();

  const [searchParams] = useSearchParams();
  const vehicleId = searchParams.get('vehicleId') || '';
  const problemReportId = searchParams.get('problemReportId');

  const { data: problemReport } = useProblemReportQuery(problemReportId!, {
    enabled: Boolean(problemReportId),
  });

  const mutation = useTaskMutation();
  const { data: vehicle, isLoading, isError } = useVehicleQuery(vehicleId);
  const { formatMessage } = useIntl();
  const addTaskIsEmphasize = problemReport?.open ? 'medium' : 'high';

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const { mutateAsync } = useCloseProblemReportMutation(problemReportId!);
  const {
    values: formValues,
    isValid,
    validateForm,
    touchAll,
    setSubmitting,
    isSubmitting,
  } = useFormContext<TaskFormValues>();

  const createTask = async (values: TaskFormValues) => {
    setSubmitting(true);
    touchAll();
    validateForm();

    if (!isValid || !vehicle) {
      return;
    }
    const { externalId: vehicleExternalId } = vehicle;

    return mutation
      .mutateAsync({
        vehicleId,
        vehicleExternalId,
        problemReportId,
        workUnitId: null,
        ...values,
      })
      .then(() => {
        addSuccessNotification(intl.formatMessage(messages.success));
        if (values.blocking) {
          activatePollingVehicle(vehicleId);
        }
        if (problemReportId) {
          navigate(`/problem-reports/${problemReportId}`);
        } else if (!routeState?.from) {
          navigate(`/vehicles/${vehicleId}/tasks`);
        } else {
          navigate(routeState.from);
        }
      })
      .catch(({ response }: AxiosError) => {
        switch (response?.status) {
          case 409:
            addErrorNotification(
              intl.formatMessage(messages.conflictErrorMessage)
            );
            break;
          default:
            addErrorNotification();
            break;
        }
      });
  };

  const handleCreateTask = () => {
    createTask(formValues).finally(() => setSubmitting(false));
  };
  const handleCreateTaskAndCloseReport = () => {
    createTask(formValues)
      .then(() => {
        if (problemReportId) {
          return mutateAsync();
        }
        return;
      })
      .catch(() =>
        addErrorNotification(formatMessage(messages.errorOnCloseReport))
      )
      .finally(() => setSubmitting(false));
  };

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <>
      {isLoading && <TopLoadingBar />}

      <Form>
        <DividedCard
          className="min-w-4xl max-w-5xl py-12"
          renderHeader={
            <div className="flex items-end justify-between">
              <div>
                <TaskBackLink vehicleId={vehicleId} />
                <h1 className="text-3xl">
                  <FormattedMessage {...messages.title} />
                </h1>
              </div>
              <div className="flex flex-row gap-3">
                <Button
                  emphasis={addTaskIsEmphasize}
                  onClick={handleCreateTask}
                  disabled={!vehicle || isSubmitting}
                >
                  <FormattedMessage {...messages.createTask} />
                </Button>
                {problemReportId && problemReport?.open && (
                  <Button
                    emphasis="high"
                    onClick={handleCreateTaskAndCloseReport}
                    disabled={!vehicle || isSubmitting}
                  >
                    <FormattedMessage {...messages.createTaskAndCloseReport} />
                  </Button>
                )}
              </div>
            </div>
          }
          renderLeft={
            <div className="flex flex-col gap-9">
              <VehiclePair
                vehicleId={vehicle?.vehicleId}
                model={vehicle?.model}
                externalId={vehicle?.externalId}
                identificationNumber={vehicle?.identificationNumber}
              />
              <SystemPair system={vehicle?.system} />
              {problemReportId && (
                <ProblemReportPair problemReportId={problemReportId} />
              )}
            </div>
          }
          renderRight={<CreateTaskForm vehicle={vehicle} />}
        />
      </Form>
    </>
  );
};
