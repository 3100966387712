import { useIntl } from 'react-intl';
import { InvoiceListItem, useInvoicePdfQuery } from '@cooltra/api';
import { Icon, Spinner } from '@cooltra/ui';
import { useEffect } from 'react';
import { MdOutlineDescription } from 'react-icons/md';

import { useNotification } from '~/hooks';

import messages from './messages';

export type InvoicePdfProps = Pick<InvoiceListItem, 'invoiceId' | 'type'>;

export const InvoicePdf = ({ invoiceId }: InvoicePdfProps) => {
  const { addErrorNotification } = useNotification();
  const { formatMessage } = useIntl();

  const { fetchStatus, refetch, error } = useInvoicePdfQuery(invoiceId, {
    enabled: false,
  });

  useEffect(() => {
    if (error) {
      switch (error.response?.status) {
        case 404:
          addErrorNotification(formatMessage(messages.pdfDoesNotExist));
          break;
        default:
          addErrorNotification(
            formatMessage(messages.somethingWentWrongOnDownloadingPdf)
          );
          break;
      }
    }
  }, [addErrorNotification, error, formatMessage]);

  if (fetchStatus === 'fetching') {
    return <Spinner size="sm" />;
  }

  return (
    <button
      type="button"
      aria-label={formatMessage(messages.invoicePdf)}
      onClick={() => refetch()}
    >
      <Icon className="text-xl text-neutral-700">
        <MdOutlineDescription />
      </Icon>
    </button>
  );
};
