import { Route, Routes } from 'react-router-dom';

import { VehicleServicesList } from './VehicleServices/VehicleServicesList/VehicleServicesList';
import { VehicleTechnicalInspectionsList } from './VehicleTechnicalInspections/VehicleTechnicalInspectionsList/VehicleTechnicalInspectionsList';
import { VehicleMaintenanceHeader } from './VehicleMaintenanceHeader/VehicleMaintenanceHeader';

export type VehicleMaintenanceProps = {
  vehicleId: string;
};

export const VehicleMaintenance = ({ vehicleId }: VehicleMaintenanceProps) => (
  <div className="container min-w-4xl max-w-5xl py-12">
    <VehicleMaintenanceHeader vehicleId={vehicleId} />
    <Routes>
      <Route
        path="/technical-inspections"
        element={<VehicleTechnicalInspectionsList vehicleId={vehicleId} />}
      />
      <Route
        path="/services"
        element={<VehicleServicesList vehicleId={vehicleId} />}
      />
    </Routes>
  </div>
);
