import { defineMessages } from 'react-intl';

export default defineMessages({
  searchUsers: {
    defaultMessage: 'Search users',
  },
  explanation: {
    defaultMessage:
      'By email, phone number, id number, driver license, referral code...',
  },
});
