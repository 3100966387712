import { format } from 'date-fns';
import { Icon } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { MdArrowDownward } from 'react-icons/md';

import { saveCsvFile } from '~/utils/file';
import { useFilteredVehiclesQuery } from '~/libs/vehicles-filters';
import { dateFormat } from '~/utils/date';

import { vehiclesToCsv } from './utils';
import messages from './messages';

export type DownloadCsvProps = {
  disabled: boolean;
};

export const DownloadCsv = ({ disabled }: DownloadCsvProps) => {
  const {
    data: { filteredVehicles },
  } = useFilteredVehiclesQuery();

  const downloadCSV = () =>
    saveCsvFile(
      `vehicles-${format(new Date(), dateFormat)}.csv`,
      vehiclesToCsv(filteredVehicles)
    );

  return (
    <button
      className="link-primary text-xs"
      onClick={downloadCSV}
      disabled={disabled}
    >
      <Icon className="mr-1">
        <MdArrowDownward />
      </Icon>
      <FormattedMessage {...messages.downloadCSV} />
    </button>
  );
};
