import { AxiosResponse } from 'axios';

import messages from './messages';

type ThereAreSomeMissingFieldsError = {
  error: 'data_invalid';
  details?: {
    request_missing_fields?: string[];
  };
};

type BodyForUnprocessableEntityError = {
  errorType: 'location_with_different_home_system' | 'ef_error';
  errorMessage: string;
};

const getErrorMessageFromUnprocessableEntity = (
  data: BodyForUnprocessableEntityError | ThereAreSomeMissingFieldsError
): { defaultMessage: string; avoidTranslation?: boolean } => {
  if (
    (data as ThereAreSomeMissingFieldsError).details?.request_missing_fields
  ) {
    return messages.missingFields;
  }

  switch ((data as BodyForUnprocessableEntityError).errorType) {
    case 'location_with_different_home_system':
      return messages.sendingVehicleToALocationWithDifferentHomeSystem;
    case 'ef_error':
      return messages.cannotTransfer;
    default:
      return messages.somethingWentWrong;
  }
};

export const getErrorMessageForTransferringVehicle = (
  errorResponse: AxiosResponse | undefined
): { defaultMessage: string; avoidTranslation?: boolean } => {
  switch (errorResponse?.status) {
    case 401:
      return messages.userDoesNotHavePermissionsToTransferVehicle;
    case 422:
      return getErrorMessageFromUnprocessableEntity(errorResponse?.data);
    default:
      return messages.somethingWentWrong;
  }
};
