import { InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';

import { FilterFields } from '~/common';

import messages from './messages';

export const Keyword = () => {
  const intl = useIntl();

  return (
    <FilterFields.Keyword
      placeholder={intl.formatMessage(messages.placeholder)}
      id="keyword-search"
      label={
        <InputLabel htmlFor="keyword-search">
          <FormattedMessage {...messages.label} />
        </InputLabel>
      }
    />
  );
};
