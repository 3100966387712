import { Route, Routes } from 'react-router-dom';

import { Four0Four } from '~/common';

import { ShiftsFiltersForm, ShiftsList } from './ShiftsList';
import { Shift } from './Shift/Shift';

export const Shifts = () => (
  <Routes>
    <Route
      path="/"
      element={
        <ShiftsFiltersForm>
          <ShiftsList />
        </ShiftsFiltersForm>
      }
    />
    <Route path="/:workShiftId" element={<Shift />} />
    <Route path="*" element={<Four0Four />} />
  </Routes>
);
