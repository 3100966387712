import { useIntl } from 'react-intl';
import { Form, FormProvider, FormProviderProps } from '@cooltra/form';

import { validateForm } from './form-validations';
import { DepositsFormValues, VehicleCategory } from './form-details';

export type DepositsFormProps = Omit<
  FormProviderProps<DepositsFormValues>,
  'validate'
> & {
  enabledVehicleCategories: VehicleCategory[];
};

export const DepositsPriceListDetailsForm = ({
  onSubmit,
  initialValues,
  children,
  enabledVehicleCategories,
}: DepositsFormProps) => {
  const intl = useIntl();

  return (
    <FormProvider
      initialValues={initialValues}
      validate={validateForm(intl, enabledVehicleCategories)}
      onSubmit={onSubmit}
    >
      <Form>{children}</Form>
    </FormProvider>
  );
};
