import { createContext, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactQrCode from 'react-qr-code';
import { Button, CopyButton, Modal } from '@cooltra/ui';
import { useContractQuery } from '@cooltra/station-based-api';

import { useContractFlags } from '~/hooks';
import { getEnvVariable } from '~/utils/environment';

import messages from './messages';

type StripeDepositQRContextValues = {
  openWithDepositId: (depositId: string) => void;
};

export const StripeDepositQRContext =
  createContext<StripeDepositQRContextValues>({
    openWithDepositId: () => {},
  });

type StripeDepositQRProviderProps = {
  contractId: string;
  children: ReactNode;
};

export const StripeDepositQRProvider = ({
  contractId,
  children,
}: StripeDepositQRProviderProps) => {
  const [state, setState] = useState({
    isOpen: false,
    depositId: '',
  });
  const {
    refetch,
    isRefetching,
    data: contract,
  } = useContractQuery(contractId);
  const { hasDepositBeenWithheld } = useContractFlags();

  const { isOpen, depositId } = state;

  const onResetState = () => setState({ isOpen: false, depositId: '' });
  const onClose = () => setState({ ...state, isOpen: false });
  const onOpenWithDepositId = (depositId: string) =>
    setState({ isOpen: true, depositId });
  const onVerifyDepositWithhold = () => refetch();

  const language = contract?.user?.language.toLowerCase() || 'en';
  const publicQRLink = `${getEnvVariable('COOLTRA_WEB_DOMAIN')}/${language}/contracts/${contractId}/pending-deposits/${depositId}`;

  useEffect(() => {
    if (hasDepositBeenWithheld) {
      onResetState();
    }
  }, [hasDepositBeenWithheld]);

  return (
    <StripeDepositQRContext.Provider
      value={{
        openWithDepositId: onOpenWithDepositId,
      }}
    >
      {!hasDepositBeenWithheld && (
        <Modal isOpen={isOpen} onRequestClose={onClose}>
          <h2 className="text-xl font-semibold text-neutral-700 text-center">
            <FormattedMessage {...messages.stripeQRRetentionMethod} />
          </h2>
          <div className="pt-16 px-36" data-testid="STRIPE_QR">
            <ReactQrCode
              size={382}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={publicQRLink}
              viewBox="0 0 256 256"
            />
          </div>
          <div className="text-center py-9">
            <Button className="text-primary-500 font-semibold" emphasis="low">
              <CopyButton
                className="text-primary-500 font-semibold"
                value={publicQRLink}
                label={
                  <span className="pl-1 text-primary-500 font-semibold">
                    <FormattedMessage {...messages.copyLink} />
                  </span>
                }
              />
            </Button>
          </div>
          <div className="w-full pb-12 text-center">
            <Button onClick={onVerifyDepositWithhold} loading={isRefetching}>
              <FormattedMessage {...messages.verifyDepositWithhold} />
            </Button>
          </div>
        </Modal>
      )}
      {children}
    </StripeDepositQRContext.Provider>
  );
};
