import {
  AlgorithmPrice,
  PriceTypeByProductCategory,
  PricingGroupPrices,
} from '@cooltra/station-based-api';
import { Currency } from '@cooltra/utils';
import { parse, format } from 'date-fns';

import { PriceListFormValues } from './Form';

const LEAP_YEAR = 2024;

export const getFormValuesFromVehicleTypePriceListDetails = (
  pricingGroupPrices: PricingGroupPrices
): PriceListFormValues => {
  const parsedDateFrom = parse(
    `${pricingGroupPrices.dateFrom}-${LEAP_YEAR}`,
    'MM-dd-yyyy',
    new Date()
  );
  const parsedDateUntil = parse(
    `${pricingGroupPrices.dateUntil}-${LEAP_YEAR}`,
    'MM-dd-yyyy',
    new Date()
  );

  const getAlgorithmPrice = (
    priceTypeByProductCategory: PriceTypeByProductCategory | null
  ): AlgorithmPrice => {
    if (!priceTypeByProductCategory) {
      return 'BASE';
    }

    return priceTypeByProductCategory.algorithmPrice;
  };

  const getPriceValue = (
    priceTypeByProductCategory: PriceTypeByProductCategory | null
  ): string => {
    if (!priceTypeByProductCategory) {
      return '';
    }

    return priceTypeByProductCategory.price.value.toString();
  };

  const getPriceCurrency = (
    priceTypeByProductCategory: PriceTypeByProductCategory | null
  ): Currency => {
    if (!priceTypeByProductCategory) {
      return 'EUR';
    }

    return priceTypeByProductCategory.price.currency;
  };

  return {
    dateFrom: `${format(parsedDateFrom, 'dd-MM')}`,
    dateUntil: `${format(parsedDateUntil, 'dd-MM')}`,
    pricingGroup: pricingGroupPrices.pricingGroup,
    'prices.EBIKE.type': `EBIKE_${getAlgorithmPrice(
      pricingGroupPrices.prices.EBIKE
    )}`,
    'prices.EBIKE.price.value': getPriceValue(pricingGroupPrices.prices.EBIKE),
    'prices.EBIKE.price.currency': getPriceCurrency(
      pricingGroupPrices.prices.EBIKE
    ),
    'prices.PREMIUM.type': `PREMIUM_${getAlgorithmPrice(
      pricingGroupPrices.prices.PREMIUM
    )}`,
    'prices.PREMIUM.price.value': getPriceValue(
      pricingGroupPrices.prices.PREMIUM
    ),
    'prices.PREMIUM.price.currency': getPriceCurrency(
      pricingGroupPrices.prices.PREMIUM
    ),
    'prices.STANDARD.type': `STANDARD_${getAlgorithmPrice(
      pricingGroupPrices.prices.STANDARD
    )}`,
    'prices.STANDARD.price.value': getPriceValue(
      pricingGroupPrices.prices.STANDARD
    ),
    'prices.STANDARD.price.currency': getPriceCurrency(
      pricingGroupPrices.prices.STANDARD
    ),
    'prices.PLUS.type': `PLUS_${getAlgorithmPrice(
      pricingGroupPrices.prices.PLUS
    )}`,
    'prices.PLUS.price.value': getPriceValue(pricingGroupPrices.prices.PLUS),
    'prices.PLUS.price.currency': getPriceCurrency(
      pricingGroupPrices.prices.PLUS
    ),
    'prices.SUPER.type': `SUPER_${getAlgorithmPrice(
      pricingGroupPrices.prices.SUPER
    )}`,
    'prices.SUPER.price.value': getPriceValue(pricingGroupPrices.prices.SUPER),
    'prices.SUPER.price.currency': getPriceCurrency(
      pricingGroupPrices.prices.SUPER
    ),
  };
};
