import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';
import { useLocation } from 'react-router-dom';
import { getParams } from '@cooltra/utils';

import {
  vehicleProblemReportsFiltersInitialValues,
  VehicleProblemReportsFiltersFormValues,
} from './vehicle-problem-reports-filters-form';

const { open, minDate, maxDate } = vehicleProblemReportsFiltersInitialValues;

export const VehicleProblemReportsFiltersForm = ({
  children,
}: PropsWithChildren) => {
  const { search } = useLocation();
  const params =
    getParams<keyof VehicleProblemReportsFiltersFormValues>(search);

  const initialValues = {
    open: params.string('open') || open,
    minDate: params.string('minDate') || minDate,
    maxDate: params.string('maxDate') || maxDate,
  } as VehicleProblemReportsFiltersFormValues;

  return <FormProvider initialValues={initialValues}>{children}</FormProvider>;
};
