import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'No problem reports found',
  },
  content: {
    defaultMessage: 'This rental does not have any problem reports',
  },
});
