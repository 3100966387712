import { Money } from '@cooltra/utils';
import { parse } from 'date-fns';

import { dateAndTimeFormat } from '~/utils/date';
import { clean } from '~/utils/objects';

import { PromotionFormValues } from './promotion-form';

const getISODate = (
  date: string | undefined,
  time: string | undefined
): string => {
  if (!date || !time) {
    return '';
  }

  return parse(`${date} ${time}`, dateAndTimeFormat, new Date()).toISOString();
};

const formatBenefitDates = ({
  benefitFromDate,
  benefitFromTime,
  benefitUntilDate,
  benefitUntilTime,
  benefitExpiresAfterDays,
  benefitExpiresAfterHours,
  benefitExpirationRangeMode,
}: PromotionFormValues): {
  benefitExpiresAfterInHours: number | null;
  benefitFrom: string | null;
  benefitUntil: string | null;
} => {
  switch (benefitExpirationRangeMode) {
    case 'specific-date':
      return {
        benefitExpiresAfterInHours: null,
        benefitFrom: getISODate(benefitFromDate, benefitFromTime),
        benefitUntil: getISODate(benefitUntilDate, benefitUntilTime),
      };
    case 'after-days':
      return {
        benefitExpiresAfterInHours:
          benefitExpiresAfterDays === ''
            ? null
            : Number(benefitExpiresAfterDays) * 24,
        benefitFrom: null,
        benefitUntil: null,
      };
    case 'after-hours':
      return {
        benefitExpiresAfterInHours:
          benefitExpiresAfterHours === ''
            ? null
            : Number(benefitExpiresAfterHours),
        benefitFrom: null,
        benefitUntil: null,
      };
  }
};

export const formatPromotionDates = (values: PromotionFormValues) => {
  const benefitDates = formatBenefitDates(values);
  return {
    validFrom: getISODate(values.validFromDate, values.validFromTime),
    validUntil: getISODate(values.validUntilDate, values.validUntilTime),
    ...benefitDates,
  };
};

export const formatCodeValues = (
  values: PromotionFormValues
): {
  code: string | null;
  multiCode: boolean;
  codePrefix: string | null;
  numberOfCodes: number | null;
} => {
  const isSingleCode = values.promotionCodeMode === 'single-code';
  return {
    code: isSingleCode ? values.code : null,
    multiCode: !isSingleCode,
    codePrefix: isSingleCode ? null : values.codePrefix,
    numberOfCodes: isSingleCode ? null : Number(values.numberOfCodes),
  };
};

export const formatDiscountAndCreditValues = (
  values: PromotionFormValues
): {
  credit: Money | null;
  discount: number | null;
  discountCap: Money | null;
  rentalsCap: number | null;
  discountCapPerRental: Money | null;
} => {
  const isACreditPromotion = values.promotionTypeMode === 'credit';

  if (isACreditPromotion) {
    return {
      credit: {
        value: Number(values['credit.value']),
        currency: 'EUR',
      },
      discount: null,
      discountCap: null,
      rentalsCap: null,
      discountCapPerRental: null,
    };
  } else {
    const isARentalsCapTypeDiscount = values.capType === 'rentals';

    return {
      credit: null,
      discount: Number(values.discount),
      discountCap: isARentalsCapTypeDiscount
        ? null
        : {
            value: Number(values['discountCap.value']),
            currency: 'EUR',
          },
      rentalsCap: isARentalsCapTypeDiscount ? Number(values.rentalsCap) : null,
      discountCapPerRental: isARentalsCapTypeDiscount
        ? {
            value: Number(values['discountCapPerRental.value']),
            currency: 'EUR',
          }
        : null,
    };
  }
};

export const formatPromotionTranslations = (values: PromotionFormValues) => {
  const titles = {
    en: values['titleTranslations.en'],
    es: values['titleTranslations.es'],
    it: values['titleTranslations.it'],
    pt: values['titleTranslations.pt'],
    fr: values['titleTranslations.fr'],
    ca: values['titleTranslations.ca'],
    nl: values['titleTranslations.nl'],
  };
  const titleTranslations = clean(titles);

  const descriptions = {
    en: values['descriptionTranslations.en'],
    es: values['descriptionTranslations.es'],
    it: values['descriptionTranslations.it'],
    pt: values['descriptionTranslations.pt'],
    fr: values['descriptionTranslations.fr'],
    ca: values['descriptionTranslations.ca'],
    nl: values['descriptionTranslations.nl'],
  };
  const descriptionTranslations = clean(descriptions);

  return {
    titleTranslations,
    descriptionTranslations,
  };
};
