import { format, isValid, parse } from 'date-fns';

export const posDateFormat = 'yyyy-MM-dd';
export const posDateTimeFormat = 'yyyy-MM-dd HH:mm';

export const formatDateQueryParam = (date = new Date()) =>
  format(date, posDateTimeFormat);

export const parseDateQueryParam = (date: string) => {
  const parsedDate = parse(date, posDateTimeFormat, new Date());
  return isValid(parsedDate) ? parsedDate : new Date();
};
