import { Money } from '@cooltra/utils';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Divider } from '@cooltra/ui';

import { Amount } from '~/common';

import messages from './messages';

export type PackProps = {
  title: string;
  description?: string;
  purchasedOn: string;
  amount: Money;
};

export const Pack = ({
  title,
  description,
  purchasedOn,
  amount,
}: PackProps) => (
  <>
    <div className="pb-4 h-28">
      <h4 className="text-xl mb-2">{title}</h4>
      {description && <p className="text-xs text-neutral-400">{description}</p>}
    </div>
    <Divider />
    <div className="pt-4">
      <div className="mb-8">
        <p className="text-xs text-neutral-400">
          <FormattedMessage {...messages.credit} />
        </p>
        <Amount className="text-md font-semibold" {...amount} />
      </div>
      <p className="flex gap-1 text-xs text-neutral-800">
        <FormattedMessage {...messages.purchasedOn} />
        <span className="font-semibold">
          <FormattedDate
            value={new Date(purchasedOn)}
            day="2-digit"
            month="short"
            year="numeric"
          />
        </span>
      </p>
    </div>
  </>
);
