import { FormattedMessage } from 'react-intl';
import { Reservation } from '@cooltra/api';
import { Card } from '@cooltra/ui';
import { RouterLink } from '@cooltra/navigation';
import { capitalize, shortenId } from '@cooltra/utils';

import { useSystemLabel } from '~/common/System';
import {
  FailedCheckIn,
  MultilineDateTime,
  UserListItemLink,
  ReservationProblemReportsBadge,
  ReservationStateBadge,
  VehicleLink,
  ReservationSystem,
} from '~/common';

import { ReservationRowLayout } from '../ReservationRowLayout';

import messages from './messages';

export const ReservationCard = ({
  createdAt,
  expiresAt,
  lastCheckinFailure,
  state,
  system,
  vehicle,
  rentalId,
  problemReportIds,
  user: { userId, name, provider },
}: Reservation) => {
  const capitalizedFullName = name
    .split(' ')
    .map((name) => capitalize(name.toLowerCase()))
    .join(' ');

  const { getSystemLabel } = useSystemLabel();

  return (
    <Card className="relative py-4 flex items-center">
      {state === 'ACTIVE' && (
        <div className="absolute bg-success-50/50 inset-0" />
      )}
      <ReservationRowLayout
        createdAt={<MultilineDateTime date={createdAt} />}
        spacer={<div className="w-full h-px bg-neutral-200" />}
        expiresAt={<MultilineDateTime date={expiresAt} />}
        user={
          <UserListItemLink
            name={capitalizedFullName}
            userId={userId}
            provider={provider}
          />
        }
        vehicle={<VehicleLink vehicle={vehicle} />}
        system={<ReservationSystem>{getSystemLabel(system)}</ReservationSystem>}
        checkIn={
          <div className="text-center">
            {rentalId ? (
              <div className="text-sm">
                <span>
                  <FormattedMessage {...messages.rental} />
                </span>
                <RouterLink to={`/rentals/${rentalId}`}>
                  #{shortenId(rentalId)}
                </RouterLink>
              </div>
            ) : (
              lastCheckinFailure && <FailedCheckIn error={lastCheckinFailure} />
            )}
          </div>
        }
        problemReports={
          <ReservationProblemReportsBadge
            problemReportIds={problemReportIds}
            userId={userId}
          />
        }
        state={<ReservationStateBadge state={state} />}
      />
    </Card>
  );
};
