import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';
import { FormattedNumber, IntlProvider } from 'react-intl';

export type AmountProps = Omit<HTMLAttributes<HTMLSpanElement>, 'children'> & {
  value: number;
  currency: string;
  negative?: boolean;
};

export const Amount = ({
  value,
  currency,
  negative,
  className,
  ...rest
}: AmountProps) => {
  const isValueAlreadyInNegative = value < 0;
  const haveToConvertToNegative = negative && !isValueAlreadyInNegative;

  return (
    <span className={classNames('tabular-nums', className)} {...rest}>
      <IntlProvider locale="en">
        <FormattedNumber
          value={haveToConvertToNegative ? value * -1 : value}
          currency={currency}
          style="currency"
        />
      </IntlProvider>
    </span>
  );
};
