import { useIntl } from 'react-intl';
import { Placeholder } from '@cooltra/ui';
import { getErrorStatus } from '@cooltra/axios';

import { NoDataAvailable } from '../../NoDataAvailable/NoDataAvailable';
import { TelematicsItemLayout } from '../TelematicsItemLayout/TelematicsItemLayout';
import { TelematicsFieldProps } from '../types';

import messages from './messages';

export const BoardVoltage = ({
  vehicleStatus,
  error,
}: TelematicsFieldProps) => {
  const { formatMessage } = useIntl();

  if (getErrorStatus(error) === 404) {
    return (
      <TelematicsItemLayout
        title={formatMessage(messages.boardVoltage)}
        value={<NoDataAvailable />}
      />
    );
  }

  if (!vehicleStatus) {
    return (
      <TelematicsItemLayout
        title={formatMessage(messages.boardVoltage)}
        value={<Placeholder className="w-20 h-2" />}
      />
    );
  }

  if (vehicleStatus.boardVoltage === null) {
    return (
      <TelematicsItemLayout
        title={formatMessage(messages.boardVoltage)}
        value={<NoDataAvailable />}
      />
    );
  }

  const updatedAt =
    vehicleStatus.updatedAt === vehicleStatus.boardVoltage.updatedAt
      ? undefined
      : vehicleStatus.boardVoltage.updatedAt;
  const { unit, value } = vehicleStatus.boardVoltage;

  return (
    <TelematicsItemLayout
      title={formatMessage(messages.boardVoltage)}
      value={`${value} ${unit}`}
      updatedAt={updatedAt}
    />
  );
};
