import { ReactNode } from 'react';
import { FormProvider } from '@cooltra/form';
import { useLocation } from 'react-router-dom';
import { getParams } from '@cooltra/utils';

import {
  vehicleRentalsFiltersInitialValues,
  VehicleRentalsFiltersFormValues,
} from './vehicle-rentals-filters-form';

const { minDate, maxDate } = vehicleRentalsFiltersInitialValues;

export type VehicleRentalsFiltersFormProps = {
  children: ReactNode;
};

export const VehicleRentalsFiltersForm = ({
  children,
}: VehicleRentalsFiltersFormProps) => {
  const { search } = useLocation();

  const params = getParams<keyof VehicleRentalsFiltersFormValues>(search);

  const initialValues = {
    minDate: params.string('minDate') || minDate,
    maxDate: params.string('maxDate') || maxDate,
  } as VehicleRentalsFiltersFormValues;

  return <FormProvider initialValues={initialValues}>{children}</FormProvider>;
};
