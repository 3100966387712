import { Rental } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { Amount } from '~/common';

import messages from './messages';

export type PricingPausingProps = Pick<Rental, 'pricing'>;

export const PricingPausing = ({ pricing }: PricingPausingProps) => {
  if (!pricing) {
    return <></>;
  }

  return (
    <div
      className="flex justify-between items-center"
      data-testid="RENTAL_PRICING_SUMMARY_PAUSING"
    >
      <span className="text-sm text-neutral-400">
        <FormattedMessage
          {...messages.pausing}
          values={{
            charge: (
              <Amount
                value={pricing.pausing.charge}
                currency={pricing.pausing.price.currency}
              />
            ),
            durationInMinutes: pricing.pausing.duration,
          }}
        />
      </span>
      <Amount
        className="text-sm"
        value={pricing.pausing.price.value}
        currency={pricing.pausing.price.currency}
      />
    </div>
  );
};
