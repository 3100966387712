import { defineMessages } from 'react-intl';

export default defineMessages({
  operator: {
    defaultMessage: 'Serviced by',
  },
  noOperators: {
    defaultMessage: 'No operators',
  },
});
