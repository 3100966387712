import { forwardRef } from 'react';
import { Icon, Tooltip } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';
import { MdErrorOutline } from 'react-icons/md';

import { TimeAgo } from '~/common';

import messages from './messages';

type TelematicsItemLayoutProps = {
  title: string | JSX.Element;
  value: string | JSX.Element;
  updatedAt?: string;
  noPadding?: boolean;
};

export const TelematicsItemLayout = forwardRef<
  HTMLSpanElement,
  TelematicsItemLayoutProps
>(({ title, value, updatedAt, noPadding }, ref) => (
  <li
    className={classNames(
      !noPadding && 'py-5',
      'flex',
      'gap-8',
      'justify-between',
      'items-center'
    )}
  >
    {typeof title === 'string' ? (
      <p className="text-sm text-neutral-400">{title}</p>
    ) : (
      title
    )}
    <span className="flex items-center">
      {updatedAt && (
        <Tooltip
          button={
            <span className="flex" ref={ref}>
              <Icon
                data-testid="LAST_SYNC_WARNING_ICON"
                className="pr-1.5 text-neutral-400"
              >
                <MdErrorOutline />
              </Icon>
            </span>
          }
          content={
            <p className="py-2 px-3 text-sm text-neutral-600">
              <FormattedMessage
                {...messages.lastSync}
                values={{ timeInThePast: <TimeAgo ISODate={updatedAt} /> }}
              />
            </p>
          }
        />
      )}
      <span>
        {typeof value === 'string' ? (
          <p className="text-sm font-semibold text-neutral-800">{value}</p>
        ) : (
          value
        )}
      </span>
    </span>
  </li>
));
