import { PricingGroups } from './PricingGroups/PricingGroups';
import { VehicleTypes } from './VehicleTypes/VehicleTypes';
import { Accessories } from './Accessories/Accessories';
import { Insurances } from './Insurances/Insurances';

export const StationBasedPromotionAppliesToFields = {
  PricingGroups,
  VehicleTypes,
  Accessories,
  Insurances,
};
