import { useRouteState } from '@cooltra/navigation';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { BackLink, RouterBackLink } from '../../BackLink';

import messages from './messages';

export type TaskBackLinkProps = {
  vehicleId: string;
};

export const TaskBackLink = ({ vehicleId }: TaskBackLinkProps) => {
  const routeState = useRouteState<'from'>();
  const navigate = useNavigate();

  const navigateBack = () => navigate(-1);

  return routeState?.from ? (
    <BackLink onClick={navigateBack} />
  ) : (
    <RouterBackLink to={`/vehicles/${vehicleId}`}>
      <FormattedMessage {...messages.backToVehicle} />
    </RouterBackLink>
  );
};
