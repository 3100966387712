import { defineMessages } from 'react-intl';

export default defineMessages({
  shouldRefundPaidTickets: {
    defaultMessage: 'You must refund paid tickets',
  },
  shouldReturnDeposit: {
    defaultMessage: 'You must return the deposit',
  },
  alertTitle: {
    defaultMessage: 'Before editing the contract:',
  },
});
