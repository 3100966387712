import { RentalColumnLabel } from '~/common';

import { OtherRentalRowLayout } from './OtherRentalRowLayout';

export const OtherRentalsListHeader = () => (
  <OtherRentalRowLayout
    className="mb-2"
    rentalId={<RentalColumnLabel name="licensePlate" />}
    system={<RentalColumnLabel name="system" />}
    pickUp={<RentalColumnLabel name="pickUp" />}
    dropOff={<RentalColumnLabel name="dropOff" />}
    state={<RentalColumnLabel name="state" />}
  />
);
