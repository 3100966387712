import { defineMessages } from 'react-intl';

export default defineMessages({
  createService: {
    defaultMessage: 'Create service',
  },
  createTechnicalInspection: {
    defaultMessage: 'Create technical inspection',
  },
  services: {
    defaultMessage: 'Services',
  },
  technicalInspections: {
    defaultMessage: 'Technical inspections',
  },
});
