import { defineMessages } from 'react-intl';

export default defineMessages({
  overview: {
    defaultMessage: 'Overview',
  },
  problemReports: {
    defaultMessage: 'Problem reports',
  },
  incidents: {
    defaultMessage: 'Incidents',
  },
  reservation: {
    defaultMessage: 'Reservation',
  },
  createdAt: {
    defaultMessage: 'Created at',
  },
  trafficOffenseReport: {
    defaultMessage: 'Traffic offense report',
  },
});
