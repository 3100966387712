import { defineMessages } from 'react-intl';
import { ContractAnnexType } from '@cooltra/station-based-api';

export default defineMessages({
  annex: {
    defaultMessage: 'Annex',
  },
  pendingToPay: {
    defaultMessage: 'Pending to pay',
  },
});

export const pendingPayAnnexTypesMessages = defineMessages<ContractAnnexType>({
  EXTENSION: {
    defaultMessage: 'Extension of {days} days',
  },
});
