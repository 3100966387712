import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'No problem reports found',
  },
  content: {
    defaultMessage: 'This user has not submitted any problem reports yet',
  },
});
