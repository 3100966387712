import { useState } from 'react';

export const rotationClasses: Record<number, string> = {
  [-270]: '-rotate-270',
  [-180]: '-rotate-180',
  [-90]: '-rotate-90',
  [0]: 'rotate-0',
  [90]: 'rotate-90',
  [180]: 'rotate-180',
  [270]: 'rotate-270',
};

export const useRotation = () => {
  const [rotation, rotate] = useState(0);
  const rotateLeft = () => rotate((prevRotation) => (prevRotation - 90) % 360);
  const rotateRight = () => rotate((prevRotation) => (prevRotation + 90) % 360);
  return {
    rotation,
    rotationClassName: rotationClasses[rotation],
    rotateLeft,
    rotateRight,
  };
};
