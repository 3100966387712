import { defineMessages } from 'react-intl';

export default defineMessages({
  noShow: {
    defaultMessage: 'No show',
  },
  returnDepositBeforeMarkingContractAsNoShow: {
    defaultMessage:
      'You must return the deposit before marking the contract as “No show”',
  },
  cancelAwaitingPaymentsBeforeMarkingContractAsNoShow: {
    defaultMessage:
      'You must cancel the stripe QR payment before marking the contract as “No show”',
  },
  areYouSureToMarkContractAsNoShow: {
    defaultMessage: 'Are you sure you want to mark this contract as “No show”?',
  },
  weWontRefundPayments: {
    defaultMessage:
      'Remember that proceeding with this, we won’t refund any payment done on this contract.',
  },
  success: {
    defaultMessage: 'Contract marked as “No show”',
  },
});
