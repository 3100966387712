import {
  InvoiceItem,
  ManualInvoiceItem,
  PackInvoiceItem,
  RentalInvoiceItem,
  RentalPassInvoiceItem,
} from '@cooltra/api';
import { Divider } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import * as Items from './Items';
import { InvoiceItemRowLayout } from './InvoiceItemRowLayout';
import messages from './messages';

export type InvoiceItemsProps = {
  items: InvoiceItem[];
  isACreditNote: boolean;
};

function isPackItem(item: InvoiceItem): item is PackInvoiceItem {
  return (item as PackInvoiceItem).type === 'PACK';
}

function isRentalPassItem(item: InvoiceItem): item is RentalPassInvoiceItem {
  return (item as RentalPassInvoiceItem).type === 'RENTAL_PASS';
}

function isRentalItem(item: InvoiceItem): item is RentalInvoiceItem {
  return (item as RentalInvoiceItem).type === 'RENTAL';
}

function isManualItem(item: InvoiceItem): item is ManualInvoiceItem {
  return (item as ManualInvoiceItem).type === 'MANUAL';
}

export const InvoiceItems = ({ items, isACreditNote }: InvoiceItemsProps) => {
  const isStateColumnDisplayed = !isACreditNote;

  if (items.length === 0) {
    return null;
  }

  return (
    <div data-testid="INVOICE_ITEMS">
      <h6 className="mb-5">
        {isACreditNote ? (
          <FormattedMessage {...messages.refundedItems} />
        ) : (
          <FormattedMessage {...messages.invoiceItems} />
        )}
      </h6>
      <InvoiceItemRowLayout
        className="mb-2"
        chargedAt={
          <ListColumnLabel>
            <FormattedMessage {...messages.chargedAt} />
          </ListColumnLabel>
        }
        item={
          <ListColumnLabel>
            <FormattedMessage {...messages.item} />
          </ListColumnLabel>
        }
        total={
          <ListColumnLabel className="text-right">
            <FormattedMessage {...messages.totalPrice} />
          </ListColumnLabel>
        }
        price={
          <ListColumnLabel className="text-right">
            <FormattedMessage {...messages.totalCharged} />
          </ListColumnLabel>
        }
        state={isStateColumnDisplayed && <span />}
      />
      <ul>
        {items.map((item, i) => (
          <li key={item.id}>
            {isPackItem(item) ? (
              <Items.PackItem
                {...item}
                isStateColumnDisplayed={isStateColumnDisplayed}
              />
            ) : isRentalItem(item) ? (
              <Items.RentalItem
                {...item}
                isStateColumnDisplayed={isStateColumnDisplayed}
              />
            ) : isRentalPassItem(item) ? (
              <Items.RentalPassItem
                {...item}
                isStateColumnDisplayed={isStateColumnDisplayed}
              />
            ) : isManualItem(item) ? (
              <Items.ManualItem
                {...item}
                isStateColumnDisplayed={isStateColumnDisplayed}
              />
            ) : null}
            {i < items.length - 1 && <Divider />}
          </li>
        ))}
      </ul>
    </div>
  );
};
