import { defineMessages } from 'react-intl';

export default defineMessages({
  servicePoint: {
    defaultMessage: 'Service point',
  },
  modalTitle: {
    defaultMessage: 'Service point change',
  },
  modalContent: {
    defaultMessage:
      'Do you want to change just the service point or also clear the parking photo and geolocation?',
  },
  resetServicePointButton: {
    defaultMessage: 'Change service point',
  },
  resetEverything: {
    defaultMessage: 'Change and clear data',
  },
});
