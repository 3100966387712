import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '../../List';
import { ClearedSuspensionRowLayout } from '../ClearedSuspensionRowLayout';

import messages from './messages';

export const ClearedSuspensionsListHeader = () => (
  <ClearedSuspensionRowLayout
    type={
      <ListColumnLabel>
        <FormattedMessage {...messages.type} />
      </ListColumnLabel>
    }
    reason={
      <ListColumnLabel>
        <FormattedMessage {...messages.reason} />
      </ListColumnLabel>
    }
    clearance={
      <ListColumnLabel>
        <FormattedMessage {...messages.clearance} />
      </ListColumnLabel>
    }
  />
);
