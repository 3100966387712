import { Route, Routes, useParams, Navigate } from 'react-router-dom';
import { useUserQuery } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { SpinnerScreen } from '@cooltra/ui';

import {
  ErrorPage,
  Four0Three,
  SidebarLayout,
  UserRentals,
  UserReservations,
} from '~/common';

import { Sessions } from '../Sessions';
import { States } from '../States';
import { Profile } from '../Profile/Profile';
import { Billing } from '../Billing';
import { UserProblemReports } from '../UserProblemReports';
import { UserOverview } from '../UserOverview';
import { Suspensions } from '../Suspensions/Suspensions';
import { UserIncidents, IncidentsFiltersForm } from '../UserIncidents';

import { Sidebar, SidebarLoading } from './Sidebar';

export const Account = () => {
  const { id = '' } = useParams<'id'>();

  const {
    data: user,
    isError,
    error,
  } = useUserQuery(id, {
    staleTime: Infinity,
  });

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <SidebarLayout
      sidebar={user ? <Sidebar user={user} /> : <SidebarLoading />}
    >
      <Routes>
        <Route path="profile/*" element={<Profile user={user} />} />
        <Route path="states" element={<States state={user?.state} />} />
        <Route
          path="overview"
          element={user ? <UserOverview /> : <SpinnerScreen />}
        />
        <Route path="problem-reports" element={<UserProblemReports />} />
        <Route
          path="incidents"
          element={
            <IncidentsFiltersForm>
              <UserIncidents userId={id} />
            </IncidentsFiltersForm>
          }
        />
        <Route
          path="sessions"
          element={user ? <Sessions user={user} /> : <SpinnerScreen />}
        />
        <Route
          path="billing/*"
          element={
            user ? (
              <Billing
                userId={user.id}
                system={user.system}
                provider="COOLTRA"
              />
            ) : (
              <SpinnerScreen />
            )
          }
        />
        <Route path="reservations" element={<UserReservations />} />
        <Route path="rentals" element={<UserRentals />} />
        <Route
          path="suspensions"
          element={<Suspensions userId={id} userState={user?.state} />}
        />
        <Route path="*" element={<Navigate replace to="overview" />} />
      </Routes>
    </SidebarLayout>
  );
};
