import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
  },
  cancelUninvoicedCharge: {
    defaultMessage: 'Cancel uninvoiced charge',
  },
  areYouSureToCancel: {
    defaultMessage: 'Are you sure you want to cancel this uninvoiced charge?',
  },
});
