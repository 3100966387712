import { FormattedMessage, useIntl } from 'react-intl';
import { Vehicle, useTransferVehicleMutation, Location } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Button } from '@cooltra/ui';
import { AxiosError } from 'axios';
import { useToggle } from '@cooltra/hooks';

import { ChangeVehiclesLocationModal } from '~/common';
import { useNotification } from '~/hooks';
import { useVehiclePolling } from '~/libs/polling-data';

import { getErrorMessageForTransferringVehicle } from './errors';
import messages from './messages';

export type TransferVehicleProps = Pick<Vehicle, 'vehicleId' | 'system'>;

export const TransferVehicle = ({
  vehicleId,
  system,
}: TransferVehicleProps) => {
  const { activatePollingVehicle } = useVehiclePolling();
  const { hasPermission } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const [isOpen, { toggleOff, toggleOn }] = useToggle();

  const { isPending, mutate } = useTransferVehicleMutation(vehicleId);

  const transferVehicleToNewLocation = (newLocation: Location) => {
    toggleOff();

    const handleOnError = (error: AxiosError) => {
      const errorMessageFromStatusCode = getErrorMessageForTransferringVehicle(
        error.response
      );

      addErrorNotification(
        errorMessageFromStatusCode.avoidTranslation
          ? errorMessageFromStatusCode.defaultMessage
          : formatMessage(errorMessageFromStatusCode)
      );
    };

    const handleOnSuccess = () => {
      addSuccessNotification(
        formatMessage(messages.successOnTransferringTheVehicle)
      );
      activatePollingVehicle(vehicleId);
    };

    mutate(
      {
        locationId: newLocation.locationId,
      },
      {
        onError: handleOnError,
        onSuccess: handleOnSuccess,
      }
    );
  };

  return (
    <div data-testid="TRANSFER_VEHICLE">
      {hasPermission('transfer:vehicles') ? (
        <>
          <ChangeVehiclesLocationModal
            isOpen={isOpen}
            title={formatMessage(messages.changeLocation)}
            systems={[system]}
            onClose={toggleOff}
            onSave={transferVehicleToNewLocation}
            loading={isPending}
          />
          <div className="flex gap-4">
            <Button
              size="sm"
              emphasis="high"
              loading={isPending}
              disabled={isPending}
              onClick={toggleOn}
            >
              <FormattedMessage {...messages.changeLocation} />
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};
