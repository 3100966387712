import { ReactNode } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { RentalTimelineEvent } from '@cooltra/api';
import { MdPause, MdPlayArrow } from 'react-icons/md';

import { RentalStateBadge, RoundOperatorInitials } from '~/common';
import { calculateTimeDifference } from '~/libs/time-difference/calculate-time-difference';

import { RentalTimelineRowLayout } from '../RentalTimelineRowLayout';
import { RentalTimelineDot } from '../RentalTimelineDot';

import messages from './messages';

export type RentalTimelineItemProps = {
  event: RentalTimelineEvent;
  occurredOn: string;
  nextEventOccurredOn?: string;
  isLast: boolean;
  closedBy?: string;
};

export const EventItem = ({
  event,
  occurredOn,
  nextEventOccurredOn,
  isLast,
  closedBy,
}: RentalTimelineItemProps) => {
  let eventComponent: ReactNode;

  switch (event) {
    case 'RentalStarted':
      eventComponent = <RentalStateBadge state="ACTIVE" />;
      break;
    case 'RentalPaused':
      eventComponent = (
        <>
          <MdPause />
          <FormattedMessage {...messages.rentalPaused} />
        </>
      );
      break;
    case 'RentalResumed':
      eventComponent = (
        <>
          <MdPlayArrow />
          <FormattedMessage {...messages.rentalResumed} />
        </>
      );
      break;
    case 'RentalCompleted':
      eventComponent = <RentalStateBadge state="COMPLETED" />;
      break;
    case 'RentalClosed':
      eventComponent = (
        <div
          data-testid="CLOSED_BY_OPERATOR"
          className="flex items-center gap-2"
        >
          <RentalStateBadge state="CLOSED" />
          <span className="text-danger-800"> by </span>
          {closedBy && <RoundOperatorInitials operatorId={closedBy} />}
        </div>
      );
      break;
  }

  const getDifferenceWithTheNextEvent = () => {
    if (
      event === 'RentalCompleted' ||
      event === 'RentalClosed' ||
      !nextEventOccurredOn
    ) {
      return <></>;
    }

    const timeDifference = calculateTimeDifference(
      new Date(occurredOn),
      new Date(nextEventOccurredOn)
    );

    return (
      <div className="relative">
        <span className="absolute text-xs text-neutral-600 bg-neutral-0 -translate-x-1/2 whitespace-nowrap">
          {timeDifference}
        </span>
      </div>
    );
  };

  return (
    <div className="mb-8">
      <RentalTimelineRowLayout
        time={
          <span className="text-sm tabular-nums font-semibold text-neutral-800">
            <FormattedTime
              value={occurredOn}
              hour="2-digit"
              hourCycle="h23"
              minute="2-digit"
            />
          </span>
        }
        timeline={<RentalTimelineDot />}
        content={
          <div className="flex gap-1 items-center text-sm font-semibold">
            {eventComponent}
          </div>
        }
        isLast={isLast}
      />
      <RentalTimelineRowLayout timeline={getDifferenceWithTheNextEvent()} />
    </div>
  );
};
