import { defineMessages } from 'react-intl';

export default defineMessages({
  dateOfBirth: {
    defaultMessage: 'Date of birth',
  },
  firstName: {
    defaultMessage: 'First name',
  },
  lastName: {
    defaultMessage: 'Last name',
  },
  system: {
    defaultMessage: 'Home system',
  },
  language: {
    defaultMessage: 'Language',
  },
  email: {
    defaultMessage: 'Email',
  },
  mobilePhone: {
    defaultMessage: 'Mobile phone',
  },
});
