import { VehicleProblemReportListItem } from '@cooltra/api';
import { classNames } from '@cooltra/utils';
import { RouterCardLink } from '@cooltra/navigation';

import { DateTime, Context, ProblemReportCardInfo, ByUser } from '~/common';

import { VehicleProblemReportRowLayout } from '../VehicleProblemReportRowLayout';

export type VehicleProblemReportCardProps = {
  problemReport: VehicleProblemReportListItem;
};

export const VehicleProblemReportCard = ({
  problemReport: {
    problemReportId,
    tags,
    description,
    open,
    numberImages,
    rentalId,
    reservationId,
    createdAt,
    createdBy,
  },
}: VehicleProblemReportCardProps) => (
  <RouterCardLink
    isMultiAction
    to={`/problem-reports/${problemReportId}`}
    className={classNames(open ? 'opacity-100' : 'opacity-50')}
  >
    <VehicleProblemReportRowLayout
      className="min-h-28 py-6"
      problem={
        <ProblemReportCardInfo
          open={open}
          numberImages={numberImages}
          description={description}
          tags={tags}
        />
      }
      context={<Context rentalId={rentalId} reservationId={reservationId} />}
      created={
        <div>
          <DateTime date={createdAt} />
          <ByUser user={createdBy} />
        </div>
      }
    />
  </RouterCardLink>
);
