import { useIntl } from 'react-intl';
import { Form, FormProvider, FormProviderProps } from '@cooltra/form';

import { AccessoryPriceFormValues } from './form-details';
import { validateForm } from './form-validations';

export type AccessoryPriceFormProps = Omit<
  FormProviderProps<AccessoryPriceFormValues>,
  'validate'
>;

export const AccessoryPriceListDetailsForm = ({
  onSubmit,
  initialValues,
  children,
}: AccessoryPriceFormProps) => {
  const intl = useIntl();

  return (
    <FormProvider
      initialValues={initialValues}
      validate={validateForm(intl)}
      onSubmit={onSubmit}
    >
      <Form>{children}</Form>
    </FormProvider>
  );
};
