import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';
import { useRevokeUserSessionsMutation, User } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import { ConfirmModal } from '~/common';
import { useNotification } from '~/hooks';

import messages from './messages';

export type RevokeSessionsProps = Pick<User, 'id'>;

export const RevokeSessions = ({ id }: RevokeSessionsProps) => {
  const { formatMessage } = useIntl();
  const [isModalOpen, { toggleOff, toggleOn }] = useToggle();

  const { addSuccessNotification, addErrorNotification } = useNotification();
  const { hasPermission } = useAuthClaimsQuery();

  const { mutateAsync, isPending } = useRevokeUserSessionsMutation(id);

  const revokeSessions = () => {
    mutateAsync()
      .then(() =>
        addSuccessNotification(
          formatMessage(messages.sessionsSuccessfullyRevoked)
        )
      )
      .catch(() => {
        addErrorNotification(formatMessage(messages.errorOnRevokeSessions));
      });
  };

  const handleConfirmingRevokingSessions = () => {
    toggleOff();
    revokeSessions();
  };

  return hasPermission('delete:sessions') ? (
    <>
      <ConfirmModal
        isOpen={isModalOpen}
        title={formatMessage(messages.revokeSessionsModalTitle)}
        content={formatMessage(messages.revokeSessionsModalContent)}
        onClose={toggleOff}
        onConfirm={handleConfirmingRevokingSessions}
        loading={isPending}
      />
      <Button emphasis="high" onClick={toggleOn}>
        <FormattedMessage {...messages.revokeSessions} />
      </Button>
    </>
  ) : (
    <></>
  );
};
