import { Money } from '@cooltra/utils';
import { isPast } from 'date-fns';

export const isCreditExpired = (expiresAt: string | null) =>
  !!expiresAt && isPast(new Date(expiresAt));

export const isCreditDeleted = (deletedAt: string | null) => !!deletedAt;

export const isCreditUsed = (remainingAmount: Money) =>
  remainingAmount.value === 0;
