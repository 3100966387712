import {
  Model,
  OperationalCondition,
  OperationalState,
  Telematics,
  VehicleListItem,
} from '@cooltra/api';
import { isBike } from '@cooltra/vehicle-utils';

import { BackgroundCircle } from './BackgroundCircle';
import { Battery } from './Battery';
import { Bike } from './Bike';
import { Motorbike } from './Motorbike';
import { Star } from './Star';

export type VehicleIconProps = Pick<
  VehicleListItem,
  'bonusEnabled' | 'model'
> & {
  operationalState: OperationalState | null | undefined;
  operationalConditions: OperationalCondition[] | null | undefined;
  batteryCharge: number | null | undefined;
  size?: number;
  telematics?: Telematics;
};

const isBikeFromModel = (model: Model) =>
  model === 'ARCADE_X2C' || model === 'SEGWAY_A300';

export const VehicleIcon = ({
  operationalConditions,
  operationalState,
  bonusEnabled,
  model,
  telematics,
  batteryCharge,
  size,
}: VehicleIconProps) => {
  const isABike = telematics ? isBike({ telematics }) : isBikeFromModel(model);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 144 144`}
    >
      <BackgroundCircle
        operationalState={operationalState}
        operationalConditions={operationalConditions}
      />
      <Battery batteryCharge={batteryCharge} model={model} />
      {isABike ? <Bike /> : <Motorbike />}
      {bonusEnabled && <Star />}
    </svg>
  );
};
