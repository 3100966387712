import { InputLabel } from '@cooltra/ui';
import { ChangeEventHandler, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PatternFormat } from 'react-number-format';

import messages from './messages';

import './date-range.css';

export type DateRangeProps = {
  onToChange: (toValue: string) => void;
  onFromChange: (fromValue: string) => void;
  toValue: string;
  fromValue: string;
  label: ReactNode;
};

const shouldCallOnChange = (value: string) => {
  const trimmedValue = value.replace(/_/g, '');
  return trimmedValue === '' || trimmedValue.length === 10;
};

export const DateRange = ({
  onFromChange,
  onToChange,
  toValue,
  fromValue,
  label,
}: DateRangeProps) => {
  const { formatMessage } = useIntl();

  const [innerFromValue, setFromValue] = useState(fromValue);
  const [innerToValue, setToValue] = useState(toValue);

  const handleFromChange: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setFromValue(target.value);
    if (shouldCallOnChange(target.value)) {
      onFromChange(target.value);
    }
  };

  const handleToChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setToValue(target.value);
    if (shouldCallOnChange(target.value)) {
      onToChange(target.value);
    }
  };

  useEffect(() => {
    if (shouldCallOnChange(fromValue)) {
      setFromValue(fromValue);
    }
    if (shouldCallOnChange(toValue)) {
      setToValue(toValue);
    }
  }, [toValue, fromValue]);

  return (
    <div className="flex flex-col">
      <span className="inline-block text-neutral-700 font-semibold text-base mb-1">
        {label}
      </span>
      <div className="inline-flex gap-4 custom-input-base custom-input custom-input--normal bg-neutral-0 overflow-hidden">
        <div className="inline-flex h-full items-baseline gap-2">
          <InputLabel htmlFor="min-date" className="mb-1">
            <FormattedMessage {...messages.from} />
          </InputLabel>
          <PatternFormat
            placeholder={formatMessage(messages.placeholder)}
            value={innerFromValue}
            className="date-range-inner-input h-full"
            id="min-date"
            onChange={handleFromChange}
            format="##-##-####"
            mask="_"
          />
        </div>
        <div className="inline-flex h-full items-baseline gap-2">
          <InputLabel htmlFor="max-date" className="mb-1">
            <FormattedMessage {...messages.to} />
          </InputLabel>
          <PatternFormat
            placeholder={formatMessage(messages.placeholder)}
            value={innerToValue}
            className="date-range-inner-input h-full"
            id="max-date"
            onChange={handleToChange}
            format="##-##-####"
            mask="_"
          />
        </div>
      </div>
    </div>
  );
};
