import { Rental } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { ScrollToTop } from '@cooltra/ui';

import { RentalInfo } from '../RentalInfo/RentalInfo';
import { UserInfo } from '../UserInfo/UserInfo';
import { OtherRentals } from '../OtherRentals';

import messages from './messages';

export type TrafficOffenseReportProps = {
  rental: Rental;
};

export const TrafficOffenseReport = ({ rental }: TrafficOffenseReportProps) => (
  <div
    className="container py-12 px-10 min-w-5xl max-w-6xl"
    data-testid="TRAFFIC_OFFENSE_REPORT"
  >
    <ScrollToTop />
    <h2 className="text-base text-neutral-600 mb-6">
      <FormattedMessage
        {...messages.rental}
        values={{ rentalId: rental.rentalId }}
      />
    </h2>
    <div className="mb-8">
      <RentalInfo rental={rental} />
    </div>
    <div className="mb-10">
      <UserInfo userId={rental.user.userId} />
    </div>
    <OtherRentals baseRental={rental} />
  </div>
);
