import { useCallback, useMemo, useState } from 'react';

export function usePagination<T>(dataItems: T[], itemsPerPage: number) {
  const [page, setPage] = useState(1);

  const goToNextPage = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
  }, []);

  const items = useMemo(
    () => dataItems.slice(0, itemsPerPage * page),
    [dataItems, itemsPerPage, page]
  );

  return {
    goToNextPage,
    items,
  };
}
