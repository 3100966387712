import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckboxField, InputLabel } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';

import { CooltraForEmployeesFormValues } from '../../Form';

import { ConsumableUntil } from './ConsumableUntil';
import messages from './messages';

export const ConsumableDate = () => {
  const {
    values: { editMode, consumableDoesNotExpire },
    setValues,
    isSubmitting,
  } = useFormContext<CooltraForEmployeesFormValues>();

  const handleChange = ({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setValues({
        consumableUntilDate: '',
        consumableUntilTime: '',
        consumableDoesNotExpire: checked,
      });
    } else {
      setValues({ consumableDoesNotExpire: checked });
    }
  };

  return (
    <div
      className="flex gap-x-8 items-start"
      data-testid="CONSUMABLE_VALIDITY_DATES"
    >
      <div className="w-52 mr-2" />
      <ConsumableUntil disabled={consumableDoesNotExpire || editMode} />
      <CheckboxField
        className="mt-8"
        id="consumable-expires"
        checked={consumableDoesNotExpire}
        label={
          <InputLabel emphasis="low" htmlFor="consumable-expires">
            <FormattedMessage {...messages.doesntExpire} />
          </InputLabel>
        }
        disabled={isSubmitting || editMode}
        onChange={handleChange}
      />
    </div>
  );
};
