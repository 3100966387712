import { Geofence, MultiPolygon, VehicleType } from '@cooltra/api';
import { Layer } from 'react-map-gl';

import { geofenceBorderLayer, geofenceLayer } from './layer';

type GeofencesMapSource = {
  id: string;
  layers: JSX.Element | JSX.Element[];
  layerId: string;
  data: any[];
};

export const getGeofenceMapSource = (
  geofence: MultiPolygon,
  vehicleType: VehicleType
): GeofencesMapSource => ({
  id: 'geofence',
  layerId: geofenceLayer.id as string,
  data: [
    {
      type: 'Feature',
      properties: {
        vehicleType,
      },
      geometry: geofence,
    },
  ],
  layers: [
    <Layer key="geofenceLayer" {...geofenceLayer} />,
    <Layer key="geofenceBorderLayer" {...geofenceBorderLayer} />,
  ],
});

export const getGeofenceMapSourceWithHomeSystem = (
  geofence: Geofence,
  homeSystem: string
): GeofencesMapSource => {
  const geofenceSourceId = `geofence-${homeSystem}`;
  const layerId = `${geofenceLayer.id}-${homeSystem}`;
  const geofenceLayerObjectData = {
    ...geofenceLayer,
    source: geofenceSourceId,
    id: layerId,
  };

  return {
    id: geofenceSourceId,
    layerId,
    data: [
      {
        type: 'Feature',
        properties: {},
        geometry: geofence.final,
      },
    ],
    layers: <Layer key="geofenceLayer" {...geofenceLayerObjectData} />,
  };
};
