import { defineMessages } from 'react-intl';

export default defineMessages({
  addDamage: {
    defaultMessage: 'Add damage',
  },
  vehiclePart: {
    defaultMessage: 'Vehicle part',
  },
  type: {
    defaultMessage: 'Damage type',
  },
  saveDamage: {
    defaultMessage: 'Add',
  },
});
