import { RouterCardLink } from '@cooltra/navigation';
import { WorkShiftListItem } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { Divider, Tooltip } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';

import { Circle, DateTime, ShiftOperator } from '~/common';

import { ShiftRowLayout } from '../ShiftRowLayout';

import { ShiftStats } from './ShiftStats';
import messages from './messages';
import { ShiftDuration } from './ShiftDuration';

export type ShiftCardProps = {
  shift: WorkShiftListItem;
};

export const ShiftCard = ({
  shift: {
    workShiftId,
    operatorId,
    createdAt,
    finishedAt,
    batterySwaps,
    vehiclesOperational,
    vehiclesWorked,
    energyAdded,
    closedTasks,
    averageEnergyAdded,
    automaticallyClosed,
    system,
    systems,
  },
}: ShiftCardProps) => (
  <RouterCardLink data-testid="SHIFT_CARD" to={`/shifts/${workShiftId}`}>
    <ShiftRowLayout
      className="min-h-28 py-4"
      operator={
        <ShiftOperator
          operatorId={operatorId}
          homeSystem={system}
          homeSystems={systems}
          operatorClassName="text-sm"
          roleClassName="text-neutral-400"
        />
      }
      duration={
        <div className="inline-block">
          {finishedAt && (
            <div className="relative flex flex-col items-center h-4 mb-2">
              <Divider className="absolute inset-y-0 my-auto" />
              <span className="relative bg-neutral-0 px-2">
                <ShiftDuration
                  createdAt={createdAt}
                  finishedAt={finishedAt}
                  className="text-sm text-neutral-1000 font-semibold"
                />
              </span>
            </div>
          )}
          <div className="flex items-center gap-3">
            <DateTime date={createdAt} />
            <div className="w-4">
              <Divider />
            </div>
            {finishedAt ? (
              <DateTime
                date={finishedAt}
                dateClassName={
                  automaticallyClosed ? 'text-warning-800 font-medium' : ''
                }
                timeClassName={automaticallyClosed ? 'text-warning-600' : ''}
              />
            ) : (
              <span className="font-semibold text-success-600">
                <FormattedMessage {...messages.inProgress} />
              </span>
            )}

            {automaticallyClosed && (
              <div className="flex">
                <Tooltip
                  button={
                    <Circle
                      className={classNames(
                        'w-4 h-4 outline outline-1 outline-neutral-600 text-neutral-600 text-sm'
                      )}
                    >
                      !
                    </Circle>
                  }
                  content={
                    <p className="py-2 px-3 text-sm text-neutral-600">
                      <FormattedMessage
                        {...messages.automaticallyClosedShift}
                      />
                    </p>
                  }
                />
              </div>
            )}
          </div>
        </div>
      }
      summary={
        <ShiftStats
          summary={{
            batterySwaps,
            vehiclesOperational,
            vehiclesWorked,
            energyAdded,
            closedTasks,
            averageEnergyAdded,
          }}
        />
      }
    />
  </RouterCardLink>
);
