import { Image } from '@cooltra/ui';
import { useVehicleParkingPhoto } from '@cooltra/station-based-api';

export type ParkingPhotoProps = {
  imageId: string;
};

export const ParkingPhoto = ({ imageId }: ParkingPhotoProps) => {
  const fetchedImageData = useVehicleParkingPhoto(imageId);

  const imageData = fetchedImageData
    ? `data:image/jpeg;base64,${fetchedImageData}`
    : '';

  return (
    <Image
      key={imageId}
      src={imageData}
      alt=""
      data-testid="PARKING_PHOTO"
      className="h-full w-full object-cover absolute"
    />
  );
};
