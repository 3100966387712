export const invalidEmailDomains = [
  '@.com.com',
  '@2verizon.net',
  '@66gmail.com',
  '@aaol.co',
  '@aho.com',
  '@ail.com',
  '@ain.com',
  '@ain.con',
  '@alo.cl',
  '@alo.com',
  '@alook.com',
  '@aol.cim',
  '@aol.clm',
  '@aol.cm',
  '@aol.co',
  '@aol.col',
  '@aol.coma',
  '@aol.comcom.com',
  '@aol.comi',
  '@aol.coml',
  '@aol.comm',
  '@aol.comomega',
  '@aol.con',
  '@aol.cpm',
  '@aol.icloud',
  '@aol.net',
  '@aol.om',
  '@aol.vom',
  '@aom.com',
  '@aotlook.com',
  '@autlook.dk',
  '@ayet.sk',
  '@blueyander.co.uk',
  '@blueyondet.co.uk',
  '@btinterent',
  '@btintermet',
  '@cloud.com',
  '@cocast.net',
  '@comast.net',
  '@comcasr.net',
  '@comcat.net',
  '@comcsst.net',
  '@concast.net',
  '@essoskei.no',
  '@fhmail.com',
  '@flgmail.com',
  '@fmail.com',
  '@g.ail.co',
  '@g.com',
  '@g.mail.co',
  '@g.mail.com',
  '@g4mail.com',
  '@gai.com',
  '@gail.co',
  '@gail.com',
  '@gamail.com',
  '@gamil..com',
  '@gamil.co',
  '@gamil.com',
  '@gamil.con',
  '@gamil.vom',
  '@gamim.com',
  '@geamil.com',
  '@geemail.com',
  '@gemail.com',
  '@ggmail.com',
  '@ghmail.com',
  '@gimai.com',
  '@gimal.com',
  '@glail.com',
  '@glaio.com',
  '@gma.comil',
  '@gma.il.com',
  '@gma8l.com',
  '@gmaail.com',
  '@gmael.com',
  '@gmai.co',
  '@gmai.com',
  '@gmai.l.com',
  '@gmaiil.com',
  '@gmaik.com',
  '@gmail.c',
  '@googlemail',
  '@gmail.ccom',
  '@gmail.ci',
  '@gmail.cim',
  '@gmail.cin',
  '@gmail.ck',
  '@gmail.ckm',
  '@gmail.clm',
  '@gmail.cln',
  '@gmail.cm',
  '@gmail.co',
  '@gmail.cok',
  '@gmail.col',
  '@gmail.com.com',
  '@gmail.com413',
  '@gmail.com4438247082',
  '@gmail.com7',
  '@gmail.com88221974',
  '@gmail.com9',
  '@gmail.coma',
  '@gmail.comb',
  '@gmail.comc',
  '@gmail.comcasa',
  '@gmail.comcom',
  '@gmail.comd',
  '@gmail.come',
  '@gmail.comf',
  '@gmail.comh',
  '@gmail.comi',
  '@gmail.comil.com',
  '@gmail.cominn',
  '@gmail.comk',
  '@gmail.coml',
  '@gmail.comm',
  '@gmail.comn',
  '@gmail.como',
  '@gmail.comom',
  '@gmail.comp',
  '@gmail.compp',
  '@gmail.comr',
  '@gmail.coms',
  '@gmail.comt',
  '@gmail.comvan1982',
  '@gmail.comw',
  '@gmail.comy',
  '@gmail.con',
  '@gmail.conm',
  '@gmail.coom',
  '@gmail.cp',
  '@gmail.cpm',
  '@gmail.dom',
  '@gmail.don',
  '@gmail.eu',
  '@gmail.fo',
  '@gmail.fom',
  '@gmail.gl',
  '@gmail.gmail.com',
  '@gmail.gom',
  '@gmail.lcom',
  '@gmail.net',
  '@gmail.om',
  '@gmail.org',
  '@gmail.ov',
  '@gmail.ru',
  '@gmail.som',
  '@gmail.stockholm.se',
  '@gmail.vo',
  '@gmail.vom',
  '@gmail.x',
  '@gmail.xo',
  '@gmail.xom',
  '@gmail1994.com',
  '@gmailc.om',
  '@gmaild.com',
  '@gmailk.com',
  '@gmaill.com',
  '@gmaill.comm',
  '@gmain.com',
  '@gmaio.com',
  '@gmaip.com',
  '@gmaiul.org',
  '@gmaiø.com',
  '@gmakil.com',
  '@gmal',
  '@gmal.co',
  '@gmal.com',
  '@gmali',
  '@gmali.com',
  '@gmalil.com',
  '@gmall.com',
  '@gmamail.com',
  '@gmaul.com',
  '@gmayle.com',
  '@gmei.com',
  '@gmeil',
  '@gmeil.com',
  '@gmial.com',
  '@gmiel.com',
  '@gmil.com',
  '@gmil.cw',
  '@gmile.com',
  '@gml.com',
  '@gmmail.com',
  '@gmqil',
  '@gmqil.com',
  '@gmsil.com',
  '@gmsil.vom',
  '@gmsol.com',
  '@gnail.com',
  '@gnail.comm',
  '@gnail.con',
  '@gnailc.om',
  '@googlemailc.om',
  '@gotmail.com',
  '@hitmail.com',
  '@hitmail.dk',
  '@hitmail.se',
  '@hltmail.fr',
  '@hmail.com',
  '@hmali.com',
  '@hmial.com',
  '@hoail.com',
  '@homail.com',
  '@homail.dk',
  '@homail.fr',
  '@homail.no',
  '@homtail.com',
  '@homtail.fr',
  '@hootmail.com',
  '@hoptmail.co.uk',
  '@hormail.com',
  '@hormail.dk',
  '@hormail.no',
  '@hormail.se',
  '@hotail.com',
  '@hotail.fr',
  '@hotamial',
  '@hotamil.com',
  '@hotemail.com',
  '@hotgmail.com',
  '@hotimail.com',
  '@hotlail.fr',
  '@hotlook.com',
  '@hotma8l.com',
  '@hotmai.',
  '@hotmai.co.uk',
  '@hotmai.com',
  '@hotmai.coml',
  '@hotmail.ccom',
  '@hotmail.cim',
  '@hotmail.clm',
  '@hotmail.co',
  '@hotmail.cocom',
  '@hotmail.cok',
  '@hotmail.coma',
  '@hotmail.comk',
  '@hotmail.coml',
  '@hotmail.comm',
  '@hotmail.commv',
  '@hotmail.como',
  '@hotmail.con',
  '@hotmail.conm',
  '@hotmail.itt',
  '@hotmail.ocm',
  '@hotmail.tr',
  '@hotmail.vo.uk',
  '@hotmail.vom',
  '@hotmail.xom',
  '@hotmailc.',
  '@hotmailii.com',
  '@hotmaill.com',
  '@hotmaio.com',
  '@hotmaip.com',
  '@hotmal.no',
  '@hotmaol',
  '@hotmaul.com',
  '@hotmial.com',
  '@hotmil.com',
  '@hotmmal.com',
  '@hotmsil',
  '@hotmsil.com',
  '@hotmzil.com',
  '@hotnail.co',
  '@hotnail.co.uk',
  '@hotnail.com',
  '@hotnail.dk',
  '@hotrmail.com',
  '@hotsmail.com',
  '@houtlook.com',
  '@hoymail',
  '@hptmail',
  '@htmail.com',
  '@htotmail.com',
  '@hutchel.net',
  '@i.cloud.com',
  '@ickoud.com',
  '@iclaud.com',
  '@iclloud.com',
  '@icload.com',
  '@iclod.com',
  '@iclode.com',
  '@icloed.com',
  '@icloed.con',
  '@icloid.com',
  '@iclon.com',
  '@iclook.com',
  '@iclooud.com',
  '@iclou.com',
  '@icloud.am',
  '@icloud.c',
  '@icloud.ca',
  '@icloud.cim',
  '@icloud.cin',
  '@icloud.ck',
  '@icloud.ckm',
  '@icloud.clm',
  '@icloud.cm',
  '@icloud.co.m',
  '@icloud.cok',
  '@icloud.col',
  '@icloud.comc',
  '@icloud.come',
  '@icloud.comicloud.com',
  '@icloud.comm',
  '@icloud.como',
  '@icloud.comom',
  '@icloud.comp',
  '@icloud.coms',
  '@icloud.con',
  '@icloud.coom',
  '@icloud.cpm',
  '@icloud.net',
  '@icloud.vo',
  '@icloud.vom',
  '@icloud.xo',
  '@icloud.xom',
  '@icloudd.com',
  '@icloude.com',
  '@iclouds.com',
  '@icloue.com',
  '@iclouf.com',
  '@iclould.com',
  '@iclour.com',
  '@iclouud.com',
  '@iclouus.com',
  '@icloyd.com',
  '@iclpud.com',
  '@iclud.com',
  '@icluod.com',
  '@icluoud.com',
  '@icmoud.com',
  '@icoloud.com',
  '@icolud.com',
  '@icooud.com',
  '@icoud.com',
  '@icould.com',
  '@ilcloud.com',
  '@ilcoud.com',
  '@ilcpud.com',
  '@imail.com',
  '@ioud.co',
  '@iverizon.net',
  '@ivloud.com',
  '@ivloyd.com',
  '@ixloud.com',
  '@jotmail',
  '@jubii.djk',
  '@lide.com',
  '@liive.no',
  '@live.cim',
  '@live.cm.mx',
  '@live.co.mx',
  '@live.con',
  '@live.om',
  '@live.pm',
  '@live.ss',
  '@livr.dk',
  '@livr.se',
  '@livse.se',
  '@love.com.mx',
  '@luve.com',
  '@mail.ck',
  '@me.con',
  '@msn.cam',
  '@msnc.om',
  '@ntlword.com',
  '@ocloud.com',
  '@oitlook',
  '@ol.com',
  '@omline.no',
  '@onlin.no',
  '@online.mo',
  '@online.ni',
  '@ootlook.com',
  '@otlook.com',
  '@otmail.com',
  '@oullook.com',
  '@oulook.com',
  '@oulook.dk',
  '@oultook.com',
  '@oultook.fr',
  '@ourlook.com',
  '@outkook.com',
  '@outkook.fr',
  '@outlet.ca',
  '@outliokk.ck',
  '@outlok.com',
  '@outlokk.com',
  '@outlokk.fr',
  '@outloo.com',
  '@outlook.ca',
  '@outlook.comi',
  '@outlook.comm',
  '@outlook.comp',
  '@outlook.con',
  '@outlook.om',
  '@outlook.vom',
  '@outloook',
  '@outlouk.com',
  '@outlouk.fr',
  '@outook.co',
  '@outook.com',
  '@outrlook.com',
  '@ouylook.com',
  '@owl.com',
  '@putlook.com',
  '@rmail.com',
  '@seynam.cy',
  '@seynam.cz',
  '@stavamgerskolen.no',
  '@tahoo.com',
  '@uahoo.com',
  '@uahoo.dk',
  '@uahoo.fr',
  '@uahoo.se',
  '@uotlook.com',
  '@utlook.com',
  '@veriaon.net',
  '@veriizon.net',
  '@veriozon.net',
  '@veririzon.net',
  '@verizin.net',
  '@verizn.net',
  '@verizo.net',
  '@verizon.netn.net',
  '@verizon.nt',
  '@verzion.net',
  '@wannadoo.fr',
  '@yahho.com',
  '@yahho.fr',
  '@yahho.no',
  '@yahho.se',
  '@yahhoo.com',
  '@yaho.com',
  '@yahoo.c',
  '@yahoo.ccom',
  '@yahoo.cim',
  '@yahoo.cin',
  '@yahoo.ckm',
  '@yahoo.col',
  '@yahoo.com.mc',
  '@yahoo.com9',
  '@yahoo.coma',
  '@yahoo.comc',
  '@yahoo.comcom',
  '@yahoo.comd',
  '@yahoo.come',
  '@yahoo.coml',
  '@yahoo.comm',
  '@yahoo.comn',
  '@yahoo.como',
  '@yahoo.comp',
  '@yahoo.comr',
  '@yahoo.comse',
  '@yahoo.con',
  '@yahoo.conm',
  '@yahoo.coom',
  '@yahoo.cpm',
  '@yahoo.cs',
  '@yahoo.cub',
  '@yahoo.fom',
  '@yahoo.gmail',
  '@yahoo.gov',
  '@yahoo.om',
  '@yahoo.v',
  '@yahoo.vo.uk',
  '@yahoo.vom',
  '@yahoo.xa',
  '@yahoo.xn--com-pma',
  '@yahoo.xom',
  '@yahoo07.com',
  '@yahoogmail.com',
  '@yahoomcom.tw',
  '@yahooo.com',
  '@yahou',
  '@yajoo.con',
  '@yaoo.com',
  '@yaoot.fr',
  '@yhaoo.com',
  '@yhaoo.con',
  '@yhoo.com',
  '@yhoo.dk',
  '@yhop.com',
  '@ymail.cim',
  '@ymaill.com',
  '@yopmail.com',
  '@youpmail.com',
  '@yshoo',
  '@yshoo.com',
  '@yyahoo.com.br',
];

export const isInvalidEmailDomain = (email: string) => {
  return invalidEmailDomains.some((domain) => email.endsWith(domain));
};
