import { FormattedMessage } from 'react-intl';
import { MdPowerSettingsNew } from 'react-icons/md';

import messages from './messages';
import { StatusContainer } from './StatusContainer';

type PowerProps = {
  off?: boolean | undefined;
};

export const Power = ({ off }: PowerProps) => {
  const getContent = () => {
    if (off === undefined) {
      return <></>;
    }

    if (off) {
      return (
        <>
          <MdPowerSettingsNew className="w-auto h-5 text-success-600" />
          <FormattedMessage {...messages.off} />
        </>
      );
    }

    return (
      <>
        <MdPowerSettingsNew className="w-auto h-5 text-neutral-200" />
        <span className="text-neutral-300">
          <FormattedMessage {...messages.on} />
        </span>
      </>
    );
  };

  return <StatusContainer>{getContent()}</StatusContainer>;
};
