import { ProblemReportColumnLabel } from '~/common';

import { VehicleProblemReportRowLayout } from './VehicleProblemReportRowLayout';

export const VehicleProblemReportsListHeader = () => (
  <VehicleProblemReportRowLayout
    problem={<ProblemReportColumnLabel name="problem" />}
    context={<ProblemReportColumnLabel name="context" />}
    created={<ProblemReportColumnLabel name="created" />}
  />
);
