import { useIntl } from 'react-intl';
import { FormRadioGroupField, useFormContext } from '@cooltra/form';
import { PromotionTarget } from '@cooltra/station-based-api';

import { StationBasedFormValues } from '../../StationBasedForm';

import messages from './messages';

export const PromotionTargetSelector = () => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext<StationBasedFormValues>();
  const { editMode } = values;
  const promotionTargetList: PromotionTarget[] = ['COUPON', 'WEB_OFFER'];

  return (
    <FormRadioGroupField
      name="promotionTarget"
      innerClassName="flex flex-row gap-6"
      legend={<></>}
      disabled={editMode}
      items={promotionTargetList.map((promotionTarget) => ({
        value: promotionTarget,
        label: formatMessage(messages[promotionTarget]),
      }))}
    />
  );
};
