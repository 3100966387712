import { RentalState } from '@cooltra/api';
import { defineMessages } from 'react-intl';

export default defineMessages({
  state: {
    defaultMessage: 'State',
  },
  placeholder: {
    defaultMessage: 'All',
  },
});

export const stateLabels = defineMessages<RentalState>({
  COMPLETED: {
    defaultMessage: 'Completed',
  },
  CLOSED: {
    defaultMessage: 'Closed',
  },
  ACTIVE: {
    defaultMessage: 'Active',
  },
});
