import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteBtn: {
    defaultMessage: 'Delete this user',
  },
  success: {
    defaultMessage: 'You have successfully deleted the user!',
  },
  userCannotBeDeleted: {
    defaultMessage: 'This user cannot be deleted',
  },
  uninvoicedCharges: {
    defaultMessage: 'Uninvoiced charges',
  },
  unpaidInvoices: {
    defaultMessage: 'Unpaid invoices',
  },
  warning: {
    defaultMessage: 'Warning',
  },
  uninvoicedChargesLink: {
    defaultMessage: 'Go to uninvoiced charges',
  },
  invoicesLink: {
    defaultMessage: 'Go to invoices',
  },
});
