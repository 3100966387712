import { useToggle } from '@cooltra/hooks';
import { Button, Modal } from '@cooltra/ui';
import {
  Contract,
  ContractPayment,
  useContractPaymentsQuery,
  usePayTicketContractMutation,
} from '@cooltra/station-based-api';
import { getErrorStatus } from '@cooltra/axios';
import { Form, FormProvider } from '@cooltra/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';

import { useNotification } from '~/hooks';
import { useWaitAnnexToBePaidPolling } from '~/components/Contract/PayTicketButton/useWaitAnnexToBePaidPolling';

import { usePayTicketPolling } from './usePayTicketPolling';
import { PayTicketFormValues } from './PayTicketFormValues';
import { PayTicketForm } from './PayTicketModal/PayTicketForm';
import messages from './messages';

export type PayTicketButtonProps = {
  disabled: boolean;
  contract: Contract;
  payment?: ContractPayment;
};

export const PayTicketButton = ({
  disabled,
  contract: { contractId, hasLinkedPaymentMethod, pendingAmount, details },
}: PayTicketButtonProps) => {
  const { formatMessage } = useIntl();
  const [isModalOpen, { toggleOff, toggleOn }] = useToggle();
  const queryClient = useQueryClient();
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const { data: payments } = useContractPaymentsQuery(contractId, {
    enabled: false,
  });
  const ticketId = payments?.find(
    (payment) => payment.status === 'PENDING'
  )?.id;
  const { mutateAsync, reset, isPending } = usePayTicketContractMutation(
    contractId,
    ticketId || ''
  );

  const initialPayTicketFormValues: PayTicketFormValues = {
    paymentMethod: '',
    operationNumber: null,
    lastFourDigits: null,
    confirmed: false,
    billingServicePointId: details!.servicePointId,
  };

  const {
    isPolling: isWaitAnnexToBePaidPollingPending,
    setIsWaitAnnexToBePaidPollingActive,
  } = useWaitAnnexToBePaidPolling({
    onSettle: () => {
      setIsWaitAnnexToBePaidPollingActive(false);
    },
    contractId,
  });

  const { isPolling: isPayTicketPollingPending, setIsStripePollingActive } =
    usePayTicketPolling({
      onSettle: () => {
        queryClient.invalidateQueries({ queryKey: ['contract', contractId] });
        reset();
        toggleOff();

        if (ticketId === undefined) {
          addSuccessNotification(
            formatMessage(messages.paymentDoneSuccessfully)
          );
        } else {
          addErrorNotification();
        }

        setIsWaitAnnexToBePaidPollingActive(true);
        setIsStripePollingActive(false);
      },
      contractId,
      ticketId: ticketId || '',
    });

  const isPolling =
    isPayTicketPollingPending || isWaitAnnexToBePaidPollingPending;

  const handleOnSubmit = (values: PayTicketFormValues) => {
    if (values.paymentMethod === '') return Promise.resolve();

    return mutateAsync({
      paymentMethod: values.paymentMethod,
      operationNumber:
        values.paymentMethod === 'TPV' ? values.operationNumber : null,
      lastFourDigits:
        values.paymentMethod === 'TPV' ? values.lastFourDigits : null,
      billingServicePointId: values.billingServicePointId,
    })
      .then(() => {
        if (values.paymentMethod === 'CARD') {
          setIsStripePollingActive(true);
          return;
        } else {
          setIsWaitAnnexToBePaidPollingActive(true);
        }

        toggleOff();
        addSuccessNotification(formatMessage(messages.paymentDoneSuccessfully));
      })
      .catch((error) => {
        const errorStatus = getErrorStatus(error);
        addErrorNotification(
          errorStatus && errorStatus < 500 && values.paymentMethod === 'CARD'
            ? formatMessage(messages.stripeChargeFailed)
            : undefined
        );
      });
  };

  return (
    <>
      <Modal isOpen={isModalOpen} onRequestClose={toggleOff}>
        <FormProvider<PayTicketFormValues>
          initialValues={initialPayTicketFormValues}
          onSubmit={handleOnSubmit}
        >
          <Form>
            <PayTicketForm
              contractId={contractId}
              hasLinkedPaymentMethod={hasLinkedPaymentMethod}
              amount={pendingAmount}
              isLoading={isPolling}
              closeModal={toggleOff}
            />
          </Form>
        </FormProvider>
      </Modal>
      <Button
        emphasis="high"
        onClick={toggleOn}
        disabled={isPending || isPolling || disabled}
        className="self-end"
      >
        <FormattedMessage {...messages.payTicket} />
      </Button>
    </>
  );
};
