import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { ShiftRowLayout } from '../ShiftRowLayout';

import messages from './messages';

export const ShiftsListHeader = () => (
  <ShiftRowLayout
    operator={
      <ListColumnLabel>
        <FormattedMessage {...messages.operator} />
      </ListColumnLabel>
    }
    duration={
      <ListColumnLabel>
        <FormattedMessage {...messages.duration} />
      </ListColumnLabel>
    }
    summary={<></>}
  />
);
