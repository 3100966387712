import { defineMessages } from 'react-intl';

export default defineMessages({
  close: {
    defaultMessage: 'Close task',
  },
  reopen: {
    defaultMessage: 'Reopen task',
  },
});
