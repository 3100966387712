import { defineMessages } from 'react-intl';

export default defineMessages({
  idleFor: {
    defaultMessage: 'Idle for {time}',
  },
  hours: {
    defaultMessage: '{hours, plural, one {# hour} other {# hours}}',
  },
  days: {
    defaultMessage: '{days, plural, one {# day} other {# days}}',
  },
});
