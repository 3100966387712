import { RouterBaseNavLink, RouterBaseNavLinkProps } from '@cooltra/navigation';
import { Tooltip } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { PropsWithChildren } from 'react';

export type ContractSidebarLinkProps = Omit<
  RouterBaseNavLinkProps,
  'children'
> &
  PropsWithChildren<{
    disabled?: boolean;
    warning?: boolean;
  }>;

export const ContractSidebarLink = ({
  children,
  warning = false,
  disabled = false,
  ...rest
}: ContractSidebarLinkProps) => {
  const ariaLabel = rest['aria-label'];

  return disabled ? (
    <button
      className="w-16 h-16 flex items-center justify-center text-neutral-100"
      disabled={true}
      aria-label={ariaLabel}
    >
      {children}
    </button>
  ) : (
    <Tooltip
      tooltipClassName="bg-neutral-600 text-neutral-0"
      popperOptions={{
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['right'],
            },
          },
        ],
      }}
      button={
        <RouterBaseNavLink
          aria-label={ariaLabel}
          className={({ isActive }) =>
            classNames(
              'w-16 h-16 flex items-center justify-center transition-all',
              'focus-visible:ring focus-visible:ring-inset focus-visible:ring-primary-200',
              'border-r-[6px] border-l-[6px] border-solid',
              isActive
                ? 'text-primary-500 border-l-primary-500'
                : 'text-neutral-400 border-l-transparent'
            )
          }
          {...rest}
        >
          {children}
          {!disabled && warning && (
            <div
              className="absolute right-2 top-2 bg-warning-500 rounded-full w-2.5 h-2.5"
              data-testid="SIDEBARLINK_WARNING"
            />
          )}
        </RouterBaseNavLink>
      }
      content={
        <div className="px-5 py-3 relative">
          <span className="text-neutral-0 font-semibold">{ariaLabel}</span>
        </div>
      }
    />
  );
};
