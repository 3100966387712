import { defineMessages } from 'react-intl';

export default defineMessages({
  rotateLeft: {
    defaultMessage: 'Rotate left',
  },
  rotateRight: {
    defaultMessage: 'Rotate right',
  },
  zoomIn: {
    defaultMessage: 'Zoom in',
  },
  zoomOut: {
    defaultMessage: 'Zoom out',
  },
  scaledImage: {
    defaultMessage: 'Scaled image',
  },
});
