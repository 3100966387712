import { defineMessages } from 'react-intl';

export default defineMessages({
  emailConfirmationSent: {
    defaultMessage: 'Confirmation email sent',
  },
  sendEmailConfirmation: {
    defaultMessage: 'Send confirmation email',
  },
  areYouSureToSendEmailConfirmation: {
    defaultMessage: 'Are you sure you want to send the confirmation email?',
  },
});
