import { defineMessages } from 'react-intl';

export default defineMessages({
  location: {
    defaultMessage: 'Location',
  },
  withoutLocation: {
    defaultMessage: 'No current activity',
  },
});
