import { Button } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';
import { useDeleteLocationMutation } from '@cooltra/api';
import { getZeusErrorMessage } from '@cooltra/axios';
import { useToggle } from '@cooltra/hooks';

import { useNotification } from '~/hooks';
import { DeleteModal } from '~/common';

import messages from './messages';

export const DeleteLocation = () => {
  const { formatMessage } = useIntl();

  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const navigate = useNavigate();
  const { locationId = '' } = useParams<'locationId'>();

  const { mutateAsync, isPending } = useDeleteLocationMutation(locationId);

  const handleConfirm = () =>
    mutateAsync()
      .then(() => {
        toggleOff();
        addSuccessNotification(formatMessage(messages.success));
        navigate('/settings/locations');
      })
      .catch((error) => {
        toggleOff();
        const zeusErrorMessage = getZeusErrorMessage(error);
        addErrorNotification(zeusErrorMessage);
      });

  return (
    <>
      <DeleteModal
        onClose={toggleOff}
        onConfirm={handleConfirm}
        isOpen={isOpen}
        isDeleting={isPending}
      />
      <Button variant="danger" emphasis="low" onClick={toggleOn}>
        <FormattedMessage {...messages.deleteBtn} />
      </Button>
    </>
  );
};
