import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
  },
  confirm: {
    defaultMessage: 'Confirm',
  },
  confirmation: {
    defaultMessage: 'Confirmation',
  },
  disclaimer: {
    defaultMessage:
      'Changes to the pickup point or the dates will reset the vehicle, license plate, coverage, and extras selections. Do you want to continue?',
  },
});
