import { useContext } from 'react';

import { PreferredSystemContext } from './PreferredSystemContext';

export const usePreferredSystem = () => {
  const preferredSystemContext = useContext(PreferredSystemContext);
  if (!preferredSystemContext) {
    throw new Error(
      'usePreferredSystem can be only used within <PreferredSystemProvider />'
    );
  }
  return {
    system: preferredSystemContext.system,
    updateSystem: preferredSystemContext.updateSystem,
  };
};
