import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Incident details',
  },
  editIncident: {
    defaultMessage: 'Save changes',
  },
  success: {
    defaultMessage: 'You have successfully edited the incident!',
  },
});
