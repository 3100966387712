import { defineMessages } from 'react-intl';

export default defineMessages({
  invoices: {
    defaultMessage: 'Invoices',
  },
  credits: {
    defaultMessage: 'Credits',
  },
  subscriptions: {
    defaultMessage: 'Subscriptions',
  },
  uninvoicedCharges: {
    defaultMessage: 'Uninvoiced charges',
  },
  discounts: {
    defaultMessage: 'Discounts',
  },
  rentalPasses: {
    defaultMessage: 'Rental passes',
  },
});
