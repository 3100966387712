import { defineMessages } from 'react-intl';

import { PromotionTypeMode } from '../../PromotionForm';

export default defineMessages<PromotionTypeMode>({
  credit: {
    defaultMessage: 'Credit',
  },
  discount: {
    defaultMessage: 'Discount',
  },
});
