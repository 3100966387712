import { useUserQuery } from '@cooltra/api';
import { RouterLink } from '@cooltra/navigation';
import { Placeholder } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type ByUserProps = Omit<HTMLAttributes<HTMLSpanElement>, 'id'> & {
  id?: string | null;
  user?: {
    id: string;
    name: string;
  };
};

export const ByUser = ({ id, user, className, ...rest }: ByUserProps) => {
  const { data } = useUserQuery(id || '', {
    enabled: !!id,
  });

  if (user) {
    if (!user.id || !user.name) {
      return <></>;
    }

    return (
      <span
        className={classNames(
          'text-sm',
          'text-neutral-500',
          'inline-flex',
          'max-w-full',
          className
        )}
        {...rest}
      >
        <span className="mr-1">
          <FormattedMessage {...messages.by} />
        </span>
        <RouterLink
          className="truncate font-medium"
          to={`/users/${user.id}/account/overview`}
        >
          {user.name}
        </RouterLink>
      </span>
    );
  }

  if (!id || !data) {
    return (
      <div className="h-5 flex items-center">
        <Placeholder className="h-2 w-20" />
      </div>
    );
  }

  return (
    <span
      className={classNames(
        'text-sm',
        'text-neutral-500',
        'inline-flex',
        'max-w-full',
        className
      )}
      {...rest}
    >
      <span className="mr-1">
        <FormattedMessage {...messages.by} />
      </span>
      <RouterLink
        className="truncate font-medium"
        to={`/users/${data.id}/account/overview`}
      >
        {data.name}
      </RouterLink>
    </span>
  );
};
