import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    defaultMessage: 'Subscription',
  },
  validFrom: {
    defaultMessage: 'From',
  },
  createdBy: {
    defaultMessage: 'Created by',
  },
  state: {
    defaultMessage: 'Status',
  },
});
