import { HTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import { CardBrand } from '@cooltra/api';

import messages from './messages';
import amex from './assets/amex.svg';
import diners from './assets/diners.svg';
import discover from './assets/discover.svg';
import mastercard from './assets/mastercard.svg';
import jcb from './assets/jcb.svg';
import unionPay from './assets/union-pay.svg';
import visa from './assets/visa.svg';

const cardLogos: { [key in CardBrand]: string } = {
  amex: amex,
  diners: diners,
  discover: discover,
  mastercard: mastercard,
  unionpay: unionPay,
  visa: visa,
  unknown: '',
  jcb: jcb,
};

export type CardLogoProps = Omit<
  HTMLAttributes<HTMLImageElement>,
  'src' | 'alt'
> & {
  brand: CardBrand;
};

export const CardLogo = ({ brand, ...rest }: CardLogoProps) => {
  const { formatMessage } = useIntl();
  const logo = cardLogos[brand];

  if (!logo) {
    return (
      <div
        aria-label={formatMessage(messages.unknown)}
        className="w-14 h-9 bg-neutral-600 rounded relative"
      >
        <div className="bg-neutral-0 absolute top-2 w-full h-2" />
      </div>
    );
  }

  return (
    <img
      alt={formatMessage(messages[brand])}
      src={cardLogos[brand]}
      {...rest}
    />
  );
};
