import {
  DailyContractsListItem,
  useContractsByDayQuery,
} from '@cooltra/station-based-api';
import { format, isToday } from 'date-fns';
import { Divider, List } from '@cooltra/ui';
import { useEffect, useRef } from 'react';

import { ErrorPage, NoSearchResults } from '~/common';

import { POSNavbar } from '../POSNavbar/POSNavbar';
import { useFilters } from '../useFilters';
import { ContractsSummary } from '../ContractsSummary/ContractsSummary';
import { POSLayout } from '../POSLayout/POSLayout';

import { ContractsByTime } from './ContractsByTime';

export const DailyView = () => {
  const timeRefs = useRef<Record<string, HTMLDivElement>>({});

  const getTimeRef = (time: string) => (node: HTMLDivElement) => {
    if (timeRefs.current) {
      timeRefs.current[time] = node;
    }
  };

  const filters = useFilters();
  const day = format(filters.date, 'yyyy-MM-dd');

  const { data, isLoading, isError } = useContractsByDayQuery({
    minDate: day,
    maxDate: day,
    serviceType: filters.serviceType || undefined,
    servicePoint: filters.servicePointId,
    status: filters.status,
  });

  const contractsWithTimeList: Record<string, DailyContractsListItem[]>[] =
    data?.[day]
      ? Object.entries(data[day]).map(([time, contracts]) => ({
          [time]: contracts,
        }))
      : [];

  useEffect(() => {
    if (isToday(new Date(day))) {
      const currentTime = format(new Date(), 'HH:mm');
      const currentTimeSlot = Object.keys(data?.[day] || {})
        .sort()
        .reverse()
        .find((time) => currentTime >= time);

      if (currentTimeSlot) {
        const ref = timeRefs.current[currentTimeSlot];
        if (ref) {
          ref.scrollIntoView();
        }
      }
    }
  }, [data, day]);

  if (isError) {
    return <ErrorPage />;
  }

  const vehicleTypes = data?.[day]
    ? Object.entries(data[day])
        .map((contractsByDay) => {
          const contracts = contractsByDay[1];

          return contracts
            .filter((contract) => contract.vehicleType?.name)
            .map((contract) => ({
              name: contract.vehicleType?.name || '',
              type: contract.serviceType,
            }));
        })
        .flat()
    : [];

  return (
    <POSLayout
      isLoading={isLoading}
      renderNavbar={<POSNavbar view="daily" />}
      renderSummary={<ContractsSummary vehicleTypes={vehicleTypes} />}
      renderContent={
        <div className="h-full overflow-scroll no-scrollbar pb-8">
          <List
            data={contractsWithTimeList}
            idProp="time"
            isLoading={isLoading}
            loadingRows={4}
            className="gap-0"
            renderEmptyMessage={<NoSearchResults />}
            renderRow={(data, index) => {
              const time = Object.keys(data)[0];
              const contracts = Object.values(data)[0];
              return (
                <div key={time} ref={getTimeRef(time)}>
                  <ContractsByTime time={time} contracts={contracts} />
                  {index !== contractsWithTimeList.length - 1 && (
                    <Divider className="my-4" />
                  )}
                </div>
              );
            }}
          />
        </div>
      }
    />
  );
};
