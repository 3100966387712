import { RouterBaseLink } from '@cooltra/navigation';
import { Badge } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type RentalIncidentsBadgeProps = {
  incidentIds: string[];
  rentalId: string;
};

export const RentalIncidentsBadge = ({
  incidentIds,
  rentalId,
}: RentalIncidentsBadgeProps) => {
  const { length } = incidentIds;

  if (!length) {
    return (
      <span className="text-sm text-neutral-300">
        <FormattedMessage {...messages.none} />
      </span>
    );
  }

  return (
    <RouterBaseLink to={`/rentals/${rentalId}/incidents`}>
      <Badge variant="warning">
        <FormattedMessage
          {...messages.incidents}
          values={{
            total: length,
          }}
        />
      </Badge>
    </RouterBaseLink>
  );
};
