import { FormattedMessage, useIntl } from 'react-intl';
import { FormSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { ContractServiceType } from '@cooltra/station-based-api';

import messages, { serviceTypesMessages } from './messages';

type ServiceTypeOption = {
  label: string;
  value: ContractServiceType | '';
};

export const ServiceTypeField = () => {
  const intl = useIntl();
  const serviceTypesOptions: ServiceTypeOption[] = [
    {
      label: intl.formatMessage(serviceTypesMessages.ALL),
      value: '',
    },
    {
      label: intl.formatMessage(serviceTypesMessages.PICK_UP),
      value: 'PICK_UP',
    },
    {
      label: intl.formatMessage(serviceTypesMessages.DROP_OFF),
      value: 'DROP_OFF',
    },
  ];

  return (
    <FormSelectField
      id="serviceType"
      name="serviceType"
      label={
        <InputLabel htmlFor="serviceType" className="shrink-0">
          <FormattedMessage {...messages.serviceType} />
        </InputLabel>
      }
      options={serviceTypesOptions}
      placeholder={intl.formatMessage(messages.all)}
    />
  );
};
