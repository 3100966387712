import { useMemo } from 'react';
import { VehicleListItem } from '@cooltra/api';

import { useVehiclesFilters } from '~/libs/vehicles-filters';
import {
  isVehicleAMatchForLegend,
  sortVehicles,
} from '~/libs/vehicles-filters/regular-filters/regular-filters';

export type ExtendedVehicleListItem = VehicleListItem & {
  isMatch: boolean;
};

export const useFilterVehiclesForLegend = (allVehicles: VehicleListItem[]) => {
  const { values } = useVehiclesFilters();

  const filteredData = useMemo(() => {
    const vehicles: ExtendedVehicleListItem[] = (allVehicles || []).map(
      (vehicle) => ({
        ...vehicle,
        isMatch: isVehicleAMatchForLegend(vehicle, values),
      })
    );
    const filteredVehicles: ExtendedVehicleListItem[] = vehicles.filter(
      ({ isMatch }) => isMatch
    );
    return {
      vehicles,
      filteredVehicles: sortVehicles(filteredVehicles, values),
      filteredVehiclesTotal: filteredVehicles.length,
    };
  }, [allVehicles, values]);

  return {
    data: filteredData,
  };
};
