import { Card } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { UserState } from '@cooltra/api';

import { UnitState } from '../UnitState/UnitState';

import messages from './messages';

export type TimelineStatesProps = {
  states: UserState[];
};

export const TimelineStates = ({ states }: TimelineStatesProps) => {
  const isLast = (index: number) => states.length - 1 === index;

  return (
    <Card className="p-14">
      {states.length > 0 ? (
        states.map((state, index) => (
          <UnitState
            key={state.createdAt}
            state={state}
            isLast={isLast(index)}
          />
        ))
      ) : (
        <p className={'text-center text-neutral-300'}>
          <FormattedMessage {...messages.thisUserHasNoStates} />
        </p>
      )}
    </Card>
  );
};
