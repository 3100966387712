import { classNames } from '@cooltra/utils';
import { ReactNode } from 'react';

type ConnectionBadgeStatus = 'loading' | 'online' | 'offline';

export type ConnectionBadgeProps = {
  children: ReactNode;
  status: ConnectionBadgeStatus;
};

const connectionBadgeClasses: { [key in ConnectionBadgeStatus]: string } = {
  loading: 'bg-neutral-100',
  online: 'bg-success-400',
  offline: 'bg-neutral-300',
};

export const ConnectionBadge = ({ children, status }: ConnectionBadgeProps) => (
  <span className="text-xs px-2 py-1 ml-2 flex items-center gap-1 justify-center rounded-xl align-middle border border-solid border-neutral-200 text-neutral-400">
    <span
      className={classNames(
        connectionBadgeClasses[status],
        'h-2',
        'w-2',
        'block',
        'rounded'
      )}
    />
    <span>{children}</span>
  </span>
);
