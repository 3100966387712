import { defineMessages } from 'react-intl';

export default defineMessages({
  all: {
    defaultMessage: 'All service points',
  },
  servicePoint: {
    defaultMessage: 'Service point',
  },
});
