import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Incidents',
  },
  addIncident: {
    defaultMessage: 'Create incident',
  },
});
