import { VehicleTaskListItem } from '@cooltra/api';
import { classNames } from '@cooltra/utils';
import { RouterCardLink } from '@cooltra/navigation';

import {
  OperatorOrGroup,
  ByOperator,
  DateTime,
  TaskCardTitle,
  TaskTags,
} from '~/common';

import { VehicleTaskRowLayout } from '../VehicleTaskRowLayout';

export type VehicleTaskCardProps = {
  task: Omit<VehicleTaskListItem, 'vehicle'>;
};

export const VehicleTaskCard = ({
  task: {
    id,
    blocking,
    operatorGroupId,
    operatorId,
    tagIds,
    title,
    updatedAt,
    updatedBy,
    createdAt,
    createdBy,
    open,
  },
}: VehicleTaskCardProps) => (
  <RouterCardLink
    to={`/tasks/${id}`}
    className={classNames(open ? 'opacity-100' : 'opacity-50')}
  >
    <VehicleTaskRowLayout
      className="min-h-28 py-4"
      task={
        <div data-testid={open ? 'OPEN_TASK' : 'CLOSED_TASK'}>
          <TaskCardTitle>{title}</TaskCardTitle>
          <TaskTags tagIds={tagIds} blocking={blocking} />
        </div>
      }
      assignee={
        <div className="text-sm text-neutral-400">
          <OperatorOrGroup
            operatorId={operatorId}
            operatorGroupId={operatorGroupId}
          />
        </div>
      }
      updated={
        updatedAt &&
        updatedBy && (
          <div>
            <DateTime date={updatedAt} />
            <ByOperator id={updatedBy} />
          </div>
        )
      }
      created={
        <div>
          <DateTime date={createdAt} />
          <ByOperator id={createdBy} />
        </div>
      }
    />
  </RouterCardLink>
);
