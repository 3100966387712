import { ChargeAttempt } from '@cooltra/api';
import { Divider } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { DateTime, ListColumnLabel } from '~/common';

import { ChargeAttemptRowLayout } from '../ChargeAttemptRowLayout';
import { ChargeAttemptState } from '../ChargeAttemptState/ChargeAttemptState';

import messages from './messages';

export type ChargeAttemptsProps = {
  chargeAttempts: ChargeAttempt[];
};

export const ChargeAttempts = ({ chargeAttempts }: ChargeAttemptsProps) => {
  if (chargeAttempts.length === 0) {
    return null;
  }

  return (
    <div data-testid="CHARGE_ATTEMPTS">
      <h6 className="mb-5">
        <FormattedMessage {...messages.chargeAttempts} />
      </h6>
      <ChargeAttemptRowLayout
        className="mb-2"
        updatedAt={
          <ListColumnLabel>
            <FormattedMessage {...messages.createdAt} />
          </ListColumnLabel>
        }
        state={
          <ListColumnLabel>
            <FormattedMessage {...messages.state} />
          </ListColumnLabel>
        }
        failureReason={
          <ListColumnLabel>
            <FormattedMessage {...messages.failureReason} />
          </ListColumnLabel>
        }
      />
      <ul>
        {chargeAttempts.map((chargeAttempt, i) => (
          <li key={chargeAttempt.updatedAt}>
            <ChargeAttemptRowLayout
              className="text-sm text-neutral-800 py-4"
              updatedAt={<DateTime date={chargeAttempt.updatedAt} />}
              state={<ChargeAttemptState state={chargeAttempt.state} />}
              failureReason={<span>{chargeAttempt.failureReason}</span>}
            />
            {i < chargeAttempts.length - 1 && <Divider />}
          </li>
        ))}
      </ul>
    </div>
  );
};
