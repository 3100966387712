import { InvoiceType as ApiInvoiceType } from '@cooltra/api';
import { HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type InvoiceTypeProps = Omit<
  HTMLAttributes<HTMLSpanElement>,
  'children'
> & {
  type: ApiInvoiceType;
};

export const InvoiceType = ({ type, ...rest }: InvoiceTypeProps) => (
  <span {...rest}>
    <FormattedMessage {...messages[type]} />
  </span>
);
