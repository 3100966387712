import { FormattedMessage } from 'react-intl';

import {
  ListColumnLabel,
  ListColumnLabelProps,
} from '../../List/ListColumnLabel/ListColumnLabel';

import messages from './messages';

export type RentalColumnLabelProps = Omit<ListColumnLabelProps, 'children'> & {
  name: keyof typeof messages;
};

export const RentalColumnLabel = ({
  name,
  ...rest
}: RentalColumnLabelProps) => (
  <ListColumnLabel {...rest}>
    <FormattedMessage {...messages[name]} />
  </ListColumnLabel>
);
