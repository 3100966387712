import { Tooltip } from '@cooltra/ui';
import { Marker } from 'react-map-gl';
import { classNames } from '@cooltra/utils';

import { Circle, WorkUnitInfo } from '~/common';

import { useShiftRouteCoordinates } from './shift-route-coordinates-provider';

import './route-work-units.css';

export const RouteWorkUnits = () => {
  const { workUnits } = useShiftRouteCoordinates();

  return (
    <>
      {workUnits.map((workUnit, workUnitPosition) => {
        const { vehicle, workUnitId, skipped } = workUnit;

        const longitude = Number(vehicle.location.before.longitude);
        const latitude = Number(vehicle.location.before.latitude);

        if (longitude === null || latitude === null) {
          return null;
        }

        return (
          <Marker key={workUnitId} longitude={longitude} latitude={latitude}>
            <Tooltip
              interactive
              appendTo={() => document.body}
              button={
                <Circle
                  className={classNames(
                    'w-6 h-6 text-neutral-50',
                    skipped ? 'bg-neutral-400' : 'bg-neutral-800'
                  )}
                >
                  {workUnitPosition + 1}
                </Circle>
              }
              content={
                <div className="w-80">
                  {workUnit.comment && <div id="work-unit-with-comment" />}
                  <WorkUnitInfo {...workUnit} />
                </div>
              }
            />
          </Marker>
        );
      })}
    </>
  );
};
