import { Route, Routes } from 'react-router-dom';

import { Four0Four } from '~/common';

import { ProblemReport } from './ProblemReport/ProblemReport';
import {
  ProblemReportsFiltersForm,
  ProblemReportsList,
} from './ProblemReportsList';

export const ProblemReports = () => (
  <Routes>
    <Route path="/:problemReportId/*" element={<ProblemReport />} />
    <Route
      path="/"
      element={
        <ProblemReportsFiltersForm>
          <ProblemReportsList />
        </ProblemReportsFiltersForm>
      }
    />
    <Route path="*" element={<Four0Four />} />
  </Routes>
);
