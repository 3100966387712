import { Incident, useIncidentsQuery, useIncidentsTotal } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { InfiniteList } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';
import { equal } from '@cooltra/utils';
import { useEffect } from 'react';

import {
  ErrorPage,
  FilterFields,
  Four0Three,
  IncidentsTotal,
  NoSearchResults,
  IncidentsListHeader,
  IncidentCard,
  IncidentCardLoading,
} from '~/common';
import { useNavigateWithQueryParams } from '~/hooks';

import {
  IncidentsFiltersFormValues,
  incidentsFiltersInitialValues,
} from './incidents-filters-form';
import { NoUserIncidents } from './NoUserIncidents/NoUserIncidents';

export type UserIncidentsProps = {
  userId: string;
};

export const UserIncidents = ({ userId }: UserIncidentsProps) => {
  const navigateWithQueryParams = useNavigateWithQueryParams();

  const { hasPermission } = useAuthClaimsQuery();

  const { values } = useFormContext<IncidentsFiltersFormValues>();

  const { data, isLoading, fetchNextPage, isFetchingNextPage, isError, error } =
    useIncidentsQuery({ ...values, userId });

  useEffect(() => {
    navigateWithQueryParams(values);
  }, [navigateWithQueryParams, values]);

  const incidents = data?.pages
    ? ([] as Incident[]).concat.apply([], data.pages)
    : [];

  const incidentsTotal = useIncidentsTotal({ ...values, userId }) || 0;

  const noIncidentsFound = !isLoading && !incidents?.length;

  if (!hasPermission('read:incidents')) {
    return <Four0Three />;
  }

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (noIncidentsFound && equal(values, incidentsFiltersInitialValues)) {
    return <NoUserIncidents />;
  }

  return (
    <div className="container min-w-3xl max-w-5xl py-12">
      <div className="flex gap-8 mb-10">
        <div className="w-60">
          <FilterFields.Status />
        </div>
      </div>
      <IncidentsTotal
        className="mb-6"
        loading={isLoading}
        total={incidentsTotal}
      />
      <InfiniteList
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        fetchMore={fetchNextPage}
        hasMore={incidents.length < incidentsTotal}
        idProp="incidentId"
        data={incidents}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<IncidentsListHeader />}
        renderLoadingRow={<IncidentCardLoading />}
        renderRow={(incident: Incident) => <IncidentCard incident={incident} />}
      />
    </div>
  );
};
