import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholder: {
    defaultMessage: 'All',
  },
  idleHours: {
    defaultMessage: 'Idle hours',
  },
});
