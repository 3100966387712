import { FormattedMessage, useIntl } from 'react-intl';
import { useSubscriptionsQuery } from '@cooltra/api';
import { FormSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';

import messages from './messages';

export const SubscriptionSelectField = () => {
  const { formatMessage } = useIntl();
  const { data, isLoading } = useSubscriptionsQuery();

  return (
    <FormSelectField
      isLoading={isLoading}
      id="subscription-id"
      name="subscriptionId"
      noOptionsMessage={() => (
        <FormattedMessage {...messages.noSubscriptions} />
      )}
      placeholder={formatMessage(messages.placeholder)}
      options={data
        ?.map(({ subscriptionId, title }) => ({
          label: title,
          value: subscriptionId,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))}
      label={
        <InputLabel htmlFor="subscription-id">
          <FormattedMessage {...messages.label} />
        </InputLabel>
      }
    />
  );
};
