import { defineMessages } from 'react-intl';

export default defineMessages({
  selectAllVehicles: {
    defaultMessage: 'Select all vehicles',
  },
  vehicle: {
    defaultMessage: 'Vehicle',
  },
  tasks: {
    defaultMessage: 'Tasks',
  },
  location: {
    defaultMessage: 'Location',
  },
  services: {
    defaultMessage: 'Next services',
  },
});
