import { FormattedMessage } from 'react-intl';
import { WorkShiftSummary } from '@cooltra/api';
import { Tooltip } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';

import { calculateTimeDifference } from '~/libs/time-difference/calculate-time-difference';
import { Circle } from '~/common';

import { DateTime } from '../../../DateTime';

import messages from './messages';

export type ShiftDurationProps = Pick<
  WorkShiftSummary,
  'createdAt' | 'finishedAt' | 'automaticallyClosed'
>;

export const ShiftDuration = ({
  createdAt,
  finishedAt,
  automaticallyClosed,
}: ShiftDurationProps) => {
  return (
    <div className="flex flex-col gap-1">
      <span className="text-xs text-neutral-300">
        <FormattedMessage {...messages.shiftDuration} />
      </span>
      {!finishedAt ? (
        <span className="text-lg text-success-500 font-semibold">
          <FormattedMessage {...messages.inProgress} />
        </span>
      ) : (
        <span className="text-lg text-neutral-1000 font-semibold">
          {calculateTimeDifference(new Date(createdAt), new Date(finishedAt))}
        </span>
      )}
      <div className="flex items-center gap-1 text-xs">
        <div className="flex gap-4 items-center">
          <DateTime date={createdAt} />
          {finishedAt && (
            <>
              <span>-</span>
              <DateTime
                date={finishedAt}
                dateClassName={
                  automaticallyClosed ? 'text-warning-800 font-medium' : ''
                }
                timeClassName={automaticallyClosed ? 'text-warning-600' : ''}
              />
            </>
          )}
          {automaticallyClosed && (
            <div className="flex">
              <Tooltip
                button={
                  <Circle
                    className={classNames(
                      'w-4 h-4 outline outline-1 outline-neutral-600 text-neutral-600 text-sm'
                    )}
                  >
                    !
                  </Circle>
                }
                content={
                  <p className="py-2 px-3 text-sm text-neutral-600">
                    <FormattedMessage {...messages.automaticallyClosedShift} />
                  </p>
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
