import { defineMessages } from 'react-intl';

export default defineMessages({
  expired: {
    defaultMessage: 'Expired',
  },
  active: {
    defaultMessage: 'Active',
  },
});
