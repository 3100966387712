import { ReactNode, useState } from 'react';

import { PreferredServicePointsContext } from './PreferredServicePointsContext';

export type PreferredServicePointsProviderProps = {
  children?: ReactNode;
  defaultServicePoint?: string;
  preferredServicePointslocalStorageKey?: string;
  billingServicePointLocalStorageKey?: string;
};

export const PreferredServicePointsProvider = ({
  children,
  preferredServicePointslocalStorageKey = 'zeus-web-station-based-preferred-service-points',
}: PreferredServicePointsProviderProps) => {
  const getPreferredServicePoints = (): string[] | null => {
    const savedServicePoints = localStorage.getItem(
      preferredServicePointslocalStorageKey
    ) as string | null;
    if (savedServicePoints) {
      return JSON.parse(savedServicePoints);
    }
    return null;
  };

  const [preferredServicePoints, setServicePoints] = useState<string[]>(
    getPreferredServicePoints() || []
  );

  const savePreferredServicePoints = (servicePoints: string[]) => {
    localStorage.setItem(
      preferredServicePointslocalStorageKey,
      JSON.stringify(servicePoints)
    );
  };

  const updatePreferredServicePoints = (servicePoints: string[]) => {
    savePreferredServicePoints(servicePoints);
    setServicePoints(servicePoints);
  };

  return (
    <PreferredServicePointsContext.Provider
      value={{
        preferredServicePoints,
        updatePreferredServicePoints,
      }}
    >
      {children}
    </PreferredServicePointsContext.Provider>
  );
};
