import { defineMessages } from 'react-intl';

export default defineMessages({
  withOperator: {
    defaultMessage: 'With operator',
  },
  noOperators: {
    defaultMessage: 'No operators',
  },
  all: {
    defaultMessage: 'All',
  },
});
