import { PromotionState } from '@cooltra/station-based-api';
import { defineMessages } from 'react-intl';

export default defineMessages({
  state: {
    defaultMessage: 'State',
  },
  placeholder: {
    defaultMessage: 'All',
  },
});

export const stateLabels = defineMessages<PromotionState>({
  ACTIVE: {
    defaultMessage: 'Active',
  },
  EXPIRED: {
    defaultMessage: 'Expired',
  },
  SCHEDULED: {
    defaultMessage: 'Scheduled',
  },
});
