import { Card, Placeholder } from '@cooltra/ui';
import { consecutiveNumbers } from '@cooltra/utils';

import { StaticFieldLoading } from '~/common';

export const PersonalDetailsLoading = () => (
  <Card className="mb-5">
    <div className="p-10">
      <div className="mb-8">
        <Placeholder className="w-40 h-3 mt-2" />
      </div>
      <div className="grid grid-cols-2 gap-x-5 gap-y-10">
        {consecutiveNumbers(4).map((key) => (
          <StaticFieldLoading key={`details=${key}`} />
        ))}
      </div>
    </div>
  </Card>
);
