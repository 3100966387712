import { defineMessages } from 'react-intl';

export default defineMessages({
  stripeQRPaymentMethod: {
    defaultMessage: 'Web payment - QR',
  },
  verifyIfPaid: {
    defaultMessage: 'Verify payment',
  },
  copyLink: {
    defaultMessage: 'Copy link',
  },
});
