export const Star = () => (
  <g>
    <path
      fill="#EE7026"
      d="M26.016 4.288a.5.5 0 01.905 0l6.076 12.947.256.433c.39.535.967.897 1.614.996l13.637 2.079a.5.5 0 01.283.843l-9.885 10.14-.313.389a2.719 2.719 0 00-.401 1.921l2.325 14.271a.5.5 0 01-.736.518L27.624 42.11l-.449-.192a2.373 2.373 0 00-1.862.192l-12.158 6.716a.5.5 0 01-.735-.518l2.33-14.27.034-.509a2.676 2.676 0 00-.748-1.803l-9.89-10.139a.5.5 0 01.282-.843l13.643-2.08c.808-.123 1.507-.657 1.869-1.428l6.076-12.947z"
    ></path>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M32.998 17.235l.255.433c.39.535.967.897 1.614.996l13.637 2.079a.5.5 0 01.283.843l-9.885 10.14-.313.389a2.719 2.719 0 00-.401 1.921l2.325 14.271a.5.5 0 01-.736.518L27.624 42.11l-.449-.192a2.373 2.373 0 00-1.861.192l-12.159 6.716a.5.5 0 01-.735-.518l2.33-14.27.035-.509a2.677 2.677 0 00-.75-1.803l-9.89-10.139a.5.5 0 01.283-.843l13.643-2.08c.808-.123 1.507-.657 1.869-1.428l6.076-12.947a.5.5 0 01.905 0l6.077 12.947zm-21.232 16.46L1.998 23.68c-1.99-2.04-.838-5.475 1.978-5.904l13.35-2.034L23.3 3.013c1.26-2.684 5.078-2.684 6.337 0l5.974 12.73 13.345 2.034c2.817.43 3.968 3.864 1.979 5.904L41.17 33.694l2.303 14.13c.47 2.884-2.591 5.04-5.148 3.627L26.47 44.898 14.606 51.45c-2.557 1.413-5.618-.744-5.147-3.627l2.307-14.13z"
      clipRule="evenodd"
    ></path>
  </g>
);
