import { useField } from '@cooltra/form';
import { FormattedMessage } from 'react-intl';

import { DateRange } from '../../DateRange/DateRange';

import messages from './messages';

export const Created = () => {
  const minDate = useField('minDate');
  const maxDate = useField('maxDate');

  return (
    <DateRange
      label={<FormattedMessage {...messages.label} />}
      onFromChange={minDate.setValue}
      fromValue={minDate.value}
      onToChange={maxDate.setValue}
      toValue={maxDate.value}
    />
  );
};
