import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { ServiceScheduledMileageValue, SidebarItem } from '~/common';

import messages from './messages';

export type ServiceMileageProps = {
  nextMileageTo: number | null | undefined;
  remainingMileageTo: number | null | undefined;
  label: ReactNode;
};

export const ServiceMileage = ({
  nextMileageTo,
  remainingMileageTo,
  label,
}: ServiceMileageProps) => {
  if (nextMileageTo && remainingMileageTo) {
    return (
      <SidebarItem
        className="mb-2"
        label={label}
        value={
          <ServiceScheduledMileageValue
            nextMileageTo={nextMileageTo}
            remainingMileageTo={remainingMileageTo}
          />
        }
      />
    );
  }

  return (
    <SidebarItem
      className="mb-2"
      label={label}
      value={<FormattedMessage {...messages.serviceNotDefined} />}
    />
  );
};
