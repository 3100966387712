import { Incident } from '@cooltra/api';

export type IncidentCardInfoProps = Pick<
  Incident,
  'open' | 'title' | 'description'
>;

export const IncidentCardInfo = ({
  open,
  title,
  description,
}: IncidentCardInfoProps) => (
  <div className="pr-2">
    <div data-testid={open ? 'OPEN_INCIDENT' : 'CLOSED_INCIDENT'}>
      <div className="flex gap-4 items-center">
        <div className="flex flex-wrap gap-2">
          <h5 className="text-base text-neutral-700">{title}</h5>
        </div>
      </div>
      {description && (
        <span className="mt-1 block text-xs text-neutral-800 line-clamp-4">
          {description}
        </span>
      )}
    </div>
  </div>
);
