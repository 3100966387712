import { defineMessages } from 'react-intl';

import { PackExpirationMode } from '../../PackForm';

export default defineMessages({
  expirationMode: {
    defaultMessage: 'Expiration mode',
  },
  doesntExpire: {
    defaultMessage: "Doesn't expire",
  },
  consumableUntil: {
    defaultMessage: 'Consumable until',
  },
  noOfDays: {
    defaultMessage: 'No. of days',
  },
  date: {
    defaultMessage: 'Date',
  },
  time: {
    defaultMessage: 'Time',
  },
});

export const packExpirationModeMessages = defineMessages<PackExpirationMode>({
  'specific-date': {
    defaultMessage: 'Specific date',
  },
  'after-days': {
    defaultMessage: 'After days',
  },
});
