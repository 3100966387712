import { DepositMethod } from '@cooltra/station-based-api';
import { defineMessages } from 'react-intl';

export default defineMessages({
  depositRetained: {
    defaultMessage: 'Deposit retained',
  },
  depositCharged: {
    defaultMessage: 'Deposit charged',
  },
  retentionValidUntil: {
    defaultMessage: 'Retention valid until: {date}',
  },
  depositExpired: {
    defaultMessage: 'Automatically released',
  },
});

export const contractDepositMessages = defineMessages<DepositMethod>({
  TPV: {
    defaultMessage: 'TPV',
  },
  BOOKING_CARD: {
    defaultMessage: 'Stripe card',
  },
  STRIPE_QR: {
    defaultMessage: 'Stripe QR',
  },
});
