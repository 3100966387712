import { defineMessages } from 'react-intl';

export default defineMessages({
  pickUpAscending: {
    defaultMessage: 'Oldest first',
  },
  pickUpDescending: {
    defaultMessage: 'Newest first',
  },
});
