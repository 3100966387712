import { FormattedMessage, useIntl } from 'react-intl';
import { Form, FormButton, FormHelpers, FormProvider } from '@cooltra/form';

import { validateRequired } from '~/validation';

import { CommentField } from './CommentField';
import messages from './messages';

export type CommentFormValues = {
  comment: string;
};

export const commentInitialFormValues: CommentFormValues = {
  comment: '',
};

export type CommentFormProps = {
  onSubmit: (
    values: CommentFormValues,
    { resetForm }: FormHelpers<CommentFormValues>
  ) => Promise<void>;
  disabled: boolean;
};

export const CommentForm = ({ onSubmit, disabled }: CommentFormProps) => {
  const intl = useIntl();

  return (
    <FormProvider<CommentFormValues>
      disabled={disabled}
      initialValues={commentInitialFormValues}
      validate={({ comment }) => {
        const commentError = validateRequired(intl, comment);
        return commentError ? { comment: commentError } : {};
      }}
      onSubmit={onSubmit}
    >
      <Form>
        <h3 className="mb-8 text-2xl text-neutral-600">
          <FormattedMessage {...messages.taskComments} />
        </h3>
        <div className="mb-6">
          <CommentField label={intl.formatMessage(messages.comment)} />
        </div>
        <FormButton>
          <FormattedMessage {...messages.addComment} />
        </FormButton>
      </Form>
    </FormProvider>
  );
};
