import { Badge } from '@cooltra/ui';
import { VehicleServices } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type ServiceOutOfMileageBadgeProps = VehicleServices;

export const ServiceOutOfMileageBadge = ({
  remainingMileageToService,
}: ServiceOutOfMileageBadgeProps) => {
  if (!remainingMileageToService || remainingMileageToService >= 0) {
    return <></>;
  }

  return (
    <Badge variant="danger" className="text-xs">
      <FormattedMessage {...messages.outOfMileage} />
    </Badge>
  );
};
