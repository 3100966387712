import { CreateCooltraForEmployeePromotionPayload } from '@cooltra/api';
import { parse } from 'date-fns';

import { CooltraForEmployeesFormValues } from '~/common';
import { dateAndTimeFormat } from '~/utils/date';

const getISODate = (
  date: string | undefined,
  time: string | undefined
): string => {
  if (!date || !time) {
    return '';
  }

  return parse(`${date} ${time}`, dateAndTimeFormat, new Date()).toISOString();
};

export const getCreateCooltraForEmployeePromotionPayload = (
  values: CooltraForEmployeesFormValues
): CreateCooltraForEmployeePromotionPayload => {
  return {
    title: values.title,
    codePrefix: values.codePrefix,
    companyFiscalName: values.companyFiscalName,
    companyName: values.companyName,
    numberOfCodes: Number(values.numberOfCodes),
    redeemableFrom: getISODate(
      values.redeemableFromDate,
      values.redeemableFromTime
    ),
    redeemableUntil: getISODate(
      values.redeemableUntilDate,
      values.redeemableUntilTime
    ),
    consumableUntil: getISODate(
      values.consumableUntilDate,
      values.consumableUntilTime
    ),
    subscriptionId: values.subscription,
  };
};
