import { FormattedMessage } from 'react-intl';
import { Button, ButtonProps } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';

import messages from './messages';

export type ToggleStatusButtonProps = Pick<
  ButtonProps,
  'onClick' | 'loading'
> & {
  isTaskOpen: boolean;
};

export const ToggleStatusButton = ({
  onClick,
  loading,
  isTaskOpen,
}: ToggleStatusButtonProps) => {
  const { dirty } = useFormContext();
  return (
    <Button
      onClick={onClick}
      emphasis="high"
      loading={loading}
      disabled={dirty}
    >
      {isTaskOpen ? (
        <FormattedMessage {...messages.close} />
      ) : (
        <FormattedMessage {...messages.reopen} />
      )}
    </Button>
  );
};
