import { FormattedMessage } from 'react-intl';
import { RouterNavLink } from '@cooltra/navigation';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { stringifyParams } from '@cooltra/utils';

import { threeDaysAgo } from '~/utils/date';
import { usePreferredSystem } from '~/libs/preferred-system';

import messages from '../messages';

export const Rentals = () => {
  const { system } = usePreferredSystem();
  const { hasPermission } = useAuthClaimsQuery();

  if (!hasPermission('read:rentals')) {
    return <></>;
  }

  const defaultRentalsParams = {
    system: [system],
    state: ['ACTIVE'],
    minDate: threeDaysAgo,
    maxDate: '',
  };

  return (
    <li>
      <RouterNavLink to={`/rentals?${stringifyParams(defaultRentalsParams)}`}>
        <FormattedMessage {...messages.rentals} />
      </RouterNavLink>
    </li>
  );
};
