import { useFormContext } from '@cooltra/form';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type ResetFiltersButton<FormValues extends Record<string, any>> = {
  initialValues: FormValues;
  onClick?: () => void;
};

export const ResetFiltersButton = <Data extends Record<string, any>>({
  initialValues,
  onClick,
}: ResetFiltersButton<Data>) => {
  const { setValues } = useFormContext();

  const resetFilters = () => {
    setValues(initialValues);
    onClick && onClick();
  };

  return (
    <button type="button" className="text-primary-700" onClick={resetFilters}>
      <FormattedMessage {...messages.clearFilters} />
    </button>
  );
};
