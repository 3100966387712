import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    defaultMessage: 'Name',
  },
  systems: {
    defaultMessage: 'Home systems',
  },
});
