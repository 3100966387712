import { defineMessages } from 'react-intl';

export default defineMessages({
  signByEmail: {
    defaultMessage: 'Sign contract by email',
  },
  contractSentSuccessfully: {
    defaultMessage: 'Contract sent successfully',
  },
  vehicleAssignedToAnotherActiveContract: {
    defaultMessage: 'The vehicle is assigned to another active contract',
  },
});
