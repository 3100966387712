import { useUserStatesQuery } from '@cooltra/api';
import { Placeholder } from '@cooltra/ui';
import { differenceInMonths } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { NotAvailable } from '~/common';

import messages from './messages';

const roundHalf = (num: number) => Math.round(num * 2) / 2;

export const UserLifespan = () => {
  const { id = '' } = useParams<'id'>();

  const { data: states, isError, isLoading } = useUserStatesQuery(id);

  const calculateYears = () => {
    if (!states) {
      return 0;
    }
    const initialState = states[states.length - 1];
    if (!initialState) {
      return 0;
    }
    const monthDifference = differenceInMonths(
      new Date(),
      new Date(initialState.createdAt)
    );
    return roundHalf(monthDifference / 12);
  };

  const years = calculateYears();

  return (
    <div data-testid="USER_LIFESPAN" className="pr-4">
      <span className="block text-neutral-400 text-xs mb-3">
        <FormattedMessage {...messages.userFor} />
      </span>
      <div className="text-neutral-800 font-semibold text-3xl">
        {isError ? (
          <NotAvailable />
        ) : isLoading ? (
          <div className="h-9 flex items-center">
            <Placeholder className="h-3 w-20" />
          </div>
        ) : (
          <span className="block">
            <span className="pr-2">{years}</span>
            <span className="text-lg font-normal">
              {years === 1 || years === 0.5 ? (
                <FormattedMessage {...messages.year} />
              ) : (
                <FormattedMessage {...messages.years} />
              )}
            </span>
          </span>
        )}
      </div>
    </div>
  );
};
