import { Card, Divider, InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormTextareaField, useFormContext } from '@cooltra/form';

import {
  PromotionFields,
  PromotionFormValues,
  PromotionStateType,
  Translations,
} from '~/common';

import messages from './messages';

export const PromotionFormFields = () => {
  const { values } = useFormContext<PromotionFormValues>();
  const { formatMessage } = useIntl();
  const { editMode, stateType } = values;
  const disabled = editMode && stateType === PromotionStateType.EXPIRED;

  return (
    <Card className="w-full mb-5">
      <div className="p-10">
        <h2 className="text-xl font-semibold text-neutral-400 mb-4">
          <FormattedMessage {...messages.promotion} />
        </h2>
        <div className="mb-6">
          <PromotionFields.PromotionTypeSelectorMode />
        </div>
        <div className="flex flex-col gap-6">
          <div className="grid grid-cols-3 gap-x-5">
            <div className="col-span-2">
              <PromotionFields.About />
            </div>
            <PromotionFields.CreditValue />
          </div>
          <PromotionFields.Discount />
          <PromotionFields.TargetVehicleModels />
        </div>
      </div>
      <Divider className="my-3" />
      <div className="p-10 mb-8">
        <h2 className="text-xl font-semibold text-neutral-400 mb-8">
          <FormattedMessage {...messages.promotionCode} />
        </h2>
        <div className="grid gap-y-10">
          <PromotionFields.PromotionCodeSelectorMode />
          <PromotionFields.Code />
          <PromotionFields.MaxUses className="grid grid-cols-2 gap-x-5" />
          <PromotionFields.MultiCode />
          <PromotionFields.UserCanRedeemMoreThanOneCode />
        </div>
      </div>
      <Divider className="my-3" />
      <div className="p-10 mb-8">
        <h2 className="text-xl font-semibold text-neutral-400 mb-8">
          <FormattedMessage {...messages.validityAndUse} />
        </h2>
        <div className="grid gap-y-10">
          <PromotionFields.ValidityDates />
          <PromotionFields.BenefitExpiresAt />
        </div>
      </div>
      <Divider className="my-3" />
      <div className="p-10 mb-8">
        <h2 className="text-xl font-semibold text-neutral-400 mb-8">
          <FormattedMessage {...messages.targetGroup} />
        </h2>
        <div className="grid gap-y-10">
          <PromotionFields.TargetHomeSystems />
          <PromotionFields.Registration />
          <FormTextareaField
            name="targetUserIds"
            id="target-user-ids"
            placeholder={formatMessage(messages.targetUserIdsPlaceholder)}
            disabled={values.stateType === 'EXPIRED'}
            rows={5}
            className="w-96"
            label={
              <InputLabel htmlFor="target-user-ids">
                <FormattedMessage {...messages.targetUserIds} />
              </InputLabel>
            }
          />
        </div>
      </div>
      <Translations disabled={disabled} />
    </Card>
  );
};
