import { FormattedMessage, useIntl } from 'react-intl';
import { FormMultiSelectField, useFormContext } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { pricingGroupNames } from '@cooltra/station-based-api';

import { StationBasedStateType } from '~/common';

import { StationBasedFormValues } from '../../../StationBasedForm';

import messages from './messages';

export const PricingGroups = () => {
  const { formatMessage } = useIntl();
  const { values, isSubmitting } = useFormContext<StationBasedFormValues>();
  const { editMode, stateType } = values;
  const editingNoScheduledPromotion =
    editMode && stateType !== StationBasedStateType.SCHEDULED;

  const options = Object.entries(pricingGroupNames)
    .map(([key, name]) => ({
      value: key,
      label: name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <FormMultiSelectField
      id="pricingGroups"
      name="pricingGroups"
      options={options}
      disabled={isSubmitting || editingNoScheduledPromotion}
      placeholder={formatMessage(messages.allPricingGroups)}
      label={
        <InputLabel htmlFor="pricingGroups">
          <FormattedMessage {...messages.pricingGroup} />
        </InputLabel>
      }
    />
  );
};
