import { StationBasedLanguageCode } from '@cooltra/countries';
import { defineMessages } from 'react-intl';

export const headings = defineMessages<StationBasedLanguageCode>({
  en: {
    defaultMessage: 'English content',
  },
  es: {
    defaultMessage: 'Spanish content',
  },
  fr: {
    defaultMessage: 'French content',
  },
  it: {
    defaultMessage: 'Italian content',
  },
  pt: {
    defaultMessage: 'Portuguese content',
  },
  ca: {
    defaultMessage: 'Catalan content',
  },
  de: {
    defaultMessage: 'German content',
  },
});

export const titleLabels = defineMessages<StationBasedLanguageCode>({
  en: {
    defaultMessage: 'English title',
  },
  es: {
    defaultMessage: 'Spanish title',
  },
  fr: {
    defaultMessage: 'French title',
  },
  it: {
    defaultMessage: 'Italian title',
  },
  pt: {
    defaultMessage: 'Portuguese title',
  },
  ca: {
    defaultMessage: 'Catalan title',
  },
  de: {
    defaultMessage: 'German title',
  },
});
