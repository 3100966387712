import { LayerProps } from 'react-map-gl';

import { getVehicleOpacity } from '../../../tools/layers';

import { getCircleColorExpressionByBatteryChargeLevel } from './layers-utils';

export const singleVehicleExpandedBackgroundLayer: LayerProps = {
  id: 'single-vehicle-expanded-background',
  type: 'circle',
  source: 'battery-view-single-vehicles',
  filter: ['==', 'isSelected', true],
  minzoom: 14,
  maxzoom: 17,
  paint: {
    'circle-opacity': 0,
    'circle-radius': 19,
    'circle-stroke-width': 3,
    'circle-stroke-color': getCircleColorExpressionByBatteryChargeLevel(),
    'circle-stroke-opacity': getVehicleOpacity(),
  },
};

export const singleVehicleExpandedLayer: LayerProps = {
  id: 'single-vehicle-expanded',
  type: 'symbol',
  source: 'battery-view-single-vehicles',
  minzoom: 14,
  maxzoom: 17,
  filter: ['!', ['has', 'point_count']],
  paint: {
    'text-color': '#fff',
    'text-translate': [11.5, -11],
    'text-opacity': getVehicleOpacity(),
    'icon-opacity': getVehicleOpacity(),
  },
  layout: {
    'text-field': [
      'match',
      ['get', 'numberOfOpenTasks'],
      0,
      '',
      ['get', 'numberOfOpenTasks'],
    ],
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 10,
    'icon-image': ['get', 'vehicleImageName'],
    'icon-size': 0.5,
    'icon-allow-overlap': true,
    'text-allow-overlap': true,
    'icon-ignore-placement': true,
    'text-ignore-placement': true,
    'symbol-placement': 'point',
    'icon-rotation-alignment': 'map',
    'text-rotation-alignment': 'viewport',
  },
};

export const singleVehicleExpandedBigBackgroundLayer: LayerProps = {
  id: 'single-vehicle-expanded-big-background',
  type: 'circle',
  source: 'battery-view-single-vehicles',
  filter: ['==', 'isSelected', true],
  minzoom: 17,
  paint: {
    'circle-opacity': 0,
    'circle-radius': 29,
    'circle-stroke-width': 5,
    'circle-stroke-color': getCircleColorExpressionByBatteryChargeLevel(),
    'circle-stroke-opacity': getVehicleOpacity(),
  },
};

export const singleVehicleExpandedBigLayer: LayerProps = {
  id: 'single-vehicle-expanded-big',
  type: 'symbol',
  source: 'battery-view-single-vehicles',
  minzoom: 17,
  filter: ['!', ['has', 'point_count']],
  paint: {
    'text-color': '#fff',
    'text-translate': [18.5, -18],
    'text-opacity': getVehicleOpacity(),
    'icon-opacity': getVehicleOpacity(),
  },
  layout: {
    'text-field': [
      'match',
      ['get', 'numberOfOpenTasks'],
      0,
      '',
      ['get', 'numberOfOpenTasks'],
    ],
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 14,
    'icon-image': ['get', 'vehicleImageName'],
    'icon-size': 0.8,
    'icon-allow-overlap': true,
    'text-allow-overlap': true,
    'icon-ignore-placement': true,
    'text-ignore-placement': true,
    'symbol-placement': 'point',
    'icon-rotation-alignment': 'map',
    'text-rotation-alignment': 'viewport',
  },
};
