import { defineMessages } from 'react-intl';

export default defineMessages({
  clear: {
    defaultMessage: 'Clear',
  },
  clearSuspension: {
    defaultMessage: 'Clear suspension',
  },
  comment: {
    defaultMessage: 'Reason for clearing the suspension',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  success: {
    defaultMessage: 'You have successfully cleared the suspension!',
  },
});
