import { defineMessages } from 'react-intl';

export default defineMessages({
  validUntil: {
    defaultMessage: 'Valid until',
  },
  validFrom: {
    defaultMessage: 'Valid from',
  },
});
