import { defineMessages } from 'react-intl';

export default defineMessages({
  coverages: {
    defaultMessage: 'Coverages',
  },
  accessories: {
    defaultMessage: 'Accessories',
  },
  downloadCsv: {
    defaultMessage: 'Download CSV',
  },
});
