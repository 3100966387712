import { RentalTimeline, RentalTimelineItem } from '@cooltra/api';

export type RentalTimelineItemReduced = RentalTimelineItem & {
  endAttemptsOn?: string;
  attemptsTimes?: number;
};

export type RentalTimelineReduced = RentalTimelineItemReduced[];

const areDismissibleErrorsEquals = (
  dismissibleErrors: string[] = [],
  dismissibleErrorsToCompare: string[] = []
): boolean => {
  return (
    dismissibleErrors.length === dismissibleErrorsToCompare.length &&
    dismissibleErrors.every(
      (error, index) => error === dismissibleErrorsToCompare[index]
    )
  );
};

const areTimelineItemsEquals = (
  rentalTimelineItem: RentalTimelineItem,
  rentalTimelineItemToCompare: RentalTimelineItem
) => {
  const areEventsEquals =
    rentalTimelineItem.event === rentalTimelineItemToCompare.event;

  if (rentalTimelineItem.event === 'VehicleStatus') {
    const areLockStatusEquals =
      rentalTimelineItem.status?.lock ===
      rentalTimelineItemToCompare.status?.lock;
    const areCentralStandStatusEquals =
      rentalTimelineItem.status?.centralStandDown ===
      rentalTimelineItemToCompare.status?.centralStandDown;
    const areTopCaseStatusEquals =
      rentalTimelineItem.status?.topCaseClosed ===
      rentalTimelineItemToCompare.status?.topCaseClosed;
    const arePowerStatusEquals =
      rentalTimelineItem.status?.powerOff ===
      rentalTimelineItemToCompare.status?.powerOff;

    return (
      areEventsEquals &&
      areLockStatusEquals &&
      areCentralStandStatusEquals &&
      areTopCaseStatusEquals &&
      arePowerStatusEquals
    );
  }

  const areErrorTypesEquals =
    rentalTimelineItem.error?.type === rentalTimelineItemToCompare.error?.type;
  const areErrorDetailsEquals =
    rentalTimelineItem.error?.detail ===
    rentalTimelineItemToCompare.error?.detail;

  return (
    areEventsEquals &&
    areErrorTypesEquals &&
    areErrorDetailsEquals &&
    areDismissibleErrorsEquals(
      rentalTimelineItem.dismissibleErrors,
      rentalTimelineItemToCompare.dismissibleErrors
    )
  );
};

const getGenericReducedTimelineItem = (
  rentalTimelineItem: RentalTimelineItem,
  previousComparedRentalTimeline: RentalTimelineItem,
  attemptsTimes: number
) => {
  return {
    ...rentalTimelineItem,
    endAttemptsOn: previousComparedRentalTimeline.occurredOn,
    attemptsTimes,
  };
};

const getReducedStatusTimelineItem = (
  rentalTimelineItem: RentalTimelineItem,
  previousComparedRentalTimeline: RentalTimelineItem,
  attemptsTimes: number
) => {
  return {
    ...rentalTimelineItem,
    status: previousComparedRentalTimeline.status,
    endAttemptsOn: previousComparedRentalTimeline.occurredOn,
    attemptsTimes,
  };
};

export const reduceRentalTimeline = (
  rentalTimeline: RentalTimeline
): RentalTimelineReduced => {
  const rentalTimelineReduced: RentalTimelineReduced = [];

  for (let index = 0; index < rentalTimeline.length; index++) {
    const rentalTimelineItem = rentalTimeline[index];
    rentalTimelineReduced.push(rentalTimelineItem);

    for (
      let subIndex = index + 1;
      subIndex < rentalTimeline.length;
      subIndex++
    ) {
      const rentalTimelineToCompare = rentalTimeline[subIndex];

      if (areTimelineItemsEquals(rentalTimelineItem, rentalTimelineToCompare)) {
        const lastRentalTimelineReducedPosition =
          rentalTimelineReduced.length - 1;
        const attemptsTimes =
          rentalTimelineReduced[lastRentalTimelineReducedPosition]
            .attemptsTimes || 1;
        const previousComparedRentalTimeline = rentalTimeline[subIndex];

        rentalTimelineReduced[lastRentalTimelineReducedPosition] =
          rentalTimelineItem.event === 'VehicleStatus'
            ? getReducedStatusTimelineItem(
                rentalTimelineItem,
                previousComparedRentalTimeline,
                attemptsTimes + 1
              )
            : getGenericReducedTimelineItem(
                rentalTimelineItem,
                previousComparedRentalTimeline,
                attemptsTimes + 1
              );

        index = subIndex;
      } else {
        break;
      }
    }
  }

  return rentalTimelineReduced;
};
