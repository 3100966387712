import { defineMessages } from 'react-intl';

export default defineMessages({
  promotion: {
    defaultMessage: 'Promotion',
  },
  promotionCode: {
    defaultMessage: 'Promotion code',
  },
  validityAndUse: {
    defaultMessage: 'Validity & use',
  },
  targetGroup: {
    defaultMessage: 'Target group',
  },
  targetUserIds: {
    defaultMessage: 'User IDs',
  },
  targetUserIdsPlaceholder: {
    defaultMessage: 'Paste user IDs separated by a new line here',
  },
});
