import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'No discounts found',
  },
  content: {
    defaultMessage: 'This user has not yet redeemed any discounts',
  },
});
