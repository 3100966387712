import { PropsWithChildren, useState } from 'react';

import { useMapCenter } from '../map-center';
import { useFilteredVehiclesQuery } from '../vehicles-filters';

import { SelectVehicleContext } from './SelectVehicleContext';

export const SelectVehicleProvider = ({ children }: PropsWithChildren) => {
  const [vehicleId, setVehicleId] = useState<string | undefined>(undefined);

  const { updateViewState } = useMapCenter();
  const {
    data: { filteredVehicles },
  } = useFilteredVehiclesQuery();

  const selectVehicle = (vehicleId: string) => {
    setVehicleId(vehicleId);

    const geoLocation = filteredVehicles.find(
      (vehicle) => vehicleId === vehicle.vehicleId
    )?.status.geoLocation;

    if (geoLocation) {
      updateViewState((prevState) => ({
        zoom: prevState.zoom < 16 ? 16 : prevState.zoom,
        latitude: geoLocation.latitude,
        longitude: geoLocation.longitude,
      }));
    }
  };

  const deselectVehicle = () => setVehicleId(undefined);

  return (
    <SelectVehicleContext.Provider
      value={{ vehicleId, selectVehicle, deselectVehicle }}
    >
      {children}
    </SelectVehicleContext.Provider>
  );
};
