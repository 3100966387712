import { UserDiscount } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { Amount } from '~/common';

import messages from './messages';

export const DiscountCap = ({
  discountCap,
  discountUsed,
}: Pick<UserDiscount, 'discountCap' | 'discountUsed'>) => {
  if (!discountCap || !discountUsed) {
    return <></>;
  }

  return (
    <FormattedMessage
      {...messages.usedOf}
      values={{
        discountUsed: (
          <Amount value={discountUsed.value} currency={discountUsed.currency} />
        ),
        discountCap: (
          <Amount value={discountCap.value} currency={discountCap.currency} />
        ),
      }}
    />
  );
};
