import { VehicleTaskListItem } from '@cooltra/api';
import { RouterCardLink } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';

import { Log, OperatorOrGroup, Pair, TaskBadge } from '~/common';

import { OpenVehicleTaskCardLayout } from './OpenVehicleTaskCardLayout';
import messages from './messages';

export type OpenVehicleTaskCardProps = {
  task: VehicleTaskListItem;
};

export const OpenVehicleTaskCard = ({
  task: {
    id,
    title,
    updatedAt,
    operatorGroupId,
    operatorId,
    createdAt,
    blocking,
  },
}: OpenVehicleTaskCardProps) => {
  const hasBeenUpdated = updatedAt && updatedAt !== createdAt;
  return (
    <RouterCardLink to={`/tasks/${id}`}>
      <OpenVehicleTaskCardLayout
        content={
          <p className="line-clamp-1 font-semibold text-neutral-700 text-sm">
            {title}
          </p>
        }
        updated={
          <Log
            action={hasBeenUpdated ? 'update' : 'create'}
            date={hasBeenUpdated ? updatedAt : createdAt}
          />
        }
        assignee={
          <>
            <Pair.Label className="mb-1">
              <FormattedMessage {...messages.assignee} />
            </Pair.Label>
            <OperatorOrGroup
              operatorGroupId={operatorGroupId}
              operatorId={operatorId}
            />
          </>
        }
        blocking={blocking && <TaskBadge.Blocking />}
      />
    </RouterCardLink>
  );
};
