import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    defaultMessage: 'No',
  },
  confirm: {
    defaultMessage: 'Yes',
  },
  confirmation: {
    defaultMessage: 'Confirmation',
  },
  disclaimer: {
    defaultMessage:
      'Are you sure you want to remove the second driver and all his/her personal information?',
  },
});
