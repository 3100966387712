import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type VehicleProblemReportRowLayoutProps =
  HTMLAttributes<HTMLDivElement> & {
    problem: ReactNode;
    context: ReactNode;
    created: ReactNode;
  };

export const VehicleProblemReportRowLayout = ({
  problem,
  context,
  created,
  className,
  ...rest
}: VehicleProblemReportRowLayoutProps) => (
  <div className={classNames('flex', 'h-full', 'w-full', className)} {...rest}>
    <div className="flex items-center w-full px-8 gap-4">
      <div className="flex-1">{problem}</div>
      <div className="w-40 shrink-0">{context}</div>
      <div className="w-40 shrink-0">{created}</div>
    </div>
  </div>
);
