import { FormattedMessage } from 'react-intl';

import { ListTotal, ListTotalProps } from '~/common';

import messages from './messages';

export type ProviderUsersTotalProps = Omit<ListTotalProps, 'children'> & {
  total: number;
  keyboard: string;
};

export const ProviderUsersTotal = ({
  total,
  keyboard,
  loading,
  ...rest
}: ProviderUsersTotalProps) => (
  <ListTotal loading={loading} {...rest}>
    <FormattedMessage
      {...messages.providerUsers}
      values={{
        total,
        keyboard,
      }}
    />
  </ListTotal>
);
