import { Badge, BadgePlaceholder, Card } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { consecutiveNumbers } from '@cooltra/utils';
import { useTagsQuery } from '@cooltra/api';

import { ErrorPage } from '~/common';

import messages from './messages';

export const TagsList = () => {
  const { isError, data } = useTagsQuery();

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div
      className="container min-w-screen-sm max-w-2xl py-12"
      data-testid="TAGS"
    >
      <h2 className="text-neutral-1000 mb-11">
        <FormattedMessage {...messages.title} />
      </h2>
      <ul className="flex flex-col gap-2.5">
        {!data &&
          consecutiveNumbers(8).map((i) => (
            <li key={`tag-list-item-placeholder-${i}`}>
              <Card className="h-16 flex items-center px-6">
                <BadgePlaceholder className="w-20" />
              </Card>
            </li>
          ))}
        {data &&
          data
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ tagId, name }) => (
              <li key={tagId}>
                <Card className="h-16 px-6 flex items-center">
                  <Badge>{name}</Badge>
                </Card>
              </li>
            ))}
      </ul>
    </div>
  );
};
