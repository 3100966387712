import { Icon } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';
import { System } from '@cooltra/auth-api';
import { FormattedMessage } from 'react-intl';
import { systemsWithBikes } from '@cooltra/api';
import { RouterButton } from '@cooltra/navigation';
import { MdOutlineExpandMore } from 'react-icons/md';
import { useLocation, useParams } from 'react-router-dom';
import { classNames, stringifyParams } from '@cooltra/utils';

import { SystemCity, useSystemOptions } from '~/common';

import { useVehicleTypeQueryParam } from '../utils';

import { HomeSystemLink } from './HomeSystemLink';
import messages from './messages';

export const HomeSystemSelector = () => {
  const { pathname } = useLocation();

  const params = useParams<'system'>();
  const system = params.system as System;

  const vehicleTypeParam = useVehicleTypeQueryParam();
  const hasBikeQueryParam = vehicleTypeParam === 'BIKE';

  const [isOpen, { toggle }] = useToggle();

  const systems = useSystemOptions();

  const canAccessOneSystem = systems.length === 1;

  const hasBikes = systemsWithBikes.includes(system);

  return (
    <div className="relative z-40 h-20">
      <div
        className="absolute w-full text-center shadow-md bg-neutral-0"
        data-testid="SELECTOR_HOME_SYSTEM"
      >
        <div
          className={classNames(
            'h-20 w-full flex items-center px-4',
            hasBikes ? 'justify-between' : 'justify-center'
          )}
        >
          {hasBikes && (
            <div className="flex w-52">
              <div className="flex gap-2 border border-neutral-200 rounded-lg p-2">
                <RouterButton
                  emphasis={hasBikeQueryParam ? 'low' : 'high'}
                  size="sm"
                  to={pathname}
                >
                  <FormattedMessage {...messages.scooters} />
                </RouterButton>
                <RouterButton
                  emphasis={hasBikeQueryParam ? 'high' : 'low'}
                  size="sm"
                  to={`?${stringifyParams({ vehicleType: 'BIKE' })}`}
                >
                  <FormattedMessage {...messages.eBikes} />
                </RouterButton>
              </div>
            </div>
          )}
          <button
            disabled={canAccessOneSystem}
            onClick={toggle}
            className="justify-center p-4 focus-visible:ring focus-visible:ring-inset focus-visible:ring-primary-200 rounded-md transition-all"
          >
            <span className="block pr-2 text-lg font-semibold text-neutral-600">
              <SystemCity system={system} />
            </span>
            {!canAccessOneSystem && (
              <Icon
                className={classNames(
                  'text-xl relative transition-transform text-neutral-800',
                  isOpen ? 'rotate-180' : 'rotate-0'
                )}
              >
                <MdOutlineExpandMore />
              </Icon>
            )}
          </button>
          {hasBikes && <div className="w-52" />}
        </div>
        {isOpen && (
          <div className="bg-neutral-0 mx-auto max-w-4xl pb-16">
            <ul className="grid grid-cols-6 gap-4">
              {systems.map(({ label, value }) => (
                <HomeSystemLink system={value} onClick={toggle} key={value}>
                  {label}
                </HomeSystemLink>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
