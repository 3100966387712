import { endOfWeek, format, startOfWeek } from 'date-fns';
import {
  DailyContractsListItem,
  useContractsByDayQuery,
} from '@cooltra/station-based-api';
import { FormattedMessage } from 'react-intl';
import { ScrollToTop, Tooltip } from '@cooltra/ui';

import { ErrorPage } from '~/common';

import { POSNavbar } from '../POSNavbar/POSNavbar';
import { useFilters } from '../useFilters';
import { SmallContractCard } from '../ContractCard';
import {
  ContractSummaryVehicleType,
  ContractsSummary,
} from '../ContractsSummary/ContractsSummary';
import { POSLayout } from '../POSLayout/POSLayout';
import { posDateFormat } from '../utils';

import { WeeklyCalendar } from './WeeklyCalendar';
import messages from './messages';

export const WeeklyView = () => {
  const { date, serviceType, servicePointId, status } = useFilters();
  const weekStart = startOfWeek(date);
  const weekEnd = endOfWeek(date);

  const { data, isLoading, isError } = useContractsByDayQuery({
    minDate: format(weekStart, posDateFormat),
    maxDate: format(weekEnd, posDateFormat),
    serviceType: serviceType || undefined,
    servicePoint: servicePointId,
    status: status,
  });

  if (isError) {
    return <ErrorPage />;
  }

  const vehicleTypes: ContractSummaryVehicleType[] = Object.values(data || {})
    .flatMap((contracts) => Object.values(contracts).flat())
    .filter((contract) => contract.vehicleType?.name)
    .map(({ serviceType, vehicleType }) => ({
      name: vehicleType?.name || '',
      type: serviceType,
    }));

  const renderContractCard = ({
    contractId,
    serviceType,
    status,
    contractNumber,
    accessories,
    hasClickAndRide,
  }: DailyContractsListItem) => (
    <li key={contractId}>
      <SmallContractCard
        hasClickAndRide={hasClickAndRide}
        contractId={contractId}
        serviceType={serviceType}
        status={status}
        accessories={accessories}
      >
        <span className="text-xs font-medium truncate">{contractNumber}</span>
      </SmallContractCard>
    </li>
  );

  return (
    <>
      <ScrollToTop />
      <POSLayout
        renderNavbar={<POSNavbar view="weekly" />}
        renderSummary={<ContractsSummary vehicleTypes={vehicleTypes} />}
        isLoading={isLoading}
        renderContent={
          <WeeklyCalendar
            displayDate={date}
            renderSlot={(date, time) => {
              const day = format(date, posDateFormat);
              const contracts = data?.[day]?.[time] || [];

              return (
                <div className="p-1" data-testid={`${day} ${time}`}>
                  <ul className="flex flex-col gap-1 mb-2">
                    {contracts.slice(0, 3).map(renderContractCard)}
                  </ul>
                  {contracts.length > 3 && (
                    <Tooltip
                      trigger="click"
                      interactive={true}
                      content={
                        <ul
                          className="flex flex-col gap-1 p-5 w-48"
                          data-testid="CONTRACT_TOOLTIP"
                        >
                          {contracts.map(renderContractCard)}
                        </ul>
                      }
                      button={
                        <button className="link link-primary text-center text-xs w-full py-2">
                          <FormattedMessage
                            {...messages.more}
                            values={{ count: contracts.length - 3 }}
                          />
                        </button>
                      }
                    />
                  )}
                </div>
              );
            }}
          />
        }
      />
    </>
  );
};
