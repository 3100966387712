import { Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLocationQuery, usePatchLocationMutation } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { FormButton, FormProvider, Form } from '@cooltra/form';
import { useRouteState } from '@cooltra/navigation';
import { SpinnerScreen } from '@cooltra/ui';

import { ErrorPage, SystemCity } from '~/common';
import { useNotification } from '~/hooks';
import { isTestingEnv } from '~/utils/e2e';

import { DeleteLocation } from '../DeleteLocation/DeleteLocation';
import { WriteLocationLayout } from '../../WriteLocationLayout/WriteLocationLayout';
import * as LocationFields from '../../LocationFields';

import {
  EditLocationFormValues,
  getLocationFormInitialValues,
  validateEditLocationForm,
} from './edit-location-form';
import messages from './messages';

export const EditLocationForm = () => {
  const navigate = useNavigate();
  const routeState = useRouteState<'from'>();

  const intl = useIntl();
  const { locationId = '' } = useParams<'locationId'>();

  const { data: location, isError } = useLocationQuery(locationId);

  const mutation = usePatchLocationMutation(locationId);

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleSubmit = (values: EditLocationFormValues) =>
    mutation
      .mutateAsync(values)
      .then(() => {
        addSuccessNotification(intl.formatMessage(messages.success));
        routeState?.from === '/settings/locations'
          ? navigate(-1)
          : navigate('..');
      })
      .catch((error) => {
        addErrorNotification(
          getErrorStatus(error) === 409
            ? intl.formatMessage(messages.conflictError)
            : undefined
        );
      });

  if (isError) {
    return <ErrorPage />;
  }

  if (!location) {
    return <SpinnerScreen />;
  }

  return (
    <FormProvider
      initialValues={getLocationFormInitialValues(location)}
      validate={validateEditLocationForm(intl)}
      onSubmit={handleSubmit}
    >
      <Form data-testid="EDIT_LOCATION">
        <WriteLocationLayout
          renderTitle={<FormattedMessage {...messages.title} />}
          renderContent={
            <>
              <LocationFields.Name />
              <span className="text-sm text-neutral-500">
                <FormattedMessage {...messages.homeSystems} />
                <span>: </span>
                {location.systems.map((system, index, list) => (
                  <Fragment key={system}>
                    <SystemCity system={system} />
                    {index !== list.length - 1 && <span>, </span>}
                  </Fragment>
                ))}
              </span>
            </>
          }
          renderFooter={
            <>
              {isTestingEnv() ? <DeleteLocation /> : <span />}
              <FormButton>
                <FormattedMessage {...messages.submitButton} />
              </FormButton>
            </>
          }
        />
      </Form>
    </FormProvider>
  );
};
