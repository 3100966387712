import { useIntl } from 'react-intl';
import { VehicleStatus } from '@cooltra/api';

import { NoDataAvailable } from '../../NoDataAvailable/NoDataAvailable';
import { TelematicsItemLayout } from '../TelematicsItemLayout/TelematicsItemLayout';

import messages from './messages';

export const Voltage = ({ voltage }: Pick<VehicleStatus, 'voltage'>) => {
  const { formatMessage } = useIntl();

  if (voltage === null) {
    return (
      <TelematicsItemLayout
        title={formatMessage(messages.voltage)}
        value={<NoDataAvailable />}
        noPadding
      />
    );
  }

  return (
    <TelematicsItemLayout
      title={formatMessage(messages.voltage)}
      value={`${voltage.value} ${voltage.unit}`}
      noPadding
    />
  );
};
