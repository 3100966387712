import { defineMessages } from 'react-intl';

export default defineMessages({
  inKm: {
    defaultMessage: 'In {km} km',
  },
  exceededBy: {
    defaultMessage: 'Exceeded by {km} km',
  },
});
