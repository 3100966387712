import { defineMessages } from 'react-intl';

export default defineMessages({
  invoiceItems: {
    defaultMessage: 'Invoice items',
  },
  refundedItems: {
    defaultMessage: 'Refunded items',
  },
  chargedAt: {
    defaultMessage: 'Charged at',
  },
  item: {
    defaultMessage: 'Item',
  },
  totalPrice: {
    defaultMessage: 'Total price',
  },
  totalCharged: {
    defaultMessage: 'Total charged',
  },
  pack: {
    defaultMessage: 'Pack {title}',
  },
  rental: {
    defaultMessage: 'Rental {rentalId}',
  },
});
