import { Task, useTagsQuery } from '@cooltra/api';
import { Badge, BadgePlaceholder } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { consecutiveNumbers } from '@cooltra/utils';

import { StaticField, StaticFieldLoading } from '../../../StaticField';

import messages from './messages';

export type TagsStaticProps = {
  value: Task['tagIds'];
};

export const TagsStatic = ({ value }: TagsStaticProps) => {
  const { data, isLoading } = useTagsQuery();

  return isLoading ? (
    <StaticFieldLoading
      renderValue={
        <span className="flex items-center gap-1">
          {consecutiveNumbers(2).map((i) => (
            <BadgePlaceholder key={`badge-placeholder-${i}`} className="w-16" />
          ))}
        </span>
      }
    />
  ) : (
    <StaticField
      renderLabel={<FormattedMessage {...messages.tags} />}
      renderValue={
        data && (
          <span className="flex flex-wrap gap-2">
            {value.map((tagId) => (
              <Badge key={tagId}>
                {data?.find((tag) => tag.tagId === tagId)?.name}
              </Badge>
            ))}
          </span>
        )
      }
    />
  );
};
