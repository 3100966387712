import { Title } from './Title/Title';
import { Subscription } from './Subscription/Subscription';
import { CompanyName } from './CompanyName/CompanyName';
import { CompanyFiscalName } from './CompanyFiscalName/CompanyFiscalName';
import { CodePrefix } from './CodePrefix/CodePrefix';
import { CodesToCSVLink } from './CodesToCSVLink/CodesToCSVLink';
import { NumberOfCodes } from './NumberOfCodes/NumberOfCodes';
import { RedeemableDates } from './RedeemableDates/RedeemableDates';
import { ConsumableDate } from './ConsumableDate/ConsumableDate';

export const CooltraForEmployeesFields = {
  Title,
  Subscription,
  CompanyName,
  CompanyFiscalName,
  CodePrefix,
  CodesToCSVLink,
  NumberOfCodes,
  RedeemableDates,
  ConsumableDate,
};
