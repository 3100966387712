import { defineMessages } from 'react-intl';

export default defineMessages({
  submitButton: {
    defaultMessage: 'Save changes',
  },
  backToPromotions: {
    defaultMessage: 'Back to promotions',
  },
  checkOnlyToNewCustomersOrMaxPromoUses: {
    defaultMessage:
      'This promotion cannot be created, please check if you have set either "Only to new customers" or "Max promo uses"',
  },
  updateMultiCodeError: {
    defaultMessage:
      'This promotion cannot be updated, please check if the promotion is expired or any field is incorrectly filled',
  },
  updateSingleCodeError: {
    defaultMessage:
      'This promotion cannot be updated, please check if this code already exists, the promotion is expired or any field is incorrectly filled',
  },
  youHaveSuccessfullyUpdatedThePromotion: {
    defaultMessage: 'You have successfully updated the promotion!',
  },
  promotionRules: {
    defaultMessage: 'Promotion rules',
  },
  couponsUsed: {
    defaultMessage: 'Coupons used ({amount})',
  },
});
