import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Page not found',
  },
  content: {
    defaultMessage: "It seems that the page you're looking for doesn't exist",
  },
});
