import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { CooltraForEmployeeRowLayout } from '../CooltraForEmployeeRowLayout';

import messages from './messages';

export const CooltraForEmployeeListHeader = () => (
  <CooltraForEmployeeRowLayout
    data-testid="COOLTRA_FOR_EMPLOYEES_PROMOTIONS_LIST_HEADER"
    promotion={
      <ListColumnLabel>
        <FormattedMessage {...messages.promotion} />
      </ListColumnLabel>
    }
    subscription={
      <ListColumnLabel>
        <FormattedMessage {...messages.subscription} />
      </ListColumnLabel>
    }
    redeemableFrom={
      <ListColumnLabel>
        <FormattedMessage {...messages.redeemableFrom} />
      </ListColumnLabel>
    }
    redeemableUntil={
      <ListColumnLabel>
        <FormattedMessage {...messages.redeemableUntil} />
      </ListColumnLabel>
    }
    uses={
      <ListColumnLabel>
        <FormattedMessage {...messages.uses} />
      </ListColumnLabel>
    }
    state={
      <ListColumnLabel>
        <FormattedMessage {...messages.state} />
      </ListColumnLabel>
    }
  />
);
