import { defineMessages } from 'react-intl';

import { PromotionTypeMode } from '~/common';

export default defineMessages({
  label: {
    defaultMessage: 'Type',
  },
  placeholder: {
    defaultMessage: 'All',
  },
});

export const promotionsTypeMessages = defineMessages<PromotionTypeMode>({
  credit: {
    defaultMessage: 'Credit',
  },
  discount: {
    defaultMessage: 'Discount',
  },
});
