import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    defaultMessage: 'Department',
  },
  placeholder: {
    defaultMessage: 'All',
  },
});
