import { useIncidentQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { useParams } from 'react-router-dom';
import { SpinnerScreen } from '@cooltra/ui';

import { ErrorPage, Four0Three } from '~/common';

import { EditIncident } from '../EditIncident';
import { ViewIncident } from '../ViewIncident/ViewIncident';

export const Incident = () => {
  const { incidentId = '' } = useParams<'incidentId'>();
  const { hasPermission } = useAuthClaimsQuery();
  const { data: incident, error, isError } = useIncidentQuery(incidentId);

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (hasPermission('write:incidents')) {
    return incident ? <EditIncident incident={incident} /> : <SpinnerScreen />;
  }

  return incident ? <ViewIncident incident={incident} /> : <SpinnerScreen />;
};
