import { FormattedMessage } from 'react-intl';
import { RentalPricingPaymentStatus } from '@cooltra/api';
import { classNames } from '@cooltra/utils';
import { MdCheck } from 'react-icons/md';

import messages from './messages';

export type PaymentStatusBadgeProps = {
  rentalPricingPaymentStatus: RentalPricingPaymentStatus;
};

export const PaymentStatusBadge = ({
  rentalPricingPaymentStatus,
}: PaymentStatusBadgeProps) => {
  let withCheck = false;
  let textColorClassName = '';

  switch (rentalPricingPaymentStatus) {
    case 'OPEN':
      textColorClassName = 'text-warning-700';
      break;
    case 'PAID':
      withCheck = true;
      textColorClassName = 'text-success-700';
      break;
    case 'REFUNDED':
      withCheck = true;
      textColorClassName = 'text-danger-800';
      break;
    case 'CANCELLED':
      textColorClassName = 'text-neutral-1000';
      break;
  }

  return (
    <p
      className={classNames(
        'font-semibold flex gap-1 items-center',
        textColorClassName
      )}
    >
      {withCheck && <MdCheck />}
      <FormattedMessage {...messages[rentalPricingPaymentStatus]} />
    </p>
  );
};
