import { BadgePlaceholder, Placeholder } from '@cooltra/ui';

import {
  SidebarHeadingLoading,
  SidebarLinksLoading,
  SidebarIconItemLoading,
} from '~/common';

export const SidebarLoading = () => (
  <>
    <div className="px-8 mt-9">
      <BadgePlaceholder className="w-24 mb-10" />
      <div className="mb-7">
        <Placeholder className="h-16 w-16 rounded-full" />
      </div>
      <SidebarHeadingLoading className="mb-5" />
      <div className="flex flex-col gap-6 mb-8">
        <Placeholder className="h-4 w-28" />
        <Placeholder className="h-4 w-24" />
        <Placeholder className="h-4 w-28" />
        <Placeholder className="h-4 w-40" />
        <Placeholder className="h-4 w-40" />
      </div>
      <SidebarIconItemLoading className="mb-8" />
    </div>
    <SidebarLinksLoading linksNumber={6} />
  </>
);
