import { FormattedMessage, useIntl } from 'react-intl';
import { FormButton, FormHelpers } from '@cooltra/form';
import { Card, SpinnerScreen } from '@cooltra/ui';
import { getErrorStatus } from '@cooltra/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useRentalPassQuery, usePatchRentalPassMutation } from '@cooltra/api';
import { useRouteState } from '@cooltra/navigation';

import { BackLink, ErrorPage, Four0Three, Log } from '~/common';
import { useNotification } from '~/hooks';

import {
  formatPassFormValues,
  parsePass,
  PassForm,
  PassFormValues,
} from '../PassForm';
import { PassFormFields } from '../PassFormFields';
import { PassState } from '../PassState/PassState';

import messages from './messages';

export const EditPass = () => {
  const navigate = useNavigate();
  const { passId = '' } = useParams<'passId'>();
  const routeState = useRouteState<'from'>();

  const { formatMessage } = useIntl();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const { data: pass, isLoading, error } = useRentalPassQuery(passId);
  const { mutateAsync } = usePatchRentalPassMutation(passId);

  const onSubmit = (
    values: PassFormValues,
    { resetForm }: FormHelpers<PassFormValues>
  ) =>
    mutateAsync(formatPassFormValues(values))
      .then(() => {
        resetForm(values);
        addSuccessNotification(formatMessage(messages.success));
      })
      .catch(() => addErrorNotification());

  const navigateBack = () =>
    routeState?.from === '/offers/passes'
      ? navigate(-1)
      : navigate('/offers/passes');

  if (isLoading) {
    return <SpinnerScreen />;
  }

  if (getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (!pass) {
    return <ErrorPage />;
  }

  return (
    <div className="container min-w-screen-sm max-w-5xl py-12">
      <div className="mb-9">
        <BackLink onClick={navigateBack}>
          <FormattedMessage {...messages.backToPasses} />
        </BackLink>
        <div className="flex items-center gap-4">
          <h1 className="text-3xl">{pass.titleTranslations.en}</h1>
          <PassState state={pass.state} />
        </div>
      </div>
      <PassForm
        onSubmit={onSubmit}
        initialValues={parsePass(pass)}
        disabled={pass.state !== 'SCHEDULED'}
      >
        <div className="flex gap-6 items-start">
          <div>
            <PassFormFields />
            <div className="flex justify-end">
              <FormButton>
                <FormattedMessage {...messages.saveChanges} />
              </FormButton>
            </div>
          </div>
          <Card className="flex flex-col gap-4 p-10 w-64">
            <Log
              user="operator"
              action="create"
              userId={pass.createdBy}
              date={pass.createdAt}
            />
            {pass.updatedBy && pass.updatedAt && (
              <Log
                user="operator"
                action="update"
                userId={pass.updatedBy}
                date={pass.updatedAt}
              />
            )}
          </Card>
        </div>
      </PassForm>
    </div>
  );
};
