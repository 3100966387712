import { defineMessages } from 'react-intl';

export default defineMessages({
  fullScreen: {
    defaultMessage: 'Full screen',
  },
  alt: {
    defaultMessage: 'Problem report image',
  },
});
