import { IntlShape } from 'react-intl';
import { valid } from 'geojson-validation';
import { VehicleType } from '@cooltra/api';

import { validateRequired, createSetError, validateAmount } from '~/validation';

import messages from './messages';

export type BonusZoneFormValues = {
  title: string;
  discount: string;
  polygon: string;
  vehicleType: VehicleType;
};

type BonusZoneFormFieldName = keyof BonusZoneFormValues;

type BonusZoneFormErrors = Partial<
  Record<BonusZoneFormFieldName, string | undefined>
>;

const hasCorrectFormat = (geoJson: any) =>
  geoJson?.features?.[0]?.geometry?.coordinates?.[0] != null ||
  geoJson?.coordinates?.[0] != null;

export const validateBonusZoneForm =
  (intl: IntlShape) =>
  ({ title, polygon, discount }: BonusZoneFormValues) => {
    const errors: BonusZoneFormErrors = {};
    const setError = createSetError(errors);

    const titleErrorMessage = validateRequired(intl, title);
    setError('title', titleErrorMessage);

    setError('discount', validateAmount(intl, discount));
    setError('discount', validateRequired(intl, discount));

    try {
      const parsedPolygon = JSON.parse(polygon);
      if (!valid(parsedPolygon) || !hasCorrectFormat(parsedPolygon)) {
        setError('polygon', intl.formatMessage(messages.invalidGeoJson));
      }
    } catch (error) {
      setError('polygon', intl.formatMessage(messages.invalidGeoJson));
    }

    const polygonErrorMessage = validateRequired(intl, polygon);
    setError('polygon', polygonErrorMessage);

    return errors;
  };

export const getInitialFormValues = (
  vehicleType: VehicleType
): BonusZoneFormValues => ({
  title: '',
  discount: '',
  polygon: '',
  vehicleType,
});
