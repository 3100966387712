import { Routes, Route, Navigate } from 'react-router-dom';
import { RouterTab } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { User } from '@cooltra/api';
import { SpinnerScreen } from '@cooltra/ui';

import { PersonalDetailsForm } from './PersonalDetails';
import { DocumentsForm } from './Documents';
import { BillingAddressForm } from './BillingAddress';
import messages from './messages';

export type ProfileProps = {
  user?: User;
};

export const Profile = ({ user }: ProfileProps) => (
  <div className="container min-w-screen-sm max-w-3xl py-12">
    <div className="flex px-4 gap-12">
      <RouterTab to="personal-details">
        <FormattedMessage {...messages.personalDetails} />
      </RouterTab>
      <RouterTab to="documents">
        <FormattedMessage {...messages.documents} />
      </RouterTab>
      <RouterTab to="billing-address">
        <FormattedMessage {...messages.billingAddress} />
      </RouterTab>
    </div>
    <Routes>
      <Route
        path="personal-details/*"
        element={user ? <PersonalDetailsForm user={user} /> : <SpinnerScreen />}
      />
      <Route
        path="documents"
        element={user ? <DocumentsForm user={user} /> : <SpinnerScreen />}
      />
      <Route
        path="billing-address"
        element={user ? <BillingAddressForm user={user} /> : <SpinnerScreen />}
      />

      <Route path="*" element={<Navigate replace to="../personal-details" />} />
    </Routes>
  </div>
);
