import { useContext } from 'react';

import { ShiftRouteCoordinatesContext } from './ShiftRouteCoordinatesContext';

export const useShiftRouteCoordinates = () => {
  const shiftRouteCoordinatesContext = useContext(ShiftRouteCoordinatesContext);

  if (!shiftRouteCoordinatesContext) {
    throw new Error(
      'shiftRouteCoordinatesContext can be only used within <ShiftRouteCoordinatesContext />'
    );
  }

  return shiftRouteCoordinatesContext;
};
