import { FormattedMessage } from 'react-intl';
import { RouterNavLink } from '@cooltra/navigation';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { stringifyParams } from '@cooltra/utils';

import messages from '../messages';

export const Offers = () => {
  const { hasPermission } = useAuthClaimsQuery();

  const isOffersTabDisplayed =
    hasPermission('read:offers') || hasPermission('read:cooltra_for_employees');

  if (!isOffersTabDisplayed) {
    return <></>;
  }

  const defaultPromotionsParams = {
    keyword: '',
    state: '',
    sort: 'createdAt.desc',
  };

  return (
    <li>
      <RouterNavLink
        to={`/offers/promotions?${stringifyParams(defaultPromotionsParams)}`}
      >
        <FormattedMessage {...messages.offers} />
      </RouterNavLink>
    </li>
  );
};
