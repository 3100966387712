import { ReactNode } from 'react';

export type ReviewHeaderLayoutProps = {
  renderImage: ReactNode;
  renderContent: ReactNode;
};

export const ReviewHeaderLayout = ({
  renderImage,
  renderContent,
}: ReviewHeaderLayoutProps) => (
  <div className="flex">
    <div className="h-28 w-36">{renderImage}</div>
    <div className="flex-1 px-4">{renderContent}</div>
  </div>
);
