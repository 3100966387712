import { Button } from '@cooltra/ui';
import {
  Contract,
  useNoShowContractMutation,
} from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { useNavigate } from 'react-router-dom';

import { ConfirmModal } from '~/common';
import { useNotification } from '~/hooks/useNotification';
import { useContractFlags } from '~/hooks';

import messages from './messages';

export type NoShowContractButtonProps = {
  contract: Contract;
  className?: string;
};

export const NoShowContractButton = ({
  contract,
  className,
}: NoShowContractButtonProps) => {
  const navigate = useNavigate();
  const { contractId, status } = contract;
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const intl = useIntl();
  const { hasPermission } = useAuthClaimsQuery();
  const { mutateAsync, isPending } = useNoShowContractMutation(contractId);
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const {
    hasDepositPendingRelease,
    hasAwaitingPaymentTicket,
    isSignedOrPendingSignature,
  } = useContractFlags();

  const handleConfirm = () => {
    mutateAsync()
      .then(() => {
        toggleOff();
        addSuccessNotification(intl.formatMessage(messages.success));
        navigate(`/contracts/${contractId}/overview`);
      })
      .catch(() => {
        addErrorNotification();
      });
  };

  const handleOpen = () => {
    if (hasDepositPendingRelease) {
      addErrorNotification(
        intl.formatMessage(messages.returnDepositBeforeMarkingContractAsNoShow)
      );
      return;
    }

    if (hasAwaitingPaymentTicket) {
      addErrorNotification(
        intl.formatMessage(
          messages.cancelAwaitingPaymentsBeforeMarkingContractAsNoShow
        )
      );
      return;
    }

    toggleOn();
  };

  if (status !== 'BOOKED') {
    return null;
  }

  if (!hasPermission('no_show:station_based_contracts')) {
    return null;
  }

  return (
    <>
      <ConfirmModal
        className="max-w-md min-w-md"
        dataTestId="NO_SHOW_CONTRACT_CONFIRM_MODAL"
        isOpen={isOpen}
        title={intl.formatMessage(messages.areYouSureToMarkContractAsNoShow)}
        content={intl.formatMessage(messages.weWontRefundPayments)}
        onClose={toggleOff}
        onConfirm={handleConfirm}
        loading={isPending}
      />
      <Button
        className={className}
        variant="neutral"
        onClick={handleOpen}
        disabled={isSignedOrPendingSignature}
      >
        <FormattedMessage {...messages.noShow} />
      </Button>
    </>
  );
};
