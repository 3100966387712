import { RouterNavLink } from '@cooltra/navigation';

import zeusLogo from '../logo.png';

export const CooltraLogo = () => {
  return (
    <li>
      <RouterNavLink to={`/`}>
        <div className="w-10 text-center flex justify-center">
          <img className="w-6 h-6" src={zeusLogo} alt="Cooltra's logo" />
        </div>
      </RouterNavLink>
    </li>
  );
};
