import { defineMessages } from 'react-intl';
import { ChargeAttemptState } from '@cooltra/api';

export default defineMessages<ChargeAttemptState>({
  PENDING: {
    defaultMessage: 'Pending',
  },
  SUCCEEDED: {
    defaultMessage: 'Succeeded',
  },
  FAILED: {
    defaultMessage: 'Failed',
  },
});
