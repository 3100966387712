import { FormattedMessage, useIntl } from 'react-intl';
import { FormInputField, useFormContext } from '@cooltra/form';

import { StationBasedFormValues } from '../../StationBasedForm';
import { StationBasedStateType } from '../../StationBasedState/StationBasedState';
import { OptionalFieldLabel } from '../../../../OptionalFieldLabel/OptionalFieldLabel';

import messages from './messages';

export const About = () => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext<StationBasedFormValues>();
  const { editMode, stateType } = values;
  const editingExpiredPromotion =
    editMode && stateType === StationBasedStateType.EXPIRED;

  return (
    <FormInputField
      name="about"
      id="about"
      disabled={editingExpiredPromotion}
      placeholder={formatMessage(messages.placeholder)}
      label={
        <OptionalFieldLabel htmlFor="about">
          <FormattedMessage {...messages.about} />
        </OptionalFieldLabel>
      }
    />
  );
};
