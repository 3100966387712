import { useContext } from 'react';
import { Spinner } from '@cooltra/ui';
import {
  usePricingGroupPricesMutation,
  usePricingGroupPricesQuery,
} from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { FormButton, FormHelpers } from '@cooltra/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { ErrorPage, Four0Four, Four0Three } from '~/common';
import { useNotification } from '~/hooks';

import { PriceListTypeContext } from '../PriceListTypeContext';

import { VehicleTypePriceListDetailsBackLink } from './VehicleTypePriceListDetailsBackLink/VehicleTypePriceListDetailsBackLink';
import { VehicleTypePriceListDetailsFormFields } from './VehicleTypePriceListDetailsFormFields';
import { getPriceListPayload } from './get-vehicle-type-price-list-details-payload';
import {
  PriceListFormValues,
  priceListInitialFormValues,
  VehicleTypePriceListDetailsForm,
} from './Form';
import { getFormValuesFromVehicleTypePriceListDetails } from './get-form-values-from-vehicle-type-price-list-details';
import messages from './messages';

export const VehicleTypePriceListDetails = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();
  const { type } = useContext(PriceListTypeContext);

  const { priceListId = '' } = useParams<'priceListId'>();
  const {
    data: pricingGroupPrices,
    isLoading,
    isError,
    error,
  } = usePricingGroupPricesQuery(
    priceListId,
    { type },
    {
      enabled: hasPermission('read:prices'),
    }
  );
  const { mutateAsync } = usePricingGroupPricesMutation(priceListId);
  const { addSuccessNotification, addErrorNotification } = useNotification();

  if (!hasPermission('read:prices')) {
    return <Four0Three />;
  }

  if (isLoading) {
    return (
      <div className="w-full text-center mt-8">
        <Spinner size="lg" />
      </div>
    );
  }

  if (isError && getErrorStatus(error) === 404) {
    return <Four0Four />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (!pricingGroupPrices) {
    return <Four0Four />;
  }

  const handleOnSubmit = (
    values: PriceListFormValues,
    { resetForm }: FormHelpers<PriceListFormValues>
  ) => {
    const payload = getPriceListPayload(values);

    return mutateAsync(payload)
      .then(() => {
        resetForm(values);
        addSuccessNotification(formatMessage(messages.updateSuccessfully));
      })
      .catch(({ response }) => {
        switch (response.status) {
          case 400:
            addErrorNotification(formatMessage(messages.badFormattedFields));
            break;
          default:
            addErrorNotification();
            break;
        }
      });
  };

  return (
    <div className="container min-w-3xl max-w-4xl py-12 pb-32">
      <VehicleTypePriceListDetailsForm
        initialValues={{
          ...priceListInitialFormValues,
          ...getFormValuesFromVehicleTypePriceListDetails(pricingGroupPrices),
        }}
        onSubmit={handleOnSubmit}
      >
        <>
          <VehicleTypePriceListDetailsBackLink />
          <div className="flex items-center justify-between">
            <h2 className="text-3xl">
              <FormattedMessage {...messages.title} />
            </h2>
            {hasPermission('write:prices') && (
              <FormButton>
                <FormattedMessage {...messages.savePriceList} />
              </FormButton>
            )}
          </div>
          <VehicleTypePriceListDetailsFormFields />
        </>
      </VehicleTypePriceListDetailsForm>
    </div>
  );
};
