import { PriceListType } from '@cooltra/station-based-api';
import { createContext } from 'react';

export type PriceListTypeContextShape = {
  type: PriceListType;
};

export const PriceListTypeContext = createContext<PriceListTypeContextShape>({
  type: 'WEB',
});
