import { defineMessages } from 'react-intl';

export default defineMessages({
  succeeded: {
    defaultMessage: 'Succeeded',
  },
  checkOutAttempt: {
    defaultMessage: 'Checkout attempt {error}',
  },
  dismissedErrors: {
    defaultMessage: 'dismissed error(s)',
  },
});
