import { IntlShape } from 'react-intl';
import { Contract } from '@cooltra/station-based-api';

import { createSetError, errorMessages, validateRequired } from '~/validation';

import messages from './messages';

export type ChecksFormValues = {
  fuelLevel: number;
  mileage: number | string;
  accessories: Record<string, boolean>;
};

export const initialValues: ChecksFormValues = {
  fuelLevel: 1,
  mileage: '',
  accessories: {},
};

type ChecksFormFieldName = keyof ChecksFormValues;

type ChecksFormErrors = Partial<
  Record<ChecksFormFieldName, string | undefined>
>;

export const validateFuelLevel = (intl: IntlShape, value: number) =>
  value === 0 ? intl.formatMessage(errorMessages.required) : undefined;

export const validateChecksInForm =
  (intl: IntlShape, { vehicle }: Contract) =>
  ({ fuelLevel, mileage }: ChecksFormValues) => {
    const errors: ChecksFormErrors = {};
    const setError = createSetError(errors);

    const isBike = vehicle?.category === 'EBIKE';
    if (!isBike) {
      setError('fuelLevel', validateFuelLevel(intl, fuelLevel));
    }

    setError('mileage', validateRequired(intl, mileage));

    return errors;
  };

export const validateChecksOutForm =
  (intl: IntlShape, { vehicle, checks }: Contract) =>
  ({ fuelLevel, mileage }: ChecksFormValues) => {
    const errors: ChecksFormErrors = {};
    const setError = createSetError(errors);

    const isBike = vehicle?.category === 'EBIKE';
    if (!isBike) {
      setError('fuelLevel', validateFuelLevel(intl, fuelLevel));
    }

    const previousMileage = checks.in?.mileage;

    if (previousMileage) {
      if (Number(mileage) < previousMileage) {
        setError('mileage', intl.formatMessage(messages.invalidMileage));
      }
    }

    setError('mileage', validateRequired(intl, mileage));

    return errors;
  };
