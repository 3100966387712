import { InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';

import { FilterFields } from '../../../FilterFields';

import messages from './messages';

export const Search = () => {
  const intl = useIntl();

  return (
    <FilterFields.Keyword
      placeholder={intl.formatMessage(messages.placeholder)}
      data-testid="vehicle-filter-keyword-search"
      id="keyword-search"
      label={
        <InputLabel htmlFor="keyword-search">
          <FormattedMessage {...messages.label} />
        </InputLabel>
      }
    />
  );
};
