import { Vehicle } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';

import { Release, Take, TransferVehicle } from '../../../ActionButtons';

import messages from './messages';

export type CurrentActivityHeaderProps = {
  vehicle?: Vehicle;
};

export const CurrentActivityHeader = ({
  vehicle,
}: CurrentActivityHeaderProps) => {
  const { user } = useAuth0();

  return (
    <div className="h-10 flex shrink-0 items-center justify-between mb-6">
      <h2 className="text-base text-neutral-500 font-semibold">
        <FormattedMessage {...messages.currentActivity} />
      </h2>
      <div className="flex items-end gap-2">
        {vehicle &&
          (vehicle.takenBy ? (
            <Release vehicleId={vehicle.vehicleId} />
          ) : (
            <Take vehicleId={vehicle.vehicleId} />
          ))}
        {vehicle && (!vehicle.takenBy || vehicle.takenBy === user?.sub) && (
          <TransferVehicle
            vehicleId={vehicle.vehicleId}
            system={vehicle.system}
          />
        )}
      </div>
    </div>
  );
};
