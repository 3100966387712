import { VehicleListItem } from '@cooltra/api';
import {
  isCityHallMatch,
  isModelMatch,
  isTelematicsMatch,
  isLocationMatch,
  isTagsMatch,
  isOperationalConditionsMatch,
  isOperatorOrGroupMatch,
  isBatteryMatch,
  isSystemMatch,
  isBonusMatch,
  isParkingReviewMatch,
  isUnbalancedMatch,
} from '@cooltra/vehicle-utils';
import { isBefore } from 'date-fns';

import { VehiclesFiltersFormValues } from '../vehicles-filters-form';

import { isKeywordMatch } from './is-keyword-match';
import { isWithOperator } from './is-with-operator';
import { isIdleFromMatch } from './is-idle-from-match';

export const sortVehicles = (
  vehicles: VehicleListItem[],
  { sort }: VehiclesFiltersFormValues
) => {
  if (sort === 'inLocationFrom.asc') {
    const sortedVehiclesWithInLocationFrom = vehicles
      .filter((vehicles) => vehicles.inLocationFrom !== '')
      .sort((a, b) => ('' + a.inLocationFrom).localeCompare(b.inLocationFrom));
    const vehiclesWithoutInLocationFrom = vehicles.filter(
      (vehicles) => vehicles.inLocationFrom === ''
    );

    return [
      ...sortedVehiclesWithInLocationFrom,
      ...vehiclesWithoutInLocationFrom,
    ];
  }

  if (sort === 'services.remainingMileageToService.asc') {
    const sortedVehiclesWithRemainingMileageToService = vehicles
      .filter((vehicles) => vehicles.services?.remainingMileageToService)
      .sort((a, b) =>
        Number(a.services?.remainingMileageToService) >
        Number(b.services?.remainingMileageToService)
          ? 1
          : -1
      );
    const vehiclesWithoutRemainingMileageToService = vehicles.filter(
      (vehicles) => !vehicles.services?.remainingMileageToService
    );

    return [
      ...sortedVehiclesWithRemainingMileageToService,
      ...vehiclesWithoutRemainingMileageToService,
    ];
  }

  if (sort === 'technicalInspections.remainingDaysToTechnicalInspection.asc') {
    const sortedVehiclesWithRemainingDaysToTechnicalInspection = vehicles
      .filter(
        (vehicles) =>
          vehicles.technicalInspections?.remainingDaysToTechnicalInspection
      )
      .sort((a, b) =>
        Number(a.technicalInspections?.remainingDaysToTechnicalInspection) >
        Number(b.technicalInspections?.remainingDaysToTechnicalInspection)
          ? 1
          : -1
      );
    const vehiclesWithoutRemainingDaysToTechnicalInspection = vehicles.filter(
      (vehicles) =>
        !vehicles.technicalInspections?.remainingDaysToTechnicalInspection
    );

    return [
      ...sortedVehiclesWithRemainingDaysToTechnicalInspection,
      ...vehiclesWithoutRemainingDaysToTechnicalInspection,
    ];
  }

  if (sort === 'idleFrom.asc') {
    const sortedVehiclesByIdleFrom = vehicles
      .filter((vehicles) => vehicles.idleFrom)
      .sort((a, b) =>
        a.idleFrom &&
        b.idleFrom &&
        isBefore(new Date(a.idleFrom), new Date(b.idleFrom))
          ? 1
          : -1
      );
    const vehiclesWithoutIdleFrom = vehicles.filter(
      (vehicles) => !vehicles.idleFrom
    );

    return [...sortedVehiclesByIdleFrom, ...vehiclesWithoutIdleFrom];
  }

  if (sort === 'idleFrom.desc') {
    const sortedVehiclesWithRemainingDaysToTechnicalInspection = vehicles
      .filter((vehicles) => vehicles.idleFrom)
      .sort((a, b) =>
        a.idleFrom &&
        b.idleFrom &&
        isBefore(new Date(b.idleFrom), new Date(a.idleFrom))
          ? 1
          : -1
      );
    const vehiclesWithoutIdleFrom = vehicles.filter(
      (vehicles) => !vehicles.idleFrom
    );

    return [
      ...sortedVehiclesWithRemainingDaysToTechnicalInspection,
      ...vehiclesWithoutIdleFrom,
    ];
  }

  return vehicles.sort((a, b) => {
    switch (sort) {
      case 'externalId.asc':
        return ('' + a.externalId).localeCompare(b.externalId);
      case 'inLocationFrom.desc':
        return ('' + b.inLocationFrom).localeCompare(a.inLocationFrom);
      default:
        return ('' + b.externalId).localeCompare(a.externalId);
    }
  });
};

export const isVehicleAMatch = (
  vehicle: VehicleListItem,
  filterValues: VehiclesFiltersFormValues
) =>
  [
    isWithOperator,
    isKeywordMatch,
    isCityHallMatch,
    isModelMatch,
    isTelematicsMatch,
    isLocationMatch,
    isTagsMatch,
    isOperationalConditionsMatch,
    isOperatorOrGroupMatch,
    isBatteryMatch,
    isBonusMatch,
    isParkingReviewMatch,
    isIdleFromMatch,
    isUnbalancedMatch,
  ].every((filter) => filter(vehicle, filterValues));

export const isVehicleAMatchForLegend = (
  vehicle: VehicleListItem,
  filterValues: VehiclesFiltersFormValues
) => [isSystemMatch].every((filter) => filter(vehicle, filterValues));
