import {
  FetchProblemReportsRequestParams,
  ProblemReportListItem,
  useProblemReportsQuery,
  useProblemReportsTotal,
} from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { List } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Counter, EmptyListMessageCard, ListErrorMessageCard } from '~/common';

import {
  OpenUserProblemReportCard,
  OpenUserProblemReportCardLoading,
} from '../OpenUserProblemReportCard';

import messages from './messages';

export const OpenUserProblemReports = () => {
  const { id = '' } = useParams<'id'>();

  const { hasPermission } = useAuthClaimsQuery();

  const params: FetchProblemReportsRequestParams = {
    open: 'true',
    userId: id,
  };
  const { data, isLoading, isError } = useProblemReportsQuery(params);

  const problemReports = data?.pages
    ? ([] as ProblemReportListItem[]).concat.apply([], data.pages)
    : [];

  const problemReportsTotal = useProblemReportsTotal(params) || 0;

  if (!hasPermission('read:problem_reports')) {
    return <></>;
  }

  if (isError) {
    return (
      <section>
        <div className="h-10 flex items-center mb-6">
          <h2 className="text-base text-neutral-500 font-semibold">
            <FormattedMessage {...messages.heading} />
          </h2>
        </div>
        <ListErrorMessageCard>
          <FormattedMessage {...messages.error} />
        </ListErrorMessageCard>
      </section>
    );
  }

  return (
    <section data-testid="OPEN_USER_PROBLEM_REPORTS">
      <div className="h-10 flex items-center mb-6">
        <h2 className="text-base text-neutral-500 font-semibold mr-3">
          <FormattedMessage {...messages.heading} />
        </h2>
        {!!problemReports?.length && <Counter>{problemReportsTotal}</Counter>}
      </div>
      <List
        loadingRows={2}
        isLoading={isLoading}
        idProp="problemReportId"
        className="gap-5 pb-2"
        data={problemReports}
        renderEmptyMessage={
          <EmptyListMessageCard>
            <FormattedMessage {...messages.noProblemReports} />
          </EmptyListMessageCard>
        }
        renderLoadingRow={<OpenUserProblemReportCardLoading />}
        renderRow={(problemReport: ProblemReportListItem) => (
          <OpenUserProblemReportCard problemReport={problemReport} />
        )}
      />
    </section>
  );
};
