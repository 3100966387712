import { defineMessages } from 'react-intl';

import { BenefitExpirationRangeMode } from '../../PromotionForm';

export default defineMessages({
  expirationAfterRedeem: {
    defaultMessage: 'Expiration after redeem',
  },
  discountValidity: {
    defaultMessage: 'Discount validity',
  },
  date: {
    defaultMessage: 'Date',
  },
  time: {
    defaultMessage: 'Time',
  },
  expirationMode: {
    defaultMessage: 'Selector of expiration mode',
  },
  doesntExpire: {
    defaultMessage: "Doesn't expire",
  },
  afterDays: {
    defaultMessage: 'After days',
  },
  afterHours: {
    defaultMessage: 'After hours',
  },
  consumableFrom: {
    defaultMessage: 'Consumable from',
  },
  consumableUntil: {
    defaultMessage: 'Consumable until',
  },
  fromDate: {
    defaultMessage: 'From date',
  },
  fromTime: {
    defaultMessage: 'From time',
  },
  untilDate: {
    defaultMessage: 'Until date',
  },
  untilTime: {
    defaultMessage: 'Until time',
  },
});

export const benefitExpirationRangeModeMessages =
  defineMessages<BenefitExpirationRangeMode>({
    'specific-date': {
      defaultMessage: 'Specific date',
    },
    'after-days': {
      defaultMessage: 'After days',
    },
    'after-hours': {
      defaultMessage: 'After hours',
    },
  });
