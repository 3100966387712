import { defineMessages } from 'react-intl';

export default defineMessages({
  none: {
    defaultMessage: 'None',
  },
  problemReports: {
    defaultMessage: '{total, plural, one {# report} other {# reports}}',
  },
});
