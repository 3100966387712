import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
  },
  removeDeposit: {
    defaultMessage: 'Remove deposit',
  },
  success: {
    defaultMessage: 'Pending deposit has been removed successfully',
  },
  cancelPendingDepositTitle: {
    defaultMessage: 'Remove pending deposit',
  },
  cancelPendingDepositContent: {
    defaultMessage: 'Are you sure you want to remove the pending deposit?',
  },
});
