import { RentalListItem, useRentalsQuery, useRentalsTotal } from '@cooltra/api';
import { InfiniteList } from '@cooltra/ui';
import { useParams } from 'react-router-dom';

import { ErrorPage } from '../ErrorPage';
import { RentalsTotal } from '../Rental';

import { NoUserRentals } from './NoUserRentals/NoUserRentals';
import { UserRentalsListHeader } from './UserRentalsListHeader';
import { UserRentalCard, UserRentalCardLoading } from './UserRentalCard';

export const UserRentals = () => {
  const { id: userId = '' } = useParams<'id'>();

  const { isError, isLoading, data, fetchNextPage, isFetchingNextPage } =
    useRentalsQuery({ userId }, { enabled: !!userId });

  const rentals = data?.pages
    ? ([] as RentalListItem[]).concat.apply([], data.pages)
    : [];

  const rentalsTotal = useRentalsTotal({ userId }) || 0;

  const noRentalsFound = !isLoading && !rentals?.length;

  if (isError) {
    return <ErrorPage />;
  }

  if (noRentalsFound) {
    return <NoUserRentals />;
  }

  return (
    <div
      className="container min-w-3xl max-w-5xl py-12"
      data-testid="USER_RENTALS"
    >
      <RentalsTotal total={rentalsTotal} loading={isLoading} className="mb-6" />
      <InfiniteList
        isLoading={isLoading || !rentals}
        idProp="rentalId"
        fetchMore={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        data={rentals}
        renderHeader={<UserRentalsListHeader />}
        renderLoadingRow={<UserRentalCardLoading />}
        renderRow={(rental: RentalListItem) => <UserRentalCard {...rental} />}
      />
    </div>
  );
};
