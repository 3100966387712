import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type ProblemReportRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  problem: ReactNode;
  context: ReactNode;
  created: ReactNode;
  vehicle: ReactNode;
};

export const ProblemReportRowLayout = ({
  problem,
  context,
  created,
  vehicle,
  className,
  ...rest
}: ProblemReportRowLayoutProps) => (
  <div className={classNames('flex', 'h-full', 'w-full', className)} {...rest}>
    <div className="flex items-center w-full px-8 gap-4">
      <div className="flex-1">{problem}</div>
      <div className="w-32 shrink-0">{context}</div>
      <div className="w-48 shrink-0">{created}</div>
      <div className="w-40 shrink-0">{vehicle}</div>
    </div>
  </div>
);
