import { useIntl } from 'react-intl';
import { differenceInHours, differenceInMinutes, format } from 'date-fns';

import messages from './messages';

export type TimeAgoProps = {
  ISODate: string;
};

export const TimeAgo = ({ ISODate }: TimeAgoProps) => {
  const { formatMessage } = useIntl();

  const lastSyncDate = new Date(ISODate);
  const minutesAgo = differenceInMinutes(new Date(), lastSyncDate);
  let dateText = format(lastSyncDate, 'dd/MM/yyyy HH:mm');

  if (minutesAgo < 60) {
    dateText = formatMessage(messages.minutesAgo, {
      minutes: minutesAgo,
    });
  }

  if (minutesAgo == 0) {
    dateText = formatMessage(messages.lessThanAMinuteAgo);
  }

  const hoursInADay = 60 * 24;

  if (minutesAgo >= 60 && minutesAgo <= hoursInADay) {
    dateText = formatMessage(messages.hoursAgo, {
      hours: differenceInHours(new Date(), lastSyncDate),
    });
  }

  return <>{dateText}</>;
};
