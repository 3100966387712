import { defineMessages } from 'react-intl';

export default defineMessages({
  none: {
    defaultMessage: 'None',
  },
  incidents: {
    defaultMessage: '{total, plural, one {# incident} other {# incidents}}',
  },
});
