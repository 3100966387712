import { defineMessages } from 'react-intl';

export default defineMessages({
  vehicleOffline: {
    defaultMessage: 'Vehicle Offline',
  },
  vehicleOnline: {
    defaultMessage: 'Vehicle Online',
  },
});
