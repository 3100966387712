import { VehicleParkedNext } from '@cooltra/api';
import { Card } from '@cooltra/ui';

import * as RentalsData from './RentalsData';

type RentalProps = {
  counter: number;
  parkingPhoto: string;
  vehiclesParkedTotal: number;
} & Pick<VehicleParkedNext, 'rentalId' | 'vehicle'>;

export const Rental = ({
  counter,
  parkingPhoto,
  vehiclesParkedTotal,
  rentalId,
  vehicle,
}: RentalProps) => (
  <div className="w-full flex items-start gap-4">
    <Card className="w-[28rem] flex-shrink-0 relative rounded-lg overflow-hidden">
      <RentalsData.PhotoCard parkingPhoto={parkingPhoto} key={parkingPhoto} />
      <div className="py-2 px-4 bg-neutral-800 bg-opacity-70 text-neutral-0 absolute top-5 left-5 rounded-xl">
        {counter}/{vehiclesParkedTotal}
      </div>
    </Card>
    <div className="w-full flex flex-col gap-4">
      <Card className="w-full rounded-lg overflow-hidden">
        <RentalsData.Maps vehicle={vehicle} />
      </Card>
      <Card className="w-full flex flex-col gap-4 rounded-lg">
        <RentalsData.Features rentalId={rentalId} vehicle={vehicle} />
      </Card>
    </div>
  </div>
);
