import { FormattedMessage } from 'react-intl';
import { RouterNavLink } from '@cooltra/navigation';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import messages from '../messages';

export const Prices = () => {
  const { hasPermission } = useAuthClaimsQuery();

  if (!hasPermission('read:prices')) {
    return <></>;
  }

  return (
    <li>
      <RouterNavLink to="/price-lists/web">
        <FormattedMessage {...messages.priceLists} />
      </RouterNavLink>
    </li>
  );
};
