import { FormattedMessage } from 'react-intl';

import { operatorGroups } from '~/utils/operators';

import { StaticField } from '../../StaticField';

import messages from './messages';

export type AssigneeStaticProps = {
  value: string;
};

export const AssigneeStatic = ({ value }: AssigneeStaticProps) => (
  <StaticField
    renderLabel={<FormattedMessage {...messages.assignee} />}
    renderValue={
      Object.values(operatorGroups).find(
        ({ operatorGroupId }) => value === operatorGroupId
      )?.name
    }
  />
);
