import { defineMessages } from 'react-intl';

export default defineMessages({
  price: {
    defaultMessage: 'Price',
  },
  savePriceList: {
    defaultMessage: 'Save price list',
  },
  algorithmForDiscount: {
    defaultMessage: 'Algorithm for discount',
  },
  placeholder: {
    defaultMessage: 'Select an algorithm',
  },
  updateSuccessfully: {
    defaultMessage: 'The price list has been saved successfully',
  },
  badFormattedFields: {
    defaultMessage:
      'This price list cannot be created, please check that every field is correct',
  },
  title: {
    defaultMessage: 'Accessories and coverages',
  },
});
