import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Technical inspection',
  },
  editService: {
    defaultMessage: 'Edit technical inspection',
  },
  success: {
    defaultMessage: 'You have successfully edited the technical inspection!',
  },
  badFormattedFields: {
    defaultMessage:
      'This technical inspection cannot be edited, please check that every field is correct',
  },
  mainInformation: {
    defaultMessage: 'Main information',
  },
  completedServiceData: {
    defaultMessage: 'Completed technical inspection data',
  },
  invalidFormat: {
    defaultMessage: 'Invalid format',
  },
});
