import { FormattedMessage } from 'react-intl';
import { InputError, InputLabel, SelectField } from '@cooltra/ui';
import { getKeys } from '@cooltra/utils';
import { useField, useFormContext } from '@cooltra/form';
import { InvoiceLegalEntity, Provider } from '@cooltra/api';

import {
  getLegalEntityDefaultTaxRate,
  legalEntityNames,
} from '~/utils/legal-entity';
import { getEnvVariable } from '~/utils/environment';

import { ChargeUserFormValues } from '../../ChargeUser/charge-user-form';

import messages from './messages';

export type LegalEntityProps = {
  provider: Provider;
};

export const LegalEntity = ({ provider }: LegalEntityProps) => {
  const { isSubmitting, setValues, values, isFormDisabled } =
    useFormContext<ChargeUserFormValues>();
  const { value, error, touched, setTouched } = useField('legalEntity');
  const legalEntities = getKeys(legalEntityNames)
    .filter((legalEntity) => {
      return provider === 'SIXT'
        ? legalEntity === 'COOLTRA_SPAIN' || legalEntity === 'COOLTRA_FRANCE'
        : true;
    })
    .filter((legalEntity) => {
      return getEnvVariable('ENVIRONMENT') === 'production'
        ? legalEntity !== 'COOLTRA_GERMANY' &&
            legalEntity !== 'COOLTRA_SWITZERLAND'
        : true;
    })
    .map((legalEntity) => ({
      label: legalEntityNames[legalEntity],
      value: legalEntity,
    }));

  return (
    <SelectField
      id="legal-entity"
      error={touched && error ? <InputError>{error}</InputError> : undefined}
      onChange={(val) => {
        const legalEntity = val as InvoiceLegalEntity;
        setValues({
          ...values,
          legalEntity,
          taxRate: getLegalEntityDefaultTaxRate(legalEntity),
        });
      }}
      value={value}
      onBlur={() => {
        setTouched(true);
      }}
      disabled={isFormDisabled || isSubmitting}
      options={legalEntities}
      label={
        <InputLabel htmlFor="legal-entity">
          <FormattedMessage {...messages.label} />
        </InputLabel>
      }
    />
  );
};
