import { useEffect, useState } from 'react';

import { usePrevious } from '../usePrevious/usePrevious';

export type UsePollingProps = {
  fn: () => Promise<unknown>;
  interval: number;
  maxAttempts?: number;
  onSettle: () => void;
};

export const usePolling = ({
  fn,
  interval,
  maxAttempts = 3,
  onSettle,
}: UsePollingProps) => {
  const [isLoading, setLoading] = useState(false);
  const previousLoading = usePrevious(isLoading);

  useEffect(() => {
    if (previousLoading && !isLoading) {
      onSettle();
    }
  }, [isLoading, onSettle, previousLoading]);

  useEffect(() => {
    let attempts = 0;
    let timer: NodeJS.Timeout;

    if (interval === 0) {
      setLoading(false);
    } else {
      setLoading(true);

      timer = setInterval(async () => {
        await fn();

        if (attempts === maxAttempts) {
          setLoading(false);
          clearInterval(timer);
        }

        attempts++;
      }, interval);
    }

    return () => clearInterval(timer);
  }, [fn, maxAttempts, interval]);

  return {
    isPolling: isLoading,
  };
};
