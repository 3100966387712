import { IntlShape } from 'react-intl';

import { ServiceFormValues } from '~/common';
import { createSetError, validateDate } from '~/validation';

import messages from './messages';

type ServiceFormFieldName = keyof ServiceFormValues;

type ServiceFormErrors = Partial<
  Record<ServiceFormFieldName, string | undefined>
>;

export const validateServiceForm =
  (intl: IntlShape) => (values: ServiceFormValues) => {
    const errors: ServiceFormErrors = {};
    const setError = createSetError(errors);

    if (values.scheduledAt) {
      setError(
        'scheduledAt',
        validateDate(intl, values.scheduledAt)
          ? intl.formatMessage(messages.invalidFormat)
          : undefined
      );
    }

    return errors;
  };
