import { TimelineItem } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { DateTime } from '../../DateTime';

import messages from './messages';

export type CommentProps = {
  comment: string;
  createdAt: string;
  createdBy?: string;
  isLast: boolean;
};

export const Comment = ({
  createdAt,
  createdBy,
  comment,
  isLast,
}: CommentProps) => (
  <TimelineItem
    header={<DateTime date={createdAt} />}
    isLast={isLast}
    timelineClassName="translate-y-2.5"
  >
    <div className="mb-16 pt-2">
      <p className="mb-4 text-base text-neutral-600">{comment}</p>
      <span className="text-xs text-neutral-400">
        <FormattedMessage
          {...messages.createdBy}
          values={{
            createdBy: (
              <span className="font-semibold text-neutral-600">
                {createdBy}
              </span>
            ),
          }}
        />
      </span>
    </div>
  </TimelineItem>
);
