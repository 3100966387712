import { defineMessages } from 'react-intl';

export default defineMessages({
  payTicket: {
    defaultMessage: 'Charge',
    id: 'contract_charge',
  },
  tpv: {
    defaultMessage: 'TPV La Caixa',
  },
  cash: {
    defaultMessage: 'Cash',
  },
  webPayment: {
    defaultMessage: 'Stripe card',
  },
  title: {
    defaultMessage: 'Payment method',
  },
  paymentDoneSuccessfully: {
    defaultMessage: 'Payment done successfully',
  },
  stripeChargeFailed: {
    defaultMessage:
      'Unable to charge ticket using Stripe card. Please try another payment method.',
  },
});
