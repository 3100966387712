import { defineMessages } from 'react-intl';

export default defineMessages({
  allInsurances: {
    defaultMessage: 'All coverages',
  },
  insurance: {
    defaultMessage: 'Coverages',
  },
  yes: {
    defaultMessage: 'Yes',
  },
  no: {
    defaultMessage: 'No',
  },
});
