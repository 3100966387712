import { defineMessages } from 'react-intl';

export default defineMessages({
  allVehicles: {
    defaultMessage: 'All vehicles',
  },
  elegibleVehicles: {
    defaultMessage: 'Elegible vehicles',
  },
});
