import { defineMessages } from 'react-intl';

export default defineMessages({
  remove: {
    defaultMessage: 'Remove',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  confirm: {
    defaultMessage: 'Confirm',
  },
  confirmation: {
    defaultMessage: 'Confirmation',
  },
  disclaimer: {
    defaultMessage:
      'Changes to the vehicle will reset the the coverage and extras selections. Do you want to continue?',
  },
});
