import { FormattedDate, FormattedMessage } from 'react-intl';
import { UserDiscount } from '@cooltra/api';

import messages from './messages';

export const DiscountValidity = ({
  state,
  validUntil,
  validFrom,
}: Pick<UserDiscount, 'validUntil' | 'validFrom' | 'state'>) => {
  if (state === 'ACTIVE' && validUntil) {
    return (
      <>
        <FormattedMessage {...messages.validUntil} />
        <span className="font-semibold">
          <FormattedDate
            value={new Date(validUntil)}
            day="2-digit"
            month="short"
            year="numeric"
          />
        </span>
      </>
    );
  }

  return (
    <>
      <FormattedMessage {...messages.validFrom} />
      <span className="font-semibold">
        <FormattedDate
          value={new Date(validFrom)}
          day="2-digit"
          month="short"
          year="numeric"
        />
      </span>
    </>
  );
};
