import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';

export type CalendarLabelProps = HTMLAttributes<HTMLSpanElement>;

export const CalendarLabel = ({ className, ...rest }: CalendarLabelProps) => (
  <span
    className={classNames('text-neutral-400 font-semibold text-xs', className)}
    {...rest}
  />
);
