import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { RejectReason } from '@cooltra/api';

import { ReviewOutcomeAlert } from '../ReviewOutcomeAlert';
import { useHandleSubmit } from '../useHandleSubmit';

import messages, { rejectReasonMessages } from './messages';
import { TranslatedRejectReason } from './types';

const isTranslatedRejectReason = (
  reason: TranslatedRejectReason | RejectReason
): reason is TranslatedRejectReason => reason !== 'other';

export type RejectAlertProps = {
  onReject: () => void;
  isLoading: boolean;
  reasons: RejectReason[];
};

export const RejectAlert = ({
  onReject,
  isLoading,
  reasons,
}: RejectAlertProps) => {
  const handleClick = useHandleSubmit(onReject);

  return (
    <ReviewOutcomeAlert
      variant="danger"
      renderHeader={<FormattedMessage {...messages.rejectHeading} />}
      renderList={reasons.filter(isTranslatedRejectReason).map((reason) => (
        <li key={reason}>
          {rejectReasonMessages[reason] ? (
            <FormattedMessage {...rejectReasonMessages[reason]} />
          ) : (
            reason
          )}
        </li>
      ))}
      renderButton={
        <Button
          variant="danger"
          emphasis="high"
          loading={isLoading}
          onClick={handleClick}
        >
          <FormattedMessage {...messages.rejectButton} />
        </Button>
      }
    />
  );
};
