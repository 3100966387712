import { RouterLink } from '@cooltra/navigation';
import { Divider } from '@cooltra/ui';
import { WorkShiftWorkUnit } from '@cooltra/api/src/modules/work-shifts/types';
import { MdArrowRightAlt, MdChat } from 'react-icons/md';

import { VehicleIcon } from '../../Vehicle';

import * as WorkDone from './WorkDone';

export const WorkUnitInfo = ({
  vehicle,
  tasksClosed,
  tasksOpened,
  tasksCommented,
  comment,
}: WorkShiftWorkUnit) => {
  const isEnergyAddedTheLast = () =>
    tasksClosed.length === 0 &&
    tasksOpened.length === 0 &&
    tasksCommented.length === 0;
  const isTaskClosedTheLast = (taskClosedIndex: number) =>
    taskClosedIndex === tasksClosed.length - 1 &&
    tasksOpened.length === 0 &&
    tasksCommented.length === 0;

  const isTaskOpenedTheLast = (taskOpenedIndex: number) =>
    taskOpenedIndex === tasksOpened.length - 1 && tasksCommented.length === 0;

  const displayWorkDoneList = () => {
    const { differenceAdded } = vehicle.battery;

    return (
      (differenceAdded != null && differenceAdded > 0) ||
      tasksClosed.length > 0 ||
      tasksOpened.length > 0 ||
      tasksCommented.length > 0
    );
  };

  return (
    <div className="rounded-lg overflow-hidden w-full">
      <div className="min-w-full bg-neutral-0">
        <div className="flex items-center justify-between py-2 px-4">
          <RouterLink
            to={`/vehicles/${vehicle.vehicleId}`}
            target="_blank"
            className="truncate font-semibold text-sm text-neutral-1000"
          >
            {vehicle.externalId}
          </RouterLink>
          <div className="flex items-center shrink-0">
            <span
              data-testid={
                vehicle.operationalState.before
                  ? vehicle.operationalState.before
                  : 'UNKNOWN-OPERATIONAL-STATE'
              }
            >
              <VehicleIcon
                size={48}
                operationalState={vehicle.operationalState.before}
                operationalConditions={[]}
                bonusEnabled={false}
                model={vehicle.model}
                batteryCharge={vehicle.battery.before || 0}
              />
            </span>
            <MdArrowRightAlt className="w-6 h-6 text-neutral-400" />
            <span
              data-testid={
                vehicle.operationalState.after
                  ? vehicle.operationalState.after
                  : 'UNKNOWN-OPERATIONAL-STATE'
              }
            >
              <VehicleIcon
                size={48}
                operationalState={vehicle.operationalState.after}
                operationalConditions={[]}
                bonusEnabled={false}
                model={vehicle.model}
                batteryCharge={vehicle.battery.after || 0}
              />
            </span>
          </div>
        </div>
        {displayWorkDoneList() && (
          <>
            <Divider />
            <div>
              <ul className="pt-6 pb-4">
                <WorkDone.BatteryAdded
                  vehicle={vehicle}
                  isLast={isEnergyAddedTheLast()}
                />
                {tasksClosed.map((taskClosed, index) => (
                  <WorkDone.TaskDone
                    key={taskClosed.taskId}
                    {...taskClosed}
                    type="closed"
                    isLast={isTaskClosedTheLast(index)}
                  />
                ))}
                {tasksOpened.map((taskOpened, index) => (
                  <WorkDone.TaskDone
                    key={taskOpened.taskId}
                    {...taskOpened}
                    type="open"
                    isLast={isTaskOpenedTheLast(index)}
                  />
                ))}
                {tasksCommented.map((taskCommented, index) => (
                  <WorkDone.TaskDone
                    key={taskCommented.taskId}
                    {...taskCommented}
                    type="commented"
                    isLast={index === tasksCommented.length - 1}
                  />
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
      {true && (
        <div className="flex items-start gap-2 px-4 py-4 text-xs italic text-neutral-500 bg-neutral-50">
          <MdChat className="w-4 h-4 shrink-0 text-neutral-300" />
          <span>{comment}</span>
        </div>
      )}
    </div>
  );
};
