import { ChargeAttemptState as ApiChargeAttemptState } from '@cooltra/api';
import { Badge, BadgeProps, BadgeVariant } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

const stateBadgeVariants: Record<ApiChargeAttemptState, BadgeVariant> = {
  FAILED: 'danger',
  PENDING: 'warning',
  SUCCEEDED: 'success',
};

export type ChargeAttemptStateProps = Omit<
  BadgeProps,
  'children' | 'variant' | 'emphasis'
> & {
  state: ApiChargeAttemptState;
};

export const ChargeAttemptState = ({
  state,
  ...rest
}: ChargeAttemptStateProps) => (
  <Badge variant={stateBadgeVariants[state]} {...rest}>
    <FormattedMessage {...messages[state]} />
  </Badge>
);
