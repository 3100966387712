import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteTechnicalInspection: {
    defaultMessage: 'Delete technical inspection',
  },
  success: {
    defaultMessage: 'You have successfully deleted the technical inspection!',
  },
});
