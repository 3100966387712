import { RentalListItem } from '@cooltra/api';
import { Link } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';

import { CustomIcon } from '../../CustomIcon';
import { MultilineDateTime } from '../../DateTime';

import messages from './messages';

type DropOffListItemProps = Pick<RentalListItem, 'dropOff'>;

export const DropOffListItem = ({ dropOff }: DropOffListItemProps) => {
  const { formatMessage } = useIntl();

  if (!dropOff) {
    return (
      <span className="text-sm text-neutral-500">
        <FormattedMessage {...messages.pending} />
      </span>
    );
  }

  const { latitude, longitude, createdAt } = dropOff;

  const createdAtDate = new Date(createdAt);

  return (
    <div className="flex items-center -ml-2">
      <Link
        href={`http://www.google.com/maps/place/${latitude},${longitude}`}
        target="_blank"
        className="pr-1 text-3xl"
        aria-label={formatMessage(messages.dropOffLocation)}
      >
        <CustomIcon.Pin />
      </Link>
      <MultilineDateTime date={createdAtDate} />
    </div>
  );
};
