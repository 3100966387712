import { FormattedMessage, useIntl } from 'react-intl';
import { Card, Divider, Button } from '@cooltra/ui';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  checkUser,
  User,
  UserDocumentsPayload,
  useUserDocumentsMutation,
} from '@cooltra/api';
import {
  ZeusErrorData,
  getErrorStatus,
  getZeusErrorMessage,
  getRequestConfig,
} from '@cooltra/axios';
import { useToggle } from '@cooltra/hooks';
import { Form, FormProvider, FormHelpers, FormButton } from '@cooltra/form';
import { MdOutlineChevronRight } from 'react-icons/md';

import { useNotification } from '~/hooks';
import {
  UnsavedChangesPrompt,
  IdCardFields,
  DriverLicenseForm,
} from '~/common';

import { Photo } from '../Photo/Photo';
import {
  DocumentsFormValues,
  getDocumentsFormInitialValues,
  getUserDocumentsPayload,
} from '../documents-form';
import { RemoveDriverLicense } from '../RemoveDriverLicense/RemoveDriverLicense';
import { RemoveIdCard } from '../RemoveIdCard/RemoveIdCard';
import { RestoreDriverLicense } from '../RestoreDriverLicense/RestoreDriverLicense';
import { RestoreIdCard } from '../RestoreIdCard/RestoreIdCard';

import { validateDocuments } from './validate-documents-dl';
import messages from './messages';

export type DocumentsProps = {
  user: User;
};

export const DocumentsForm = ({ user }: DocumentsProps) => {
  const hasDriverLicense = !!user.photos.driverLicenseFront;
  const hasIdCard = !!user.photos.idCardFront;
  const isIdCardOptional = user.system === 'Paris';

  const [isDLSectionVisible, { toggleOn, toggleOff }] =
    useToggle(hasDriverLicense);

  const [isIdCardSectionVisible, { toggle }] = useToggle(hasIdCard);

  const intl = useIntl();
  const { id = '' } = useParams<'id'>();
  const { getAccessTokenSilently } = useAuth0();
  const userDocumentsMutation = useUserDocumentsMutation(id);
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleSubmit = (
    values: DocumentsFormValues,
    { setErrors, resetForm }: FormHelpers<DocumentsFormValues>
  ) => {
    if (isIdCardSectionVisible || isDLSectionVisible) {
      return getAccessTokenSilently()
        .then((accessToken) =>
          checkUser(
            id,
            {
              ...(isIdCardSectionVisible && {
                idCardNumber: values.idCardNumber,
              }),
              ...(isDLSectionVisible && {
                driverLicenseNumber: values.driverLicenseNumber,
              }),
            },
            getRequestConfig(accessToken)
          )
        )
        .then((result) => {
          if (
            result.idCardNumber === false ||
            result.driverLicenseNumber === false
          ) {
            const duplicatedMessage = intl.formatMessage(messages.duplicated);
            setErrors({
              ...(result.idCardNumber === false &&
                isIdCardSectionVisible && { idCardNumber: duplicatedMessage }),
              ...(result.driverLicenseNumber === false &&
                isDLSectionVisible && {
                  driverLicenseNumber: duplicatedMessage,
                }),
            });
            return false as unknown;
          }
          return userDocumentsMutation.mutateAsync(
            getUserDocumentsPayload(
              values,
              user.version
            ) as UserDocumentsPayload
          );
        })
        .then((result) => {
          if (result !== false) {
            resetForm(values);
            addSuccessNotification(
              intl.formatMessage(messages.submissionSuccess)
            );
          }
        })
        .catch((error: AxiosError<ZeusErrorData>) => {
          if (getErrorStatus(error) === 409) {
            addErrorNotification(
              intl.formatMessage(messages.conflictErrorMessage)
            );
          } else {
            const errorMessage = getZeusErrorMessage(error);
            addErrorNotification(errorMessage);
          }
        });
    }

    return userDocumentsMutation
      .mutateAsync(
        getUserDocumentsPayload(values, user.version) as UserDocumentsPayload
      )
      .then(() => {
        resetForm(values);
        addSuccessNotification(intl.formatMessage(messages.submissionSuccess));
      })
      .catch((error: AxiosError<ZeusErrorData>) => {
        if (getErrorStatus(error) === 409) {
          addErrorNotification(
            intl.formatMessage(messages.conflictErrorMessage)
          );
        } else {
          const errorMessage = getZeusErrorMessage(error);
          addErrorNotification(errorMessage);
        }
      });
  };

  return (
    <FormProvider
      initialValues={getDocumentsFormInitialValues(user)}
      onSubmit={handleSubmit}
      validate={validateDocuments(
        intl,
        isIdCardSectionVisible,
        isDLSectionVisible
      )}
    >
      <Form>
        <div data-testid="EDIT_USER_DOCUMENTS">
          <Card className="mb-5">
            <div className="p-10">
              <div className="flex items-center">
                <h2 className="text-xl font-semibold text-neutral-400  mr-4">
                  <FormattedMessage {...messages.idCard} />
                </h2>
                {hasIdCard && isIdCardOptional && (
                  <>
                    {isIdCardSectionVisible ? (
                      <RemoveIdCard onRemove={toggle} />
                    ) : (
                      <RestoreIdCard onRestore={toggle} />
                    )}
                  </>
                )}
                {!hasIdCard && isIdCardOptional && (
                  <>
                    {isIdCardSectionVisible ? (
                      <RemoveIdCard onRemove={toggle} />
                    ) : (
                      <Button size="sm" onClick={toggle}>
                        <FormattedMessage {...messages.add} />
                      </Button>
                    )}
                  </>
                )}
              </div>
              {(!isIdCardOptional || isIdCardSectionVisible) && (
                <div className="grid grid-cols-2 gap-x-8 mt-8">
                  <div>
                    <div className="overflow-hidden rounded-md">
                      <Photo name="idCardFront" side="front" />
                      <div className="h-px" />
                      <Photo name="idCardBack" side="back" />
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-10">
                    <IdCardFields.IdNumber />
                    <IdCardFields.TaxIdNumber />
                    <IdCardFields.IdCardCountry />
                  </div>
                </div>
              )}
            </div>
            <Divider className="my-3" />
            <div className="p-10">
              <div className="flex items-center">
                <h2 className="text-xl font-semibold text-neutral-400 mr-4">
                  <FormattedMessage {...messages.driverLicense} />
                </h2>
                {hasDriverLicense && (
                  <>
                    {isDLSectionVisible ? (
                      <RemoveDriverLicense onRemove={toggleOff} />
                    ) : (
                      <RestoreDriverLicense onRestore={toggleOn} />
                    )}
                  </>
                )}
                {!hasDriverLicense && (
                  <>
                    {isDLSectionVisible ? (
                      <RemoveDriverLicense onRemove={toggleOff} />
                    ) : (
                      <Button
                        data-testid="ID_CARD_ADD_BUTTON"
                        size="sm"
                        onClick={toggleOn}
                      >
                        <FormattedMessage {...messages.add} />
                      </Button>
                    )}
                  </>
                )}
              </div>
              {isDLSectionVisible && (
                <div
                  className="grid grid-cols-2 gap-x-8 mt-8"
                  data-testid="DRIVER_LICENSE_SECTION"
                >
                  <div>
                    <div className="overflow-hidden rounded-md">
                      <Photo name="driverLicenseFront" side="front" />
                      <div className="h-px" />
                      <Photo name="driverLicenseBack" side="back" />
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-10">
                    <DriverLicenseForm.DriverLicenseNumber />
                    <DriverLicenseForm.ExpirationDate />
                    <DriverLicenseForm.DriverLicenseCountry />
                    <DriverLicenseForm.Category />
                  </div>
                </div>
              )}
            </div>
            <Divider className="my-3" />
            <div className="p-10 mb-3">
              <h2 className="text-xl font-semibold text-neutral-400 mb-8">
                <FormattedMessage {...messages.selfie} />
              </h2>
              <div className="grid grid-cols-2 gap-x-8">
                <div>
                  <div className="overflow-hidden rounded-md">
                    <Photo name="selfie" />
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <UnsavedChangesPrompt />
          <div className="flex justify-end">
            <FormButton
              trailingIcon={<MdOutlineChevronRight className="text-xl" />}
            >
              <FormattedMessage {...messages.submitBtn} />
            </FormButton>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};
