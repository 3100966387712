import {
  Rental,
  RentalPromotionDiscountItem,
  RentalDiscountItem,
} from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { Amount } from '~/common';

import { pricingDiscountTypesMessages } from './messages';
import { PricingDiscountItem } from './PricingDiscountItem/PricingDiscountItem';

export type PricingDiscountBreakdownProps = Pick<Rental, 'pricing'>;

function isDiscountItem(
  item: RentalDiscountItem
): item is RentalPromotionDiscountItem {
  return (item as RentalPromotionDiscountItem).type === 'DISCOUNT';
}

export const PricingDiscountBreakdown = ({
  pricing,
}: PricingDiscountBreakdownProps) => {
  if (!pricing) {
    return <></>;
  }

  return (
    <div
      className="flex flex-col gap-2"
      data-testid="RENTAL_PRICING_SUMMARY_DISCOUNT_BREAKDOWN"
    >
      {pricing.discountBreakdown
        .filter((discount) => discount.type !== 'PASS')
        .map((discount) => (
          <div
            key={discount.type}
            className="flex justify-between items-center"
          >
            {isDiscountItem(discount) ? (
              <PricingDiscountItem {...discount} />
            ) : (
              <span className="text-sm text-neutral-400">
                <FormattedMessage
                  {...pricingDiscountTypesMessages[discount.type]}
                />
              </span>
            )}
            <Amount
              className="text-sm"
              value={-discount.amount.value}
              currency={discount.amount.currency}
            />
          </div>
        ))}
    </div>
  );
};
