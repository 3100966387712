import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
  },
  confirmCancel: {
    defaultMessage: 'Are you sure you want to cancel this contract?',
  },
  returnDepositBeforeCancelingContract: {
    defaultMessage:
      'You must return the deposit before canceling the contract.',
  },
  cancelAwaitingPaymentsBeforeMarkingContractAsNoShow: {
    defaultMessage:
      'You must cancel the stripe QR payment before canceling the contract.',
  },
  paidPayments: {
    defaultMessage:
      'Before canceling the contract you must return the amount charged.',
  },
  basicInsuranceWarning: {
    defaultMessage:
      'This contract includes basic insurance coverage, therefore cancellation with refund of the amount is not included.',
  },
  successfulCancelConfirmation: {
    defaultMessage: 'Contract marked as “Cancelled”',
  },
});
