import { defineMessages } from 'react-intl';

export default defineMessages({
  licensePlate: {
    defaultMessage: 'License plate',
  },
  duration: {
    defaultMessage: 'Duration',
  },
  pickUp: {
    defaultMessage: 'Pick up',
  },
  dropOff: {
    defaultMessage: 'Drop off',
  },
});
