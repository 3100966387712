import { defineMessages } from 'react-intl';

export default defineMessages({
  userFor: {
    defaultMessage: 'User for',
  },
  year: {
    defaultMessage: 'year',
  },
  years: {
    defaultMessage: 'years',
  },
});
