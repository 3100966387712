import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { FormInputField } from '@cooltra/form';

import { FormFieldWithName } from '~/utils/form';

import messages from './messages';

export const FirstName = ({ name = 'firstName' }: FormFieldWithName) => (
  <FormInputField
    name={name}
    id="first-name"
    label={
      <InputLabel htmlFor="first-name">
        <FormattedMessage {...messages.firstName} />
      </InputLabel>
    }
  />
);
