import { createContext } from 'react';
import { WorkShiftWorkUnit } from '@cooltra/api/src/modules/work-shifts/types';

export type ShiftRouteContextShape = {
  routeCoordinates: [number, number][];
  workUnits: WorkShiftWorkUnit[];
};

export const ShiftRouteCoordinatesContext = createContext<
  ShiftRouteContextShape | undefined
>(undefined);
