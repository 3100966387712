import { Divider } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { ServiceFields } from '~/common';

import messages from './messages';

export const EditTechnicalInspectionForm = () => {
  return (
    <div data-testid="EDIT_SERVICE">
      <div>
        <h3 className="text-xl font-semibold text-neutral-400 mb-4">
          <FormattedMessage {...messages.mainInformation} />
        </h3>
        <div className="flex flex-col gap-y-6">
          <div className="w-1/2">
            <ServiceFields.ScheduledAt optional={false} />
          </div>
        </div>
      </div>
      <Divider className="my-6" />
      <div>
        <h3 className="text-xl font-semibold text-neutral-400 mb-4">
          <FormattedMessage {...messages.completedServiceData} />
        </h3>
        <div className="w-full grid grid-cols-2 gap-4">
          <ServiceFields.ServicedBy />
          <ServiceFields.ServicedAt />
        </div>
      </div>
    </div>
  );
};
