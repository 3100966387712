import { classNames } from '@cooltra/utils';
import { HTMLAttributes, forwardRef } from 'react';

export type CalendarCellProps = HTMLAttributes<HTMLDivElement>;

export const CalendarCell = forwardRef<HTMLDivElement, CalendarCellProps>(
  ({ className, ...rest }, ref) => (
    <div
      ref={ref}
      className={classNames('border-neutral-100', className)}
      {...rest}
    />
  )
);
