import { Routes, Route, Navigate } from 'react-router-dom';

import { Review } from './Review';
import { Account } from './Account/Account';

export const User = () => (
  <Routes>
    <Route path="review/*" element={<Review />} />
    <Route path="account/*" element={<Account />} />
    <Route path="*" element={<Navigate replace to="account/overview" />} />
  </Routes>
);
