import { CooltraForEmployeePromotionListItem } from '@cooltra/api';
import { RouterCardLink } from '@cooltra/navigation';
import { classNames } from '@cooltra/utils';
import { isPast } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { MultilineDateTime, PromotionState } from '~/common';

import { CooltraForEmployeeRowLayout } from '../CooltraForEmployeeRowLayout';

import messages from './messages';

export type CooltraForEmployeeCardProps = {
  cooltraForEmployeePromotion: CooltraForEmployeePromotionListItem;
};

export const CooltraForEmployeeCard = ({
  cooltraForEmployeePromotion: {
    cooltraForEmployeePromotionId,
    title,
    subscription,
    redeemableFrom,
    redeemableUntil,
    numberOfUses,
    numberOfCodes,
  },
}: CooltraForEmployeeCardProps) => (
  <RouterCardLink
    to={`/offers/cooltra-for-employees/${cooltraForEmployeePromotionId}/edit`}
    className={classNames(
      redeemableUntil && isPast(new Date(redeemableUntil))
        ? 'opacity-50'
        : 'opacity-100'
    )}
  >
    <CooltraForEmployeeRowLayout
      className="min-h-24 py-4"
      promotion={
        <>
          <span className="block text-base">
            <span className="text-base text-neutral-1000 font-semibold">
              {title}
            </span>
            <span className="pl-2 text-sm text-neutral-600">
              <FormattedMessage
                {...messages.numberOfCodes}
                values={{
                  numberOfCodes,
                }}
              />
            </span>
          </span>
        </>
      }
      subscription={
        <span className="text-center font-semibold">{subscription.name}</span>
      }
      redeemableFrom={
        redeemableFrom && (
          <MultilineDateTime emphasize="date" date={new Date(redeemableFrom)} />
        )
      }
      redeemableUntil={
        redeemableUntil && (
          <MultilineDateTime
            emphasize="date"
            date={new Date(redeemableUntil)}
          />
        )
      }
      uses={
        <span className="text-base text-neutral-900 tabular-nums">
          {numberOfUses}
        </span>
      }
      state={
        <PromotionState
          validFrom={redeemableFrom}
          validUntil={redeemableUntil}
        />
      }
    />
  </RouterCardLink>
);
