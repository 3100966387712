import { defineMessages } from 'react-intl';

import { PromotionCodeMode } from '../../PromotionForm';

export default defineMessages<PromotionCodeMode>({
  'single-code': {
    defaultMessage: 'Single code',
  },
  'multi-code': {
    defaultMessage: 'Multiple codes',
  },
});
