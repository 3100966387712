import { Route, Routes } from 'react-router-dom';

import { PermissionCheck } from '~/common';

import { Zones } from '../Zones/Zones';
import { NewRestriction } from '../NewRestriction';
import { NewBonusZone } from '../NewBonusZone';

export const ZonesRoutes = () => (
  <Routes>
    <Route
      path="restrictions/new"
      element={
        <PermissionCheck permission="write:geofence_restrictions">
          <NewRestriction />
        </PermissionCheck>
      }
    />
    <Route
      path="bonus-zones/new"
      element={
        <PermissionCheck permission="write:bonus_zones">
          <NewBonusZone />
        </PermissionCheck>
      }
    />
    <Route path="*" element={<Zones />} />
  </Routes>
);
