import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { useIntl } from 'react-intl';

import { cities } from './messages';

export const useSystemOptions = () => {
  const { data: claims } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();
  const accessibleSystems = claims?.systems || [];

  return accessibleSystems
    .map((system) => ({
      value: system,
      label: formatMessage(cities[system]),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
