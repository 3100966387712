import { defineMessages } from 'react-intl';

export default defineMessages({
  tags: {
    defaultMessage: 'Task tags',
  },
  noTags: {
    defaultMessage: 'No tags',
  },
  placeholder: {
    defaultMessage: 'All tags',
  },
});
