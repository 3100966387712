import { VehicleListItem } from '@cooltra/station-based-api';
import { RouterCardLink } from '@cooltra/navigation';

import { VehicleRowLayout } from '../VehicleRowLayout';

import { VehicleServicePoint } from './VehicleServicePoint/VehicleServicePoint';

export type VehicleCardProps = {
  vehicle: VehicleListItem;
};

export const VehicleCard = ({
  vehicle: { modelName, licensePlate, vehicleId, servicePointId, inTransit },
}: VehicleCardProps) => (
  <RouterCardLink to={`/pos/vehicles/${vehicleId}`} className="h-24">
    <VehicleRowLayout
      vehicle={
        <span className="flex flex-col gap-1">
          <span className="text-neutral-900 font-semibold uppercase">
            {licensePlate}
          </span>
          <span className="text-neutral-600 text-xs">{modelName}</span>
        </span>
      }
      servicePoint={
        servicePointId ? (
          <VehicleServicePoint
            servicePointId={servicePointId}
            inTransit={inTransit}
          />
        ) : (
          <div />
        )
      }
    />
  </RouterCardLink>
);
