import { defineMessages } from 'react-intl';
import { ContractStatus } from '@cooltra/station-based-api';

export default defineMessages<ContractStatus>({
  NEW: {
    defaultMessage: 'New',
  },
  BOOKED: {
    defaultMessage: 'Booked',
  },
  CANCELED: {
    defaultMessage: 'Canceled',
  },
  NO_SHOW: {
    defaultMessage: 'No show',
  },
  DRAFT: {
    defaultMessage: 'Draft',
  },
  ACTIVE: {
    defaultMessage: 'Active',
  },
  CLOSED: {
    defaultMessage: 'Closed',
  },
});
