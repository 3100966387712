import { defineMessages } from 'react-intl';

export default defineMessages({
  mileage: {
    defaultMessage: 'Mileage:',
  },
  noData: {
    defaultMessage: 'N/A',
  },
});
