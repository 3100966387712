import { useSearchParams } from 'react-router-dom';
import { fetchTask, useProblemReportQuery, Vehicle } from '@cooltra/api';
import { getRequestConfig } from '@cooltra/axios';
import { useFormContext } from '@cooltra/form';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import { IssueFields, TaskFields, TaskFormValues } from '~/common';

export const CreateTaskForm = ({
  vehicle,
}: {
  vehicle: Vehicle | undefined;
}) => {
  const [searchParams] = useSearchParams();
  const problemReportId = searchParams.get('problemReportId') || null;
  const { setValues } = useFormContext<TaskFormValues>();

  const { getAccessTokenSilently } = useAuth0();

  const { data: problemReport } = useProblemReportQuery(problemReportId || '', {
    enabled: problemReportId != null,
  });

  useEffect(() => {
    if (problemReport) {
      setValues({ description: problemReport.description });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [problemReport]);

  const fetchTasks = () =>
    getAccessTokenSilently().then((accessToken) =>
      Promise.all(
        (problemReport?.taskIds || []).map((taskId) =>
          fetchTask(taskId, getRequestConfig(accessToken))
        )
      )
    );

  const { data: tasks = [] } = useQuery({
    queryKey: ['problem-report-tasks', problemReportId],
    queryFn: fetchTasks,
    enabled: !!problemReport?.problemReportId,
  });

  return (
    <div data-testid="CREATE_TASK" className="flex flex-col gap-y-6 gap-x-8">
      <TaskFields.Title
        system={vehicle?.system}
        disabled={!vehicle?.system}
        problemReportDescription={problemReport?.description}
        ignored={tasks.map(({ title }) => title)}
      />
      <div className="grid grid-cols-2 gap-x-8">
        <IssueFields.Assignee />
        <IssueFields.Operator
          systems={vehicle?.system ? [vehicle.system] : []}
        />
      </div>
      <TaskFields.Tags />
      <IssueFields.Description />
      <TaskFields.Blocking />
    </div>
  );
};
