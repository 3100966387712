import { RouterLink } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { stringifyParams } from '@cooltra/utils';

import { useVehiclesFilters } from '~/libs/vehicles-filters';

import messages from './messages';

export const VehiclesViews = () => {
  const { values } = useVehiclesFilters();

  return (
    <div className="flex p-x-8 mb-12 gap-7">
      <span className="font-semibold text-neutral-600">
        <FormattedMessage {...messages.allVehicles} />
      </span>
      <RouterLink
        className="font-semibold text-primary-500"
        to={`/vehicles/map?${stringifyParams(values)}`}
      >
        <FormattedMessage {...messages.mapView} />
      </RouterLink>
      <RouterLink
        className="font-semibold text-primary-500"
        to={`/vehicles/alarms`}
      >
        <FormattedMessage {...messages.alarms} />
      </RouterLink>
    </div>
  );
};
