import { classNames, equal } from '@cooltra/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';
import { ButtonHTMLAttributes } from 'react';
import { MdTune } from 'react-icons/md';

import { vehiclesFiltersInitialValues } from '~/libs/vehicles-filters';

import messages from './messages';

export type VehicleFiltersButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const VehicleFiltersButton = ({
  onClick,
  className,
  ...rest
}: VehicleFiltersButtonProps) => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext();

  const isActive = !equal(vehiclesFiltersInitialValues, values);

  return (
    <button
      onClick={onClick}
      className={classNames(
        'bg-neutral-0 shadow rounded-md h-11 px-5',
        isActive ? 'text-primary-500' : 'text-neutral-500',
        className
      )}
      {...rest}
    >
      <span className="mr-1.5 -ml-1 leading-0">
        <Icon className="rotate-90 text-xl">
          <MdTune />
        </Icon>
      </span>
      <span className="text-md font-semibold">
        <FormattedMessage {...messages.filters} />
      </span>
      {isActive && (
        <span
          aria-label={formatMessage(messages.active)}
          className="absolute w-3 h-3 inline-block rounded-full bg-primary-500 bottom-0 right-0 translate-x-1/2 translate-y-1/2"
        />
      )}
    </button>
  );
};
