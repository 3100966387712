import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type PromotionRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  promotion: ReactNode;
  subscription: ReactNode;
  redeemableFrom: ReactNode | null;
  redeemableUntil: ReactNode | null;
  uses: ReactNode;
  state: ReactNode;
};

export const CooltraForEmployeeRowLayout = ({
  promotion,
  subscription,
  redeemableFrom,
  redeemableUntil,
  uses,
  state,
  className,
  ...rest
}: PromotionRowLayoutProps) => (
  <div className={classNames('flex h-full w-full', className)} {...rest}>
    <div className="flex items-center w-full px-8 gap-4">
      <div className="flex-1">{promotion}</div>
      <div className="w-64 flex shrink-0 justify-center">{subscription}</div>
      <div className="w-28 shrink-0">{redeemableFrom}</div>
      <div className="w-28 shrink-0">{redeemableUntil}</div>
      <div className="w-20 flex justify-center shrink-0">{uses}</div>
      <div className="w-24 flex justify-center shrink-0">{state}</div>
    </div>
  </div>
);
