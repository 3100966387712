import { InvoiceLegalEntity } from '@cooltra/api';
import { FormSelectField, useField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { getLegalEntityTaxRates } from '~/utils/legal-entity';

import messages from './messages';

export const TaxRate = () => {
  const { value } = useField<InvoiceLegalEntity>('legalEntity');
  const taxRates = getLegalEntityTaxRates(value);

  return (
    <FormSelectField
      name="taxRate"
      options={taxRates.map((rate) => ({
        value: rate.toString(),
        label: `${rate * 100}%`,
      }))}
      id="tax-rate"
      label={
        <InputLabel htmlFor="tax-rate">
          <FormattedMessage {...messages.label} />
        </InputLabel>
      }
    />
  );
};
