import { Card, Divider } from '@cooltra/ui';
import {
  classNames,
  getVehicleName,
  vehicleImages,
  VehicleTypeName,
} from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';
import { ContractServiceType } from '@cooltra/station-based-api';

import { useFilters } from '../useFilters';

import messages from './messages';

export type ContractSummaryVehicleType = {
  name: string;
  type: ContractServiceType;
};

type GroupVehicleTypesByNameAndTypeCounter = {
  pickUpCounter: number;
  dropOffCounter: number;
};

type GroupVehicleTypesByNameAndType = {
  [vehicleType: string]: GroupVehicleTypesByNameAndTypeCounter;
};

type ContractsSummaryProps = {
  vehicleTypes: ContractSummaryVehicleType[];
};

export const ContractsSummary = ({ vehicleTypes }: ContractsSummaryProps) => {
  const { serviceType } = useFilters();
  const groupVehicleTypesByNameAndType: GroupVehicleTypesByNameAndType = {};
  let totalPickUps = 0;
  let totalDropOffs = 0;
  const displayPickUpCounter =
    serviceType === null || serviceType === 'PICK_UP';
  const displayDropOffCounter =
    serviceType === null || serviceType === 'DROP_OFF';

  vehicleTypes.forEach((vehicleType) => {
    const groupVehicleTypesByNameAndTypeElement =
      groupVehicleTypesByNameAndType[vehicleType.name] || {};
    const counter: GroupVehicleTypesByNameAndTypeCounter = {
      pickUpCounter: groupVehicleTypesByNameAndTypeElement?.pickUpCounter || 0,
      dropOffCounter:
        groupVehicleTypesByNameAndTypeElement?.dropOffCounter || 0,
    };

    if (vehicleType.type === 'DROP_OFF') {
      counter['dropOffCounter'] = counter['dropOffCounter'] + 1;
      totalDropOffs += 1;
    } else {
      counter['pickUpCounter'] = counter['pickUpCounter'] + 1;
      totalPickUps += 1;
    }

    groupVehicleTypesByNameAndType[vehicleType.name] = counter;
  });

  if (totalPickUps === 0 && totalDropOffs === 0) {
    return <></>;
  }

  const getTitle = () => {
    switch (serviceType) {
      case 'PICK_UP':
        return (
          <FormattedMessage
            {...messages.titleDeliveries}
            values={{
              pickUps: totalPickUps,
            }}
          />
        );
      case 'DROP_OFF':
        return (
          <FormattedMessage
            {...messages.titleReturns}
            values={{
              dropOffs: totalDropOffs,
            }}
          />
        );
      default:
        return (
          <FormattedMessage
            {...messages.title}
            values={{
              pickUps: totalPickUps,
              dropOffs: totalDropOffs,
            }}
          />
        );
    }
  };

  return (
    <div
      className="w-72 shrink-0 flex flex-col h-full overflow-scroll no-scrollbar pb-8"
      data-testid="CONTRACTS_SUMMARY"
    >
      <h2 className="font-semibold text-lg text-neutral-600 sticky top-0 z-10 bg-neutral-50 pb-4">
        {getTitle()}
      </h2>
      <div className="w-full flex flex-col gap-4">
        {Object.keys(groupVehicleTypesByNameAndType)
          .sort()
          .map((vehicleType) => {
            const vehicleTypeName = vehicleType as VehicleTypeName;

            return (
              <Card
                key={vehicleType}
                className="w-full p-6"
                data-testid="CONTRACT_SUMMARY_ITEM"
              >
                <div className="flex items-center gap-2">
                  <img
                    className="w-14"
                    src={vehicleImages[vehicleTypeName]}
                    alt={getVehicleName(vehicleTypeName)}
                  />
                  <h3 className="font-medium text-sm text-neutral-800">
                    {getVehicleName(vehicleTypeName)}
                  </h3>
                </div>
                <ul className="w-full mt-2 flex items-stretch gap-6">
                  {displayPickUpCounter && (
                    <li
                      className={classNames(
                        'flex-1 flex flex-col gap-2',
                        groupVehicleTypesByNameAndType[vehicleType]
                          .pickUpCounter === 0 && 'opacity-40'
                      )}
                      data-testid="CONTRACT_SUMMARY_DELIVERIES"
                    >
                      <span className="font-medium text-xs text-neutral-500">
                        <FormattedMessage {...messages.deliveries} />
                      </span>
                      <div className="flex items-center gap-1.5">
                        <span className="font-semibold text-xl text-neutral-800">
                          {
                            groupVehicleTypesByNameAndType[vehicleType]
                              .pickUpCounter
                          }
                        </span>
                      </div>
                    </li>
                  )}
                  {serviceType === null && <Divider direction="vertical" />}
                  {displayDropOffCounter && (
                    <li
                      className={classNames(
                        'flex-1 flex flex-col gap-2',
                        groupVehicleTypesByNameAndType[vehicleType]
                          .dropOffCounter === 0 && 'opacity-40'
                      )}
                      data-testid="CONTRACT_SUMMARY_RETURNS"
                    >
                      <span className="font-medium text-xs text-neutral-500">
                        <FormattedMessage {...messages.returns} />
                      </span>
                      <div className="flex items-center gap-1.5">
                        <span className="font-semibold text-xl text-neutral-800">
                          {
                            groupVehicleTypesByNameAndType[vehicleType]
                              .dropOffCounter
                          }
                        </span>
                      </div>
                    </li>
                  )}
                </ul>
              </Card>
            );
          })}
      </div>
    </div>
  );
};
