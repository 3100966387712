import { Placeholder } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type VehiclesWorkedProps = {
  vehiclesWorked?: number;
};

export const VehiclesWorked = ({ vehiclesWorked }: VehiclesWorkedProps) => (
  <div className="flex flex-col">
    {vehiclesWorked !== undefined ? (
      <span className="text-md text-neutral-1000 font-semibold">
        {vehiclesWorked}
      </span>
    ) : (
      <Placeholder className="w-6 h-5 mx-auto mb-1" />
    )}
    <span className="text-xs text-neutral-300">
      <FormattedMessage {...messages.vehiclesWorked} />
    </span>
  </div>
);
