import { defineMessages } from 'react-intl';

export default defineMessages({
  inProgress: {
    defaultMessage: 'In progress',
  },
  automaticallyClosedShift: {
    defaultMessage:
      'This shift automatically closed after 2 hours of inactivity. The end time is when the last action occurred.',
  },
});
