import { FormattedMessage } from 'react-intl';
import { FormInputField, useFormContext } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';

import { PromotionFormValues } from '../../PromotionForm';
import { PromotionStateType } from '../../PromotionState/PromotionState';

import messages from './messages';

export const Code = () => {
  const { values } = useFormContext<PromotionFormValues>();
  const { promotionCodeMode, editMode, stateType } = values;
  const editingNonScheduledPromotion =
    editMode && stateType !== PromotionStateType.SCHEDULED;

  if (promotionCodeMode !== 'single-code') {
    return <></>;
  }

  return (
    <div className="grid grid-cols-3 gap-x-5">
      <FormInputField
        name="code"
        id="code"
        disabled={editingNonScheduledPromotion}
        label={
          <InputLabel htmlFor="code">
            <FormattedMessage {...messages.code} />
          </InputLabel>
        }
      />
    </div>
  );
};
