import { useCooltraForEmployeePromotionMutation } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { FormButton, FormHelpers } from '@cooltra/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  CooltraForEmployeesForm,
  CooltraForEmployeesFormValues,
  cooltraForEmployeesInitialFormValues,
  Four0Three,
} from '~/common';
import { useNotification } from '~/hooks';

import { CooltraForEmployeesBackLink } from '../BackLink/CooltraForEmployeesBackLink';
import { CooltraForEmployeesFormFields } from '../CooltraForEmployeesFormFields';

import { getCreateCooltraForEmployeePromotionPayload } from './get-create-promotion-payload';
import messages from './messages';

export const CreateCooltraForEmployees = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { hasPermission } = useAuthClaimsQuery();
  const { mutateAsync, isPending } = useCooltraForEmployeePromotionMutation();
  const { addSuccessNotification, addErrorNotification } = useNotification();

  if (!hasPermission('write:cooltra_for_employees')) {
    return <Four0Three />;
  }

  const onSubmitCooltraForEmployeesForm = (
    values: CooltraForEmployeesFormValues,
    { resetForm }: FormHelpers<CooltraForEmployeesFormValues>
  ) => {
    const payload = getCreateCooltraForEmployeePromotionPayload(values);

    return mutateAsync(payload)
      .then(() => {
        resetForm(cooltraForEmployeesInitialFormValues);
        addSuccessNotification(
          formatMessage(messages.youHaveSuccessfullyCreatedThePromotion)
        );
        navigate(`/offers/cooltra-for-employees`);
      })
      .catch(({ response }) => {
        switch (response.status) {
          case 400:
            addErrorNotification(formatMessage(messages.badFormattedFields));
            break;
          case 422:
            addErrorNotification(
              formatMessage(messages.duplicatedCodePrefixOrInvalidSubscription)
            );
            break;
          default:
            addErrorNotification();
            break;
        }
      });
  };

  return (
    <div
      className="container min-w-screen-sm max-w-3xl py-12"
      data-testid="CREATE_SUBSCRIPTION_PROMOTION"
    >
      <div className="mb-9">
        <CooltraForEmployeesBackLink />
        <h1 className="text-3xl">
          <FormattedMessage {...messages.title} />
        </h1>
      </div>
      <CooltraForEmployeesForm
        initialValues={cooltraForEmployeesInitialFormValues}
        onSubmit={onSubmitCooltraForEmployeesForm}
      >
        <CooltraForEmployeesFormFields />
        <div className="flex justify-end mt-10">
          <FormButton loading={isPending}>
            <FormattedMessage {...messages.submitButton} />
          </FormButton>
        </div>
      </CooltraForEmployeesForm>
    </div>
  );
};
