import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox } from '@cooltra/ui';
import { ChangeEvent } from 'react';

import { ListColumnLabel } from '~/common';

import { VehicleRowLayout } from '../VehicleRowLayout/VehicleRowLayout';

import messages from './messages';

export type VehiclesListHeaderProps = {
  checked: boolean;
  onCheck: (checked: boolean) => void;
};

export const VehiclesListHeader = ({
  onCheck,
  checked,
}: VehiclesListHeaderProps) => {
  const { formatMessage } = useIntl();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onCheck(e.target.checked);
  };

  return (
    <VehicleRowLayout
      className="items-center"
      checkbox={
        <Checkbox
          checked={checked}
          aria-label={formatMessage(messages.selectAllVehicles)}
          onChange={handleChange}
        />
      }
      info={
        <ListColumnLabel>
          <FormattedMessage {...messages.vehicle} />
        </ListColumnLabel>
      }
      tasks={
        <ListColumnLabel>
          <FormattedMessage {...messages.tasks} />
        </ListColumnLabel>
      }
      location={
        <ListColumnLabel>
          <FormattedMessage {...messages.location} />
        </ListColumnLabel>
      }
      services={
        <ListColumnLabel>
          <FormattedMessage {...messages.services} />
        </ListColumnLabel>
      }
    />
  );
};
