import { Badge } from '@cooltra/ui';
import { VehicleTechnicalInspections } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type TechnicalInspectionOutOfTimeBadgeProps =
  VehicleTechnicalInspections;

export const TechnicalInspectionOutOfTimeBadge = ({
  remainingDaysToTechnicalInspection,
}: TechnicalInspectionOutOfTimeBadgeProps) => {
  if (remainingDaysToTechnicalInspection >= 0) {
    return <></>;
  }

  return (
    <Badge variant="danger" className="text-xs">
      <FormattedMessage {...messages.pastDue} />
    </Badge>
  );
};
