import { Incident } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { ScrollToTop } from '@cooltra/ui';

import {
  DividedCard,
  Log,
  ProblemReportPair,
  SystemPair,
  IssueFields,
  ClosedBadge,
  Context,
  IncidentBackLink,
} from '~/common';

import * as IncidentFields from '../IncidentFields';
import { Comments } from '../Comments/Comments';

import messages from './messages';

export type ViewIncidentProps = {
  incident: Incident;
};

export const ViewIncident = ({
  incident: {
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    description,
    incidentId,
    open = true,
    operatorGroupId,
    operatorId,
    problemReportId,
    rentalId,
    system,
    title,
  },
}: ViewIncidentProps) => (
  <div className="pb-32">
    <ScrollToTop />
    <DividedCard
      className="min-w-4xl max-w-5xl py-12"
      data-testid="VIEW_INCIDENT"
      renderHeader={
        <>
          <IncidentBackLink problemReportId={problemReportId} />
          <h2 className="text-3xl">
            <FormattedMessage {...messages.title} />
          </h2>
          {!open && (
            <div className="mt-4">
              <ClosedBadge />
            </div>
          )}
        </>
      }
      renderLeft={
        <div className="flex h-full flex-col gap-12 justify-between">
          <div className="flex flex-col gap-6">
            <Context rentalId={rentalId} />
            <SystemPair system={system} />
            {problemReportId && (
              <ProblemReportPair problemReportId={problemReportId} />
            )}
          </div>
          <div className="flex flex-col gap-6">
            <Log
              user="operator"
              action="create"
              userId={createdBy}
              date={createdAt}
            />
            {updatedBy && updatedAt && (
              <Log
                user="operator"
                action="update"
                userId={updatedBy}
                date={updatedAt}
              />
            )}
          </div>
        </div>
      }
      renderRight={
        <div className="flex flex-col gap-8">
          <IncidentFields.TitleStatic value={title} />
          <div className="grid grid-cols-2 gap-5">
            <IssueFields.AssigneeStatic value={operatorGroupId} />
            <IssueFields.OperatorStatic value={operatorId} />
          </div>
          <IssueFields.DescriptionStatic value={description} />
        </div>
      }
    />
    <div
      className="container min-w-4xl max-w-5xl"
      data-testid="INCIDENT_COMMENTS"
    >
      <h3 className="mb-12 text-2xl text-neutral-600">
        <FormattedMessage {...messages.incidentComments} />
      </h3>
      <Comments incidentId={incidentId} />
    </div>
  </div>
);
