import { defineMessages } from 'react-intl';

export default defineMessages({
  operator: {
    defaultMessage: 'Operator',
  },
  duration: {
    defaultMessage: 'Shift duration',
  },
});
