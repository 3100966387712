import { Rental } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { Amount } from '~/common';

import messages from './messages';

export type PricingTotalPriceProps = Pick<Rental, 'pricing'>;

export const PricingTotalPrice = ({ pricing }: PricingTotalPriceProps) => {
  if (!pricing) {
    return <></>;
  }

  return (
    <div
      className="flex justify-between items-center"
      data-testid="RENTAL_PRICING_SUMMARY_TOTAL_PRICE"
    >
      <span className="text-sm">
        <FormattedMessage {...messages.totalPrice} />
      </span>
      <Amount
        className="text-md font-semibold"
        value={pricing.totalPrice.value}
        currency={pricing.totalPrice.currency}
      />
    </div>
  );
};
