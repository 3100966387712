import { defineMessages } from 'react-intl';
import { PromotionTarget } from '@cooltra/station-based-api';

export default defineMessages({
  discount: {
    defaultMessage: '{discount}% - {offerType}',
  },
  credit: {
    defaultMessage: '{credit} - {offerType}',
  },
});

export const offerTypeMessages = defineMessages<PromotionTarget>({
  COUPON: {
    defaultMessage: 'Coupon',
  },
  WEB_OFFER: {
    defaultMessage: 'Web offer',
  },
});
