import { defineMessages } from 'react-intl';

export default defineMessages({
  allVehicles: {
    defaultMessage: 'All vehicles',
  },
  mapView: {
    defaultMessage: 'Map view',
  },
  alarms: {
    defaultMessage: 'Alarms',
  },
});
