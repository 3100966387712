import { differenceInMinutes } from 'date-fns';
import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';

export type ShiftDurationProps = HTMLAttributes<HTMLSpanElement> & {
  createdAt: string;
  finishedAt: string;
};

export const ShiftDuration = ({
  createdAt,
  finishedAt,
  className,
}: ShiftDurationProps) => {
  const totalMinutes = differenceInMinutes(
    new Date(finishedAt),
    new Date(createdAt)
  );

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes - hours * 60;

  const padWith0 = (num: number) => num.toString().padStart(2, '0');

  return (
    <span className={classNames('block tabular-nums', className)}>
      {`${padWith0(hours)}h${padWith0(minutes)}m`}
    </span>
  );
};
