import { Card, Placeholder } from '@cooltra/ui';

import { SubscriptionChargeRowLayout } from './SubscriptionChargeRowLayout';

export const SubscriptionChargeCardLoading = () => (
  <Card>
    <SubscriptionChargeRowLayout
      className="min-h-28 py-4"
      system={<Placeholder className="w-28 h-2" />}
      price={<Placeholder className="h-6 w-20" />}
      inputPrice={<Placeholder className="h-11 w-36" />}
      save={<Placeholder className="h-5 w-16" />}
    />
  </Card>
);
