import { defineMessages } from 'react-intl';

export default defineMessages({
  telematics: {
    defaultMessage: 'Telematics',
  },
  placeholder: {
    defaultMessage: 'All telematics',
  },
});
