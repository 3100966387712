import { defineMessages } from 'react-intl';

export default defineMessages({
  rentalOverview: {
    defaultMessage: 'Rental overview',
  },
  rentalTimeline: {
    defaultMessage: 'Rental timeline',
  },
});
