import { Created } from './Created/Created';
import { Keyword } from './Keyword/Keyword';
import { Status } from './Status/Status';
import { System } from './System/System';
import { Updated } from './Updated/Updated';

export const FilterFields = {
  Created,
  Keyword,
  Status,
  System,
  Updated,
};
