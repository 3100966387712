import { defineMessages } from 'react-intl';

export default defineMessages({
  personalInformation: {
    defaultMessage: 'Personal information',
  },
  identityDocument: {
    defaultMessage: 'Identity document',
  },
  driverLicense: {
    defaultMessage: 'Driver license',
  },
  selfie: {
    defaultMessage: 'Selfie',
  },
});
