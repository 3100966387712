import { Badge, BadgeVariant } from '@cooltra/ui';
import { HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { UserStateType } from '@cooltra/api';

import messages from './messages';

const stateVariants: { [key in UserStateType]: BadgeVariant } = {
  AWAITING_REVIEW: 'warning',
  ACTIVATED: 'success',
  SUSPENDED: 'danger',
  REJECTED: 'danger',
  CORRECTIONS_REQUESTED: 'warning',
  DELETED: 'danger',
};

export type UserStateBadgeProps = HTMLAttributes<HTMLSpanElement> & {
  state: UserStateType;
};

export const UserStateBadge = ({ state, ...rest }: UserStateBadgeProps) => (
  <Badge variant={stateVariants[state]} {...rest}>
    <FormattedMessage {...messages[state]} />
  </Badge>
);
