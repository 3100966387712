import { Route, Routes } from 'react-router-dom';

import {
  Four0Four,
  PermissionCheck,
  TaskFormProvider,
  taskInitialFormValues,
} from '~/common';

import { CreateTask } from './CreateTask/CreateTask';
import { Task } from './Task/Task';
import { TasksList } from './Tasks/TasksList';
import { TasksFiltersForm } from './Tasks/TasksFiltersForm';

export const Tasks = () => (
  <Routes>
    <Route
      path="new"
      element={
        <PermissionCheck permission="write:tasks">
          <TaskFormProvider initialValues={taskInitialFormValues}>
            <CreateTask />
          </TaskFormProvider>
        </PermissionCheck>
      }
    />
    <Route path="/:taskId/*" element={<Task />} />
    <Route
      path="/"
      element={
        <TasksFiltersForm>
          <TasksList />
        </TasksFiltersForm>
      }
    />
    <Route path="*" element={<Four0Four />} />
  </Routes>
);
