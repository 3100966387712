import { FormInputField, useFormContext } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { CooltraForEmployeesFormValues } from '~/common';

import messages from './messages';

export const Title = () => {
  const {
    values: { editMode },
  } = useFormContext<CooltraForEmployeesFormValues>();

  return (
    <FormInputField
      name="title"
      id="title"
      disabled={editMode}
      label={
        <InputLabel htmlFor="title">
          <FormattedMessage {...messages.title} />
        </InputLabel>
      }
    />
  );
};
