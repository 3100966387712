import { Location as LocationAPI } from '@cooltra/api';
import { Link } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { TimeAgo } from '~/common';

import { ReleaseFromLocation } from '../../../ActionButtons';

import messages from './messages';

export type LocationProps = {
  location: LocationAPI;
  vehicleId?: string;
  href: string;
  inLocationFrom?: string;
};

export const Location = ({
  vehicleId,
  location,
  href,
  inLocationFrom,
}: LocationProps) => (
  <div>
    <span className="text-xs text-neutral-400 block mb-0.5">
      <FormattedMessage {...messages.inALocation} />{' '}
      {inLocationFrom && (
        <span>
          {' '}
          <FormattedMessage {...messages.since} />{' '}
          <TimeAgo ISODate={inLocationFrom} />
        </span>
      )}
    </span>
    <Link
      className="text-sm font-semibold truncate"
      href={href}
      rel="noreferrer"
      target="_blank"
    >
      {location.name}
    </Link>
    {vehicleId && (
      <div className="mt-3 flex justify-center">
        <ReleaseFromLocation vehicleId={vehicleId} />
      </div>
    )}
  </div>
);
