import { CreatePassPayload, RentalPass } from '@cooltra/api';
import { LanguageCode } from '@cooltra/countries';
import { format, parse } from 'date-fns';

import { dateAndTimeFormat, dateFormat, timeFormat } from '~/utils/date';
import { clean } from '~/utils/objects';

type TitleTranslationKey = `titleTranslations.${LanguageCode}`;
type TitleTranslations = Record<TitleTranslationKey, string>;

export type PassFormValues = {
  code: string;
  price: string;
  validFromDate: string;
  validFromTime: string;
  validUntilDate: string;
  validUntilTime: string;
  passDoesntExpire: boolean;
  benefitExpiresAfterHours: string;
  targetUserIds: string;
} & TitleTranslations;

export type PassFormErrors = Partial<Record<string, string | undefined>>;

const currentDate = format(new Date(), dateFormat);
const currentTime = format(new Date(), timeFormat);

export const initialPassFormValues: PassFormValues = {
  code: '',
  price: '',
  validFromDate: currentDate,
  validFromTime: currentTime,
  validUntilDate: '',
  validUntilTime: '',
  passDoesntExpire: false,
  benefitExpiresAfterHours: '',
  targetUserIds: '',
  'titleTranslations.en': '',
  'titleTranslations.es': '',
  'titleTranslations.it': '',
  'titleTranslations.pt': '',
  'titleTranslations.fr': '',
  'titleTranslations.ca': '',
  'titleTranslations.nl': '',
};

export const parsePass = ({
  code,
  price,
  validFrom,
  validUntil,
  benefitExpiresAfterHours,
  titleTranslations,
  targetUserIds,
}: RentalPass): PassFormValues => ({
  code,
  price: price.value.toString(),
  validFromDate: format(validFrom, dateFormat),
  validFromTime: format(validFrom, timeFormat),
  validUntilDate: validUntil ? format(validUntil, dateFormat) : '',
  validUntilTime: validUntil ? format(validUntil, timeFormat) : '',
  passDoesntExpire: !validUntil,
  benefitExpiresAfterHours: benefitExpiresAfterHours.toString(),
  targetUserIds: targetUserIds.join('\n'),
  'titleTranslations.en': titleTranslations.en,
  'titleTranslations.es': titleTranslations.es || '',
  'titleTranslations.it': titleTranslations.it || '',
  'titleTranslations.pt': titleTranslations.pt || '',
  'titleTranslations.fr': titleTranslations.fr || '',
  'titleTranslations.ca': titleTranslations.ca || '',
  'titleTranslations.nl': titleTranslations.nl || '',
});

const getISODateTime = (date: string, time: string) =>
  parse(`${date} ${time}`, dateAndTimeFormat, new Date()).toISOString();

export const formatPassTranslations = (values: PassFormValues) => {
  const titles = {
    es: values['titleTranslations.es'],
    it: values['titleTranslations.it'],
    pt: values['titleTranslations.pt'],
    fr: values['titleTranslations.fr'],
    ca: values['titleTranslations.ca'],
    nl: values['titleTranslations.nl'],
  };

  return {
    titleTranslations: {
      en: values['titleTranslations.en'],
      ...clean(titles),
    },
  };
};

const formatUserIds = (userIds: string): string[] => {
  const uuidLength = 36;
  return userIds
    .split('\n')
    .map((userId) => userId.trim())
    .filter((userId) => userId.length === uuidLength);
};

export const formatPassFormValues = (
  values: PassFormValues
): CreatePassPayload => {
  const {
    code,
    price,
    validFromDate,
    validFromTime,
    validUntilDate,
    validUntilTime,
    benefitExpiresAfterHours,
    targetUserIds,
  } = values;

  return {
    code,
    price: {
      value: Number(price),
      currency: 'EUR',
    },
    validFrom: getISODateTime(validFromDate, validFromTime),
    validUntil:
      !validUntilDate || !validUntilTime
        ? null
        : getISODateTime(validUntilDate, validUntilTime),
    benefitExpiresAfterHours: Number(benefitExpiresAfterHours),
    targetUserIds: formatUserIds(targetUserIds),
    ...formatPassTranslations(values),
  };
};
