import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  PricingGroupPricesListItem,
  usePricingGroupsPricesQuery,
} from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getParams } from '@cooltra/utils';

import { ErrorPage, Four0Three } from '~/common';

import { PriceListTypeContext } from '../PriceListTypeContext';
import { PriceListsNavigationTab } from '../PriceListsNavigationTab/PriceListsNavigationTab';

import { PricingGroupSelect } from './PricingGroupSelect/PricingGroupSelect';
import { PriceListsByPriceGroup } from './PriceListsByPriceGroup/PriceListsByPriceGroup';

export const VehicleTypePriceLists = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { search } = useLocation();
  const { type } = useContext(PriceListTypeContext);
  const params = getParams<'pricingGroup'>(search);
  const pricingGroup = params.string('pricingGroup');

  const [pricingGroupPricesListItems, setPricingGroupPricesListItems] =
    useState<PricingGroupPricesListItem[]>([]);

  const { data, isLoading, isError } = usePricingGroupsPricesQuery(
    { type },
    {
      enabled: hasPermission('read:prices'),
    }
  );

  const handleOnChangeSelectedPricingGroup = useCallback(
    (selectedPricingGroup: string) => {
      if (data && Object.keys(data).includes(selectedPricingGroup)) {
        setPricingGroupPricesListItems(data[selectedPricingGroup]);
      } else {
        setPricingGroupPricesListItems([]);
      }
    },
    [data]
  );

  useEffect(() => {
    if (pricingGroup) {
      handleOnChangeSelectedPricingGroup(pricingGroup);
    }
  }, [pricingGroup, handleOnChangeSelectedPricingGroup]);

  if (!hasPermission('read:prices')) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div className="container min-w-5xl max-w-6xl py-12">
      <div className="flex items-center justify-between mb-6">
        <PriceListsNavigationTab />
      </div>
      <PricingGroupSelect isLoading={isLoading} />
      <PriceListsByPriceGroup
        pricingGroupPricesListItems={pricingGroupPricesListItems}
        isLoading={isLoading}
      />
    </div>
  );
};
