import { defineMessages } from 'react-intl';

export default defineMessages({
  rentalIn: {
    defaultMessage: '{rentalShortId} with {userName}',
  },
  inARental: {
    defaultMessage: 'In a rental',
  },
  reservationIn: {
    defaultMessage: '{reservationShortId} with {userName}',
  },
  inAReservation: {
    defaultMessage: 'In a reservation',
  },
  inARentalOrReservation: {
    defaultMessage: 'In a rental or reservation',
  },
});
