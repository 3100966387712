import { PropsWithChildren, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonProps, Card } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';
import { MdOutlineMoreVert } from 'react-icons/md';

import { useOutsideElement } from '~/hooks';

import messages from './messages';

export type NiuActionsProps = PropsWithChildren & Pick<ButtonProps, 'emphasis'>;

export const ActionsDropdown = ({ children, emphasis }: NiuActionsProps) => {
  const ref = useRef(null);
  const [isActionsOpen, { toggle, toggleOff }] = useToggle(false);
  useOutsideElement(ref, toggleOff);

  return (
    <div ref={ref} className="relative">
      <Button
        size="sm"
        emphasis={emphasis}
        loading={false}
        onClick={toggle}
        trailingIcon={<MdOutlineMoreVert />}
      >
        <span className="text-sm">
          <FormattedMessage {...messages.moreActions} />
        </span>
      </Button>
      {isActionsOpen && (
        <Card className="w-48 absolute top-9 right-0 z-30">{children}</Card>
      )}
    </div>
  );
};
