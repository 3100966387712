import { defineMessages } from 'react-intl';

export default defineMessages({
  newSubscription: {
    defaultMessage: 'New subscription',
  },
  validFrom: {
    defaultMessage: 'Valid from',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  save: {
    defaultMessage: 'Save',
  },
});
