import { Incident, Rental, useIncidentsQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { RouterButton } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { List } from '@cooltra/ui';
import { stringifyParams } from '@cooltra/utils';

import { ErrorPage } from '~/common';

import { NoRentalIncidents } from '../NoRentalIncidents/NoRentalIncidents';
import { RentalIncidentsListHeader } from '../RentalIncidentsListHeader';
import {
  RentalIncidentCard,
  RentalIncidentCardLoading,
} from '../RentalIncidentCard';

import messages from './messages';

export type RentalIncidentsProps = {
  rental: Rental;
};

export const RentalIncidents = ({ rental }: RentalIncidentsProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  const { data, isLoading, isError } = useIncidentsQuery({
    rentalId: rental.rentalId,
    page: 1,
    limit: 1000,
  });

  const incidents = data?.pages
    ? ([] as Incident[]).concat.apply([], data.pages)
    : [];

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div
      className="container min-w-3xl max-w-5xl py-12"
      data-testid="RENTAL_INCIDENTS"
    >
      <div className="mb-6">
        <div className="flex justify-between items-center mb-12">
          <h2 className="text-base text-neutral-500 font-semibold">
            <FormattedMessage {...messages.heading} />
          </h2>
          {hasPermission('write:incidents') && (
            <RouterButton
              emphasis="high"
              to={`/incidents/new?${stringifyParams({
                rentalId: rental.rentalId,
                system: rental.system,
              })}`}
            >
              <FormattedMessage {...messages.addIncident} />
            </RouterButton>
          )}
        </div>
        <List
          loadingRows={2}
          isLoading={isLoading}
          idProp="incidentId"
          className="gap-5 pb-2"
          data={incidents}
          renderEmptyMessage={<NoRentalIncidents />}
          renderHeader={<RentalIncidentsListHeader />}
          renderLoadingRow={<RentalIncidentCardLoading />}
          renderRow={(incident: Incident) => (
            <RentalIncidentCard incident={incident} />
          )}
        />
      </div>
    </div>
  );
};
