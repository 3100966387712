import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';
import { useLocation } from 'react-router-dom';
import { getParams } from '@cooltra/utils';

import {
  shiftsFiltersInitialValues,
  ShiftsFiltersFormValues,
} from './shifts-filters-form';

const { system, minDate, maxDate, operatorId, sort } =
  shiftsFiltersInitialValues;

export const ShiftsFiltersForm = ({ children }: PropsWithChildren) => {
  const { search } = useLocation();
  const params = getParams<keyof ShiftsFiltersFormValues>(search);

  const initialValues = {
    system: params.array('system').length ? params.array('system') : system,
    minDate: params.string('minDate') || minDate,
    maxDate: params.string('maxDate') || maxDate,
    operatorId: params.string('operatorId') || operatorId,
    sort: params.string('sort') || sort,
  } as ShiftsFiltersFormValues;

  return <FormProvider initialValues={initialValues}>{children}</FormProvider>;
};
