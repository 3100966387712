import { InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormMultiSelectField } from '@cooltra/form';

import { useSystemOptions } from '../../System';

import messages from './messages';

export type SystemProps = {
  className?: string;
};

export const System = ({ className }: SystemProps) => {
  const { formatMessage } = useIntl();
  const systemOptions = useSystemOptions();

  if (systemOptions.length <= 1) {
    return null;
  }

  return (
    <FormMultiSelectField
      name="system"
      id="system"
      label={
        <InputLabel htmlFor="system">
          <FormattedMessage {...messages.system} />
        </InputLabel>
      }
      placeholder={formatMessage(messages.placeholder)}
      options={systemOptions}
      className={className}
    />
  );
};
