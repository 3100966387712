import { ProblemReportListItem } from '@cooltra/api';
import { classNames } from '@cooltra/utils';
import { RouterCardLink } from '@cooltra/navigation';

import {
  ByUser,
  Context,
  DateTime,
  ProblemReportCardInfo,
  ProblemReportTaskCount,
} from '~/common';

import { RentalProblemReportRowLayout } from '../RentalProblemReportRowLayout';

export type ProblemReportCardProps = {
  problemReport: ProblemReportListItem;
};

export const RentalProblemReportCard = ({
  problemReport: {
    problemReportId,
    tags,
    description,
    open,
    vehicle,
    numberImages,
    rentalId,
    reservationId,
    createdAt,
    createdBy,
  },
}: ProblemReportCardProps) => (
  <RouterCardLink
    isMultiAction
    to={`/problem-reports/${problemReportId}`}
    className={classNames(open ? 'opacity-100' : 'opacity-50')}
  >
    <RentalProblemReportRowLayout
      className="min-h-28 py-4"
      problem={
        <ProblemReportCardInfo
          open={open}
          numberImages={numberImages}
          description={description}
          tags={tags}
        />
      }
      context={<Context rentalId={rentalId} reservationId={reservationId} />}
      created={
        <div>
          <DateTime date={createdAt} />
          <ByUser user={createdBy} />
        </div>
      }
      tasks={
        <span className="flex justify-center text-sm text-neutral-400 font-normal">
          <ProblemReportTaskCount openTasksCount={vehicle.numberTasks} />
        </span>
      }
    />
  </RouterCardLink>
);
