import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { getLanguageOptions, LanguageCode } from '@cooltra/countries';
import { FormSelectField } from '@cooltra/form';

import { FormFieldWithName } from '~/utils/form';

import messages from './messages';

export type LanguageProps = FormFieldWithName<{
  skip?: LanguageCode[];
}>;

export const Language = ({ name = 'language', skip = [] }: LanguageProps) => (
  <FormSelectField
    name={name}
    id={name}
    label={
      <InputLabel htmlFor={name}>
        <FormattedMessage {...messages.language} />
      </InputLabel>
    }
    options={getLanguageOptions(skip)}
  />
);
