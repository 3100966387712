import { Route, Routes } from 'react-router-dom';

import { CreatePromotion } from './Mutations/Create/CreatePromotion';
import { EditPromotion } from './Mutations/Edit/EditPromotion';
import { PromotionsList, PromotionsFiltersForm } from './List';

export const Promotions = () => (
  <Routes>
    <Route
      path="/"
      element={
        <PromotionsFiltersForm>
          <PromotionsList />
        </PromotionsFiltersForm>
      }
    />
    <Route path="/new" element={<CreatePromotion />} />
    <Route path="/:promotionId/edit" element={<EditPromotion />} />
  </Routes>
);
