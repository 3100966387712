import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
  },
  confirm: {
    defaultMessage: 'Confirm',
  },
});
