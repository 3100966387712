import { RentalListItem } from '@cooltra/api';
import { RouterCardLink } from '@cooltra/navigation';
import { classNames } from '@cooltra/utils';

import { useSystemLabel } from '~/common/System';
import {
  DropOffListItem,
  PickUp,
  RentalDuration,
  RentalIncidentsBadge,
  RentalStateBadge,
  RentalSystem,
  UserListItemLink,
  VehicleLink,
} from '~/common';

import { RentalRowLayout } from '../RentalRowLayout';

export const RentalCard = ({
  rentalId,
  state,
  system,
  vehicle,
  pickUp,
  dropOff,
  user: { userId, name, provider },
  incidentIds,
}: RentalListItem) => {
  const { getSystemLabel } = useSystemLabel();

  return (
    <RouterCardLink
      to={`/rentals/${rentalId}`}
      data-testid={`RENTAL_CARD_${rentalId}`}
      className={classNames(
        'py-4',
        'relative',
        state === 'ACTIVE' && 'bg-success-50/50'
      )}
      isMultiAction
    >
      <RentalRowLayout
        pickUp={<PickUp pickUp={pickUp} />}
        dropOff={<DropOffListItem dropOff={dropOff} />}
        duration={
          <RentalDuration
            startDate={pickUp.createdAt}
            finishDate={dropOff?.createdAt || new Date()}
            className="text-neutral-400 text-sm"
          />
        }
        vehicle={<VehicleLink vehicle={vehicle} />}
        user={
          <UserListItemLink name={name} userId={userId} provider={provider} />
        }
        system={<RentalSystem>{getSystemLabel(system)}</RentalSystem>}
        incidents={
          <RentalIncidentsBadge rentalId={rentalId} incidentIds={incidentIds} />
        }
        state={<RentalStateBadge state={state} />}
      />
    </RouterCardLink>
  );
};
