import { Form, FormHelpers, FormProvider } from '@cooltra/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card } from '@cooltra/ui';
import {
  Contract,
  usePatchContractDriverMutation,
  useServicePointsQuery,
} from '@cooltra/station-based-api';
import { captureMessage } from '@sentry/react';

import {
  ContractPendingSignAlert,
  DriverLicenseForm,
  FormQRCode,
  FormSaveButton,
  IdCardFields,
  UnsavedChangesPrompt,
  UserFields,
} from '~/common';
import { useContractFlags, useNotification } from '~/hooks';

import * as DriverFields from '../DriverFields';
import { Photo } from '../Photo/Photo';
import { DriverLicenseNumber } from '../DriverFields/DriverLicenseNumber';
import { ContractDriverDetailsNavigationTabs } from '../ContractDriverDetailsNavigationTabs/ContractDriverDetailsNavigationTabs';
import { SendCheckInEmail } from '../SendCheckInEmail/SendCheckInEmail';

import messages from './messages';
import {
  DriverDetailsFormValues,
  formatDriverDetailsPayload,
  getDriverFormInitialValues,
  validateDriverDetailsForm,
} from './contract-driver-details-form';

export type ContractDriverDetailsProps = {
  contract: Contract;
};

export const ContractDriverDetails = ({
  contract,
}: ContractDriverDetailsProps) => {
  const intl = useIntl();
  const { isTaxIdRequired, isSignedOrPendingSignature } = useContractFlags();

  const { data: servicePoints = [] } = useServicePointsQuery({
    enabled: false,
  });

  const { status, contractId, vehicle, details } = contract;

  const isEbike = vehicle?.category === 'EBIKE';
  const mutation = usePatchContractDriverMutation(contractId);

  const { addErrorNotification } = useNotification();

  const handleSubmit = (
    values: DriverDetailsFormValues,
    { resetForm }: FormHelpers<DriverDetailsFormValues>
  ) =>
    mutation
      .mutateAsync(formatDriverDetailsPayload(values))
      .then(() => resetForm(values))
      .catch((e) => {
        captureMessage('ERROR_UPDATING_DRIVER_DETAILS', {
          extra: {
            error: e,
            contract,
          },
        });
        addErrorNotification();
      });

  const enabled = status === 'DRAFT' || status === 'BOOKED';

  if (!details) {
    return null;
  }

  const servicePointCountry = servicePoints.find(
    (servicePoint) => servicePoint.id === details.servicePointId
  )?.country;

  return (
    <div>
      <FormProvider
        initialValues={getDriverFormInitialValues(contract)}
        onSubmit={handleSubmit}
        validate={validateDriverDetailsForm(intl, servicePointCountry)}
        disabled={isSignedOrPendingSignature || !enabled}
      >
        <UnsavedChangesPrompt />
        <Form>
          <div className="mx-auto max-w-4xl pt-4 pb-10">
            <ContractPendingSignAlert />
            <div className="flex justify-between gap-4">
              <ContractDriverDetailsNavigationTabs contract={contract} />
              <SendCheckInEmail contractId={contractId} />
            </div>
            <h2 className="text-xl text-neutral-700 mb-4">
              <FormattedMessage {...messages.personalInformation} />
            </h2>
            <Card className="pt-8 pb-14 px-10 mb-28 grid grid-cols-2 gap-x-11 gap-y-10">
              <UserFields.FirstName />
              <UserFields.LastName />
              <UserFields.MobilePhone />
              <UserFields.Email />
              <DriverFields.Language skip={['ca']} />
              <div className="col-span-2">
                <DriverFields.Street />
              </div>
              <div className="grid grid-cols-3 gap-x-5 gap-y-10 col-span-2">
                <DriverFields.Zip />
                <DriverFields.City />
                <DriverFields.Country />
              </div>
            </Card>
            <h2 className="text-xl text-neutral-700 mb-6">
              <FormattedMessage {...messages.identityDocument} />
            </h2>
            <Card className="px-10 pt-10 pb-8 flex gap-14 mb-16">
              <div className="flex flex-col gap-6 w-72 shrink-0">
                <Photo
                  testId="ID_CARD_FRONT_PHOTO"
                  name="idCardFront"
                  side="front"
                />
                <Photo name="idCardBack" side="back" />
              </div>
              <div>
                <div className="flex w-64 flex-col gap-y-10">
                  <DriverFields.DateOfBirth />
                  <DriverFields.IdCardCountry />
                  <IdCardFields.IdNumber />
                  <IdCardFields.IdCardExpirationDate />
                  <IdCardFields.TaxIdNumber optional={!isTaxIdRequired} />
                </div>
              </div>
            </Card>
            {!isEbike && (
              <>
                <h2 className="text-xl text-neutral-700 mb-6">
                  <FormattedMessage {...messages.driverLicense} />
                </h2>
                <Card
                  data-testid="DRIVER_LICENSE_FORM_FIELDS"
                  className="px-10 pt-10 pb-8 flex gap-14 mb-16"
                >
                  <div className="flex flex-col gap-6 w-72 shrink-0">
                    <Photo name="driverLicenseFront" side="front" />
                    <Photo name="driverLicenseBack" side="back" />
                  </div>
                  <div>
                    <div className="flex flex-col gap-y-10">
                      <div className="w-64 flex flex-col gap-y-10">
                        <DriverLicenseNumber />
                        <DriverLicenseForm.ExpirationDate optional={true} />
                        <DriverLicenseForm.DriverLicenseCountry />
                      </div>
                    </div>
                  </div>
                </Card>
              </>
            )}
            <h2 className="text-xl text-neutral-700 mb-6">
              <FormattedMessage {...messages.selfie} />
            </h2>
            <Card className="px-10 pt-10 pb-8 flex gap-14 mb-16">
              <div className="flex flex-col gap-6 w-72 shrink-0">
                <Photo name="selfie" isDocument={false} />
              </div>
            </Card>
          </div>
          {enabled && (
            <FormSaveButton
              className="btn-shadow fixed bottom-8 right-28 -mr-2 z-10"
              isSaved={mutation.isSuccess}
            />
          )}
        </Form>
        <FormQRCode />
      </FormProvider>
    </div>
  );
};
