import { IntlShape } from 'react-intl';
import { algorithmPrices } from '@cooltra/station-based-api';

import { createSetError, validateAmountWithoutDecimals } from '~/validation';

import { CoveragePriceFormValues } from './form-details';
import messages from './messages';

type CoveragePriceFormFieldName = keyof CoveragePriceFormValues;

type CoveragePriceFormErrors = Partial<
  Record<CoveragePriceFormFieldName, string | undefined>
>;

export const validateForm =
  (intl: IntlShape) => (values: CoveragePriceFormValues) => {
    const errors: CoveragePriceFormErrors = {};
    const setError = createSetError(errors);

    setError(`price`, validateAmountWithoutDecimals(intl, values[`price`]));

    if (!algorithmPrices.includes(values.algorithmName)) {
      setError(
        `algorithmName`,
        intl.formatMessage(messages.algorithmNameError)
      );
    }

    return errors;
  };
