import { FormErrors } from '@cooltra/form';
import { IntlShape } from 'react-intl';

import { createSetError } from '~/validation';

import { validateRequired } from '../ContractDetails/contract-details-form';

export type ManualAdjustmentValues = {
  value: string;
  description: string;
  isNegative: boolean;
};

export const manualAdjustmentInitialFormValues: ManualAdjustmentValues = {
  value: '',
  description: '',
  isNegative: false,
};

export const validateManualAdjustmentForm =
  (intl: IntlShape) =>
  (values: ManualAdjustmentValues): FormErrors<ManualAdjustmentValues> => {
    const errors: FormErrors<ManualAdjustmentValues> = {};
    const setError = createSetError(errors);

    setError('value', validateRequired(intl, values.value));
    setError('description', validateRequired(intl, values.description));

    return errors;
  };
