import { defineMessages } from 'react-intl';

export default defineMessages({
  checkOnlyToNewCustomersOrMaxPromoUses: {
    defaultMessage:
      'You have to set either "Only to new customers" or "Max promo uses"',
  },
  exceedMaxUsesValue: {
    defaultMessage: 'Exceeds maximum values allowed (50.000)',
  },
  exceedDiscountValue: {
    defaultMessage: 'Exceeds maximum percentage values allowed (100%)',
  },
  validFromGreaterThanValidUntil: {
    defaultMessage: 'Redeemable until must be greater than redeemable from',
  },
  benefitFromGreaterThanBenefitUntil: {
    defaultMessage: 'Consumable until must be greater than consumable from',
  },
  consumableFromGreaterOrEqualThanBenefitFrom: {
    defaultMessage: 'Consumable from must be greater than redeemable from',
  },
  consumableUntilGreaterThanBenefitUntil: {
    defaultMessage: 'Consumable until must be greater than redeemable until',
  },
  numberOfCodesHaveToBeGreaterThanOne: {
    defaultMessage: 'The number of codes have to be greater than 1',
  },
});
