import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from '@cooltra/form';

import { DocumentsFormValues } from '../documents-form';

import messages from './messages';

export type RemoveDriverLicenseProps = {
  onRemove: () => void;
};

export const RemoveDriverLicense = ({ onRemove }: RemoveDriverLicenseProps) => {
  const { setValues } = useFormContext<DocumentsFormValues>();

  const clearDriverLicense = () => {
    onRemove();
    setValues({
      driverLicenseFront: '',
      driverLicenseBack: '',
      driverLicenseNumber: '',
      driverLicenseExpirationDate: '',
      driverLicenseCountry: '',
      driverLicenseCategory: '',
    });
  };

  return (
    <Button
      type="button"
      size="sm"
      variant="danger"
      onClick={clearDriverLicense}
    >
      <FormattedMessage {...messages.remove} />
    </Button>
  );
};
