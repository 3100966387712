import { defineMessages } from 'react-intl';

export default defineMessages({
  energyAdded: {
    defaultMessage: 'Energy added:',
  },
  created: {
    defaultMessage: 'Created:',
  },
  closed: {
    defaultMessage: 'Closed:',
  },
  commented: {
    defaultMessage: 'Commented:',
  },
});
