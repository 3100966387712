import { VehicleListItem } from '@cooltra/api';
import { subHours, isBefore, isAfter, subDays } from 'date-fns';

import { IdleFrom } from '../vehicles-filters-form';

export const isIdleFromMatch = (
  vehicle: VehicleListItem,
  { idleFrom }: { idleFrom: IdleFrom[] }
) => {
  if (idleFrom.length === 0) {
    return true;
  }

  if (!vehicle.idleFrom) {
    return false;
  }

  let result = false;

  const now = new Date();
  const vehicleIdleFrom = new Date(vehicle.idleFrom);

  idleFrom.forEach((i) => {
    switch (i) {
      case '1-20h': {
        if (
          isBefore(vehicleIdleFrom, subHours(now, 1)) &&
          isAfter(vehicleIdleFrom, subHours(now, 20))
        ) {
          result = true;
        }
        break;
      }
      case '20-24h': {
        if (
          isBefore(vehicleIdleFrom, subHours(now, 20)) &&
          isAfter(vehicleIdleFrom, subHours(now, 24))
        ) {
          result = true;
        }
        break;
      }
      case '1-2d': {
        if (
          isBefore(vehicleIdleFrom, subDays(now, 1)) &&
          isAfter(vehicleIdleFrom, subDays(now, 2))
        ) {
          result = true;
        }
        break;
      }
      case '2-3d': {
        if (
          isBefore(vehicleIdleFrom, subDays(now, 2)) &&
          isAfter(vehicleIdleFrom, subDays(now, 3))
        ) {
          result = true;
        }
        break;
      }

      case '3-4d': {
        if (
          isBefore(vehicleIdleFrom, subDays(now, 3)) &&
          isAfter(vehicleIdleFrom, subDays(now, 4))
        ) {
          result = true;
        }
        break;
      }

      case '4-5d': {
        if (
          isBefore(vehicleIdleFrom, subDays(now, 4)) &&
          isAfter(vehicleIdleFrom, subDays(now, 5))
        ) {
          result = true;
        }
        break;
      }
      case '5-10d': {
        if (
          isBefore(vehicleIdleFrom, subDays(now, 5)) &&
          isAfter(vehicleIdleFrom, subDays(now, 10))
        ) {
          result = true;
        }
        break;
      }
      case '+10d': {
        if (isBefore(vehicleIdleFrom, subDays(now, 10))) {
          result = true;
        }
        break;
      }
    }
  });

  return result;
};
