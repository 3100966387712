import { useField, useFormContext } from '@cooltra/form';
import { classNames } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';

import { Levels } from './Levels';
import messages from './messages';

export type LevelSelectorProps = {
  levels: number;
  name: string;
  className?: string;
};

export const LevelSelector = ({
  levels,
  name,
  className,
}: LevelSelectorProps) => {
  const { isFormDisabled } = useFormContext();
  const { value, setValue } = useField<number>(name);

  const handleClick = (index: number) => {
    setValue(index);
  };

  return (
    <label className={className}>
      <span className="inline-block text-neutral-700 font-semibold text-base mb-1">
        <FormattedMessage {...messages.fuelLevel} />
      </span>
      <ul className="flex gap-2">
        <Levels
          id={name}
          value={value}
          levels={levels}
          onClick={handleClick}
          buttonClassNames={classNames('h-11 w-6 p-0 rounded-sm')}
          buttonGreaterThanValueClassNames="bg-primary-500 hover:bg-primary-500 active:bg-primary-500 focus:bg-primary-500"
          buttonLessThanValueClassNames="bg-neutral-100 hover:bg-neutral-100 active:bg-neutral-100 focus:bg-neutral-100"
          readonly={isFormDisabled}
        />
      </ul>
    </label>
  );
};
