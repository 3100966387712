import { Link } from '@cooltra/ui';

export const CookiesPolicy = () => (
  <div
    className="container py-24 min-w-screen-sm max-w-screen-lg"
    data-testid="COOKIES_POLICY"
  >
    <h1 className="text-5xl mb-8">Política de cookies</h1>
    <section className="mb-14 flex flex-col gap-4">
      <p>Nuestra página Web Zeus utiliza cookies propias y de terceros.</p>
      <p>
        El usuario puede, en todo momento, aceptar o rechazar las cookies
        instaladas que no sean estrictamente necesarias para el correcto
        funcionamiento de la web y el acceso al Usuario a sus servicios, a
        través del panel de ajuste de cookies proporcionado en nuestra web.
      </p>
      <p>
        Asimismo, podrá configurar su navegador en todo momento sin que ello
        perjudique la posibilidad del Usuario de acceder a los contenidos. Sin
        embargo, le informamos de que el rechazo de las cookies puede disminuir
        el buen funcionamiento de la web.
      </p>
    </section>
    <section className="mb-14 flex flex-col gap-4">
      <h2>¿Qué son las cookies?</h2>
      <p>
        Una cookie es un fichero que se descarga en un ordenador cuando se
        accede a determinadas páginas web. Las cookies permiten a una página
        web, entre otras cosas, almacenar y recuperar información sobre los
        hábitos de navegación de un usuario o de su equipo y, dependiendo de la
        información que contengan y del modo en que usted utilice su equipo,
        pueden utilizarse para reconocer al usuario. Las cookies se asocian
        únicamente a un usuario anónimo y a su ordenador o dispositivo y no
        proporcionan referencias que permitan conocer sus datos personales.
      </p>
    </section>
    <section className="mb-14 flex flex-col gap-4">
      <h2>Cookies afectadas por la normativa y cookies exentas</h2>
      <p>
        Según la directiva de la UE, las cookies que requieren el consentimiento
        informado por parte del usuario son las cookies de analítica, las de
        publicidad y afiliación, quedando exceptuadas las de carácter técnico y
        las necesarias para el funcionamiento del sitio web o la prestación de
        servicios expresamente demandados por el usuario.
      </p>
    </section>
    <div className="mb-14">
      <h2 className="mb-4">Tipos de cookies</h2>
      <ol className="list-decimal pl-6 flex flex-col gap-6 mb-6">
        <li>
          Según la entidad que las gestione, hay Cookies propias (aquellas que
          se envían al equipo terminal del usuario desde un equipo o dominio
          gestionado por el propio editor y desde el que se presta el servicio
          solicitado por el usuario) y de terceros (las que se envían al equipo
          terminal del usuario desde un equipo o dominio que no es gestionado
          por el editor, sino por otra entidad que trata los datos obtenidos
          mediante las galletas).
        </li>
        <li>
          Según el tiempo que permanecen activas, existen las de sesión
          (diseñadas para recabar y almacenar datos mientras el usuario accede a
          una página web) y las persistentes (en las que los datos se almacenan
          en el terminal y pueden ser accedidos y tratados durante un periodo
          definido por el responsable de la cookie -puede ir de unos minutos a
          varios años-).
        </li>
        <li>
          <span className="block mb-3">
            Según la finalidad para la que traten la información que recopilan,
            pueden ser:
          </span>
          <ul className="list-disc pl-4 flex flex-col gap-2">
            <li>
              Cookies técnicas (necesarias para el uso de la web y la prestación
              del servicio contratado),
            </li>
            <li>
              Cookies de personalización (que permiten al usuario acceder al
              servicio con características predefinidas, como por ejemplo el
              idioma, tipo de navegador, configuración regional, etc.),
            </li>
            <li>
              Cookies de análisis (recogen información el uso que se realiza de
              la web),
            </li>
            <li>
              Cookies publicitarias (recogen información sobre las preferencias
              y elecciones personales de los usuarios),
            </li>
            <li>
              Cookies de afiliados (permiten realizar un seguimiento de las
              visitas procedentes de otras webs, con las que el sitio web
              establece un contrato de afiliación).
            </li>
          </ul>
        </li>
      </ol>
      <p>
        <span>Su consentimiento se aplica a los siguientes dominios: </span>
        <a className="link link-primary">https://web.zeus.cooltra.com</a>
      </p>
      <p className="mb-6">Su estado actual: Solo usar cookies necesarias.</p>
      <section className="mb-6">
        <h3 className="text-xl mb-1.5">Necesario</h3>
        <p>
          Las cookies necesarias ayudan a hacer una página web utilizable
          activando funciones básicas como la navegación en la página y el
          acceso a áreas seguras de la página web. La página web no puede
          funcionar adecuadamente sin estas cookies.
        </p>
      </section>
      <section className="mb-6">
        <h3 className="text-xl mb-1.5">Preferencias</h3>
        <p>
          Las cookies de preferencias permiten a la página web recordar
          información que cambia la forma en que la página se comporta o el
          aspecto que tiene, como su idioma preferido o la región en la que
          usted se encuentra.
        </p>
      </section>
      <section className="mb-6">
        <h3 className="text-xl mb-1.5">Preferencias</h3>
        <p>
          Las cookies de preferencias permiten a la página web recordar
          información que cambia la forma en que la página se comporta o el
          aspecto que tiene, como su idioma preferido o la región en la que
          usted se encuentra.
        </p>
      </section>
      <section className="mb-6">
        <h3 className="text-xl mb-1.5">Estadística</h3>
        <p>
          Las cookies estadísticas ayudan a los propietarios de páginas web a
          comprender cómo interactúan los visitantes con las páginas web
          reuniendo y proporcionando información de forma anónima. También
          pueden ser tratadas por terceros, diseñadas y utilizadas para el
          seguimiento y análisis del comportamiento de los Usuarios.
        </p>
      </section>
      <section className="mb-6">
        <h3 className="text-xl mb-1.5">Marketing</h3>
        <p>
          Las cookies de marketing se utilizan para rastrear a los visitantes en
          las páginas web. La intención es mostrar anuncios relevantes y
          atractivos para el usuario individual, y por lo tanto, más valiosos
          para los editores y terceros anunciantes.
        </p>
      </section>
      <section className="mb-8">
        <h3 className="text-xl mb-1.5">No clasificados</h3>
        <p>
          Las cookies no clasificadas son cookies para las que todavía estamos
          en proceso de clasificar, junto con los proveedores de cookies
          individuales.
        </p>
      </section>
      <p className="mb-2">
        En todo momento podrá acceder a la configuración de su navegador
        aceptando o rechazando todas las cookies, o bien seleccionar aquéllas
        cuya instalación admite y cuáles no, siguiendo uno de los siguientes
        procedimientos, que depende del navegador que utilice:
      </p>
      <ul className="list-disc pl-6 flex flex-col gap-2 mb-4">
        <li>
          <p>Google Chrome (en el Menú Herramientas):</p>
          <p>
            Configuración &gt; Mostrar opciones avanzadas &gt; Privacidad
            (Configuración de contenido) &gt; Cookies:
          </p>
          <Link href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktopandhl=es">
            https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktopandhl=es
          </Link>
        </li>
        <li>
          <p>Microsoft Internet Explorer (en el Menú Herramientas):</p>
          <p>Opciones de Internet &gt; Privacidad &gt; Avanzada:</p>
          <Link href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">
            https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies
          </Link>
        </li>
        <li>
          <p>Firefox:</p>
          <p>Opciones &gt; Privacidad &gt; Cookies:</p>
          <Link href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">
            https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias
          </Link>
        </li>
        <li>
          <p>Safari, iPad y iPhone:</p>
          <p>Preferencias &gt; Privacidad:</p>
          <Link href="https://support.apple.com/kb/ph21411?locale=es_ES">
            https://support.apple.com/kb/ph21411?locale=es_ES
          </Link>
        </li>
        <li>
          <p>Opera:</p>
          <p>Configuración &gt; Opciones &gt; Avanzado &gt; Cookies:</p>
          <Link href="http://help.opera.com/Windows/12.00/es-ES/cookies.html">
            http://help.opera.com/Windows/12.00/es-ES/cookies.html
          </Link>
        </li>
      </ul>
      <p className="mb-4">
        Estos navegadores están sometidos a actualizaciones o modificaciones,
        por lo que no podemos garantizar que se ajusten completamente a la
        versión de su navegador.
      </p>
      <p>
        También puede ser que utilice otro navegador no contemplado en estos
        enlaces como Konqueror, Arora, Flock, etc. Para evitar estos desajustes,
        puede acceder directamente desde las opciones de su navegador,
        generalmente en el menú de &quot;Opciones&quot; en la sección de
        &quot;Privacidad&quot;. (Por favor, consulte la ayuda de su navegador
        para más información).
      </p>
    </div>
    <section className="mb-14 flex flex-col gap-4">
      <h2>Desactivación/activación y eliminación de cookies</h2>
      <p>
        Para restringir o bloquear las cookies, se hace a través de la
        configuración del navegador.
      </p>
      <p>
        Si no desea que los sitios web pongan ninguna cookie en su equipo, puede
        adaptar la configuración del navegador de modo que se le notifique antes
        de que se coloque ninguna cookie. De igual modo, puede adaptar la
        configuración de forma que el navegador rechace todas las cookies, o
        únicamente las cookies de terceros. También puede eliminar cualquiera de
        las cookies que ya se encuentren en el equipo. Tenga en cuenta que
        tendrá que adaptar por separado la configuración de cada navegador y
        equipo que utilice.
      </p>
      <p>
        Tenga en cuenta que si no desea recibir cookies, ya no podremos
        garantizar que nuestro sitio web funcione debidamente. Puede que algunas
        funciones del sitio se pierdan y es posible que ya no pueda ver ciertos
        sitios web. Además, rechazar las cookies no significa que ya no vaya a
        ver anuncios publicitarios. Simplemente los anuncios no se ajustarán a
        sus intereses y se repetirán con más frecuencia.
      </p>
      <p>
        Cada navegador posee un método distinto para adaptar la configuración.
        Si fuera necesario, consulte la función de ayuda del navegador para
        establecer la configuración correcta.
      </p>
      <p>
        Para desactivar las cookies en el teléfono móvil, consulte el manual del
        dispositivo para obtener más información.
      </p>
      <p>
        <span>
          Puede obtener más información sobre las cookies en Internet,{' '}
        </span>
        <Link href="http://www.aboutcookies.org">
          http://www.aboutcookies.org
        </Link>
        <span>.</span>
      </p>
      <p>
        Teniendo en cuenta la forma en la que funciona Internet y los sitios
        web, no siempre contamos con información de las cookies que colocan
        terceras partes a través de nuestro sitio web. Esto se aplica
        especialmente a casos en los que nuestra página web contiene lo que se
        denominan elementos integrados: textos, documentos, imágenes o breves
        películas que se almacenan en otra parte, pero se muestran en nuestro
        sitio web o a través del mismo.
      </p>
      <p>
        Por consiguiente, en caso de que se encuentre con este tipo de cookies
        en este sitio web y no estén enumeradas en la lista anterior, le rogamos
        que nos lo comunique. O bien póngase en contacto directamente con el
        tercero para pedirle información sobre las cookies que coloca, la
        finalidad y la duración de la cookie, y cómo ha garantizado su
        privacidad.
      </p>
    </section>
    <section>
      <h3 className="mb-4">
        Información relacionada con el tratamiento de datos personales (ART.13
        RGPD)
      </h3>
      <Link
        className="block mb-2"
        href="https://www.cooltra.com/es/politica-de-privacidad"
      >
        https://www.cooltra.com/es/politica-de-privacidad
      </Link>
      <p className="mb-4">
        Declaración de cookies actualizada por última vez el 23/9/2021:
      </p>
      <table className="w-full border border-neutral-100 rounded-md overflow-hidden">
        <thead>
          <tr className="grid grid-cols-5 bg-neutral-100">
            <th className="text-left p-4">Nombre</th>
            <th className="text-left p-4">Proveedor</th>
            <th className="text-left p-4">Propósito</th>
            <th className="text-left p-4">Caducidad</th>
            <th className="text-left p-4">Tipo</th>
          </tr>
        </thead>
        <tbody>
          <tr className="grid grid-cols-5 border-b border-neutral-100">
            <td className="p-4">_ga</td>
            <td className="p-4">Google</td>
            <td className="p-4">
              Registra una identificación única que se utiliza para generar
              datos estadísticos acerca de cómo utiliza el visitante el sitio
              web.
            </td>
            <td className="p-4">2 años</td>
            <td className="p-4">HTTP</td>
          </tr>
          <tr className="grid grid-cols-5 border-b border-neutral-100">
            <td className="p-4">_gat</td>
            <td className="p-4">Google</td>
            <td className="p-4">
              Utilizado por Google Analytics para controlar la tasa de
              peticiones
            </td>
            <td className="p-4">1 día</td>
            <td className="p-4">HTTP</td>
          </tr>
          <tr className="grid grid-cols-5">
            <td className="p-4">_gid</td>
            <td className="p-4">Google</td>
            <td className="p-4">
              Registra una identificación única que se utiliza para generar
              datos estadísticos acerca de cómo utiliza el visitante el sitio
              web.
            </td>
            <td className="p-4">1 día</td>
            <td className="p-4">HTTP</td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
);
