import { useContext } from 'react';
import { useExtrasPricesQuery } from '@cooltra/station-based-api';
import { getErrorStatus } from '@cooltra/axios';
import { Icon, List } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { AccessoryName, InsuranceName } from '@cooltra/utils';
import { format } from 'date-fns';
import { MdArrowDownward } from 'react-icons/md';

import { ErrorPage, Four0Three, NoSearchResults } from '~/common';
import { saveCsvFile } from '~/utils/file';
import { dateFormat } from '~/utils/date';

import { PriceListTypeContext } from '../PriceListTypeContext';
import { priceListsToCsv } from '../utils/export-csv';
import { ExtraCard } from '../AccessoriesPriceList/ExtraCard/ExtraCard';
import { PriceListsNavigationTab } from '../PriceListsNavigationTab/PriceListsNavigationTab';

import messages from './messages';
import { getDailyPrices } from './utils/get-daily-prices';

export const AccessoriesPriceList = () => {
  const { type } = useContext(PriceListTypeContext);
  const {
    data: extrasPrices,
    isLoading,
    isError,
    error,
  } = useExtrasPricesQuery({
    type,
  });

  const { coverages, accessories } = extrasPrices || {};

  const downloadCoveragesCsv = () => {
    if (!coverages || !coverages.length) {
      return;
    }

    const coveragesByDay = getDailyPrices(coverages);

    if (!coveragesByDay?.length) {
      return;
    }

    const coveragesNames = coverages.map(({ name }) => name);
    const pricesCsv = priceListsToCsv<InsuranceName>(
      coveragesByDay,
      coveragesNames as InsuranceName[]
    );

    saveCsvFile(
      `coverages-prices-${format(new Date(), dateFormat)}.csv`,
      pricesCsv
    );
  };

  const downloadAccessoriesCsv = () => {
    if (!accessories || !accessories.length) {
      return;
    }

    const accessoriesByDay = getDailyPrices(accessories);

    if (!accessoriesByDay?.length) {
      return;
    }

    const accessoriesNames = accessories.map((accessory) => accessory.name);
    const pricesCsv = priceListsToCsv<AccessoryName>(
      accessoriesByDay,
      accessoriesNames as AccessoryName[]
    );

    saveCsvFile(
      `accessories-prices-${format(new Date(), dateFormat)}.csv`,
      pricesCsv
    );
  };

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div className="container min-w-5xl max-w-6xl py-12">
      <div className="flex items-center justify-between mb-6">
        <PriceListsNavigationTab />
      </div>
      <div className="grid grid-cols-2 gap-y-6 gap-x-8 mb-10">
        <div>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-neutral-300 text-xs font-normal">
              <FormattedMessage {...messages.coverages} />
            </h3>
            <button
              className="link-primary text-xs"
              onClick={downloadCoveragesCsv}
            >
              <Icon className="mr-1">
                <MdArrowDownward />
              </Icon>
              <FormattedMessage {...messages.downloadCsv} />
            </button>
          </div>
          <List
            isLoading={isLoading}
            loadingRows={4}
            idProp="id"
            data={coverages?.sort((a, b) => a.name.localeCompare(b.name)) || []}
            renderEmptyMessage={<NoSearchResults />}
            renderRow={(coverage) => (
              <ExtraCard extra={coverage} extraType="COVERAGE" />
            )}
          />
        </div>
        <div>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-neutral-300 text-xs font-normal">
              <FormattedMessage {...messages.accessories} />
            </h3>
            <button
              className="link-primary text-xs"
              onClick={downloadAccessoriesCsv}
            >
              <Icon className="mr-1">
                <MdArrowDownward />
              </Icon>
              <FormattedMessage {...messages.downloadCsv} />
            </button>
          </div>
          <List
            isLoading={isLoading}
            loadingRows={4}
            idProp="id"
            data={
              accessories?.sort((a, b) => a.name.localeCompare(b.name)) || []
            }
            renderEmptyMessage={<NoSearchResults />}
            renderRow={(accessory) => (
              <ExtraCard extra={accessory} extraType="ACCESSORY" />
            )}
          />
        </div>
      </div>
    </div>
  );
};
