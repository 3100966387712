import { ParkingReview as ApiParkingReview } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type ParkingReviewProps = {
  type: ApiParkingReview | null | undefined;
};

export const ParkingReview = ({ type }: ParkingReviewProps) =>
  type ? <FormattedMessage {...messages[type]} /> : null;
