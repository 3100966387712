import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Filter preferences',
  },
  homeSystemLabel: {
    defaultMessage: 'Freefloat home system',
  },
  all: {
    defaultMessage: 'All',
  },
  preferredServicePoints: {
    defaultMessage: 'Station-based preferred service points',
  },
  currentServicePoint: {
    defaultMessage: 'Station-based current billing service point',
  },
});
