import { defineMessages } from 'react-intl';

export default defineMessages({
  invoicePdf: {
    defaultMessage: 'Invoice PDF',
  },
  pdfDoesNotExist: {
    defaultMessage: 'The PDF is not available for this invoice',
  },
  somethingWentWrongOnDownloadingPdf: {
    defaultMessage: 'Something went wrong trying to download the PDF',
  },
});
