import { defineMessages } from 'react-intl';

export default defineMessages({
  selectDates: {
    defaultMessage: 'Select dates',
  },
  maxRentalAlert: {
    defaultMessage: 'Rentals cannot be longer than 30 days',
  },
  minRental: {
    defaultMessage: 'Minimal rental duration is 30 minutes',
  },
});
