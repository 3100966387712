import { ScrollToTop } from '@cooltra/ui';

import { SuspensionAlert } from '~/common';

import { CurrentActivity } from '../CurrentActivity';
import { CreditBalance } from '../CreditBalance/CreditBalance';
import { LastSession } from '../LastSession/LastSession';
import { OpenUserProblemReports } from '../OpenUserProblemReports';
import { ActiveOfferContracts } from '../ActiveOfferContracts';
import { ReportsTotalAndLifespan } from '../ReportsTotalAndLifespan';
import { CurrentSubscription } from '../CurrentSubscription/CurrentSubscription';
import { PaymentMethod } from '../PaymentMethod';

import './user-overview.css';

export const UserOverview = () => (
  <>
    <SuspensionAlert />
    <div
      className="container py-12 px-10 min-w-5xl max-w-7xl"
      data-testid="USER_OVERVIEW"
    >
      <ScrollToTop />
      <div className="w-full gap-16 flex flex-col">
        <div className="grid user-overview-heading-layout gap-3">
          <CreditBalance />
          <div className="grid grid-rows-2 gap-3 h-full">
            <CurrentSubscription />
            <PaymentMethod />
          </div>
          <ActiveOfferContracts />
          <div className="flex flex-col gap-3 h-full">
            <ReportsTotalAndLifespan />
            <LastSession />
          </div>
        </div>
        <CurrentActivity />
        <OpenUserProblemReports />
      </div>
    </div>
  </>
);
