import { FormattedMessage } from 'react-intl';
import {
  Contract,
  useContractAvailablePenaltiesQuery,
} from '@cooltra/station-based-api';
import { Button, Card, Placeholder } from '@cooltra/ui';
import { consecutiveNumbers } from '@cooltra/utils';
import { MdCheckCircle } from 'react-icons/md';

import { Amount, ListMessageCard } from '~/common';

import { AddPenaltyButton } from './AddPenaltyButton';
import { RemovePenaltyButton } from './RemovePenaltyButton';
import { PenaltyPriceVariationTooltip } from './PenaltyPriceVariationTooltip';
import messages, { penaltiesMessages } from './messages';

export type PenaltiesProps = {
  contract: Contract;
  disabled: boolean;
};

export const Penalties = ({ contract, disabled }: PenaltiesProps) => {
  const {
    data: penalties = [],
    isLoading,
    isError,
    refetch,
  } = useContractAvailablePenaltiesQuery(contract.contractId);

  const handleOnReload = () => refetch();
  const getContent = () => {
    if (isLoading) {
      return (
        <ul className="grid grid-cols-2 gap-4">
          {consecutiveNumbers(5).map((position) => (
            <Card
              className="py-4 px-6 flex justify-between items-center"
              key={position}
            >
              <div className="flex flex-col gap-1">
                <Placeholder className="w-44 h-3" />
                <Placeholder className="w-20 h-5" />
              </div>
              <Placeholder className="w-16 h-6" />
            </Card>
          ))}
        </ul>
      );
    }

    if (isError) {
      return (
        <ListMessageCard className="h-44">
          <div className="flex flex-col gap-2">
            <FormattedMessage {...messages.error} />
            <Button emphasis="medium" onClick={handleOnReload}>
              <FormattedMessage {...messages.reload} />
            </Button>
          </div>
        </ListMessageCard>
      );
    }

    return (
      <ul
        className="grid grid-cols-2 gap-4"
        data-testid="CONTRACT_AVAILABLE_PENALTIES"
      >
        {penalties.map((penalty) => {
          const { penaltyId, name, price } = penalty;
          const isAdded = Boolean(contract.penalties?.includes(penaltyId));

          return (
            <Card
              className="py-4 px-6 flex justify-between items-center"
              key={penaltyId}
            >
              <div className="flex flex-col gap-1">
                <p className="font-normal text-neutral-800">
                  <FormattedMessage {...penaltiesMessages[name]} />
                </p>
                <div className="flex items-center gap-1">
                  <Amount
                    className="font-semibold text-md"
                    value={price.max.value}
                    currency={price.max.currency}
                  />
                  {price.min.value < price.max.value && (
                    <PenaltyPriceVariationTooltip penalty={penalty} />
                  )}
                </div>
              </div>
              {isAdded ? (
                <div className="flex items-center gap-5">
                  <RemovePenaltyButton
                    penaltyId={penaltyId}
                    contractId={contract.contractId}
                    disabled={disabled}
                  />
                  <span className="flex items-center gap-1 text-sm text-neutral-600">
                    <MdCheckCircle />
                    <span className="font-semibold">
                      <FormattedMessage {...messages.added} />
                    </span>
                  </span>
                </div>
              ) : (
                <AddPenaltyButton
                  penaltyId={penaltyId}
                  contractId={contract.contractId}
                  disabled={disabled}
                />
              )}
            </Card>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="mx-auto max-w-4xl pt-4 pb-10">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-xl text-neutral-700">
          <FormattedMessage {...messages.penalties} />
        </h1>
      </div>
      <div>{getContent()}</div>
    </div>
  );
};
