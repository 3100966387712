import { BadgePlaceholder, Card, Placeholder } from '@cooltra/ui';

import { CooltraForEmployeeRowLayout } from '../CooltraForEmployeeRowLayout';

export const CooltraForEmployeeCardLoading = () => (
  <Card>
    <CooltraForEmployeeRowLayout
      className="min-h-24 py-4"
      promotion={
        <div className="flex flex-col gap-3 py-0.5">
          <Placeholder className="w-24 h-2" />
          <Placeholder className="w-40 h-2" />
        </div>
      }
      subscription={<Placeholder className="h-2 w-8" />}
      redeemableFrom={
        <div className="flex flex-col gap-3 py-0.5">
          <Placeholder className="w-16 h-2" />
          <Placeholder className="w-24 h-2" />
        </div>
      }
      redeemableUntil={
        <div className="flex flex-col gap-3 py-0.5">
          <Placeholder className="w-16 h-2" />
          <Placeholder className="w-24 h-2" />
        </div>
      }
      uses={<Placeholder className="h-2 w-8" />}
      state={<BadgePlaceholder className="w-20" />}
    />
  </Card>
);
