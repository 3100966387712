import { SearchQueryParamValue, stringifyParams } from '@cooltra/utils';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useNavigateWithQueryParams = <
  T extends Record<string, SearchQueryParamValue>,
>() => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navigateWithFilters = useCallback(
    (values: T) => {
      navigate(`${pathname}?${stringifyParams(values)}`, { replace: true });
    },
    [pathname, navigate]
  );

  return navigateWithFilters;
};
