import { FormattedMessage } from 'react-intl';
import { PackInvoiceItem } from '@cooltra/api';

import {
  Amount,
  DateTime,
  RefundedItemLabel,
  RefundInvoiceItem,
} from '~/common';

import { InvoiceItemRowLayout } from '../InvoiceItemRowLayout';

import messages from './messages';

export const PackItem = ({
  id,
  chargedAt,
  pack,
  total,
  price,
  chargeState,
  type,
  isStateColumnDisplayed,
  refundable,
}: PackInvoiceItem & {
  isStateColumnDisplayed: boolean;
}) => (
  <InvoiceItemRowLayout
    className="text-sm text-neutral-800 py-4"
    chargedAt={<DateTime date={chargedAt} />}
    item={
      <FormattedMessage
        tagName="span"
        {...messages.pack}
        values={{ title: pack.title }}
      />
    }
    total={<Amount className="block text-right" {...total} />}
    price={<Amount className="block text-right" {...price} />}
    state={
      isStateColumnDisplayed && (
        <>
          {chargeState === 'REFUNDED' ? (
            <RefundedItemLabel />
          ) : refundable ? (
            <RefundInvoiceItem id={id} type={type} />
          ) : null}
        </>
      )
    }
  />
);
