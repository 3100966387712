import { Form, FormProvider, FormProviderProps } from '@cooltra/form';
import { useIntl } from 'react-intl';

import { validateStationBasedPromotionForm } from './station-based-promotion-form-validations';
import { StationBasedFormValues } from './station-based-form';

export type StationBasedFormProps = Omit<
  FormProviderProps<StationBasedFormValues>,
  'validate'
>;

export const StationBasedPromotionForm = ({
  onSubmit,
  initialValues,
  children,
}: StationBasedFormProps) => {
  const intl = useIntl();

  return (
    <FormProvider
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateStationBasedPromotionForm(intl)}
    >
      <Form>{children}</Form>
    </FormProvider>
  );
};
