import { AlgorithmPrice } from '@cooltra/station-based-api';

export type CoveragePriceFormValues = {
  price: string;
  algorithmName: AlgorithmPrice;
};

export const coveragePriceFormInitialValues: CoveragePriceFormValues = {
  price: '',
  algorithmName: 'BASE',
};
