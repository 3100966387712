import { useIntl } from 'react-intl';
import { Form, FormProvider, FormProviderProps } from '@cooltra/form';

import { validateForm } from './form-validations';
import { CoveragePriceFormValues } from './form-details';

export type CoveragePriceFormProps = Omit<
  FormProviderProps<CoveragePriceFormValues>,
  'validate'
>;

export const CoveragePriceListDetailsForm = ({
  onSubmit,
  initialValues,
  children,
}: CoveragePriceFormProps) => {
  const intl = useIntl();

  return (
    <FormProvider
      initialValues={initialValues}
      validate={validateForm(intl)}
      onSubmit={onSubmit}
    >
      <Form>{children}</Form>
    </FormProvider>
  );
};
