import { RouterCardLink } from '@cooltra/navigation';
import { PropsWithChildren } from 'react';

export type ContractCardRouterLink = PropsWithChildren<{
  id: string;
}>;

export const ContractCardRouterLink = ({
  children,
  id,
  ...rest
}: PropsWithChildren<{ id: string }>) => (
  <RouterCardLink
    data-testid={id}
    to={`/contracts/${id}/overview`}
    className="w-full overflow-hidden"
    {...rest}
  >
    {children}
  </RouterCardLink>
);
