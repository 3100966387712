import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type VehicleRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  vehicle: ReactNode;
  servicePoint: ReactNode;
};

export const VehicleRowLayout = ({
  vehicle,
  servicePoint,
  className,
  ...rest
}: VehicleRowLayoutProps) => (
  <div
    className={classNames('flex w-full items-center h-full pl-6', className)}
    {...rest}
  >
    <div className="w-52">{vehicle}</div>
    <div className="flex-1">{servicePoint}</div>
  </div>
);
