import { ReactNode } from 'react';
import { Spinner } from '@cooltra/ui';
import './pos-layout.css';

export type POSLayoutProps = {
  renderNavbar: ReactNode;
  renderSummary: ReactNode;
  renderContent: ReactNode;
  isLoading: boolean;
};

export const POSLayout = ({
  renderNavbar,
  renderSummary,
  renderContent,
  isLoading,
}: POSLayoutProps) => (
  <div className="flex flex-col h-screen-content">
    {renderNavbar}
    <div className="w-full flex gap-4 lg:gap-8 flex-1 pos-content pt-4 lg:pt-8 px-4 lg:px-8">
      {isLoading ? (
        <div className="h-80 flex w-full items-center justify-center">
          <Spinner size="lg" />
        </div>
      ) : (
        <>
          <div className="hidden lg:block">{renderSummary}</div>
          <div className="w-full h-full">{renderContent}</div>
        </>
      )}
    </div>
  </div>
);
