import { ReactNode, useRef } from 'react';
import { MapRef, NavigationControl } from 'react-map-gl';

import { isTestingEnv } from '~/utils/e2e';
import { BaseMap } from '~/common';

import { VehicleMarkerWithLink } from './VehicleMapMarker';

export type VehicleMapProps = {
  id: string;
  geolocation: {
    latitude: number;
    longitude: number;
  };
  children?: ReactNode;
};

export const VehicleMap = ({ id, geolocation, children }: VehicleMapProps) => {
  const mapRef = useRef<MapRef | null>(null);

  if (isTestingEnv()) {
    return (
      <div
        className="h-full w-full bg-neutral-100"
        data-testid="VEHICLE_GEOLOCATION"
      >
        {children}
      </div>
    );
  }

  return (
    <BaseMap
      id={id}
      ref={mapRef}
      initialViewState={{
        latitude: geolocation.latitude,
        longitude: geolocation.longitude,
        zoom: 16,
      }}
      style={{ width: '100%', height: '444px' }}
    >
      <NavigationControl showZoom showCompass={false} />
      <VehicleMarkerWithLink
        latitude={geolocation.latitude}
        longitude={geolocation.longitude}
      />
      {children}
    </BaseMap>
  );
};
