import { IntlShape } from 'react-intl';
import { FormErrors } from '@cooltra/form';

import { validateRequired } from '~/validation';

export type AccessoryInfoFormValues = {
  info: string;
};

export const initialValues: AccessoryInfoFormValues = {
  info: '',
};

export const validate =
  (intl: IntlShape) =>
  (values: AccessoryInfoFormValues): FormErrors<AccessoryInfoFormValues> => {
    const errors: FormErrors<AccessoryInfoFormValues> = {};
    const requiredErrorMessage = validateRequired(intl, values.info);
    if (requiredErrorMessage) {
      errors.info = requiredErrorMessage;
    }
    return errors;
  };
