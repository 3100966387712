import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
  useContractQuery,
  useRemoveContractVehicleTypesMutation,
} from '@cooltra/station-based-api';
import { Button, Modal } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';
import { useEffect } from 'react';

import { useContractFlags, useNotification } from '~/hooks';

import messages from './messages';

export type VehicleTypeCardRemoveButtonProps = {
  vehicleTypeId: string;
};

export const VehicleTypeCardRemoveButton = ({
  vehicleTypeId,
}: VehicleTypeCardRemoveButtonProps) => {
  const { contractId = '' } = useParams();
  const [isModalOpen, { toggleOn, toggleOff }] = useToggle();
  const [isRemoving, { toggleOn: startRemoving, toggleOff: endRemoving }] =
    useToggle();
  const { data: contract, isRefetching } = useContractQuery(contractId, {
    enabled: false,
  });

  const { isVehicleRemovable, isSignedOrPendingSignature } = useContractFlags();

  const { mutateAsync, isPending } =
    useRemoveContractVehicleTypesMutation(contractId);
  const { addErrorNotification } = useNotification();

  const removeVehicleType = () => {
    startRemoving();
    mutateAsync({ vehicleTypeId }).catch(() => addErrorNotification());
  };

  useEffect(() => {
    if (!isPending && !isRefetching) {
      endRemoving();
    }
  }, [endRemoving, isPending, isRefetching]);

  if (!contract) {
    return null;
  }

  const { accessories, insurance } = contract;

  return (
    <>
      {!!accessories?.length || insurance !== 'BASIC' ? (
        <Button
          variant="danger"
          emphasis="low"
          onClick={toggleOn}
          disabled={isSignedOrPendingSignature || !isVehicleRemovable}
        >
          <FormattedMessage {...messages.remove} />
        </Button>
      ) : (
        <Button
          variant="danger"
          emphasis="low"
          onClick={removeVehicleType}
          loading={isRemoving}
          disabled={isSignedOrPendingSignature || !isVehicleRemovable}
        >
          <FormattedMessage {...messages.remove} />
        </Button>
      )}
      <Modal
        className="max-w-sm min-w-xs"
        isOpen={isModalOpen}
        onRequestClose={toggleOff}
      >
        <div className="text-center pb-8 px-8">
          <h6 className="text-neutral-700 text-lg font-semibold mb-2">
            <FormattedMessage {...messages.confirmation} />
          </h6>
          <p className="text-neutral-600 text-sm mb-8">
            <FormattedMessage {...messages.disclaimer} />
          </p>
          <div className="flex justify-center gap-4">
            <Button onClick={toggleOff}>
              <FormattedMessage {...messages.cancel} />
            </Button>
            <Button
              loading={isRemoving}
              emphasis="high"
              onClick={removeVehicleType}
            >
              <FormattedMessage {...messages.confirm} />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
