import { defineMessages } from 'react-intl';

export default defineMessages({
  system: {
    defaultMessage: 'Home system',
  },
  placeholder: {
    defaultMessage: 'All home systems',
  },
});
