import { FormattedMessage } from 'react-intl';
import { RouterTab } from '@cooltra/navigation';
import {
  Contract,
  useContractAvailableAccessoriesQuery,
} from '@cooltra/station-based-api';
import { MdError } from 'react-icons/md';

import { useContractFlags } from '~/hooks';

import messages from './messages';

export type ContractDriverDetailsNavigationTabsProps = {
  contract: Contract;
};

export const ContractDriverDetailsNavigationTabs = ({
  contract: { status, accessories, contractId },
}: ContractDriverDetailsNavigationTabsProps) => {
  const { isSecondDriverDataComplete } = useContractFlags();

  const { data: accessoriesAvailable = [] } =
    useContractAvailableAccessoriesQuery(contractId);

  const canHaveSecondDriver = accessoriesAvailable.some(
    ({ name }) => name === 'CONDUCTOR_ADICIONAL'
  );

  const hasSecondDriver = (accessories || []).some(
    ({ name }) => name === 'CONDUCTOR_ADICIONAL'
  );

  const isEditingEnabled = status === 'DRAFT' || status === 'BOOKED';

  return (
    <div className="flex flex-1 shrink-0 gap-10 mb-8">
      <RouterTab to={`/contracts/${contractId}/driver`}>
        <FormattedMessage {...messages.mainDriver} />
      </RouterTab>
      {((isEditingEnabled && canHaveSecondDriver) || hasSecondDriver) && (
        <RouterTab
          to={`/contracts/${contractId}/second-driver`}
          className="flex gap-1"
        >
          <FormattedMessage {...messages.additionalDriver} />
          {hasSecondDriver && !isSecondDriverDataComplete && (
            <MdError
              className="text-warning-500 text-xl"
              data-testid="INCOMPLETE_SECOND_DRIVER_DATA"
            />
          )}
        </RouterTab>
      )}
    </div>
  );
};
