import { FormattedMessage } from 'react-intl';

import { EmptyListMessage } from '~/common';

import messages from './messages';

export const NoRentalPasses = () => (
  <EmptyListMessage
    title={<FormattedMessage {...messages.title} />}
    content={<FormattedMessage {...messages.content} />}
  />
);
