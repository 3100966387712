import { defineMessages } from 'react-intl';

export default defineMessages({
  cityHall: {
    defaultMessage: 'This vehicle is being shared with City Hall',
  },
  noCityHall: {
    defaultMessage: 'This vehicle is not being shared with City Hall',
  },
});
