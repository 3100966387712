import {
  ProblemReport,
  User,
  Vehicle,
  useCloseProblemReportMutation,
} from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { FormattedMessage } from 'react-intl';
import { Button, ScrollToTop } from '@cooltra/ui';
import { RouterButton } from '@cooltra/navigation';
import { stringifyParams } from '@cooltra/utils';

import {
  ByOperator,
  DateTime,
  DividedCard,
  Log,
  ProblemReportBadge,
  StaticField,
  SystemPair,
  VehiclePair,
  Context,
  ClosedBadge,
  ProblemReportBackLink,
} from '~/common';
import { useNotification } from '~/hooks';

import { Photo } from '../Photo/Photo';
import { ProblemReportTask } from '../ProblemReportTask';
import { ProblemReportIncident } from '../ProblemReportIncident';

import messages from './messages';

export type ViewProblemReportProps = {
  problemReport: ProblemReport;
  vehicle: Vehicle;
  user?: User;
};

export const ViewProblemReport = ({
  problemReport,
  vehicle: { vehicleId, model, externalId, system, identificationNumber },
  user,
}: ViewProblemReportProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  const { mutateAsync, isPending } = useCloseProblemReportMutation(
    problemReport.problemReportId
  );

  const { addErrorNotification } = useNotification();

  const handleClosingReport = () =>
    mutateAsync().catch(() => addErrorNotification());

  return (
    <div className="pb-32">
      <ScrollToTop />
      <DividedCard
        className="min-w-4xl max-w-5xl pt-12 pb-6"
        data-testid="VIEW_PROBLEM_REPORT"
        renderHeader={
          <div>
            <ProblemReportBackLink userId={problemReport.createdBy} />
            <div className="flex justify-between items-end">
              <div className="flex items-center gap-10">
                <div>
                  <h2 className="text-3xl">
                    <FormattedMessage {...messages.title} />
                  </h2>
                </div>
                {!problemReport.open && (
                  <div className="flex items-center gap-4">
                    <ClosedBadge />
                    <div>
                      <DateTime date={problemReport.closedAt} />
                      <ByOperator
                        id={problemReport.closedBy}
                        className="block text-neutral-400"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex gap-3">
                {hasPermission('write:tasks') && (
                  <RouterButton
                    to={`/tasks/new?${stringifyParams({
                      vehicleId,
                      problemReportId: problemReport.problemReportId,
                    })}`}
                    emphasis="high"
                  >
                    <FormattedMessage {...messages.addTask} />
                  </RouterButton>
                )}
                {hasPermission('write:incidents') &&
                  !problemReport.reservationId && (
                    <RouterButton
                      to={`/incidents/new?${stringifyParams({
                        vehicleId,
                        problemReportId: problemReport.problemReportId,
                      })}`}
                      emphasis="high"
                    >
                      <FormattedMessage {...messages.addIncident} />
                    </RouterButton>
                  )}
                {hasPermission('write:problem_reports') &&
                  problemReport.open && (
                    <Button
                      emphasis="high"
                      loading={isPending}
                      onClick={handleClosingReport}
                    >
                      <FormattedMessage {...messages.closeReport} />
                    </Button>
                  )}
              </div>
            </div>
          </div>
        }
        renderLeft={
          <div className="flex h-full flex-col justify-between">
            <div className="flex flex-col gap-7 mb-20">
              <VehiclePair
                vehicleId={vehicleId}
                model={model}
                externalId={externalId}
                identificationNumber={identificationNumber}
              />
              <SystemPair system={system} />
              <Context
                rentalId={problemReport.rentalId}
                reservationId={problemReport.reservationId}
              />
            </div>
            <Log
              action="create"
              user={user && 'customer'}
              date={problemReport.createdAt}
              userId={user?.id}
            />
          </div>
        }
        renderRight={
          <div className="flex flex-col gap-10">
            <StaticField
              renderLabel={<FormattedMessage {...messages.reportType} />}
              renderValue={
                problemReport && (
                  <div className="flex flex-wrap gap-2">
                    {problemReport.tags.map((tagId) => (
                      <ProblemReportBadge tagId={tagId} key={tagId} />
                    ))}
                  </div>
                )
              }
            />
            <StaticField
              renderLabel={<FormattedMessage {...messages.userDescription} />}
              renderValue={problemReport.description}
            />
            <div className="flex flex-col gap-4">
              <span className="block text-neutral-700 text-base font-semibold h-5">
                <FormattedMessage {...messages.attachedImages} />
              </span>
              <div>
                {problemReport.imagePaths.length ? (
                  <div className="grid grid-cols-2 gap-4">
                    {problemReport.imagePaths.map((imagePath) => (
                      <Photo key={imagePath} name={imagePath} />
                    ))}
                  </div>
                ) : (
                  <span className="block text-neutral-1000 text-sm">-</span>
                )}
              </div>
            </div>
          </div>
        }
      />

      {hasPermission('read:tasks') && !!problemReport?.taskIds.length && (
        <div
          className="min-w-4xl max-w-5xl container mb-12"
          data-testid="PROBLEM_REPORT_TASKS"
        >
          <h3 className="text-neutral-800 text-2xl mb-4">
            <FormattedMessage {...messages.tasks} />
          </h3>
          {problemReport.taskIds.map((taskId) => (
            <ProblemReportTask key={taskId} taskId={taskId} />
          ))}
        </div>
      )}

      {!!problemReport?.incidentIds.length && (
        <div
          className="min-w-4xl max-w-5xl container mb-12"
          data-testid="PROBLEM_REPORT_INCIDENTS"
        >
          <h3 className="text-neutral-800 text-2xl mb-4">
            <FormattedMessage {...messages.incidents} />
          </h3>
          <div className="flex flex-col gap-4">
            {problemReport.incidentIds?.map((incidentId) => (
              <ProblemReportIncident key={incidentId} incidentId={incidentId} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
