import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Invoice',
  },
  backToInvoices: {
    defaultMessage: 'Back to invoices',
  },
  id: {
    defaultMessage: 'ID',
  },
  total: {
    defaultMessage: 'Total',
  },
  recipient: {
    defaultMessage: 'Recipient',
  },
  legalEntity: {
    defaultMessage: 'Legal entity',
  },
  issued: {
    defaultMessage: 'Issued',
  },
  paid: {
    defaultMessage: 'Paid',
  },
  invoicePdf: {
    defaultMessage: 'Invoice PDF',
  },
  refundsInvoice: {
    defaultMessage: 'Refunds invoice',
  },
  pdfDoesNotExist: {
    defaultMessage: 'The PDF is not available for this invoice',
  },
  somethingWentWrongOnDownloadingPdf: {
    defaultMessage: 'Something went wrong trying to download the PDF',
  },
  markedAsPaid: {
    defaultMessage: 'Marked as paid',
  },
  comment: {
    defaultMessage: 'Comment',
  },
});
