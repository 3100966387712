import { HTMLAttributes, ReactNode } from 'react';
import { classNames } from '@cooltra/utils';

export type UserRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  contractNumber: ReactNode;
  customer: ReactNode;
  date: ReactNode;
  vehicle: ReactNode;
  status: ReactNode;
};

export const ContractRowLayout = ({
  contractNumber,
  customer,
  date,
  vehicle,
  status,
  className,
  ...rest
}: UserRowLayoutProps) => (
  <div className={classNames('flex', 'h-full', 'w-full', className)} {...rest}>
    <div className="grid grid-cols-12 items-center w-full px-8 gap-2">
      <div className="hidden lg:block col-span-2">{contractNumber}</div>
      <div className="col-span-4 lg:col-span-2">{customer}</div>
      <div className="col-span-4 lg:col-span-3">{date}</div>
      <div className="col-span-3 flex lg:col-span-3">{vehicle}</div>
      <div className="col-span-1 flex justify-center lg:col-span-2">
        {status}
      </div>
    </div>
  </div>
);
