import { FormattedMessage } from 'react-intl';
import { InputLabel } from '@cooltra/ui';

import { FormDateInputField, OptionalFieldLabel } from '~/common';

import messages from './messages';

type ScheduledAtProps = {
  optional?: boolean;
};

export const ScheduledAt = ({ optional }: ScheduledAtProps) => {
  return (
    <FormDateInputField
      name="scheduledAt"
      id="scheduledAt"
      className="w-full"
      label={
        optional ? (
          <OptionalFieldLabel htmlFor="scheduledAt">
            <FormattedMessage {...messages.scheduledAt} />
          </OptionalFieldLabel>
        ) : (
          <InputLabel htmlFor="scheduledAt">
            <FormattedMessage {...messages.scheduledAt} />
          </InputLabel>
        )
      }
    />
  );
};
