import { defineMessages } from 'react-intl';

export default defineMessages({
  byStatus: {
    defaultMessage: 'Status',
  },
  byBattery: {
    defaultMessage: 'Battery',
  },
});
