import { ReactNode } from 'react';

export type CurrentRentalLayoutProps = {
  pickUp: ReactNode;
  duration: ReactNode;
  vehicle: ReactNode;
  rental: ReactNode;
  system: ReactNode;
  incidents: ReactNode;
};

export const CurrentRentalLayout = ({
  pickUp,
  duration,
  vehicle,
  rental,
  system,
  incidents,
}: CurrentRentalLayoutProps) => (
  <div className="w-full flex gap-12 px-6">
    <div>{pickUp}</div>
    <div>{duration}</div>
    <div className="flex-1">{vehicle}</div>
    <div className="w-20">{rental}</div>
    <div className="w-20">{system}</div>
    <div className="w-28 text-center">{incidents}</div>
  </div>
);
