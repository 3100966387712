import { defineMessages } from 'react-intl';

export default defineMessages({
  codePrefix: {
    defaultMessage: 'Code Prefix',
  },
  numberOfCodes: {
    defaultMessage: 'Number of codes',
  },
});
