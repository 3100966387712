import { defineMessages } from 'react-intl';

export default defineMessages({
  disclaimer: {
    defaultMessage:
      "We use our own and third-party cookies while browsing the website, in order to allow access to the tool's functionalities, extract traffic statistics and improve the pseudonymized user experience. You can accept all cookies, as well as select which ones you want to enable or configure your preferences. For more information, you can consult our {cookiesPolicy}.",
  },
  cookiesPolicy: {
    defaultMessage: 'Cookies policy',
  },
});
