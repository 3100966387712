import { HTMLAttributes, ReactNode } from 'react';
import { classNames } from '@cooltra/utils';

export type SubscriptionChargeRowLayoutProps =
  HTMLAttributes<HTMLDivElement> & {
    system: ReactNode;
    price: ReactNode;
    inputPrice: ReactNode;
    save: ReactNode;
  };

export const SubscriptionChargeRowLayout = ({
  system,
  price,
  inputPrice,
  save,
  className,
  ...rest
}: SubscriptionChargeRowLayoutProps) => (
  <div className={classNames('flex', 'h-full', 'w-full', className)} {...rest}>
    <div className="flex items-center w-full px-8 gap-4">
      <div className="flex-1 shrink-0">{system}</div>
      <div className="flex-1 shrink-0">{price}</div>
      <div className="mr-4 shrink-0">{inputPrice}</div>
      <div className="shrink-0">{save}</div>
    </div>
  </div>
);
