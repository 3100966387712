import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  useContractQuery,
  usePatchContractVehicleTypesMutation,
} from '@cooltra/station-based-api';
import { Button } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';
import { useEffect } from 'react';

import { useNotification } from '~/hooks';

import messages from './messages';

export type VehicleTypeCardAddButtonProps = {
  vehicleTypeId: string;
};

export const VehicleTypeCardAddButton = ({
  vehicleTypeId,
}: VehicleTypeCardAddButtonProps) => {
  const { contractId = '' } = useParams();
  const queryClient = useQueryClient();
  const { mutateAsync, isPending } =
    usePatchContractVehicleTypesMutation(contractId);
  const [isAdding, { toggleOn: startAdding, toggleOff: endAdding }] =
    useToggle();
  const { isRefetching } = useContractQuery(contractId, { enabled: false });

  const { addErrorNotification } = useNotification();

  useEffect(() => {
    if (!isPending && !isRefetching) {
      endAdding();
    }
  }, [endAdding, isPending, isRefetching]);

  const addVehicleType = () => {
    startAdding();
    mutateAsync({ vehicleTypeId })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: ['contract', contractId],
        })
      )
      .catch(() => addErrorNotification());
  };

  return (
    <Button onClick={addVehicleType} loading={isAdding}>
      <FormattedMessage {...messages.add} />
    </Button>
  );
};
