import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Driver license',
  },
  isValidDriverLicense: {
    defaultMessage: 'Is it a valid driver license in {country}?',
  },
  tooltipBody: {
    defaultMessage:
      'There are agreements between countries that determine what constitutes a valid driver license. For example, an Italian driver license is valid in Spain whereas an American is not (only an international one is).',
  },
  tooltipHelp: {
    defaultMessage: 'In case of doubts, check out this { csTableLink }.',
  },
  tooltipLink: {
    defaultMessage: 'table',
  },
  valid: {
    defaultMessage: 'Valid',
  },
  invalid: {
    defaultMessage: 'Invalid',
  },
});
