import { ThreeStateValue } from '@cooltra/api';

export type VehicleProblemReportsFiltersFormValues = {
  open: ThreeStateValue;
  minDate: string;
  maxDate: string;
};

export const vehicleProblemReportsFiltersInitialValues: VehicleProblemReportsFiltersFormValues =
  {
    open: '',
    minDate: '',
    maxDate: '',
  };
