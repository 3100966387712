import { ProblemReportColumnLabel } from '~/common';

import { UserProblemReportRowLayout } from './UserProblemReportRowLayout';

export const UserProblemReportsListHeader = () => (
  <UserProblemReportRowLayout
    problem={<ProblemReportColumnLabel name="problem" />}
    context={<ProblemReportColumnLabel name="context" />}
    created={<ProblemReportColumnLabel name="created" />}
    vehicle={
      <ProblemReportColumnLabel name="vehicle" className="text-center" />
    }
  />
);
