import { defineMessages } from 'react-intl';

export default defineMessages({
  sort: {
    defaultMessage: 'Sort by',
  },
  updatedAtAscending: {
    defaultMessage: 'Oldest first',
  },
  updatedAtDescending: {
    defaultMessage: 'Newest first',
  },
});
