import { FormattedMessage, useIntl } from 'react-intl';
import { MdBackupTable, MdCreditCard, MdQrCode } from 'react-icons/md';
import { FormRadioGroupCustomField } from '@cooltra/form';
import { DepositMethod } from '@cooltra/station-based-api';
import { classNames } from '@cooltra/utils';

import messages from './messages';

type DepositMethodOption = {
  label: string;
  value: DepositMethod;
};

type FormRadioGroupDepositsFieldProps = {
  hasLinkedPaymentMethod: boolean;
  amount: string;
};

export const FormRadioGroupDepositsField = ({
  hasLinkedPaymentMethod,
  amount,
}: FormRadioGroupDepositsFieldProps) => {
  const intl = useIntl();
  const isThereAnyDepositMethod = hasLinkedPaymentMethod && Number(amount) > 0;
  const depositMethodOptions: DepositMethodOption[] = isThereAnyDepositMethod
    ? [
        {
          label: intl.formatMessage(messages.webPayment),
          value: 'BOOKING_CARD',
        },
        {
          label: intl.formatMessage(messages.tpv),
          value: 'TPV',
        },
      ]
    : [
        {
          label: intl.formatMessage(messages.tpv),
          value: 'TPV',
        },
        {
          label: intl.formatMessage(messages.stripeQR),
          value: 'STRIPE_QR',
        },
      ];

  const getOptionIcon = (method: DepositMethod) => {
    switch (method) {
      case 'BOOKING_CARD':
        return <MdCreditCard className="text-2xl" />;
      case 'TPV':
        return <MdBackupTable className="text-2xl" />;
      case 'STRIPE_QR':
        return <MdQrCode className="text-2xl" />;
    }
  };

  return (
    <FormRadioGroupCustomField
      id="deposit-method"
      name="method"
      legend={
        <label
          htmlFor="deposit-method"
          className="block text-xl font-semibold text-neutral-700 mb-8 text-center"
        >
          <FormattedMessage {...messages.title} />
        </label>
      }
      items={depositMethodOptions}
      renderRadio={({ label, checked, value }) => (
        <div
          className={classNames(
            'p-6 flex gap-2 w-full font-semibold transition-colors',
            checked && 'text-primary-500'
          )}
        >
          {getOptionIcon(value as DepositMethod)}
          <span>{label}</span>
        </div>
      )}
    />
  );
};
