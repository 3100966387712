import { List } from '@cooltra/ui';
import { getErrorStatus } from '@cooltra/axios';
import {
  ContractListItem,
  useContractsQuery,
} from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { useSearchParams } from 'react-router-dom';

import { ErrorPage, Four0Three, NoSearchResults } from '~/common';

import { ContractsTotal } from './ContractsTotal/ContractsTotal';
import { ContractCard, ContractCardLoading } from './ContractCard';
import { ContractsListHeader } from './ContractsListHeader/ContractsListHeader';
import { ContractsInputSearch } from './ContractsInputSearch/ContractsInputSearch';

export const ContractsList = () => {
  const { hasPermission } = useAuthClaimsQuery();

  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('keyword') || '';

  const {
    data = [],
    isLoading,
    error,
    isError,
  } = useContractsQuery({ keyword }, { enabled: keyword !== '' });

  if (!hasPermission('read:station_based_contracts')) {
    return <Four0Three />;
  }

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  const getContent = () => {
    if (keyword === '') {
      return <></>;
    }

    return (
      <div
        className="container max-w-6xl py-28 px-6 lg:px-0"
        data-testid="CONTRACTS_LIST"
      >
        <ContractsTotal
          loading={isLoading}
          total={data.length}
          keyword={keyword}
          className="mb-12"
        />
        <List
          loadingRows={2}
          isLoading={isLoading}
          idProp="contractNumber"
          data={data}
          renderEmptyMessage={<NoSearchResults />}
          renderLoadingRow={<ContractCardLoading />}
          renderHeader={<ContractsListHeader />}
          renderRow={(contract: ContractListItem) => (
            <ContractCard contract={contract} />
          )}
        />
      </div>
    );
  };

  return (
    <main className="h-screen-content overflow-y-auto relative">
      <div className="px-4 lg:px-8 py-5 bg-neutral-0 shadow-sm fixed top-auto w-full z-50">
        <ContractsInputSearch />
      </div>
      <section>{getContent()}</section>
    </main>
  );
};
