import { defineMessages } from 'react-intl';

export default defineMessages({
  allAccessories: {
    defaultMessage: 'All accessories',
  },
  accessory: {
    defaultMessage: 'Accessories',
  },
  yes: {
    defaultMessage: 'Yes',
  },
  no: {
    defaultMessage: 'No',
  },
});
