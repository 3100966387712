import { FormProvider, FormProviderProps } from '@cooltra/form';

import { TechnicalInspectionFormValues } from './technical-inspection-form';

export const TechnicalInspectionFormProvider = ({
  onSubmit,
  initialValues,
  children,
  validate,
}: FormProviderProps<TechnicalInspectionFormValues>) => {
  return (
    <FormProvider
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {children}
    </FormProvider>
  );
};
