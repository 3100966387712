import { Icon } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';
import { MdError } from 'react-icons/md';

export type CalendarAlertVariant = 'danger' | 'primary';

const variantClasses: Record<CalendarAlertVariant, string> = {
  danger: 'text-danger-400',
  primary: 'text-primary-400',
};

export type CalendarAlertProps = HTMLAttributes<HTMLDivElement> & {
  variant?: CalendarAlertVariant;
};

export const CalendarAlert = ({
  children,
  variant = 'primary',
  className,
}: CalendarAlertProps) => (
  <div
    className={classNames(
      'inline-flex min-h-11 items-center gap-2 rounded-lg bg-primary-50 px-4 py-2 w-auto',
      className
    )}
  >
    <Icon>
      <MdError className={variantClasses[variant]} />
    </Icon>
    <p className="text-sm">{children}</p>
  </div>
);
