import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';
import { useLocation } from 'react-router-dom';
import { getParams } from '@cooltra/utils';

import {
  initialProblemReportsFiltersFormValues,
  ProblemReportsFiltersFormValues,
} from './problem-reports-filters-form';

const { sort, system, open, minDate, maxDate } =
  initialProblemReportsFiltersFormValues;

export const ProblemReportsFiltersForm = ({ children }: PropsWithChildren) => {
  const { search } = useLocation();

  const params = getParams<keyof ProblemReportsFiltersFormValues>(search);

  const initialValues = {
    sort: params.string('sort') || sort,
    system: params.array('system').length ? params.array('system') : system,
    open: params.string('open') || open,
    minDate: params.string('minDate') || minDate,
    maxDate: params.string('maxDate') || maxDate,
  } as ProblemReportsFiltersFormValues;

  return <FormProvider initialValues={initialValues}>{children}</FormProvider>;
};
