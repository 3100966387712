import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';
import { useLocation } from 'react-router-dom';
import { getParams } from '@cooltra/utils';

import { monthAgo } from '~/utils/date';

import { VehicleRentalsFiltersFormValues } from './vehicle-reservations-filters-form';

export const VehicleReservationsFiltersForm = ({
  children,
}: PropsWithChildren) => {
  const { search } = useLocation();
  const params = getParams<keyof VehicleRentalsFiltersFormValues>(search);

  const initialValues = {
    minDate: params.string('minDate') || monthAgo,
    maxDate: params.string('maxDate') || '',
  } as VehicleRentalsFiltersFormValues;

  return <FormProvider initialValues={initialValues}>{children}</FormProvider>;
};
