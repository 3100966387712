import { defineMessages } from 'react-intl';

export default defineMessages({
  backToProblemReport: {
    defaultMessage: 'Back to problem report',
  },
  backToIncidentList: {
    defaultMessage: 'Back to incident list',
  },
});
