import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    defaultMessage: 'Subscription',
  },
  placeholder: {
    defaultMessage: 'Select subscription',
  },
  noSubscriptions: {
    defaultMessage: 'No subscriptions',
  },
});
