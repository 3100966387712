import {
  differenceInYears,
  parse,
  isPast as dfIsPast,
  format,
  subDays,
  subMonths,
  endOfDay,
} from 'date-fns';

export const dateFormat = 'dd-MM-yyyy';
export const timeFormat = 'HH:mm';
export const dateAndTimeFormat = 'dd-MM-yyyy HH:mm';
export const dateAndTimeEnglishFormat = 'yyyy-MM-dd HH:mm';

const todayDate = new Date();

export const hoursInADay = 24;
export const thirtySeconds = 30 * 1000;

export const isAdult = (dateOfBirth: string) =>
  differenceInYears(todayDate, parse(dateOfBirth, dateFormat, todayDate)) >= 18;

export const isPast = (date: string) =>
  dfIsPast(endOfDay(parse(date, dateFormat, todayDate)));

export const calculateAge = (dateOfBirth: string): number => {
  const date = parse(dateOfBirth, dateFormat, todayDate);
  return differenceInYears(new Date(), date);
};

export const today = format(new Date(), dateFormat);

export const yesterday = format(subDays(new Date(), 1), dateFormat);
export const threeDaysAgo = format(subDays(new Date(), 3), dateFormat);
export const monthAgo = format(subMonths(new Date(), 1), dateFormat);

type SortableObject = {
  createdAt: string;
};

export const sortByDate =
  (descending = true) =>
  (a: SortableObject, b: SortableObject) =>
    descending
      ? new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      : new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
