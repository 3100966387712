import { VehicleListItem } from '@cooltra/api';

export const isWithOperator = (
  vehicle: VehicleListItem,
  { withOperator }: { withOperator: string }
) => {
  if (!withOperator) {
    return true;
  }

  return (
    vehicle.takenBy === withOperator || vehicle.workUnitBy === withOperator
  );
};
