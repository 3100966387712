import { Battery } from './Battery';
import { Lock } from './Lock';
import { CentralStand } from './CentralStand';
import { TopCase } from './TopCase';
import { Power } from './Power';

export const RentalVehicleStatus = {
  Battery,
  Lock,
  CentralStand,
  TopCase,
  Power,
};
