import { IntlShape } from 'react-intl';

import { createSetError, validateDate } from '~/validation';

import messages from './messages';
import { TechnicalInspectionFormValues } from './technical-inspection-form';

type TechnicalInspectionFormFieldName = keyof TechnicalInspectionFormValues;

type TechnicalInspectionFormErrors = Partial<
  Record<TechnicalInspectionFormFieldName, string | undefined>
>;

export const validateTechnicalInspectionForm =
  (intl: IntlShape) => (values: TechnicalInspectionFormValues) => {
    const errors: TechnicalInspectionFormErrors = {};
    const setError = createSetError(errors);

    if (values.scheduledAt) {
      setError(
        'scheduledAt',
        validateDate(intl, values.scheduledAt)
          ? intl.formatMessage(messages.invalidFormat)
          : undefined
      );
    }

    return errors;
  };
