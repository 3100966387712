import { IntlShape } from 'react-intl';
import { Location } from '@cooltra/api';
import { FormErrors } from '@cooltra/form';

import { validateRequired, createSetError } from '~/validation';

export type EditLocationFormValues = Pick<Location, 'name' | 'systems'>;

export const validateEditLocationForm =
  (intl: IntlShape) =>
  (values: EditLocationFormValues): FormErrors<EditLocationFormValues> => {
    const errors: FormErrors<EditLocationFormValues> = {};
    const setError = createSetError(errors);

    const nameErrorMessage = validateRequired(intl, values.name);
    if (!values.name) {
      setError('name', nameErrorMessage);
    }

    return errors;
  };

export const getLocationFormInitialValues = ({ name, systems }: Location) => ({
  name,
  systems,
});
