import { FormattedMessage } from 'react-intl';
import { ContractPayment } from '@cooltra/station-based-api';
import { getVehicleName, VehicleTypeName } from '@cooltra/utils';

import { Amount } from '~/common';

import messages from './messages';

type VehicleProps = {
  className?: string;
  payment?: ContractPayment;
};

export const Vehicle = ({ className, payment }: VehicleProps) => {
  if (!payment || !payment.vehicleType) return null;

  const vehicleTypeName = getVehicleName(
    payment.vehicleType.name as VehicleTypeName
  );

  return (
    <div className={className}>
      <h3>
        <span className="text-sm font-semibold text-neutral-800">
          <FormattedMessage {...messages.vehicle} />
        </span>
      </h3>
      <div className="flex justify-between my-2 gap-8 text-neutral-800">
        <span>
          <FormattedMessage
            {...messages.orSimilar}
            values={{
              vehicleTypeName: vehicleTypeName,
              contractDays: payment.vehicleType.days,
            }}
          />
        </span>
        <span className="text-neutral-800">
          <Amount {...payment.vehicleType.price} />
        </span>
      </div>
    </div>
  );
};
