import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLocationMutation } from '@cooltra/api';
import { FormButton, FormProvider, Form } from '@cooltra/form';
import { getErrorStatus } from '@cooltra/axios';

import { useNotification } from '~/hooks';

import { WriteLocationLayout } from '../WriteLocationLayout/WriteLocationLayout';
import * as LocationFields from '../LocationFields';

import {
  CreateLocationFormValues,
  formatLocationFormValues,
  validateCreateLocationForm,
  locationFormInitialValues,
} from './create-location-form';
import messages from './messages';

export const CreateLocation = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const mutation = useLocationMutation();

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleSubmit = (values: CreateLocationFormValues) =>
    mutation
      .mutateAsync(formatLocationFormValues(values))
      .then(() => {
        addSuccessNotification(intl.formatMessage(messages.success));
        navigate('/settings/locations');
      })
      .catch((error) => {
        addErrorNotification(
          getErrorStatus(error) === 409
            ? intl.formatMessage(messages.conflictError)
            : undefined
        );
      });

  return (
    <FormProvider
      initialValues={locationFormInitialValues}
      validate={validateCreateLocationForm(intl)}
      onSubmit={handleSubmit}
    >
      <Form data-testid="CREATE_LOCATION">
        <WriteLocationLayout
          renderTitle={<FormattedMessage {...messages.title} />}
          renderContent={
            <>
              <LocationFields.Name />
              <LocationFields.Systems />
            </>
          }
          renderFooter={
            <>
              <span />
              <FormButton>
                <FormattedMessage {...messages.submitButton} />
              </FormButton>
            </>
          }
        />
      </Form>
    </FormProvider>
  );
};
