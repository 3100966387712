import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { FormInputField } from '@cooltra/form';

import { OptionalFieldLabel } from '~/common';

import messages from './messages';

export type TaxIdNumberProps = {
  disabled?: boolean;
  optional?: boolean;
};

export const TaxIdNumber = ({
  disabled = false,
  optional = false,
}: TaxIdNumberProps) => (
  <FormInputField
    disabled={disabled}
    name="taxIdNumber"
    id="tax-id"
    label={
      optional ? (
        <OptionalFieldLabel htmlFor="tax-id">
          <FormattedMessage {...messages.taxIdNumber} />
        </OptionalFieldLabel>
      ) : (
        <InputLabel htmlFor="tax-id">
          <FormattedMessage {...messages.taxIdNumber} />
        </InputLabel>
      )
    }
  />
);
