import { defineMessages } from 'react-intl';
import { Model } from '@cooltra/api';

export const vehicleTypes = defineMessages<Model>({
  ARCADE_X2C: {
    defaultMessage: 'E-Bike',
  },
  ASKOLL_ES2: {
    defaultMessage: 'E-Scooter',
  },
  ASKOLL_ES2_B2B: {
    defaultMessage: 'E-Scooter',
  },
  GOVECS_DIRT: {
    defaultMessage: 'E-Scooter',
  },
  GOVECS_GOS: {
    defaultMessage: 'E-Scooter',
  },
  NIU_N1S: {
    defaultMessage: 'E-Scooter',
  },
  NIU_N1S_25: {
    defaultMessage: 'E-Scooter',
  },
  SEGWAY_A300: {
    defaultMessage: 'E-Bike',
  },
});
