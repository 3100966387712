import { Vehicle } from '@cooltra/api';
import { useOperatorsQuery } from '@cooltra/auth-api';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type TakenByProps = Pick<Vehicle, 'takenBy'>;

export const TakenBy = ({ takenBy }: TakenByProps) => {
  const { data: operators } = useOperatorsQuery();
  const takenByName = operators?.find(
    ({ externalId }) => externalId === takenBy
  )?.name;

  return (
    <div>
      <span className="text-xs text-neutral-400 block mb-0.5">
        <FormattedMessage {...messages.takenBy} />
      </span>
      <span className="block text-sm font-semibold">{takenByName}</span>
    </div>
  );
};
