import { ReactNode } from 'react';
import { Alert, AlertProps } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';

export type ReviewOutcomeAlertProps = Omit<AlertProps, 'children'> & {
  renderHeader: ReactNode;
  renderList: ReactNode;
  renderButton: ReactNode;
};

export const ReviewOutcomeAlert = ({
  renderHeader,
  renderList,
  renderButton,
  className,
  ...rest
}: ReviewOutcomeAlertProps) => (
  <Alert className={classNames('h-full grow max-w-2xl', className)} {...rest}>
    <div className="h-full flex gap-4">
      <div className="grow">
        <h6 className="font-semibold">{renderHeader}</h6>
        <ul className="list-disc pl-5">{renderList}</ul>
      </div>
      <div className="flex items-end">{renderButton}</div>
    </div>
  </Alert>
);
