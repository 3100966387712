import { Card, Select } from '@cooltra/ui';
import { getParams } from '@cooltra/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { pricingGroupNames } from '@cooltra/station-based-api';

import { useNavigateWithQueryParams } from '~/hooks';

import messages from './messages';

type PricingGroupSelectProps = {
  isLoading: boolean;
};

const options = Object.entries(pricingGroupNames)
  .map(([key, name]) => ({
    value: key,
    label: name,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

export const PricingGroupSelect = ({ isLoading }: PricingGroupSelectProps) => {
  const { formatMessage } = useIntl();
  const { search } = useLocation();
  const params = getParams<'pricingGroup'>(search);
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const handleOnChange = (selectedPricingGroup: string) =>
    navigateWithQueryParams({ pricingGroup: selectedPricingGroup });

  useEffect(() => {
    if (!params.string('pricingGroup')) {
      navigateWithQueryParams({ pricingGroup: options[0].value });
    }
  }, [navigateWithQueryParams, params]);

  return (
    <Card className="mb-10 w-full py-6">
      <div className="container flex items-center gap-4">
        <span className="text-base font-semibold text-neutral-700">
          <FormattedMessage {...messages.pricingGroup} />
        </span>
        <Select
          aria-label={formatMessage(messages.selectPricingGroup)}
          isClearable
          className="w-72 text-sm"
          options={options}
          value={params.string('pricingGroup') || ''}
          onChange={handleOnChange}
          isLoading={isLoading}
          placeholder={formatMessage(messages.selectPricingGroup)}
        />
      </div>
    </Card>
  );
};
