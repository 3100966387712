import { Card, Placeholder } from '@cooltra/ui';

import { CurrentRentalLayout } from '../CurrentRental';

export const CurrentActivityLoading = () => (
  <Card className="py-8 flex">
    <CurrentRentalLayout
      pickUp={
        <div className="flex flex-col gap-3">
          <Placeholder className="w-12 h-2" />
          <Placeholder className="w-20 h-2" />
          <Placeholder className="w-28 h-2" />
        </div>
      }
      duration={
        <div className="flex flex-col gap-3">
          <Placeholder className="w-12 h-2" />
          <Placeholder className="w-36 h-2" />
        </div>
      }
      vehicle={
        <div className="flex flex-col gap-3">
          <Placeholder className="w-12 h-2" />
          <Placeholder className="w-24 h-2" />
        </div>
      }
      rental={
        <div className="flex flex-col gap-3">
          <Placeholder className="w-12 h-2" />
          <Placeholder className="w-24 h-2" />
        </div>
      }
      system={
        <div className="flex flex-col gap-3">
          <Placeholder className="w-12 h-2" />
          <Placeholder className="w-24 h-2" />
        </div>
      }
      incidents={
        <div className="flex flex-col gap-3">
          <Placeholder className="w-12 h-2 mx-auto" />
          <Placeholder className="w-24 h-2 mx-auto" />
        </div>
      }
    />
  </Card>
);
