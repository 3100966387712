import { defineMessages } from 'react-intl';

export default defineMessages({
  providerUsers: {
    defaultMessage:
      '{total, plural, one {# result} other {# results}} for "{keyboard}" from Providers',
  },
  users: {
    defaultMessage:
      '{total, plural, one {# result} other {# results}} for "{keyboard}" from Cooltra',
  },
});
