import { FormattedMessage } from 'react-intl';
import { VehicleListItem } from '@cooltra/api';
import { useOperatorsQuery } from '@cooltra/auth-api';
import { Placeholder } from '@cooltra/ui';

import messages from './messages';

type WorkUnitByProps = Pick<VehicleListItem, 'workUnitBy'>;

export const WorkUnitBy = ({ workUnitBy }: WorkUnitByProps) => {
  const { data, isLoading } = useOperatorsQuery();

  const operatorName = data?.find(
    ({ externalId }) => workUnitBy === externalId
  )?.name;

  return (
    <div
      data-testid="VEHICLE_LIST_ITEM_WORK_UNIT_BY"
      className="h-24 w-full flex flex-col justify-center"
    >
      <span className="block text-xs text-neutral-400 mb-0.5">
        <FormattedMessage {...messages.workInProgress} />
      </span>
      <span className="block text-xs text-neutral-900">
        {isLoading ? <Placeholder className="h-2 mt-0.5 w-24" /> : operatorName}
      </span>
    </div>
  );
};
