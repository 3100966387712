import { FormattedMessage } from 'react-intl';
import { useFormContext } from '@cooltra/form';

import { StationBasedFormValues, StationBasedPromotionFields } from '~/common';

import messages from '../messages';

export const CouponDetails = () => {
  const { values } = useFormContext<StationBasedFormValues>();

  if (values.promotionTarget !== 'COUPON') {
    return <></>;
  }

  return (
    <div className="p-10">
      <h2 className="text-xl font-semibold text-neutral-400 mb-1">
        <FormattedMessage {...messages.details} />
      </h2>
      <div className="flex flex-col gap-8">
        <div className="flex gap-8">
          <div className="mt-6">
            <StationBasedPromotionFields.CouponTypeSelector />
          </div>
          <div>
            {values.couponType === 'DISCOUNT' ? (
              <StationBasedPromotionFields.Discount />
            ) : (
              <StationBasedPromotionFields.CreditValue />
            )}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-x-5">
          <div className="col-span-3">
            <StationBasedPromotionFields.About />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-x-5">
          <div className="col-span-2">
            <StationBasedPromotionFields.Code />
          </div>
        </div>
      </div>
    </div>
  );
};
