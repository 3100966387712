import { defineMessages } from 'react-intl';

export default defineMessages({
  release: {
    defaultMessage: 'Release from location',
  },
  title: {
    defaultMessage: 'Release from location',
  },
  content: {
    defaultMessage:
      'Are you sure you want to release the selected vehicles from their locations?',
  },
  success: {
    defaultMessage: 'Vehicles were successfully released from their locations',
  },
});
