import { FormattedMessage } from 'react-intl';
import { useFormContext, FormAutocompleteField } from '@cooltra/form';

import { allContractTypes, getContractTypeName } from '~/utils/contract';

import { ContractDetailsFormValues } from '../contract-details-form';

import messages from './messages';

export type ContractDetailsChannelProps = {
  disabled?: boolean;
};

export const ContractDetailsChannel = ({
  disabled = false,
}: ContractDetailsChannelProps) => {
  const { setValues, initialValues } =
    useFormContext<ContractDetailsFormValues>();

  const handleSelectedItemChange = ({
    selectedItem,
  }: {
    selectedItem?: string | null | undefined;
  }) => {
    if (!selectedItem) {
      return;
    }

    setValues({
      ...initialValues,
      type: selectedItem,
    });
  };

  const contractTypePresetValues = allContractTypes
    .filter((contractType) => contractType !== 'WEB' && contractType !== 'APP')
    .map((contractType) => getContractTypeName(contractType));

  return (
    <FormAutocompleteField
      data-testid="CONTRACT_DETAILS_CHANNEL_FIELD"
      name="type"
      disabled={disabled}
      onSelectedItemChange={handleSelectedItemChange}
      items={contractTypePresetValues}
      label={<FormattedMessage {...messages.channel} />}
    />
  );
};
