import { defineMessages } from 'react-intl';

export default defineMessages({
  stripeQRRetentionMethod: {
    defaultMessage: 'Stripe QR Withhold Method',
  },
  verifyDepositWithhold: {
    defaultMessage: 'Verify deposit',
  },
  copyLink: {
    defaultMessage: 'Copy link',
  },
});
