import { defineMessages } from 'react-intl';

export default defineMessages({
  goToVehicle: {
    defaultMessage: 'Go to vehicle',
  },
  close: {
    defaultMessage: 'Close',
  },
});
