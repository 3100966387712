import { defineMessages } from 'react-intl';
import { UserDiscountState } from '@cooltra/api';

export default defineMessages({
  validUntil: {
    defaultMessage: 'Valid until',
  },
  validFrom: {
    defaultMessage: 'Valid from',
  },
});
export const statusMessages = defineMessages<UserDiscountState>({
  ACTIVE: {
    defaultMessage: 'Active',
  },
  SCHEDULED: {
    defaultMessage: 'Scheduled',
  },
});
