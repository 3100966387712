export const Motorbike = () => (
  <>
    <g clipPath="url(#clip0_3067_16672)">
      <g
        fill="#fff"
        fillRule="evenodd"
        clipPath="url(#clip1_3067_16672)"
        clipRule="evenodd"
      >
        <path d="M89.893 49.59c-.399.254-.564.773-.495 1.556l.054.414.552.922c2.22 3.594 3.817 6.319 4.788 8.174-4.847 2.51-8.134 7.416-8.134 13.055 0 1.71.302 3.353.858 4.882a3.141 3.141 0 01-1.702.727l-.53.03H67.175c-1.513 0-2.916-.716-3.767-1.896l-.262-.41-9.697-17.178-1.54-.157c-2.483-.266-4.501-.558-5.405-.849-1.424-.459-2.291-.91-2.817-1.363l-3.661-.042c-1.998-.041-3.112-.11-3.343-.206-.694-.286-3.156-3.596-3.156-6.722 0-2.903 4.77-6.268 11.744-6.534l.814-.017.833.012c3.998.127 5.648 2.588 5.76 4.081.11 1.493.05 5.375-.558 6.365l-.089.12 3.305.33c6.309.666 13.566 1.619 14.736 2.383 1.916 1.252 1 3.08-.432 3.47l.018-.7c-.194 6.095.271 10.712 1.396 13.851l1.388.118c.647.044 1.228.064 1.775.052l.668-.03a10.936 10.936 0 002.583-.507c2.02-.64 6.596-14.333 6.965-15.644.185-.656.31-1.653.374-2.99l.044-1.453-2.897-5.882-3.5 1.634-.233.074-.237.012a.918.918 0 01-.753-.491.85.85 0 01.212-1.038l.206-.132 4.047-1.888.186-.138-.002-4.765a2.88 2.88 0 01-.718.257l-.313.037c-2.088.062-1.968-3.878.06-4.055h.302c.421.039.91.227 1.466.618.797.561.909 1.305.92 1.765l-.01.32.001 4.564 1.532-1.101c1.357-.958 2.193-1.484 2.505-1.58l.085-.016.545.018c1.304.062 4.31.307 4.848 1.009.644.842.27 5.34-.645 6.271-.305.31-.614.438-.917.487l-.3.027-.577-.013c-.279-.005-.54.015-.772.163z"></path>
        <path d="M101.728 61.7c6.777 0 12.272 5.291 12.272 11.818 0 6.31-5.134 11.464-11.599 11.8l-.673.018c-6.778 0-12.273-5.291-12.273-11.818 0-6.31 5.135-11.464 11.599-11.8l.674-.018zm0 5.204c-3.794 0-6.87 2.961-6.87 6.614s3.076 6.614 6.87 6.614c3.793 0 6.868-2.96 6.868-6.614 0-3.653-3.075-6.614-6.868-6.614zM45.27 61.706c6.775 0 12.268 5.29 12.268 11.815 0 6.307-5.133 11.46-11.596 11.797l-.673.017C38.493 85.335 33 80.045 33 73.522c0-6.308 5.133-11.46 11.596-11.797l.673-.018zm0 5.197c-3.796 0-6.873 2.963-6.873 6.618 0 3.655 3.077 6.617 6.872 6.617 3.796 0 6.872-2.962 6.872-6.617s-3.076-6.618-6.872-6.618z"></path>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3067_16672">
        <path fill="#fff" d="M0 0H144V144H0z"></path>
      </clipPath>
      <clipPath id="clip1_3067_16672">
        <path fill="#fff" d="M0 0H81V78H0z" transform="translate(33 24)"></path>
      </clipPath>
    </defs>
  </>
);
