import { defineMessages } from 'react-intl';

export default defineMessages({
  date: {
    defaultMessage: 'Date',
  },
  deviceAndVersion: {
    defaultMessage: 'Device & Version',
  },
});
