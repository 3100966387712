import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Task',
  },
  createTask: {
    defaultMessage: 'Create task',
  },
  createTaskAndCloseReport: {
    defaultMessage: 'Create and close PR',
  },
  conflictErrorMessage: {
    defaultMessage: 'Task already exists!',
  },
  success: {
    defaultMessage: 'You have successfully created a new task!',
  },
  errorOnCloseReport: {
    defaultMessage: 'Error while trying to close the task',
  },
});
