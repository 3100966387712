import { Route, Routes } from 'react-router-dom';

import {
  ParkedVehiclesReview,
  ParkedVehiclesReviewForm,
} from './ParkedVehiclesReview';

export const Fines = () => (
  <Routes>
    <Route
      path="/*"
      element={
        <ParkedVehiclesReviewForm>
          <ParkedVehiclesReview />
        </ParkedVehiclesReviewForm>
      }
    />
  </Routes>
);
