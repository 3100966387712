import { ReactNode } from 'react';
import { Auth0Context, Auth0ContextInterface } from '@auth0/auth0-react';
import { authMock } from '@cooltra/auth-api';

export type MockAuth0ProviderProps = {
  children: ReactNode;
};

export const MockAuth0Provider = ({ children }: MockAuth0ProviderProps) => {
  const token =
    localStorage.getItem('mock-auth-token') || authMock.createToken();

  const getAccessTokenSilently = (): Promise<any> =>
    new Promise((resolve) => resolve(token));

  const asyncMock = (): Promise<any> =>
    new Promise((resolve) => resolve('test'));

  const value: Auth0ContextInterface = {
    getAccessTokenSilently: getAccessTokenSilently,
    getAccessTokenWithPopup: asyncMock,
    getIdTokenClaims: asyncMock,
    loginWithRedirect: asyncMock,
    loginWithPopup: asyncMock,
    logout: asyncMock,
    handleRedirectCallback: asyncMock,
    isAuthenticated: true,
    isLoading: false,
    user: {
      sub: 'auth0|86c4-5875-9fa1-0a094a0f7381',
    },
  };

  return (
    <Auth0Context.Provider value={value}>{children}</Auth0Context.Provider>
  );
};
