import { defineMessages } from 'react-intl';

export default defineMessages({
  homeSystems: {
    defaultMessage: 'Home systems',
  },
  allHomeSystems: {
    defaultMessage: 'All home systems',
  },
});
