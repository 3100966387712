import { Card } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { UserInputSearch } from './UsersInputSearch/UserInputSearch';
import messages from './messages';

export const UsersSearch = () => (
  <Card className="h-[250px] pt-12 pb-14 text-center">
    <h1 className="font-semibold text-4xl">
      <FormattedMessage {...messages.searchUsers} />
    </h1>
    <p className="w-80 mt-3 mx-auto text-sm text-center font-normal text-neutral-800">
      <FormattedMessage {...messages.explanation} />
    </p>
    <UserInputSearch className="w-80 mt-5 mx-auto text-left" />
  </Card>
);
