import { RentalListItem } from '@cooltra/api';
import { RouterCardLink } from '@cooltra/navigation';
import { classNames } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';

import {
  RentalIncidentsBadge,
  DropOffListItem,
  PickUp,
  RentalDuration,
  RentalStateBadge,
  UserListItemLink,
  RentalSystem,
} from '~/common';
import { cities } from '~/common/System/messages';

import { VehicleRentalRowLayout } from '../VehicleRentalRowLayout';

export const VehicleRentalCard = ({
  rentalId,
  state,
  system,
  user: { userId, name, provider },
  pickUp,
  dropOff,
  incidentIds,
}: RentalListItem) => (
  <RouterCardLink
    to={`/rentals/${rentalId}`}
    data-testid={`RENTAL_CARD_${rentalId}`}
    className={classNames('py-4', state === 'ACTIVE' && 'bg-success-50/50')}
    isMultiAction
  >
    <VehicleRentalRowLayout
      pickUp={<PickUp pickUp={pickUp} />}
      dropOff={<DropOffListItem dropOff={dropOff} />}
      duration={
        <RentalDuration
          startDate={pickUp.createdAt}
          finishDate={dropOff?.createdAt || new Date()}
          className="text-neutral-400 text-sm"
        />
      }
      user={
        <UserListItemLink name={name} userId={userId} provider={provider} />
      }
      system={
        <RentalSystem>
          <FormattedMessage {...cities[system]} />
        </RentalSystem>
      }
      incidents={
        <RentalIncidentsBadge rentalId={rentalId} incidentIds={incidentIds} />
      }
      state={<RentalStateBadge state={state} />}
    />
  </RouterCardLink>
);
