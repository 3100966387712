import { useVehicleStatusQuery } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { FormattedMessage } from 'react-intl';
import { Placeholder } from '@cooltra/ui';

import { NotAvailable } from '~/common';

import messages from './messages';
import { countIssues } from './count-issues';

export type TelematicsListItemProps = {
  vehicleId: string;
};

export const TelematicsListItem = ({ vehicleId }: TelematicsListItemProps) => {
  const {
    data: status,
    error,
    isLoading,
  } = useVehicleStatusQuery(vehicleId, {
    enabled: !!vehicleId,
    retry: false,
  });

  const renderTelematicsStatus = () => {
    if (isLoading) {
      return <Placeholder className="w-32 h-3" />;
    }

    if (getErrorStatus(error) === 404) {
      return <NotAvailable />;
    }

    if (!status) {
      return <></>;
    }

    if (status.offline === null) {
      return <NotAvailable />;
    }

    const issuesCount = countIssues(status);

    if (issuesCount === 0) {
      return (
        <span className="text-sm text-neutral-500 pl-2 truncate">
          <FormattedMessage {...messages.noIssues} />
        </span>
      );
    }

    return (
      <span className="text-sm text-danger-500 pl-2 truncate">
        <span className="pr-1">{issuesCount}</span>
        {issuesCount === 1 ? (
          <FormattedMessage {...messages.issue} />
        ) : (
          <FormattedMessage {...messages.issues} />
        )}
      </span>
    );
  };

  return (
    <li className="py-4 border-b border-solid border-neutral-100">
      <div className="flex justify-between items-center gap-4">
        <span className="text-sm text-neutral-400">
          <FormattedMessage {...messages.telematics} />
        </span>
        {renderTelematicsStatus()}
      </div>
    </li>
  );
};
