import { Provider } from '@cooltra/api';
import { RouterLink, RouterLinkProps } from '@cooltra/navigation';

export type UserLinkProps = Omit<RouterLinkProps, 'to'> & {
  userId: string;
  provider: Provider;
};

export const UserLink = ({ userId, provider, ...rest }: UserLinkProps) => (
  <RouterLink
    to={
      provider === 'COOLTRA'
        ? `/users/${userId}/account/overview`
        : `/maas/users/${userId}/account/overview`
    }
    {...rest}
  />
);
