import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Comments',
  },
  placeholder: {
    defaultMessage: 'Leave your comments here',
  },
});
