import {
  Reservation,
  useReservationsQuery,
  useReservationsTotal,
} from '@cooltra/api';
import { Form, useFormContext } from '@cooltra/form';
import { Card, InfiniteList } from '@cooltra/ui';

import {
  ApplyFiltersButton,
  ErrorPage,
  FilterFields,
  NoSearchResults,
  ReservationsTotal,
  ResetFiltersButton,
} from '~/common';
import { usePreferredSystem } from '~/libs/preferred-system';
import { useNavigateWithQueryParams } from '~/hooks';

import * as ReservationsFilterFields from './ReservationsFilterFields';
import { ReservationCard, ReservationCardLoading } from './ReservationCard';
import {
  initialReservationsFiltersFormValues,
  ReservationsFiltersFormValues,
} from './reservations-filters-form';
import { ReservationsListHeader } from './ReservationsListHeader';
import { useReservationsFiltersFromUrl } from './ReservationsFiltersForm';

export const ReservationsList = () => {
  const navigateWithQueryParams = useNavigateWithQueryParams();

  const { system } = usePreferredSystem();
  const initialValues = {
    ...initialReservationsFiltersFormValues,
    system: [system],
  };

  const reservationsFiltersFromUrl = useReservationsFiltersFromUrl();

  const persistQueryParams = (values: ReservationsFiltersFormValues) =>
    navigateWithQueryParams(values);

  const { values } = useFormContext<ReservationsFiltersFormValues>();

  const resetFilters = () => persistQueryParams(initialValues);

  const { isError, isLoading, data, fetchNextPage, isFetchingNextPage } =
    useReservationsQuery(reservationsFiltersFromUrl, {
      refetchOnWindowFocus: false,
    });

  const reservations = data?.pages
    ? ([] as Reservation[]).concat.apply([], data.pages)
    : [];

  const reservationsTotal = useReservationsTotal(values) || 0;

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div>
      <Card className="p-8 mb-10" data-testid="RESERVATIONS_FILTERS">
        <Form>
          <div className="grid grid-cols-3 gap-y-6 gap-x-8 mb-12">
            <FilterFields.System />
            <ReservationsFilterFields.State />
            <FilterFields.Created />
          </div>
          <div className="flex justify-between">
            <ResetFiltersButton
              initialValues={{
                ...initialReservationsFiltersFormValues,
                system: [system],
              }}
              onClick={resetFilters}
            />
            <ApplyFiltersButton />
          </div>
        </Form>
      </Card>
      <div className="flex items-center h-11 mb-12">
        <ReservationsTotal loading={isLoading} total={reservationsTotal} />
      </div>
      <div data-testid="RESERVATIONS">
        <InfiniteList
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          fetchMore={fetchNextPage}
          hasMore={reservations.length < reservationsTotal}
          idProp="reservationId"
          data={reservations}
          renderEmptyMessage={<NoSearchResults />}
          renderHeader={<ReservationsListHeader />}
          renderLoadingRow={<ReservationCardLoading />}
          renderRow={(reservation: Reservation) => (
            <ReservationCard {...reservation} />
          )}
        />
      </div>
    </div>
  );
};
