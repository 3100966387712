import { InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSelectField } from '@cooltra/form';

import messages from './messages';

export const Status = () => {
  const { formatMessage } = useIntl();
  return (
    <FormSelectField
      name="open"
      id="status"
      isClearable
      placeholder={formatMessage(messages.placeholder)}
      label={
        <InputLabel htmlFor="status">
          <FormattedMessage {...messages.status} />
        </InputLabel>
      }
      options={[
        {
          label: formatMessage(messages.open),
          value: 'true',
        },
        {
          label: formatMessage(messages.closed),
          value: 'false',
        },
      ]}
    />
  );
};
