import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@cooltra/ui';
import {
  ContractDeposit,
  ContractStatus,
  useCancelContractDepositMutation,
} from '@cooltra/station-based-api';
import { useToggle } from '@cooltra/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { isPast } from 'date-fns';

import { useContractFlags, useNotification, usePolling } from '~/hooks';
import { Amount, ConfirmModal } from '~/common';

import messages from './messages';

export type ReleaseDepositProps = {
  contractId: string;
  contractStatus: ContractStatus;
  deposit: ContractDeposit;
};

export const ReleaseDeposit = ({
  contractId,
  contractStatus,
  deposit: {
    amount,
    id,
    lastFourDigits,
    operationNumber,
    method,
    withheldValidUntil,
  },
}: ReleaseDepositProps) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const { mutateAsync, isPending, isSuccess } =
    useCancelContractDepositMutation(contractId, id);
  const { addSuccessNotification, addErrorNotification } = useNotification();

  const { hasDepositBeenWithheld, isSignedOrPendingSignature } =
    useContractFlags();

  const retentionHasExpired = withheldValidUntil && isPast(withheldValidUntil);

  const displayReleaseButton =
    hasDepositBeenWithheld &&
    !retentionHasExpired &&
    (contractStatus === 'DRAFT' ||
      contractStatus === 'BOOKED' ||
      contractStatus === 'ACTIVE' ||
      contractStatus === 'CLOSED');

  const invalidateQueries = async () => {
    await queryClient.invalidateQueries({ queryKey: ['contract', contractId] });
    await queryClient.invalidateQueries({
      queryKey: ['contract-payments', contractId],
    });
  };

  const { isPolling } = usePolling({
    fn: invalidateQueries,
    onSettle: () => toggleOff(),
    interval: isSuccess ? 500 : 0,
  });

  const handleReleaseDeposit = () =>
    mutateAsync()
      .then(() => {
        addSuccessNotification(formatMessage(messages.depositHasBeenReleased));
        toggleOff();
      })
      .catch(() => addErrorNotification());

  if (!displayReleaseButton) {
    return null;
  }

  const getReleaseContractDepositModal = () => {
    if (method === 'TPV') {
      return (
        <ConfirmModal
          className="max-w-md min-w-md"
          dataTestId="RELEASE_CONTRACT_DEPOSIT_CONFIRM_MODAL"
          isOpen={isOpen}
          title={formatMessage(messages.areYourSureToReleaseTPVDeposit)}
          content={
            <div className="mb-5">
              <p>
                <FormattedMessage
                  {...messages.tpvOperationId}
                  values={{
                    operationId: operationNumber,
                  }}
                />
              </p>
              <p>
                <FormattedMessage
                  {...messages.cardLastFourDigits}
                  values={{
                    lastFourDigits: lastFourDigits,
                  }}
                />
              </p>
            </div>
          }
          confirmText={formatMessage(messages.tpvConfirmRelease, {
            amount: <Amount value={amount.value} currency={amount.currency} />,
          })}
          onClose={toggleOff}
          onConfirm={handleReleaseDeposit}
          loading={isPending || isPolling}
        />
      );
    }

    return (
      <ConfirmModal
        className="max-w-md min-w-md"
        dataTestId="RELEASE_CONTRACT_DEPOSIT_CONFIRM_MODAL"
        isOpen={isOpen}
        title={formatMessage(messages.areYourSureToReleaseStripeDeposit)}
        content=""
        confirmText={formatMessage(messages.confirmRelease, {
          amount: <Amount value={amount.value} currency={amount.currency} />,
        })}
        onClose={toggleOff}
        onConfirm={handleReleaseDeposit}
        loading={isPending || isPolling}
      />
    );
  };

  return (
    <>
      {getReleaseContractDepositModal()}
      <Button
        size="sm"
        onClick={toggleOn}
        disabled={isSignedOrPendingSignature}
      >
        {method === 'TPV' ? (
          <FormattedMessage {...messages.markAsReleased} />
        ) : (
          <FormattedMessage {...messages.release} />
        )}
      </Button>
    </>
  );
};
