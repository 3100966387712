import { useContext } from 'react';

import { PreferredServicePointsContext } from './PreferredServicePointsContext';

export const usePreferredServicePoints = () => {
  const preferredServicePointsContext = useContext(
    PreferredServicePointsContext
  );
  if (!preferredServicePointsContext) {
    throw new Error(
      'usePreferredServicePoints can be only used within <PreferredServicePointsProvider />'
    );
  }
  return {
    preferredServicePoints:
      preferredServicePointsContext.preferredServicePoints,
    updateServicePoints:
      preferredServicePointsContext.updatePreferredServicePoints,
  };
};
