import { RouterBaseLink } from '@cooltra/navigation';
import { Badge } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type ReservationProblemReportsBadgeProps = {
  problemReportIds: string[];
  userId?: string;
};

export const ReservationProblemReportsBadge = ({
  problemReportIds,
  userId,
}: ReservationProblemReportsBadgeProps) => {
  const { length } = problemReportIds;

  if (!length) {
    return (
      <span className="text-sm text-neutral-300">
        <FormattedMessage {...messages.none} />
      </span>
    );
  }

  if (length === 1) {
    return (
      <RouterBaseLink to={`/problem-reports/${problemReportIds[0]}`}>
        <Badge variant="warning">
          <FormattedMessage
            {...messages.problemReports}
            values={{
              total: length,
            }}
          />
        </Badge>
      </RouterBaseLink>
    );
  }

  if (userId) {
    return (
      <RouterBaseLink to={`/users/${userId}/account/problem-reports`}>
        <Badge variant="warning">
          <FormattedMessage
            {...messages.problemReports}
            values={{
              total: length,
            }}
          />
        </Badge>
      </RouterBaseLink>
    );
  }

  return (
    <Badge variant="warning">
      <FormattedMessage
        {...messages.problemReports}
        values={{
          total: length,
        }}
      />
    </Badge>
  );
};
