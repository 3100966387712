import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'No rentals found',
  },
  content: {
    defaultMessage: 'This user is yet to make his first rental',
  },
});
