import { Route, Routes } from 'react-router-dom';

import { PermissionCheck } from '~/common';

import { CreateTechnicalInspection } from './CreateTechnicalInspection/CreateTechnicalInspection';
import { EditTechnicalInspection } from './EditTechnicalInspection/EditTechnicalInspection';

export const TechnicalInspections = () => (
  <Routes>
    <Route
      path="new"
      element={
        <PermissionCheck permission="write:services">
          <CreateTechnicalInspection />
        </PermissionCheck>
      }
    />
    <Route
      path="/:technicalInspectionId/*"
      element={
        <PermissionCheck permission="write:services">
          <EditTechnicalInspection />
        </PermissionCheck>
      }
    />
  </Routes>
);
