import { Card, Placeholder } from '@cooltra/ui';

import { IncidentRowLayout } from './IncidentRowLayout';

export const IncidentCardLoading = () => (
  <Card className="h-28 py-0.5 flex">
    <IncidentRowLayout
      incident={
        <>
          <Placeholder className="w-28 h-2 mb-3" />
          <Placeholder className="h-2 w-52 mb-2" />
          <Placeholder className="h-2 w-40" />
        </>
      }
      assignee={
        <>
          <Placeholder className="h-2 w-20 mb-3" />
          <Placeholder className="h-2 w-28" />
        </>
      }
      created={
        <>
          <Placeholder className="w-16 h-2 mb-3" />
          <Placeholder className="w-32 h-2 mb-2" />
          <Placeholder className="w-24 h-2" />
        </>
      }
    />
  </Card>
);
