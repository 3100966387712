import { AlarmType } from '@cooltra/api';
import { defineMessages } from 'react-intl';

export default defineMessages({
  all: {
    defaultMessage: 'All',
  },
});

export const alarmTypes = defineMessages<AlarmType>({
  UNLOCKED: {
    defaultMessage: 'Unlocked',
  },
  STOLEN: {
    defaultMessage: 'Stolen',
  },
  OFFLINE: {
    defaultMessage: 'Offline',
  },
});
