import { IntlShape } from 'react-intl';
import { Contract, ContractSecondDriver } from '@cooltra/station-based-api';

import {
  createSetError,
  validateDate,
  validateExpirationDate,
  validateName,
  validateRequired,
} from '~/validation';

export type SecondDriverDetailsFormValues = {
  firstName: string;
  lastName: string;
  driverLicenseNumber: string;
  driverLicenseExpirationDate: string;
  driverLicenseCountry: string;
  driverLicenseFront: string;
  driverLicenseBack: string;
};

type SecondDriverDetailsFormFieldName = keyof SecondDriverDetailsFormValues;

type SecondDriverDetailsFormErrors = Partial<
  Record<SecondDriverDetailsFormFieldName, string | undefined>
>;

const requiredFields: SecondDriverDetailsFormFieldName[] = [
  'firstName',
  'lastName',
];

const nameFields: SecondDriverDetailsFormFieldName[] = [
  'firstName',
  'lastName',
];

export const validateSecondDriverDetailsForm =
  (intl: IntlShape) =>
  (values: SecondDriverDetailsFormValues): SecondDriverDetailsFormErrors => {
    const errors: SecondDriverDetailsFormErrors = {};
    const setError = createSetError(errors);

    nameFields.forEach((fieldName) => {
      setError(fieldName, validateName(intl, values[fieldName]));
    });
    requiredFields.forEach((fieldName) => {
      setError(fieldName, validateRequired(intl, values[fieldName]));
    });
    if (values.driverLicenseExpirationDate) {
      setError(
        'driverLicenseExpirationDate',
        validateExpirationDate(intl, values.driverLicenseExpirationDate)
      );
      setError(
        'driverLicenseExpirationDate',
        validateDate(intl, values.driverLicenseExpirationDate)
      );
    }

    return errors;
  };

export const getSecondDriverFormInitialValues = ({
  secondDriver,
}: Contract): SecondDriverDetailsFormValues => ({
  firstName: secondDriver?.firstName || '',
  lastName: secondDriver?.lastName || '',
  driverLicenseNumber: secondDriver?.driverLicenseNumber || '',
  driverLicenseExpirationDate: secondDriver?.driverLicenseExpirationDate || '',
  driverLicenseCountry: secondDriver?.driverLicenseCountry || '',
  driverLicenseFront: secondDriver?.photos?.driverLicenseFront || '',
  driverLicenseBack: secondDriver?.photos?.driverLicenseBack || '',
});

export const formatSecondDriverDetailsPayload = ({
  firstName,
  lastName,
  driverLicenseNumber,
  driverLicenseExpirationDate,
  driverLicenseCountry,
  driverLicenseBack,
  driverLicenseFront,
}: SecondDriverDetailsFormValues): ContractSecondDriver => ({
  firstName,
  lastName,
  driverLicenseNumber: driverLicenseNumber || undefined,
  driverLicenseExpirationDate: driverLicenseExpirationDate || undefined,
  driverLicenseCountry: driverLicenseCountry || undefined,
  ...(driverLicenseBack || driverLicenseFront
    ? {
        photos: {
          driverLicenseFront: driverLicenseFront || undefined,
          driverLicenseBack: driverLicenseBack || undefined,
        },
      }
    : { photos: undefined }),
});
