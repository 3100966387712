import { defineMessages } from 'react-intl';

export default defineMessages({
  submitButton: {
    defaultMessage: 'Save changes',
  },
  youHaveSuccessfullyUpdatedThePromotion: {
    defaultMessage: 'You have successfully updated the promotion!',
  },
  wrongFieldsError: {
    defaultMessage:
      'This promotion cannot be updated, please check that every field is correct',
  },
  codeDuplicated: {
    defaultMessage:
      'The code is duplicated and is already created in another active coupon promotion',
  },
  promotionRules: {
    defaultMessage: 'Promotion rules',
  },
  couponsUsed: {
    defaultMessage: 'Coupons used ({amount})',
  },
});
