import {
  useVehicleProblemReportsQuery,
  VehicleProblemReportListItem,
} from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { List } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { Counter, EmptyListMessageCard, ListErrorMessageCard } from '~/common';
import { useVehicleQuery } from '~/hooks';

import {
  OpenVehicleProblemReportCard,
  OpenVehicleProblemReportCardLoading,
} from '../OpenVehicleProblemReportCard';

import messages from './messages';

export type OpenProblemReportsProps = {
  vehicleId: string;
};

export const OpenVehicleProblemReports = ({
  vehicleId,
}: OpenProblemReportsProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  const {
    data: problemReports = [],
    isError,
    isLoading,
  } = useVehicleProblemReportsQuery(vehicleId, {
    open: 'true',
  });

  const { data: vehicle } = useVehicleQuery(vehicleId);

  if (!hasPermission('read:problem_reports')) {
    return <></>;
  }

  if (isError) {
    return (
      <section>
        <div className="h-10 flex items-center mb-6">
          <h2 className="text-base text-neutral-500 font-semibold">
            <FormattedMessage {...messages.heading} />
          </h2>
        </div>
        <ListErrorMessageCard>
          <FormattedMessage {...messages.error} />
        </ListErrorMessageCard>
      </section>
    );
  }

  return (
    <section data-testid="OPEN_PROBLEM_REPORTS">
      <div className="h-10 flex items-center mb-6">
        <h2 className="text-base text-neutral-500 font-semibold mr-3">
          <FormattedMessage {...messages.heading} />
        </h2>
        {!!problemReports?.length && <Counter>{problemReports.length}</Counter>}
      </div>
      <List
        loadingRows={2}
        isLoading={isLoading || !vehicle}
        idProp="problemReportId"
        data={problemReports}
        renderEmptyMessage={
          <EmptyListMessageCard>
            <FormattedMessage {...messages.noProblemReports} />
          </EmptyListMessageCard>
        }
        renderLoadingRow={<OpenVehicleProblemReportCardLoading />}
        renderRow={(problemReport: VehicleProblemReportListItem) =>
          vehicle && (
            <OpenVehicleProblemReportCard problemReport={problemReport} />
          )
        }
      />
    </section>
  );
};
