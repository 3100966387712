import { FormattedMessage } from 'react-intl';
import { languages } from '@cooltra/countries';
import { AppUserLanguage } from '@cooltra/api';

import { isSupportedLanguage } from '~/utils/users';

import { StaticField } from '../../../StaticField/StaticField';

import messages from './messages';

export type LanguageStaticProps = {
  value: AppUserLanguage | string;
};

export const LanguageStatic = ({ value }: LanguageStaticProps) => (
  <StaticField
    renderLabel={<FormattedMessage {...messages.language} />}
    renderValue={
      isSupportedLanguage(value) ? languages[value].native : languages.en.native
    }
  />
);
