import { classNames } from '@cooltra/utils';
import { Marker } from 'react-map-gl';
import { Card } from '@cooltra/ui';
import { MdLocationPin } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { CircleProps, Circle } from '~/common';

import messages from './messages';

const MarkerCircle = ({ className, ...rest }: CircleProps) => (
  <Circle
    className={classNames(
      'w-5 h-5 flex bg-neutral-700 text-neutral-50 box-content',
      className
    )}
    {...rest}
  />
);

export type MarkerProps = {
  latitude: number;
  longitude: number;
};

export const VehicleMarkerWithLink = ({ latitude, longitude }: MarkerProps) => (
  <Marker latitude={latitude} longitude={longitude}>
    <div className="flex flex-col items-center relative bottom-4">
      <Card className="bg-neutral-0 px-3 py-2 gap-1 relative bottom-1 rounded-md flex flex-row">
        <MdLocationPin className="text-primary-700" size={20} />
        <a
          className="text-primary-400"
          href={`https://maps.google.com/?q=${latitude},${longitude}`}
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage {...messages.linkToGoogleMaps} />
        </a>
      </Card>
      <MarkerCircle className="w-6 h-6 border-solid border-4 border-neutral-50 outline outline-1 outline-neutral-700" />
    </div>
  </Marker>
);
