import { FormattedMessage } from 'react-intl';
import { Reservation } from '@cooltra/api';
import { Card } from '@cooltra/ui';
import { RouterLink } from '@cooltra/navigation';
import { shortenId } from '@cooltra/utils';

import {
  MultilineDateTime,
  FailedCheckIn,
  ReservationStateBadge,
  ReservationProblemReportsBadge,
  UserListItemLink,
  ReservationSystem,
} from '~/common';
import { cities } from '~/common/System/messages';

import { VehicleReservationRowLayout } from '../VehicleReservationRowLayout';

import messages from './messages';

export const VehicleReservationCard = ({
  rentalId,
  createdAt,
  expiresAt,
  lastCheckinFailure,
  state,
  system,
  problemReportIds,
  user: { userId, name, provider },
}: Reservation) => (
  <Card className="relative py-4 flex items-center">
    {state === 'ACTIVE' && (
      <div className="absolute bg-success-50 opacity-40 inset-0" />
    )}
    <VehicleReservationRowLayout
      createdAt={<MultilineDateTime date={createdAt} />}
      spacer={<div className="w-full h-px bg-neutral-200" />}
      expiresAt={<MultilineDateTime date={expiresAt} />}
      user={
        <UserListItemLink userId={userId} name={name} provider={provider} />
      }
      system={
        <ReservationSystem>
          <FormattedMessage {...cities[system]} />
        </ReservationSystem>
      }
      checkIn={
        <div className="text-center">
          {rentalId ? (
            <div className="text-sm">
              <span>
                <FormattedMessage {...messages.rental} />
              </span>
              <RouterLink to={`/rentals/${rentalId}`} className="font-medium">
                #{shortenId(rentalId)}
              </RouterLink>
            </div>
          ) : (
            lastCheckinFailure && <FailedCheckIn error={lastCheckinFailure} />
          )}
        </div>
      }
      problemReports={
        <ReservationProblemReportsBadge
          problemReportIds={problemReportIds}
          userId={userId}
        />
      }
      state={<ReservationStateBadge state={state} />}
    />
  </Card>
);
