import { FormattedMessage } from 'react-intl';
import { TimelineItem, TimelineItemProps } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { WorkShiftWorkUnit } from '@cooltra/api/src/modules/work-shifts/types';

import messages from './messages';

type BatteryAddedProps = Pick<WorkShiftWorkUnit, 'vehicle'> &
  Omit<TimelineItemProps, 'header'>;

export const BatteryAdded = ({
  vehicle,
  ...timelineItemProps
}: BatteryAddedProps) => {
  if (!vehicle.battery.differenceAdded) {
    return <></>;
  }

  return (
    <TimelineItem
      {...timelineItemProps}
      header={
        <li
          className={classNames(
            'flex items-center gap-1 text-xs relative -top-1',
            timelineItemProps.isLast ? '' : 'pb-3'
          )}
        >
          <span className="font-semibold">
            <FormattedMessage {...messages.energyAdded} />
          </span>
          <span className="text-neutral-600">
            {vehicle.battery.differenceAdded}%
          </span>
        </li>
      }
    />
  );
};
