import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type ExtrasRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  name: ReactNode;
  price: ReactNode;
};

export const ExtrasRowLayout = ({
  name,
  price,
  className,
  ...rest
}: ExtrasRowLayoutProps) => (
  <div className={classNames('flex', 'h-full', 'w-full', className)} {...rest}>
    <div className="flex items-center w-full px-8 gap-4">
      <div className="flex-1 shrink-0">{name}</div>
      <div className="flex-2 shrink-0">{price}</div>
    </div>
  </div>
);
