import { defineMessages } from 'react-intl';

export default defineMessages({
  externalIdAscending: {
    defaultMessage: 'External ID: A - Z',
  },
  externalIdDescending: {
    defaultMessage: 'External ID: Z - A',
  },
  inLocationFromAscending: {
    defaultMessage: 'In location since: Oldest to newest',
  },
  inLocationFromDescending: {
    defaultMessage: 'In location since: Newest to oldest',
  },
  remainingMileageToService: {
    defaultMessage: 'Remaining mileage to next service',
  },
  remainingDaysToTechnicalInspection: {
    defaultMessage: 'Remaining days to next technical inspection',
  },
  idleFromAsc: {
    defaultMessage: 'Idle for: Newest to oldest',
  },
  idleFromDesc: {
    defaultMessage: 'Idle for: Oldest to newest',
  },
});
