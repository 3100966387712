import { FormattedMessage, useIntl } from 'react-intl';
import { Vehicle, useReleaseVehicleFromLocationMutation } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Button } from '@cooltra/ui';
import { AxiosError } from 'axios';

import { useNotification } from '~/hooks';
import { useVehiclePolling } from '~/libs/polling-data';

import { getErrorMessageForTransferringVehicle } from './errors';
import messages from './messages';

export type ReleaseFromLocationProps = Pick<Vehicle, 'vehicleId'>;

export const ReleaseFromLocation = ({
  vehicleId,
}: ReleaseFromLocationProps) => {
  const { activatePollingVehicle } = useVehiclePolling();
  const { hasPermission } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleOnError = (error: AxiosError) => {
    const errorMessageFromStatusCode = getErrorMessageForTransferringVehicle(
      error.response
    );

    addErrorNotification(
      errorMessageFromStatusCode.avoidTranslation
        ? errorMessageFromStatusCode.defaultMessage
        : formatMessage(errorMessageFromStatusCode)
    );
  };

  const handleOnSuccess = () => {
    addSuccessNotification(
      formatMessage(messages.successOnReleasingTheVehicleFromLocation)
    );
    activatePollingVehicle(vehicleId);
  };

  const { isPending, mutate } = useReleaseVehicleFromLocationMutation(
    vehicleId,
    {
      onError: handleOnError,
      onSuccess: handleOnSuccess,
    }
  );

  const releaseVehicleFromLocation = () => mutate();

  return (
    <div data-testid="RELEASE_VEHICLE_FROM_LOCATION">
      {hasPermission('transfer:vehicles') && (
        <div className="flex gap-4">
          <Button
            size="sm"
            loading={isPending}
            disabled={isPending}
            onClick={releaseVehicleFromLocation}
          >
            <FormattedMessage {...messages.releaseFromLocation} />
          </Button>
        </div>
      )}
    </div>
  );
};
