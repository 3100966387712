import { defineMessages } from 'react-intl';

export default defineMessages({
  moveHomeSystem: {
    defaultMessage: 'Move to home system',
  },
  success: {
    defaultMessage: `Vehicles were moved to "{homeSystem}" successfully`,
  },
});
