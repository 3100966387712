import { FormattedMessage } from 'react-intl';
import { List } from '@cooltra/ui';
import { useUserSessionsQuery, User, UserSession } from '@cooltra/api';

import { ErrorPage } from '~/common';

import { RevokeSessions } from '../RevokeSessions/RevokeSessions';
import { SessionsListHeader } from '../SessionsListHeader/SessionsListHeader';
import { SessionCard, SessionCardLoading } from '../SessionCard';
import { NoSessions } from '../NoSessions/NoSessions';

import messages from './messages';

export type SessionsProps = {
  user: User;
};

export const Sessions = ({ user: { id } }: SessionsProps) => {
  const { data: sessions = [], isLoading, isError } = useUserSessionsQuery(id);

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div className="container min-w-screen-sm max-w-3xl py-12">
      <div className="w-full mb-10 flex items-center h-11 justify-between">
        <h1 className="text-base text-neutral-600">
          <FormattedMessage {...messages.userSessions} />
        </h1>
        <div className="flex gap-6">
          <RevokeSessions id={id} />
        </div>
      </div>
      <List
        isLoading={isLoading}
        loadingRows={4}
        idProp="date"
        data={sessions}
        renderEmptyMessage={<NoSessions />}
        renderHeader={<SessionsListHeader />}
        renderLoadingRow={<SessionCardLoading />}
        renderRow={(session: UserSession) => <SessionCard session={session} />}
      />
    </div>
  );
};
