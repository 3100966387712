import { FormattedMessage } from 'react-intl';
import { FormInputField, useFormContext } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';

import { FormNumberInputField } from '../../../../FormattedInput';
import { DownloadCodes } from '../DownloadCodes/DownloadCodes';
import { OptionalFieldLabel } from '../../../../OptionalFieldLabel/OptionalFieldLabel';
import { PromotionFormValues } from '../../PromotionForm';

import messages from './messages';

export const MultiCode = () => {
  const { values } = useFormContext<PromotionFormValues>();
  const { editMode, promotionCodeMode } = values;

  if (promotionCodeMode !== 'multi-code') {
    return <></>;
  }

  return (
    <div className="flex gap-4 items-center">
      <FormInputField
        name="codePrefix"
        id="codePrefix"
        required={false}
        maxLength={2}
        disabled={editMode}
        label={
          <OptionalFieldLabel htmlFor="codePrefix">
            <FormattedMessage {...messages.codePrefix} />
          </OptionalFieldLabel>
        }
      />
      <FormNumberInputField
        name="numberOfCodes"
        id="numberOfCodes"
        disabled={editMode}
        label={
          <InputLabel htmlFor="numberOfCodes">
            <FormattedMessage {...messages.numberOfCodes} />
          </InputLabel>
        }
      />
      <div className="mt-5">
        <DownloadCodes />
      </div>
    </div>
  );
};
