import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { StationBasedPromotionRowLayout } from '../StationBasedPromotionRowLayout';

import messages from './messages';

export const StationBasedPromotionsListHeader = () => (
  <StationBasedPromotionRowLayout
    data-testid="STATION_BASED_PROMOTIONS_LIST_HEADER"
    promotion={
      <ListColumnLabel>
        <FormattedMessage {...messages.promotion} />
      </ListColumnLabel>
    }
    type={
      <ListColumnLabel>
        <FormattedMessage {...messages.type} />
      </ListColumnLabel>
    }
    validity={
      <ListColumnLabel>
        <FormattedMessage {...messages.validity} />
      </ListColumnLabel>
    }
    state={
      <ListColumnLabel>
        <FormattedMessage {...messages.state} />
      </ListColumnLabel>
    }
  />
);
