import { HTMLAttributes, ReactNode } from 'react';
import { classNames } from '@cooltra/utils';

export type UserRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  system: ReactNode;
  name: ReactNode;
  provider: ReactNode;
  status: ReactNode;
};

export const UserRowLayout = ({
  system,
  name,
  provider,
  status,
  className,
  ...rest
}: UserRowLayoutProps) => (
  <div className={classNames('flex', 'h-full', 'w-full', className)} {...rest}>
    <div className="flex items-center w-full px-8 gap-4">
      <div className="w-52 shrink-0">{system}</div>
      <div className="flex-1 shrink-0">{name}</div>
      <div className="w-48 shrink-0">{provider}</div>
      <div className="flex-1 shrink-0 text-center">{status}</div>
    </div>
  </div>
);
