import { FormattedMessage } from 'react-intl';
import { Money, classNames } from '@cooltra/utils';
import { AlgorithmPrice } from '@cooltra/station-based-api';
import { Divider, Tooltip } from '@cooltra/ui';
import { MdShowChart } from 'react-icons/md';

import { algorithmNamesMessages } from '../messages';

import messages from './messages';

type PriceColumnProps = {
  price: Money;
  algorithmPrice: AlgorithmPrice;
  priceList: Money[] | null;
};

const PRICE_LIST_COLUMNS = 3;
const MAX_NUMBER_OF_PRICE_LIST = 30;
const FIRST_NUMBER_OF_LAST_PRICE_LIST_COLUMN =
  MAX_NUMBER_OF_PRICE_LIST - MAX_NUMBER_OF_PRICE_LIST / PRICE_LIST_COLUMNS;

export const PriceColumn = ({
  price,
  algorithmPrice,
  priceList,
}: PriceColumnProps) => {
  const priceFormatter = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: price.currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const formattedPrice = priceFormatter.format(price.value);

  const getAlgorithmPrice = () => {
    switch (algorithmPrice) {
      case 'BASE':
        return (
          <div className="flex items-center gap-1">
            <div className="w-3 h-0.5 bg-neutral-400" />
            <span className="text-xs text-neutral-400">
              <FormattedMessage {...algorithmNamesMessages['BASE']} />
            </span>
          </div>
        );
      case 'AGGRESSIVE':
        return (
          <div className="flex items-center gap-1 text-neutral-400">
            <MdShowChart className="w-4 text-neutral-400" />
            <span className="text-xs">
              <FormattedMessage {...algorithmNamesMessages['AGGRESSIVE']} />
            </span>
          </div>
        );
      case 'MODERATE':
        return (
          <div className="flex items-center gap-1 text-neutral-400">
            <div className="text-xl mb-0.5">~</div>
            <span className="text-xs">
              <FormattedMessage {...algorithmNamesMessages['MODERATE']} />
            </span>
          </div>
        );
      case 'LINEAR':
        return (
          <div className="flex items-center gap-1 text-neutral-400">
            <div className="w-3 h-0.5 bg-neutral-400 -rotate-45" />
            <span className="text-xs">
              <FormattedMessage {...algorithmNamesMessages['LINEAR']} />
            </span>
          </div>
        );
      case 'FIXED':
        return (
          <div className="flex items-center gap-1 text-neutral-400">
            <div className="w-3 h-0.5 bg-neutral-400" />
            <span className="text-xs">
              <FormattedMessage {...algorithmNamesMessages['FIXED']} />
            </span>
          </div>
        );
      case 'EXTRA':
        return (
          <div className="flex items-center gap-1 text-neutral-400">
            <MdShowChart className="w-4 text-neutral-400 -rotate-45" />
            <span className="text-xs">
              <FormattedMessage {...algorithmNamesMessages['EXTRA']} />
            </span>
          </div>
        );
      default:
        return <span />;
    }
  };

  const getTooltipContent = () => {
    if (!priceList) {
      return (
        <div className="p-4 text-neutral-400">
          <FormattedMessage {...messages.thereIsNoAnyPriceUploaded} />
        </div>
      );
    }

    const getPositionNumber = (currentIndex: number) => {
      return String(currentIndex + 1).padStart(2, '0');
    };

    return (
      <div className="w-full grid grid-rows-10 grid-flow-col text-center p-10">
        {priceList
          .filter((_, index) => index < MAX_NUMBER_OF_PRICE_LIST)
          .map((price, index) => (
            <div
              key={index}
              className={classNames(
                'col-span-1 pb-1.5',
                index < FIRST_NUMBER_OF_LAST_PRICE_LIST_COLUMN
                  ? 'border-r border-r-neutral-100 pr-6 mr-6'
                  : ''
              )}
            >
              <div className="flex w-auto items-center gap-1 mx-auto">
                <div className="w-4 shrink-0 text-neutral-400">
                  {getPositionNumber(index)}
                </div>
                <div className="w-2 shrink-0">
                  <Divider className="text-neutral-200" />
                </div>
                <div className="flex-1 font-semibold">
                  {priceFormatter.format(price.value)}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };

  return (
    <Tooltip
      trigger="click"
      tooltipClassName="max-w-md"
      button={
        <button className="flex items-center gap-3">
          <span className="text-base text-neutral-800 font-semibold">
            {formattedPrice}
          </span>
          <div>{getAlgorithmPrice()}</div>
        </button>
      }
      content={getTooltipContent()}
    />
  );
};
