import { FormattedMessage } from 'react-intl';
import { List } from '@cooltra/ui';
import {
  GeofenceRestriction,
  useGeofenceRestrictionsQuery,
  VehicleType,
} from '@cooltra/api';
import { System } from '@cooltra/auth-api';
import { classNames } from '@cooltra/utils';
import { PropsWithChildren } from 'react';

import { NewRestrictionLink } from '../NewRestrictionLink/NewRestrictionLink';
import { RestrictionCard } from '../RestrictionCard/RestrictionCard';

import messages from './messages';

const SublistTotal = (props: PropsWithChildren) => (
  <span className="block text-base text-neutral-400 mt-1 mb-6" {...props} />
);

export type RestrictionProps = {
  system: System;
  vehicleType: VehicleType;
};

export const Restrictions = ({ system, vehicleType }: RestrictionProps) => {
  const {
    isLoading,
    data = [],
    isError,
  } = useGeofenceRestrictionsQuery(system, {
    vehicleType,
  });

  const activeRestrictions = data.filter(({ enabled }) => enabled);
  const inactiveRestrictions = data.filter(({ enabled }) => !enabled);

  return (
    <div className="pb-4 pt-6">
      <div className="relative">
        <NewRestrictionLink className="absolute top-0 right-0" />
      </div>
      <div data-testid="RESTRICTIONS">
        {isError && (
          <span className="text-neutral-600 text-sm block text-center pt-20">
            <FormattedMessage {...messages.error} />
          </span>
        )}
        {!isError && (
          <>
            {isLoading ? (
              <SublistTotal>
                <FormattedMessage {...messages.loading} />
              </SublistTotal>
            ) : activeRestrictions.length ? (
              <SublistTotal>
                <FormattedMessage
                  {...messages.activeRestrictions}
                  values={{
                    total: activeRestrictions.length,
                  }}
                />
              </SublistTotal>
            ) : null}
            <List
              isLoading={isLoading}
              idProp="geofenceRestrictionId"
              data={activeRestrictions}
              className={classNames(
                'gap-5',
                !!activeRestrictions.length && 'mb-10'
              )}
              loadingRows={4}
              renderRow={(restriction: GeofenceRestriction) => (
                <RestrictionCard {...restriction} system={system} />
              )}
            />
            {!!inactiveRestrictions.length && (
              <>
                <SublistTotal>
                  <FormattedMessage
                    {...messages.inactiveRestrictions}
                    values={{
                      total: inactiveRestrictions.length,
                    }}
                  />
                </SublistTotal>
                <List
                  isLoading={isLoading}
                  idProp="geofenceRestrictionId"
                  data={inactiveRestrictions}
                  loadingRows={0}
                  renderRow={(restriction: GeofenceRestriction) => (
                    <RestrictionCard
                      deleteEnabled={true}
                      {...restriction}
                      system={system}
                    />
                  )}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
