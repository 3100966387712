import { TaskColumnLabel } from '~/common';

import { TaskRowLayout } from './TaskRowLayout';

export const TasksListHeader = () => (
  <TaskRowLayout
    task={<TaskColumnLabel name="task" />}
    assignee={<TaskColumnLabel name="assignee" />}
    updated={<TaskColumnLabel name="updated" />}
    context={<TaskColumnLabel name="context" />}
  />
);
