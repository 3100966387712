import { BadgePlaceholder, Card, Placeholder } from '@cooltra/ui';

import { OpenVehicleTaskCardLayout } from './OpenVehicleTaskCardLayout';

export const OpenVehicleTaskCardLoading = () => (
  <Card className="min-h-28 flex">
    <OpenVehicleTaskCardLayout
      content={<Placeholder className="w-52 h-2 mt-2" />}
      updated={
        <div className="mt-3">
          <Placeholder className="h-2 w-20 mb-3" />
          <Placeholder className="h-2 w-28" />
        </div>
      }
      assignee={
        <div className="mt-3">
          <Placeholder className="h-2 w-16 mb-3" />
          <Placeholder className="h-2 w-24" />
        </div>
      }
      blocking={<BadgePlaceholder className="w-20" />}
    />
  </Card>
);
