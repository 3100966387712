import { FormattedMessage } from 'react-intl';

import { ListTotal } from '~/common';

import messages from './messages';

export type VehiclesTotalProps = {
  loading: boolean;
  selected: number;
  total: number;
};

export const VehiclesTotal = ({
  selected,
  total,
  loading,
}: VehiclesTotalProps) => (
  <ListTotal loading={loading}>
    {selected ? (
      <FormattedMessage
        {...messages.selectedResults}
        values={{
          selected,
          total,
        }}
      />
    ) : (
      <FormattedMessage
        {...messages.result}
        values={{
          total,
        }}
      />
    )}
  </ListTotal>
);
