import { Incident } from '@cooltra/api';
import { classNames } from '@cooltra/utils';
import { RouterCardLink } from '@cooltra/navigation';

import { DateTime } from '../../DateTime';
import { Context } from '../../Context/Context';
import { ByOperator, OperatorOrGroup } from '../../Operator';
import { IncidentCardInfo } from '../IncidentCardInfo/IncidentCardInfo';
import { IncidentRowLayout } from '../IncidentRowLayout';

export type IncidentCardProps = {
  incident: Incident;
};

export const IncidentCard = ({
  incident: {
    incidentId,
    title,
    description,
    open,
    operatorId,
    operatorGroupId,
    rentalId,
    updatedAt,
    updatedBy,
  },
}: IncidentCardProps) => (
  <RouterCardLink
    isMultiAction
    to={`/incidents/${incidentId}`}
    className={classNames(open ? 'opacity-100' : 'opacity-50')}
  >
    <IncidentRowLayout
      className="min-h-28 py-4"
      incident={
        <IncidentCardInfo open={open} title={title} description={description} />
      }
      assignee={
        <div className="text-sm text-neutral-400">
          <OperatorOrGroup
            operatorId={operatorId}
            operatorGroupId={operatorGroupId}
          />
        </div>
      }
      lastUpdated={
        <div>
          <DateTime date={updatedAt} />
          <ByOperator id={updatedBy} />
        </div>
      }
      context={
        <div className="text-center">
          <Context rentalId={rentalId} />
        </div>
      }
    />
  </RouterCardLink>
);
