import { Rental, useFetchDocument } from '@cooltra/api';
import { Image, Link } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';
import { classNames } from '@cooltra/utils';
import { FormattedMessage, useIntl } from 'react-intl';

import { useRotation } from '~/hooks';

import { CustomIcon } from '../../CustomIcon';
import { MultilineDateTime } from '../../DateTime';
import { PhotoModal } from '../../Photo/PhotoModal/PhotoModal';

import messages from './messages';

type DropOffProps = Pick<Rental, 'dropOff'>;

export const DropOff = ({ dropOff }: DropOffProps) => {
  const { formatMessage } = useIntl();
  const parkingPhotoImageData = useFetchDocument(dropOff?.parkingPhoto);
  const [parkingPhotoModalOpen, { toggleOn, toggleOff }] = useToggle();
  const { rotationClassName, rotateLeft, rotateRight } = useRotation();

  if (!dropOff) {
    return (
      <span className="text-sm text-neutral-500">
        <FormattedMessage {...messages.pending} />
      </span>
    );
  }

  const { latitude, longitude, createdAt } = dropOff;

  const createdAtDate = new Date(createdAt);

  const parkingPhotoBase64 = parkingPhotoImageData
    ? `data:image/jpeg;base64,${parkingPhotoImageData}`
    : '';

  return (
    <div className="flex items-center -ml-2">
      {dropOff.parkingPhoto && (
        <div className="flex items-center justify-center absolute top-0 left-10">
          <Image
            data-testid={
              parkingPhotoBase64 ? 'PARKING_PHOTO_LOADED' : 'PARKING_PHOTO'
            }
            src={parkingPhotoBase64}
            className={classNames(
              'w-16 object-cover aspect-square cursor-pointer transition-opacity hover:opacity-60 rounded-md',
              rotationClassName
            )}
            onClick={toggleOn}
          />
        </div>
      )}
      <Link
        href={`https://www.google.com/maps/place/${latitude},${longitude}`}
        target="_blank"
        className="pr-1 text-3xl"
        aria-label={formatMessage(messages.dropOffLocation)}
      >
        <CustomIcon.Pin />
      </Link>
      <MultilineDateTime date={createdAtDate} />
      <PhotoModal
        isOpen={parkingPhotoModalOpen}
        onRequestClose={toggleOff}
        rotateLeft={rotateLeft}
        rotateRight={rotateRight}
        className={rotationClassName}
        imageData={parkingPhotoBase64}
      />
    </div>
  );
};
