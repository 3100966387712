import { UserDiscountListItem } from '@cooltra/api';
import { RouterCardLink } from '@cooltra/navigation';
import { classNames } from '@cooltra/utils';

import { MultilineDateTime, UserDiscountState } from '~/common';

import { DiscountRowLayout } from '../DiscountRowLayout';

export type DiscountCardProps = {
  discount: UserDiscountListItem;
};

export const DiscountCard = ({
  discount: {
    id,
    title,
    description,
    code,
    discountPercentage,
    redeemedAt,
    benefitFrom,
    benefitUntil,
    state,
  },
}: DiscountCardProps) => (
  <RouterCardLink
    to={`/offers/promotions/${id}/edit`}
    className={classNames(
      'text-sm text-neutral-800',
      (state === 'EXPIRED' || state === 'USED') && 'opacity-50'
    )}
  >
    <DiscountRowLayout
      className="h-16"
      offer={
        <span className="flex flex-col">
          <span className="font-medium">{title}</span>
          <span className="text-xs truncate">{description}</span>
        </span>
      }
      code={<span>{code}</span>}
      discountPercentage={
        <span>
          <span className="font-medium">{discountPercentage}%</span>
        </span>
      }
      redeemedAt={<MultilineDateTime emphasize="date" date={redeemedAt} />}
      validFrom={<MultilineDateTime emphasize="date" date={benefitFrom} />}
      validUntil={
        benefitUntil && (
          <MultilineDateTime emphasize="date" date={benefitUntil} />
        )
      }
      state={<UserDiscountState state={state} />}
    />
  </RouterCardLink>
);
