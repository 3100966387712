import { useIntl } from 'react-intl';
import { FormSelectField } from '@cooltra/form';

import { useSystemOptions } from '~/common';

import messages from './messages';

export type SystemFieldProps = {
  className?: string;
};

export const SystemField = ({ className }: SystemFieldProps) => {
  const { formatMessage } = useIntl();
  const systemOptions = useSystemOptions();

  if (systemOptions.length <= 1) {
    return null;
  }

  return (
    <FormSelectField
      name="system"
      id="system"
      aria-label={formatMessage(messages.system)}
      options={systemOptions}
      className={className}
    />
  );
};
