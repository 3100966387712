import { useIntl } from 'react-intl';
import { FormRadioGroupField, useFormContext } from '@cooltra/form';

import { CouponType, StationBasedFormValues } from '../../StationBasedForm';

import messages from './messages';

export const CouponTypeSelector = () => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext<StationBasedFormValues>();
  const { editMode } = values;
  const couponTypes: CouponType[] = ['CREDIT', 'DISCOUNT'];

  return (
    <FormRadioGroupField
      name="couponType"
      innerClassName="flex flex-row gap-6"
      legend={<></>}
      disabled={editMode}
      items={couponTypes.map((couponType) => ({
        value: couponType,
        label: formatMessage(messages[couponType]),
      }))}
    />
  );
};
