import { MdChevronLeft } from 'react-icons/md';
import { Link, LinkProps } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const BackLink = ({ children, className, ...rest }: LinkProps) => (
  <Link
    className={classNames('inline-flex', 'items-center', 'mb-4', className)}
    {...rest}
  >
    <MdChevronLeft />
    {children || <FormattedMessage {...messages.back} />}
  </Link>
);
