import {
  ProblemReportListItem,
  Rental,
  useProblemReportsQuery,
} from '@cooltra/api';
import { List } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { ErrorPage } from '~/common';

import { NoRentalProblemReports } from '../NoRentalProblemReports/NoRentalProblemReports';
import { RentalProblemReportsListHeader } from '../RentalProblemReportsListHeader';
import {
  RentalProblemReportCard,
  RentalProblemReportCardLoading,
} from '../RentalProblemReportCard';

import messages from './messages';

type RentalProblemReportsProps = {
  rental: Rental;
};

export const RentalProblemReports = ({ rental }: RentalProblemReportsProps) => {
  const rentalProblemReportsQuery = useProblemReportsQuery({
    rentalId: rental.rentalId,
    page: 1,
    limit: 1000,
  });

  const reservationProblemReportsQuery = useProblemReportsQuery({
    reservationId: rental.reservation.reservationId,
    page: 1,
    limit: 1000,
  });

  const isLoading =
    rentalProblemReportsQuery.isLoading ||
    reservationProblemReportsQuery.isLoading;

  const isError =
    rentalProblemReportsQuery.isError || reservationProblemReportsQuery.isError;

  const rentalProblemReports = rentalProblemReportsQuery?.data?.pages
    ? ([] as ProblemReportListItem[]).concat.apply(
        [],
        rentalProblemReportsQuery.data.pages
      )
    : [];

  const reservationProblemReports = reservationProblemReportsQuery?.data?.pages
    ? ([] as ProblemReportListItem[]).concat.apply(
        [],
        reservationProblemReportsQuery.data.pages
      )
    : [];

  const problemReports = [
    ...rentalProblemReports,
    ...reservationProblemReports,
  ];

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div
      className="container min-w-3xl max-w-5xl py-12"
      data-testid="RENTAL_PROBLEM_REPORTS"
    >
      <h2 className="text-base text-neutral-500 font-semibold mb-6">
        <FormattedMessage {...messages.heading} />
      </h2>
      <List
        loadingRows={2}
        isLoading={isLoading}
        idProp="problemReportId"
        className="gap-5 pb-2"
        data={problemReports}
        renderEmptyMessage={<NoRentalProblemReports />}
        renderHeader={<RentalProblemReportsListHeader />}
        renderLoadingRow={<RentalProblemReportCardLoading />}
        renderRow={(problemReport: ProblemReportListItem) => (
          <RentalProblemReportCard problemReport={problemReport} />
        )}
      />
    </div>
  );
};
