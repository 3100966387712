import { defineMessages } from 'react-intl';

export default defineMessages({
  accountSuspended: {
    defaultMessage: 'This account is suspended',
  },
  seeMore: {
    defaultMessage: 'See more',
  },
  suspendedSince: {
    defaultMessage: 'Since {date}, {time}',
  },
  linkToInvoiceList: {
    defaultMessage: 'See invoice list',
  },
});
