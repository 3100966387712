import { ReactNode } from 'react';

export type EmptyListMessageProps = {
  title: ReactNode;
  content: ReactNode;
};

export const EmptyListMessage = ({ title, content }: EmptyListMessageProps) => (
  <div className="text-center py-32 text-neutral-700">
    <h2 className="text-2xl mb-2">{title}</h2>
    <p className="text-base">{content}</p>
  </div>
);
