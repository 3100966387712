import { ContractStatus } from '@cooltra/station-based-api';
import { Badge } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import {
  getContractStatusMessage,
  getContractStatusVariant,
} from '~/utils/contract';

export type ContractBadgeProps = {
  status: ContractStatus;
};

export const ContractBadge = ({ status }: ContractBadgeProps) => (
  <Badge className="justify-center" variant={getContractStatusVariant(status)}>
    <FormattedMessage {...getContractStatusMessage(status)} />
  </Badge>
);
