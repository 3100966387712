import { defineMessages } from 'react-intl';

export default defineMessages({
  scheduledMileage: {
    defaultMessage: 'Scheduled mileage (in km)',
  },
  warningMileage: {
    defaultMessage:
      'Warning: You will set a mileage less the vehicle already has',
  },
});
