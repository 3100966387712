import { FormattedMessage } from 'react-intl';

import { ListTotal, ListTotalProps } from '~/common';

import messages from './messages';

export type ShiftsTotalProps = Omit<ListTotalProps, 'children'> & {
  total: number;
};

export const ShiftsTotal = ({ total, loading, ...rest }: ShiftsTotalProps) => (
  <ListTotal loading={loading} {...rest}>
    <FormattedMessage
      {...messages.shifts}
      values={{
        total,
      }}
    />
  </ListTotal>
);
