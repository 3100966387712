import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { Rental } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { RouterLink, RouterSidebarNavLink } from '@cooltra/navigation';
import { Divider } from '@cooltra/ui';
import { capitalize, shortenId } from '@cooltra/utils';
import { matchPath, useLocation } from 'react-router-dom';
import { MdLocationOn } from 'react-icons/md';

import {
  Counter,
  MaasLogo,
  ModelImage,
  RentalStateBadge,
  SessionDevice,
  SidebarHeading,
  SidebarIconItem,
  SidebarLinkContent,
  SystemCity,
  UserLink,
} from '~/common';

import messages from './messages';

export type SidebarProps = {
  rental: Rental;
};

export const Sidebar = ({
  rental: {
    rentalId,
    state,
    reservation,
    system,
    vehicle,
    userAgent,
    user,
    problemReportIds = [],
    incidentIds,
  },
}: SidebarProps) => {
  const { hasPermission } = useAuthClaimsQuery();
  const { pathname } = useLocation();

  const isProblemReportsMatch = !!matchPath(
    'rentals/:rentalId/problem-reports',
    pathname
  );

  const isIncidentsMatch = !!matchPath('rentals/:rentalId/incidents', pathname);

  const capitalizedFullName = user.name
    .split(' ')
    .map((name) => capitalize(name.toLowerCase()))
    .join(' ');

  return (
    <div data-testid="RENTAL_SIDEBAR">
      <div className="flex flex-wrap justify-start items-center gap-2 mt-8 mb-2 px-8">
        <RentalStateBadge state={state} />
      </div>
      <div className="px-8 mt-8">
        <SidebarHeading className="mb-3">#{shortenId(rentalId)}</SidebarHeading>
        <span className="text-xs text-neutral-400 flex gap-1 mb-1">
          <FormattedMessage {...messages.reservation} />
          <span className="font-semibold">
            #{shortenId(reservation.reservationId)}
          </span>
        </span>
        <span className="text-xs text-neutral-400 flex gap-1 mb-2">
          <FormattedMessage {...messages.createdAt} />
          <span className="font-semibold">
            <FormattedDate
              value={new Date(reservation.reservationCreatedAt)}
              day="2-digit"
              month="short"
              year="numeric"
            />
            {' - '}
            <FormattedTime
              value={new Date(reservation.reservationCreatedAt)}
              hour="2-digit"
              hourCycle="h23"
              minute="2-digit"
            />
          </span>
        </span>
        <div className="mb-7 pt-4 flex flex-col gap-1 items-start">
          <ModelImage
            className="h-8 -ml-3"
            model={vehicle.model}
            identificationNumber={vehicle.identificationNumber}
          />
          <RouterLink
            className="font-medium"
            to={`/vehicles/${vehicle.vehicleId}`}
          >
            {vehicle.externalId}
          </RouterLink>
          <span className="text-xs text-neutral-400">
            {vehicle.licensePlate}
          </span>
        </div>
        <div className="mb-9">
          <MaasLogo provider={user.provider} className="h-5 mb-2" />
          <UserLink
            className="text-sm font-medium break-words"
            provider={user.provider}
            userId={user.userId}
          >
            {capitalizedFullName}
          </UserLink>
          <SessionDevice
            className="text-xs whitespace-nowrap"
            classNameValue="truncate"
            session={{
              device: userAgent,
              expired: false,
              date: '',
            }}
          />
        </div>
        <div className="mb-12">
          <SidebarIconItem
            icon={<MdLocationOn />}
            label={<SystemCity system={system} />}
          />
        </div>
      </div>
      <Divider />
      <ul>
        <li>
          <RouterSidebarNavLink to={`/rentals/${rentalId}`} end>
            <SidebarLinkContent>
              <FormattedMessage {...messages.overview} />
            </SidebarLinkContent>
          </RouterSidebarNavLink>
          <Divider />
        </li>
        {user.provider === 'COOLTRA' && (
          <li>
            <RouterSidebarNavLink
              to={`/rentals/${rentalId}/problem-reports`}
              end
            >
              <SidebarLinkContent>
                <span className="flex-1">
                  <FormattedMessage {...messages.problemReports} />
                </span>
                {!!problemReportIds.length && (
                  <Counter
                    variant={isProblemReportsMatch ? 'primary' : 'neutral'}
                    className="mr-2"
                  >
                    {problemReportIds.length}
                  </Counter>
                )}
              </SidebarLinkContent>
            </RouterSidebarNavLink>
            <Divider />
          </li>
        )}
        {hasPermission('read:incidents') && (
          <li>
            <RouterSidebarNavLink to={`/rentals/${rentalId}/incidents`} end>
              <SidebarLinkContent>
                <span className="flex-1">
                  <FormattedMessage {...messages.incidents} />
                </span>
                {!!incidentIds.length && (
                  <Counter
                    variant={isIncidentsMatch ? 'primary' : 'neutral'}
                    className="mr-2"
                  >
                    {incidentIds.length}
                  </Counter>
                )}
              </SidebarLinkContent>
            </RouterSidebarNavLink>
            <Divider />
          </li>
        )}
        {state !== 'ACTIVE' && (
          <li>
            <RouterSidebarNavLink
              to={`/rentals/${rentalId}/traffic-offense-report`}
              end
            >
              <SidebarLinkContent>
                <FormattedMessage {...messages.trafficOffenseReport} />
              </SidebarLinkContent>
            </RouterSidebarNavLink>
            <Divider />
          </li>
        )}
      </ul>
    </div>
  );
};
