import { MdChevronLeft } from 'react-icons/md';
import { RouterLink, RouterLinkProps } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const RouterBackLink = ({ children, ...rest }: RouterLinkProps) => (
  <RouterLink className="inline-flex items-center mb-4" {...rest}>
    <MdChevronLeft />
    {children || <FormattedMessage {...messages.back} />}
  </RouterLink>
);
