import { FormSelectField, useFormContext } from '@cooltra/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckboxField, InputLabel, Legend } from '@cooltra/ui';
import { ChangeEvent } from 'react';

import {
  FormDateInputField,
  FormNumberInputField,
  FormTimeInputField,
} from '~/common';

import { PackExpirationMode, PackFormValues } from '../../PackForm';

import messages, { packExpirationModeMessages } from './messages';

export const ExpiresAt = () => {
  const { formatMessage } = useIntl();
  const { setValues, isSubmitting, isFormDisabled, values } =
    useFormContext<PackFormValues>();

  const handleChange = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setValues({
        benefitUntilDate: '',
        benefitUntilTime: '',
        benefitExpiresAfterDays: '',
        benefitDoesntExpire: checked,
        benefitExpirationMode: '',
      });
    } else {
      setValues({
        benefitDoesntExpire: checked,
        benefitExpirationMode: 'specific-date',
      });
    }
  };

  const availableOptions: PackExpirationMode[] = [
    'after-days',
    'specific-date',
  ];
  const options = availableOptions.map((option) => ({
    label: formatMessage(packExpirationModeMessages[option]),
    value: option,
  }));

  return (
    <div className="flex flex-col gap-y-2" data-testid="BENEFIT_EXPIRES_AT">
      <div className="flex gap-x-8 items-start">
        <FormSelectField
          className="w-44 mb-2"
          id="benefit-expiration-mode"
          name="benefitExpirationMode"
          options={options}
          disabled={values.benefitDoesntExpire}
          label={
            <InputLabel htmlFor="benefit-expiration-mode">
              <FormattedMessage {...messages.expirationMode} />
            </InputLabel>
          }
        />
        <CheckboxField
          className="mt-9"
          id="benefit-expires"
          checked={values.benefitDoesntExpire}
          label={
            <InputLabel emphasis="low" htmlFor="benefit-expires">
              <FormattedMessage {...messages.doesntExpire} />
            </InputLabel>
          }
          disabled={isFormDisabled || isSubmitting}
          onChange={handleChange}
        />
      </div>
      {values.benefitExpirationMode === 'specific-date' && (
        <fieldset className="flex gap-2" data-testid="BENEFIT_UNTIL">
          <Legend className="mb-1">
            <FormattedMessage {...messages.consumableUntil} />
          </Legend>
          <FormDateInputField
            className="w-32"
            name="benefitUntilDate"
            id="benefit-until-date"
            aria-label={formatMessage(messages.date)}
          />
          <FormTimeInputField
            className="w-20"
            name="benefitUntilTime"
            aria-label={formatMessage(messages.time)}
          />
        </fieldset>
      )}
      {values.benefitExpirationMode === 'after-days' && (
        <FormNumberInputField
          className="w-44"
          name="benefitExpiresAfterDays"
          id="benefit-expires-after-days"
          label={
            <InputLabel htmlFor="benefit-expires-after-days">
              <FormattedMessage {...messages.noOfDays} />
            </InputLabel>
          }
        />
      )}
    </div>
  );
};
