import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type PromotionRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  promotion: ReactNode;
  benefit: ReactNode;
  validFrom: ReactNode | null;
  validUntil: ReactNode | null;
  uses: ReactNode;
  state: ReactNode;
};

export const PromotionRowLayout = ({
  promotion,
  benefit,
  validFrom,
  validUntil,
  uses,
  state,
  className,
  ...rest
}: PromotionRowLayoutProps) => (
  <div className={classNames('flex h-full w-full', className)} {...rest}>
    <div className="flex items-center w-full px-8 gap-4">
      <div className="flex-1">{promotion}</div>
      <div className="w-32 flex justify-end shrink-0 pr-16">{benefit}</div>
      <div className="w-28 shrink-0">{validFrom}</div>
      <div className="w-28 shrink-0">{validUntil}</div>
      <div className="w-20 flex justify-end shrink-0 pr-8">{uses}</div>
      <div className="w-24 flex justify-center shrink-0">{state}</div>
    </div>
  </div>
);
