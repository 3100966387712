import { useIntl } from 'react-intl';

import { ErrorPage } from '~/common';

import messages from './messages';

export const CrashPage = () => {
  const { formatMessage } = useIntl();
  return (
    <ErrorPage
      title={formatMessage(messages.title)}
      error={formatMessage(messages.content)}
    />
  );
};
