import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { SessionRowLayout } from '../SessionRowLayout';

import messages from './messages';

export const SessionsListHeader = () => (
  <SessionRowLayout
    date={
      <ListColumnLabel>
        <FormattedMessage {...messages.date} />
      </ListColumnLabel>
    }
    deviceAndVersion={
      <ListColumnLabel>
        <FormattedMessage {...messages.deviceAndVersion} />
      </ListColumnLabel>
    }
  />
);
