import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Message } from '@cooltra/ui';
import { MdErrorOutline } from 'react-icons/md';

import messages from './messages';

export type ErrorPageProps = {
  title?: string;
  error?: ReactNode;
  icon?: ReactNode;
  footer?: ReactNode;
};

export const ErrorPage = ({
  error = '',
  title = '',
  icon,
  footer,
}: ErrorPageProps) => {
  const { formatMessage } = useIntl();
  return (
    <div className="container flex justify-center py-16 tc max-w-5xl">
      <Message
        icon={icon || <MdErrorOutline />}
        title={title || formatMessage(messages.title)}
        text={error || formatMessage(messages.content)}
        footer={footer}
      />
    </div>
  );
};
