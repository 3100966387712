import 'react-day-picker/dist/style.css';

import {
  addMonths,
  format,
  getMonth,
  isBefore,
  Locale,
  parseISO,
} from 'date-fns';
import { es, fr, it } from 'date-fns/locale';
import { useRef } from 'react';
import { DayPicker } from 'react-day-picker';
import { useIntl } from 'react-intl';
import { useFormContext } from '@cooltra/form';
import { AppUserLanguage } from '@cooltra/api';

import { ContractDetailsFormValues } from '../contract-details-form';

import { DesktopCalendarHead } from './CalendarHead';
import { CalendarNavigation } from './CalendarNavigation/CalendarNavigation';
import { DayContent } from './DayContent';
import { GoToStartDateMonth } from './GoToStartDateMonth';

const calendarLocales: Record<AppUserLanguage, Locale | undefined> = {
  es,
  fr,
  it,
  en: undefined,
  pt: undefined,
  ca: undefined,
};

export const Calendar = () => {
  const { locale } = useIntl();
  const {
    setValues,
    values: { pickUpDate, dropOffDate, pickup, contractStatus },
  } = useFormContext<ContractDetailsFormValues>();
  const monthRefs = useRef<Record<string, HTMLSpanElement>>({});

  if (!pickup) {
    return null;
  }

  const today = parseISO(new Date().toISOString());

  const shouldDayBeDisabled = (day: Date) =>
    Boolean(
      contractStatus === 'ACTIVE' && pickUpDate && isBefore(day, pickUpDate)
    );

  return (
    <div className="relative">
      <DayPicker
        numberOfMonths={2}
        pagedNavigation
        mode="range"
        toDate={addMonths(today, 12)}
        locale={calendarLocales[locale as AppUserLanguage]}
        classNames={{
          button: '!border-none group hover:cursor-pointer',
          root: 'm-0 w-full',
          table: 'w-full',
          month: 'w-full',
          row: 'grid grid-cols-7 mb-1',
          cell: 'block h-10 w-full',
          day: 'h-full w-full',
          multiple_months: 'p-0 m-0',
          months: 'flex gap-x-12',
        }}
        selected={{
          from: pickUpDate,
          to: dropOffDate,
        }}
        onDayClick={(day) => {
          if (contractStatus === 'ACTIVE') {
            setValues({
              pickUpDate: pickUpDate,
              dropOffDate: day,
            });
            return;
          }

          if (pickUpDate && dropOffDate) {
            setValues({
              pickUpDate: day,
              dropOffDate: undefined,
            });
          }
        }}
        onSelect={(range) => {
          if (!pickUpDate || !dropOffDate) {
            setValues({
              pickUpDate: range?.from,
              dropOffDate: range?.to,
            });
          }
        }}
        disabled={(day) => shouldDayBeDisabled(day)}
        weekStartsOn={1}
        components={{
          Head: DesktopCalendarHead,
          DayContent,
          Footer: GoToStartDateMonth,
          Caption: ({ displayMonth }) => (
            <CalendarNavigation
              displayMonth={displayMonth}
              ref={(ref) => {
                if (ref) {
                  monthRefs.current[getMonth(displayMonth)] = ref;
                }
              }}
            >
              {format(displayMonth, 'MMMM yyyy', {
                locale: calendarLocales[locale as AppUserLanguage],
              })}
            </CalendarNavigation>
          ),
        }}
      ></DayPicker>
    </div>
  );
};
