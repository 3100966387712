import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { SidebarItem, TechnicalInspectionScheduledAtValue } from '~/common';

import messages from './messages';

export type ServiceMileageProps = {
  dateToTechnicalInspection: string | null;
  remainingDaysToTechnicalInspection: number | null;
  label: ReactNode;
};

export const TechnicalInspectionScheduledAt = ({
  dateToTechnicalInspection,
  remainingDaysToTechnicalInspection,
  label,
}: ServiceMileageProps) => {
  if (
    dateToTechnicalInspection !== null &&
    remainingDaysToTechnicalInspection !== null
  ) {
    return (
      <SidebarItem
        className="mb-2"
        label={label}
        value={
          <TechnicalInspectionScheduledAtValue
            date={new Date(dateToTechnicalInspection)}
            remainingDays={remainingDaysToTechnicalInspection}
          />
        }
      />
    );
  }

  return (
    <SidebarItem
      className="mb-2"
      label={label}
      value={<FormattedMessage {...messages.technicalInspectionNotDefined} />}
    />
  );
};
