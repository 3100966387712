import { FormProvider, Form, FormProviderProps } from '@cooltra/form';
import { useIntl } from 'react-intl';

import { BonusZoneFormValues, validateBonusZoneForm } from './bonus-zone-form';

export type BonusZoneFormProps = Omit<
  FormProviderProps<BonusZoneFormValues>,
  'validate'
>;

export const BonusZoneForm = ({
  onSubmit,
  initialValues,
  children,
}: BonusZoneFormProps) => {
  const intl = useIntl();

  return (
    <FormProvider
      initialValues={initialValues}
      validate={validateBonusZoneForm(intl)}
      onSubmit={onSubmit}
    >
      <Form>{children}</Form>
    </FormProvider>
  );
};
