import { FormattedMessage } from 'react-intl';

import { ListColumnLabel } from '~/common';

import { PromotionRowLayout } from '../PromotionRowLayout';

import messages from './messages';

export const PromotionsListHeader = () => (
  <PromotionRowLayout
    data-testid="PROMOTIONS_LIST_HEADER"
    promotion={
      <ListColumnLabel>
        <FormattedMessage {...messages.promotion} />
      </ListColumnLabel>
    }
    benefit={
      <ListColumnLabel>
        <FormattedMessage {...messages.benefit} />
      </ListColumnLabel>
    }
    validFrom={
      <ListColumnLabel>
        <FormattedMessage {...messages.redeemableFrom} />
      </ListColumnLabel>
    }
    validUntil={
      <ListColumnLabel>
        <FormattedMessage {...messages.redeemableUntil} />
      </ListColumnLabel>
    }
    uses={
      <ListColumnLabel>
        <FormattedMessage {...messages.uses} />
      </ListColumnLabel>
    }
    state={
      <ListColumnLabel>
        <FormattedMessage {...messages.state} />
      </ListColumnLabel>
    }
  />
);
