import { AlgorithmPrice } from '@cooltra/station-based-api';
import { defineMessages } from 'react-intl';

export const algorithmNamesMessages = defineMessages<AlgorithmPrice>({
  AGGRESSIVE: {
    defaultMessage: 'Aggressive',
  },
  BASE: {
    defaultMessage: 'Base',
  },
  MODERATE: {
    defaultMessage: 'Moderate',
  },
  LINEAR: {
    defaultMessage: 'Linear',
  },
  FIXED: {
    defaultMessage: 'Fixed',
  },
  EXTRA: {
    defaultMessage: 'Extra',
  },
});
