import { defineMessages } from 'react-intl';

export default defineMessages({
  extraInfo: {
    defaultMessage:
      'To fill the second driver information, you must add this service to the contract.',
  },
  personalInformation: {
    defaultMessage: 'Personal information',
  },
  identityDocument: {
    defaultMessage: 'Identity document',
  },
  driverLicense: {
    defaultMessage: 'Driver license',
  },
  selfie: {
    defaultMessage: 'Selfie',
  },
  addSecondDriver: {
    defaultMessage: 'Add',
  },
  dailyPrice: {
    defaultMessage: '/ day',
  },
  totalPrice: {
    defaultMessage: 'Total {amount}',
  },
  additionalDriver: {
    defaultMessage: 'Additional driver',
  },
});
