import { UserListItem } from '@cooltra/api';
import { List } from '@cooltra/ui';

import { NoSearchResults } from '~/common';

import { UsersTotal } from './UsersTotal/UsersTotal';
import { UsersListHeader } from './UsersListHeader';
import { UserCard, UserCardLoading } from './UserCard';

type CooltraUsersListProps = {
  isLoading: boolean;
  users: UserListItem[];
  keyword: string;
};

export const CooltraUsersList = ({
  isLoading,
  users,
  keyword,
}: CooltraUsersListProps) => {
  if (users.length === 0 && !isLoading) {
    return <></>;
  }

  return (
    <div
      className="container min-w-3xl max-w-6xl pb-12"
      data-testid="USER_LIST"
    >
      <UsersTotal
        loading={isLoading}
        total={users.length}
        keyboard={keyword}
        className="mb-12"
      />
      <List
        loadingRows={2}
        isLoading={isLoading}
        idProp="userId"
        data={users}
        renderEmptyMessage={<NoSearchResults />}
        renderLoadingRow={<UserCardLoading />}
        renderHeader={<UsersListHeader />}
        renderRow={(user: UserListItem) => <UserCard user={user} />}
      />
    </div>
  );
};
