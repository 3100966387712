import { defineMessages } from 'react-intl';

export default defineMessages({
  promotion: {
    defaultMessage: 'Promotion',
  },
  type: {
    defaultMessage: 'Type',
  },
  validity: {
    defaultMessage: 'Validity',
  },
  state: {
    defaultMessage: 'State',
  },
});
