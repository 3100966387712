import { FormattedMessage } from 'react-intl';
import { InputLabel } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';

import { FormAmountInputField } from '../../../../FormattedInput';
import { StationBasedFormValues } from '../../StationBasedForm';
import { StationBasedStateType } from '../../StationBasedState/StationBasedState';

import messages from './messages';

export const CreditValue = () => {
  const { values } = useFormContext<StationBasedFormValues>();
  const { editMode, stateType, promotionTarget } = values;
  const editingNoSchedulePromotion =
    editMode && stateType !== StationBasedStateType.SCHEDULED;

  if (promotionTarget !== 'COUPON') {
    return <></>;
  }

  return (
    <FormAmountInputField
      name="credit.value"
      id="credit-value"
      className="w-44"
      disabled={editingNoSchedulePromotion}
      placeholder="25 €"
      label={
        <InputLabel htmlFor="credit-value">
          <FormattedMessage {...messages.creditValue} />
        </InputLabel>
      }
    />
  );
};
