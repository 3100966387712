import { InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSelectField } from '@cooltra/form';
import { PromotionState } from '@cooltra/api';

import messages, { stateLabels } from './messages';

const states: PromotionState[] = ['ACTIVE', 'EXPIRED', 'SCHEDULED'];

export const State = () => {
  const { formatMessage } = useIntl();
  return (
    <FormSelectField
      name="state"
      id="state"
      isClearable
      placeholder={formatMessage(messages.placeholder)}
      label={
        <InputLabel htmlFor="state">
          <FormattedMessage {...messages.state} />
        </InputLabel>
      }
      options={states.map((value) => ({
        value,
        label: formatMessage(stateLabels[value]),
      }))}
    />
  );
};
