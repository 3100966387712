import { ReactNode } from 'react';
import { WorkShiftWorkUnit } from '@cooltra/api/src/modules/work-shifts/types';

import { ShiftRouteCoordinatesContext } from './ShiftRouteCoordinatesContext';

export type ShiftRouteProviderProps = {
  children: ReactNode;
  workUnits: WorkShiftWorkUnit[];
};

export const ShiftRouteCoordinatesProvider = ({
  children,
  workUnits,
}: ShiftRouteProviderProps) => {
  const rawRouteCoordinates = workUnits.map(({ vehicle }) => [
    Number(vehicle.location.before.longitude),
    Number(vehicle.location.before.latitude),
  ]);

  const sanitiseCoordinates = (rawRouteCoordinates: (number | null)[][]) =>
    rawRouteCoordinates.filter(
      (coordinate) => coordinate[0] !== null && coordinate[1] !== null
    ) as [number, number][];

  const routeCoordinates = sanitiseCoordinates(rawRouteCoordinates);

  return (
    <ShiftRouteCoordinatesContext.Provider
      value={{
        routeCoordinates,
        workUnits,
      }}
    >
      {children}
    </ShiftRouteCoordinatesContext.Provider>
  );
};
