import { Placeholder } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type RecollectionTakesProps = {
  recollectionTakes: number;
};

export const RecollectionTakes = ({
  recollectionTakes,
}: RecollectionTakesProps) => {
  return (
    <div className="flex flex-col">
      {recollectionTakes !== undefined ? (
        <span className="text-md text-neutral-1000 font-semibold">
          {recollectionTakes}
        </span>
      ) : (
        <Placeholder className="w-6 h-5 mx-auto mb-1" />
      )}
      <span className="text-xs text-neutral-300">
        <FormattedMessage {...messages.recollectionTakes} />
      </span>
    </div>
  );
};
