import { RequestChangesReason } from '@cooltra/api';

import { isPast } from '~/utils/date';
import { isValidDate } from '~/validation';

import { ReviewFormValues } from '../../review-form-types';

export const getRequestChangesReasons = ({
  arePhotosValid,
  isValidSelfie,
  isValidIdPhoto,
  isValidDriverLicensePhoto,
  isValidId,
  isValidDriverLicense,
  driverLicenseExpirationDate,
  isAddressComplete,
  taxIdCountry,
  country,
}: ReviewFormValues) => {
  const reasons: RequestChangesReason[] = [];

  if (arePhotosValid === false) {
    if (isValidSelfie === false) {
      reasons.push('invalid selfie');
    }
    if (isValidIdPhoto === false) {
      reasons.push('invalid id photo');
    }
    if (isValidDriverLicensePhoto === false) {
      reasons.push('invalid driver license photo');
    }
  }

  if (
    isValidId === true &&
    taxIdCountry &&
    country &&
    taxIdCountry !== country
  ) {
    reasons.push('address id country mismatch');
  }

  if (isValidId === false) {
    reasons.push('invalid id');
  }

  if (isValidDriverLicense === false) {
    reasons.push('invalid driver license');
  }

  if (
    isValidDriverLicense &&
    isValidDate(driverLicenseExpirationDate) &&
    isPast(driverLicenseExpirationDate)
  ) {
    reasons.push('expired driver license');
  }

  if (isAddressComplete === false) {
    reasons.push('incomplete address');
  }

  return reasons;
};
