export const clean = <T>(object: T): Partial<T> => {
  const cleanObject: any = {};

  for (const key in object) {
    if (object[key]) {
      cleanObject[key] = object[key];
    }
  }

  return cleanObject;
};
