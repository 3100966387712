import { FormattedMessage, useIntl } from 'react-intl';
import { InputLabel } from '@cooltra/ui';
import { AlgorithmPrice, ProductCategory } from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { FormInputField, FormSelectField } from '@cooltra/form';

import messages, {
  algorithmMessages,
  productCategoryTypeMessages,
} from './messages';

type ProductCategoryProps = {
  productCategory: ProductCategory;
};

export const ProductCategoryItem = ({
  productCategory,
}: ProductCategoryProps) => {
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthClaimsQuery();
  const algorithms: AlgorithmPrice[] = [
    'BASE',
    'AGGRESSIVE',
    'MODERATE',
    'LINEAR',
    'FIXED',
    'EXTRA',
  ];

  return (
    <div
      data-testid={`PRODUCT_CATEGORY_${productCategory}`}
      className="flex py-12 gap-10"
    >
      <h3 className="flex-1 text-lg text-neutral-400 font-semibold">
        <FormattedMessage {...productCategoryTypeMessages[productCategory]} />
      </h3>
      <FormInputField
        name={`prices.${productCategory}.price.value`}
        id={productCategory}
        className="w-24"
        placeholder="30"
        disabled={!hasPermission('write:prices')}
        label={
          <InputLabel htmlFor={productCategory}>
            <FormattedMessage {...messages.price} /> (€)
          </InputLabel>
        }
      />
      <FormSelectField
        name={`prices.${productCategory}.type`}
        id={`prices.${productCategory}.type`}
        className="flex-1"
        label={
          <InputLabel htmlFor={`prices.${productCategory}.type`}>
            <FormattedMessage {...messages.algorithmForDiscount} />
          </InputLabel>
        }
        placeholder={formatMessage(messages.placeholder)}
        disabled={!hasPermission('write:prices')}
        options={algorithms.map((algorithmType) => ({
          value: `${productCategory}_${algorithmType}`,
          label: formatMessage(algorithmMessages[algorithmType]),
        }))}
      />
    </div>
  );
};
