import { Vehicle } from '@cooltra/api';
import { MdLocationOn } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { ModelImage } from '~/common';
import { cities } from '~/common/System/messages';

export type VehicleTooltipProps = Pick<
  Vehicle,
  'model' | 'externalId' | 'system' | 'licensePlate' | 'identificationNumber'
>;

export const VehicleTooltip = ({
  model,
  licensePlate,
  externalId,
  system,
  identificationNumber,
}: VehicleTooltipProps) => (
  <div className="w-72">
    <div className="p-4">
      <div className="flex items-center gap-3">
        <ModelImage
          model={model}
          identificationNumber={identificationNumber}
          className="h-12"
        />
        <div>
          <span className="text-sm font-semibold text-neutral-900">
            {externalId}
          </span>
          <span className="text-sm text-neutral-500 block mb-1">
            {licensePlate}
          </span>
          <span className="flex items-center gap-1 text-sm">
            <MdLocationOn className="text-neutral-300" />
            <span className="text-neutral-700">
              <FormattedMessage {...cities[system]} />
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
);
