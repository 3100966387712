import { RentalState } from '@cooltra/api';
import { defineMessages } from 'react-intl';

export default defineMessages<RentalState>({
  COMPLETED: {
    defaultMessage: 'Completed',
  },
  CLOSED: {
    defaultMessage: 'Closed',
  },
  ACTIVE: {
    defaultMessage: 'Active',
  },
});
