import { FormattedMessage, useIntl } from 'react-intl';
import { useHonkMutation } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { Button } from '@cooltra/ui';
import { AxiosError } from 'axios';
import { MdVolumeUp } from 'react-icons/md';

import { useNotification } from '~/hooks';
import { useVehiclePolling } from '~/libs/polling-data';

import { errorMessages } from '../error-messages';
import { ActionButton } from '../types';

import messages from './messages';

export const Honk = ({
  vehicleId,
  emphasis,
  showIcon,
  isDropdown,
}: ActionButton) => {
  const { formatMessage } = useIntl();
  const { activatePollingVehicle } = useVehiclePolling();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleOnSuccess = () => {
    addSuccessNotification(formatMessage(messages.successOnHonkVehicle));
    activatePollingVehicle(vehicleId);
  };

  const handleOnError = (error: AxiosError) => {
    let errorNotification;

    switch (getErrorStatus(error)) {
      case 409:
        errorNotification = formatMessage(errorMessages.conflictInAction);
        break;
      case 422:
        errorNotification = formatMessage(
          errorMessages.notProperlyConfiguredVehicle
        );
        break;
      case 502:
        errorNotification = formatMessage(
          errorMessages.issueWithTelematicsProvider
        );
        break;
      case 504:
        errorNotification = formatMessage(errorMessages.vehicleIsOffline);
        break;
    }

    addErrorNotification(errorNotification);
  };

  const { isPending, mutate } = useHonkMutation(vehicleId, {
    onError: handleOnError,
    onSuccess: handleOnSuccess,
  });

  return (
    <>
      {isDropdown ? (
        <Button
          size="sm"
          emphasis="low"
          className="w-full border-0 rounded-none py-5 text-neutral-1000"
          onClick={() => mutate()}
          loading={isPending}
        >
          <div className="flex gap-4 items-center">
            {showIcon && <MdVolumeUp className="w-5 h-5 mb-1 mt-1" />}
            <span className="w-32 text-left font-normal text-sm">
              <FormattedMessage {...messages.honk} />
            </span>
          </div>
        </Button>
      ) : (
        <Button
          size={showIcon ? 'lg' : 'sm'}
          emphasis={emphasis}
          loading={isPending}
          onClick={() => mutate()}
          className={showIcon ? 'w-full' : ''}
        >
          <div className="flex flex-col items-center justify-center">
            {showIcon && <MdVolumeUp className="w-5 h-5 mb-1 mt-1" />}
            <span className="text-sm bg">
              <FormattedMessage {...messages.honk} />
            </span>
          </div>
        </Button>
      )}
    </>
  );
};
