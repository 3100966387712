import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Task details',
  },
  taskComments: {
    defaultMessage: 'Comments',
  },
});
