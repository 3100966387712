import { defineMessages } from 'react-intl';

export default defineMessages({
  geoLocation: {
    defaultMessage: 'Geolocation',
  },
  lastUpdated: {
    defaultMessage: 'Last updated',
  },
  speed: {
    defaultMessage: 'Speed',
  },
  noData: {
    defaultMessage: 'No data available',
  },
});
