import { defineMessages } from 'react-intl';

export default defineMessages({
  exceedDiscountValue: {
    defaultMessage: 'Exceeds maximum percentage values allowed (100%)',
  },
  oneOfThemMustBeSelected: {
    defaultMessage: 'One of them must be selected',
  },
  exceedMaxUsesValue: {
    defaultMessage: 'Exceeds maximum values allowed (50.000)',
  },
});
