import { RentalListItem } from '@cooltra/api';
import { RouterCardLink } from '@cooltra/navigation';

import { DateTime, RentalStateBadge, SystemCity } from '~/common';

import { OtherRentalRowLayout } from './OtherRentalRowLayout';

export type OtherRentalCardProps = {
  rental: RentalListItem;
};

export const OtherRentalCard = ({
  rental: { rentalId, system, pickUp, dropOff, state, vehicle },
}: OtherRentalCardProps) => (
  <RouterCardLink to={`/rentals/${rentalId}`} className="py-4" isMultiAction>
    <OtherRentalRowLayout
      rentalId={
        <span className="font-semibold text-neutral-800">
          {vehicle.licensePlate}
        </span>
      }
      system={<SystemCity system={system} />}
      pickUp={<DateTime date={pickUp.createdAt} />}
      dropOff={<DateTime date={dropOff?.createdAt} />}
      state={<RentalStateBadge state={state} />}
    />
  </RouterCardLink>
);
