import {
  useVehicleProblemReportsQuery,
  VehicleProblemReportListItem,
} from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { equal } from '@cooltra/utils';
import { useFormContext } from '@cooltra/form';
import { useParams } from 'react-router-dom';
import { List } from '@cooltra/ui';
import { useEffect } from 'react';

import {
  ErrorPage,
  FilterFields,
  Four0Three,
  NoSearchResults,
  ProblemReportsTotal,
} from '~/common';
import { useNavigateWithQueryParams, useVehicleQuery } from '~/hooks';

import { VehicleProblemReportsListHeader } from './VehicleProblemReportsListHeader';
import {
  VehicleProblemReportCard,
  VehicleProblemReportCardLoading,
} from './VehicleProblemReportCard';
import {
  VehicleProblemReportsFiltersFormValues,
  vehicleProblemReportsFiltersInitialValues,
} from './vehicle-problem-reports-filters-form';
import { NoVehicleProblemReports } from './NoVehicleProblemReports/NoVehicleProblemReports';

export const VehicleProblemReportsList = () => {
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const { vehicleId = '' } = useParams<'vehicleId'>();
  const { hasPermission } = useAuthClaimsQuery();

  const { values } = useFormContext<VehicleProblemReportsFiltersFormValues>();

  const { data: vehicle } = useVehicleQuery(vehicleId);

  const {
    data: problemReports = [],
    isLoading,
    isError,
    error,
  } = useVehicleProblemReportsQuery(vehicleId, values);

  useEffect(() => {
    navigateWithQueryParams(values);
  }, [navigateWithQueryParams, values]);

  const problemReportsTotal = problemReports?.length || 0;

  const noProblemReportsFound = !isLoading && !problemReports?.length;

  if (!hasPermission('read:problem_reports')) {
    return <Four0Three />;
  }

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (
    noProblemReportsFound &&
    equal(values, vehicleProblemReportsFiltersInitialValues)
  ) {
    return <NoVehicleProblemReports />;
  }

  return (
    <div className="container min-w-3xl max-w-5xl py-12">
      <div className="flex gap-8 mb-10">
        <div className="w-60">
          <FilterFields.Status />
        </div>
        <FilterFields.Created />
      </div>
      <ProblemReportsTotal
        loading={isLoading}
        total={problemReportsTotal}
        className="mb-12"
      />
      <List
        loadingRows={20}
        isLoading={isLoading || !vehicle}
        idProp="problemReportId"
        data={problemReports}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<VehicleProblemReportsListHeader />}
        renderLoadingRow={<VehicleProblemReportCardLoading />}
        renderRow={(problemReport: VehicleProblemReportListItem) =>
          vehicle && <VehicleProblemReportCard problemReport={problemReport} />
        }
      />
    </div>
  );
};
