import { InputLabel } from '@cooltra/ui';
import { useLocationsQuery, LocationListItem } from '@cooltra/api';
import { System } from '@cooltra/auth-api';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormMultiSelectField, useField } from '@cooltra/form';

import messages from './messages';

export const Locations = () => {
  const { formatMessage } = useIntl();
  const { value } = useField<System[]>('system');

  const { data, isLoading } = useLocationsQuery({
    system: value,
  });

  return (
    <FormMultiSelectField
      name="location"
      id="location"
      isLoading={isLoading}
      noOptionsMessage={() => <FormattedMessage {...messages.noLocations} />}
      label={
        <InputLabel htmlFor="location">
          <FormattedMessage {...messages.locations} />
        </InputLabel>
      }
      placeholder={formatMessage(messages.placeholder)}
      options={data
        ?.map((location: LocationListItem) => ({
          label: location.name,
          value: location.locationId,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))}
    />
  );
};
