import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Identity document',
  },
  isValidId: {
    defaultMessage: 'Is it a valid ID in {country}?',
  },
  tooltipBody: {
    defaultMessage:
      'There are agreements between countries that determine what constitutes a valid ID. For example, a national ID of an Italian is valid in Spain whereas a national ID of a Brazilian is not (only passport is).',
  },
  tooltipHelp: {
    defaultMessage: 'In case of doubts, check out this { csTableLink }.',
  },
  tooltipLink: {
    defaultMessage: 'table',
  },
  tooltipFootnote: {
    defaultMessage:
      'Please refer to the spreadsheet provided by Customer Service for more details.',
  },
  valid: {
    defaultMessage: 'Valid',
  },
  invalid: {
    defaultMessage: 'Invalid',
  },
  idSameAsTaxId: {
    defaultMessage: 'Same as ID number',
  },
});
