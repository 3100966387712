import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { RequestChangesReason } from '@cooltra/api';

import { ReviewOutcomeAlert } from '../ReviewOutcomeAlert';
import { useHandleSubmit } from '../useHandleSubmit';

import messages, { requestChangesReasonMessages } from './messages';

export type RequestChangesAlertProps = {
  onRequestChanges: () => void;
  isLoading: boolean;
  reasons: RequestChangesReason[];
};

export const RequestChangesAlert = ({
  onRequestChanges,
  isLoading,
  reasons,
}: RequestChangesAlertProps) => {
  const handleClick = useHandleSubmit(onRequestChanges);
  return (
    <ReviewOutcomeAlert
      renderHeader={<FormattedMessage {...messages.requestChangesHeading} />}
      renderList={reasons.map((reason) => (
        <li key={reason}>
          {requestChangesReasonMessages[reason] ? (
            <FormattedMessage {...requestChangesReasonMessages[reason]} />
          ) : (
            reason
          )}
        </li>
      ))}
      renderButton={
        <Button emphasis="high" loading={isLoading} onClick={handleClick}>
          <FormattedMessage {...messages.requestChangesButton} />
        </Button>
      }
    />
  );
};
