import { InvoiceState, InvoiceType } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { useToggle } from '@cooltra/hooks';
import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { MarkInvoiceAsPaidModal } from '../MarkInvoiceAsPaidModal/MarkInvoiceAsPaidModal';

import messages from './messages';

export type MarkInvoiceAsPaidProps = {
  invoiceId: string;
  state: InvoiceState;
  type: InvoiceType;
};

export const MarkInvoiceAsPaid = ({
  invoiceId,
  state,
  type,
}: MarkInvoiceAsPaidProps) => {
  const { hasPermission } = useAuthClaimsQuery();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const canMarkInvoiceAsPaid =
    state === 'FAILED' && (type === 'FOREIGNER_INVOICE' || type === 'INVOICE');

  if (!hasPermission('mark_as_paid:invoices') || !canMarkInvoiceAsPaid) {
    return null;
  }

  return (
    <>
      <MarkInvoiceAsPaidModal
        isOpen={isOpen}
        onClose={toggleOff}
        invoiceId={invoiceId}
      />
      <Button size="sm" emphasis="high" onClick={toggleOn}>
        <FormattedMessage {...messages.markAsPaid} />
      </Button>
    </>
  );
};
