import { FormattedMessage } from 'react-intl';
import { InputLabel } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';
import { useVehicleStatusQuery } from '@cooltra/api';

import { FormNumberInputField, ServiceFormValues } from '~/common';

import messages from './messages';

export type ServiceMileageProps = {
  vehicleId: string;
};

export const ServiceMileage = ({ vehicleId }: ServiceMileageProps) => {
  const { data: vehicleStatus } = useVehicleStatusQuery(vehicleId, {
    enabled: !!vehicleId,
  });
  const {
    values: { serviceMileage },
  } = useFormContext<ServiceFormValues>();

  return (
    <div className="w-full flex flex-col" data-testid="SERVICE_MILEAGE">
      <FormNumberInputField
        name="serviceMileage"
        id="serviceMileage"
        placeholder="10000 km"
        label={
          <InputLabel htmlFor="serviceMileage">
            <FormattedMessage {...messages.serviceMileage} />
          </InputLabel>
        }
      />
      {Number(serviceMileage) < Number(vehicleStatus?.mileage?.value) ? (
        <sub className="text-sm text-warning-600">
          <FormattedMessage {...messages.warningMileage} />
        </sub>
      ) : (
        <></>
      )}
    </div>
  );
};
