import { FormattedMessage } from 'react-intl';
import { FormInputField } from '@cooltra/form';

import { OptionalFieldLabel } from '~/common';

import messages from './messages';

export type IdNumberProps = {
  disabled: boolean;
};

export const IdNumber = ({ disabled }: IdNumberProps) => (
  <FormInputField
    name="identificationNumber"
    id="identification-number"
    disabled={disabled}
    label={
      <OptionalFieldLabel htmlFor="identification-number">
        <FormattedMessage {...messages.identificationNumber} />
      </OptionalFieldLabel>
    }
  />
);
