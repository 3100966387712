import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { InputField, InputFieldProps } from '@cooltra/ui';
import { useField } from '@cooltra/form';

export type KeywordProps = Omit<
  InputFieldProps,
  'valid' | 'invalid' | 'onChange' | 'onBlur' | 'value' | 'error' | 'name'
>;

export const Keyword = (props: KeywordProps) => {
  const [internalValue, setInternalValue] = useState('');

  const timeout = useRef<NodeJS.Timeout>();
  const { setValue, value } = useField('keyword');

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setInternalValue(target.value);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setValue(target.value);
    }, 1000);
  };

  const handleBlur = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      setValue(target.value);
    }
  };

  useEffect(
    () => () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    },
    []
  );

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <InputField
      onChange={handleChange}
      onBlur={handleBlur}
      value={internalValue}
      {...props}
    />
  );
};
