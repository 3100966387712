import { Link } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { AnchorHTMLAttributes } from 'react';

export type GoogleMapsLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  longitude: number;
  latitude: number;
};

export const GoogleMapsLink = ({
  latitude,
  longitude,
  className,
  ...rest
}: GoogleMapsLinkProps) => (
  <Link
    href={`http://www.google.com/maps/place/${latitude},${longitude}`}
    target="_blank"
    className={classNames('text-xs', 'font-semibold', className)}
    {...rest}
  >{`${longitude.toPrecision(6)}, ${latitude.toPrecision(6)}`}</Link>
);
