import { BadgePlaceholder, Card, Placeholder } from '@cooltra/ui';

import { PromotionRowLayout } from '../PromotionRowLayout';

export const PromotionCardLoading = () => (
  <Card>
    <PromotionRowLayout
      className="min-h-24 py-4"
      promotion={
        <div className="flex flex-col gap-3 py-0.5">
          <Placeholder className="w-24 h-2" />
          <Placeholder className="w-40 h-2" />
        </div>
      }
      benefit={<Placeholder className="h-2 w-8" />}
      validFrom={
        <div className="flex flex-col gap-3 py-0.5">
          <Placeholder className="w-16 h-2" />
          <Placeholder className="w-24 h-2" />
        </div>
      }
      validUntil={
        <div className="flex flex-col gap-3 py-0.5">
          <Placeholder className="w-16 h-2" />
          <Placeholder className="w-24 h-2" />
        </div>
      }
      uses={<Placeholder className="h-2 w-8" />}
      state={<BadgePlaceholder className="w-20" />}
    />
  </Card>
);
