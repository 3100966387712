import { OperationalCondition, OperationalState } from '@cooltra/api';
import { ReactNode } from 'react';

import { ExtendedOperationalCondition } from '~/libs/vehicles-filters';

import { SelectItem } from './SelectItem';

export type OperationalConditionSelectGroupOption = {
  label: string;
  value: OperationalCondition;
  group: OperationalState;
};

export type OperationalConditionSelectGroup = {
  label: string;
  group: OperationalState;
  options: OperationalConditionSelectGroupOption[];
};

export type OperationalConditionSelectProps = {
  onChange: (values: ExtendedOperationalCondition[]) => void;
  options: OperationalConditionSelectGroup[];
  renderLabel: ReactNode;
  renderOption: (option: OperationalConditionSelectGroupOption) => ReactNode;
  renderGroupLabel: (groupLabel: OperationalConditionSelectGroup) => ReactNode;
  values: ExtendedOperationalCondition[];
};

export const OperationalConditionSelect = ({
  onChange,
  options,
  renderLabel,
  renderOption,
  renderGroupLabel,
  values,
}: OperationalConditionSelectProps) => {
  const isSelectedOption = (option: OperationalConditionSelectGroupOption) =>
    !!values.find((val) => option.value === val);

  const isDeselectedOption = (option: OperationalConditionSelectGroupOption) =>
    !!values.find((val) => `!${option.value}` === val);

  const handleChange =
    (option: OperationalConditionSelectGroupOption) => () => {
      const newValues = isSelectedOption(option)
        ? values
            .filter((val) => option.value !== val)
            .concat(`!${option.value}`)
        : isDeselectedOption(option)
        ? values.filter((val) => `!${option.value}` !== val)
        : values.concat(option.value);

      onChange(newValues);
    };

  return (
    <div>
      <h6 className="text-neutral-700 mb-1 pl-4">{renderLabel}</h6>
      <ul data-testid="MULTI_SELECT_OPTIONS">
        {options.map((group) => (
          <li key={group.label} className="mb-4">
            {renderGroupLabel(group)}
            <ul>
              {group.options.map((option) => (
                <SelectItem
                  isSelected={!!isSelectedOption(option)}
                  isDeselected={!!isDeselectedOption(option)}
                  onClick={handleChange(option)}
                  key={option.value}
                  data-testid={option.value}
                >
                  {renderOption(option)}
                </SelectItem>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};
