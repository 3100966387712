import { RentalPromotionDiscountItem } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { RouterLink } from '@cooltra/navigation';

export type PricingDiscountItemProps = Pick<
  RentalPromotionDiscountItem,
  'promotionId' | 'title'
>;

export const PricingDiscountItem = ({
  promotionId,
  title,
}: PricingDiscountItemProps) => {
  const { hasPermission } = useAuthClaimsQuery();
  if (hasPermission('write:offers')) {
    return (
      <RouterLink
        className="text-sm"
        to={`/offers/promotions/${promotionId}/edit`}
      >
        {title}
      </RouterLink>
    );
  }
  return <span className="text-sm text-neutral-400">{title}</span>;
};
