import { FormattedMessage } from 'react-intl';

import { VehicleMap } from '../VehicleMap';

import messages from './messages';

export type VehicleGeolocationProps = {
  geolocation: {
    latitude: number;
    longitude: number;
  } | null;
  vehicleId: string;
};

export const VehicleGeolocation = ({
  geolocation,
  vehicleId,
}: VehicleGeolocationProps) => {
  return geolocation ? (
    <VehicleMap id={vehicleId} geolocation={geolocation} />
  ) : (
    <FormattedMessage {...messages.noContent} />
  );
};
