import { Legend } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { FormCheckboxGroupField } from '@cooltra/form';

import { useSystemOptions } from '~/common';

import messages from './messages';

export const Systems = () => {
  const options = useSystemOptions();
  return (
    <FormCheckboxGroupField
      innerClassName="grid grid-cols-2 gap-y-5"
      name="systems"
      legend={
        <Legend>
          <FormattedMessage {...messages.systems} />
        </Legend>
      }
      items={options.map(({ value, label }) => ({
        name: value,
        label,
      }))}
    />
  );
};
