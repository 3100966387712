import { defineMessages } from 'react-intl';

export default defineMessages({
  settings: {
    defaultMessage: 'Settings',
  },
  locations: {
    defaultMessage: 'Locations',
  },
  tags: {
    defaultMessage: 'Task tags',
  },
});
