import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Create promotion',
  },
  type: {
    defaultMessage: 'Promotion type',
  },
  details: {
    defaultMessage: 'Promotion details',
  },
  validity: {
    defaultMessage: 'Validity',
  },
  appliesTo: {
    defaultMessage: 'Applies to',
  },
});
