import { ImgHTMLAttributes, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Icon, Modal } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import {
  MdOutlineZoomIn,
  MdOutlineZoomOut,
  MdRotateLeft,
  MdRotateRight,
} from 'react-icons/md';

import messages from './messages';

import './photo-modal.css';

export type PhotoModalProps = ImgHTMLAttributes<HTMLImageElement> & {
  isOpen: boolean;
  onRequestClose: () => void;
  rotateLeft?: () => void;
  rotateRight?: () => void;
  imageData: string;
};

const INITIAL_SCALE = 100;
const SCALE_STEP = 50;

export const PhotoModal = ({
  isOpen,
  onRequestClose,
  rotateLeft,
  rotateRight,
  imageData,
  className,
  ...rest
}: PhotoModalProps) => {
  const { formatMessage } = useIntl();
  const [scale, setScale] = useState(INITIAL_SCALE);

  const handleOnZoomIn = () => {
    const newScale = scale + SCALE_STEP;

    setScale(newScale);
  };

  const handleOnZoomOut = () => {
    const newScale = scale - SCALE_STEP;

    if (newScale >= INITIAL_SCALE) {
      setScale(newScale);
    }
  };

  return (
    <Modal
      type="screen"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="h-full"
    >
      <div className="flex gap-6 justify-center">
        <div className="flex gap-2 mb-8">
          <Button
            square
            onClick={rotateLeft}
            aria-label={formatMessage(messages.rotateLeft)}
          >
            <Icon className="text-2xl">
              <MdRotateLeft />
            </Icon>
          </Button>
          <Button
            square
            onClick={rotateRight}
            aria-label={formatMessage(messages.rotateRight)}
          >
            <Icon className="text-2xl">
              <MdRotateRight />
            </Icon>
          </Button>
        </div>
        <div className="flex gap-2 mb-8">
          <Button
            square
            onClick={handleOnZoomIn}
            aria-label={formatMessage(messages.zoomIn)}
          >
            <Icon className="text-2xl">
              <MdOutlineZoomIn />
            </Icon>
          </Button>
          <Button
            square
            onClick={handleOnZoomOut}
            aria-label={formatMessage(messages.zoomOut)}
          >
            <Icon className="text-2xl">
              <MdOutlineZoomOut />
            </Icon>
          </Button>
        </div>
      </div>
      <div className="flex overflow-x-scroll overflow-y-scroll h-full items-center justify-center pb-20">
        {imageData && (
          <img
            src={imageData}
            alt={formatMessage(messages.scaledImage)}
            className={classNames('photo-modal relative top-0', className)}
            style={{
              scale: `${scale}%`,
            }}
            {...rest}
          />
        )}
      </div>
    </Modal>
  );
};
