import { defineMessages } from 'react-intl';

export default defineMessages({
  moreActions: {
    defaultMessage: 'More actions',
  },
  ignitionOn: {
    defaultMessage: 'Ignition On',
  },
  ignitionOff: {
    defaultMessage: 'Ignition Off',
  },
  enableAlarm: {
    defaultMessage: 'Enable alarm',
  },
  disableAlarm: {
    defaultMessage: 'Disable alarm',
  },
  refresh: {
    defaultMessage: 'Refresh',
  },
  resetGPS: {
    defaultMessage: 'Reset GPS',
  },
  openSaddle: {
    defaultMessage: 'Open saddle',
  },
  successOnGpsRebootVehicle: {
    defaultMessage: 'Reset GPS action has been triggered successfully',
  },
  successOnIgnitionOnVehicle: {
    defaultMessage: 'Ignition On action has been triggered successfully',
  },
  successOffIgnitionOnVehicle: {
    defaultMessage: 'Ignition Off action has been triggered successfully',
  },
  successOnOpenSaddleVehicle: {
    defaultMessage: 'Open saddle action has been triggered successfully',
  },
  successOnEnableAlarmVehicle: {
    defaultMessage: 'Alarm has been enabled successfully',
  },
  successOnDisableAlarmVehicle: {
    defaultMessage: 'Alarm has been disabled successfully',
  },
});
