import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Locations',
  },
  addNewButton: {
    defaultMessage: '+ Add new',
  },
  allSystems: {
    defaultMessage: 'All home systems',
  },
  edit: {
    defaultMessage: 'Edit',
  },
  count: {
    defaultMessage: '{count, plural, one {# vehicle} other {# vehicles}}',
  },
});
