import { FormattedMessage } from 'react-intl';
import { UserDiscount } from '@cooltra/api';

import messages, { vehicleTypes } from './messages';

export const TargetVehicleModels = ({
  targetVehicleModels,
}: Pick<UserDiscount, 'targetVehicleModels'>) => {
  const getFormattedTargetVehicleModels = () =>
    targetVehicleModels.map((vehicleType, index) => {
      const separator = index < targetVehicleModels.length - 1 ? ', ' : '';
      return (
        <FormattedMessage
          key={vehicleType}
          {...vehicleTypes[vehicleType]}
          values={{ separator }}
        />
      );
    });

  return (
    <FormattedMessage
      {...messages.onlyFor}
      values={{ targetVehicleModels: getFormattedTargetVehicleModels() }}
    />
  );
};
