import { UserSuspensionType } from '@cooltra/api';
import { Badge, BadgeProps } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type SuspensionBadgeProps = Omit<BadgeProps, 'variant' | 'emphasis '> & {
  type: UserSuspensionType;
};

export const SuspensionBadge = ({ type, ...rest }: SuspensionBadgeProps) => (
  <Badge variant="neutral" emphasis="low" {...rest}>
    <FormattedMessage {...messages[type]} />
  </Badge>
);
