import { defineMessages } from 'react-intl';

import { VehicleCategory } from '../../Form';

export default defineMessages({
  price: {
    defaultMessage: 'Price',
  },
});

export const vehicleCategoryTypeMessages = defineMessages<VehicleCategory>({
  EBIKE: {
    defaultMessage: 'Ebike',
  },
  STANDARD: {
    defaultMessage: 'Standard',
  },
  PLUS: {
    defaultMessage: 'Plus',
  },
  PREMIUM: {
    defaultMessage: 'Premium',
  },
  SUPER_PREMIUM: {
    defaultMessage: 'Super premium',
  },
});
