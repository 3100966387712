import { Card, Placeholder, TimelineItem, BadgePlaceholder } from '@cooltra/ui';
import { consecutiveNumbers } from '@cooltra/utils';

export const StatesLoading = () => (
  <Card className="p-14">
    {consecutiveNumbers(4).map((pos, index, list) => (
      <TimelineItem
        key={pos}
        header={<BadgePlaceholder className="w-24" />}
        timelineClassName="translate-y-3"
        isLast={list.length - 1 === index}
      >
        <div className="pt-4">
          <Placeholder className="w-60 h-2 mb-3" />
          <Placeholder className="w-36 h-2 mb-6" />
        </div>
        <Placeholder className="w-24 h-2 mb-8" />
      </TimelineItem>
    ))}
  </Card>
);
