import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { useToggle } from '@cooltra/hooks';
import { Button, Modal } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import {
  useFilteredVehiclesQuery,
  vehiclesFiltersInitialValues,
} from '~/libs/vehicles-filters';

import { ResetFiltersButton } from '../../FilterButtons';
import { FilterFields } from '../../FilterFields';
import { VehicleFiltersButton } from '../VehicleFiltersButton/VehicleFiltersButton';
import * as VehicleFilterFields from '../VehicleFilterFields';

import messages from './messages';

export type VehicleFiltersModalProps = {
  disabled: boolean;
};

export const VehicleFiltersModal = ({ disabled }: VehicleFiltersModalProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const {
    data: { filteredVehiclesTotal },
    isLoading,
  } = useFilteredVehiclesQuery();

  return (
    <>
      <VehicleFiltersButton onClick={toggleOn} disabled={disabled} />
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleOff}
        className="w-full max-w-screen-lg mb-14"
      >
        <div className="px-10 pb-10" data-testid="VEHICLE_FILTERS_MODAL">
          <div className="flex items-stretch gap-8">
            <div className="w-60 shrink-0 -ml-4 mb-8">
              <VehicleFilterFields.OperationalCondition />
            </div>
            <div className="flex flex-col justify-between gap-14 flex-1">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <div className="col-span-2">
                  <VehicleFilterFields.Search />
                </div>
                <FilterFields.System />
                {hasPermission('transfer:vehicles') && (
                  <VehicleFilterFields.Locations />
                )}
                <VehicleFilterFields.Assignee />
                <VehicleFilterFields.Tag />
                <VehicleFilterFields.Model />
                <VehicleFilterFields.Telematics />
                <VehicleFilterFields.CityHall />
                <VehicleFilterFields.WithOperator />
                <div className="relative z-0">
                  <VehicleFilterFields.Battery />
                </div>
                <VehicleFilterFields.BonusEnabled />
                <VehicleFilterFields.Unbalanced />
                <VehicleFilterFields.IdleFrom />
                <VehicleFilterFields.ParkingReview />
              </div>
              <div className="flex gap-12 items-center justify-end">
                <ResetFiltersButton
                  initialValues={vehiclesFiltersInitialValues}
                />
                <Button emphasis="high" onClick={toggleOff}>
                  {isLoading ? (
                    <FormattedMessage {...messages.loading} />
                  ) : (
                    <FormattedMessage
                      {...messages.showVehicles}
                      values={{ vehiclesTotal: filteredVehiclesTotal }}
                    />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
