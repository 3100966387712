import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@cooltra/ui';
import { useAddInvoiceCustomerRetriesMutation } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';

import { useNotification } from '~/hooks';

import messages from './messages';

export type AddInvoiceCustomerRetriesProps = {
  invoiceId: string;
  isPaid: boolean;
};

export const AddInvoiceCustomerRetries = ({
  invoiceId,
  isPaid,
}: AddInvoiceCustomerRetriesProps) => {
  const { hasPermission } = useAuthClaimsQuery();
  const intl = useIntl();
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const { mutateAsync } = useAddInvoiceCustomerRetriesMutation(invoiceId);

  if (!hasPermission('write:invoices') || isPaid) {
    return null;
  }

  const onClick = () => {
    mutateAsync()
      .then(() => addSuccessNotification(intl.formatMessage(messages.success)))
      .catch((error) => {
        switch (getErrorStatus(error)) {
          case 404:
            addErrorNotification(intl.formatMessage(messages.error404));
            break;
          case 422:
            addErrorNotification(intl.formatMessage(messages.error422));
            break;
          default:
            addErrorNotification();
            break;
        }
      });
  };

  return (
    <Button size="sm" emphasis="high" onClick={onClick}>
      <FormattedMessage {...messages.addCustomerRetries} />
    </Button>
  );
};
