import { defineMessages } from 'react-intl';

export default defineMessages({
  problem: {
    defaultMessage: 'Problem',
  },
  created: {
    defaultMessage: 'Created',
  },
  vehicle: {
    defaultMessage: 'Vehicle',
  },
  context: {
    defaultMessage: 'Context',
  },
  tasks: {
    defaultMessage: 'Tasks',
  },
});
