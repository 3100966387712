import { Card, Placeholder } from '@cooltra/ui';

import { RentalIncidentRowLayout } from '../RentalIncidentRowLayout';

export const RentalIncidentCardLoading = () => (
  <Card className="h-28 py-0.5 flex">
    <RentalIncidentRowLayout
      incident={
        <>
          <Placeholder className="w-28 h-2 mb-3" />
          <Placeholder className="h-2 w-52 mb-2" />
          <Placeholder className="h-2 w-40" />
        </>
      }
      assignee={<Placeholder className="h-2 w-20" />}
      lastUpdated={
        <>
          <Placeholder className="h-2 w-36 mb-3" />
          <Placeholder className="h-2 w-28" />
        </>
      }
    />
  </Card>
);
