import { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  useContractPaymentsQuery,
  ContractPayment,
} from '@cooltra/station-based-api';

import { useNotification, usePolling } from '~/hooks';

import messages from './messages';

export type UseContractPaymentsPollingProps = {
  contractId: string;
  ticketId: string;
  onSettle: () => void;
};

const getIsPaymentRefunded = (
  contractPayments: ContractPayment[] | undefined,
  ticketId: string
) => {
  const ticket = contractPayments?.find((t) => t.id === ticketId);
  return Boolean(ticket?.status === 'REFUNDED');
};

export const useContractPaymentsPolling = ({
  onSettle,
  contractId,
  ticketId,
}: UseContractPaymentsPollingProps) => {
  const { formatMessage } = useIntl();
  const { addSuccessNotification } = useNotification();
  const [isStripePoolingActive, setIsStripePollingActive] = useState(false);
  const { data: contractPayments, refetch } =
    useContractPaymentsQuery(contractId);

  const isPaymentRefunded = getIsPaymentRefunded(contractPayments, ticketId);

  const checkIfPaymentIsRefunded = async () =>
    await refetch().then(({ data }) => {
      if (getIsPaymentRefunded(data, ticketId)) {
        addSuccessNotification(formatMessage(messages.refundSuccess));
        setIsStripePollingActive(false);
      }
    });

  const { isPolling } = usePolling({
    fn: checkIfPaymentIsRefunded,
    onSettle,
    maxAttempts: 5,
    interval: !isPaymentRefunded && isStripePoolingActive ? 500 : 0,
  });

  return {
    isPolling,
    setIsStripePollingActive,
  };
};
