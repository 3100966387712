import { FormattedMessage } from 'react-intl';
import { InputLabel } from '@cooltra/ui';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { FormInputField } from '@cooltra/form';

import { VehicleCategory } from '../../Form';

import messages, { vehicleCategoryTypeMessages } from './messages';

type VehicleCategoryProps = {
  vehicleCategory: VehicleCategory;
  shouldBeEnabled: boolean;
};

export const VehicleCategoryItem = ({
  vehicleCategory,
  shouldBeEnabled,
}: VehicleCategoryProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  return (
    <div
      data-testid={`VEHICLE_CATEGORY_${vehicleCategory}`}
      className="flex py-12 gap-10"
    >
      <h3 className="flex-1 text-lg text-neutral-400 font-semibold">
        <FormattedMessage {...vehicleCategoryTypeMessages[vehicleCategory]} />
      </h3>
      <FormInputField
        name={`prices.${vehicleCategory}.price.value`}
        id={vehicleCategory}
        className="w-24"
        placeholder={shouldBeEnabled ? '100' : ''}
        disabled={!hasPermission('write:prices') || !shouldBeEnabled}
        label={
          <InputLabel htmlFor={vehicleCategory}>
            <FormattedMessage {...messages.price} /> (€)
          </InputLabel>
        }
      />
    </div>
  );
};
