import { Vehicle } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { ModelName, StaticField } from '~/common';

import messages from './messages';

export type ModelStaticProps = {
  value: Vehicle['model'];
};

export const ModelStatic = ({ value }: ModelStaticProps) => (
  <StaticField
    renderLabel={<FormattedMessage {...messages.model} />}
    renderValue={<ModelName model={value} />}
  />
);
