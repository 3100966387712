import { defineMessages } from 'react-intl';

export default defineMessages({
  dailyContracts: {
    defaultMessage: 'Daily contracts',
  },
  search: {
    defaultMessage: 'Search',
  },
  newDraftContract: {
    defaultMessage: 'New contract',
  },
  vehicles: {
    defaultMessage: 'Vehicles',
  },
});
