import { PromotionListItem } from '@cooltra/station-based-api';
import { RouterCardLink } from '@cooltra/navigation';
import { classNames } from '@cooltra/utils';
import { isPast } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { Divider } from '@cooltra/ui';

import { Amount, MultilineDateTime, StationBasedState } from '~/common';

import { StationBasedPromotionRowLayout } from '../StationBasedPromotionRowLayout';

import messages, { offerTypeMessages } from './messages';

export type PromotionCardProps = {
  promotion: PromotionListItem;
};

export const StationBasedPromotionCard = ({
  promotion: {
    promotionId,
    title,
    target,
    about,
    discount,
    credit,
    validFrom,
    validUntil,
  },
}: PromotionCardProps) => (
  <RouterCardLink
    to={`/offers/station-based/${promotionId}/edit`}
    className={classNames(
      validUntil && isPast(new Date(validUntil)) ? 'opacity-50' : 'opacity-100'
    )}
  >
    <StationBasedPromotionRowLayout
      className="min-h-24 py-4"
      promotion={
        <>
          <span className="block">
            <span className="text-base text-neutral-1000 font-semibold">
              {title}
            </span>
          </span>
          <span className="text-sm text-neutral-600">{about}</span>
        </>
      }
      type={
        <span className="text-base">
          {discount ? (
            <FormattedMessage
              {...messages.discount}
              values={{
                discount: (
                  <span className="tabular-nums font-semib">{discount}</span>
                ),
                offerType: (
                  <span className="font-semibold">
                    <FormattedMessage {...offerTypeMessages[target]} />
                  </span>
                ),
              }}
            />
          ) : (
            credit && (
              <FormattedMessage
                {...messages.credit}
                values={{
                  credit: <Amount {...credit} />,
                  offerType: (
                    <FormattedMessage {...offerTypeMessages[target]} />
                  ),
                }}
              />
            )
          )}
        </span>
      }
      validity={
        <div className="flex items-center gap-8 text-sm">
          <MultilineDateTime emphasize="date" date={new Date(validFrom)} />
          <div className="w-6">
            <Divider />
          </div>
          <MultilineDateTime emphasize="date" date={new Date(validUntil)} />
        </div>
      }
      state={
        <StationBasedState validFrom={validFrom} validUntil={validUntil} />
      }
    />
  </RouterCardLink>
);
