import { useState } from 'react';
import { Button, InputLabel, Modal, SelectField } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { System } from '@cooltra/auth-api';
import { useLocationsQuery, LocationListItem, Location } from '@cooltra/api';

import messages from './messages';

export type ChangeVehiclesLocationModalProps = {
  isOpen: boolean;
  title: string;
  systems: System[];
  onClose: () => void;
  onSave: (newLocation: Location) => void;
  loading?: boolean;
};

export const ChangeVehiclesLocationModal = ({
  isOpen,
  title,
  systems,
  onClose,
  onSave,
  loading,
}: ChangeVehiclesLocationModalProps) => {
  const { formatMessage } = useIntl();
  const { data } = useLocationsQuery(
    { system: systems, availableInAllSystems: true },
    {
      staleTime: Infinity,
    }
  );
  const [selectedLocation, setSelectedLocation] = useState<
    LocationListItem | undefined
  >(undefined);

  const handleOnChangeLocation = (value: string) => {
    const newSelectedLocation = data?.find(
      (listLocation) => listLocation.locationId === value
    );
    setSelectedLocation(newSelectedLocation);
  };

  const handleOnSave = () => {
    if (selectedLocation) {
      onSave({
        ...selectedLocation,
        systems: systems,
      });
      setSelectedLocation(undefined);
    }
  };

  return (
    <Modal
      className="w-96"
      isOpen={isOpen}
      onRequestClose={onClose}
      header={
        <h6 className="text-neutral-400 text-lg px-4 mt-5 font-semibold">
          {title}
        </h6>
      }
    >
      <div
        data-testid="CHANGE_VEHICLE_LOCATION_MODAL"
        className="text-center pb-8 px-8"
      >
        <SelectField
          id="select-location-field"
          className="mt-8 mb-12 text-left"
          placeholder={formatMessage(messages.selectLocationPlaceholder)}
          noOptionsMessage={() => (
            <FormattedMessage {...messages.noLocations} />
          )}
          options={data
            ?.map((location: LocationListItem) => ({
              label: location.name,
              value: location.locationId,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))}
          onChange={handleOnChangeLocation}
          label={
            <InputLabel htmlFor="select-location-field">
              <FormattedMessage {...messages.selectNewLocation} />
            </InputLabel>
          }
        />
        <div className="flex justify-center gap-4">
          <Button className="flex-1" onClick={onClose}>
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button
            data-testid="BUTTON_TO_TRANSFER_VEHICLE"
            className="flex-1"
            emphasis="high"
            onClick={handleOnSave}
            disabled={!selectedLocation}
            loading={loading}
          >
            <FormattedMessage {...messages.save} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
