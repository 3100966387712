import { Card, Placeholder } from '@cooltra/ui';

import { SessionRowLayout } from '../SessionRowLayout';

export const SessionCardLoading = () => (
  <Card className="h-16">
    <SessionRowLayout
      date={<Placeholder className="h-2 w-28" />}
      deviceAndVersion={<Placeholder className="h-2 w-52" />}
    />
  </Card>
);
