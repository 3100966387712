import { defineMessages } from 'react-intl';

export default defineMessages({
  remaining: {
    defaultMessage: 'Remaining:',
  },
  serviced: {
    defaultMessage: 'With {mileage} at {date}',
  },
  done: {
    defaultMessage: 'Done',
  },
  pastDue: {
    defaultMessage: 'Past due',
  },
  outOfMileage: {
    defaultMessage: 'Out of mileage',
  },
  next: {
    defaultMessage: 'Next',
  },
});
