import { IntlShape } from 'react-intl';
import { FormErrors } from '@cooltra/form';

import { validateRequired } from '~/validation';

export type ClearSuspensionFormValues = {
  comment: string;
};

export const clearSuspensionFormInitialValues: ClearSuspensionFormValues = {
  comment: '',
};

export const validateClearSuspensionForm =
  (intl: IntlShape) =>
  (
    values: ClearSuspensionFormValues
  ): FormErrors<ClearSuspensionFormValues> => {
    const errors: FormErrors<ClearSuspensionFormValues> = {};
    const requiredErrorMessage = validateRequired(intl, values.comment);
    if (requiredErrorMessage) {
      errors.comment = requiredErrorMessage;
    }
    return errors;
  };
