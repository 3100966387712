import { FormattedMessage } from 'react-intl';
import { Card, Divider, Placeholder } from '@cooltra/ui';

import messages from './messages';

export const ActiveOfferContractsLoading = () => (
  <Card className="p-6">
    <span className="block text-neutral-400 text-xs mb-3">
      <FormattedMessage {...messages.offers} />
    </span>
    <div className="pt-2 h-28">
      <Placeholder className="w-20 h-3 mb-5" />
      <Placeholder className="w-full h-2 mb-2" />
      <Placeholder className="w-52 h-2" />
    </div>
    <Divider />
    <div className="pt-6">
      <Placeholder className="w-12 h-2 mb-3" />
      <Placeholder className="w-16 h-2 mb-10" />
      <Placeholder className="w-32 h-2 mb-10" />
    </div>
  </Card>
);
