import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { FormCheckboxField, useFormContext } from '@cooltra/form';

import { PromotionFormValues } from '../../PromotionForm';
import { PromotionStateType } from '../../PromotionState/PromotionState';

import messages from './messages';

export const Registration = () => {
  const { values } = useFormContext<PromotionFormValues>();
  const { editMode, stateType } = values;
  const editingExpiredPromotion =
    editMode && stateType === PromotionStateType.EXPIRED;

  return (
    <FormCheckboxField
      id="registration"
      disabled={editingExpiredPromotion}
      label={
        <InputLabel emphasis="low" htmlFor="registration">
          <FormattedMessage {...messages.registration} />
        </InputLabel>
      }
      name="registration"
    />
  );
};
