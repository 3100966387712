import { defineMessages } from 'react-intl';

export default defineMessages({
  createTechnicalInspection: {
    defaultMessage: 'Create technical inspection',
  },
  completedTechnicalInspection: {
    defaultMessage: 'Completed technical inspection',
  },
  newTechnicalInspection: {
    defaultMessage: 'New technical inspection',
  },
  invalidFormat: {
    defaultMessage: 'Invalid format',
  },
  save: {
    defaultMessage: 'Save',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  success: {
    defaultMessage: `Technical inspections were saved successfully`,
  },
  technicalInspectionWereNotSavedProperly: {
    defaultMessage: "Some technical inspections weren't saved properly",
  },
});
