import { Rental } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Button, Divider, Spinner } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { shortenId } from '@cooltra/utils';

import { AddCreditModal } from '~/common';

import { PaymentStatusBadge } from '../PaymentStatusBadge/PaymentStatusBadge';
import { RentalInvoiceLink } from '../RentalInvoiceLink/RentalInvoiceLink';

import * as PricingValues from './PricingValues';
import messages from './messages';

export type RentalPricingSummaryProps = {
  rental: Rental;
};

export const RentalPricingSummary = ({
  rental: { pricing, dropOff, rentalId, state, user },
}: RentalPricingSummaryProps) => {
  const { hasPermission } = useAuthClaimsQuery();
  const { formatMessage } = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const canWriteCredits = hasPermission('write:credits');

  if (!dropOff?.createdAt) {
    return (
      <div className="w-full pt-16 text-center opacity-60">
        <Spinner />
      </div>
    );
  }

  if (!pricing) {
    return (
      <p className="text-neutral-400 mt-2 text-center">
        <FormattedMessage {...messages.missingRentalPricing} />
      </p>
    );
  }

  const discountPass = pricing.discountBreakdown.find(
    (discount) => discount.type === 'PASS'
  );

  return (
    <div className="py-2 px-2">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-base text-neutral-500 font-semibold">
          <FormattedMessage {...messages.pricingSummary} />
        </h2>
        <span className="text-neutral-400">
          <PaymentStatusBadge
            rentalPricingPaymentStatus={pricing.paymentStatus}
          />
        </span>
      </div>
      <Divider />
      <div className="flex flex-col gap-2 py-4">
        <PricingValues.PricingTotalPrice pricing={pricing} />
        {pricing.riding.price.value > 0 && (
          <PricingValues.PricingRiding pricing={pricing} />
        )}
        {pricing.pausing.price.value > 0 && (
          <PricingValues.PricingPausing pricing={pricing} />
        )}
        <PricingValues.PricingStarting pricing={pricing} />
        <PricingValues.PricingDiscountBreakdown pricing={pricing} />
      </div>
      <Divider />
      {discountPass ? (
        <PricingValues.PricingPass amount={discountPass.amount} />
      ) : (
        <PricingValues.PricingCredit pricing={pricing} />
      )}
      <Divider />
      <PricingValues.PricingFinalPrice pricing={pricing} />
      <AddCreditModal
        isOpen={isOpen}
        onClose={toggleOff}
        userId={user.userId}
        title={formatMessage(messages.compensateTitle) + shortenId(rentalId)}
      />
      {canWriteCredits && (
        <div className="flex w-full gap-4 pt-8">
          <Button size="sm" onClick={toggleOn}>
            <FormattedMessage {...messages.compensate} />
          </Button>
        </div>
      )}
      {state !== 'ACTIVE' &&
        user.provider === 'COOLTRA' &&
        hasPermission('read:invoices') && (
          <RentalInvoiceLink rentalId={rentalId} />
        )}
    </div>
  );
};
