import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { FormInputField } from '@cooltra/form';

import messages from './messages';

export const City = () => (
  <FormInputField
    name="city"
    id="city"
    label={
      <InputLabel htmlFor="city">
        <FormattedMessage {...messages.city} />
      </InputLabel>
    }
  />
);
