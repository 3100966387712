import { defineMessages } from 'react-intl';

export default defineMessages({
  zoomIn: {
    defaultMessage: 'Zoom in',
  },
  fullScreen: {
    defaultMessage: 'Full screen',
  },
  rotateLeft: {
    defaultMessage: 'Rotate left',
  },
  rotateRight: {
    defaultMessage: 'Rotate right',
  },
});
