import { defineMessages } from 'react-intl';

export default defineMessages({
  delete: {
    defaultMessage: 'Delete',
  },
  deleted: {
    defaultMessage: 'Deleted',
  },
  expired: {
    defaultMessage: 'Expired',
  },
  used: {
    defaultMessage: 'Used',
  },
});
