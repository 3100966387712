import { UserDiscount } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const RentalCap = ({
  rentalsCap,
  rentalsUsed,
}: Pick<UserDiscount, 'rentalsCap' | 'rentalsUsed'>) => (
  <FormattedMessage
    {...messages.rentalsUsed}
    values={{ rentalsUsed, rentalsCap }}
  />
);
