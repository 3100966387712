import { FormattedMessage, useIntl } from 'react-intl';
import { FormTextareaField } from '@cooltra/form';
import { MdChat } from 'react-icons/md';

import { CardLayout } from '../CardLayout/CardLayout';

import messages from './messages';

export const ReviewComments = () => {
  const { formatMessage } = useIntl();

  return (
    <CardLayout
      data-testid="REVIEW_COMMENTS"
      icon={<MdChat />}
      heading={<FormattedMessage {...messages.heading} />}
    >
      <FormTextareaField
        className="mb-6"
        placeholder={formatMessage(messages.placeholder)}
        id="review-comments"
        rows={5}
        name="comments"
      />
    </CardLayout>
  );
};
