import { Card, Divider } from '@cooltra/ui';
import { HTMLAttributes, ReactNode } from 'react';
import { classNames } from '@cooltra/utils';

export type DividedCardProps = HTMLAttributes<HTMLDivElement> & {
  renderHeader: ReactNode;
  renderLeft: ReactNode;
  renderRight: ReactNode;
  renderFooter?: ReactNode;
};

export const DividedCard = ({
  renderHeader,
  renderLeft,
  renderRight,
  renderFooter,
  className,
  ...rest
}: DividedCardProps) => (
  <div className={classNames('container', className)} {...rest}>
    <div className="mb-9">{renderHeader}</div>
    <Card className="mb-5 p-10">
      <div className="flex">
        <div className="w-1/4">{renderLeft}</div>
        <Divider direction="vertical" className="mx-14" />
        <div className="flex-1">{renderRight}</div>
      </div>
    </Card>
    {renderFooter && (
      <div className="flex justify-between items-center">{renderFooter}</div>
    )}
  </div>
);
