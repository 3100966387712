import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type RentalPassRowLayoutProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'title'
> & {
  title: ReactNode;
  price: ReactNode;
  validFrom: ReactNode;
  validUntil: ReactNode;
  status: ReactNode;
};

export const RentalPassRowLayout = ({
  validFrom,
  validUntil,
  title,
  price,
  status,
  className,
  ...rest
}: RentalPassRowLayoutProps) => (
  <div
    className={classNames('pl-6 pr-4 flex items-center gap-4', className)}
    {...rest}
  >
    <div className="flex-1 shrink-0">{title}</div>
    <div className="w-24 shrink-0">{price}</div>
    <div className="w-56 shrink-0">{validFrom}</div>
    <div className="w-56 shrink-0">{validUntil}</div>
    <div className="w-24 shrink-0 flex justify-center">{status}</div>
  </div>
);
