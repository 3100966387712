import { Icon } from '@cooltra/ui';
import { ReactNode } from 'react';

export type SidebarIconItemProps = {
  icon: ReactNode;
  label: ReactNode;
};

export const SidebarIconItem = ({ icon, label }: SidebarIconItemProps) => (
  <li className="flex items-center gap-2">
    <Icon className="text-neutral-300">{icon}</Icon>
    <span className="text-sm font-semibold text-neutral-800">{label}</span>
  </li>
);
