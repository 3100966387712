import { useParams } from 'react-router-dom';
import { useCooltraForEmployeePromotionQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Card, Spinner } from '@cooltra/ui';

import {
  CooltraForEmployeesForm,
  Four0Four,
  Four0Three,
  Log,
  PromotionState,
} from '~/common';

import { CooltraForEmployeesBackLink } from '../BackLink/CooltraForEmployeesBackLink';
import { CooltraForEmployeesFormFields } from '../CooltraForEmployeesFormFields';

import { getInitialFormValuesFromPayload } from './get-initial-form-values-from-payload';

export const EditCooltraForEmployees = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { cooltraForEmployeesId = '' } = useParams<'cooltraForEmployeesId'>();
  const { data: cooltraForEmployeePromotion, isLoading } =
    useCooltraForEmployeePromotionQuery(cooltraForEmployeesId);

  if (!hasPermission('read:cooltra_for_employees')) {
    return <Four0Three />;
  }

  if (isLoading) {
    return (
      <div className="w-full text-center mt-8">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!cooltraForEmployeePromotion) {
    return <Four0Four />;
  }

  const initialFormValues = getInitialFormValuesFromPayload(
    cooltraForEmployeePromotion
  );

  return (
    <div
      className="container min-w-screen-sm max-w-5xl py-12"
      data-testid="EDIT_SUBSCRIPTION_PROMOTION"
    >
      <div className="mb-9">
        <CooltraForEmployeesBackLink />
        <div className="flex items-center gap-4">
          <h1 className="text-3xl">{cooltraForEmployeePromotion.title}</h1>
          <PromotionState
            validFrom={cooltraForEmployeePromotion.redeemableFrom}
            validUntil={cooltraForEmployeePromotion.redeemableUntil}
          />
        </div>
      </div>
      <CooltraForEmployeesForm initialValues={initialFormValues}>
        <div className="flex gap-6 items-start">
          <CooltraForEmployeesFormFields />
          <Card className="flex flex-col gap-4 p-10">
            <Log
              user="operator"
              action="create"
              userId={cooltraForEmployeePromotion.createdBy}
              date={cooltraForEmployeePromotion.createdAt}
            />
          </Card>
        </div>
      </CooltraForEmployeesForm>
    </div>
  );
};
