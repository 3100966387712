import { ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckboxField, InputLabel, Legend } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';

import { FormDateInputField, FormTimeInputField } from '~/common';

import { PassFormValues } from '../../PassForm';

import messages from './messages';

export const ValidityDates = () => {
  const { formatMessage } = useIntl();
  const { setValues, isSubmitting, isFormDisabled, values } =
    useFormContext<PassFormValues>();

  const handleChange = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setValues({
        validUntilDate: '',
        validUntilTime: '',
        passDoesntExpire: checked,
      });
    } else {
      setValues({ passDoesntExpire: checked });
    }
  };

  return (
    <div className="flex gap-x-8 items-start" data-testid="VALIDITY_DATES">
      <fieldset className="flex gap-2" data-testid="VALID_FROM">
        <Legend className="mb-1">
          <FormattedMessage {...messages.validFrom} />
        </Legend>
        <FormDateInputField
          className="w-32"
          name="validFromDate"
          aria-label={formatMessage(messages.date)}
        />
        <FormTimeInputField
          className="w-20"
          name="validFromTime"
          aria-label={formatMessage(messages.time)}
        />
      </fieldset>
      <fieldset className="flex gap-2" data-testid="VALID_UNTIL">
        <Legend className="mb-1">
          <FormattedMessage {...messages.validUntil} />
        </Legend>
        <FormDateInputField
          className="w-32"
          name="validUntilDate"
          aria-label={formatMessage(messages.date)}
          disabled={values.passDoesntExpire}
        />
        <FormTimeInputField
          className="w-20"
          name="validUntilTime"
          aria-label={formatMessage(messages.time)}
          disabled={values.passDoesntExpire}
        />
      </fieldset>
      <CheckboxField
        className="mt-9"
        id="pass-expires"
        checked={values.passDoesntExpire}
        label={
          <InputLabel emphasis="low" htmlFor="pass-expires">
            <FormattedMessage {...messages.doesntExpire} />
          </InputLabel>
        }
        disabled={isFormDisabled || isSubmitting}
        onChange={handleChange}
      />
    </div>
  );
};
