import { createRoot } from 'react-dom/client';
import { init } from '@sentry/react';

import { getEnvVariable } from '~/utils/environment';

import App from './components/App/App';

if (
  getEnvVariable('SENTRY_DSN') &&
  getEnvVariable('NODE_ENV') !== 'development'
) {
  init({
    dsn: getEnvVariable('SENTRY_DSN'),
    environment: getEnvVariable('ENVIRONMENT'),
    // integrations: [new Replay()],
    // replaysOnErrorSampleRate: 0.2,
    ignoreErrors: ['Non-Error promise rejection captured'],
  });
}

const container = document.getElementById('app');

const root = createRoot(container!);

root.render(<App />);
