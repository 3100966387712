import { Card, FakeInput } from '@cooltra/ui';
import { useIntl } from 'react-intl';
import { useFormContext } from '@cooltra/form';

import { ContractDetailsFormValues } from '../contract-details-form';

import messages from './messages';

export type ContractDetailsDatesProps = {
  openModal: () => void;
  disabledDelivery?: boolean;
  disabledReturn?: boolean;
};

export const ContractDetailsDates = ({
  disabledDelivery = false,
  disabledReturn = false,
  openModal,
}: ContractDetailsDatesProps) => {
  const { formatMessage, formatDate } = useIntl();

  const {
    isSubmitting,
    values: { pickup, dropOffDate, dropOffTime, pickUpDate, pickUpTime },
    touched,
    errors,
  } = useFormContext<ContractDetailsFormValues>();

  const formatDateTime = (date?: Date, time?: string) => {
    if (!date || !time) {
      return '';
    }

    return `${formatDate(date, {
      day: 'numeric',
      month: 'short',
    })} - ${time}`;
  };

  return (
    <Card className="pt-8 pb-14 px-14 flex gap-4 items-start">
      <button
        disabled={disabledDelivery}
        type="button"
        onClick={openModal}
        className="w-40"
      >
        <FakeInput
          disabled={isSubmitting || !pickup || disabledDelivery}
          label={formatMessage(messages.delivery)}
          placeholder={formatMessage(messages.dateAndTime)}
          value={formatDateTime(pickUpDate, pickUpTime)}
          invalid={
            (touched.pickUpDate || touched.pickUpTime) &&
            (!!errors.pickUpDate || !!errors.pickUpTime)
          }
          error={
            touched.pickUpDate || touched.pickUpTime
              ? errors.pickUpDate || errors.pickUpTime
              : undefined
          }
        />
      </button>
      <button
        disabled={disabledReturn}
        type="button"
        onClick={openModal}
        className="w-40"
      >
        <FakeInput
          disabled={isSubmitting || !pickup || disabledReturn}
          label={formatMessage(messages.return)}
          placeholder={formatMessage(messages.dateAndTime)}
          value={formatDateTime(dropOffDate, dropOffTime)}
          invalid={
            (touched.dropOffDate || touched.dropOffTime) &&
            (!!errors.dropOffDate || !!errors.dropOffTime)
          }
          error={
            touched.dropOffDate || touched.dropOffTime
              ? errors.dropOffDate || errors.dropOffTime
              : undefined
          }
        />
      </button>
    </Card>
  );
};
