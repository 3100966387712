import { LayerProps } from 'react-map-gl';

export const geofenceLayer: LayerProps = {
  id: 'geofence-area',
  type: 'fill',
  source: 'geofence',
  layout: {},
  paint: {
    'fill-translate': [0, 0],
    'fill-color': '#111',
    'fill-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      7,
      0,
      12,
      0.3,
      22,
      0.5,
    ],
  },
};

export const geofenceBorderLayer: LayerProps = {
  id: 'geofence-border',
  type: 'line',
  source: 'geofence',
  paint: {
    'line-color': [
      'case',
      ['==', ['get', 'vehicleType'], 'SCOOTER'],
      '#008AFF',
      ['==', ['get', 'vehicleType'], 'BIKE'],
      '#3AFC3A',
      '#008AFF',
    ],
    'line-width': ['interpolate', ['linear'], ['zoom'], 7, 1, 12, 2, 22, 4],
    'line-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 1],
  },
};
