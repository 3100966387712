import { FormattedMessage } from 'react-intl';

import { ListTotal, ListTotalProps } from '../../List';

import messages from './messages';

export type TasksTotalProps = Omit<ListTotalProps, 'children'> & {
  total: number;
};

export const TasksTotal = ({ total, loading, ...rest }: TasksTotalProps) => (
  <ListTotal loading={loading} {...rest}>
    <FormattedMessage
      {...messages.tasks}
      values={{
        total,
      }}
    />
  </ListTotal>
);
