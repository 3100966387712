import { FormattedMessage } from 'react-intl';
import { Card, Divider, Placeholder, Link, Icon } from '@cooltra/ui';
import { useParams } from 'react-router-dom';
import { PaymentMethodOrigin, useUserPaymentMethodQuery } from '@cooltra/api';
import { MdOutlineChevronRight } from 'react-icons/md';

import { getEnvVariable } from '~/utils/environment';
import { CardLogo } from '~/components/User/UserOverview/PaymentMethod/CardLogo/CardLogo';
import {
  PaymentMethodLogo,
  PaymentMethodType,
} from '~/components/User/UserOverview/PaymentMethod/PaymentMethodLogo/PaymentMethodLogo';

import messages from './messages';

const getStripeHref = (stripeId: string, origin: PaymentMethodOrigin) => {
  const accountPath =
    origin == 'COOLTRA' ? '/acct_17BPWMFwLMQuqQ0P' : '/acct_1ArSXbLD1f1asXZR';

  const testPath =
    getEnvVariable('ENVIRONMENT') === 'production' ? '' : '/test';

  return `https://dashboard.stripe.com${accountPath}${testPath}/customers/${stripeId}`;
};

export const PaymentMethod = () => {
  const { id = '' } = useParams<'id'>();

  const {
    data: paymentMethod,
    isError,
    isLoading,
  } = useUserPaymentMethodQuery(id, {
    retry: false,
  });

  const getContent = () => {
    if (isLoading) {
      return (
        <div>
          <div className="flex justify-between">
            <Placeholder className="h-9 w-16" />
            <div className="flex flex-col gap-2 justify-center items-end">
              <Placeholder className="w-16 h-2" />
              <Placeholder className="w-12 h-2" />
            </div>
          </div>
          <Divider className="mt-4 mb-4" />
          <Placeholder className="w-20 h-2" />
        </div>
      );
    }

    if (isError) {
      return (
        <p className="h-full flex flex-col justify-center text-center text-xs text-neutral-300">
          <FormattedMessage {...messages.paymentMethodNotFound} />
        </p>
      );
    }

    if (!paymentMethod) {
      return null;
    }

    const {
      brand,
      type,
      lastFourDigits,
      expiryMonth,
      expiryYear,
      stripeId,
      origin,
    } = paymentMethod;

    return (
      <div>
        <div className="flex justify-between items-center text-xs">
          {type === 'card' ? (
            <div className="flex items-center gap-1">
              <CardLogo brand={brand} className="h-9" />
            </div>
          ) : (
            <div className="flex items-center gap-1">
              <PaymentMethodLogo
                type={type as PaymentMethodType}
                className="h-9"
              />
            </div>
          )}
          <div className="flex flex-col gap-1 text-right">
            <span className="text-neutral-900">****{lastFourDigits}</span>
            {expiryMonth && (
              <span className="text-neutral-500">
                {expiryMonth.toString().padStart(2, '0')}/{expiryYear}
              </span>
            )}
          </div>
        </div>
        <Divider className="mt-4 mb-3" />
        <Link
          href={getStripeHref(stripeId, origin)}
          target="_blank"
          className="text-xs flex justify-between w-full"
        >
          <FormattedMessage {...messages.billingReference} />
          <Icon className="pl-1 text-base">
            <MdOutlineChevronRight />
          </Icon>
        </Link>
      </div>
    );
  };

  return (
    <Card
      className="p-6 h-full flex flex-col justify-between"
      data-testid="PAYMENT_CARD"
    >
      <span className="block text-neutral-400 text-xs mb-3">
        <FormattedMessage {...messages.payment} />
      </span>
      {getContent()}
    </Card>
  );
};
